import React, { useState, useEffect } from 'react'
import { Typography, Table, Space, Button, Row } from 'antd'

import { request } from '../../../utils/fetch'
const { Link } = Typography

function DownPage(props) {
  const [tableData, setTableData] = useState([])
  const pageSize = 20 //每页条数
  const [total, setTotal] = useState(0)
  const [pageNum, setPageNum] = useState(1)

  const columns = [
    {
      title: '操作人',
      dataIndex: 'createUser',
      align: 'center',
    },
    {
      title: '操作类型',
      dataIndex: 'typeName',
      align: 'center',
    },
    {
      title: '文件名称',
      dataIndex: 'fileName',
      align: 'center',
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      render: text => ['进行中', '成功', '失败'][text],
    },
    {
      title: '开始时间',
      dataIndex: 'createTime',
      align: 'center',
    },
    {
      title: '结束时间',
      dataIndex: 'updateTime',
      align: 'center',
    },
    {
      title: '操作',
      width: 180,
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return <Space size='middle'>{record.status === 1 && <Link onClick={() => down(record)}>下载</Link>}</Space>
      },
    },
  ]

  const down = record => {
    request.downExport('/common/downloadTaskUrl', { fileName: record.fileName, url: record.url })
  }

  const refresh = () => {
    request.get('/common/getDownloadTaskList', { pageSize: pageSize, pageNum: pageNum }).then(({ list, totalCount }) => {
      if (list && list.length > 0) {
        setTotal(totalCount)
        setTableData(list)
        setPageNum(1)
      }
    })
  }

  useEffect(() => {
    request.get('/common/getDownloadTaskList', { pageSize: pageSize, pageNum: pageNum }).then(({ list, totalCount }) => {
      if (list && list.length > 0) {
        setTotal(totalCount)
        setTableData(list)
      }
    })
  }, [pageNum])

  return (
    <>
      <Row>
        <Button type='primary' onClick={refresh}>
          刷新
        </Button>
      </Row>
      <Table
        scroll={{ x: 'max-content', y: 550 }}
        columns={columns}
        rowKey={record => record.id}
        dataSource={tableData}
        pagination={{
          position: ['bottomCenter'],
          total: total,
          current: pageNum,
          pageSize: pageSize,
          showSizeChanger: false,
          showTotal: total => `共 ${total} 条数据`,
          onChange: (pageNum, pageSize) => setPageNum(pageNum),
        }}
      />
    </>
  )
}

export default DownPage
