import React, { useImperativeHandle, useState, forwardRef, useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  Button,
  message,
  Upload,
  InputNumber
} from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons';
import moment from 'moment'
import { subtractTime } from '../../../../../utils/auth'
import { getProvinceIdListByRegionalNo,getCityListByProvinceId } from '../../api'
import { securityArchivesgetDetail, securityArchivesUpdate } from '../api'
import '../index.css'
const { TextArea } = Input;
const topCol = {
  span: 12,
}

const EditMoel = forwardRef((props,ref) => {
  const { refresh } = props
  const [visible, setVisible] = useState(false);
  const [provinceList, setProvinceList] = useState([])
  const [cityList, setCityList] = useState([])
  const [recordData, setRecordData] = useState({});
  const [isReported, setIsReported] = useState()
  const [isJudged, setIsJudged] = useState()
  const [isRisky, setIsRisky] = useState()
  const [amount, setAmount] = useState()
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileListpf: '',
    previewTitle: '',
  })
  const [form] = Form.useForm()
  const { Option } = Select
  const initialFormValues = {}
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )
  // const action = "http://test.zchpay.com/wispay/common/uploadFile"
  const action = "/wispay/common/uploadFile"
  const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU:1.重大刑事案件;2.涉及刑事案件对公司品牌造成严重影响</p>
      <p className="titp">P0:1.普通刑事案件对公司造成不良品牌影响;2.殴打政府工作人员</p>
      <p className="titp">P1:涉及普通刑事犯罪,未造成不良品牌影响造成不良品牌影响治安事件</p>
      <p className="titp">P2:未造成不良影响的治安案件</p>
      <p className="titp">P3:扬言要杀人跳楼自杀等事件辱骂威胁骚扰等其他案件</p>
    </div>
  )
  const shixiao = <div className="titbox">客服响应时间-首次响应时间</div>
  const shixiaocz = <div className="titbox">处置回复时间-客服响应时间</div>
  const shixiaojz = <div className="titbox">结案时间-接报或上报时间</div>
  useEffect(() => {
    getProvinceIdList()
  }, [])
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // editModal 就是暴露给父组件的方法
    editModal: (record) => { 
      form.resetFields()
      setVisible(true)
      getDetail(record)
    }
  }));
  const getDetail = async (record)=>{
    let result = await securityArchivesgetDetail({archivesCode:record.archivesCode})
    if (result.data.success) {
      let data = result.data.data
      data.eventReportTime = data.eventReportTime? moment(data.eventReportTime): null
      data.eventTime = data.eventTime? moment(data.eventTime): null
      data.firstAnswerTime = data.firstAnswerTime? moment(data.firstAnswerTime): null
      data.serverAnswerTime = data.serverAnswerTime? moment(data.serverAnswerTime): null
      data.dealWithTime = data.dealWithTime? moment(data.dealWithTime): null
      data.attachmentUrl = setFlieList(data.attachmentUrl)
      provinceIdChange(result.data.data.eventProvinceId)
      setIsReported(data.reportedFlag)
      setIsRisky(data.riskyFlag)
      setIsJudged(data.judgedFlag)
      setAmount(data.payAmount)
      setRecordData(data)
      form.setFieldsValue(data)
    } else {
      message.error(result.data.retMsg)
    }
  }
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  // 上传图片，把图片地址存入 licenseFileUrl
  const changeFile = ({ file, fileList }, type) => {
    fileList = fileList.map(item => {
      if (item.response&&item.response.success) {
        // Component will show file.url as link
        item.url = item.response.data[0].url;
      }
      return item;
    });
    setPreviewData(prev => {
        return {
          ...prev,
          fileListpf: fileList
        }
    })
  }
  const handleCancel = () => {
    setPreviewData(prev => {
      return {
        ...prev,
        previewVisible: false
      }
    })
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  // 获取省份
  const getProvinceIdList = () => {
    getProvinceIdListByRegionalNo().then((res)=>{
        if (res.data.success) {
            setProvinceList(res.data.data)
        }else{
          setProvinceList([])
        }
        
    })
  }
  // 根据省份获取城市
  const provinceIdChange = (value) => {
      if(value){
          getCityListByProvinceId({
              provinceId:value
          }).then((res)=>{
              if (res.data.success) {
                  setCityList(res.data.data)
              }
          })
      }else{
          setCityList([])
      }
  }
  const setFlieList = (list) => {
    list = list&&list.filter(item=>{
      return item.url
    })
    list = list&&list.map((item,index)=>{
      return {
        url: item.url,
        uid: item.uid || index,
        name: item.name||item.url,
        status: 'done',
      }
    })
    return list
  }
  const setFlieParams = (list,type) => {
    list = list&&list.filter(item=>{
      return item.url
    })
      list = list&&list.map((item,index)=>{
        return {
          url: item.url,
          uid: 0-index,
          name: item.name||item.url,
          status: 'done'
        }
      })
      return list
  }
  // 修改
  const handleUpdate = async (params) => {
    let data = params
    data.attachmentUrl = setFlieParams(data.attachmentUrl)
    data.eventCode = recordData.eventCode
    data.cityId = recordData.cityId
    data.reportOriginatorUserId = recordData.reportOriginatorUserId
    data.caseOriginatorUserId = recordData.caseOriginatorUserId
    let result = await securityArchivesUpdate(data)
    if (result.data.success) {
      message.success(result.data.retMsg)
      setVisible(false)
      refresh('query', {})
    } else {
      message.error(result.data.retMsg)
    }
  }
  const handleOk = async () => {
    form.validateFields().then(async (valus)=>{
      const data = form.getFieldsValue()
      let params = Object.assign(data, { archivesCode: recordData.archivesCode })
      params.eventReportTime = params.eventReportTime ? moment(params.eventReportTime).format('YYYY-MM-DD HH:mm:ss') : null
      params.eventTime = params.eventTime ? moment(params.eventTime).format('YYYY-MM-DD HH:mm:ss') : null
      params.firstAnswerTime = params.firstAnswerTime ? moment(params.firstAnswerTime).format('YYYY-MM-DD HH:mm:ss') : null
      params.serverAnswerTime = params.serverAnswerTime ? moment(params.serverAnswerTime).format('YYYY-MM-DD HH:mm:ss') : null
      params.dealWithTime = params.dealWithTime ? moment(params.dealWithTime).format('YYYY-MM-DD HH:mm:ss') : null

      handleUpdate(params)
    }).catch(()=>{
      message.error('请完善表单')
    })
  }
  const disabledDatesc = (current) => {
    let dates = form.getFieldsValue().serverAnswerTime
    if (!dates) {
      return false;
    }
    return current>dates&&current!=dates;
  };
  const disabledDatexy = (current) => {
    let dates = form.getFieldsValue().firstAnswerTime
    if (!dates) {
      return false;
    }
    return current<dates;
  };
  const disabledDatecz = (current) => {
    let dates = form.getFieldsValue().serverAnswerTime
    if (!dates) {
      return false;
    }
    return current<dates;
  };
  // 响应时间变化
  const AnswerChange = (inputValue) => {
    const firstAnswerTime = form.getFieldsValue().firstAnswerTime;
    const serverAnswerTime = form.getFieldsValue().serverAnswerTime;
    const dealWithTime = form.getFieldsValue().dealWithTime;
    // 案件响应时间
    if(serverAnswerTime&&firstAnswerTime){
      const diff1 = subtractTime(firstAnswerTime,serverAnswerTime)
      form.setFieldsValue({
        caseResponseEffective: diff1
      })
    }else{
      form.setFieldsValue({
        caseResponseEffective: ''
      })
    }
    //  处置回复时间
    if(serverAnswerTime&&dealWithTime){
      const diff2 = subtractTime(serverAnswerTime,dealWithTime) 
      form.setFieldsValue({
        dealWithTimeEffective: diff2
      })
    }else{
      form.setFieldsValue({
        dealWithTimeEffective: ''
      })
    }
  }
  return (
    <>
      <Modal maskClosable={false}
        title="修改"
        width='1000px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={[
          <Button key="back" onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button key="link" type="primary" onClick={()=>{handleOk()}} >
            保存
          </Button>,
        ]}
      >
        <Form initialValues={initialFormValues} className="addform" form={form}>
          <Row>
            <Col {...topCol}>
              <Form.Item name="dealStatusName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="当前处理进度"  >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...topCol}>
              <Form.Item name="reportOriginatorUserName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="报备发起人"  >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseOriginatorUserName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="结案发起人"  >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventSource" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件来源" >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>客服工单</Option>
                  <Option value={2}>战团反馈</Option>
                  <Option value={3}>饿了么客服</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件工单号" rules={[{ required: true, message: '请填写事件工单号!' }]} >
                <Input />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventReportTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="接报或上报时间" rules={[{ required: true, message: '请填写接报或上报时间!' }]} >
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  showTime
                  allowClear={true}
                  format={["YYYY-MM-DD HH:mm:ss"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityName" label="注册城市" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventClassify" label="事件分类" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Select allowClear placeholder="全部" >
                  <Option value={1}>偷盗</Option>
                  <Option value={2}>服务态度恶劣</Option>
                  <Option value={3}>实施威胁行为(不当言论)</Option>
                  <Option value={4}>实施威胁行为(无不当言论)</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventLevel" label="事件等级" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} tooltip={{ title: content, color: '#FFFFFF' }} >
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="ESU">ESU</Option>
                  <Option value="P0">P0</Option>
                  <Option value="P1">P1</Option>
                  <Option value="P2">P2</Option>
                  <Option value="P3">P3</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="name" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="骑手姓名">
                 <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eleCourierId" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="骑手ID">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="手机号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="idNumber" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="身份证号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件发生时间" rules={[{ required: true,message: '请选择事件发生时间!' }]} >
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  showTime
                  allowClear={true}
                  format={["YYYY-MM-DD HH:mm:ss"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item required labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发城市">
                <div style={{display: 'flex', justifyContent: 'space-between'}} >
                  <Form.Item style={{ marginBottom: 0, width: '100px' }} name="eventProvinceId" rules={[{ required: true,message: '请选择省份!' }]} >
                    <Select placeholder="请选择" onChange={(value) => { provinceIdChange(value) }} getPopupContainer={triggerNode => triggerNode.parentElement} >
                      {provinceList.map(item => (
                        <Option value={item.provinceId} key={item.provinceId}>{item.provinceName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <span style={{display: 'inline-block',height: '32px', lineHeight: '32px', margin: '0 5px'}} >-</span>
                  <Form.Item style={{flex: '1', marginBottom: 0}} name="eventCityId" rules={[{ required: true,message: '请选择事发城市!' }]} >
                    <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                      {cityList.map(item => (
                        <Option value={item.cityId} key={item.cityId}>{item.cityName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
            {/* <Col {...topCol}>
              <Form.Item name="eventRegionName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发区" rules={[{ required: true,message: '请输入事发区!' }]} >
                <Input placeholder='请输入事发区' />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventStreetName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发街道" rules={[{ required: true,message: '请输入事发街道!' }]} >
                <Input placeholder='请输入事发街道' />
              </Form.Item>
            </Col> */}
            <Col {...topCol}>
              <Form.Item name="firstAnswerTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="首次响应时间" >
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  showTime
                  disabledDate={disabledDatesc}
                  allowClear={true}
                  format={["YYYY-MM-DD HH:mm:ss"]}
                  onChange={(value) => { AnswerChange(value)}} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="serverAnswerTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="客服响应时间" >
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  showTime
                  disabledDate={disabledDatexy}
                  allowClear={true}
                  format={["YYYY-MM-DD HH:mm:ss"]}
                  onChange={(value) => { AnswerChange(value)}} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseResponseEffective" label="案件响应时效" tooltip={{ title: shixiao, color: '#FFFFFF' }} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled={true}  />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="dealComment" label="督导处置意见" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写督导处置意见,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dealWithTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="处置回复时间">
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  showTime
                  disabledDate={disabledDatecz}
                  allowClear={true}
                  format={["YYYY-MM-DD HH:mm:ss"]}
                  onChange={(value) => { AnswerChange(value)}} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dealWithTimeEffective" label="处置回复时效" tooltip={{ title: shixiaocz, color: '#FFFFFF' }} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventPlace" label="事件发生地址" rules={[{ required: true,message: '请填写事件发生地址!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input placeholder="请填写事件发生地址" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventScene" label="事件发生情景" rules={[{ required: true,message: '请填写事件发生情景!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input placeholder="请填写事件发生情景" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventReason" label="事件发生起因" rules={[{ required: true,message: '请填写事件发生起因!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input placeholder="请填写事件发生起因" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="badBehavior" label="不良行为" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input placeholder="请填写不良行为" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventDescribe" label="事件经过描述" rules={[{ required: true,message: '请填写事件经过描述!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写事件经过描述,限500字" />
              </Form.Item>
            </Col>
            
            <Col {...topCol}>
              <Form.Item name="workFlag"  label="跑单时段" labelCol={{ span: 6 }} rules={[{ required: true,message: '请选择跑单时段!' }]} wrapperCol={{ span: 13 }}>
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="sensitiveFlag" label="涉及敏感人群" rules={[{ required: true,message: '请选择涉及敏感人群!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value="1">是</Option>
                  <Option value="0">否</Option>
                  {/* <Option value={0}>不涉及</Option>
                  <Option value={2}>14岁以下儿童</Option>
                  <Option value={3}>65岁以上老人</Option>
                  <Option value={4}>政府要员</Option>
                  <Option value={5}>外国友人</Option>
                  <Option value={6}>军人</Option>
                  <Option value={7}>媒体</Option>
                  <Option value={8}>三期妇女</Option>
                  <Option value={9}>伤残人士</Option>
                  <Option value={10}>高知名度社会人士</Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="reportedFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="是否报警">
                <Select placeholder="请选择" onChange={(value) => { setIsReported(value) }} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="judgedFlag" label="是否判责" rules={[{ required: isReported=='1',message: '请选择是否判责!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Select placeholder="请选择" onChange={(value) => { setIsJudged(value) }} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="involvedDuty" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="涉及责任方" rules={[{ required: isJudged=='1',message: '请选择是否判责!' }]} >
                <Input placeholder="请填写涉及责任方" maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dutyJudge" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="责任认定" rules={[{ required: isJudged=='1',message: '请选择是否判责!' }]} >
                <Input placeholder="请填写责任认定" maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="customerDemand" label="客户诉求" rules={[{ required: true }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input placeholder="请填写客户诉求" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="customerDemandFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="客户诉求是否达成一致">
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riskyFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="是否有潜在风险">
                <Select placeholder="请选择" onChange={(value) => { setIsRisky(value)}} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riskDescribe" label="风险描述" rules={[{ required: isRisky=='1',message: '请填写风险描述!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写风险描述,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="solutionDescribe" label="解决方案" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea autoSize={{ minRows: 2}} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderViolateProject" label="骑手违规项目" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderViolateReason" label="骑手违规原因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="greyInvisibleFlag" label="是否需要隐形拉灰" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="requireDescribe" label="甲方要求" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="payAmount" label="费用支出金额" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <InputNumber style={{width: '100%'}} onChange={(value)=>{ setAmount(value) }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="compensateReason" label="赔偿原因" rules={[{ required: amount>0,message: '请填写赔偿原因!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="finishTime" label="结案时间" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseFinishTimeEffective" label="结案时效" tooltip={{ title: shixiaojz, color: '#FFFFFF' }} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="finishRemark" label="结案备注" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea autoSize={{ minRows: 2}} disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div style={{marginBottom: '20px'}} >
                <h2 className="equip-h2">附件</h2>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item name='attachmentUrl' label="赔付凭证" rules={[{ required: amount>0,message: '请上传赔付凭证!' }]} valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  name="files"
                  multiple
                  fileList={previewData.fileListpf}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'files1')}
                >
                  {previewData.fileListpf.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal maskClosable={false}
          width={1000}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
      </Modal>
    </>
  );
});
export default EditMoel



