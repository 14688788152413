import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Row, Col, message } from 'antd'
import { changeUpdateBase } from '../../api'

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 }
}

const AccountModal = props => {
    const { visible, close, modalData, refresh } = props
    const [form] = Form.useForm()
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [visible2, setVisible2] = useState(false)
    const [change, setChange] = useState([])
    const initialFormValues = {
        name: '',
        idNumber: '',
        idNumberAddress: '',
        standCityName: '',
        phone: ''
    }
    useEffect(() => {
        form.setFieldsValue({
            name: modalData.name,
            idNumber: modalData.idNumber,
            idNumberAddress: modalData.idNumberAddress,
            standCityName: modalData.standCityName,
            phone: modalData.phone,
            serviceCompany: modalData.serviceCompany,
            bankNameInfo: modalData.bankNameInfo,
            bankOpenInfo: modalData.bankOpenInfo,
            bankNumberInfo: modalData.bankNumberInfo,
        })
    }, [modalData, form])
    const onCancel = () => {
        close()
        form.resetFields()
    }
    const handleOk = () => {
        form.validateFields().then(() => {
            let formValue = form.getFieldsValue()
            console.log(formValue)
            let temp = []
            if (modalData.name != formValue.name) {
                temp.push('name')
            }
            if (modalData.idNumber != formValue.idNumber) {
                temp.push('idNumber')
            }
            if (modalData.idNumberAddress != formValue.idNumberAddress) {
                temp.push('idNumberAddress')
            }
            if (modalData.phone != formValue.phone) {
                temp.push('phone')
            }
            if (modalData.standCityName != formValue.standCityName) {
                temp.push('standCityName')
            }
            
            if (modalData.serviceCompany != formValue.serviceCompany) {
                temp.push('serviceCompany')
            }
            if (modalData.bankNameInfo != formValue.bankNameInfo) {
                temp.push('bankNameInfo')
            }
            if (modalData.bankOpenInfo != formValue.bankOpenInfo) {
                temp.push('bankOpenInfo')
            }
            if (modalData.bankNumberInfo != formValue.bankNumberInfo) {
                temp.push('bankNumberInfo')
            }

            if (temp.length === 0) {
                message.error('没有改动，无需提交！')
            } else {
                setChange(temp)
                setVisible2(true)
            }
        })
    }
    const onFinish = async values => {
        try {
            setConfirmLoading(true)
            const res = await changeUpdateBase({
                oldIdNumber: modalData.idNumber,
                idNumber: values.idNumber,
                idNumberAddress: values.idNumberAddress,
                standCityName: values.standCityName,
                oldPhone: modalData.phone,
                phone: values.phone,
                oldName: modalData.name,
                name: values.name,

                serviceCompany: values.serviceCompany,
                bankNameInfo: values.bankNameInfo,
                bankOpenInfo: values.bankOpenInfo,
                bankNumberInfo: values.bankNumberInfo,
            })
            if (res.data.success) {
                message.success('提交成功')
                setChange([])
                close()
                setVisible2(false)
                refresh()
            } else {
                message.error(res.data.retMsg)
            }
            setConfirmLoading(false)
        } catch (err) {
            setConfirmLoading(false)
        }
    }
    return (
        <Modal maskClosable={false} forceRender title='修改' visible={visible} onOk={handleOk} onCancel={onCancel} width={740}>
            <Form form={form} {...formItemLayout} initialValues={initialFormValues} onFinish={onFinish}>
                <Form.Item label='姓名' name='name' rules={[{ required: true, message: '姓名格式有误!', max: 20 }]}>
                    <Input />
                </Form.Item>
                <Form.Item label='手机号' name='phone' rules={[{ required: true, message: '手机号格式错误!', len: 11 }]}>
                    <Input />
                </Form.Item>
                <Form.Item label='身份证号' name='idNumber' rules={[{ required: true, message: '身份证号格式有误!', len: 18 }]}>
                    <Input />
                </Form.Item>
                <Form.Item label='身份证地址' name='idNumberAddress' rules={[{ required: true, message: '请输入身份证地址!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label='所在城市' name='standCityName' >
                    <Input />
                </Form.Item>
                <Form.Item label='服务企业' name='serviceCompany' >
                    <Input />
                </Form.Item>
                <Form.Item label='开户银行' name='bankNameInfo' >
                    <Input />
                </Form.Item>
                <Form.Item label='开户支行' name='bankOpenInfo' >
                    <Input />
                </Form.Item>
                <Form.Item label='银行卡号' name='bankNumberInfo' >
                    <Input />
                </Form.Item>
            </Form>
            <Modal maskClosable={false}
                title='确认'
                visible={visible2}
                onCancel={() => {
                    setVisible2(false)
                    setChange([])
                }}
                onOk={() => {
                    form.submit()
                }}
                confirmLoading={confirmLoading}
            >
                <Row style={{ color: 'red', fontSize: '18px' }}>有如下改动，是否确认提交?</Row>
                {change.includes('name') && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col span={24}>旧姓名：{modalData.name}</Col>
                        <Col style={{ color: 'red' }} span={24}>
                            新姓名：{form.getFieldValue('name')}
                        </Col>
                    </Row>
                )}
                {change.includes('idNumber') && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col span={24}>旧身份证号：{modalData.idNumber}</Col>
                        <Col style={{ color: 'red' }} span={24}>
                            新身份证号：{form.getFieldValue('idNumber')}
                        </Col>
                    </Row>
                )}
                {change.includes('idNumberAddress') && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col span={24}>旧身份证地址：{modalData.idNumberAddress}</Col>
                        <Col style={{ color: 'red' }} span={24}>
                            新身份证地址：{form.getFieldValue('idNumberAddress')}
                        </Col>
                    </Row>
                )}
                {change.includes('phone') && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col span={24}>旧手机号：{modalData.phone}</Col>
                        <Col style={{ color: 'red' }} span={24}>
                            新手机号：{form.getFieldValue('phone')}
                        </Col>
                    </Row>
                )}
                {change.includes('standCityName') && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col span={24}>旧所在城市：{modalData.standCityName}</Col>
                        <Col style={{ color: 'red' }} span={24}>
                            新所在城市：{form.getFieldValue('standCityName')}
                        </Col>
                    </Row>
                )}
                {change.includes('serviceCompany') && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col span={24}>旧服务企业：{modalData.serviceCompany}</Col>
                        <Col style={{ color: 'red' }} span={24}>
                            新服务企业：{form.getFieldValue('serviceCompany')}
                        </Col>
                    </Row>
                )}
                {change.includes('bankNameInfo') && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col span={24}>旧开户银行：{modalData.bankNameInfo}</Col>
                        <Col style={{ color: 'red' }} span={24}>
                            新开户银行：{form.getFieldValue('bankNameInfo')}
                        </Col>
                    </Row>
                )}
                {change.includes('bankOpenInfo') && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col span={24}>旧开户支行：{modalData.bankOpenInfo}</Col>
                        <Col style={{ color: 'red' }} span={24}>
                            新开户支行：{form.getFieldValue('bankOpenInfo')}
                        </Col>
                    </Row>
                )}
                {change.includes('bankNumberInfo') && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col span={24}>旧银行卡号：{modalData.bankNumberInfo}</Col>
                        <Col style={{ color: 'red' }} span={24}>
                            新银行卡号：{form.getFieldValue('bankNumberInfo')}
                        </Col>
                    </Row>
                )}
            </Modal>
        </Modal>
    )
}

export default AccountModal
