import React, { useRef } from 'react'
import { Table, Space, Button, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons'
import RemarkModal from './RemarkModal'
import ViewModal from './ViewModal'
import PreviewModal from './PreviewModal'
// 即到期
const DueSoon = (props) => {
    const { pageNum, setPageNum, total, tableData=[], refresh } = props
    const RemarkModalRef = useRef()
    const ViewModalRef = useRef()
    const PreviewModalRef = useRef()
    const toRemark = (record)=>{
        RemarkModalRef.current.remarkData(record)
    }
    const toView = (record) => {
        ViewModalRef.current.viewData(record)
    };
    const tofile = (record,type)=>{
        if(type===1){
            PreviewModalRef.current.previewData(record)
        }else{
            PreviewModalRef.current.downloadData(record)
        }
    }
    // 列表字段
    const tableColumns = [
        { title: '归档时间', dataIndex: 'filingTime', width:170, align: 'center' },
        { title: '来源', dataIndex: 'sourceName', width:160, align: 'center' },
        { title: '合同编号', dataIndex: 'contractNo', width:170, align: 'center' },
        { title: '合同名称', dataIndex: 'contractName', width:140, align: 'center' },
        { title: '甲方', dataIndex: 'partyA', width:160, align: 'center' },
        { title: '乙方', dataIndex: 'partyB', width:160, align: 'center' },
        { title: '申请日期', dataIndex: 'applyDate', width:120, align: 'center' },
        { title: '起始日期', dataIndex: 'contractStartDate', width:120, align: 'center' },
        { title: '终止日期', dataIndex: 'contractEndDate', width:120, align: 'center' },
        { title: '申请人', dataIndex: 'applyName', width:120, align: 'center' },
        { title: '部门', dataIndex: 'applyDepartment', width:120, align: 'center' },
        { title: ()=>{
            return (
                <>
                <span style={{ marginRight: '5px' }}>剩余天数</span>
                <Tooltip placement="topLeft" title={'剩余天数=合同终止日期 - 当前日期'}>
                    <QuestionCircleFilled />
                </Tooltip>
                </>
                
                )
        }, dataIndex: 'lastDay',width:140, align: 'center' },
        {
            title: '操作',
            key: 'control',
            width: 200,
            fixed: 'right',
            align: 'center',
            render: (record) => (
                <Space size="small">
                    <Button type="link" size="small" onClick={() => {toRemark(record)}} block>备注</Button>
                    <Button type="link" size="small" onClick={() => {toView(record)}} block>查看</Button>
                    <Button type="link" size="small" onClick={() => {tofile(record,1)}} block>预览</Button>
                    <Button type="link" size="small" onClick={() => {tofile(record,2)}} block>下载</Button>
                </Space>
            )
        }
    ]
  return (
        <>
        <Table 
            columns={tableColumns} 
            dataSource={tableData} 
            rowKey="contractNo"
            scroll={{ x: 'max-content', y: 540 }}
            pagination={{
            position: ['bottomCenter'],
            total: total,
            current: pageNum,
            pageSize: 20,
            showTotal:(total) =>  `共 ${total} 条数据`,
            onChange: (page, pageSize)　=> setPageNum(page)
            }}
        />
        <RemarkModal ref={RemarkModalRef} refresh={refresh} />
        <ViewModal ref={ViewModalRef} />
        <PreviewModal ref={PreviewModalRef} />
        </>
  )
}

export default DueSoon