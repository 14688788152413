
import Login from './pages/Login';
import NotFind from './pages/NotFind';

import Home from './pages/admin/home/Home';
import DownPage from './pages/admin/downPage/index';
import ManualCheck from './pages/admin/commissionManage/ManualCheck/ManualCheck';
import commissionUpload from './pages/admin/commissionManage/CommissionUpload/CommissionUpload';  //佣金上传
import CommissionUploadDetail from './pages/admin/commissionManage/CommissionUpload/Detail/Detail';//佣金上传-明细 
import CommissionUploadDetailPreview from './pages/admin/commissionManage/CommissionUpload/Detail/Preview/Preview';//佣金上传-明细-预览 
import CommissionUploadDetailStatement from './pages/admin/commissionManage/CommissionUpload/Detail/Statement/Statement';//佣金上传-明细-查看结算单 
import CommissionUploadDetailCheck from './pages/admin/commissionManage/CommissionUpload/Detail/Check/Check';//佣金上传-明细-审核查询 
import CompanyLists from './pages/admin/companyManage/companyLists'
import CheckDetail from './pages/admin/companyManage/companyLists/CheckDetail'
import ProtocolTemplate from './pages/admin/companyManage/protocolTemplate'
import OfflineConfirm from './pages/admin/commissionManage/OfflineConfirm/OfflineConfirm';
import CityCounty from './pages/admin/systemSet/CityCounty/CityCounty';
import AccountManage from './pages/admin/systemSet/AccountManage/AccountManage';
import ResourceManage from './pages/admin/systemSet/ResourceManage/ResourceManage';
import RolePermission from './pages/admin/systemSet/RolePermission/RolePermission';
import ColonyRole from './pages/admin/systemSet/ColonyRole/ColonyRole';
import ColonyAccount from './pages/admin/systemSet/ColonyAccount/ColonyAccount';
import OrganizationManage from './pages/admin/systemSet/OrganizationManage/OrganizationManage';

import CompanyPermission from './pages/admin/systemSet/CompanyPermission/CompanyPermission'; 
import CompanyAccount from './pages/admin/systemSet/CompanyAccount/CompanyAccount';
import CompanyRole from './pages/admin/systemSet/CompanyRole/CompanyRole';

import CompanyEnter from './pages/admin/companyManage/companyEnter'
import ContractLists from './pages/admin/companyManage/contractLists'
import ElectronicContract from './pages/admin/personalManage/ElectronicContract/ElectronicContract'
import ContractTemp from './pages/admin/personalManage/ContractTemp/ContractTemp'
import PersonalLists from './pages/admin/personalManage/personalLists'
import DocumentSave from './pages/admin/personalManage/documentSave'
import ChangeContract from './pages/admin/personalManage/changeContract'
import ChangeContractDetail from './pages/admin/personalManage/changeContract/Detail'
import HistoryConfig from './pages/admin/companyManage/companyLists/HistoryConfig'
import PlatformRecord from './pages/admin/financeManage/platformRecord'
import PlatformDetail from './pages/admin/financeManage/platformRecord/detail'
import CompanyPipeline from './pages/admin/financeManage/companyPipeline'
import SecondStatement from './pages/admin/financeManage/secondStatement'
import PlatformCheck from './pages/admin/financeManage/platformCheck'
import BankSlip from './pages/admin/financeManage/bankSlip'
import TaxSource from './pages/admin/financeManage/taxSource'


import CompanyPipelineDetail from './pages/admin/financeManage/companyPipeline/detail'
import LadderRefund from './pages/admin/financeManage/ladderRefund'
import CompanyInvoice from './pages/admin/invoiceManage/companyInvoice'
import CompanyInvoiceLeft from './pages/admin/invoiceManage/companyInvoiceLeft'
import PersonageInvoice from './pages/admin/invoiceManage/personageInvoice/PersonageInvoice'
import SettleQuery from './pages/admin/commissionManage/SettleQuery/SettleQuery';
import CompanyUpdate from './pages/admin/companyManage/companyLists/updateCompany'
import BankSon from './pages/admin/companyManage/bankSon'
import WithdrawRecord from './pages/admin/financeManage/platformRecord/withdrawRecord'



import accidentClosure from './pages/admin/crowdsourceManage/accidentClosure/index'
import accidentFile from './pages/admin/crowdsourceManage/accidentFile/index'
import accidentReporting from './pages/admin/crowdsourceManage/accidentReporting/index'
import crowdsourcePersonnel from './pages/admin/crowdsourceManage/crowdsourcePersonnel/index'
import withdrawProve from './pages/admin/crowdsourceManage/withdrawProve/index'
import regional from './pages/admin/crowdsourceManage/regional/index'
import crowdRiderBill from './pages/admin/crowdsourceManage/crowdRiderBill/index'
import crowdMerchantBill from './pages/admin/crowdsourceManage/crowdMerchantBill/index'
import OrderInvestigate from './pages/admin/InvestigatePage/OrderInvestigate'
import PublisherInvestigate from './pages/admin/InvestigatePage/PublisherInvestigate'
import ServiceInvestigate from './pages/admin/InvestigatePage/ServiceInvestigate'
import TaskDescription from './pages/admin/InvestigatePage/TaskDescription'
import orderTrail from './pages/admin/crowdsourceManage/orderTrail'
import orderFiles from './pages/admin/crowdsourceManage/orderFiles'
import ddShowFiles from './pages/admin/ddShowFiles'

//天津数据
import TianJinData from './pages/admin/tianJin/tianJinData';  //佣金上传
import TianJinDataDetail from './pages/admin/tianJin/tianJinData/Detail/Detail';//佣金上传-明细 
import TianJinDataDetailPreview from './pages/admin/tianJin/tianJinData/Detail/Preview/Preview';//佣金上传-明细-预览 


import orderList from './pages/admin/electricCarModel/orderList'
import assetList from './pages/admin/electricCarModel/assetList'
import billDetail from './pages/admin/electricCarModel/billDetail'
import settlementRecord from './pages/admin/electricCarModel/settlementRecord'
import CarDealerInfo from './pages/admin/electricCarModel/carDealerInfo'
import MerchantInfo from './pages/admin/electricCarModel/merchantInfo'
import UsersInfo from './pages/admin/electricCarModel/usersInfo'
import ServiceChargeDetail from './pages/admin/electricCarModel/serviceChargeDetail'


// 合同管理
import contractFile from './pages/admin/contractManage/contractFile'

import trafficSafety from './pages/admin/crowdsourceManage/trafficSafety/index'
import trafficArchives from './pages/admin/crowdsourceManage/trafficArchives/index'

import selfEmployed from './pages/admin/personalManage/selfEmployed/index'//九江个体户
import bankCardPush from './pages/admin/personalManage/bankCardPush/index'//银行卡推送
import cardPushDetail from './pages/admin/personalManage/bankCardPush/cardPushDetail'//银行卡推送


import CnlinePayment from './pages/admin/commissionManage/CnlinePayment/index';
import CnlinePaymentDetail from './pages/admin/commissionManage/CnlinePayment/Detail';

import PaymentRefund from './pages/admin/commissionManage/PaymentRefund/index';

import MultistageAccount from './pages/admin/commissionManage/MultistageAccount/index';
import MultistageAccountDetail from './pages/admin/commissionManage/MultistageAccount/Detail';

import BankReceipt from './pages/admin/commissionManage/BankReceipt/BankReceipt';

//不需要权限
export const mainRouter = [
    { path: "/login", name: "Login", component: Login },
    { path: "/404", name: "NotFind", component: NotFind },   
    { path: '/ddShowFiles', name: '附件列表', component: ddShowFiles}, 
    { path: '/order_info', name: '订单反查', component: OrderInvestigate}, 
    { path: '/publish_info', name: '发布方反查', component: PublisherInvestigate}, 
    { path: '/service_info', name: '服务方反查', component: ServiceInvestigate}, 
    { path: '/TaskDescription', name: '服务方反查', component: TaskDescription}, 
]

//需要登录
export const adminRouter = [
    { path: '/admin/companyLists', name: '企业列表', component: CompanyLists},
    { path: '/admin/companyLists/:id', name: '企业列表详情', component: CheckDetail},
    { path: '/admin/protocolTemplate', name: '企业协议模板', component: ProtocolTemplate},
    { path: '/admin/companyEnter', name: '企业入驻', component: CompanyEnter},
    { path: '/admin/companyUpdate/:companyCode', name: '企业信息修改', component: CompanyUpdate},
    { path: '/admin/contractLists', name: '发包信息', component: ContractLists},
    { path: '/admin/personalLists', name: '个人列表', component: PersonalLists},
    { path: '/admin/documentSave', name: '证件留档', component: DocumentSave},
    { path: '/admin/historyConfig/:id', name: '历史配置', component: HistoryConfig},
    { path: '/admin/platformRecord', name: '平台入账', component: PlatformRecord},
    { path: '/admin/platformRecord/detail/:id', name: '平台入账明细', component: PlatformDetail},
    { path: '/admin/companyPipeline', name: '企业流水', component: CompanyPipeline},
    { path: '/admin/companyPipeline/detail/:id', name: '企业流水明细', component: CompanyPipelineDetail},
    { path: '/admin/ladderRefund', name: '阶梯退费', component: LadderRefund },
    { path: '/admin/companyInvoice', name: '企业开票', component: CompanyInvoice },
    { path: '/admin/companyTicketLeft', name: '企业余票', component: CompanyInvoiceLeft },
    { path: '/admin/personageInvoice', name: '个人开票', component: PersonageInvoice },
    { path: '/admin/bankSon', name: '银行子户', component: BankSon},
    { path: '/admin/withdrawRecord', name: '提现记录', component: WithdrawRecord},
    { path: "/admin/home", name: "首页", component: Home },
    { path: "/admin/downPage", name: "下载列表", component: DownPage },
    { path: "/admin/commissionUpload", name: "佣金上传", component: commissionUpload },
    { path: "/admin/commissionUpload/detail", name: "佣金上传-详情", component: CommissionUploadDetail },
    { path: "/admin/commissionUpload/detail/preview", name: "佣金上传-详情-预览", component: CommissionUploadDetailPreview },
    { path: "/admin/commissionUpload/detail/statement", name: "佣金上传-详情-查看结算单", component: CommissionUploadDetailStatement },
    { path: "/admin/commissionUpload/detail/check", name: "佣金上传-详情-审核查询", component: CommissionUploadDetailCheck },
    { path: "/admin/manualCheck", name: "人工审核", component: ManualCheck },
    { path: '/admin/offlineConfirm', name: '线下确认', component: OfflineConfirm},
    { path: '/admin/settleQuery', name: '结算查询', component: SettleQuery},
    { path: '/admin/cityCounty', name: '落地区县', component: CityCounty},
    { path: '/admin/accountManage', name: '账号管理', component: AccountManage},
    { path: '/admin/resourceManage', name: '资源管理', component: ResourceManage},
    { path: '/admin/rolePermission', name: '角色权限', component: RolePermission},
    { path: '/admin/companyPermission', name: '企业账号权限', component: CompanyPermission},
    { path: '/admin/companyPermission/detail1', name: '账号权限', component: CompanyAccount},
    { path: '/admin/companyPermission/detail2', name: '角色权限', component: CompanyRole},
    { path: '/admin/electronicContract', name: '电子合同', component: ElectronicContract},
    { path: '/admin/contractTemp', name: '合同模板', component: ContractTemp},
    { path: '/admin/changeContract', name: '合同换签', component: ChangeContract},
    { path: '/admin/changeContract/detail', name: '合同换签-详情', component: ChangeContractDetail},
    { path: '/admin/secondStatement', name: '二类户流水', component: SecondStatement},
    { path: '/admin/platformCheck', name: '平台对账', component: PlatformCheck},
    { path: '/admin/bankSlip', name: '银行回单', component: BankSlip},
    { path: '/admin/colonyRole', name: '集群角色', component: ColonyRole},
    { path: '/admin/colonyAccount', name: '集群账号', component: ColonyAccount},
    { path: '/admin/accidentClosure', name: '事件结案', component: accidentClosure},
    { path: '/admin/accidentFile', name: '事件档案', component: accidentFile},
    { path: '/admin/accidentReporting', name: '事件报备', component: accidentReporting},
    { path: '/admin/orderTrail', name: '治安跟踪', component: orderTrail},
    { path: '/admin/orderFiles', name: '治安档案', component: orderFiles},
    { path: '/admin/crowdsourcePersonnel', name: '众包人员', component: crowdsourcePersonnel},
    { path: '/admin/TaxSource', name: '财税企业', component: TaxSource},
    { path: '/admin/organizationManage', name: '财税企业', component: OrganizationManage},
    { path: '/admin/withdrawProve', name: '财税企业', component: withdrawProve},
    { path: '/admin/regional', name: '大区管理', component: regional},
    { path: '/admin/crowdRiderBill', name: '骑手账单', component: crowdRiderBill},
    { path: '/admin/crowdMerchantBill', name: '骑手账单', component: crowdMerchantBill},
    { path: "/admin/tianJinData", name: "佣金上传", component: TianJinData },
    { path: "/admin/tianJinData/detail", name: "佣金上传-详情", component: TianJinDataDetail },
    { path: "/admin/tianJinData/detail/preview", name: "佣金上传-详情-预览", component: TianJinDataDetailPreview },
    { path: '/admin/orderList', name: '订单列表', component: orderList},
    { path: '/admin/assetList', name: '资产列表', component: assetList},
    { path: '/admin/billDetail', name: '账单明细', component: billDetail},
    { path: '/admin/settlementRecord', name: '结算记录', component: settlementRecord},
    { path: '/admin/carDealerInfo', name: '车行信息', component: CarDealerInfo},
    { path: '/admin/merchantInfo', name: '商户信息', component: MerchantInfo},
    { path: '/admin/usersInfo', name: '用户信息', component: UsersInfo},
    { path: '/admin/serviceChargeDetail', name: '服务费明细', component: ServiceChargeDetail},
    { path: '/admin/contractFile', name: '合同档案', component: contractFile},
    { path: '/admin/trafficSafety', name: '交安跟踪', component: trafficSafety},
    { path: '/admin/trafficArchives', name: '交安档案', component: trafficArchives},
    { path: '/admin/selfEmployed', name: '九江个体户', component: selfEmployed},
    { path: '/admin/bankCardPush', name: '银行卡推送', component: bankCardPush},
    { path: '/admin/bankCardPush/cardPushDetail', name: '银行卡推送', component: cardPushDetail},
    { path: '/admin/CnlinePayment', name: '线上支付', component: CnlinePayment},
    { path: '/admin/CnlinePayment/Detail', name: '线上支付', component: CnlinePaymentDetail},
    { path: '/admin/PaymentRefund', name: '支付退回', component: PaymentRefund},
    { path: '/admin/MultistageAccount', name: '农行多级账户', component: MultistageAccount},
    { path: '/admin/MultistageAccount/Detail', name: '农行多级账户', component: MultistageAccountDetail},
    { path: '/admin/BankReceipt', name: '银行回单', component: BankReceipt},
]

