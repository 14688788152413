import React, { useImperativeHandle, useState, forwardRef } from 'react'
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Select
} from 'antd';
import { securityEventTransform } from '../api'
import '../index.css'
const { TextArea } = Input;
const { Option } = Select;
const topCol = {
  span: 12,
}

const DeliverMoel = forwardRef((props,ref) => {
  const { refresh } = props
  const [visible, setVisible] = useState(false);
  const [eventCode, setEventCode] = useState('');
  const [form] = Form.useForm()
  const initialFormValues = {}

  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (record) => { 
      form.resetFields()
      form.setFieldsValue(record)
      setEventCode(record.eventCode)
      setVisible(true)
    }
  }));
  // 转交安
  const handleTransform = async (data)=>{
    let result = await securityEventTransform(data)
    if (result.data.success) {
      message.success(result.data.retMsg)
      setVisible(false)
      refresh('query', {})
    } else {
      message.error(result.data.retMsg)
    }
  }
  const handleOk = async () => {
    form.validateFields().then(async (valus)=>{
      const data = {
        eventCode: eventCode,
        eventDescribe: form.getFieldsValue().eventDescribe
      } 
      handleTransform(data)
    }).catch(()=>{
      message.error('请完善表单')
    })
  }
  return (
    <>
      <Modal maskClosable={false}
        title="转交安"
        width='1000px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={[
          <Button key="back" onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button key="link" type="primary" onClick={()=>{handleOk()}}>
            确定
          </Button>
        ]}
      >
        <Form initialValues={initialFormValues} className="addform" form={form}>
          <Row>
            <Col {...topCol}>
              <Form.Item name="eventSourceName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件来源"  >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件工单号" >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventReportTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="接报或上报时间" >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityName" label="注册城市" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="name" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="骑手姓名">
                 <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eleCourierId" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="骑手ID">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="手机号">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="idNumber" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="身份证号">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件发生时间" >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发城市">
                <div style={{display: 'flex', justifyContent: 'space-between'}} >
                  <Form.Item style={{ marginBottom: 0, width: '100px' }} name="eventProvinceName" >
                      <Input disabled />
                  </Form.Item>
                  <span style={{display: 'inline-block',height: '32px', lineHeight: '32px', margin: '0 5px'}} >-</span>
                  <Form.Item style={{flex: '1', marginBottom: 0}} name="eventCityName" >
                    <Input disabled />
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
            {/* <Col {...topCol}>
              <Form.Item name="eventRegionName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发区" >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventStreetName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发街道" >
                <Input disabled={true} />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item name="eventPlace" label="事件发生地址" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="workFlag"  label="跑单时段" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }}>
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="sensitiveName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="涉及敏感人群">
                  <Input disabled={true} />
                </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventDescribe" label="事件经过描述" rules={[{ required: true,message: '请填写事件经过描述!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写事件经过描述,限500字" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
});
export default DeliverMoel



