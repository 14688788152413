import React, { useState, useEffect } from 'react'
import { Table, message, PageHeader } from 'antd';
import axios from 'axios'
import qs from 'qs'
// 任务描述明细
const TaskDescription = (props) => {
  const [dataSource,setdataSource] = useState([])
  const columns = [
    { title: '账单时间', width: 100, align:'center', dataIndex: 'zdsj', key: 'zdsj' },
    { title: '团队ID', width: 90, align:'center', dataIndex: 'tdid', key: 'tdid' },
    { title: '团队名称', width: 140, align:'center', dataIndex: 'tdmc', key: 'tdmc' },
    { title: '配送状态', width: 90, align:'center', dataIndex: 'pszt', key: 'pszt' },
    { title: '订单号', width: 140, align:'center', dataIndex: 'ddh', key: 'ddh' },
    { title: '运单号', width: 140, align:'center', dataIndex: 'ydh', key: 'ydh' },
    { title: '业务交易时间', width: 120, align:'center', dataIndex: 'ywjysj', key: 'ywjysj' },
    { title: '订单来源', width: 90, align:'center', dataIndex: 'ddly', key: 'ddly' },
    { title: '标品类型', width: 120, align:'center', dataIndex: 'bplx', key: 'bplx' },
    { title: '门店ID', width: 90, align:'center', dataIndex: 'mdid', key: 'mdid' },
    { title: '门店名称', dataIndex: 'mdmc', key: 'mdmc' },
    { title: '每单结算总金额', width: 130, align:'center', dataIndex: 'jszje', key: 'jszje' },
    { title: '每单实际结算到手金额', width: 180, align:'center', dataIndex: 'sjje', key: 'sjje' },
    { title: '每单平台合计扣款',  width: 150, align:'center', dataIndex: 'hjje', key: 'hjje' },
  ];
  const getData = ()=>{
    let data = qs.parse(props.history.location.search.replace(/\?/g, ''));
    axios({
      method: 'get',
      url: `/wispay/platform/reverseData/ddInfo`,
      // url: `/platform/reverseData/ddInfo`,
      params: data,
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      if(res.data.success){
        setdataSource(res.data.data.detailList)
      }else{
        message.error(res.data.retMsg)
      }
    }).catch(err => {
        console.log(err)
    })
   
  }

  useEffect(() => {
    getData()
  },[])
  return (
    <div className='tablenotop'>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="订单明细"
      />
    <Table 
    dataSource={dataSource} 
    scroll={{ x: 'max-content',y: 770 }} 
    rowKey={(record) => record.ddh} 
    columns={columns}
    pagination={{
      position: ['bottomCenter'],
      showSizeChanger:false,
      pageSize: 20,
      showTotal:(total) => `共 ${total} 条数据` ,
    }}/>
    </div>
  )
}

export default TaskDescription