import React, { useState, useEffect,useRef, useImperativeHandle,forwardRef } from 'react';
import {
    Table,
    Space,
    message,
    Popconfirm,
    Tooltip
} from 'antd'
import store from '../../../../../store/index'
import { getAccidentReportedList,deleteAccidentReported, getDetailAccidentReported } from '../../api'
const TableList = (props,ref)=> {
    let path = 'accidentReported'
    const { refresh } = props
    const [tableData, setTableData] = useState([])
    // const [queryData, setQueryData] = useState({})
    let pageSize = 20
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: pageSize,
        total: 0
    })
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        getlist: (val) => {
            // setQueryData(val)
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
            getTableLists(pagination,val)
        }
    }));
    const getTableLists = async ({pageNum, pageSize},queryData={}) => {
        let result = await getAccidentReportedList(Object.assign({}, queryData, {pageNum: pageNum, pageSize: pageSize}))
        if (result.data.success) {
            setTableData(result.data.data.list)
            setPagination(prev => {
                return {
                    ...prev,
                    total: result.data.data.totalCount
                }
            })
        } else {
            message.error(result.data.retMsg)
        }
    }
    // 列表字段
    const tableColumns = [
        { title: '事件编号', dataIndex: 'accidentNo', key: 'accidentNo', align: 'center', width: 170 },
        { title: '事件月', dataIndex: 'accidentMonth', key: 'accidentMonth', align: 'center', width: 110 },
        { title: '事件日期', dataIndex: 'accidentDate', key: 'accidentDate', align: 'center', width: 120 },
        { title: '城市', dataIndex: 'cityIdName', key: 'cityIdName', align: 'center', width: 100 },
        { title: '企业名称', dataIndex: 'companyName', key: 'companyName', align: 'center', width: 140 },
        { title: 'T名称', dataIndex: 'tname', key: 'tname', align: 'center', width: 100,},
        { title: '报备人', dataIndex: 'reportedUserIdName', key: 'reportedUserIdName', align: 'center', width: 100 },
        { title: '工单类型', dataIndex: 'accidentOrderTypeName', key: 'accidentOrderTypeName', align: 'center', width: 100 },
        { title: '事件级别', dataIndex: 'accidentLevel', key: 'accidentLevel', align: 'center', width: 100 },
        { title: '事件人员', dataIndex: 'name', key: 'name', align: 'center', width: 180,},
        { title: '手机号', key: 'phone', dataIndex: 'phone', align: 'center', width: 120, },
        { title: '身份证号', dataIndex: 'idNumber', key: 'idNumber', align: 'center', width: 110 },
        { title: '人员类型', dataIndex:'staffTypeName', key: 'identityName', align: 'center', width: 100,  },
        { title: '事件类型', dataIndex:'eventTypeName', key: 'eventTypeName', align: 'center', width: 100,  },
        { title: '事件起因', dataIndex: 'accidentCause', key: 'accidentCause', align: 'center', width: 120,
        ellipsis: {
            showTitle: false,
          },
          render: accidentCause => (
            <Tooltip placement="topLeft" title={accidentCause}>
              {accidentCause}
            </Tooltip>
          ),
        },
        { title: '人员车辆', dataIndex: 'riderVehicle', key: 'riderVehicle', width: 150, align: 'center', },
        { title: '事件地点', dataIndex: 'accidentAddress', key: 'accidentAddress', width: 150, align: 'center',ellipsis: {
            showTitle: false,
          },
          render: accidentAddress => (
            <Tooltip placement="topLeft" title={accidentAddress}>
              {accidentAddress}
            </Tooltip>)
        },
        { title: '财产伤害', dataIndex: 'propertyTypeName', key: 'propertyTypeName', align: 'center', width: 100 },
        { title: '人身伤害程度', dataIndex: 'degreeInjuryTypeName', key: 'degreeInjuryTypeName', align: 'center', width: 120 },
        { title: '事件认定责任', dataIndex: 'accidentLiability', key: 'accidentLiability', align: 'center', width: 120 },
        { title: '保险公司', dataIndex: 'insuranceCompanyTypeName', key: 'insuranceCompanyTypeName', align: 'center', width: 100 },
        { title: '保单号', dataIndex: 'insurancePolicyNo', key: 'insurancePolicyNo', align: 'center', width: 100 },
        { title: '事件工单号', dataIndex: 'accidentOrderNo', key: 'accidentOrderNo', align: 'center', width: 130 },
        { title: '事件描述', dataIndex: 'accidentDescribe', key: 'accidentDescribe', align: 'center', width: 160, 
            ellipsis: {
            showTitle: false,
          },
          render: accidentDescribe => (
            <Tooltip placement="topLeft" title={accidentDescribe}>
              {accidentDescribe}
            </Tooltip>
          ), },
        { title: '创建日期', dataIndex: 'createTime', key: 'createTime', align: 'center', width: 120 },
        { title: '流程状态', dataIndex: 'processStatusName', key: 'processStatusName', align: 'center', width: 100 },
        {
            title: '操作',
            key: 'control',
            fixed: 'right',
            width: 280,
            align: 'center',
            render: (record) => (
                <Space>
                    {store.getState().userReducer.permissions.indexOf(`${path}:select`) > -1 &&<a key="1" onClick={() => handelView(record)}>查看</a>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:update`) > -1 &&(record.processStatus == 0 || record.processStatus == 4  || record.processStatus == 5)&&<a key="2" onClick={() => handelModify(record)}>修改</a>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:update`) > -1 &&<a  key="2-1" onClick={() => handelModifyIdNumber(record)}>修改身份证号</a>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:sendMsg`) > -1&&<a key="3" onClick={() => handelSendMsg(record)}>发短信</a>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:delete`) > -1 &&record.processStatus == 0&&<Popconfirm
                        title="是否删除?"
                        onConfirm={()=>{handelDelete(record)}}
                        okText="确定"
                        cancelText="取消"
                    >
                        <a href="#">删除</a>
                    </Popconfirm>}
                </Space>
               
            )
        }
    ]
    useEffect(() => {
        getTableLists(pagination)
    }, [])
    // 查看
    const handelView = async (record) => {
        let result = await getDetailAccidentReported({accidentNo:record.accidentNo})
        if(result.data.success){
            refresh('view', result.data.data.accidentReportedVo,result.data.data.auditRecordList)
        }else{
            message.error(result.data.retMsg)
        }
    }
    // 修改
    const handelModify = async (record) => {
        let result = await getDetailAccidentReported({accidentNo:record.accidentNo})
        if(result.data.success){
            refresh('updata',result.data.data.accidentReportedVo,result.data.data.auditRecordList)
        }else{
            message.error(result.data.retMsg)
        }
    }
    // 修改身份证号
    const handelModifyIdNumber = async (record) => {
        let result = await getDetailAccidentReported({accidentNo:record.accidentNo})
        if(result.data.success){
            refresh('editIdNumber',result.data.data.accidentReportedVo,result.data.data.auditRecordList)
        }else{
            message.error(result.data.retMsg)
        }
    }
    // 删除
    const handelDelete = async (row) => {
        let result = await deleteAccidentReported({accidentNo:row.accidentNo})
        if (result.data.success) {
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
            message.success(result.data.retMsg)
            getTableLists({ pageSize: pageSize, pageNum: 1 })
        } else {
            message.error(result.data.retMsg)
        }
        
    }
     // 发短信
     const handelSendMsg = async (record) => {
        refresh('sendMsg',record)
    }
    //分页改变
    const tablePageChange = (pageNum, pageSize) => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: pageNum
            }
        })
        getTableLists({ pageSize: pageSize, pageNum: pageNum })
    }
    return (
        <Table
            columns={tableColumns}
            dataSource={tableData}
            scroll={{ x: 1600, y: 500 }}
            rowKey={record => record.accidentNo}
            pagination={{
                position: ['bottomCenter'],
                showSizeChanger: false,
                total: pagination.total,
                current: pagination.pageNum,
                pageSize: pagination.pageSize,
                showTotal: (total) => `共 ${total} 条数据`,
                onChange: (pageNum, pageSize) => tablePageChange(pageNum, pageSize)
            }}
        />
    )
}
export default forwardRef(TableList)
