import React, { useState, useRef,useEffect } from 'react';
import { Spin } from 'antd';
import QueryForm from './components/QueryForm'
import TableList from './components/TableList'
import AddMoel from './components/AddMoel'
import EditIdNumber from './components/EditIdNumber'
import SendMsgModel from './components/SendMsgModel'
import moment from 'moment'
import {getCityListByProvinceId} from '../api'
function AccidentReporting() {
    const [loading, setLoading] = useState(false)
    const [cityData, setCityData] = useState([])
    const TableListRef = useRef();
    const AddMoelRef = useRef();
    const EditIdNumberRef = useRef();
    const SendMsgModelRef = useRef();
    useEffect(() => {
        getCityListByProvinceId().then((res)=>{
            if(res.data.success){
                setCityData(res.data.data)
            }
        })
      
    }, [])
    const refresh = (val,data,splist)=>{
        switch (val) {
            case "query":
                    TableListRef.current.getlist(data);
                break;
            case "add":
                AddMoelRef.current.showModal('新增');
                break;
            case "updata":
                    AddMoelRef.current.showModal('编辑',Object.assign(data,{accidentDate: moment(data.accidentDate)}),splist);
                break;
            case "editIdNumber":
                EditIdNumberRef.current.showModal('修改身份证号',Object.assign(data,{accidentDate: moment(data.accidentDate)}),splist);
                break;
            case "view":
                    AddMoelRef.current.showModal('查看',Object.assign(data,{accidentDate: moment(data.accidentDate)}),splist);
                break;
            case "sendMsg":
                SendMsgModelRef.current.showModal(data);
            break;
            default: 
          }
    }
    return (
        <Spin spinning={loading}>
            <QueryForm cityData={cityData} refresh={refresh}></QueryForm>
            <TableList refresh={refresh} ref={TableListRef}></TableList>
            <AddMoel cityData={cityData} refresh={refresh} ref={AddMoelRef} ></AddMoel>
            <EditIdNumber  refresh={refresh} ref={EditIdNumberRef} ></EditIdNumber>
            <SendMsgModel refresh={refresh} ref={SendMsgModelRef} ></SendMsgModel>
        </Spin>
    )
}

export default AccidentReporting
