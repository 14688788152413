import React,{useState,useEffect,useProps} from 'react'
import {
  Modal,
  Row,
  Col,
  Table,
  Select,
  Input,
  Form,
  Button,
  Popconfirm,
  message,
  Space
 } from 'antd';
 import { PlusOutlined } from '@ant-design/icons';
 import store from '../../../../store/index' 
import {request} from '../../../../utils/fetch'
const FormItem = Form.Item;
 
function ResourceManage(props) {
    let path = 'platform:SysMenu'
    const [form] = Form.useForm();
    const [tableData,setTableData] = useState([])
    const [loading, setLoading] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [titleModel, setTitleModel] = React.useState();
    const [TypeModel, setTypeModel] = React.useState();
    const [initialValues, setInitialValues] = React.useState({
        menuName:'',
        orderNum:'',
        url:'',
        menuType:'',
        perms:'',
        icon:'',
        
    });
    const [addForm, setAddForm] = React.useState({});
    const columns = [
        {
            title: '资源名称',
            dataIndex: 'menuName',
            key: 'menuName',
            align:'center',
            
        }, {
            title: '资源路径',
            dataIndex: 'url',
            key: 'url',
            align:'center',
            
        },{
            title: '权限字符串',
            dataIndex: 'perms',
            key: 'perms',
            align:'center',
            
        }, {
            title: '排序号',
            dataIndex: 'orderNum',
            key: 'orderNum',
            align:'center',
            
        },  {
            title: '资源类型',
            dataIndex: 'menuType',
            key: 'menuType',
            align:'center',
            render: (text, record) => {
                if(record.menuType == 'M'){
                    return '目录'
                }else if(record.menuType == 'C'){
                    return '菜单'
                }else if(record.menuType == 'F'){
                    return '按钮'
                }else{
                    return '栏目'
                }
            }
        }, 
        {
        title: '操作',
        key: 'action',
        align:'center',
        render: (text, record) => (
            <Space size="middle">
                {
                    store.getState().userReducer.permissions.indexOf(`${path}:add`)>-1&&record.menuType != 'F'?
                    <a onClick={() => {
                        console.log(record.menuType)
                        // setTypeModel
                        let parentId =''
                        if(record.menuType != 'C'){
                            setTitleModel('新增')
                            setTypeModel(record.menuType)
                            parentId = record.parentId || record.id
                        }else{
                            setTitleModel('新增按钮')
                            setTypeModel('F')
                            parentId = record.id
                        }
                        setVisible(true);
                        form.resetFields()
                        setAddForm({
                            parentId: parentId,
                            systemType:record.systemType,
                        })
                    }}>添加子项</a>: null
                }
                
                {store.getState().userReducer.permissions.indexOf(`${path}:update`)>-1&&<a onClick={() => {
                      setTitleModel('修改')
                      console.log(record.menuType)
                      setTypeModel(record.menuType)
                      setVisible(true);
                      form.setFieldsValue(record)
                      setAddForm({
                        parentId:record.parentId,
                        systemType:record.systemType,
                        id:record.id,
                    })
                } }>修改</a>}
                {store.getState().userReducer.permissions.indexOf(`${path}:remove`)>-1&&<Popconfirm
                    title="确定删除吗？"
                    onConfirm={() => {
                        handleDel(record)
                    }}
                    okText="确定"
                    cancelText="取消"
                    className="marginLeft"
                    >
                    <a>删除</a>
                </Popconfirm>}
            </Space>
        ),
        },
    ];
    const getTableList = async() => {
        const result = await request.get('/platform/menu/list')
        var arr = 
        [
           {
               menuName:'平台',
               id:'pt',
               parentId:'0',
               systemType:'1',
               children:[...result.platformList]
           },
           {
                menuName:'企业',
                id:'qy',
                parentId:'0',
                systemType:'2',
                children:[...result.companyList]
            }
        ]
        setTableData(arr)
    }
    const handleDel = (obj) => {
        request.get('/platform/menu/remove',{id:obj.id}).then(res => {
            getTableList()
            message.success(res.msg)
        })
    }
  
    const handleOk = async() => {
        try {
          const values = await form.validateFields();
          setLoading(true);
          if(titleModel == '新增'){
            handleAdd(values)
            return
          }else if(titleModel == '新增按钮'){
            handleAddDZ(values)
            return
          }
          handleModify(values)
         
        } catch (errorInfo) {
          console.log('Failed:', errorInfo);
        }
    }
    const handleAdd = (obj) => {
        request.post('/platform/menu/add',Object.assign(addForm,obj)).then(res => {
            setVisible(false);
            setLoading(false);
            message.success(res.msg)
            getTableList()
        }).catch(err => setLoading(false))
    }
    const handleAddDZ = (obj) => {
        let data = Object.assign(addForm,obj)
        console.log(data)
        request.post('/platform/menu/add',Object.assign(addForm,obj)).then(res => {
            setVisible(false);
            setLoading(false);
            message.success(res.msg)
            getTableList()
        }).catch(err => setLoading(false))
    }
    const handleModify = (obj) => {
        request.post('/platform/menu/edit',Object.assign(addForm,obj)).then(res => {
            setVisible(false);
            setLoading(false);
            message.success(res.msg)
            getTableList()
        }).catch(err => setLoading(false))
    }

    useEffect(() => {
        getTableList()
    },[])

    return (
        <div>
            {/* <Button style={{ marginTop: 10 }} type="primary" icon={<PlusOutlined />} onClick={() => {
                setVisible(true)
                form.resetFields()
                setTitleModel('新增')
                }
            }>新增菜单</Button> */}
            
            <Table 
                scroll={{ x: 'max-content' }}
                columns={columns} 
                rowKey={(record) => record.id}
                dataSource={tableData} 
                pagination={false}
           />
            <Modal maskClosable={false}
                forceRender
                title={titleModel}
                width='800px'
                visible={visible}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={() => {setVisible(false);}}
            >
            <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 10 }}
            initialValues={initialValues}
            form={form}
            >
                <FormItem 
                    name="menuName"
                    label="资源名称"
                    rules={[
                    {
                        required: true,
                        message: '请填写名称',
                    },
                    ]}
                    >
                    <Input placeholder=""/>
                </FormItem>
                <FormItem name="orderNum" label="排序号" >
                    <Input placeholder=""/>
                </FormItem>
                {TypeModel == 'F'?<FormItem name="perms" 
                    rules={[
                        {
                            required: true,
                            message: '请填写',
                        },
                    ]}
                    label="权限字符串" >
                    <Input placeholder=""/>
                </FormItem>:null}
                {
                    TypeModel != 'F'? <FormItem name="url" label="资源url" >
                    <Input placeholder=""/>
                </FormItem>: null
                }
                
                {
                    TypeModel != 'F'? <FormItem name="icon" label="菜单图标" >
                    <Input placeholder=""/>
                </FormItem>: null
                }
                <FormItem name="menuType"
                    rules={[
                        {
                            required: true,
                            message: '请选择类型',
                        },
                    ]}
                    label="类型"
                 >
                    <Select>
                        <Select.Option value={'M'}>目录</Select.Option>
                        <Select.Option value={'C'}>菜单</Select.Option>
                        <Select.Option value={'F'}>按钮</Select.Option>
                    </Select>
                </FormItem>
            </Form>
        </Modal>
        </div>
    )
}

export default ResourceManage
