import React, { useEffect, useState } from 'react'
import { request } from '../../../../../utils/fetch'
import { Form, Input, Select, Button, DatePicker, Row, Col, Space, InputNumber, Radio } from 'antd'
import store from '../../../../../store/index'
import moment from 'moment'
const QueryForm = (props) => {
    const { refresh, pageNum, setPageNum, activeKey } = props
    const [radio, setRadio] = useState(10)
    const [form] = Form.useForm()
    const { Option } = Select
    const { RangePicker } = DatePicker
    const labelStyleObj = {
        labelCol: { flex: '90px' },
        wrapperCol: { flex: '1 1 0' },
    }
    const itemStyle = { width: '320px', marginRight: '15px' }
    const itemStyle1 = { width: '380px' }
    const initialFormValues = { dateType: 1 }
    const Query = ()=>{
        if(pageNum===1){
            handelQuery()
        }else{
            setPageNum(1)
        }
    }
    const rangeChange = (dates) => {
        if (dates === null) {
          setRadio(10)
        }
    }
    // 时间radio选择
    const radioChange = e => {
        let startDate, endDate
        setRadio(e.target.value)
        startDate = moment().subtract(e.target.value - 1, 'days')
        endDate = moment()
        form.setFieldsValue({ date: [startDate, endDate] })
    }
    // 查询
    const handelQuery = () => {
        const data = form.getFieldsValue()
		let params ={
			tabType:activeKey,
			dateType:data.dateType,
			beginTime: data.date ? moment(data.date[0]).format('YYYY-MM-DD') : undefined,
            endTime: data.date ? moment(data.date[1]).format('YYYY-MM-DD') : undefined,
			source:data.source,
			reverseBeginDay:data.reverseBeginDay,
			reverseEndDay:data.reverseEndDay,
			filingBeginDay:data.filingBeginDay,
			filingEndDay:data.filingEndDay,
			keyword:data.keyword,
		}
        refresh('query', params)
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
    }
    // 导出
    const exportData = () => {
        const data = form.getFieldsValue()
        let params ={
			tabType:activeKey,
			dateType:data.dateType,
			beginTime: data.date ? moment(data.date[0]).format('YYYY-MM-DD') : undefined,
            endTime: data.date ? moment(data.date[1]).format('YYYY-MM-DD') : undefined,
			source:data.source,
			reverseBeginDay:data.reverseBeginDay,
			reverseEndDay:data.reverseEndDay,
			filingBeginDay:data.filingBeginDay,
			filingEndDay:data.filingEndDay,
			keyword:data.keyword,
        }

        request.downExport('/platform/contractArchives/export', params)
    }
    useEffect(() => {
        handelQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNum,activeKey])
    return (
        <>
        <Form {...labelStyleObj} initialValues={initialFormValues} form={form}>
        <Row>
          <Form.Item style={{ width: '130px', marginLeft: '38px' }} name="dateType" wrapperCol={24}>
            <Select>
              <Option value={1}>申请日期</Option>
              <Option value={2}>起始日期</Option>
              <Option value={3}>终止日期</Option>
            </Select>
          </Form.Item>
          <Form.Item name="date" >
            <RangePicker style={{ width: '280px' }} onChange={(dates) => rangeChange(dates)}  format='YYYY-MM-DD' />
          </Form.Item>
          <Form.Item style={{ width: '600px', marginLeft: '15px' }}>
            <Radio.Group onChange={radioChange} value={radio} >
              <Radio value={1}>今天</Radio>
              <Radio value={7}>近7天</Radio>
              <Radio value={30}>近30天</Radio>
            </Radio.Group>
          </Form.Item>
        </Row>
            <Row>
                <Form.Item style={itemStyle} name='source' label='来源'>
                    <Select allowClear placeholder='全部'>
                        <Option value={1}>江西万之象科技</Option>
                        <Option value={2}>厦门万之象咨询</Option>
                        <Option value={3}>江西蜂鸟抚州东乡</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle}  label='到签天数'>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
                        <div style={{display: 'flex',flex: 1}}>
                            <Form.Item name='reverseBeginDay' style={{width: '100%', marginBottom: '0px'}}>
                                <InputNumber placeholder='请输入数字' style={{width: '100%'}}  />  
                            </Form.Item>
                        </div>
                        <span style={{lineHeight: '32px',marginLeft: '6px',marginRight: '6px'}}>~</span>
                        <div style={{display: 'flex',flex: 1}}>
                            <Form.Item name='reverseEndDay' style={{width: '100%', marginBottom: '0px'}}>
                                <InputNumber placeholder='请输入数字' style={{width: '100%'}} />
                            </Form.Item>
                        </div>
                    </div>
                </Form.Item>
                <Form.Item style={itemStyle}  label='归档天数'>
                    <div style={{display: 'flex',justifyContent: 'space-between'}}>
                        <div style={{display: 'flex',flex: 1}}>
                            <Form.Item name='filingBeginDay' style={{width: '100%', marginBottom: '0px'}}>
                                <InputNumber placeholder='请输入数字' style={{width: '100%'}}  />  
                            </Form.Item>
                        </div>
                        <span style={{lineHeight: '32px',marginLeft: '6px',marginRight: '6px'}}>~</span>
                        <div style={{display: 'flex',flex: 1}}>
                            <Form.Item name='filingEndDay' style={{width: '100%', marginBottom: '0px'}}>
                                <InputNumber placeholder='请输入数字' style={{width: '100%'}} />
                            </Form.Item>
                        </div>
                    </div>
                </Form.Item>
                <Form.Item style={itemStyle1} name='keyword' label='关键字'>
                    <Input placeholder='申请人丨甲方丨乙方丨合同名称丨合同编码' />
                </Form.Item>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Space>
                        <Button type='primary' onClick={Query}>查询</Button>
                        <Button onClick={resetForm}>重置</Button>
                        <Button onClick={exportData}>导出</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    </>
    )
}

export default QueryForm
