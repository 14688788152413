import React,{useState,useEffect} from 'react'
import {
  Modal,
  Row,
  Table,
  Select,
  Input,
  Form,
  Tree,
  Button,
  Space
 } from 'antd';
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
const FormItem = Form.Item;
const itemStyle = {
    width:'300px',
    marginRight:'15px'
}
const labelStyleObj = {
   labelCol:{ span: 9},
   wrapperCol:{ span: 15}
}

function CompanyPermission(props) {
    let path ='platform:SysCompanyInfo'
    const [form] = Form.useForm();
    const [tableData,setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [initialValues, setInitialValues] = React.useState({
        keyword:''
    });
    const columns = [
        {
            title: '企业名称',
            dataIndex: 'companyName',
            key: 'companyName',
            align:'center',
            
            
        }, {
            title: '角色数',
            dataIndex: 'roleNum',
            key: 'roleNum',
            align:'center',
            
        },{
            title: '登录账号数',
            dataIndex: 'userNum',
            key: 'userNum',
            align:'center',
            
        },
        {
        title: '操作',
        key: 'action',
        align:'center',
        render: (text, record) => (
            <Space size="middle">
                {/* <a onClick={() => {props.history.push('/admin/rolePermission?detail=true&companyName='+record.companyName+'&companyCode='+record.companyCode)}}>角色明细</a> */}
                {store.getState().userReducer.permissions.indexOf(`${path}:listRoleForCompany`)>-1&&<a onClick={() => {props.history.push('/admin/companyPermission/detail2?companyName='+record.companyName+'&companyCode='+record.companyCode)}}>角色明细</a>}
                {store.getState().userReducer.permissions.indexOf(`${path}:listUserForCompany`)>-1&&<a onClick={() => {props.history.push('/admin/companyPermission/detail1?companyName='+record.companyName+'&companyCode='+record.companyCode)}}>账号明细</a>}
            </Space>
            ),
        },
    ];
   
    const methodsFn =  {
        
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(form.getFieldsValue())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/user/listForCompany',{
                keyword:searchObj.keyword,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        handleExport(){
            request.downExport('/platform/user/exportForCompany',{keyword:form.getFieldsValue().keyword})
        }
    }

    useEffect(() => {
        methodsFn.getTableList(form.getFieldsValue())
    
    },[pageNum])

    return (
        <div>
             <Form form={form} initialValues={initialValues}>
                    <Row>
                        <FormItem label="关键字"  name="keyword"  style={itemStyle} {...labelStyleObj}>
                            <Input placeholder="企业名称" />
                        </FormItem>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                            <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        </FormItem>
                    </Row>
                </Form>
            
            <Table 
                scroll={{ x: 'max-content',y:500 }}
                columns={columns} 
                dataSource={tableData} 
                rowKey={(record) => record.companyCode}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger:false,
                    showTotal:(total) =>  `共 ${total} 条数据` ,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
           />
     
        </div>
    )
}

export default CompanyPermission
