import React, { useState, useEffect, useRef } from 'react'
import { Row, Table, Select, Form, Button, message, Input, DatePicker, Upload, Modal, Space, Radio, Typography, Col, Popconfirm } from 'antd'

import axios from 'axios'
import moment from 'moment'
import { UploadOutlined } from '@ant-design/icons'
import { getCrowdStaffList, getCityListByProvinceId, deleteCrowdStaff } from '../api'
import { request } from '../../../../utils/fetch'
import EditModel from './EditModel'
import AddModel from './AddModel'
import ProveModel from './ProveModel'
import store from '../../../../store/index'
import { getTaxEnterpriseList } from '../../../../api/api'
const { Link } = Typography
const FormItem = Form.Item
const { Option } = Select
const { RangePicker } = DatePicker

// 搜索表单通用样式
const itemStyle = {
    width: '300px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}

// 上传弹窗表单样式
const formItemLayout = {
    labelCol: {
        span: 6
    },
    wrapperCol: {
        span: 14
    }
}
function CrowdsourcePersonnel(props) {
    let path = 'platform:crowdStaff'
    const [form] = Form.useForm() //搜索表单
    const [form2] = Form.useForm() //上传文件表单
    const [fileList, setFileList] = useState([]) //文件列表
    // 初始化搜索数据
    const initSearch = {
        date: []
    }
    const [dayValue, setDayValue] = useState(0) //快捷天数
    const [companyData, setCompanyData] = useState([]) //公司列表
    const [tableData, setTableData] = useState([])
    const [visible, setVisible] = useState(false) //文件上传弹窗
    const [loading, setLoading] = useState(false)
    const [cityData, setCityData] = useState([]) // 城市
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const EditModelRef = useRef()
    const AddModelRef = useRef()
    const ProveModelRef = useRef()
    const fileProps = {
        //表格上传配置和方法
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept: '.xls, .xlsx',
        beforeUpload: () => {
            return false
        },

        onChange(info) {
            let fileCon = [...info.fileList]
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1)
            setFileList(fileCon)
        }
    }
    const columns = [
        {
            title: '注册时间',
            dataIndex: 'createTime',
            width: 120,
            align: 'center'
        },
        {
            title: '姓名',
            dataIndex: 'name',
            width: 120,
            align: 'center',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            width: 80,
            align: 'center',
            render: sex => (sex === '1' ? '男' : '女')
        },
        {
            title: '身份证件类型',
            width: 150,
            align: 'center',
            render: () => '身份证'
        },
        {
            title: '身份证号',
            dataIndex: 'idNumber',
            width: 150,
            align: 'center'
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            width: 150,
            align: 'center'
        },
        {
            title: '人员类别',
            dataIndex: 'staffTypeName',
            width: 100,
            align: 'center'
        },
        {
            title: '骑手ID',
            dataIndex: 'staffId',
            width: 100,
            align: 'center'
        },
        {
            title: 'SubID',
            dataIndex: 'subId',
            width: 100,
            align: 'center'
        },
        {
            title: '纳税人识别号',
            dataIndex: 'taxpayerNo',
            width: 150,
            align: 'center'
        },
        {
            title: '跑单城市',
            dataIndex: 'cityName',
            width: 150,
            align: 'center'
        },
        {
            title: '切换时间',
            dataIndex: 'changeTime',
            width: 150,
            align: 'center'
        },
        {
            title: '企业名称',
            dataIndex: 'companyName',
            width: 150,
            align: 'center'
        },
        {
            title: 'T名称',
            dataIndex: 'tname',
            width: 150,
            align: 'center'
        },
        {
            title: '财税企业',
            dataIndex: 'taxEnterpriseName',
            width: 200,
            align: 'center'
        },
        {
            title: '落地城市区县',
            dataIndex: 'planCityCountyName',
            width: 150,
            align: 'center'
        },
        {
            title: '落地方案',
            dataIndex: 'planTypeName',
            width: 150,
            align: 'center'
        },
        {
            title: '办证状态',
            dataIndex: 'certificateStatusName',
            width: 150,
            align: 'center'
        },
        {
            title: '数据来源',
            dataIndex: 'source',
            width: 150,
            align: 'center',
            render: source => (source === 2 ? '手工录入' : '文件导入')
        },
        {
            title: '操作',
            width: 180,
            align: 'center',
            fixed: 'right',
            render: (text, record) => {
                return (
                    <Space size='middle'>
                        {permissions.includes(`${path}:update`) && <Link onClick={() => methodsFn.edit(record)}>修改</Link>}
                        {permissions.includes(`${path}:download`) && record.certificateStatus === 2 && <Link onClick={() => methodsFn.downProve(record)}>合作证明</Link>}
                        {permissions.includes(`${path}:delete`) && (
                            <Popconfirm
                                title='确定删除吗？'
                                onConfirm={() => {
                                    methodsFn.handleDel(record)
                                }}
                                okText='确定'
                                cancelText='取消'
                            >
                                <Link>删除</Link>
                            </Popconfirm>
                        )}
                    </Space>
                )
            }
        }
    ]

    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        <FormItem label='注册时间' name='date' style={{ marginLeft: '6px' }}>
                            <RangePicker onChange={methodsFn.timeChange} />
                        </FormItem>
                        <FormItem style={{ with: '600px', marginLeft: '26px' }}>
                            <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                                <Radio value={1}>今天</Radio>
                                <Radio value={7}>近7天</Radio>
                                <Radio value={30}>近30天</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Row>
                    <Row>
                        <FormItem label='跑单城市' name='cityIdList' style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder='全部'>
                                {cityData.length > 0 &&
                                    cityData.map(item => (
                                        <Option value={Number(item.cityId)} key={item.cityId}>
                                            {item.cityName}
                                        </Option>
                                    ))}
                            </Select>
                        </FormItem>
                        <FormItem label='办证状态' name='certificateStatus' style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder='全部'>
                                <Option value={1}>未办理</Option>
                                <Option value={2}>已办理</Option>
                                <Option value={3}>已注销</Option>
                            </Select>
                        </FormItem>
                        <FormItem label='落地方案' name='planType' style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder='全部'>
                                <Option value={1}>临时税务登记</Option>
                                <Option value={2}>个体工商户</Option>
                                <Option value={3}>委托代征</Option>
                            </Select>
                        </FormItem>
                        <FormItem label='人员类别' name='staffType' style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder='全部'>
                                <Option value={1}>普通</Option>
                                <Option value={2}>优选</Option>
                                <Option value={3}>001</Option>
                            </Select>
                        </FormItem>
                        <FormItem label='财税企业' name='taxEnterpriseCode' style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder='全部'>
                                {TaxEnterpriseLis.map(e => (
                                    <Option key={e.code} value={e.code}>
                                        {e.enterpriseName}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                        <FormItem label='关键字' labelCol={{ style: { width: 75 } }} wrapperCol={{ flex: 300 }} name='keyword'>
                            <Input style={{ width: 350 }} placeholder='姓名丨身份证号丨手机号丨骑手ID 丨subID丨T名称' />
                        </FormItem>
                    </Row>
                    <Row>
                        <Col span={24} md={24} lg={24}>
                            <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }}>
                                {permissions.includes(`${path}:select`) && (
                                    <Button type='primary' onClick={methodsFn.handleSearch}>
                                        查询
                                    </Button>
                                )}
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>
                                    重置
                                </Button>
                                {permissions.includes(`${path}:insert`) && (
                                    <Button style={{ marginLeft: 10 }} onClick={methodsFn.add}>
                                        新增
                                    </Button>
                                )}
                                {permissions.includes(`${path}:export`) && (
                                    <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>
                                        导出
                                    </Button>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            )
        }
    }
    //方法函数
    const methodsFn = {
        //日期变化
        timeChange() {
            setDayValue(0)
        },
        //天数变化
        dayChange(e) {
            let startDate = moment()
                .subtract(e.target.value - 1, 'd')
                .format('YYYY-MM-DD')
            let endDate = moment().format('YYYY-MM-DD')
            setDayValue(e.target.value)
            form.setFieldsValue({
                date: [moment(startDate), moment(endDate)]
            })
        },
        handleSearch() {
            setPageNum(1)
            if (pageNum == 1) {
                methodsFn.getTableList()
            }
        },
        handleFormReset() {
            form.resetFields()
            setDayValue()
        },
        getsearchObj() {
            let formObj = form.getFieldsValue()
            let beginTime = '',
                endTime = ''
            if (formObj.date.length > 1) {
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }
            let obj = {
                beginTime: beginTime,
                endTime: endTime,
                staffType: formObj.staffType,
                cityIdList: formObj.cityIdList,
                companyCodeList: formObj.companyCodeList,
                planType: formObj.planType,
                certificateStatus: formObj.certificateStatus,
                keyword: formObj.keyword,
                taxEnterpriseCode: formObj.taxEnterpriseCode
            }
            return obj
        },
        handleDel(record) {
            deleteCrowdStaff({
                id: record.id
            }).then(res => {
                if (res.data.success) {
                    methodsFn.handleSearch()
                    message.success('删除成功！')
                } else {
                    message.success(res.data.retMsg)
                }
            })
            console.log(record)
        },
        getTableList() {
            let searchObj = methodsFn.getsearchObj()
            getCrowdStaffList({
                ...searchObj,
                pageSize: pageSize,
                pageNum: pageNum
            })
                .then(res => {
                    if (res.data.success) {
                        console.log(res.data)
                        setTotal(res.data.data.totalCount)
                        setTableData(res.data.data.list)
                    } else {
                        message.error(res.data.retMsg)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async handleUp() {
            let values = await form2.validateFields()
            console.log(values)
            let data = new FormData() //通过FormData将文件转成二进制数据
            let file = values.ffile.file
            data.append('token', localStorage.getItem('token'))
            data.append('mobile', localStorage.getItem('mobile'))
            data.append('file', file)
            data.append('companyCode', values.companyCode)

            setLoading(true)

            axios({
                method: 'post',
                url: `/wispay/platform/crowdStaff/importData`,
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                    setLoading(false)
                    if (res.data.success) {
                        setVisible(false)
                        message.success(res.data.retMsg)
                        methodsFn.handleSearch()
                    } else {
                        let errArry = res.data.retMsg.split('##')
                        let node = errArry.map(item => {
                            return (
                                <span key={item}>
                                    {item}
                                    <br />
                                </span>
                            )
                        })
                        message.error(node)
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                })
        },
        // 修改
        edit(record) {
            EditModelRef.current.showModal(record)
        },
        add() {
            AddModelRef.current.showModal()
        },
        downProve(record) {
            console.log()
            ProveModelRef.current.showModal(record)
        },
        // 导出
        handleExport() {
            request.downExport('/platform/crowdStaff/export', methodsFn.getsearchObj())
        }, // 获取所有财税企业
        async getTaxEnterpriseListall() {
            const res = await getTaxEnterpriseList()
            if (res.data.success) {
                setTaxEnterpriseLis(res.data.data)
            }
        }
    }
    useEffect(() => {
        methodsFn.getTaxEnterpriseListall()
        getCityListByProvinceId().then(res => {
            if (res.data.success) {
                setCityData(res.data.data)
            }
        })
    }, [])
    useEffect(() => {
        methodsFn.getTableList()
        request.get('/platform/companyBase/allList', { projectType: 2 }).then(res => {
            setCompanyData(res)
        })
    }, [pageNum])

    return (
        <div>
            <div>
                {permissions.includes(`${path}:import`) && (
                    <div style={{ display: 'flex', background: '#fff', marginBottom: '20px', padding: '30px' }}>
                        <div>
                            <div>上传表格请使用固定的 个人导入模板</div>
                            <div>同一文件最多支持1000条，同一个企业下的身份证号不能重复上传</div>
                        </div>
                        {
                            <Button
                                type='primary'
                                style={{ marginLeft: '10px' }}
                                onClick={() => {
                                    setFileList([])
                                    form2.resetFields()
                                    setVisible(true)
                                }}
                            >
                                导入
                            </Button>
                        }
                    </div>
                )}
                {myComponent.renderSearchForm()}
                <Table
                    scroll={{ x: 'max-content', y: 500 }}
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={tableData}
                    pagination={{
                        position: ['bottomCenter'],
                        total: total,
                        current: pageNum,
                        pageSize: pageSize,
                        showSizeChanger: false,
                        showTotal: total => `共 ${total} 条数据`,
                        onChange: (pageNum, pageSize) => setPageNum(pageNum)
                    }}
                />
            </div>

            <Modal maskClosable={false}
                title='导入'
                confirmLoading={loading}
                visible={visible}
                onOk={methodsFn.handleUp}
                onCancel={() => {
                    setVisible(false)
                }}
            >
                <Form form={form2} {...formItemLayout}>
                    <Form.Item rules={[{ required: true, message: '请选择企业' }]} label='企业' name='companyCode'>
                        <Select placeholder='请选择'>
                            {companyData.map(item => {
                                return (
                                    <Option value={item.companyCode} key={item.companyCode}>
                                        {item.companyName}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='上传表格' name='ffile' rules={[{ required: true, message: '请上传表格' }]}>
                        <Upload {...fileProps}>
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                            <Button
                                type='link'
                                onClick={e => {
                                    e.stopPropagation()
                                    window.location.href = `/wispay/common/getTemplateDownloadUrl?token=${localStorage.getItem('token')}&mobile=${localStorage.getItem('mobile')}&type=PT_ZBYHDR`
                                }}
                            >
                                下载模板
                            </Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
            <EditModel ref={EditModelRef} companyData={companyData} refres={methodsFn.handleSearch} />
            <AddModel ref={AddModelRef} companyData={companyData} refres={methodsFn.handleSearch} />
            <ProveModel ref={ProveModelRef} companyData={companyData} refres={methodsFn.handleSearch} />
        </div>
    )
}

export default CrowdsourcePersonnel
