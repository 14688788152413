import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, message } from 'antd';
import axios from 'axios'
import qs from 'qs'
// 服务方反查
const ServiceInvestigate = (props) => {
  const [form] = Form.useForm();
  const [formData,setFormData] = useState({})
  let formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 14,
    },
  }

  const getData = ()=>{
    let data = qs.parse(props.history.location.search.replace(/\?/g, ''));
    axios({
      method: 'get',
      url: `/wispay/platform/reverseData/fwfInfo`,
      // url: `/platform/reverseData/fwfInfo`,
      params: data,
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      if(res.data.success){
        setFormData(res.data.data)
        form.setFieldsValue(res.data.data)
      }else{
        message.error(res.data.retMsg)
      }
    }).catch(err => {
        console.log(err)
    })
   
  }
  useEffect(() => {
    getData()
  },[])
  return (
    <>
     <Form
      {...formItemLayout}
      form={form}
      initialValues={formData}
    >
      <Row>
        <Col span={12}>
          <Form.Item name="fwfuuid" label="服务方UUID">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fwfxm" label="服务方姓名">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fwfsfzjlx" label="身份证件类型">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fwfsfzjhm" label="身份证件号码">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="yddh" label="手机号码">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="czdq" label="跑单城市">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="ssdq" label="服务方所属城市">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="ptzcsj" label="平台注册时间">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="zytgywlb" label="业务类别">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fwfipdz" label="协议">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    </Form>
    </>
  )
}

export default ServiceInvestigate