import React, { useImperativeHandle,forwardRef, useState, useEffect } from 'react'
import {Modal,Form,InputNumber,Input,Select,Row,Col,DatePicker,Upload,Button,message} from 'antd';
import {
  PlusOutlined,
} from '@ant-design/icons';
import '../index.css'
import moment from 'moment'
const { TextArea } = Input;
const {  OptGroup } = Select;
const topCol = {
  span: 12,
}

const action = "/wispay/common/uploadFile"
// const action = "http://test.zchpay.com/wispay/common/uploadFile"
const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const EditReport = (props,ref) => {
  const { refresh } = props
//   const { cityData } = props
  const [visible, setVisible] = useState(false);
  const [damage, setDamage] = useState(true);
  const [sgqy, setsgqy] = useState(false);
  const [rycl, setrycl] = useState(false);
  const [sjrdzr, setsjrdzr] = useState(false);
  const [title, setTitle] = useState('修改');
  const [disabled, setDisabled] = useState(false);
  const [DingkUser, setDingkUser] = useState([]);
  const [editData, setEditData] = useState([]);
  const [spData, setSpData] = useState([]);
  const [personList,setPersonList] =useState([])
  const [provinceList, setProvinceList] = useState([])
  const [regionalList, setRegionalList] = useState([])
  const [cityList, setCityList] = useState([])
  const [filterEventTypedetail, setFilterEventTypedetail] = useState([])
  const [idNumberd, setidNumberd] = useState()
  const [rule, setRule] = useState()
  const [riskDescribeRule, setRiskDescribeRule] = useState()
  const [advanceReasonRule, setAdvanceReasonRule] = useState()
  const [loading,setLoading] = useState(false)
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList1: '',
    fileList2: '',
    fileList3: '',
    fileList4: '',
    fileList5: '',
    fileList6: '',
    fileList7: '',
    previewTitle: '',
  })
  const [form] = Form.useForm()
  const { Option } = Select
  const initialFormValues = {}
  const [violationShow,setViolationShow] = useState(false)
  const violationChange = (value)=>{
    if(value=='18'){
      setViolationShow(true)
    }else{
      setViolationShow(false)
    }
  }
  const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU：重大刑事案件、涉及刑事或者治安案件，对公司品牌造成严重影响的</p>
      <p className="titp">P0：致人死亡或2人及以上重伤</p>
      <p className="titp">P1：致1人重伤</p>
      <p className="titp">P2：致人轻伤</p>
      <p className="titp">P3：致人轻微伤</p>
    </div>
  )
  const content2 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">案件响应时间-接报或上报时间</p>
    </div>
  )
  const content3 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">处置回复时间-案件响应时间</p>
    </div>
  )
  const content4 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">实际垫付金额 - 保险回款金额</p>
    </div>
  )
  const content5 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">家属诉求慰问金 - 实际赔付慰问金</p>
    </div>
  )
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  useEffect(() => {
  }, [])
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (ctitle, data, splist) => {
      form.resetFields()
    }
  }));
  //事件类型1
  const eventOption1 = [
    { key:'JT',name:'交通事故',},
    { key:'YW',name:'意外事故'},
    { key:'ZAXS',name:'治安刑事事件'},
    { key:'WW',name:'维稳事件'},
    { key:'XF',name:'消防事件'},
    { key:'YQ',name:'舆情事件',},
  ]
  //事件类型2，3
  const select = {
    'JT':[
      { key:'GZ',name:'工作期间' },
      { key:'FGZ',name:'非工作期间' },
    ],
    'YW':[
      { key:'YW_NYX',name:'脑溢血'},
      { key:'YW_XJGS',name:'心肌梗塞'},
      { key:'YW_GKZW',name:'高空坠物'},
      { key:'YW_DWSH',name:'动物伤害'},
      { key:'YW_NS',name:'溺水'},
      { key:'YW_CD',name:'触电'},
      { key:'ZDY',name:'自定义' }
    ],
    'ZAXS':[
      { key:'ZA',name:'治安事件'},
      { key:'XS',name:'刑事事件'},
      { key:'YB',name:'一般事件'}
    ],
    'ZA':[
      { key:'XSR',name:'性骚扰' },
      { key:'FAGW',name:'妨碍公务' }
    ],
    'XS':[
      { key:'TD',name:'偷盗' },
      { key:'DONS',name:'斗殴闹事' }
    ],
    'YB':[
      { key:'FWYC',name:'服务异常（恐吓威胁辱骂骚扰）' },
      { key:'YY',name:'扬言类' }
    ],
    'WW':[
      { key:'WW_BG',name:'罢工' },
      { key:'WW_QT',name:'群体性事件' },
      { key:'WW_GR',name:'个人极端行为' },
      { key:'WW_YH',name:'涉维稳风险隐患' },
      { key:'ZDY',name:'自定义' }
    ],
    'XF':[
      { key:'XF_FW',name:'自租房屋火灾' },
      { key:'XF_DDCHZ',name:'电动车火灾' },
      { key:'XF_DDCZR',name:'电动车自燃' },
    ]
  }
  //事件类型2
  const [eventOption2,setEventOption2] = useState([])
  //事件类型3
  const [eventOption3,setEventOption3] = useState([])
  const [isShow,setIsShow] = useState(false)
  //事件1改变时
  const eventTypeChange1 = (value,option)=>{
    form.setFieldsValue({
      eventType2:'',
      eventType3:'',
      eventTypeExplain:'',
    })
    setEventOption2([])
    setEventOption3([])
    setIsShow(false)
    if(value=='YW'){
      setEventOption2(select['JT'])
      setEventOption3(select['YW'])
    }else if(value=='YQ'){
    }else{
      setEventOption2(select[value])
    }
  }
  //事件2改变时
  const eventTypeChange2 = (value,option)=>{
    form.setFieldsValue({
      eventType3:'',
      eventTypeExplain:'',
    })
    const data = form.getFieldsValue()
    if(data.eventType1=='ZAXS'){
      setEventOption3(select[value])
    }
    if(value=='ZDY'){
      setIsShow(true)
    }
    console.log(value,option,data);
  }
  //事件3改变时
  const eventTypeChange3 = (value,option)=>{
    form.setFieldsValue({
      eventTypeExplain:'',
    })
    if(value=='ZDY'){
      setIsShow(true)
    }else{
      setIsShow(false)
    }
  }

  const caseRespondTimeChange = (value)=>{
    let a = form.getFieldValue('eventReportTime')
    let b = form.getFieldValue('dealWithTime')
    if((a&&a>=value)||(b&&value>=b)){
      form.setFieldsValue({
        caseRespondTime:''
      })
      message.error('请输入合理的上报时间')
    }
    if(form.getFieldValue('eventReportTime')&&form.getFieldValue('caseRespondTime')){
      form.setFieldsValue({
        caseRespondInterval: subtractTime(form.getFieldValue('eventReportTime'),form.getFieldValue('caseRespondTime'))
      })
    }
    if(form.getFieldValue('caseRespondTime')&&form.getFieldValue('dealWithTime')){
      form.setFieldsValue({
        dealWithInterval: subtractTime(form.getFieldValue('caseRespondTime'),form.getFieldValue('dealWithTime'))
      })
    }
  }
  const dealWithTimeChange = (value)=>{
    let a = form.getFieldValue('caseRespondTime')
    if(a&&value<=a){
      form.setFieldsValue({
        dealWithTime:''
      })
      message.error('处置回复时间要在案件响应之后')
    }
    if(form.getFieldValue('caseRespondTime')&&form.getFieldValue('dealWithTime')){
      form.setFieldsValue({
        dealWithInterval: subtractTime(form.getFieldValue('caseRespondTime'),form.getFieldValue('dealWithTime'))
      })
    }
  }
  //计算时间差
  const subtractTime = (startDate,endDate)=>{
    let time = endDate-startDate
    console.log(time);
    let days = 1000*60*60*24
    let hours = 1000*60*60
    let minutes = 1000*60
    let day =0
    let hour =0
    let minute =0
    let second = 0
    let timeStr=''
    if(time/days>1){
      day = parseInt(time/days)
      timeStr = day+'天'
    }
    if(time/hours>1){
      hour = parseInt((time-(day*days))/hours)
      timeStr = timeStr+hour+'时'
    }
    if(time/minutes>1){
      minute = parseInt((time-(day*days)-(hour*hours))/minutes)
      timeStr = timeStr+minute+'分'
    }
    if(time/1000>1){
      second = parseInt((time-(day*days)-(hour*hours)-(minute*minutes))/1000)
      timeStr = timeStr+second+'秒'
    }
    console.log(timeStr);
    return timeStr
  }
  // 下拉框
  const selectFilter = (inputValue, option) => {
    if (option.children.indexOf(inputValue) != -1) {
        return option
    }
  }

  const personChange = (value)=>{
    let row = personList.filter(item=>{
      return item.idNumber == value
    })
    console.log(row)
    form.setFieldsValue({
      name:row[0].name,
      eleCourierId: row[0].staffId,
      phone: row[0].phone,
      staffType: row[0].staffType + '',
    })
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  const handleCancel = () => {
    setPreviewData(prev => {
      return {
        ...prev,
        previewVisible: false
      }
    })
  }
  const setFlieList = (list) => {
    list = list&&list.filter(item=>{
      return item.url
    })
    list = list&&list.map((item,index)=>{
      return {
        url: item.url,
        uid: item.uid || index,
        name: item.name||item.url,
        status: 'done',
      }
    })
    return list
  }
  const setFlieParams = (list,type) => {
      list = list&&list.filter(item=>{
        return item.url
      })
      list = list&&list.map((item,index)=>{
        return {
          url: item.url,
          uid: 0-index,
          name: item.name||item.url,
          status: 'done',
          type: type
        }
      })
      return list
  }
  const changeFile = ({ file, fileList }, type) => {
    fileList = fileList.map(item => {
      if (item.response&&item.response.success) {
        item.url = item.response.data[0].url;
      }
      return item;
    });
    setPreviewData(prev => {
      const data = {...prev}
      data[type] = fileList
      return data
    })
  }
  const insureFlagChange = (value) =>{
    if(value==1){
      setRule([{ required: true, message: '请选择保险!' }])
    }else if(value==0){
      setRule([])
      form.setFieldsValue({
        insureCompany:''
      })
    }
  }
  const riskFlagChange = (value)=>{
    if(value==1){
      setRiskDescribeRule([{ required: true, message: '请填写风险描述' }])
    }else if(value==0){
      setRiskDescribeRule([])
    }
  }
  const advanceAmountChange = ()=>{
    let value = form.getFieldValue('advanceAmount')
    if(value==0){
      setAdvanceReasonRule([])
    }else{
      setAdvanceReasonRule([{ required: true, message: '请填写垫付原因' }])
    }
  }
  const policeDepartmentChange=(value)=>{
    if(value==0){
      form.setFieldsValue({
        policeDepartment:['0']
      })
    }else{
      const arr = form.getFieldValue('policeDepartment').filter(i=>i!=0)
      form.setFieldsValue({
        policeDepartment:arr
      })
    }
  }
  const comfortLossAmountChange = ()=>{
    form.setFieldsValue({
      comfortLossAmount:form.getFieldValue('familyComfortAmount')-form.getFieldValue('realityComfortAmount')
    })
  }
  const advanceLossAmountChange = ()=>{
    form.setFieldsValue({
      advanceLossAmount:form.getFieldValue('realityAdvanceAmount')-form.getFieldValue('insureCollectionAmount')
    })
  }
  const submit = ()=>{
    const formData = form.getFieldValue()
    const query = {...formData}
    form.validateFields().then((values)=>{

    }).catch(()=>{
      message.error('请完善表单')
    })
    
  }
  return (
    <>
      <Modal maskClosable={false}
        title={title}
        width='1200px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={!disabled?[
          <Button key="back" onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button
            key="link"
            // href="https://google.com"
            type="primary"
            loading={loading}
            onClick={()=>{submit()}}
          >
            保存
          </Button>,
        ]:null}
      >
        <Form initialValues={initialFormValues} form={form} labelAlign='right' className='trafficSafetyEditForm'>
          <Row className="margin-15">
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 7 }} label="当前处理进度">
                <Input disabled={true} value={form.getFieldValue('dealStatus')==1?'报备':form.getFieldValue('dealStatus')==2?'跟进':'结案'} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="reportOriginatorUserName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="报备发起人">
                <Input disabled={true} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseOriginatorUserName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="结案发起人">
                <Input disabled={true} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventSource" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件来源">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement}>
                  <Option value={1}>众包骑手上报</Option>
                  <Option value={2}>WOOS</Option>
                  <Option value={3}>保险上报（线上）</Option>
                  <Option value={4}>保险上报（线下）</Option>
                  <Option value={5}>钉钉外包人资商架构</Option>
                  <Option value={6}>交通违规 </Option>
                  <Option value={7}>客服转单（治安）</Option>
                  <Option value={8}>数字治理平台</Option>
                  <Option value={9}>算法实时产出 </Option>
                  <Option value={10}>线下上报 </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件工单号">
                <Input placeholder="请填写事件工单号" maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityId" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="注册城市">
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} 
                disabled={true}>
                  <Option value={'TJ'}>天津市</Option>
                  <Option value={'NC'}>南昌市</Option>
                  <Option value={'JJ'}>九江市</Option>
                  <Option value={'GZ'}>赣州市</Option>
                  <Option value={'JDZ'}>景德镇市</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventLevel" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} tooltip={{ title: content, color: '#FFFFFF' }} label="事件级别">
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} disabled={disabled} >
                  <Option value="ESU">ESU</Option>
                  <Option value="P0">P0</Option>
                  <Option value="P1">P1</Option>
                  <Option value="P2">P2</Option>
                  <Option value="P3">P3</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="idNumber" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="骑手姓名">
                <Select placeholder="请选择" showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)} getPopupContainer={triggerNode => triggerNode.parentElement} 
                onChange={personChange}>
                  (personList?{personList.map(item => (
                    <Option value={item.idNumber} key={item.idNumber}>{item.name}</Option>
                  ))}:[])
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eleCourierId" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="骑手ID">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="手机号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name='idNumber' labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="身份证号">
                <Input disabled={true}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="staffType" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="人员类型">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} disabled={true} >
                  <Option value={1}>普通</Option>
                  <Option value={2}>优选</Option>
                  <Option value={3}>001</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item  label="事件类型" required style={{ marginBottom: 0 }} labelCol={{ span: 3 }} 
              wrapperCol={{ span: 19 }} >
                <div style={{display:'flex',width:'100%'}}>
                  <Form.Item name="eventType1" style={{width:'18%'}}>
                    <Select placeholder="请选择"  getPopupContainer={triggerNode => triggerNode.parentElement} 
                    onChange={(value,option)=>{eventTypeChange1(value,option)}}>
                      {eventOption1.map(item => (
                        <Option value={item.key} key={item.key}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  { eventOption2.length>0 && <span >&nbsp;&nbsp;</span>}
                  { eventOption2.length>0 &&
                    <Form.Item name="eventType2" style={{width:'18%'}}>
                      <Select placeholder="请选择"  getPopupContainer={triggerNode => triggerNode.parentElement} 
                      onChange={(value,option)=>{eventTypeChange2(value,option)}}>
                        {eventOption2.map(item => (
                          <Option value={item.key} key={item.key}>{item.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  }
                  { eventOption3.length>0 && <span >&nbsp;&nbsp;</span>}
                  { eventOption3.length>0 &&
                    <Form.Item name="eventType3" style={{width:'18%'}}>
                      <Select placeholder="请选择"  getPopupContainer={triggerNode => triggerNode.parentElement} 
                      onChange={(value,option)=>{eventTypeChange3(value,option)}}>
                        {eventOption3.map(item => (
                          <Option value={item.key} key={item.key}>{item.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  }
                  { isShow && <span >&nbsp;&nbsp;</span>}
                  {isShow &&
                  <Form.Item name="eventTypeExplain" style={{width:'43%'}}>
                    <Input placeholder='自定义描述（10字以内）'></Input>
                  </Form.Item>
                  }
                </div>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insureFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="是否报保险">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} disabled={true} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insureCompany" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="保险公司">
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>平安</Option>
                  <Option value={2}>前海</Option>
                  <Option value={3}>泰康</Option>
                  <Option value={4}>太平洋</Option>
                  <Option value={5}>新职业伤害险</Option>     
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="insureOrderNo" labelCol={{ span: 3 }} wrapperCol={{ span: 7 }} label="保单号">
                <Input placeholder="请输入保单号" maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseRespondTime" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="案件响应时间">
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  onChange={value=>caseRespondTimeChange(value)}
                  showTime
                  format={["YYYY-MM-DD HH:mm:ss"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseRespondInterval" tooltip={{ title: content2, color: '#FFFFFF' }} labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="案件响应时效">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dealWithTime" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="处置回复时间">
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  showTime
                  onChange={dealWithTimeChange}
                  format={["YYYY-MM-DD HH:mm:ss"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dealWithInterval" tooltip={{ title: content3, color: '#FFFFFF' }} labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="处置回复时效">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventPlace" label="事件发生地址" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请输入事件发生地址，限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="workFlag" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="跑单时段">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="policeDepartment" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="报警部门">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} mode='multiple' placeholder="请选择"
                onSelect={value =>policeDepartmentChange(value)}>
                  <Option value={'0'}>无</Option>
                  <Option value={'1'}>派出所</Option>
                  <Option value={'2'}>交警队</Option>
                  <Option value={'3'}>消防队</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="carType" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="驾驶车辆">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value={0}>无</Option>
                  <Option value={1}>电动车</Option>
                  <Option value={2}>自行车</Option>
                  <Option value={3}>汽车(证照齐全)</Option>
                  <Option value={4}>汽车(证照不齐)</Option>
                  <Option value={5}>摩托车(证照齐全)</Option>
                  <Option value={6}>摩托车(证照不齐)</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="sensitiveFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="涉及敏感人群">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value={0}>不涉及</Option>
                  <Option value={2}>14岁以下儿童</Option>
                  <Option value={3}>65岁以上老人</Option>
                  <Option value={4}>政府要员</Option>
                  <Option value={5}>外国友人</Option>
                  <Option value={6}>军人</Option>
                  <Option value={7}>媒体</Option>
                  <Option value={8}>三期妇女</Option>
                  <Option value={9}>伤残人士</Option>
                  <Option value={10}>高知名度社会人士</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderStatus" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="骑手状态">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value={1}>死亡</Option>
                  <Option value={2}>重伤(ICU)</Option>
                  <Option value={3}>重伤(普通病房)</Option>
                  <Option value={4}>轻伤(住院) </Option>
                  <Option value={5}>轻伤(未住院)</Option>
                  <Option value={6}>无人伤</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderDescribe" label="骑手受伤情况说明" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right"  >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderWakeFlag" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="骑手是否清醒" labelAlign="right"  >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderSurgicalFlag"  labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="骑手是否需要手术" labelAlign="right"  >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderFamilyFlag"  labelCol={{ span: 3 }} 
              wrapperCol={{ span: 7 }} label="骑手家属是否到场" labelAlign="right"  >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartyStatus" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="三者状态" >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>死亡</Option>
                  <Option value={2}>重伤(ICU)</Option>
                  <Option value={3}>重伤(普通病房)</Option>
                  <Option value={4}>轻伤(住院) </Option>
                  <Option value={5}>轻伤(未住院)</Option>
                  <Option value={6}>无人伤</Option>
                  <Option value={0}>无人伤</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartySurgicalFlag"  labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="三者是否需要手术">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="threePartyDescribe" label="三者具体受伤情况及物损说明" labelCol={{ span: 3 }} 
              wrapperCol={{ span: 19 }} labelAlign="right"  >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartyDamageAmount" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="三者财产损失金额">
                <InputNumber placeholder="请填写" min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="responsibleParty"  labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="三者是否需要手术">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>待定</Option>
                  <Option value={2}>全责</Option>
                  <Option value={3}>主责</Option>
                  <Option value={4}>同责</Option>
                  <Option value={5}>次责 </Option>
                  <Option value={6}>无责</Option>
                  <Option value={7}>无需定责</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="violationType" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="违规项目">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} onChange={(value)=>{violationChange(value)}} >
                  <Option value='0'>判定无违规</Option>
                  <Option value='1'>超速驾驶(时速超过25KM/H)</Option>
                  <Option value='2'>逆行</Option>
                  <Option value='3'>闯红灯</Option>
                  <Option value='4'>骑行时使用手机</Option>
                  {/* <Option value='5'>追尾</Option>
                  <Option value='6'>肇事逃逸</Option> */}
                  <Option value='7'>酒后驾驶</Option>
                  <Option value='8'>超车不当</Option>
                  <Option value='9'>走机动车道</Option>
                  <Option value='10'>未佩戴头盔</Option>
                  <Option value='11'>转弯未让直行</Option>
                  <Option value='12'>横穿马路</Option>
                  <Option value='13'>单手驾驶</Option>
                  <Option value='14'>违规载人</Option>
                  <Option value='15'>驾驶不合格车辆</Option>
                  <Option value='16'>无证驾驶</Option>
                  <Option value='17'>无法判定</Option>
                  <Option value='18'>自定义</Option>
                </Select>
              </Form.Item>
            </Col>
            {violationShow&&<Col {...topCol}>
              <Form.Item name="violationDescribe" label="自定义违规项目" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} rules={[{ required: violationShow, message: '请输入自定义违规项目!' }]}>
                <Input placeholder="请输入" maxLength={128} />
              </Form.Item>
            </Col>}
            <Col span={24}>
              <Form.Item name="costDescribe" label="费用支出说明" labelCol={{ span: 3 }} 
              wrapperCol={{ span: 19 }} labelAlign="right"  >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riskFlag" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="是否存在潜在风险">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} onChange={values=>riskFlagChange(values)}>
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riskDescribe" label="风险描述" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" rules={riskDescribeRule}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventDescribe" label="事件经过描述" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right"  >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="requireDescribe" label="甲方要求" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <TextArea showCount maxLength={500}  autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="advanceAmount" label="预估垫付金额" labelCol={{ span: 3 }} wrapperCol={{ span: 7 }} 
              labelAlign="right" >
                <InputNumber placeholder="请填写" min={0} max={9999999999} maxLength={100} precision={2} onChange={advanceAmountChange}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="advanceReason" label="垫付原因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" rules={advanceReasonRule}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="borrowAmount" label="借款金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <InputNumber placeholder="请填写" min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="realityAdvanceAmount" label="实际垫付金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <InputNumber placeholder="请填写" onChange={advanceLossAmountChange} min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insureCollectionAmount" label="保险回款金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <InputNumber placeholder="请填写" onChange={advanceLossAmountChange} min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="chargeAgainstFlag" label="垫付是否冲销" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder='请选择'>
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="chargeAgainstReason" label="未冲销原因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="advanceLossAmount" label="垫付损失金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" tooltip={{ title: content4, color: '#FFFFFF' }}>
                <Input  disabled={true} value={form.getFieldValue('realityAdvanceAmount')-form.getFieldValue('insureCollectionAmount')} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="advanceDamageReason" label="垫付损失原因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="finalSolution" label="最终解决方案" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="familyComfortAmount" label="家属诉求慰问金" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <InputNumber placeholder="请填写" onChange={comfortLossAmountChange} min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="realityComfortAmount" label="实际赔付慰问金" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <InputNumber placeholder="请填写" onChange={comfortLossAmountChange} min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="comfortLossAmount" label="减损金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" tooltip={{ title: content5, color: '#FFFFFF' }}>
                <Input  disabled={true}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} name='scenePictureUrl' valuePropName="fileList"
                getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={{ span: 19 }} label="现场/住院照片">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  multiple
                  maxCount={10}
                  name="files"
                  fileList={previewData.fileList1}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList1')}
                >
                  {previewData.fileList1.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: 'right' }}>订单截图<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
            </Col>
            <Col span={19}>
              <Form.Item name='orderPictureUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={10}
                  multiple
                  name="files"
                  fileList={previewData.fileList2}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList2')}
                >
                  {previewData.fileList2.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: 'right' }}>责任认定书<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
            </Col>
            <Col span={19}>
              <Form.Item name='responsibleLetterUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={10}
                  multiple
                  name="files"
                  fileList={previewData.fileList3}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList3')}
                >
                  {previewData.fileList3.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} name='medicalProveUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')} 
              wrapperCol={{ span: 19 }} label="诊断证明或病历">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={10}
                  multiple
                  name="files"
                  fileList={previewData.fileList4}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList4')}
                >
                  {previewData.fileList4.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} name='comfortProtocolUrl' valuePropName="fileList" 
              getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={{ span: 19 }} label="慰问协议">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={10}
                  multiple
                  name="files"
                  fileList={previewData.fileList5}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList5')}
                >
                  {previewData.fileList5.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="otherZipUrl" label="家属资料/诉讼材料/其他" getValueFromEvent={(e) => normFile(e, 'list')}
                valuePropName="fileList"
                labelCol={{ span: 3 }} wrapperCol={{ span: 19 }}>
                <Upload name="files" data={uploadObj}
                  fileList={previewData.fileList6}
                  action={action}
                  maxCount={1}
                  listType="picture"
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList6')}
                  >
                    {previewData.fileList6.length >= 1 ? null : <Button type="primary">上传压缩包</Button>}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Modal maskClosable={false}
          width={1000}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
        </Modal>
      </Modal>
    </>
  );
};
export default forwardRef(EditReport)