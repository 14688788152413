// 线上支付
import React, { useState, useRef, useEffect } from 'react'
import { Spin, Table, Space, Button, message } from 'antd'
import ConfirmModel from './components/ConfirmModel'
import store from '../../../../store/index'
import { abcEnterpriseGetList } from './api'

const MultistageAccount = (props)=> {
    let path = 'platform:abcEnterpriseAccountConfig'
    const [tableData, setTableData] = useState([])
    const ConfirmModelRef = useRef();
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)

    const refresh = () => {
        setPageNum(1)
        getTableLists()
    }
    const getTableLists = async () => {
        let data = {
            pageNum: pageNum,
            pageSize: pageSize
        }
        abcEnterpriseGetList(data).then(res=>{
            if(res.data.success){
                setTableData(res.data.data.list || [])
                setTotal(res.data.data.totalCount)
            }else{
                message.error(res.data.retMsg)
            }
        })
    }
    const getTableData = (num)=>{
        setPageNum(num)
    }
    const onAdd = ()=>{
        ConfirmModelRef.current.showModal();
    }
    const handelView = (record)=>{
        props.history.push(`/admin/MultistageAccount/Detail?taxEnterpriseCode=${record.taxEnterpriseCode}`)
    }

    // 列表字段
    const tableColumns = [
        { title: '创建时间', dataIndex: 'createTime', key: 'createTime', align: 'center', width: 160 },
        { title: '财税企业名称', dataIndex: 'enterprisName', key: 'enterprisName', align: 'center', width: 140 },
        { title: '开户行名称', dataIndex: 'storesName', key: 'storesName', align: 'center', width: 200, render:()=>{ return '中国农业银行' } },
        { title: '银行账号', dataIndex: 'accNo', key: 'accNo', align: 'center', width: 100 },
        { title: '企业子户数', dataIndex: 'companyCount', key: 'companyCount', align: 'center', width: 100 },
        {
            title: '操作',
            key: 'control',
            fixed: 'right',
            width: 160,
            align: 'center',
            render: (record) => (
                <Space>
                    {<a key="1" onClick={() => handelView(record)}>明细</a>}
                </Space>
            )
        }
    ]
    useEffect(() => {
        getTableLists()
    }, [pageNum])
    return (
        <Spin spinning={false}>
            {store.getState().userReducer.permissions.indexOf(`${path}:insert`)>-1&&(
                <div style={{textAlign: 'right', padding: '10px 40px 10px', backgroundColor: '#fff', }} >
                    <Button type='primary' onClick={onAdd}>新增</Button>
                </div>
            )}
            <div className='tablenotop'>
                <Table 
                columns={tableColumns} 
                dataSource={tableData} 
                scroll={{ x: 'max-content', y: 520 }} 
                rowKey={(record) => record.id}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (num, pageSize) => getTableData(num),
                }} />
            </div>
            <ConfirmModel refresh={refresh}  ref={ConfirmModelRef} />
        </Spin>
    )
}
export default MultistageAccount
