import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import {
    Table,
    Space,
    message,
    Button,
    Tooltip
} from 'antd'
import '../index.css'
import { request } from '../../../../../utils/fetch'
import store from '../../../../../store/index'
import ViewMoel from './ViewMoel'
import EditMoel from './EditMoel'
import { securityArchivesList } from '../api'
const TableList = forwardRef((props,ref)=> {
    let path = 'securityEventArchives'
    const [xg] = useState(store.getState().userReducer.permissions.indexOf(`${path}:update`)>-1)
    const [tableData, setTableData] = useState([])
    const ViewMoelRef = useRef();
    const EditMoelRef = useRef();
    const [queryData, setQueryData] = useState({})
    let pageSize = 20
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: pageSize,
        total: 0
    })
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        getlist: (val) => {
            setQueryData(val)
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
            getTableLists({ ...pagination, pageNum: 1},val)
        }
    }));
    const getTableLists = async ({pageNum, pageSize},query=queryData) => {
        let result = await securityArchivesList(Object.assign({}, query, {pageNum: pageNum, pageSize: pageSize}))
        if (result.data.success) {
            setTableData(result.data.data.list)
            setPagination(prev => {
                return {
                    ...prev,
                    total: result.data.data.totalCount
                }
            })
        } else {
            message.error(result.data.retMsg)
        }
    }
    const onRefresh = ()=>{
        getTableLists(pagination)
    }
    // 列表字段
    const tableColumns = [
        { title: '更新时间', dataIndex: 'updateTime', key: 'updateTime', align: 'center', width: 120 },
        { title: '当前处理进度', dataIndex: 'dealStatusName', key: 'dealStatusName', align: 'center', width: 120 },
        { title: '事件来源', dataIndex: 'eventSourceName', key: 'eventSourceName', align: 'center', width: 110 },
        { title: '事件工单号', dataIndex: 'eventOrderNo', key: 'eventOrderNo', align: 'center', width: 160 },
        { title: '注册城市', dataIndex: 'cityName', key: 'cityName', align: 'center', width: 100,},
        { title: '报备人', dataIndex: 'reportOriginatorUserName', key: 'reportOriginatorUserName', align: 'center', width: 100 },
        { title: '事件分类', dataIndex: 'eventClassifyName', key: 'eventClassifyName', align: 'center', width: 120 },
        { title: '事件等级', dataIndex: 'eventLevel', key: 'eventLevel', align: 'center', width: 110 },
        { title: '骑手姓名', dataIndex: 'name', key: 'name', align: 'center', width: 100,},
        { title: '骑手ID', dataIndex: 'eleCourierId', key: 'eleCourierId', align: 'center', width: 100 },
        { title: '手机号', dataIndex: 'phone', key: 'phone', align: 'center', width: 120 },
        { title: '身份证号', dataIndex: 'idNumber', key: 'idNumber', align: 'center', width: 150 },
        { title: '事件发生时间', dataIndex: 'eventTime', key: 'eventTime', align: 'center', width: 120 },
        { title: '接报或上报时间', dataIndex: 'eventReportTime', key: 'eventReportTime', align: 'center', width: 130 },
        { title: '首次响应时间', dataIndex: 'firstAnswerTime', key: 'firstAnswerTime', align: 'center', width: 120 },
        { title: '督导处理意见', dataIndex: 'dealComment', key: 'dealComment', align: 'center', width: 130,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            )
        },
        { title: '客服响应时间', dataIndex: 'serverAnswerTime', key: 'serverAnswerTime', align: 'center', width: 120 },
        { title: '案件响应时效', dataIndex: 'caseResponseEffective', key: 'caseResponseEffective', align: 'center', width: 130 },
        { title: '处置回复时间', dataIndex: 'dealWithTime', key: 'dealWithTime', align: 'center', width: 120 },
        { title: '处置回复时效', dataIndex: 'dealWithTimeEffective', key: 'dealWithTimeEffective', align: 'center', width: 130 },
        { title: '事发城市', dataIndex: 'eventCityName', key: 'eventCityName', align: 'center', width: 100 },
        { title: '事件发生地址', dataIndex: 'eventPlace', key: 'eventPlace', align: 'center', width: 130,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            ) 
        },
        { title: '事件发生情景', dataIndex: 'eventScene', key: 'eventScene', align: 'center', width: 130,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            ) 
        },
        { title: '事件发生起因', dataIndex: 'eventReason', key: 'eventReason', align: 'center', width: 130 ,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            ) 
        },
        { title: '不良行为', dataIndex: 'badBehavior', key: 'badBehavior', align: 'center', width: 130 ,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            ) 
        },
        { title: '事件经过描述', dataIndex: 'eventDescribe', key: 'eventDescribe', align: 'center', width: 130 ,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            ) 
        },
        { title: '客户诉求', dataIndex: 'customerDemand', key: 'customerDemand', align: 'center', width: 130 ,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            ) 
        },
        { title: '客户诉求是否达成一致', dataIndex: 'customerDemandFlag', key: 'customerDemandFlag', align: 'center', width: 120, render: (text) => { return text?'是': '否' } },
        { title: '是否存在潜在风险', dataIndex: 'riskyFlag', key: 'riskyFlag', align: 'center', width: 100, render: (text) => { return text?'是': '否' }  },
        { title: '风险描述', dataIndex: 'riskDescribe', key: 'riskDescribe', align: 'center', width: 130,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            ) 
        },
        { title: '解决方案', dataIndex: 'solutionDescribe', key: 'solutionDescribe', align: 'center', width: 130,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            ) 
        },
        { title: '跑单时段', dataIndex: 'workFlag', key: 'workFlag', align: 'center', width: 100, render: (text) => { return text?'是': '否' }  },
        { title: '涉及敏感人群', dataIndex: 'sensitiveName', key: 'sensitiveName', align: 'center', width: 100 },
        { title: '是否报警', dataIndex: 'reportedFlag', key: 'reportedFlag', align: 'center', width: 100, render: (text) => { return text?'是': '否' }  },
        { title: '是否判责', dataIndex: 'judgedFlag', key: 'judgedFlag', align: 'center', width: 100, render: (text) => { return text?'是': '否' }  },
        { title: '涉及责任方', dataIndex: 'involvedDuty', key: 'involvedDuty', align: 'center', width: 120 },
        { title: '责任认定', dataIndex: 'dutyJudge', key: 'dutyJudge', align: 'center', width: 130 },
        { title: '骑手违规项目', dataIndex: 'riderViolateProject', key: 'riderViolateProject', align: 'center', width: 130 },
        { title: '骑手违规原因', dataIndex: 'riderViolateReason', key: 'riderViolateReason', align: 'center', width: 130,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            ) 
        },
        { title: '是否需要隐形拉灰', dataIndex: 'greyInvisibleFlag', key: 'greyInvisibleFlag', align: 'center', width: 100, render: (text) => { return text?'是': '否' }  },
        { title: '甲方要求', dataIndex: 'requireDescribe', key: 'requireDescribe', align: 'center', width: 130,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            ) 
        },
        { title: '费用支出金额', dataIndex: 'payAmount', key: 'payAmount', align: 'center', width: 100 },
        { title: '赔偿原因', dataIndex: 'compensateReason', key: 'compensateReason', align: 'center', width: 120,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            ) 
        },
        { title: '结案时间', dataIndex: 'caseFinishTime', key: 'caseFinishTime', align: 'center', width: 120 },
        { title: '结案时效', dataIndex: 'caseFinishTimeEffective', key: 'caseFinishTimeEffective', align: 'center', width: 120 },
        { title: '结案备注', dataIndex: 'caseFinishRemark', key: 'caseFinishRemark', align: 'center', width: 120,
            ellipsis: {
            showTitle: false,
            },
            render: (address) => (
            <Tooltip placement="topLeft" title={address}>
                {address}
            </Tooltip>
            ) 
        },
        { title: '结案人', dataIndex: 'caseOriginatorUserName', key: 'caseOriginatorUserName', align: 'center', width: 100 },
        {
            title: '操作',
            key: 'control',
            fixed: 'right',
            width: 160,
            align: 'center',
            render: (record) => (
                <Space align="center" >
                    {<Button className='botlink' type="link" key="1" onClick={() => handelView(record)}>查看</Button>}
                    {(!record.caseFinishTime)&&xg&&<Button className='botlink' type="link" key="2" onClick={() => handelModify(record)}>修改</Button>}
                    {<Button className='botlink' type="link" key="2" onClick={() => handelDown(record)}>下载</Button>}
                </Space>
               
            )
        }
    ]
    useEffect(() => {
        getTableLists(pagination)
    }, [])
    // 查看
    const handelView = async (record) => {
        ViewMoelRef.current.showModal(record);
    }
    // 修改
    const handelModify = async (record) => {
        EditMoelRef.current.editModal(record);
    }
    // 下载
    const handelDown = (record) => {
        let params = {
            archivesNo: record.archivesCode,
            name: record.name,
            idNumber: record.idNumber,
            eventReportTime: record.eventReportTime,
        }
        request.downExport('/platform/eventDownLoad/download', params)
    }
    //分页改变
    const tablePageChange = (pageNum, pageSize) => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: pageNum
            }
        })
        getTableLists({ pageSize: pageSize, pageNum: pageNum })
    }
    return (
        <>
            <Table
                columns={tableColumns}
                dataSource={tableData}
                scroll={{ x: '100%', y: 460 }}
                rowKey={record => record.archivesCode}
                pagination={{
                    position: ['bottomCenter'],
                    showSizeChanger: false,
                    total: pagination.total,
                    current: pagination.pageNum,
                    pageSize: pagination.pageSize,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => tablePageChange(pageNum, pageSize)
                }}
            />
            <ViewMoel ref={ViewMoelRef} />
            <EditMoel refresh={onRefresh} ref={EditMoelRef} />
        </>
    )
})
export default TableList
