import React,{ useImperativeHandle, useEffect, forwardRef } from 'react'
import { 
  Modal,
  Input,
  Form,
  message,
  Select
} from 'antd';
import { getTaxEnterpriseList } from '../../../../../api/api'
import { insert } from '../api'
const FormItem = Form.Item;
const ConfirmModel = (props, ref) => {
  const { Option } = Select
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [TaxEnterpriseLis, setTaxEnterpriseLis] = React.useState([])
  const initialValues = {}

   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (val) => {
      setVisible(true);
      form.resetFields()
    }
  }));
  useEffect(() => {
    getTaxEnterpriseListall()
  }, [])
  // 获取所有财税企业
  const getTaxEnterpriseListall = async ()=>{
    const res = await getTaxEnterpriseList()
    if (res.data.success) {
        setTaxEnterpriseLis(res.data.data)
    }
  }
  const methodsFn =  {
    handleOk: async() => {
      try {
        const values = await form.validateFields();
        setLoading(true);
        methodsFn.handleAdd(values)
      } catch (errorInfo) {
        console.log('Failed:', errorInfo);
      }
    },
    handleAdd(obj){
      console.log(obj)
      let itemCode = TaxEnterpriseLis.filter(e=>{
        return e.code === obj.taxEnterpriseCode
      })
      console.log(itemCode)
      let data = {
        ...obj,
        enterprisName: itemCode[0].enterpriseName
      }
      insert(data).then(res=>{
        if(res.data.success){
          setVisible(false);
          setLoading(false);
          message.success(res.msg)
          props.refresh()
        }else{
          setLoading(false)
          message.error(res.data.retMsg)
        }
      }).catch(err => setLoading(false))
    },
  }

  return (
    <>
      <Modal maskClosable={false}
        forceRender
        title='确认支付'
        width='600px'
        visible={visible}
        onOk={methodsFn.handleOk}
        confirmLoading={loading}
        onCancel={() => {setVisible(false);}}
      >
        <Form
          labelCol={{ flex: '100px' }}
          wrapperCol={{ span: 15 }}
          initialValues={initialValues}
          form={form}
        >
          <FormItem name="taxEnterpriseCode" label="财税企业" >
              <Select allowClear placeholder='请选择财税企业'>
                {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
              </Select>
          </FormItem>
          <FormItem label="开户行名称" >
              <Input disabled value={'中国农业银行'} />
          </FormItem>
          <FormItem name="accNo" label="银行账号" >
              <Input  />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};
export default forwardRef(ConfirmModel)



