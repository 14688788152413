import { request } from '../utils/fetch'

/* 公共api接口 */
/*
 * 城市接口
 */
export function getCityList() {
    return request.get('/common/getCityList')
}

/*
 * 落地区县
 */
export const getCountyList = (cityId) => {
    return request.get('/common/getCountyList', cityId)
}

/*
 * 城市/区县
 */
export const getCityCountyList = () => {
    return request.get('/common/getCityCountyList')
}

// 获取所有企业
export const getRelevanceCompany = (params) => {
    return request.get('/platform/companyBase/allList', params)
}

// 获取企业余额
export const getCompanyBalances = (params) => {
    return request.get('/platform/companyBase/getCompanyBalances', params)
}

// 服务费，税费查询接口
export const getPlateBalances = (params) => {
    return request.get('/platform/financialdeal/getBalances', params)
}
// 获取钉钉审批二维码
export const getCodeUrl = (params) => {
    return request.requestGet('/platform/dingtalkUser/getCodeUrl', params)
}
// 获取钉钉审批节点
export const getProcessTask = (params) => {
    return request.requestGet('/common/getProcessTask', params)
}
// 获取附件列表
export const getFileList = (params) => {
    return request.requestGet('/common/getFileList', params)
}
// 识别营业执照
export const verBusinessLicense = (params) => {
    return request.requestPost('/common/verBusinessLicense', params)
}
// 获取所有财税企业
export const getTaxEnterpriseList = (params) => {
    return request.requestGet('/common/getTaxEnterpriseList', params)
}
export const getCompletedTaxEnterpriseList = (params) => {
    return request.requestGet('/common/getCompletedTaxEnterpriseList', params)
}
export const getCompanyList = (params) => {
    return request.requestGet('/platform/abcEnterpriseAccountDeal/getCompanyList', params)
}
// 根据入驻企业获取财税企业
export const getListByCompanyCode = (params) => {
    return request.get('/platform/taxEnterprise/getListByCompanyCode', params)
}
// 省份城市区县
export const getAreaList = (params) => {
    return request.requestGet('/common/getAreaList', params)
}
// 获取钉钉账户列表
export const getDingtalkUser = (params)　=> {
    return request.requestGet('/platform/dingtalkUser/selectDingtalkUser', params)
}
