import React, { useImperativeHandle, useState, useEffect, forwardRef } from 'react'
import {
  Modal,
  Form,
  Input,
  Select,
  Space,
  Row,
  Col,
  DatePicker,
  Upload,
  Button,
  message,
  Timeline
} from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons';
import moment from 'moment'
import { getAccidentStaff, getAccidentNo, getDingtalkUser, addAccidentCase, updateAccidentCase } from '../../api'
import { getCodeUrl } from '../../../../../api/api'

import '../index.css'
const { TextArea } = Input;
const {  OptGroup } = Select;
const topCol = {
  span: 12,
}
const action = "/wispay/common/uploadFile"
const colSpan = {
  labelCol: { span: 8 }, wrapperCol: { span: 13 }
}
const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const AddMoel = (props,ref) => {
  const { refresh } = props
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('新增');
  const [disabled, setDisabled] = useState(false);
  const [idData, setIddata] = useState();
  const [spData, setSpData] = useState([]);
  const [editData, setEditData] = useState({});
  const [NailQRcode, setNailQRcode] = useState(null);
  const [accidentNolist, setaccidentNolist] = useState([]);
  const [idNumberd, setidNumberd] = useState()
  const [DingkUser, setDingkUser] = useState([]);
  const [renyunData, setRenyunData] = useState([])
  const [disabledyy, setdisabledyy] = useState(true)
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList1: '',
    fileList2: '',
    fileList3: '',
    fileList4: '',
    previewTitle: '',
  })
  const [form] = Form.useForm()
  const { Option } = Select
  const initialFormValues = {}
  const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU：重大刑事案件、涉及刑事或者治安案件，对公司品牌造成严重影响的</p>
      <p className="titp">P0：交通事件致人死亡或2人及以上重伤</p>
      <p className="titp">P1：交通事件致1人重伤</p>
      <p className="titp">P2：交通事件致人轻伤</p>
      <p className="titp">P3：交通事件致人轻微伤</p>
    </div>
  )
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  const getUser = () => {
    getDingtalkUser().then(res => {
      if (res.data.success) {
        setDingkUser(res.data.data)
      }
    })
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  // 上传图片，把图片地址存入 licenseFileUrl
  const changeFile = ({ file, fileList }, type) => {
    fileList = fileList.map(item => {
      if (item.response&&item.response.success) {
        // Component will show file.url as link
        item.url = item.response.data[0].url;
      }
      return item;
    });
    switch (type) {
      case 'files1':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList1: fileList
          }
        })
        break;
      case 'files2':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList2: fileList
          }
        })
        break;
      case 'files3':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList3: fileList
          }
        })
        break;
      case 'files4':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList4: fileList
          }
        })
        break;
      default:
    }
  }
  const setFlieList = (list) => {
    list = list&&list.filter(item=>{
      return item.url
    })
    list = list&&list.map((item,index)=>{
      return {
        url: item.url,
        uid: item.uid || index,
        name: item.name||item.url,
        status: 'done',
      }
    })
    return list
  }
  const setFlieParams = (list,type) => {
      list = list&&list.filter(item=>{
        return item.url
      })
      list = list&&list.map((item,index)=>{
        return {
          url: item.url,
          uid: 0-index,
          name: item.name||item.url,
          status: 'done',
          type: type
        }
      })
      return list
  }
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (ctitle, data, splist) => {
      form.resetFields()
      setPreviewData({
        previewVisible: false,
        previewImage: null,
        fileList1: '',
        fileList2: '',
        fileList3: '',
        fileList4: '',
        previewTitle: '',
      })
      setVisible(true);
      setTitle(ctitle)
      setdisabledyy(true)
      setidNumberd(null)
      if(ctitle == '查看'){
        setDisabled(true)
      }else{
        setDisabled(false)
        getPersonList()
      }
      if(splist){
        setSpData(splist)
      }else{
        setSpData([])
      }
      if (data) {
        console.log(data)
        setEditData(data)
        data.transferRecord = setFlieList(data.transferRecord)
        data.compensationAgreement = setFlieList(data.compensationAgreement)
        data.otherUrl = setFlieList(data.otherUrl)
        data.otherNotPictureUrl = setFlieList(data.otherNotPictureUrl) 
        data.tName = data.tname
        data.accidentMonth = moment(moment(data.accidentDate) ).format('YYYY年MM月')
        setIddata({
          cityId: data.cityId,
          companyCode: data.companyCode,
          reportedUserId: data.reportedUserId,
        })
        setidNumberd(data.idNumber)
        form.setFieldsValue(data)
        setidNumberd(data.idNumber)
        if(ctitle == '查看'){
          setRenyunData([{idNumber:data.idNumber,name: data.name}])
        }else{
          getPersonList()
        }
        if(data.processInstanceId){
          getQRcode(data)
        }else{
          setNailQRcode('')
        }
        if(!data.lossAmount){
          setdisabledyy(true)
        }else{
          setdisabledyy(false)
        }
      } else {
        setEditData({})
        form.resetFields()
      }
    }
  }));
  // 获取钉钉审批二维码
  const getQRcode = async (row)=>{
    let result = await getCodeUrl({processInstanceId:row.processInstanceId})
    if (result.data.success) {
      setNailQRcode(result.data.data)
    } else {
      message.error(result.data.retMsg)
    }
  }
  const selectFilter = (inputValue, option) => {
    if (option.children.indexOf(inputValue) != -1) {
      return option
    }
  }
  const selectFilter2 = (inputValue, option) => {
  if (option.children&&option.children.includes(inputValue)) {
    return option
  }
}
  // 获取人员
  const getPersonList = async () => {
    const res = await getAccidentStaff()
    if (res.data.success) {
      setRenyunData(res.data.data||[])
    }
  }
  const handleInsert = async (params) => {
    let result = await addAccidentCase(params)
    if(result.data.success){
      message.success(result.data.retMsg)
      setVisible(false)
      refresh('query', {})
    }else{
      message.error(result.data.retMsg)
    }
  }
  const handleUpdate = async (params) => {
    params.caseNo = editData.caseNo
    let result = await updateAccidentCase(params)
    if(result.data.success){
      message.success(result.data.retMsg)
      setVisible(false)
      refresh('query', {})
    }else{
      message.error(result.data.retMsg)
    }
  }
  const handleOk = (submitType) => {//addAccidentCase
    form.validateFields().then((valus)=>{
      const data = form.getFieldsValue()
      data.transferRecord = setFlieParams(data.transferRecord,'G01')
      data.compensationAgreement = setFlieParams(data.compensationAgreement,'G02')
      data.otherUrl = setFlieParams(data.otherUrl,'G03')
      data.otherNotPictureUrl = setFlieParams(data.otherNotPictureUrl,'G04') 
      data.caseDate = moment(data.caseDate).format('YYYY-MM-DD')
      let params = Object.assign(data, { submitType: submitType },idData)
      if(title=='新增'){
        handleInsert(params)
      }else{
        handleUpdate(params)
      }
    }).catch(()=>{
      message.error('请完善表单')
    })
    
  }
  const onChange = (e, v) => {
    console.log(e)
    console.log(v)
  }
  const handleCancel = () => {
    setPreviewData(prev => {
      return {
        ...prev,
        previewVisible: false
      }
    })
  }
  const renyuanChange = async (value)=>{
    form.setFieldsValue({
      accidentNo: null
    })
    setdisabledyy(true)
    setidNumberd(null)
    setIddata({
        cityId: null,
        companyCode: null,
        reportedUserId: null,
      })
    form.setFieldsValue({
      accidentDate: null,
      accidentMonth: null,
      phone: null,
      reportedUserIdName: null,
      cityIdName: null,
      lossAmount: null,
      lossCausesType: null,
      accidentLevel: null,
      companyName: null,
      tName: null,
      staffType: null,
    })
    let result = await getAccidentNo({idNumber: value});
    console.log(result)
    if (result.data.success) {
      setaccidentNolist(result.data.data||[])
    }
  }
  const accidentNoChange = async (value)=>{
    let row = accidentNolist.filter(item=>{
      return item.accidentNo == value
    })
    setidNumberd(row[0].idNumber)
    console.log(row)
    setIddata({
        cityId: row[0].cityId,
        companyCode: row[0].companyCode,
        reportedUserId: row[0].reportedUserId,
      })
    form.setFieldsValue({
      accidentDate: row[0].accidentDate,
      accidentMonth: row[0].accidentDate? moment(row[0].accidentDate).format('YYYY年MM月') : null,
      phone: row[0].phone,
      reportedUserIdName: row[0].reportedUserIdName,
      cityIdName: row[0].cityIdName,
      regionalName: row[0].regionalName,
      provinceName: row[0].provinceName,
      lossAmount: row[0].lossAmount||0,
      lossCausesType: row[0].lossCausesType,
      accidentLevel: row[0].accidentLevel,
      companyName: row[0].companyName,
      tName: row[0].tname,
      staffType: row[0].staffType + '',
    })
    if(!row[0].lossAmount){
      setdisabledyy(true)
    }else{
      setdisabledyy(false)
    }
  }
  useEffect(() => {
    getUser()
    
  }, [])
  return (
    <>
      <Modal maskClosable={false}
        title={title}
        width='1000px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={!disabled?[
          <Button key="back" onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={()=>{handleOk(1)}}>
          暂存
        </Button>,
        <Button
          key="link"
          // href="https://google.com"
          type="primary"
          onClick={()=>{handleOk(2)}}
        >
          提交审批
        </Button>,
        ]:null}
      >
        <Form initialValues={initialFormValues} form={form}>
          <Row className="margin-15">
            <Col {...topCol}>
              <Form.Item name="originatorUserId" rules={[{ required: true, message: '请选择结案人!' }]}  {...colSpan} label="结案人">
                <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement}
                showSearch filterOption={(inputValue, option) => selectFilter2(inputValue, option)} >
                  {DingkUser.map(item => (
                    <OptGroup label={item.name} key={item.name}>
                        {
                            item.list.map((citem)=>(
                                <Option value={citem.userId} key={citem.userId}>{citem.name}</Option>
                            ))
                        }
                    </OptGroup>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>

            </Col>
            <Col {...topCol}>
              <Form.Item name="idNumber" rules={[{ required: true, message: '请选择事件人员!' }]} {...colSpan} label="事件人员">
                <Select showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)} getPopupContainer={triggerNode => triggerNode.parentElement} disabled={disabled} onChange={(value) => renyuanChange(value)} placeholder="请选择" >
                  (renyunData?{renyunData.map(item => (
                    <Option value={item.idNumber} key={item.idNumber}>{item.name}</Option>
                  ))}:[])
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentNo" rules={[{ required: true, message: '请选择事件编号!' }]} {...colSpan} label="事件编号">
              <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} onChange={(value) => accidentNoChange(value)} >
                (accidentNolist?{accidentNolist.map(item => (
                    <Option value={item.accidentNo} key={item.accidentNo}>{item.accidentNo}</Option>
                  ))}:[])
                  </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentDate" {...colSpan} label="事件日期">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentMonth" {...colSpan} label="事件月">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" {...colSpan} label="手机号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item {...colSpan} label="身份证号">
                <Input disabled={true} value={idNumberd} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="reportedUserIdName" {...colSpan} label="事件报备人">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="regionalName" {...colSpan} label="大区">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="provinceName" {...colSpan} label="省份">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityIdName" {...colSpan} label="城市">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="companyName" {...colSpan} label="企业名称">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="tName" {...colSpan} label="T名称">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="staffType" {...colSpan} label="人员类别">
                {/* <Input disabled={true} /> */}
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} disabled={true} >
                  <Option value='1'>普通</Option>
                  <Option value='2'>优选</Option>
                  <Option value='3'>001</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentLevel" rules={[{ required: true, message: '请选择事件级别!' }]} {...colSpan} tooltip={{ title: content, color: '#FFFFFF' }} label="事件级别">
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} disabled={disabled} >
                  <Option value="ESU">ESU</Option>
                  <Option value="P0">P0</Option>
                  <Option value="P1">P1</Option>
                  <Option value="P2">P2</Option>
                  <Option value="P3">P3</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseDate" rules={[{ required: true, message: '请选择结案日期!'}]} {...colSpan} label="结案日期">
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  disabled={disabled}
                  allowClear={true}
                  onChange={onChange}
                  format={["YYYY-MM-DD"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}></Col>
            <Col {...topCol}>
              <Form.Item name="caseAmount" {...colSpan} label="结案金额">
                <Input placeholder="请输入结案金额" disabled={disabled} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insuranceClaimAmount" {...colSpan} label="保险理赔金额">
                <Input placeholder="请输入保险理赔金额" disabled={disabled} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="lossAmount" {...colSpan} tooltip={{ title: '公司当前损失金额=合计公司赔偿/垫支金额 - 合计回款金额' }} label="公司当前损失金额">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="lossCausesType" {...colSpan} label="造成当前实损原因">
                <Input disabled={disabledyy||disabled} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="msg" labelCol={{ span: 4 }} wrapperCol={{ span: 19 }} label="结案说明">
                <TextArea disabled={disabled} style={{width:'calc(97% + 2px)'}} showCount maxLength='500' rows='4' />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='transferRecord' valuePropName="fileList"
                getValueFromEvent={(e) => normFile(e, 'list')} 
                labelCol={{ span: 4 }} wrapperCol={{ span: 19 }} label="转账记录/保险理赔凭据">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={5}
                  disabled={disabled}
                  name="files"
                  fileList={previewData.fileList1}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'files1')}
                >
                  {previewData.fileList1.length >= 5 ? null : uploadButton}
                </Upload>
               
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='compensationAgreement' valuePropName="fileList"
                getValueFromEvent={(e) => normFile(e, 'list')} 
                labelCol={{ span: 4 }} wrapperCol={{ span: 19 }} label="赔偿协议">
                <Upload
                  action={action}
                  accept='.jpg,.jpeg,.png'
                  listType="picture-card"
                  maxCount={5}
                  disabled={disabled}
                  data={uploadObj}
                  name="files"
                  fileList={previewData.fileList2}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'files2')}
                >
                  {previewData.fileList2.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='otherUrl' valuePropName="fileList"
                getValueFromEvent={(e) => normFile(e, 'list')} 
                 labelCol={{ span: 4 }} wrapperCol={{ span: 19 }} label="其他">
                <Upload
                  action={action}
                  accept='.jpg,.jpeg,.png'
                  listType="picture-card"
                  data={uploadObj}
                  name="files"
                  maxCount={5}
                  disabled={disabled}
                  fileList={previewData.fileList3}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'files3')}
                >
                  {previewData.fileList3.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name='otherNotPictureUrl' valuePropName="fileList"
                getValueFromEvent={(e) => normFile(e, 'list')}
                label="其他(非图片)"
                disabled={disabled}
                labelCol={{ span: 4 }} wrapperCol={{ span: 19 }}
              >
                <Upload name="files" 
                data={uploadObj} 
                action={action} 
                maxCount={5}
                fileList={previewData.fileList4}
                onChange={({ file, fileList }) => changeFile({ file, fileList }, 'files4')} listType="picture">
                  {previewData.fileList4.length >= 5 ? null : !disabled&&<Button type="primary">上传</Button>}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              {(editData.processStatus=='2'||editData.processStatus=='3'||editData.processStatus=='4')&&disabled&&NailQRcode?<div style={{marginBottom: '20px'}} >
                <h2 className="equip-h2">钉钉审批二维码</h2>
                <div>
                  <img style={{width: '150px', height: '150px'}} src={NailQRcode}  />
                </div>
              </div>:null}
            </Col>
            <Col span={24}>
              {spData.length > 0&&disabled&&<div style={{marginBottom: '20px'}} >
                <h2 className="equip-h2">审批节点</h2>
                <Timeline>
                {spData.map((item,index) => (
                    <Timeline.Item key={index}>
                      {!item.afterStatus&&<span>{item.auditUserName} 发起 {item.time}</span>}
                      {item.afterStatus==3&&<span>{item.auditUserName} 通过 {item.time}</span>}
                      {item.afterStatus==3&&item.auditNote&&<span>理由： {item.auditNote}</span>}
                      {item.afterStatus == 4&&<span>{item.auditUserName} 拒绝 {item.time}</span>}
                      {item.afterStatus == 4&&item.auditNote&&<span>理由： {item.auditNote}</span>}
                    </Timeline.Item>
                  ))}
                </Timeline>
              </div>}
            </Col>
          </Row>
        </Form>
        <Modal maskClosable={false}
          width={1000}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
        </Modal>
      </Modal>
    </>
  );
};
export default forwardRef(AddMoel)



