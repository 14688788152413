import React, { useImperativeHandle, useState, forwardRef } from 'react'
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
} from 'antd';
import { securityEventFollow } from '../api'
import '../index.css'
const { TextArea } = Input;
const topCol = {
  span: 12,
}
const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU:1.重大刑事案件;2.涉及刑事案件对公司品牌造成严重影响</p>
      <p className="titp">P0:1.普通刑事案件对公司造成不良品牌影响;2.殴打政府工作人员</p>
      <p className="titp">P1:涉及普通刑事犯罪,未造成不良品牌影响造成不良品牌影响治安事件</p>
      <p className="titp">P2:未造成不良影响的治安案件</p>
      <p className="titp">P3:扬言要杀人跳楼自杀等事件辱骂威胁骚扰等其他案件</p>
    </div>
  )
const FollowMoel = forwardRef((props,ref) => {
  const { refresh } = props
  const [visible, setVisible] = useState(false);
  const [eventCode, setEventCode] = useState('');
  const [form] = Form.useForm()
  const initialFormValues = {}

  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (record) => { 
      form.resetFields()
      form.setFieldsValue(record)
      setEventCode(record.eventCode)
      setVisible(true)
    }
  }));
  // 跟进
  const handleFollow = async (data) => {
      let result = await securityEventFollow(data)
      if (result.data.success) {
        message.success(result.data.retMsg)
        setVisible(false)
        refresh('query', {})
      } else {
        message.error(result.data.retMsg)
      }
  }
  const handleOk = async (submitType) => {
    form.validateFields().then(async (valus)=>{
      const data = {
        eventCode: eventCode,
        followDescribe: form.getFieldsValue().followDescribe
      } 
      handleFollow(data)
    }).catch(()=>{
      message.error('请完善表单')
    })
  }
  return (
    <>
      <Modal maskClosable={false}
        title="跟进"
        width='1000px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={[
          <Button key="back" onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button key="link" type="primary" onClick={()=>{handleOk(2)}}>
            提交
          </Button>
        ]}
      >
        <Form initialValues={initialFormValues} className="addform" form={form}>
          <Row>
            <Col {...topCol}>
              <Form.Item name="eventSourceName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件来源"  >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件工单号" >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventReportTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="接报或上报时间" >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityName" label="注册城市" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventClassifyName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件分类">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventLevel" label="事件级别" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} tooltip={{ title: content, color: '#FFFFFF' }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="name" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="骑手姓名">
                 <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eleCourierId" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="骑手ID">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="手机号">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="idNumber" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="身份证号">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件发生时间" >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发城市">
                <div style={{display: 'flex', justifyContent: 'space-between'}} >
                  <Form.Item style={{ marginBottom: 0, width: '100px' }} name="eventProvinceName" >
                    <Input disabled />
                  </Form.Item>
                  <span style={{display: 'inline-block',height: '32px', lineHeight: '32px', margin: '0 5px'}} >-</span>
                  <Form.Item style={{flex: '1', marginBottom: 0}} name="eventCityName" >
                    <Input disabled />
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
            {/* <Col {...topCol}>
              <Form.Item name="eventRegionName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发区" >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventStreetName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发街道" >
                <Input disabled={true} />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item name="dealComment" label="督导处置意见" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea autoSize={{ minRows: 2}} disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventDescribe" label="事件经过描述" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount disabled autoSize={{ minRows: 2}} maxLength={500} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="customerDemand" label="客户诉求" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="followDescribe" label="跟进描述" rules={[{ required: true,message: '请填写跟进描述!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写跟进描述,限500字" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
});
export default FollowMoel



