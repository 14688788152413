import React, { useState, useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  Image,
  Radio,
  message,
} from 'antd'
import { staffKeep } from '../../api'

const { TextArea } = Input
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
}
const VideoAuditModal = (props) => {
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { visible, close, modalData, refresh, title, auditType } = props
  const initialFormValues = {
    name: '',
    idNumber: '',
    cityName: '',
    countyName: '',
    planTypeName: '',
    relevanceCompanyName: ''
  }
  const [auditData, setAuditData] = useState({
    auditResult: 1,
    refusedReason: ''
  })
  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name: modalData.name,
        idNumber: modalData.idNumber,
        cityName: modalData.cityName,
        countyName: modalData.countyName,
        planTypeName: modalData.planTypeName,
        relevanceCompanyName: modalData.relevanceCompanyName,
        transactVideoUrl: modalData.transactVideoUrl,
        cancelVideoUrl: modalData.cancelVideoUrl,
      })
      setAuditData({
        auditResult: 1,
        refusedReason: ''
      })
    }
  }, [visible, modalData])

  const auditChange = (value) => {
    setAuditData(prev => {
      return {
        ...prev,
        auditResult: value
      }
    })
  }
  const refuseChange = (value) => {
    setAuditData(prev => {
      return {
        ...prev,
        refusedReason: value
      }
    })
  }
  const onCancel = () => {
    close()
  }
  const handleOk = async() => {
    try {
      if (auditData.auditResult === 2 && !auditData.refusedReason) {
        message.warning('请填写拒绝理由')
        return
      }
      setConfirmLoading(true)
      const res = await staffKeep({
        auditType: auditType,
        auditResult: auditData.auditResult,
        refusedReason: auditData.refusedReason,
        keepNo: modalData.keepNo
      })
      console.log(res)
      if (res.data.success) {
        message.success('提交成功')
        close()
        refresh()
      } else {
        message.error(res.data.retMsg)
      }
      setConfirmLoading(false)
    } catch(err) {
      setConfirmLoading(false)
    }
  }
  return ( 
    <>
      <Modal maskClosable={false} title={title} visible={visible} confirmLoading={confirmLoading} onCancel={onCancel} onOk={handleOk} width={740}>
        <Form form={form} {...formItemLayout} initialValues={initialFormValues}>
          <Form.Item label="姓名" name="name">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="身份证号" name="idNumber">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="落地城市" name="cityName">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="落地区县" name="countyName">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="落地方案" name="planTypeName">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="财税企业" name="taxEnterpriseName">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="成立日期" name="establishDate">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="注销视频">
            <video key={modalData.cancelVideoUrl} id="video1" width="420" height="240" controls>
              <source src={modalData.cancelVideoUrl} type="video/mp4" />
              您的浏览器不支持 HTML5 video 标签。
            </video>
          </Form.Item>
        </Form>
        <div style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '14px'}}>审核操作</div>
        <div style={{display: 'flex', marginBottom: '14px'}}>
          <span style={{width: '100px', textAlign: 'right'}}>审核结果：</span>
          <Radio.Group onChange={(e) => auditChange(e.target.value)} value={auditData.auditResult}>
            <Radio value={1}>审核通过</Radio>
            <Radio value={2}>审核拒绝</Radio>
          </Radio.Group>
        </div>
        <div style={{display: 'flex'}}>
          <span style={{width: '100px', textAlign: 'right'}}>说明：</span>
          <TextArea maxLength={200} onChange={(e) => refuseChange(e.target.value)} value={auditData.refusedReason} />
        </div>
      </Modal>
    </>
  )
}

export default VideoAuditModal