import React, { useImperativeHandle, useState, forwardRef } from 'react'
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Upload,
  message,
  InputNumber
} from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons';
import moment from 'moment'
import { subtractTime } from '../../../../../utils/auth'
import { securityEventgetDetail, securityEventFinish, securityEventReFinish } from '../api'
import '../index.css'
const { TextArea } = Input;
const topCol = {
  span: 12,
}
const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU:1.重大刑事案件;2.涉及刑事案件对公司品牌造成严重影响</p>
      <p className="titp">P0:1.普通刑事案件对公司造成不良品牌影响;2.殴打政府工作人员</p>
      <p className="titp">P1:涉及普通刑事犯罪,未造成不良品牌影响造成不良品牌影响治安事件</p>
      <p className="titp">P2:未造成不良影响的治安案件</p>
      <p className="titp">P3:扬言要杀人跳楼自杀等事件辱骂威胁骚扰等其他案件</p>
    </div>
  )
const shixiaocz = <div className="titbox">处置回复时间-客服响应时间</div>
const shixiao = <div className="titbox">客服响应时间-首次响应时间</div>
const shixiaojz = <div className="titbox">结案时间-接报或上报时间</div>

const CaseMoel = forwardRef((props,ref) => {
  const { refresh } = props
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('结案');
  const [recordData, setRecordData] = useState({});
  const [isReported, setIsReported] = useState()
  const [isJudged, setIsJudged] = useState()
  const [isRisky, setIsRisky] = useState()
  const [amount, setAmount] = useState()
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileListpf: '',
    previewTitle: '',
  })
  const [form] = Form.useForm()
  const initialFormValues = {}
  const { Option } = Select
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )
  // const action = "http://test.zchpay.com/wispay/common/uploadFile"
  const action = "/wispay/common/uploadFile"
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (record,rtitle,type) => {
      form.resetFields()
      setTitle(rtitle) 
      setVisible(true)
      getDetail(record,type)
    }
  }));
  const getDetail = async (record,type)=>{
    let result = await securityEventgetDetail({eventCode:record.eventCode, type: type})
    if (result.data.success) {
      let data = result.data.data
      data.firstAnswerTime = data.firstAnswerTime? moment(data.firstAnswerTime): null
      data.serverAnswerTime = data.serverAnswerTime? moment(data.serverAnswerTime): null
      data.dealWithTime = data.dealWithTime? moment(data.dealWithTime): null
      data.caseFinishTime = data.caseFinishTime? moment(data.dealWithTime): null
      data.attachmentUrl = setFlieList(data.attachmentUrl)
      setRecordData(data)
      setIsReported(data.reportedFlag)
      setIsRisky(data.riskyFlag)
      setIsJudged(data.judgedFlag)
      setAmount(data.payAmount)
      form.setFieldsValue(data)
    } else {
      message.error(result.data.retMsg)
    }
  }
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  // 上传图片，把图片地址存入 licenseFileUrl
  const changeFile = ({ file, fileList }, type) => {
    fileList = fileList.map(item => {
      if (item.response&&item.response.success) {
        // Component will show file.url as link
        item.url = item.response.data[0].url;
      }
      return item;
    });
    setPreviewData(prev => {
        return {
          ...prev,
          fileListpf: fileList
        }
    })
  }
  const handleCancel = () => {
    setPreviewData(prev => {
      return {
        ...prev,
        previewVisible: false
      }
    })
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  const setFlieList = (list) => {
    list = list&&list.filter(item=>{
      return item.url
    })
    list = list&&list.map((item,index)=>{
      return {
        url: item.url,
        uid: item.uid || index,
        name: item.name||item.url,
        status: 'done',
      }
    })
    return list
  }
  const setFlieParams = (list,type) => {
    list = list&&list.filter(item=>{
      return item.url
    })
      list = list&&list.map((item,index)=>{
        return {
          url: item.url,
          uid: 0-index,
          name: item.name||item.url,
          status: 'done'
        }
      })
      return list
  }
  // 结案
  const handleCase = async (params) => {
    let data = params
    data.attachmentUrl = setFlieParams(data.attachmentUrl)
    data.eventSource = recordData.eventSource
    data.eventOrderNo = recordData.eventOrderNo
    data.eventCode = recordData.eventCode
    data.cityId = recordData.cityId
    data.eventType = 2
    data.eventClassify = recordData.eventClassify
    data.eventLevel = recordData.eventLevel
    data.eventProvinceId = recordData.eventProvinceId
    data.eventCityId = recordData.eventCityId
    data.workFlag = recordData.workFlag
    data.sensitiveFlag = recordData.sensitiveFlag
    data.reportOriginatorUserId = recordData.reportOriginatorUserId
    let result = await securityEventFinish(params)
    if (result.data.success) {
      message.success(result.data.retMsg)
      setVisible(false)
      refresh('query', {})
    } else {
      message.error(result.data.retMsg)
    }
  }
  // 重新结案
  const handleReCase = async (params) => {
    let data = params
    data.attachmentUrl = setFlieParams(data.attachmentUrl)
    data.eventSource = recordData.eventSource
    data.eventOrderNo = recordData.eventOrderNo
    data.relationEventCode = recordData.relationEventCode
    data.eventCode = recordData.eventCode
    data.cityId = recordData.cityId
    data.eventType = 2
    data.eventClassify = recordData.eventClassify
    data.eventLevel = recordData.eventLevel
    data.eventProvinceId = recordData.eventProvinceId
    data.eventCityId = recordData.eventCityId
    data.workFlag = recordData.workFlag
    data.sensitiveFlag = recordData.sensitiveFlag
    data.reportOriginatorUserId = recordData.reportOriginatorUserId
    let result = await securityEventReFinish(params)
    if (result.data.success) {
      message.success(result.data.retMsg)
      setVisible(false)
      refresh('query', {})
    } else {
      message.error(result.data.retMsg)
    }
  }
  const handleOk = (submitType) => {
    form.validateFields().then((valus)=>{
      const data = form.getFieldsValue()
      let params = Object.assign(data, { type: submitType})
      params.firstAnswerTime = params.firstAnswerTime ? moment(params.firstAnswerTime).format('YYYY-MM-DD HH:mm:ss') : null
      params.serverAnswerTime = params.serverAnswerTime ? moment(params.serverAnswerTime).format('YYYY-MM-DD HH:mm:ss') : null
      params.dealWithTime = params.dealWithTime ? moment(params.dealWithTime).format('YYYY-MM-DD HH:mm:ss') : null
      params.caseFinishTime = params.caseFinishTime ? moment(params.caseFinishTime).format('YYYY-MM-DD HH:mm:ss') : null
      if(title=='结案'){
        handleCase(params)
      }else{
        handleReCase(params)
      }
    }).catch(()=>{
      message.error('请完善表单')
    })
  }
  const finishChange = (value)=>{
    let eventReportTime = moment(form.getFieldsValue().eventReportTime);
    if(value){
      const diff1 = subtractTime(eventReportTime,value)
      form.setFieldsValue({
        caseFinishTimeEffective: diff1
      })
    }else{
      form.setFieldsValue({
        caseFinishTimeEffective: ''
      })
    }
  }
  const disabledDatesc = (current) => {
    let dates = form.getFieldsValue().serverAnswerTime
    if (!dates) {
      return false;
    }
    return current>dates&&current!=dates;
  };
  const disabledDatexy = (current) => {
    let dates = form.getFieldsValue().firstAnswerTime
    if (!dates) {
      return false;
    }
    return current<dates;
  };
  const disabledDatecz = (current) => {
    let dates = form.getFieldsValue().serverAnswerTime
    if (!dates) {
      return false;
    }
    return current<dates;
  };
  const disabledDateja = (current) => {
    let dates = moment(form.getFieldsValue().eventReportTime)
    if (!dates) {
      return false;
    }
    return current<dates;
  };
  // 响应时间变化
  const AnswerChange = (inputValue) => {
    const firstAnswerTime = form.getFieldsValue().firstAnswerTime;
    const serverAnswerTime = form.getFieldsValue().serverAnswerTime;
    const dealWithTime = form.getFieldsValue().dealWithTime;

    // 案件响应时间
    if(serverAnswerTime&&firstAnswerTime){
      const diff1 = subtractTime(firstAnswerTime,serverAnswerTime)
      form.setFieldsValue({
        caseResponseEffective: diff1
      })
    }else{
      form.setFieldsValue({
        caseResponseEffective: ''
      })
    }
    //  处置回复时间
    if(serverAnswerTime&&dealWithTime){
      const diff2 = subtractTime(serverAnswerTime,dealWithTime)
      form.setFieldsValue({
        dealWithTimeEffective: diff2
      })
    }else{
      form.setFieldsValue({
        dealWithTimeEffective: ''
      })
    }
  }
  return (
    <>
      <Modal maskClosable={false}
        title={title}
        width='1000px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={[
            <Button key="back" onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={()=>{handleOk(0)}}>
            暂存
          </Button>,
          <Button key="link" type="primary" onClick={()=>{handleOk(1)}}>
            提交审批
          </Button>
        ]}
      >
        <Form initialValues={initialFormValues} className="addform" form={form}>
          <Row>
            <Col {...topCol}>
              <Form.Item name="eventSourceName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件来源"  >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件工单号" >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventReportTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="接报或上报时间" >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityName" label="注册城市" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventClassifyName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件分类">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventLevel" label="事件级别" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} tooltip={{ title: content, color: '#FFFFFF' }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="name" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="骑手姓名">
                 <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eleCourierId" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="骑手ID">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="手机号">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="idNumber" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="身份证号">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件发生时间" >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发城市">
                <div style={{display: 'flex', justifyContent: 'space-between'}} >
                  <Form.Item style={{ marginBottom: 0, width: '100px' }} name="eventProvinceName" >
                    <Input disabled />
                  </Form.Item>
                  <span style={{display: 'inline-block',height: '32px', lineHeight: '32px', margin: '0 5px'}} >-</span>
                  <Form.Item style={{flex: '1', marginBottom: 0}} name="eventCityName" >
                    <Input disabled />
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
            {/* <Col {...topCol}>
              <Form.Item name="eventRegionName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发区" >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventStreetName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发街道" >
                <Input disabled={true} />
              </Form.Item>
            </Col> */}
            <Col {...topCol}>
              <Form.Item name="firstAnswerTime" labelCol={{ span: 6 }} rules={[{ required: true,message: '请选择首次响应时间!' }]} wrapperCol={{ span: 13 }} label="首次响应时间" >
                <DatePicker
                  showTime
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  disabledDate={disabledDatesc}
                  format={["YYYY-MM-DD HH:mm:ss"]} 
                  onChange={(value) => { AnswerChange(value)}} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="serverAnswerTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="客服响应时间" rules={[{ required: true,message: '请选择客服响应时间!' }]} >
                <DatePicker
                  showTime
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  disabledDate={disabledDatexy}
                  format={["YYYY-MM-DD HH:mm:ss"]} 
                  onChange={(value) => { AnswerChange(value)}}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseResponseEffective" label="案件响应时效" tooltip={{ title: shixiao, color: '#FFFFFF' }} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled={true}  />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="dealComment" label="督导处置意见" rules={[{ required: true,message: '请填写督导处置意见!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写督导处置意见,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dealWithTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="处置回复时间" rules={[{ required: true,message: '请选择处置回复时间!' }]} >
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  showTime
                  disabledDate={disabledDatecz}
                  format={["YYYY-MM-DD HH:mm:ss"]}
                  onChange={(value) => { AnswerChange(value)}} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dealWithTimeEffective" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="处置回复时效" tooltip={{ title: shixiaocz, color: '#FFFFFF' }} >
                <Input disabled={true}  />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventPlace" label="事件发生地址" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventScene" label="事件发生情景" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventReason" label="事件发生起因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="badBehavior" label="不良行为" rules={[{ required: true,message: '请填写不良行为!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventDescribe" label="事件经过描述" rules={[{ required: true,message: '请填写事件经过描述!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写事件经过描述,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="workFlag"  label="跑单时段" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }}>
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="sensitiveName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="涉及敏感人群">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="reportedFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="是否报警" rules={[{ required: true,message: '请选择是否报警!' }]} >
                <Select placeholder="请选择" onChange={(value) => { setIsReported(value) }} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="judgedFlag" label="是否判责" rules={[{ required: isReported=='1',message: '请选择是否判责!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Select placeholder="请选择" onChange={(value) => { setIsJudged(value) }} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="involvedDuty" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="涉及责任方" rules={[{ required: isJudged=='1',message: '请填写涉及责任方!' }]} >
                <Input placeholder="请填写涉及责任方" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dutyJudge" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="责任认定" rules={[{ required: isJudged=='1',message: '请填写责任认定!' }]} >
                <Input placeholder="请填写责任认定" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="customerDemand" label="客户诉求" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="customerDemandFlag" className='labelwarp' labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="客户诉求是否达成一致" rules={[{ required: true,message: '请选择客户诉求是否达成一致!' }]} >
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riskyFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="是否有潜在风险" rules={[{ required: true,message: '请选择是否有潜在风险!' }]}>
                <Select placeholder="请选择" onChange={(value) => { setIsRisky(value)}} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riskDescribe" label="风险描述" rules={[{ required: isRisky=='1', message: '请填写风险描述!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写风险描述,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="solutionDescribe" label="解决方案" rules={[{ required: true,message: '请填写解决方案!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写解决方案,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderViolateProject" label="骑手违规项目" rules={[{ required: true,message: '请填写骑手违规项目!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input placeholder="请填写骑手违规项目" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderViolateReason" label="骑手违规原因" rules={[{ required: true,message: '请填写骑手违规原因!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input placeholder="请填写骑手违规原因" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...topCol}>
              <Form.Item name="greyInvisibleFlag" className='labelwarp' label="是否需要隐形拉灰" rules={[{ required: true,message: '请选择是否需要隐形拉灰!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="requireDescribe" label="甲方要求" rules={[{ required: true,message: '请填写甲方要求!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="payAmount" label="费用支出金额" rules={[{ required: true,message: '请填写费用支出金额!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <InputNumber onChange={(value)=>{ setAmount(value) }} style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="compensateReason" label="赔偿原因" rules={[{ required: amount>0,message: '请填写赔偿原因!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写赔偿原因,限500字"  />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseFinishTime" label="结案时间" rules={[{ required: true,message: '请选择结案时间!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  showTime
                  disabledDate={disabledDateja}
                  format={["YYYY-MM-DD HH:mm:ss"]}
                  onChange={(value) => { finishChange(value)}} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseFinishTimeEffective" label="结案时效" tooltip={{ title: shixiaojz, color: '#FFFFFF' }} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="caseFinishRemark" label="结案备注" rules={[{ required: true,message: '请填写结案备注!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写结案备注,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div style={{marginBottom: '20px'}} >
                <h2 className="equip-h2">附件</h2>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item name='attachmentUrl' label="赔付凭证" rules={[{ required: amount>0,message: '请上传赔付凭证!' }]} valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  name="files"
                  multiple
                  fileList={previewData.fileListpf}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'files1')}
                >
                  {previewData.fileListpf.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal maskClosable={false}
          width={1000}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
      </Modal>
    </>
  );
});
export default CaseMoel



