import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Form, Input, message, Table, Row, Col, } from 'antd'

import { getCompanyAccountDetail, getRelationInfo } from '../../api.js'


const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

const ShowModal = (props,ref) => {
    const [form] = Form.useForm()
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [companyBaseList, setCompanyBaseList] = useState([])
    const [visible, setvisible] = useState(false)
    const [companyData, setCompanyData] = useState({})
    const tableColumns = [
        { title: '企业名称', dataIndex: 'companyName', key: 'companyName', align: 'center' }
    ]


    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        showModal: (record) => {
            setCompanyData(record)
            console.log(record)
            form.setFieldsValue({
                companyName: record.companyName,
                taxEnterpriseName: record.taxEnterpriseName,
                certificateNumber: record.certificateNumber,
                bankName: record.accountStatus == 'FAIL' ? record.bankName : record.openBankName,
                bankNumber: record.accountStatus == 'FAIL' ? record.bankNumber : record.baseAccountId,
                mblNo: record.mblNo,
                mobileConfirmStatusName: record.mobileConfirmStatus === '01' ? '未确权' : '已确权'
            })
            setvisible(true)
            // getDetail(record)
            getInfo(record)
          }
    }));
    // useEffect(() => {
    //     if (visible) {
    //         getDetail()
    //         getInfo()
    //     }
    // }, [visible])

    const getDetail = async(record) => {
        try {
            const result = await getCompanyAccountDetail({companyCode: record.companyCode})
            if (result.data.success) {
                // setDetail(result.data.data)
                form.setFieldsValue({
                    companyName: result.data.data.companyName,
                    taxEnterpriseName: result.data.data.taxEnterpriseName,
                    certificateNumber: result.data.data.certificateNumber,
                    bankName: result.data.data.accountStatus == 'FAIL' ? result.data.data.bankName : result.data.data.openBankName,
                    bankNumber: result.data.data.accountStatus == 'FAIL' ? result.data.data.bankNumber : result.data.data.baseAccountId,
                    mblNo: result.data.data.mblNo,
                    mobileConfirmStatusName: result.data.data.mobileConfirmStatus === '01' ? '未确权' : '已确权'
                })
            } else {
                message.error(result.data.retMsg)
            }
        } catch(err) {
            console.log('__银行子户详情', err)
        }
    }
    const getInfo = async (record) => {
        setCompanyBaseList([])
        let result = await getRelationInfo({accountCompanyCode: record.companyCode,taxEnterpriseCode: record.taxEnterpriseCode})
        if (result.data.success) {
            console.log(result.data.data)
            setCompanyBaseList(result.data.data)
        } else {
            message.error(result.data.retMsg)
        }
    }
    const onCancel = () => {
        setvisible(false)
    }
    const handleOk = () => {

    }


    return (
        <Modal maskClosable={false}
            title="基础信息" 
            visible={visible} 
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            onOk={handleOk}
            width={800}>
            <Form {...formItemLayout} form={form}>
                <Row gutter={0}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item name="taxEnterpriseName" rules={[{required: false}]} label="财税企业">
                            <Input disabled placeholder="请输入" />
                        </Form.Item>
                    </Col>
                   
                    <Col className="gutter-row" span={12}>
                        <Form.Item name="companyName" rules={[{required: false}]} label="子账号企业">
                            <Input disabled placeholder="请输入" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item name="certificateNumber" rules={[{required: false}]} label="统一社会信用代码">
                            <Input disabled  placeholder="请输入" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item name="bankName" rules={[{required: false}]} label="开户行名称">
                            <Input disabled placeholder="请输入" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item name="bankNumber" rules={[{required: false}]} label="银行账号">
                            <Input disabled placeholder="请输入" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item name="mblNo" rules={[{required: false}]} label="确权手机号">
                            <Input disabled placeholder="请输入" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item name="mobileConfirmStatusName" rules={[{required: false}]} label="确权状态">
                            <Input disabled placeholder="请输入" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div style={{fontSize: '16px', fontWeight: 'bold'}}>关联的企业</div>
            <Table
                columns={tableColumns}
                dataSource={companyBaseList}
                scroll={{y: 350 }}
                rowKey={record=>record.id}
                pagination={false}>
            </Table>
        </Modal>
    )
}

export default forwardRef(ShowModal)