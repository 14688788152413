import React, { useState, useEffect } from 'react'
import { Spin, Tabs, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons'
import QueryForm from './component/QueryForm'
import ToBeFiled from './component/ToBeFiled'
import Archived from './component/Archived'
import DueSoon from './component/DueSoon'
import DiesVenit from './component/DiesVenit'
import { getContractArchivesList, getTabTotal } from '../api'
// 合同档案
const ContractFile = (props) => {
    const [pageNum, setPageNum] = useState(1)
    const [total, setTotal] = useState(0)
    const [tableData, setTableData] = useState([])
    const [quers, setQuers] = useState({})
    const [totalData, setTotalData] = useState({tab1: 0, tab2: 0, tab3: 0, tab4: 0})
    const [activeKey,setActiveKey] = useState('1')
    const refresh = (val,params) => {
        switch (val) {
            case 'query':
                setQuers(params)
                getTotal(params)
                getList(params)
                break;
            default:
                getList(quers)
        }
    }

    const getList = (params) => {
        getContractArchivesList({ ...params, pageSize: 20, pageNum: pageNum }).then(res => {
            console.log(res)
            if(res.data.success){
                setTableData(res.data.data.list)
                setTotal(res.data.data.totalCount)
            }else{
                setTableData([])
                setTotal(0)
            }
          })
    }
    const getTotal = (params)=> {
        getTabTotal(params).then(res=>{
            if(res.data.success){
                setTotalData(res.data.data)
            }
        })
    }

    const onTabsChange = (value) => setActiveKey(value)
    useEffect(() => {
        getTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
    <Spin spinning={false}>
        <QueryForm refresh={refresh} pageNum={pageNum} setPageNum={setPageNum} activeKey={activeKey}/>
        <div className='tablenotop' style={{ backgroundColor: '#fff' }} >
            <Tabs defaultActiveKey="1" tabBarStyle={{ paddingLeft: '20px', marginBottom: '2px' }} onChange={onTabsChange}>
                <Tabs.TabPane tab={`待归档(${totalData.tab1})`} key="1">
                    <ToBeFiled pageNum={pageNum} refresh={refresh} setPageNum={setPageNum} total={total} tableData={tableData} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={`已归档(${totalData.tab2})`} key="2">
                    <Archived pageNum={pageNum} refresh={refresh} setPageNum={setPageNum} total={total} tableData={tableData} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={
                    <span>
                        <span style={{ marginRight: '5px' }}>{`即到期(${totalData.tab3})`}</span>
                        <Tooltip placement="topLeft" title={'距终止日期≤60天'}>
                            <QuestionCircleFilled style={{ marginRight: '0px' }} />
                        </Tooltip>
                    </span>} key="3">
                    <DueSoon pageNum={pageNum} refresh={refresh} setPageNum={setPageNum} total={total} tableData={tableData} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={`已到期(${totalData.tab4})`} key="4">
                    <DiesVenit pageNum={pageNum} refresh={refresh} setPageNum={setPageNum} total={total} tableData={tableData} />
                </Tabs.TabPane>
            </Tabs>
        </div>
        
    </Spin>
  )
}

export default ContractFile