import React, { useState, useEffect } from 'react'
import { request } from '../../../../../utils/fetch'
import { Form, Input, Select, Button, DatePicker, Radio, Row, Col, Space } from 'antd'
import store from '../../../../../store/index'
import { getRelevanceCompany } from '../../../../../api/api'
import moment from 'moment'
import  eventTypedetail from '../../eventTypedetail'
const QueryForm = (props) => {
    const { refresh, cityData } = props
    let path = 'accidentArchives'
    const [form] = Form.useForm()
    const { Option } = Select
    const { RangePicker } = DatePicker
    const labelStyleObj = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 },
    }
    const itemStyle = { width: '340px', marginRight: '15px' }
    const initialFormValues = {
        date: null,
        type: '1',
        status: undefined, // 状态
        countyIdList: undefined,
        cityIdList: undefined,
        planType: undefined, // 落地方案
        certificationStatusList: '', //认证状态
        signStatusList: '', // 签署状态
        accountStatus: '',
        companyCodeList: undefined,
        source: undefined,
        keyword: '', // 关键字
    }
    const [dataObj, setDataObj] = useState({})
    const [radio, setRadio] = useState(10)
    const [countyData, seteCountyData] = useState([])
    const [companyData, setCompanyData] = useState([])
    const [filterEventTypedetail, setFilterEventTypedetail] = useState([])
    const getIndexData = () => {
        //    request.get('/platform/index/indexData').then(res => {
        //     setDataObj(res)
        //    })
    }

    useEffect(() => {
        getCompany()
        getIndexData()
    }, [])
    // 下拉框
    const selectFilter = (inputValue, option) => {
        if (option.children.indexOf(inputValue) != -1) {
            return option
        }
    }
    // 时间radio选择
    const radioChange = (e) => {
        let startDate, endDate
        setRadio(e.target.value)
        startDate = moment()
            .subtract(e.target.value - 1, 'days')
            .startOf('day')
        endDate = moment()
        form.setFieldsValue({ date: [startDate, endDate] })
    }
    const rangeChange = (dates) => {
        if (dates === null) {
            setRadio(10)
        }
    }
    // 获取企业
    const getCompany = async () => {
        const res = await getRelevanceCompany({ projectType: 2 })
        console.log(res)
        if (res && res.length > 0) {
            setCompanyData(res)
        }
    }
    const handelQuery = () => {
        console.log(form.getFieldsValue())
        let params = form.getFieldsValue()
        const date = form.getFieldsValue().date
        let data = {
            beginTime: date ? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date ? moment(date[1]).format('YYYY-MM-DD') : '',
        }
        delete params.date
        refresh('query', Object.assign(params, data))
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
        setRadio('')
    }
    // 批量下载
    const handExport = () => {
        refresh('rows')
        console.log('批量下载')
    }
    // 导出
    const exportData = () => {
        console.log(form.getFieldsValue())
        const date = form.getFieldsValue().date
        let data = form.getFieldsValue()
        let params = {}
        Object.keys(data).forEach((key) => {
            if (data[key]) {
                if (key == 'date') {
                    params['beginTime'] = date ? moment(date[0]).format('YYYY-MM-DD') : ''
                    params['endTime'] = date ? moment(date[1]).format('YYYY-MM-DD') : ''
                } else {
                    params[key] = data[key]
                }
            }
        })
        request.downExport('/platform/accidentArchives/export', params)
    }
    const eventTypeChange = (value) => {
        setFilterEventTypedetail(eventTypedetail[value])
        form.setFieldsValue({
            accidentCauseTypeList:[]
        })
    }
    return (
        <Form {...labelStyleObj} initialValues={initialFormValues} form={form}>
            <Row>
                <Col span={24} style={{ display: 'flex', paddingLeft: '30px' }}>
                    <Form.Item style={{ marginBottom: 0 }} wrapperCol={{ span: 24 }}>
                        <Form.Item style={{ display: 'inline-block', width: '110px' }} name='type'>
                            <Select>
                                <Option value='1'>事件日期</Option>
                                <Option value='2'>更新时间</Option>
                                <Option value='3'>结案日期</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block', width: '310px' }} name='date'>
                            <RangePicker
                                allowClear={true}
                                onChange={(dates) => {
                                    rangeChange(dates)
                                    setRadio(10)
                                }}
                            />
                        </Form.Item>
                    </Form.Item>
                    <Radio.Group style={{ height: '32px', alignItems: 'center', display: 'flex', marginLeft: '10px' }} onChange={radioChange} value={radio}>
                        <Radio value={1}>今天</Radio>
                        <Radio value={7}>近7天</Radio>
                        <Radio value={30}>近30天</Radio>
                    </Radio.Group>
                </Col>
                <Form.Item style={itemStyle} name='cityIdList' label='城市名称'>
                    <Select allowClear placeholder='全部'>
                        {cityData.length > 0 &&
                            cityData.map((item) => (
                                <Option value={Number(item.cityId)} key={item.cityId}>{item.cityName}</Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='companyCode' label='企业名称'>
                    <Select placeholder='全部' allowClear showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)}>
                        {companyData.map((item) => (
                            <Option value={item.companyCode} key={item.companyCode}>
                                {item.companyName}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='staffType' label='人员类型'>
                    <Select allowClear placeholder='全部'>
                        <Option value='1'>普通</Option>
                        <Option value='2'>优选</Option>
                        <Option value='3'>001</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='accidentHandlingStageTypeList' label='处理阶段'>
                    <Select allowClear mode='multiple' placeholder='全部'>
                        <Option value='-1'>处理中</Option>
                        <Option value='5'>暂冻结处理</Option>
                        <Option value='6'>已结案</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='accidentLevelList' label='事件级别'>
                    <Select allowClear mode='multiple' placeholder='全部'>
                        <Option value='ESU'>ESU</Option>
                        <Option value='P0'>P0</Option>
                        <Option value='P1'>P1</Option>
                        <Option value='P2'>P2</Option>
                        <Option value='P3'>P3</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='eventTypeList' label='事件类别'>
                    <Select placeholder='全部'  onChange={(value)=>{eventTypeChange(value)}}>
                        <Option value='JT'>交通</Option>
                        <Option value='XF'>消防</Option>
                        <Option value='ZA'>治安</Option>
                        <Option value='WW'>维稳</Option>
                        <Option value='YW'>意外</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='accidentCauseTypeList' label='事件起因'>
                    <Select allowClear mode='multiple' placeholder='全部'>
                    {
                          filterEventTypedetail.map((item)=>{
                            return(
                                <Option value={item.id} key={item.id}>{item.name}</Option>
                            )
                          })
                      }
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='propertyTypeList' label='财产伤害'>
                    <Select allowClear mode='multiple' placeholder='全部'>
                        <Option value='0'>对方财产</Option>
                        <Option value='1'>我方财产</Option>
                        <Option value='2'>双方财产</Option>
                        <Option value='3'>无财产伤害</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='damageTypeList' label='人身伤害'>
                    <Select allowClear mode='multiple' placeholder='全部'>
                        <Option value='0'>对方人身</Option>
                        <Option value='1'>我方人身</Option>
                        <Option value='2'>双方人身</Option>
                        <Option value='3'>无人身伤害</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='degreeInjuryTypeList' label='人身伤害程度'>
                    <Select allowClear mode='multiple' placeholder='全部'>
                        <Option value='0'>轻伤</Option>
                        <Option value='1'>中度伤</Option>
                        <Option value='2'>重伤</Option>
                        <Option value='4'>死亡</Option>
                        <Option value='5'>无</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='accidentLiabilityTypeList' label='事件认定责任'>
                    <Select allowClear placeholder='全部'>
                        <Option value='0'>对方全责</Option>
                        <Option value='1'>对方主责</Option>
                        <Option value='2'>双方同责</Option>
                        <Option value='3'>我方全责</Option>
                        <Option value='4'>我方主责</Option>
                        <Option value='5'>责任待定</Option>
                        <Option value="6">其他</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='insuranceCompanyTypeList' label='保险公司'>
                    <Select allowClear placeholder='全部'>
                        <Option value='0'>平安</Option>
                        <Option value='1'>国泰</Option>
                        <Option value='2'>太平洋</Option>
                        <Option value='3'>无商业保险</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='disableGradeTypeList' label='伤残等级'>
                    <Select allowClear placeholder='全部'>
                        <Option value='1'>1级</Option>
                        <Option value='2'>2级</Option>
                        <Option value='3'>3级</Option>
                        <Option value='4'>4级</Option>
                        <Option value='5'>5级</Option>
                        <Option value='6'>6级</Option>
                        <Option value='7'>7级</Option>
                        <Option value='8'>8级</Option>
                        <Option value='9'>9级</Option>
                        <Option value='10'>10级</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='riderVehicleTypeList' label='人员车辆'>
                    <Select allowClear placeholder='全部'>
                        <Option value='1'>电动车</Option>
                        <Option value='2'>摩托车</Option>
                        <Option value='0'>其他</Option>
                    </Select>
                </Form.Item>

                <Form.Item labelCol={{ span: 5 }} style={{ width: '500px' }} name='keyword' label='关键字'>
                    <Input placeholder='事件人员姓名丨事件人员身份证号丨事件编号丨T名称' />
                </Form.Item>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Space>
                        {store.getState().userReducer.permissions.indexOf(`${path}:select`) > -1 && (
                            <Button type='primary' onClick={handelQuery}>
                                查询
                            </Button>
                        )}
                        <Button onClick={resetForm}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`) > -1 && <Button onClick={exportData}>导出</Button>}
                        {store.getState().userReducer.permissions.indexOf(`${path}:download`) > -1 && <Button onClick={handExport}>批量下载</Button>}
                    </Space>
                </Col>
            </Row>
        </Form>
    )
}

export default QueryForm
