import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Form, Input, Modal, Row, Col, Typography, DatePicker, Button, Select, message, InputNumber} from "antd";
import moment from 'moment';
import { getContractArchivesDetail ,updateContractArchives} from '../../api'

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography
const ConfigModal = forwardRef((props,ref)=>{
    const {refresh} = props
    const [title, setTitle] = useState('修改');
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm()
    const initialValues = {}
    useImperativeHandle(ref, () => ({
        editData: (record) => {
            getContractArchivesDetail({contractNo:record.contractNo}).then(res=>{
                form.setFieldsValue({
                    ...res.data.data,
                    filingFlag: res.data.data.filingFlag === 1 ? '已归档' : '未归档',
                    contractStartDate: res.data.data.contractStartDate? moment(res.data.data.contractStartDate): null,
                    contractEndDate: res.data.data.contractEndDate? moment(res.data.data.contractEndDate): null,
                    applyDate: res.data.data.applyDate? moment(res.data.data.applyDate): null,
                })
            
            })
            setTitle('修改')
            setVisible(true)
        },
    }));
    const onFinish = (values) => {
       console.log(values);
       let params = {
        ...values,
        filingFlag: values.filingFlag === '已归档' ? 1 : 0,
        contractStartDate: values.contractStartDate? values.contractStartDate.format('YYYY-MM-DD'):null,
        contractEndDate: values.contractEndDate? values.contractEndDate.format('YYYY-MM-DD'):null,
        applyDate: values.applyDate? values.applyDate.format('YYYY-MM-DD'):null,
       }

       updateContractArchives(params).then(res=>{
            if(res.data.success){
                message.success('修改成功！')
                form.resetFields()
                setVisible(false)
                refresh(1)
            }
       })
    }
    
    const onCancel = () =>{
        setVisible(false)
        form.resetFields()
    }
    
    return (
      <Modal maskClosable={false}
        title={title} 
        visible={visible} 
        width={800}
        onCancel={onCancel}
        footer={[
            <Button key="back" onClick={onCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={form.submit}>
              保存
            </Button>]}>
        <Form
            form={form}
            labelCol={{ flex: '100px' }}
            initialValues={initialValues}
            onFinish={onFinish}
            >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} style={{ margin: '0px 20px 10px 20px', color: '#23a8a8' }}>合同信息</Title>
                </Col>
                <Col span={12}>
                    <Form.Item name="dingEndTime" label="审批通过时间" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="sourceName" label="来源" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="processNo" label="流程编号" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='contractNo' label='合同编码' >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="contractName" label="合同名称" rules={[{required: true}]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="partyA" label="合同甲方" rules={[{required: true}]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="partyB" label="合同乙方" rules={[{required: true}]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="contractStartDate" label="起始日期" rules={[{required: true}]}>
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="contractEndDate" label="终止日期">
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="applyName" label="申请人">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="applyDepartment" label="部门">
                        <Input disabled/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="applyDate" label="申请日期" rules={[{required: true}]}>
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="sealTypeName" label="用印类型">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="stampType" label="印章类型" rules={[{required: true}]}>
                        <Select placeholder="全部" allowClear>
                            <Option value='GZ'>公章</Option>
                            <Option value='HTZ'>合同章</Option>
                            <Option value='QT'>其他</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Title level={5} style={{ margin: '20px 20px 10px 20px', color: '#23a8a8' }}>档案信息</Title>
                </Col>
                <Col span={12}>
                    <Form.Item name="filingFlag" label="归档状态">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="filingName" label="归档人员">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="filingTime" label="归档时间">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="filingDay" label="归档天数">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={24}></Col>
                <Col span={12}>
                    <Form.Item name="reverseSignatureDay" label="倒签时间">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="sealNum" label="用印份数" rules={[{required: true}]}>
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="sealMsg" label="用印说明" rules={[{required: true}]}>
                        <TextArea rows={3} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item className='wrapl' name="sendAddress" label="回寄联系人及地址">
                        <TextArea rows={3} />
                    </Form.Item>
                </Col>
                <Col>
                    <Title level={5} style={{ margin: '20px 20px 10px 20px', color: '#23a8a8' }}>关联审批单</Title>
                </Col>
                <Col span={24}>
                    <Form.Item name="relationProcessName" label="关联流程">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
      </Modal>
    );
})

export default ConfigModal;
