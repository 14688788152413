import React, { useState, useEffect, useRef } from 'react'
import {
    Row,
    Table,
    Select,
    Form,
    Input,
    Button,
    Space,
    PageHeader,
    Typography
} from 'antd';
import qs from 'qs'
import { request } from '../../../../../../utils/fetch'
import MyModel3 from './MyModel3';
import store from '../../../../../../store/index'
const { Text } = Typography;
const FormItem = Form.Item;
const { Option } = Select;
const itemStyle = {
    width: '300px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 }
}


function Preview(props) {
    let path = 'platform:BillUpload'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g, ''));
    const [form] = Form.useForm();
    const initSearch = {
        status: '',
        keyWord: ''
    }
    const [detailObj, setDetailObj] = useState({
        auditReason: ''
    })
    const [tableData, setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [reasonList, setReasonList] = useState([])
    const [statData, setStatData] = useState({})
    const myModel3 = useRef();

    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            width: 150,
            align: 'center',
        }, {
            title: '身份证号',
            dataIndex: 'idNumber',
            width: 170,
            align: 'center',
        }, {
            title: '佣金（元）',
            dataIndex: 'commissionAmount',
            width: 150,
            align: 'center',
        }, {
            title: '进入审核的原因',
            dataIndex: 'auditReasonName',
            width: 150,
            align: 'center',
        }, {
            title: '进入审核状态',
            dataIndex: 'auditStatusName',
            width: 170,
            align: 'center',
        }, {
            title: '收款卡号',
            dataIndex: 'bankNumber',
            width: 150,
            align: 'center',
        }, {
            title: '开户行',
            dataIndex: 'bankName',
            width: 150,
            align: 'center',
        }, {
            title: '打款备注',
            dataIndex: 'content',
            width: 150,
            align: 'center',
        }, {
            title: '操作',
            key: 'action',
            width: 150,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <a onClick={() => methodsFn.handleLook(record)}>查看</a>
            ),
        },
    ];
    const columns2 = [
        {
            title: '姓名',
            dataIndex: 'name',
            width: 150,
            align: 'center',
        }, {
            title: '身份证号',
            dataIndex: 'idNumber',
            width: 170,
            align: 'center',
        }, {
            title: '佣金（元）',
            dataIndex: 'commissionAmount',
            width: 150,
            align: 'center',
        }, {
            title: '进入审核的原因',
            dataIndex: 'auditReasonName',
            width: 150,
            align: 'center',
        }, {
            title: '进入审核状态',
            dataIndex: 'auditStatusName',
            width: 170,
            align: 'center',
        }, {
            title: '打款备注',
            dataIndex: 'content',
            width: 150,
            align: 'center',
        }, {
            title: '操作',
            key: 'action',
            width: 150,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <a onClick={() => methodsFn.handleLook(record)}>查看</a>
            ),
        },
    ];

    //组件渲染函数
    const myComponent = {
        totalTxt() {
            return (
                <div style={{ backgroundColor: '#fff' }}>
                    <PageHeader
                        onBack={() => props.history.goBack()}
                        title="审核查询"
                    />
                    <div style={{ padding: '0 30px 30px', backgroundColor: '#fff', marginBottom: '20px' }}>
                        <div style={{ marginBottom: '15px', borderTop: '1px solid rgba(0, 0, 0, 0.06)', paddingTop: '20px' }}>
                            <Space size={30}>
                                <Text>生成时间：{statData.createTime}</Text>
                                <Text>企业名称：{searchParam.companyName}</Text>
                                <Text>账单月份：{statData.month}</Text>
                                <Text>发放方式：{searchParam.lineType == 1 ? '线上发放' : '线下发放'}</Text>
                            </Space>
                        </div>
                        <div>
                            <Space size={30}>
                                <Text>发放人数：{statData.staffCount}人</Text>
                                <Text>个人佣金：￥{statData.totalCommissionAmount}</Text>
                                <Text>平台服务费：￥{statData.totalServiceAmount}</Text>
                                <Text>平台税费：￥{statData.totalTaxAmount}</Text>
                                <Text>合计申请放款：￥{statData.totalAmount}</Text>
                            </Space>
                        </div>
                    </div>
                </div>
            )
        },
        renderSearchForm() {
            return (
                <Form form={form} initialValues={initSearch} {...labelStyleObj}>
                    <Row>
                        <FormItem label="进入审核的原因" name="auditReasonList" style={{ width: '500px', marginRight: '15px' }}>
                            <Select >
                                {
                                    reasonList.map(item => {
                                        return <Option value={item.id} key={item.id}>{item.reason}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                        <FormItem label="进入审核状态" name="auditStatus" style={itemStyle} >
                            <Select>
                                <Option value='SHZ'>审核中</Option>
                                <Option value='SHTG'>审核通过</Option>
                                <Option value='SHJJ'>审核拒绝</Option>
                                <Option value='SHWC'>审核完成</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="关键字" name="keyword" style={itemStyle} >
                            <Input placeholder="姓名丨身份证号" />
                        </FormItem>
                    </Row>
                    <Row justify='end'>
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {
                            store.getState().userReducer.permissions.indexOf(`${path}:export`) > -1 &&
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>
                        }
                    </Row>
                </Form>
            );
        },
    }
    //方法函数
    const methodsFn = {
        handleSearch() {
            setPageNum(1)
            if(pageNum==1){
                methodsFn.getTableList(form.getFieldsValue())
            }
        },
        handleFormReset() {
            form.resetFields();
        },
        async getTableList(searchObj) {
            let result = await request.get('/platform/billUpload/getAuditDetailList', {
                pageSize: pageSize,
                pageNum: pageNum,
                divideCode: searchParam.divideCode,
                auditReasonList: searchObj.auditReasonList,
                auditStatus: searchObj.auditStatus,
                keyword: searchObj.keyword,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },
        async getAuditDetailStat() {
            let result = await request.get('/platform/billUpload/getAuditDetailStat', {
                divideCode: searchParam.divideCode,
            })
            setStatData(result)
        },
        async getAuditReason() {
            let result = await request.get('/common/getAuditReason')
            let arr = []
            for (var key in result) {
                arr.push({
                    id: key,
                    reason: result[key]
                })
            }
            setReasonList(arr)
        },
        handleExport() {
            let searchObj = form.getFieldsValue()
            request.downExport('/platform/billUpload/exportAuditDetail', {
                divideCode: searchParam.divideCode,
                auditReasonList: searchObj.auditReasonList,
                auditStatus: searchObj.auditStatus,
                keyword: searchObj.keyword,
            })
        },
        async getDetail(obj) {
            const result = await request.get('/platform/billAudit/getDetail', {
                billDetailNo: obj.billDetailNo,
                auditNo: obj.auditNo,
            })
            setDetailObj(result)
        },
        handleLook(record) {
            console.log(record);
            methodsFn.getDetail(record)
            myModel3.current.showModal(true)
        },
    }

    useEffect(() => {
        methodsFn.getAuditReason()
        methodsFn.getAuditDetailStat()
    }, [])
    useEffect(() => {
        methodsFn.getTableList(form.getFieldsValue())

    }, [pageNum])
    return (
        <div>
            {myComponent.totalTxt()}
            {myComponent.renderSearchForm()}
            <Table
                bordered
                scroll={{ x: 'max-content', y: 500 }}
                columns={searchParam.lineType == 1 ? columns2 : columns}
                rowKey={(record) => record.idNumber}
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
            />
            <MyModel3 ref={myModel3} detailObj={detailObj} />
        </div>

    )
}

export default Preview
