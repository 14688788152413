import React, { useImperativeHandle, useState, forwardRef } from 'react'
import { Modal, Table, Typography, Popconfirm, message, Button } from 'antd'
import { getSmsTemplateList, getSmsLogList, sendSms } from '../../api'
const { Link } = Typography
const SendMsgModel = (props,ref) => {
    const [visible, setVisible] = useState(false)
    const [params, setParams] = useState({}) //首页带过来信息
    const [msgList, setMsgList] = useState([])
    const [historyList, setHistoryList] = useState([])
    const msgColumns = [
        { title: '短信模板', dataIndex: 'content',align: 'center' },
        {
            title: '操作',
            align: 'center',
            width: '200px',
            render: (record) => {
                return (
                    <Popconfirm
                        title='确定用此模板发短信么？'
                        onConfirm={() => {
                            sendMsg(record)
                        }}
                        okText='确定'
                        cancelText='取消'
                    >
                        <Link>发短信</Link>
                    </Popconfirm>
                )
            },
        },
    ]
    const historyColumns = [
        {
            title: '发送时间',
            dataIndex: 'createTime',
            align: 'center',
            width: '120px',
            render(createTime) {
                return createTime.substring(0, 19)
            },
        },
        { title: '短信模板', dataIndex: 'templateContent', align: 'center' },
        { title: '发送人', dataIndex: 'send', align: 'center', width: '80px' },
        {
            title: '发送状态',
            dataIndex: 'status',
            align: 'center',
            width: '100px',
            render(status) {
                return status === 1 ? '失败' : '成功'
            },
        },
    ]

    // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        showModal: (data) => {
            console.log(data)
            setParams(data)
            getMsmTemp()
            getMsgHistory(data.archivesNo)
            setVisible(true)
        },
    }))
    // 获取短信模板
    const getMsmTemp = () => {
        getSmsTemplateList({ type: '1' }).then((res) => {
            if (res.data.success) {
                setMsgList(res.data.data)
            } else {
                message.error(res.data.retMsg)
            }
        })
    }
    //短信发送历史
    const getMsgHistory = (archivesNo) => {
        getSmsLogList({ type: '2', code: archivesNo }).then((res) => {
            if (res.data.success) {
                setHistoryList(res.data.data)
            } else {
                message.error(res.data.retMsg)
            }
        })
    }
    //发短信
    const sendMsg = (record) => {
        sendSms({
            archivesNo: params.archivesNo,
            templateCode: record.templateCode,
        }).then((res) => {
            if (res.data.success) {
                message.success('请求发送成功！')
                getMsgHistory(params.archivesNo)
            } else {
                message.error(res.data.retMsg)
            }
        })
    }
    const onCancel = () => {
        setVisible(false)
    }
    return (
        <>
            <Modal maskClosable={false}
                title='短信'
                width='1000px'
                visible={visible}
                onCancel={onCancel}
                footer={[
                    <Button key='back' onClick={onCancel}>
                        取消
                    </Button>,
                ]}
            >
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>模板列表</div>
                <Table columns={msgColumns} dataSource={msgList} scroll={{ y: 350 }} rowKey={(record) => record.id} pagination={false} />
                <div style={{ fontSize: '16px', fontWeight: 'bold', paddingTop: '40px' }}>历史发送</div>
                <Table columns={historyColumns} dataSource={historyList} scroll={{ y: 350 }} rowKey={(record) => record.id} pagination={false} />
            </Modal>
        </>
    )
}
export default forwardRef(SendMsgModel)
