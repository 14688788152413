import React, { useImperativeHandle,forwardRef, useState, useEffect } from 'react'
import {Table,Modal,Form,Input,Row,Col,Button,message} from 'antd';
import {trafficSafetyGetDetail,sendMsg,trafficSafetyFollowRecord,getCityListByProvinceId} from '../../api'
import { request } from '../../../../../utils/fetch'
import '../index.css'
const topCol = {
  span: 12,
}

const Take = (props,ref) => {
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('记录');
  const [cityList,setCityList] = useState()
  const [tableData,setTableData] =useState()
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList1: '',
    fileList2: '',
    fileList3: '',
    fileList4: '',
    fileList5: '',
    fileList6: '',
    fileList7: '',
    previewTitle: '',
  })
  const [form] = Form.useForm()
  const initialFormValues = {}
  
  const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU：重大刑事案件、涉及刑事或者治安案件，对公司品牌造成严重影响的</p>
      <p className="titp">P0：致人死亡或2人及以上重伤</p>
      <p className="titp">P1：致1人重伤</p>
      <p className="titp">P2：致人轻伤</p>
      <p className="titp">P3：致人轻微伤</p>
    </div>
  )
  useEffect(() => {
  }, [])
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (data, type) => {
      console.log(type);
      form.resetFields()
      setVisible(true);
      const query = {}
      if(type==1){
        query.eventCode = data.eventCode
      }else{
        query.eventCode = data.relationEventCode
      }
      console.log(query);
      trafficSafetyGetDetail({eventCode:data.eventCode,type:0}).then((res)=>{
        const result = res.data.data
        getCity()
        form.setFieldsValue(result)
      })
      trafficSafetyFollowRecord(query).then((res)=>{
        if(res.data.success){
          setTableData(res.data.data.list)
        }else{
            message.error(res.data.retMsg)
        }
      })
    }
  }));
  const columns = [
    { title: '提交时间',dataIndex: 'createTime',width: 120,align: 'center' },
    { title: '跟进人',dataIndex: 'createUser',width: 120,align: 'center', },
    { title: '跟进描述',dataIndex: 'followDescribe',width: 150,align: 'center'},
    { title: '地点',dataIndex: 'address',width: 150,align: 'center'}
  ]
  const getCity = ()=>{
    getCityListByProvinceId({
        regionalNo:form.getFieldsValue().regionalNo,
        provinceId:form.getFieldsValue().eventProvinceId
    }).then((res)=>{
        setCityList(res.data.data)
    })
  }
  const exportData = ()=>{
    console.log(form.getFieldValue('eventCode'));
    request.downExport('/platform/trafficEvent/exportFollowRecord',{eventCode:form.getFieldValue('eventCode')})
  }
  const send = ()=>{
    console.log(form.getFieldValue('eventCode'));
    const query = {
      eventCode:form.getFieldValue('eventCode'),
      eventLevel:form.getFieldValue('eventLevel'),
      eventCityName:form.getFieldValue('eventCityName'),
      name:form.getFieldValue('name')
    }
    console.log(query);
    sendMsg(query).then((res)=>{
      if(res.data.success){
        message.success('发送成功')
      }else{
        message.error(res.data.retMsg)
      }
    })
  }
  return (
    <>
      <Modal maskClosable={false}
        title={title}
        width='1200px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        className='trafficSafetyTake'
        footer={null}
      >
        <Form initialValues={initialFormValues} form={form} labelAlign='right' className='trafficSafetyTakeForm'>
          <Row className="margin-15">
            <Col {...topCol}>
              <Form.Item name="eventSourceName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件来源">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件工单号">
                <Input disabled={true} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventTypeNames" label="事件类型" style={{ marginBottom: 0 }} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventLevel" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} tooltip={{ title: content, color: '#FFFFFF' }} label="事件等级">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="注册城市">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="staffTypeName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="人员类型">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事发城市">
                <div style={{display:'flex'}}>
                  <Input disabled={true} value={form.getFieldValue('eventProvinceName')} />
                  <span style={{margin:'0 10px'}}>—</span>
                  <Input disabled={true} value={form.getFieldValue('eventCityName')}/>
                </div>
              </Form.Item>
            </Col>
            
            <Col {...topCol}>
              <Form.Item name="name" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="骑手姓名">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eleCourierId" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="骑手ID">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="手机号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name='idNumber' labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="身份证号">
                <Input disabled={true}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventTime"
              labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事发时间">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderStatusName" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="骑手状态">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartyStatusName" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="三者状态" >
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="left">
            <h2 className="staff-title">明细</h2>
            <Button style={{ marginRight: 10 ,float:'right'}} type='primary' onClick={exportData}>
                下载
            </Button>
            <Button style={{ marginRight: 10 ,float:'right'}} type='primary' onClick={send}>
                发送提醒
            </Button>
        </div>
        <Table
            scroll={{ x: 'max-content', y: 500 }}
            columns={columns}
            rowKey={record => record.id}
            dataSource={tableData}
            pagination={false}
        />
        <Modal maskClosable={false}
          width={1000}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
        </Modal>
      </Modal>
    </>
  );
};
export default forwardRef(Take)