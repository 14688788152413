import {
    request
} from '../../../../utils/fetch'

// 治安跟踪-列表
export const securityEventList = (params) => {
    return request.requestGet('/platform/securityEvent/getList', params)
}

// 治安跟踪-跟进列表
export const getFollowList = (params) => {
    return request.requestGet('/platform/securityEvent/getFollowList', params)
}

// 治安跟踪-新增
export const securityEventReport = (data) => {
    return request.requestPost('/platform/securityEvent/report', data)
}

// 治安跟踪-修改结案
export const securityEventUpdate = (data) => {
    return request.requestPost('/platform/securityEvent/update', data)
}

// 治安跟踪-转交安
export const securityEventTransform = (data) => {
    return request.requestPost('/platform/securityEvent/transform', data)
}

// 治安跟踪-结案
export const securityEventFinish = (data) => {
    return request.requestPost('/platform/securityEvent/finish', data)
}

// 治安跟踪-重新结案
export const securityEventReFinish = (data) => {
    return request.requestPost('/platform/securityEvent/reFinish', data)
}

// 治安跟踪-跟进
export const securityEventFollow = (data) => {
    return request.requestPost('/platform/securityEvent/follow', data)
}

// 治安跟踪-删除
export const securityEventDelete = (data) => {
    return request.requestPost('/platform/securityEvent/delete', data)
}

// 治安跟踪-详情
export const securityEventgetDetail = (params) => {
    return request.requestGet('/platform/securityEvent/getDetail', params)
}
