import { request } from '../../../utils/fetch'

// 平台流水列表（新）
export function abcEnterpriseAccountDealGetList(params) {
    return request.requestGet('/platform/abcEnterpriseAccountDeal/getList', params)
}

// 查询服务费、税费（新）
export function getFee(params) {
    return request.requestGet('/platform/abcEnterpriseAccountDeal/getFee', params)
}

// 平台入账列表
export function getPlatformRecordList(params) {
    return request.requestGet('/platform/financialdeal/getList', params)
}

// 平台入账详情
export function getPlatformDetail(params) {
    return request.requestGet('/platform/financialdeal/getDetail', params)
}
// 平台入账详情头部数据
export function getDetailTitle(params) {
    return request.requestGet('/platform/financialdeal/detailTitle', params)
}
// 提现
export function withdrawal(data) {
    return request.requestPost('/platform/abcEnterpriseAccountDeal/withDraw', data)
}
// 提现记录
export function getRecord(params) {
    return request.requestGet('/platform/financialdeal/getRecord', params)
}
// 企业流水列表
export function getCompanyPipeline(params) {
    return request.requestGet('/platform/companydetail/list', params)
}
// 企业流水详情
export function getCompanyPipelineDetail(params) {
    return request.requestGet('/platform/companydetail/getAccountsCapitalDetail', params)
}


// 阶梯退费列表
export function getLadderRefundList(params) {
    return request.requestGet('/platform/returnCost/getList', params)
}

// 阶梯退费查看
export function getLadderRefundDetail(params) {
    return request.requestGet('/platform/returnCost/getDetail', params)
}

// 确认退费
export function confirmCost(data) {
    return request.requestPost('/platform/returnCost/confirm', data)
}


// 财税企业列表
export function getTaxEnterpriseList(params) {
    return request.requestGet('/platform/taxEnterprise/list', params)
}
// 财税企业-获取实名链接
export function getAuthCompanyUrl(params) {
    return request.requestGet('/platform/taxEnterprise/getAuthCompanyUrl', params)
}
// 财税企业-获取授权链接
export function getBeforeAuthSign(params) {
    return request.requestGet('/platform/taxEnterprise/getBeforeAuthSign', params)
}
// 财税企业新增
export function taxEnterpriseInsert(data) {
    return request.requestPost('/platform/taxEnterprise/insert', data)
}
// 财税企业启停用
export function taxEnterpriseUpdateStatus(data) {
    return request.requestPost('/platform/taxEnterprise/updateStatus', data)
}
// 财税企业上传印章
export function updateSealUrl(data) {
    return request.requestPost('/platform/taxEnterprise/updateSealUrl', data)
}