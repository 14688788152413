import React,{useState} from 'react'
import { Layout, Menu,Dropdown,Modal,Form,Input,message} from 'antd';
import {DownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {clearToken} from '../../utils/auth.js'
import './layout.css';
import {request} from '../../utils/fetch'
import CryptoJS from 'crypto-js';
import SHA256 from 'crypto-js/sha256';

const { Header } = Layout;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
function MyHeader(props) {
    const [form] = Form.useForm();
    const [visible, setVisible] = React.useState(false);
    const [visible2,setVisible2] = useState(false)

    const titleModel = '修改密码'
    const encodeAesString = (data, key, iv) => {
      var key = CryptoJS.enc.Utf8.parse(key);
      var iv = CryptoJS.enc.Utf8.parse(iv);
      var encrypted = CryptoJS.AES.encrypt(data, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      //返回的是base64格式的密文	
      return encrypted.toString();
  }
    const handleVisibleChange = flag => {
      setVisible(flag)
    };
    const loginOut = () => {
      clearToken()
      props.history.push('/login')
    }
    const changePwd = async() => {
      let values = await form.validateFields()
      if(values.pwd !== values.newPassword){
        message.error('两次密码不一致！')
        return
      }
      
      var key = 'fdf653gsdg34ferg'; // 密钥 长度16  前后端定义好，加盐
      var iv = 'fdf653gsdg34ferg'; // 密钥 长度16
      var encrypted = encodeAesString(values.newPassword, key, iv); // 密文

      request.post('/platform/user/updatePassword',{
        id:2,
        newPassword:encrypted,
      }).then(res => {
        message.success('修改成功！')
        setVisible2(false)
      })

     
    }

    const menu = (
      <Menu>
        <Menu.Item key="1"  onClick={() => setVisible2(true) }>修改密码</Menu.Item>
        <Menu.Item key="2" onClick={loginOut}>退出登录</Menu.Item>
      </Menu>
    );
    return (
        <Header className="header">
            {/* <div className="logo" /> */}
            <div >你好，{props.userName}</div>
            <Dropdown
            overlay={menu}
            onVisibleChange={handleVisibleChange}
            visible={visible}
            >
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            个人中心 <DownOutlined />
            </a>
            </Dropdown>

            <Modal maskClosable={false}
              forceRender
              title={titleModel}
              width='500px'
              visible={visible2}
              onOk={changePwd}
              onCancel={() => setVisible2(false) }
            >
              <Form
                initialValues={{
                  pwd:''
                }}
                form={form}
                {...formItemLayout} >
                <FormItem
                  name="pwd" 
                  rules={[{ required: true, message: '请填写' }]}
                  label="新密码" >
                    <Input  type="password" placeholder="请输入"/>
                </FormItem>
                <FormItem
                  rules={[{ required: true, message: '请填写' }]}
                  name="newPassword"
                  label="确定密码" >
                    <Input  type="password" placeholder="请输入"/>
                </FormItem>
                
                </Form>
            </Modal>
           
        </Header>
    )
}

const CounterMapStateToProps = (state) => ({
  userName:state.userReducer.userName    
})

const mapDispatchToProps = (dispatch) => ({
  dispatch
})
MyHeader = connect(CounterMapStateToProps, mapDispatchToProps)(MyHeader)

export default withRouter(MyHeader)
