/* eslint-disable */
import React, { useImperativeHandle,forwardRef, useState, useEffect } from 'react'
import {Modal,Form,Input,InputNumber,Select,Row,Col,DatePicker,Upload,Button,message,Timeline} from 'antd';
import moment from 'moment'
import {trafficSafetyInsert,getPerson,getAllRegionalList,getProvinceIdListByRegionalNo,getCityListByProvinceId} from '../../api'
import { PlusOutlined } from '@ant-design/icons';
import '../index.css'
const { TextArea } = Input;
const topCol = {
  span: 12,
}
const labCol = {
  span:6,
}
const wrapCol = {
  span:14,
}
const labCol2 = {
  span:3,
}
const wrapCol2 = {
  span:19,
}
const action = "/wispay/common/uploadFile"
// const action = "http://test.zchpay.com/wispay/common/uploadFile"
const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const AddReport = (props,ref) => {
  const { refresh } = props
//   const { cityData } = props
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('新增');
  const [disabled, setDisabled] = useState(false);
  const [editData, setEditData] = useState([]);
  const [spData, setSpData] = useState([]);
  const [NailQRcode, setNailQRcode] = useState(null);
  const [regionalList, setRegionalList] = useState([])
  const [provinceList, setProvinceList] = useState([])
  const [personList,setPersonList] =useState([])
  const [cityList, setCityList] = useState([])
  const [idNumberd, setIdNumberd] = useState()
  const [rule, setRule] = useState()
  const [riskDescribeRule, setRiskDescribeRule] = useState()
  const [advanceReasonRule, setAdvanceReasonRule] = useState()
  const [loading,setLoading] = useState(false)
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList1: '',
    fileList2: '',
    fileList3: '',
    fileList4: '',
    fileList5: '',
    fileList6: '',
    fileList7: '',
    previewTitle: '',
  })
  const [form] = Form.useForm()
  const { Option } = Select
  const initialFormValues = {
  }
  
  const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU：重大刑事案件、涉及刑事或者治安案件，对公司品牌造成严重影响的</p>
      <p className="titp">P0：致人死亡或2人及以上重伤</p>
      <p className="titp">P1：致1人重伤</p>
      <p className="titp">P2：致人轻伤</p>
      <p className="titp">P3：致人轻微伤</p>
    </div>
  )
  const content2 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">案件响应时间-接报或上报时间</p>
    </div>
  )
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  useEffect(() => {
    getAllRegional()
  }, [])
  useEffect(() => {
    getPersonList()
  }, [])
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (ctitle, data, splist) => {
      form.resetFields()
      setEventOption2([])
      setEventOption3([])
      setRiskDescribeRule([])
      setAdvanceReasonRule([])
      setVisible(true);
    }
  }));
  //事件类型1
  const eventOption1 = [
    { key:'JT',name:'交通事故',},
    { key:'YW',name:'意外事故'},
    { key:'ZAXS',name:'治安刑事事件'},
    { key:'WW',name:'维稳事件'},
    { key:'XF',name:'消防事件'},
    { key:'YQ',name:'舆情事件',},
  ]
  //事件类型2，3
  const select = {
    'JT':[
      { key:'GZ',name:'工作期间' },
      { key:'FGZ',name:'非工作期间' },
    ],
    'YW':[
      { key:'YW_NYX',name:'脑溢血'},
      { key:'YW_XJGS',name:'心肌梗塞'},
      { key:'YW_GKZW',name:'高空坠物'},
      { key:'YW_DWSH',name:'动物伤害'},
      { key:'YW_NS',name:'溺水'},
      { key:'YW_CD',name:'触电'},
      { key:'ZDY',name:'自定义' }
    ],
    'ZAXS':[
      { key:'ZA',name:'治安事件'},
      { key:'XS',name:'刑事事件'},
      { key:'YB',name:'一般事件'}
    ],
    'ZA':[
      { key:'XSR',name:'性骚扰' },
      { key:'FAGW',name:'妨碍公务' }
    ],
    'XS':[
      { key:'TD',name:'偷盗' },
      { key:'DONS',name:'斗殴闹事' }
    ],
    'YB':[
      { key:'FWYC',name:'服务异常（恐吓威胁辱骂骚扰）' },
      { key:'YY',name:'扬言类' }
    ],
    'WW':[
      { key:'WW_BG',name:'罢工' },
      { key:'WW_QT',name:'群体性事件' },
      { key:'WW_GR',name:'个人极端行为' },
      { key:'WW_YH',name:'涉维稳风险隐患' },
      { key:'ZDY',name:'自定义' }
    ],
    'XF':[
      { key:'XF_FW',name:'自租房屋火灾' },
      { key:'XF_DDCHZ',name:'电动车火灾' },
      { key:'XF_DDCZR',name:'电动车自燃' },
    ]
  }
  //事件类型2
  const [eventOption2,setEventOption2] = useState([])
  //事件类型3
  const [eventOption3,setEventOption3] = useState([])
  const [isShow,setIsShow] = useState(false)
  const [violationShow,setViolationShow] = useState(false)

  const violationChange = (value)=>{
    if(value=='18'){
      setViolationShow(true)
    }else{
      setViolationShow(false)
    }
  }
  //事件1改变时
  const eventTypeChange1 = (value,option)=>{
    form.setFieldsValue({
      eventType2:'',
      eventType3:'',
      eventTypeExplain:'',
    })
    setEventOption2([])
    setEventOption3([])
    setIsShow(false)
    if(value=='YW'){
      setEventOption2(select['JT'])
      setEventOption3(select['YW'])
    }else if(value=='YQ'){
    }else{
      setEventOption2(select[value])
    }
  }
  //事件2改变时
  const eventTypeChange2 = (value,option)=>{
    form.setFieldsValue({
      eventType3:'',
      eventTypeExplain:'',
    })
    const data = form.getFieldsValue()
    if(data.eventType1=='ZAXS'){
      setEventOption3(select[value])
    }
    if(value=='ZDY'){
      setIsShow(true)
    }
    console.log(value,option,data);
  }
  //事件3改变时
  const eventTypeChange3 = (value,option)=>{
    form.setFieldsValue({
      eventTypeExplain:'',
    })
    if(value=='ZDY'){
      setIsShow(true)
    }else{
      setIsShow(false)
    }
  }
  //限制时间
  const eventTimeChange = (value)=>{
    let a = form.getFieldValue('eventReportTime')
    if(a&&value>=a){
      form.setFieldsValue({
        eventTime:''
      })
      message.error('发生时间要在上报时间之前')
    }
  }
  const eventReportTimeChange = (value)=>{
    let a = form.getFieldValue('eventTime')
    let b = form.getFieldValue('caseRespondTime')
    console.log(a,value,b);
    if((a&&a>=value)||(b&&value>=b)){
      form.setFieldsValue({
        eventReportTime:''
      })
      message.error('请输入合理的上报时间')
    }
    if(form.getFieldValue('eventReportTime')&&form.getFieldValue('caseRespondTime')){
      form.setFieldsValue({
        caseRespondInterval: subtractTime(form.getFieldValue('eventReportTime'),form.getFieldValue('caseRespondTime'))
      })
    }
  }
  const caseRespondTimeChange = (value)=>{
    let a = form.getFieldValue('eventReportTime')
    let b = form.getFieldValue('dealWithTime')
    if((a&&a>=value)||(b&&value>=b)){
      form.setFieldsValue({
        caseRespondTime:''
      })
      message.error('请输入合理的响应时间')
    }
    if(form.getFieldValue('eventReportTime')&&form.getFieldValue('caseRespondTime')){
      form.setFieldsValue({
        caseRespondInterval: subtractTime(form.getFieldValue('eventReportTime'),form.getFieldValue('caseRespondTime'))
      })
    }
  }
  //计算时间差
  const subtractTime = (startDate,endDate)=>{
    let time = endDate-startDate
    console.log(time);
    let days = 1000*60*60*24
    let hours = 1000*60*60
    let minutes = 1000*60
    let day =0
    let hour =0
    let minute =0
    let second = 0
    let timeStr=''
    if(time/days>1){
      day = parseInt(time/days)
      timeStr = day+'天'
    }
    if(time/hours>1){
      hour = parseInt((time-(day*days))/hours)
      timeStr = timeStr+hour+'时'
    }
    if(time/minutes>1){
      minute = parseInt((time-(day*days)-(hour*hours))/minutes)
      timeStr = timeStr+minute+'分'
    }
    if(time/1000>1){
      second = parseInt((time-(day*days)-(hour*hours)-(minute*minutes))/1000)
      timeStr = timeStr+second+'秒'
    }
    console.log(timeStr);
    return timeStr
  }
  //获取大区
  const getAllRegional = () => {
    getAllRegionalList().then((res)=>{
        setRegionalList(res.data.data)
    })
  }
  const regionalChage = (value) => {
    form.setFieldsValue({
      eventProvinceId:'',
      eventCityId:''
    })
    setCityList([])
    getProvince()
  }
  const getProvince = ()=>{
    getProvinceIdListByRegionalNo({
        regionalNo:form.getFieldsValue().regionalNo
    }).then((res)=>{
        setProvinceList(res.data.data)
    })
  }
  //省份改变获取城市
  const ProvinceChage = (value) => {
    form.setFieldsValue({
      eventCityId:''
    })
    getCity()
  }
  const getCity = ()=>{
    getCityListByProvinceId({
        regionalNo:form.getFieldsValue().regionalNo,
        provinceId:form.getFieldsValue().eventProvinceId
    }).then((res)=>{
        setCityList(res.data.data)
    })
  }
  // 获取人员
  const getPersonList = async (value) => {
    const res = await getPerson({cityIdList:form.getFieldsValue().eventCityId})
    if (res.data.success) {
      setPersonList(res.data.data||[])
    }
  }
  // 下拉框
  const selectFilter = (inputValue, option) => {
    if (option.children.indexOf(inputValue) != -1) {
        return option
    }
  }
  //选择人员后
  const personChange = (value)=>{
    setIdNumberd(value)
    let row = personList.filter(item=>{
      return item.idNumber == value
    })
    console.log(row)
    form.setFieldsValue({
      name:row[0].name,
      eleCourierId: row[0].staffId,
      phone: row[0].phone,
      staffType: row[0].staffType + '',
    })
    console.log(form.getFieldValue());
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  const handleCancel = () => {
    setPreviewData(prev => {
      return {
        ...prev,
        previewVisible: false
      }
    })
  }
  const changeFile = ({ file, fileList }, type) => {
    fileList = fileList.map(item => {
      if (item.response&&item.response.success) {
        item.url = item.response.data[0].url;
      }
      return item;
    });
    setPreviewData(prev => {
      const data = {...prev}
      data[type] = fileList
      return data
    })
  }
  const setFlieList = (list) => {
    list = list&&list.filter(item=>{
      return item.url
    })
    list = list&&list.map((item,index)=>{
      return {
        url: item.url,
        uid: item.uid || index,
        name: item.name||item.url,
        status: 'done',
      }
    })
    return list
  }
  const setFlieParams = (list,type) => {
      list = list&&list.filter(item=>{
        return item.url
      })
      list = list&&list.map((item,index)=>{
        return {
          url: item.url,
          uid: 0-index,
          name: item.name||item.url,
          status: 'done',
          type: type
        }
      })
      return list
  }
  const insureFlagChange = (value) =>{
    if(value==1){
      setRule([{ required: true, message: '请选择保险!' }])
    }else if(value==0){
      setRule([])
      form.setFieldsValue({
        insureCompany:''
      })
    }
  }
  const riskFlagChange = (value)=>{
    if(value==1){
      setRiskDescribeRule([{ required: true, message: '请填写风险描述' }])
    }else if(value==0){
      setRiskDescribeRule([])
    }
  }
  const advanceAmountChange = ()=>{
    let value = form.getFieldValue('advanceAmount')
    if(value==0){
      setAdvanceReasonRule([])
    }else{
      setAdvanceReasonRule([{ required: true, message: '请填写垫付原因' }])
    }
  }
  const policeDepartmentChange=(value)=>{
    if(value==0){
      form.setFieldsValue({
        policeDepartment:['0']
      })
    }else{
      const arr = form.getFieldValue('policeDepartment').filter(i=>i!=0)
      form.setFieldsValue({
        policeDepartment:arr
      })
    }
  }
  const submit = (submitType)=>{
    const formData = form.getFieldValue()
    const query = {submitType,...formData}
    form.validateFields().then(async (values)=>{
      setLoading(true)
      query.eventTime = query.eventTime?moment(query.eventTime).format("YYYY-MM-DD HH:mm:ss"):''
      query.eventReportTime = query.eventReportTime?moment(query.eventReportTime).format("YYYY-MM-DD HH:mm:ss"):''
      query.caseRespondTime = query.caseRespondTime?moment(query.caseRespondTime).format("YYYY-MM-DD HH:mm:ss"):''
      query.policeDepartment = query.policeDepartment.join(',')
      query.scenePictureUrl = setFlieParams(query.scenePictureUrl)
      query.orderPictureUrl = setFlieParams(query.orderPictureUrl)
      query.responsibleLetterUrl = setFlieParams(query.responsibleLetterUrl)
      trafficSafetyInsert(query).then((res)=>{
        if(res.data.success){
          message.success(submitType==1?'暂存成功':'提交成功')
          setVisible(false)
          refresh('search')
        }else{
          message.error(res.data.retMsg)
        }
        setLoading(false)
      }).catch(()=>{
        setLoading(false)
      })
    }).catch(()=>{
      message.error('请完善表单')
    })
  }
  return (
    <>
      <Modal maskClosable={false}
        title={title}
        width='1200px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={!disabled?[
          <Button key="back" onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={()=>{submit(1)}}>
            暂存
          </Button>,
          <Button
            key="link"
            // href="https://google.com"
            type="primary"
            loading={loading}
            onClick={()=>{submit(2)}}
          >
            提交审批
          </Button>,
        ]:null}
      >
        <Form initialValues={initialFormValues} form={form} labelAlign='rigth' className='trafficSafetyAddForm'>
          <Row className="margin-15">
            <Col {...topCol}>
              <Form.Item name="eventSource" rules={[{ required: true, message: '请选择事件来源' }]} labelCol={labCol} wrapperCol={wrapCol} label="事件来源">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value='1'>众包骑手上报</Option>
                  <Option value='2'>WOOS</Option>
                  <Option value='3'>保险上报（线上）</Option>
                  <Option value='4'>保险上报（线下）</Option>
                  <Option value='5'>钉钉外包人资商架构</Option>
                  <Option value='6'>交通违规 </Option>
                  <Option value='7'>客服转单（治安）</Option>
                  <Option value='8'>数字治理平台</Option>
                  <Option value='9'>算法实时产出 </Option>
                  <Option value='10'>线下上报 </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventOrderNo" labelCol={labCol} wrapperCol={wrapCol} label="事件工单号"
              rules={[{ required: true, message: '请填写事件工单号' }]}>
                <Input placeholder="请填写" maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="regionalNo" labelCol={labCol} rules={[{ required: true,message: '请选择大区!' }]} wrapperCol={wrapCol} label="大区" >
                <Select placeholder="请选择"  getPopupContainer={triggerNode => triggerNode.parentElement} 
                onChange={(value)=>{regionalChage(value)}}>
                  {regionalList.map(item => (
                    <Option value={item.regionalNo} key={item.regionalNo}>{item.regionalName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityId" rules={[{ required: true, message: '请选择注册城市!' }]} labelCol={labCol} wrapperCol={wrapCol} label="注册城市">
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} 
                showSearch >
                  <Option value='TJ'>天津市</Option>
                  <Option value='NC'>南昌市</Option>
                  <Option value='JJ'>九江市</Option>
                  <Option value='GZ'>赣州市</Option>
                  <Option value='JDZ'>景德镇市</Option>
                  <Option value='JN'>济南市</Option>
                  <Option value='LY'>临沂市</Option>
                  <Option value='QD'>青岛市</Option>
                  <Option value='WH'>威海市</Option>
                  <Option value='WF'>潍坊市</Option>
                  <Option value='YT'>烟台市</Option>
                  <Option value='ZB'>淄博市</Option>
                  <Option value='TY'>太原市</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name='eventProvinceId' rules={[{ required: true, message: '请选择事发城市!' }]} labelCol={labCol} wrapperCol={wrapCol} label="事发城市">
                <div style={{display:'flex',width:'100%'}} className='nobtn' >
                  <Form.Item name="eventProvinceId" style={{width:'100%'}}>
                    <Select placeholder="请选择"  getPopupContainer={triggerNode => triggerNode.parentElement} 
                    showSearch onChange={(value)=>{ProvinceChage(value)}}>
                      {provinceList.map(item => (
                        <Option value={item.provinceId} key={item.provinceId}>{item.provinceName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <span>&nbsp;&nbsp;—&nbsp;&nbsp;</span>
                  <Form.Item name="eventCityId" style={{width:'100%'}}>
                    <Select placeholder="请选择"  getPopupContainer={triggerNode => triggerNode.parentElement} showSearch >
                      {cityList.map(item => (
                        <Option value={item.cityId} key={item.cityId}>{item.cityName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventLevel" labelCol={labCol} wrapperCol={wrapCol} rules={[{ required: true,message: '请选择事件级别!' }]}
              tooltip={{ title: content, color: '#FFFFFF' }} label="事件级别">
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="ESU">ESU</Option>
                  <Option value="P0">P0</Option>
                  <Option value="P1">P1</Option>
                  <Option value="P2">P2</Option>
                  <Option value="P3">P3</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventRegionName" labelCol={labCol} wrapperCol={wrapCol} label="事发区" rules={[{ required: true,message: '请输入事发区!' }]} >
                <Input placeholder='请输入事发区' />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventStreetName" labelCol={labCol} wrapperCol={wrapCol} label="事发街道"  >
                <Input placeholder='请输入事发街道' />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="idNumber" labelCol={labCol} rules={[{ required: true,message: '请选择骑手!' }]} wrapperCol={wrapCol} label="骑手姓名">
                <Select placeholder="请选择" showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)} getPopupContainer={triggerNode => triggerNode.parentElement}
                onChange={(value) => personChange(value)} >
                  (personList?{personList.map(item => (
                    <Option value={item.idNumber} key={item.idNumber}>{item.name}</Option>
                  ))}:[])
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eleCourierId" labelCol={labCol} wrapperCol={wrapCol} label="骑手ID">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={labCol} wrapperCol={wrapCol} label="手机号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="idNumber" labelCol={labCol} wrapperCol={wrapCol} label="身份证号">
                <Input disabled={true}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="staffType" labelCol={labCol} wrapperCol={wrapCol} label="人员类型">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} disabled={true} >
                  <Option value='1'>普通</Option>
                  <Option value='2'>优选</Option>
                  <Option value='3'>001</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="事件类型" required style={{ marginBottom: 0 }} labelCol={labCol2} 
              wrapperCol={wrapCol2} rules={[{ required: true, message: '请选择事件类型' }]}>
                <div style={{display:'flex',width:'100%'}}>
                  <Form.Item name="eventType1" style={{width:'18%'}}>
                    <Select placeholder="请选择"  getPopupContainer={triggerNode => triggerNode.parentElement} 
                    onChange={(value,option)=>{eventTypeChange1(value,option)}}>
                      {eventOption1.map(item => (
                        <Option value={item.key} key={item.key}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  { eventOption2.length>0 && <span >&nbsp;&nbsp;</span>}
                  { eventOption2.length>0 &&
                    <Form.Item name="eventType2" style={{width:'18%'}} rules={[{ required: true, message: '请选择事件类型' }]}>
                      <Select placeholder="请选择"  getPopupContainer={triggerNode => triggerNode.parentElement} 
                      onChange={(value,option)=>{eventTypeChange2(value,option)}}>
                        {eventOption2.map(item => (
                          <Option value={item.key} key={item.key}>{item.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  }
                  { eventOption3.length>0 && <span >&nbsp;&nbsp;</span>}
                  { eventOption3.length>0 &&
                    <Form.Item name="eventType3" style={{width:'18%'}} rules={[{ required: true, message: '请选择事件类型' }]}>
                      <Select placeholder="请选择"  getPopupContainer={triggerNode => triggerNode.parentElement} 
                      onChange={(value,option)=>{eventTypeChange3(value,option)}}>
                        {eventOption3.map(item => (
                          <Option value={item.key} key={item.key}>{item.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  }
                  { isShow && <span >&nbsp;&nbsp;</span>}
                  {isShow &&
                  <Form.Item name="eventTypeExplain" style={{width:'43%'}} rules={[{ required: true, message: '请填写自定义描述' }]}>
                    <Input placeholder='自定义描述（10字以内）'></Input>
                  </Form.Item>
                  }
                </div>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insureFlag" labelCol={labCol} wrapperCol={wrapCol} label="是否报保险"
              rules={[{ required: true, message: '请选择选择是否保险!' }]}>
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} 
                onChange={(values)=>insureFlagChange(values)}>
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insureCompany" labelCol={labCol} wrapperCol={wrapCol} label="保险公司"
              rules={rule}>
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>平安</Option>
                  <Option value={2}>前海</Option>
                  <Option value={3}>泰康</Option>
                  <Option value={7}>紫金</Option>
                  <Option value={4}>太平洋</Option>
                  <Option value={6}>大地保险</Option>
                  <Option value={5}>新职业伤害险</Option>    
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="insureOrderNo" labelCol={labCol2} wrapperCol={{ span: 7 }} label="保单号">
                <Input placeholder="请输入保单号" maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventTime" rules={[{ required: true, message: '请选择事件发生时间!' }]} 
              labelCol={labCol} wrapperCol={wrapCol} label="事件发生时间">
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  onChange = {value=>eventTimeChange(value)}
                  showTime
                  format={["YYYY-MM-DD HH:mm:ss"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventReportTime" rules={[{ required: true, message: '请选择接报或上报时间!' }]} 
              labelCol={labCol} wrapperCol={wrapCol} label="接报或上报时间">
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  onChange={(value)=>eventReportTimeChange(value)}
                  showTime
                  format={["YYYY-MM-DD HH:mm:ss"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseRespondTime" rules={[{ required: true, message: '请选择案件响应时间!' }]} labelCol={labCol} wrapperCol={wrapCol} label="案件响应时间">
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  onChange={value=>caseRespondTimeChange(value)}
                  showTime
                  format={["YYYY-MM-DD HH:mm:ss"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseRespondInterval" tooltip={{ title: content2, color: '#FFFFFF' }} labelCol={labCol} 
              wrapperCol={wrapCol} label="案件响应时效">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventPlace" label="事件发生地址" labelCol={labCol2} wrapperCol={wrapCol2} 
              rules={[{ required: true, message: '请输入事件发生地址!' }]}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请输入事件发生地址，限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="workFlag" rules={[{ required: true, message: '请选择跑单时段!' }]} labelCol={labCol} 
              wrapperCol={wrapCol} label="跑单时段">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value='0'>否</Option>
                  <Option value='1'>是</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="policeDepartment" rules={[{ required: true, message: '请选择报警部门!' }]} labelCol={labCol} 
              wrapperCol={wrapCol} label="报警部门">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} mode="multiple" placeholder="请选择"
                onSelect={value =>policeDepartmentChange(value)}>
                  <Option value={'0'}>无</Option>
                  <Option value={'1'}>派出所</Option>
                  <Option value={'2'}>交警队</Option>
                  <Option value={'3'}>消防队</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="carType" rules={[{ required: true, message: '请选择驾驶车辆!' }]} labelCol={labCol} 
              wrapperCol={wrapCol} label="驾驶车辆">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value={0}>无</Option>
                  <Option value={1}>电动车</Option>
                  <Option value={2}>自行车</Option>
                  <Option value={3}>汽车(证照齐全)</Option>
                  <Option value={4}>汽车(证照不齐)</Option>
                  <Option value={5}>摩托车(证照齐全)</Option>
                  <Option value={6}>摩托车(证照不齐)</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="sensitiveFlag" rules={[{ required: true, message: '请选择涉及敏感人群!' }]} labelCol={labCol} 
              wrapperCol={wrapCol} label="涉及敏感人群">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value={0}>不涉及</Option>
                  <Option value={2}>14岁以下儿童</Option>
                  <Option value={3}>65岁以上老人</Option>
                  <Option value={4}>政府要员</Option>
                  <Option value={5}>外国友人</Option>
                  <Option value={6}>军人</Option>
                  <Option value={7}>媒体</Option>
                  <Option value={8}>三期妇女</Option>
                  <Option value={9}>伤残人士</Option>
                  <Option value={10}>高知名度社会人士</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderStatus" rules={[{ required: true, message: '请选择骑手状态!' }]} labelCol={labCol} 
              wrapperCol={wrapCol} label="骑手状态">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value='1'>死亡</Option>
                  <Option value='2'>重伤(ICU)</Option>
                  <Option value='3'>重伤(普通病房)</Option>
                  <Option value='4'>轻伤(住院) </Option>
                  <Option value='5'>轻伤(未住院)</Option>
                  <Option value='6'>无人伤</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderDescribe" label="骑手受伤情况说明" labelCol={labCol2} wrapperCol={wrapCol2}
              rules={[{ required: true, message: '请填写骑手受伤情况说明!' }]}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderWakeFlag" rules={[{ required: true, message: '请选择骑手是否清醒!' }]} labelCol={labCol} 
              wrapperCol={wrapCol} label="骑手是否清醒" >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderSurgicalFlag" labelCol={labCol} 
              wrapperCol={wrapCol} label="骑手是否需要手术">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderFamilyFlag" rules={[{ required: true, message: '请选择骑手家属是否到场!' }]} labelCol={labCol2} 
              wrapperCol={{ span: 7 }} label="骑手家属是否到场">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartyStatus" rules={[{ required: true, message: '请选择三者状态!' }]} labelCol={labCol} 
              wrapperCol={wrapCol} label="三者状态" >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>死亡</Option>
                  <Option value={2}>重伤(ICU)</Option>
                  <Option value={3}>重伤(普通病房)</Option>
                  <Option value={4}>轻伤(住院) </Option>
                  <Option value={5}>轻伤(未住院)</Option>
                  <Option value={6}>无人伤</Option>
                  <Option value={0}>无三者</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartySurgicalFlag" labelCol={labCol} 
              wrapperCol={wrapCol} label="三者是否需要手术">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="threePartyDescribe" label="三者具体受伤情况及物损说明" labelCol={labCol2} 
              wrapperCol={wrapCol2}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartyDamageAmount" labelCol={labCol} wrapperCol={wrapCol} label="三者财产损失金额">
                <InputNumber placeholder="请填写" min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="responsibleParty" rules={[{ required: true, message: '请选择事故定责!' }]} labelCol={labCol} 
              wrapperCol={wrapCol} label="事故定责">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value='1'>待定</Option>
                  <Option value='2'>全责</Option>
                  <Option value='3'>主责</Option>
                  <Option value='4'>同责</Option>
                  <Option value='5'>次责 </Option>
                  <Option value='6'>无责</Option>
                  <Option value='7'>无需定责</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="violationType" labelCol={labCol} 
              wrapperCol={wrapCol} label="违规项目">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} onChange={(value)=>{violationChange(value)}} >
                  <Option value='0'>判定无违规</Option>
                  <Option value='1'>超速驾驶(时速超过25KM/H)</Option>
                  <Option value='2'>逆行</Option>
                  <Option value='3'>闯红灯</Option>
                  <Option value='4'>骑行时使用手机</Option>
                  {/* <Option value='5'>追尾</Option>
                  <Option value='6'>肇事逃逸</Option> */}
                  <Option value='7'>酒后驾驶</Option>
                  <Option value='8'>超车不当</Option>
                  <Option value='9'>走机动车道</Option>
                  <Option value='10'>未佩戴头盔</Option>
                  <Option value='11'>转弯未让直行</Option>
                  <Option value='12'>横穿马路</Option>
                  <Option value='13'>单手驾驶</Option>
                  <Option value='14'>违规载人</Option>
                  <Option value='15'>驾驶不合格车辆</Option>
                  <Option value='16'>无证驾驶</Option>
                  <Option value='17'>无法判定</Option>
                  <Option value='18'>自定义</Option>
                </Select>
              </Form.Item>
            </Col>
           {violationShow&&<Col {...topCol}>
              <Form.Item name="violationDescribe" label="自定义违规项目" labelCol={labCol} wrapperCol={wrapCol} rules={[{ required: violationShow, message: '请输入自定义违规项目!' }]}>
                <Input placeholder="请输入" maxLength={128} />
              </Form.Item>
            </Col>}
            <Col span={24}>
              <Form.Item name="costDescribe" label="费用支出说明" labelCol={labCol2} 
              wrapperCol={wrapCol2}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riskFlag" rules={[{ required: true, message: '请选择是否存在潜在风险!' }]} labelCol={labCol} 
              wrapperCol={wrapCol} label="是否存在潜在风险">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} 
                onChange={values=>riskFlagChange(values)}>
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riskDescribe" label="风险描述" labelCol={labCol2} wrapperCol={wrapCol2} 
               rules={riskDescribeRule}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventDescribe" label="事件经过描述" labelCol={labCol2} wrapperCol={wrapCol2} 
              rules={[{ required: true, message: '请填写事件经过描述' }]}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="requireDescribe" label="甲方要求" labelCol={labCol2} wrapperCol={wrapCol2}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="advanceAmount" label="预估垫付金额" labelCol={labCol} wrapperCol={wrapCol}>
                <InputNumber placeholder="请填写" onChange={advanceAmountChange} min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="advanceReason" label="垫付原因" labelCol={labCol2} wrapperCol={wrapCol2} 
              rules={advanceReasonRule}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <div className="left">
              <h2 className="staff-title">附件</h2>
          </div>
            <Col span={24}>
              <Form.Item labelCol={labCol2} name='scenePictureUrl' valuePropName="fileList"
                getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={wrapCol2} label="现场/住院照片">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  multiple
                  maxCount={10}
                  name="files"
                  fileList={previewData.fileList1}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList1')}
                >
                  {previewData.fileList1.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: 'right' }}>订单截图<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
            </Col>
            <Col span={19}>
              <Form.Item name='orderPictureUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  multiple
                  maxCount={10}
                  name="files"
                  fileList={previewData.fileList2}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList2')}
                >
                  {previewData.fileList2.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: 'right' }}>责任认定书<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
            </Col>
            <Col span={19}>
              <Form.Item name='responsibleLetterUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={10}
                  multiple
                  name="files"
                  fileList={previewData.fileList3}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList3')}
                >
                  {previewData.fileList3.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              {(editData.processStatus=='2'||editData.processStatus=='3'||editData.processStatus=='4')&&disabled&&NailQRcode?<div style={{marginBottom: '20px'}} >
                <h2 className="equip-h2">钉钉审批二维码</h2>
                <div>
                  <img style={{width: '150px', height: '150px'}} src={NailQRcode}  />
                </div>
              </div>:null}
            </Col>
            <Col span={24}>
              {spData.length > 0&&disabled&&<div style={{marginBottom: '20px'}} >
                <h2 className="equip-h2">审批节点</h2>
                <Timeline>
                {spData.map((item,index) => (
                    <Timeline.Item key={index}>
                      {!item.afterStatus&&<span>{item.auditUserName} 发起 {item.time}</span>}
                      {item.afterStatus==3&&<span>{item.auditUserName} 通过 {item.time}</span>}
                      {item.afterStatus==3&&item.auditNote&&<span>理由： {item.auditNote}</span>}
                      {item.afterStatus == 4&&<span>{item.auditUserName} 拒绝 {item.time}</span>}
                      {item.afterStatus == 4&&item.auditNote&&<span>理由： {item.auditNote}</span>}
                    </Timeline.Item>
                  ))}
                </Timeline>
              </div>}
            </Col>
          </Row>
        </Form>
        <Modal maskClosable={false}
          width={1000}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
        </Modal>
      </Modal>
    </>
  );
};
export default forwardRef(AddReport)



