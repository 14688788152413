import React, { useImperativeHandle, useState, useEffect, forwardRef } from 'react'
import {
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  Upload,
  Button,
  message,
  Timeline
} from 'antd';
import {
  PlusOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import moment from 'moment'
import { getDingtalkUser, addAccidentReported,updateAccidentReported,getPerson,accidentReportedCheck } from '../../api'
import { getRelevanceCompany, getCodeUrl, } from '../../../../../api/api'
import { getAllRegionalList,getProvinceIdListByRegionalNo,getCityListByProvinceId} from '../../api'
import '../index.css'
import  eventTypedetail from '../../eventTypedetail'
const { TextArea } = Input;
const {  OptGroup } = Select;
const { confirm } = Modal;
const topCol = {
  span: 12,
}

const action = "/wispay/common/uploadFile"
const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const AddMoel = (props, ref) => {
  const { refresh } = props
//   const { cityData } = props
  const [visible, setVisible] = useState(false);
  const [damage, setDamage] = useState(true);
  const [sgqy, setsgqy] = useState(false);
  const [rycl, setrycl] = useState(false);
  const [sjrdzr, setsjrdzr] = useState(false);
  const [title, setTitle] = useState('新增');
  const [disabled, setDisabled] = useState(false);
  const [DingkUser, setDingkUser] = useState([]);
  const [editData, setEditData] = useState([]);
  const [spData, setSpData] = useState([]);
  const [NailQRcode, setNailQRcode] = useState(null);
  const [companyData, setCompanyData] = useState([])
  const [renyunData, setRenyunData] = useState([])
  const [regionalList, setRegionalList] = useState([])
  const [provinceList, setProvinceList] = useState([])
  const [cityList, setCityList] = useState([])
  const [filterEventTypedetail, setFilterEventTypedetail] = useState([])
  const [idNumberd, setidNumberd] = useState()
  const [name, setidName] = useState()
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList1: '',
    fileList2: '',
    fileList3: '',
    fileList4: '',
    fileList5: '',
    fileList6: '',
    fileList7: '',
    previewTitle: '',
  })
  const [form] = Form.useForm()
  const { Option } = Select
  const initialFormValues = {}
  
  const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU：重大刑事案件、涉及刑事或者治安案件，对公司品牌造成严重影响的</p>
      <p className="titp">P0：交通事件致人死亡或2人及以上重伤</p>
      <p className="titp">P1：交通事件致1人重伤</p>
      <p className="titp">P2：交通事件致人轻伤</p>
      <p className="titp">P3：交通事件致人轻微伤</p>
    </div>
  )
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  useEffect(() => {
    getUser()
    getCompany()
    getAllRegional()
  }, [])
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  const changeFile = ({ file, fileList }, type) => {
    fileList = fileList.map(item => {
      if (item.response&&item.response.success) {
        // Component will show file.url as link
        item.url = item.response.data[0].url;
      }
      return item;
    });
    switch (type) {
        case 'zp':
          setPreviewData(prev => {
            return {
              ...prev,
              fileList1: fileList
            }
          })
          break;
        case 'dd':
          setPreviewData(prev => {
            return {
              ...prev,
              fileList2: fileList
            }
          })
          break;
  
        case 'zr':
          setPreviewData(prev => {
            return {
              ...prev,
              fileList3: fileList
            }
          })
          break;
  
        case 'zd':
          setPreviewData(prev => {
            return {
              ...prev,
              fileList4: fileList
            }
          })
          break;
  
        case 'zj':
          setPreviewData(prev => {
            return {
              ...prev,
              fileList5: fileList
            }
          })
          break;
  
        case 'qt':
          setPreviewData(prev => {
            return {
              ...prev,
              fileList6: fileList
            }
          })
          break;
  
        case 'qt1':
          setPreviewData(prev => {
            return {
              ...prev,
              fileList7: fileList
            }
          })
          break;
        default: 
      }
  }
  const setFlieList = (list) => {
      list = list&&list.filter(item=>{
        return item.url
      })
      list = list&&list.map((item,index)=>{
        return {
          url: item.url,
          uid: item.uid || index,
          name: item.name||item.url,
          status: 'done',
        }
      })
      return list
  }
  const setFlieParams = (list,type) => {
    list = list&&list.filter(item=>{
      return item.url
    })
      list = list&&list.map((item,index)=>{
        return {
          url: item.url,
          uid: 0-index,
          name: item.name||item.url,
          status: 'done',
          type: type,
        }
      })
      return list
  }
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (ctitle, data, splist) => {
      form.resetFields()
      setPreviewData({
        previewVisible: false,
        previewImage: null,
        fileList1: '',
        fileList2: '',
        fileList3: '',
        fileList4: '',
        fileList5: '',
        fileList6: '',
        fileList7: '',
        previewTitle: '',
      })
      setRenyunData([])
      setVisible(true);
      setTitle(ctitle)
      setidNumberd(null)
      if(ctitle == '查看'){
        setDisabled(true)
      }else{
        setDisabled(false)
      }
      if(splist){
        setSpData(splist)
      }else{
        setSpData([])
      }
      if (data) {
        setEditData(data)
        data.eventType&&setFilterEventTypedetail(eventTypedetail[data.eventType])
        data.accidentCauseType ={value: String(data.accidentCauseType),label: String(data.accidentCauseTypeName)}
        data.scenePictureUrls = setFlieList(data.scenePictureUrls)
        data.accidentOrderScreenshotUrls = setFlieList(data.accidentOrderScreenshotUrls)
        data.accidentLiabilityUrl = setFlieList(data.accidentLiabilityUrl)
        data.diagnosticProofUrl = setFlieList(data.diagnosticProofUrl)
        data.motorcycleDrivingLicenseUrl = setFlieList(data.motorcycleDrivingLicenseUrl)
        data.otherUrl = setFlieList(data.otherUrl)
        data.otherNotPictureUrl = setFlieList(data.otherNotPictureUrl)
        data.tName = data.tname
        data.accidentMonth = data.accidentDate ? moment(data.accidentDate).format('YYYY年MM月') : null
        data.degreeInjuryType == '5'?setDamage(false):setDamage(true)
        data.accidentCauseTypeName == '其他'?setsgqy(true):setsgqy(false)
        data.riderVehicleType == '0'?setrycl(true):setrycl(false)
        data.accidentLiabilityType == '6'?setsjrdzr(true):setsjrdzr(false)
        form.setFieldsValue(data)
        setidNumberd(data.idNumber)
        setidName(data.name)
        getProvinceIdListByRegionalNo({
            regionalNo:data.regionalNo
        }).then((res)=>{
            setProvinceList(res.data.data)
        })
        getCityListByProvinceId({
            regionalNo:data.regionalNo,
            provinceId:data.provinceId
        }).then((res)=>{
            setCityList(res.data.data)
        })
       

        if(ctitle == '查看'){
          setRenyunData([{idNumber:data.idNumber,name: data.name}])
          setCompanyData([{companyCode:data.companyCode,companyName: data.companyName}])
        }else{
          getPersonList()
        }
        if(data.processInstanceId){
          getQRcode(data)
        }else{
          setNailQRcode('')
        }
      } else {
        setEditData({})
        form.resetFields()
      }
    }
  }));
  // 获取钉钉审批二维码
  const getQRcode = async (row)=>{
    let result = await getCodeUrl({processInstanceId:row.processInstanceId})
    if (result.data.success) {
      setNailQRcode(result.data.data)
    } else {
      message.error(result.data.retMsg)
    }
  }
  const renyuanChange = (value)=>{
    setidNumberd(value)
    let row = renyunData.filter(item=>{
      return item.idNumber == value
    })
    console.log(row)
    form.setFieldsValue({
      tName: row[0].tname,
      phone: row[0].phone,
      staffType: row[0].staffType + '',
    })
    setidName(row[0].name)
  }
  const companyChange = (value)=>{
    // companyCode
    form.setFieldsValue({
      idNumber: null
    })
    getPersonList(value)
  }
  // 获取企业
  const getCompany = async () => {
    const res = await getRelevanceCompany({projectType:2})
    if (res && res.length > 0) {
      setCompanyData(res)
    }
  }
  // 获取人员
  const getPersonList = async (value) => {
    const res = await getPerson({cityIdList:form.getFieldsValue().cityId,companyCodeList:form.getFieldsValue().companyCode})
    if (res.data.success) {
      setRenyunData(res.data.data||[])
    }
  }
  // 新增
  const handleInsert = async (params) => {
    params.scenePictureUrls = setFlieParams(params.scenePictureUrls,'E01')
    params.accidentOrderScreenshotUrls = setFlieParams(params.accidentOrderScreenshotUrls,'E02')
    params.accidentLiabilityUrl = setFlieParams(params.accidentLiabilityUrl,'E04')
    params.diagnosticProofUrl = setFlieParams(params.diagnosticProofUrl,'E05')
    params.motorcycleDrivingLicenseUrl = setFlieParams(params.motorcycleDrivingLicenseUrl,'E06')
    params.otherUrl = setFlieParams(params.otherUrl,'E07')
    params.otherNotPictureUrl = setFlieParams(params.otherNotPictureUrl,'E08')
    let result = await addAccidentReported(params)
    if (result.data.success) {
      message.success(result.data.retMsg)
      setVisible(false)
      refresh('query', {})
    } else {
      message.error(result.data.retMsg)
    }
  }
  // 修改
  const handleUpdate = async (params) => {
    params.scenePictureUrls = setFlieParams(params.scenePictureUrls,'E01')
    params.accidentOrderScreenshotUrls = setFlieParams(params.accidentOrderScreenshotUrls,'E02')
    params.accidentLiabilityUrl = setFlieParams(params.accidentLiabilityUrl,'E04')
    params.diagnosticProofUrl = setFlieParams(params.diagnosticProofUrl,'E05')
    params.motorcycleDrivingLicenseUrl = setFlieParams(params.motorcycleDrivingLicenseUrl,'E06')
    params.otherUrl = setFlieParams(params.otherUrl,'E07')
    params.otherNotPictureUrl = setFlieParams(params.otherNotPictureUrl,'E08')
    params.accidentNo = editData.accidentNo
    let result = await updateAccidentReported(params)
    if (result.data.success) {
      message.success(result.data.retMsg)
      setVisible(false)
      refresh('query', {})
    } else {
      message.error(result.data.retMsg)
    }
  }
  const handleOk = async (submitType) => {
    let nameList = ''
    if(submitType==1){
      nameList = ['accidentDate','cityId','idNumber']
    }
    form.validateFields(nameList).then(async (valus)=>{
      console.log(valus)
      const data = form.getFieldsValue()
      console.log('tijiao', data)
      let date = data.accidentDate ? moment(data.accidentDate).format('YYYY-MM-DD') : null
      let params = Object.assign(data, { accidentDate: date, submitType: submitType,name: name ,accidentCauseType:data.accidentCauseType.value})
      if(submitType==1){
        if(title=='新增'){
          handleInsert(params)
        }else{
          handleUpdate(params)
        }
        return
      }
      let result = await accidentReportedCheck({idNumber: params.idNumber, accidentDate: params.accidentDate,})
      console.log(result)
      let str = ''
      if (result.data.success) {
        if (result.data.data == 2) {
          str = '事件档案中同一人同一天已经有一条事件信息，确定是否提交？'
        } else if (result.data.data == 1) {
          str = '事件报备中同一人同一天已经有一条事件报备处于审批中，确定是否提交？'
        } else {
          str = '是否提交？'
        }
        confirm({
          title: '提示！',
          icon: <ExclamationCircleOutlined />,
          content: str,
          onOk() {
            if(title=='新增'){
              handleInsert(params)
            }else{
              handleUpdate(params)
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        });
        
      }else{
        message.error(result.data.retMsg)
      }
    }).catch(()=>{
      message.error('请完善表单')
    })
  }
  const onChange = (e) => {
    form.setFieldsValue({
      accidentMonth: moment(e).format('YYYY年MM月')
    })
  }
  const getUser = () => {
    getDingtalkUser().then(res => {
      if (res.data.success) {
        setDingkUser(res.data.data)
      }
    })
  }
  const handleCancel = () => {
    setPreviewData(prev => {
      return {
        ...prev,
        previewVisible: false
      }
    })
  }
  // 下拉框
  const selectFilter = (inputValue, option) => {
    if (option.children.indexOf(inputValue) != -1) {
        return option
    }
  }
  const aClick = (e) => {
    if(e == 2){
      setPreviewData(prev => {
        return {
          ...prev,
          previewImage: require('../../../../../images/zeren.png'),
          previewVisible: true,
          previewTitle: '责任认定书示例'
        }
      })
    }else{
      setPreviewData(prev => {
        return {
          ...prev,
          previewImage: require('../../../../../images/dingdan.png'),
          previewVisible: true,
          previewTitle: '事件订单示例'
        }
      })
    }
  }
  const getAllRegional = () => {
    getAllRegionalList().then((res)=>{
        setRegionalList(res.data.data)
    })
  }
  const regionalChage = (value) => {
    getProvinceIdListByRegionalNo({
        regionalNo:value
    }).then((res)=>{
        form.setFieldsValue({
            provinceId:'',
            cityId:''
        })
        setProvinceList(res.data.data)
    })
      console.log(value)
  }
  const ProvinceChage = (value) => {
    getCityListByProvinceId({
        regionalNo:form.getFieldsValue().regionalNo,
        provinceId:value
    }).then((res)=>{
        form.setFieldsValue({
            cityId:''
        })
        console.log(res)
        setCityList(res.data.data)
    })
      console.log(value)
  }
  const eventTypeChange = (value) => {
      setFilterEventTypedetail(eventTypedetail[value])
      form.setFieldsValue({
        accidentCauseType:{value:'',label:''}
    })
    setsgqy(false)
  }
  const selectFilter2 = (inputValue, option) => {
  if (option.children&&option.children.includes(inputValue)) {
    return option
  }}
  
  return (
    <>
      <Modal maskClosable={false}
        title={title}
        width='1000px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={!disabled?[
          <Button key="back" onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={()=>{handleOk(1)}}>
            暂存
          </Button>,
          <Button
            key="link"
            // href="https://google.com"
            type="primary"
            onClick={()=>{handleOk(2)}}
          >
            提交审批
          </Button>,
        ]:null}
      >
        <Form initialValues={initialFormValues} form={form}>
          <Row className="margin-15">
            <Col {...topCol}>
              <Form.Item name="reportedUserId" rules={[{ required: true, message: '请选择报备人!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="报备人">
                <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} showSearch filterOption={(inputValue, option) => selectFilter2(inputValue, option)}>
                {DingkUser.map(item => (
                    <OptGroup label={item.name} key={item.name}>
                        {
                            item.list.map((citem)=>(
                                <Option value={citem.userId} key={citem.userId}>{citem.name}</Option>
                            ))
                        }
                    </OptGroup>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentOrderType" rules={[{ required: true, message: '请选择工单类型!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="工单类型">
                {/* <Input disabled={true} /> */}
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value='1'>客服工单</Option>
                  <Option value='2'>安全工单</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentDate" rules={[{ required: true, message: '请选择事件日期!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件日期">
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  disabled={disabled}
                  allowClear={true}
                  onChange={onChange}
                  format={["YYYY-MM-DD"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentMonth" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件月">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="regionalNo" labelCol={{ span: 6 }} rules={[{ required: true,message: '请选择大区!' }]} wrapperCol={{ span: 13 }} label="大区" >
                <Select placeholder="请选择"   disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} onChange={(value)=>{regionalChage(value)}} >
                  {regionalList.map(item => (
                    <Option value={item.regionalNo} key={item.regionalNo}>{item.regionalName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="provinceId" labelCol={{ span: 6 }} rules={[{ required: true,message: '请选择省份!' }]} wrapperCol={{ span: 13 }} label="省份">
                <Select placeholder="请选择"  disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} onChange={(value)=>{ProvinceChage(value)}} >
                  {provinceList.map(item => (
                    <Option value={item.provinceId} key={item.provinceId}>{item.provinceName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityId" labelCol={{ span: 6 }} rules={[{ required: true,message: '请选择城市!' }]} wrapperCol={{ span: 13 }} label="城市">
                <Select placeholder="请选择" showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)} disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} onChange={(value) => companyChange(value)}>
                  {cityList.map(item => (
                    <Option value={item.cityId} key={item.cityId}>{item.cityName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="companyCode" labelCol={{ span: 6 }} rules={[{ required: true,message: '请选择企业名称!' }]} wrapperCol={{ span: 13 }} label="企业名称">
                <Select placeholder="请选择" showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)} getPopupContainer={triggerNode => triggerNode.parentElement} disabled={disabled} onChange={(value) => companyChange(value)} >
                  {companyData.map(item => (
                    <Option value={item.companyCode} key={item.companyCode}>{item.companyName}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="idNumber" labelCol={{ span: 6 }} rules={[{ required: true,message: '请选择事件人员!' }]} wrapperCol={{ span: 13 }} label="事件人员">
                <Select placeholder="请选择" showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)} getPopupContainer={triggerNode => triggerNode.parentElement} disabled={disabled} onChange={(value) => renyuanChange(value)} >
                  (renyunData?{renyunData.map(item => (
                    <Option value={item.idNumber} key={item.idNumber}>{item.name}</Option>
                  ))}:[])
                </Select>
               
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="tName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="T名称">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="手机号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="身份证号">
                <Input disabled={true} value={idNumberd} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="staffType" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="人员类型">
                {/* <Input disabled={true} /> */}
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} disabled={true} >
                  <Option value='1'>普通</Option>
                  <Option value='2'>优选</Option>
                  <Option value='3'>001</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentLevel" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} tooltip={{ title: content, color: '#FFFFFF' }} label="事件级别">
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} disabled={disabled} >
                  <Option value="ESU">ESU</Option>
                  <Option value="P0">P0</Option>
                  <Option value="P1">P1</Option>
                  <Option value="P2">P2</Option>
                  <Option value="P3">P3</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="事件类型" required style={{ marginBottom: 0 }} labelCol={{ span: 3 }} wrapperCol={{ span: 18 }}>
                <Form.Item
                  name="eventType"
                  rules={[{ required: true, message: '请选择事件类型' }]}
                  style={{ display: 'inline-block', width: 'calc(37% - 8px)' }}
                >
                  <Select placeholder="请选择" disabled={disabled} onChange={(value)=>{eventTypeChange(value)}} getPopupContainer={triggerNode => triggerNode.parentElement} >
                    <Option value="JT">交通</Option>
                    <Option value="XF">消防</Option>
                    <Option value="ZA">治安</Option>
                    <Option value="WW">维稳</Option>
                    <Option value="YW">意外</Option>
                  </Select>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="事件起因" required style={{ marginBottom: 0 }} labelCol={{ span: 3 }} wrapperCol={{ span: 18 }}>
                <Form.Item
                  name="accidentCauseType"
                  rules={[{ required: true, message: '请选择事件起因' }]}
                  style={{ display: 'inline-block', width: 'calc(37% - 8px)' }}
                >
                  <Select labelInValue  placeholder="请选择" disabled={disabled} onChange={(val)=>{ console.log(val); val.label==='其他'?setsgqy(true):setsgqy(false)}} getPopupContainer={triggerNode => triggerNode.parentElement} >
                      {
                          filterEventTypedetail.map((item)=>{
                            return(
                                <Option value={item.id} key={item.id}>{item.name}</Option>
                            )
                          })
                      }
                  </Select>
                </Form.Item>
                {sgqy&&<Form.Item
                  name="accidentCauseOther"
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: sgqy, message: '请输入事件起因说明' }]}
                  style={{ display: 'inline-block', width: 'calc(63% - 8px)', margin: '0 8px' }}
                >
                  <TextArea autoSize={true} disabled={disabled} maxLength={500} placeholder="请输入其他说明，限500字" />
                </Form.Item>}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="人员车辆" required style={{ marginBottom: 0 }} rules={[{ required: true }]} labelCol={{ span: 3 }} wrapperCol={{ span: 18 }}>
                <Form.Item
                  name="riderVehicleType"
                  rules={[{ required: true, message: '请选择人员车辆' }]}
                  style={{ display: 'inline-block', width: 'calc(37% - 8px)' }}
                >
                  <Select placeholder="请选择" disabled={disabled} onChange={(val)=>{val=='0'?setrycl(true):setrycl(false)}} getPopupContainer={triggerNode => triggerNode.parentElement} >
                    <Option value="1">电动车</Option>
                    <Option value="2">摩托车</Option>
                    <Option value="0">其他</Option>
                  </Select>
                </Form.Item>
                {rycl&&<Form.Item
                  name="riderVehicleOther"
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: rycl, message: '请输入人员车辆说明' }]}
                  style={{ display: 'inline-block', width: 'calc(63% - 8px)', margin: '0 8px' }}
                >
                  <TextArea autoSize={true} disabled={disabled} maxLength={500} placeholder="请输入其他说明，限500字" />
                </Form.Item>}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="accidentAddress" label="事件地点" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={200} disabled={disabled} placeholder="请输入事件地点，限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="事件认定责任" required style={{ marginBottom: 0 }} rules={[{ required: true }]} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                <Form.Item
                  name="accidentLiabilityType"
                  rules={[{ required: true, message: '请选择事件认定责任!' }]}
                  style={{ display: 'inline-block', width: 'calc(33% - 4px)' }}
                >
                  <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement}  onChange={(val)=>{val=='6'?setsjrdzr(true):setsjrdzr(false)}}>
                  <Option value="0">对方全责</Option>
                  <Option value="1">对方主责</Option>
                  <Option value="2">双方同责</Option>
                  <Option value="3">我方全责</Option>
                  <Option value="4">我方主责</Option>
                  <Option value="5">责任待定</Option>
                  <Option value="6">其他</Option>
                </Select>
                </Form.Item>
                {sjrdzr&&<Form.Item
                  name="accidentLiabilityOther"
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: sjrdzr, message: '请输入事件认定责任说明' }]}
                  style={{ display: 'inline-block', width: 'calc(60% - 8px)', margin: '0 8px' }}
                >
                  <TextArea autoSize={true} disabled={disabled} maxLength={500} placeholder="请输入其他说明，限500字" />
                </Form.Item>}
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item name="accidentLiabilityType" labelCol={{ span: 4 }} rules={[{ required: true,message: '请选择事件认定责任!' }]} wrapperCol={{ span: 18 }} label="事件认定责任" style={{ display: 'inline-block', width: 'calc(37% - 8px)' }}>
                <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement}  onChange={(val)=>{val=='6'?setsjrdzr(true):setsjrdzr(false)}}>
                  <Option value="0">对方全责</Option>
                  <Option value="1">对方主责</Option>
                  <Option value="2">双方同责</Option>
                  <Option value="3">我方全责</Option>
                  <Option value="4">我方主责</Option>
                  <Option value="5">责任待定</Option>
                  <Option value="6">其他</Option>
                </Select>
              </Form.Item>
              {sjrdzr&&<Form.Item
                  name="accidentLiabilityOther"
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: sjrdzr, message: '请输入人员车辆说明' }]}
                  style={{ display: 'inline-block', width: 'calc(63% - 8px)', margin: '0 8px' }}
                >
                  <TextArea autoSize={true} disabled={disabled} maxLength={500} placeholder="请输入其他说明，限500字" />
                </Form.Item>}
            </Col> */}
            <Col {...topCol}>
              <Form.Item name="degreeInjuryType" labelCol={{ span: 6 }} rules={[{ required: true,message: '请选择人身伤害程度!' }]} wrapperCol={{ span: 13 }} label="人身伤害程度">
                <Select placeholder="请选择" disabled={disabled} 
                getPopupContainer={triggerNode => triggerNode.parentElement}
                onChange={(val)=>{val=='5'?setDamage(false):setDamage(true); form.setFieldsValue({ damageType: null })}} >
                  <Option value="0">轻伤</Option>
                  <Option value="1">中度伤</Option>
                  <Option value="2">重伤</Option>
                  <Option value="4">死亡</Option>
                  <Option value="5">无</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="propertyType" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="财产伤害">
                <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="0">对方财产</Option>
                  <Option value="1">我方财产</Option>
                  <Option value="2">双方财产</Option>
                  <Option value="3">无财产伤害</Option>
                </Select>
              </Form.Item>
            </Col>
            {damage && <Col {...topCol}>
              <Form.Item name="damageType" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="人身伤害">
                <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="0">对方人身</Option>
                  <Option value="1">我方人身</Option>
                  <Option value="2">双方人身</Option>
                  <Option value="3">无人身伤害</Option>
                </Select>
              </Form.Item>
            </Col>}
            <Col {...topCol}>
              <Form.Item name="insuranceCompanyType" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="保险公司">
                <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="0">平安</Option>
                  <Option value="1">国泰</Option>
                  <Option value="4">前海</Option>
                  <Option value="2">太平洋</Option>
                  <Option value="5">泰康三者险</Option>
                  <Option value="3">无商业保险</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insurancePolicyNo" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="保单号">
                <Input placeholder="请输入保单号" disabled={disabled} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件工单号">
                <Input placeholder="请输入事件工单号" disabled={disabled} maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="accidentDescribe" label="事件描述" rules={[{ required: true }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea maxLength={500} disabled={disabled} placeholder="请输入事件描述，限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} name='scenePictureUrls' valuePropName="fileList"
                getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={{ span: 19 }} label="现场照片">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  disabled={disabled}
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={5}
                  name="files"
                  fileList={previewData.fileList1}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'zp')}
                >
                  {previewData.fileList1.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: 'right' }}>事件订单截图<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
              <div style={{ textAlign: 'center' }}><Button type="link" onClick={()=>{aClick(1)}} block>查看示例图</Button></div>
            </Col>
            <Col span={19}>
              <Form.Item name='accidentOrderScreenshotUrls' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  disabled={disabled}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={5}
                  name="files"
                  fileList={previewData.fileList2}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'dd')}
                >
                  {previewData.fileList2.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: 'right' }}>责任认定书<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
              <div style={{ textAlign: 'center' }}><Button type="link" onClick={()=>{aClick(2)}} block>查看示例图</Button></div>
            </Col>
            <Col span={19}>
              <Form.Item name='accidentLiabilityUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={5}
                  disabled={disabled}
                  name="files"
                  fileList={previewData.fileList3}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'zr')}
                >
                  {previewData.fileList3.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} name='diagnosticProofUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={{ span: 19 }} label="诊断证明或病历">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  disabled={disabled}
                  maxCount={5}
                  name="files"
                  fileList={previewData.fileList4}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'zd')}
                >
                  {previewData.fileList4.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: 'right',paddingRight: '10px' }}>摩托车驾驶证行驶证<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
            </Col>
            <Col span={19}>
              <Form.Item name='motorcycleDrivingLicenseUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  disabled={disabled}
                  maxCount={5}
                  name="files"
                  fileList={previewData.fileList5}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'zj')}
                >
                  {previewData.fileList5.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} name='otherUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={{ span: 19 }} label="其他">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={5}
                  disabled={disabled}
                  name="files"
                  fileList={previewData.fileList6}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'qt')}
                >
                  {previewData.fileList6.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="otherNotPictureUrl"
                label="其他(非图片)"
                getValueFromEvent={(e) => normFile(e, 'list')}
                valuePropName="fileList"
                labelCol={{ span: 3 }} wrapperCol={{ span: 19 }}
              >
                <Upload name="files" data={uploadObj}
                  fileList={previewData.fileList7}
                  action={action}
                  disabled={disabled}
                  maxCount={5}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'qt1')}
                  listType="picture">
                    {previewData.fileList7.length >= 5 ? null : !disabled&&<Button type="primary">上传</Button>}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              {(editData.processStatus=='2'||editData.processStatus=='3'||editData.processStatus=='4')&&disabled&&NailQRcode?<div style={{marginBottom: '20px'}} >
                <h2 className="equip-h2">钉钉审批二维码</h2>
                <div>
                  <img style={{width: '150px', height: '150px'}} src={NailQRcode}  />
                </div>
              </div>:null}
            </Col>
            <Col span={24}>
              {spData.length > 0&&disabled&&<div style={{marginBottom: '20px'}} >
                <h2 className="equip-h2">审批节点</h2>
                <Timeline>
                {spData.map((item,index) => (
                    <Timeline.Item key={index}>
                      {!item.afterStatus&&<span>{item.auditUserName} 发起 {item.time}</span>}
                      {item.afterStatus==3&&<span>{item.auditUserName} 通过 {item.time}</span>}
                      {item.afterStatus==3&&item.auditNote&&<span>理由： {item.auditNote}</span>}
                      {item.afterStatus == 4&&<span>{item.auditUserName} 拒绝 {item.time}</span>}
                      {item.afterStatus == 4&&item.auditNote&&<span>理由： {item.auditNote}</span>}
                    </Timeline.Item>
                  ))}
                </Timeline>
              </div>}
            </Col>
          </Row>
        </Form>
        <Modal maskClosable={false}
          width={1000}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
        </Modal>
      </Modal>
    </>
  );
};
export default forwardRef(AddMoel)



