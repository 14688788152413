import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Table,
    Select,
    Form,
    Radio,
    Button,
    DatePicker,
    Space
} from 'antd';
import qs from 'qs'
import moment from 'moment';
import BankReceiptDetail1 from './BankReceiptDetail1'
import BankReceiptDetail2 from './BankReceiptDetail2'
import store from '../../../../store/index'
import { request } from '../../../../utils/fetch'
import { getTaxEnterpriseList } from '../../../../api/api'
const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;
const itemStyle = {
    width: '350px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}

function SettleQuery(props) {
    let path = 'platform:Settlement'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g, ''));
    const [form] = Form.useForm();
    const initSearch = {
        date: [],
        // companyCode: ''
    }
    const [companyList, setCompanyList] = useState([])
    const [dayValue, setDayValue] = React.useState('');
    const [tableData, setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [isDetail, setIsDetail] = useState(false)
    const [parmObj, setParmObj] = useState({})       //传给明细的参数
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])

    const columns = [
        {
            title: '支付完成时间',
            dataIndex: 'statusUpdateTime',
            key: 'statusUpdateTime',
            width: 150,
            align: 'center',
        },
        { title: '财税企业', dataIndex: 'taxEnterpriseName', key: 'taxEnterpriseName', align: 'center', width: 150 },
        {
            title: '企业名称',
            dataIndex: 'companyCodeName',
            key: 'companyCodeName',
            width: 150,
            align: 'center',
        }, 
        {
            title: '金额',
            dataIndex: 'sureCommissionAmount',
            key: 'sureCommissionAmount',
            width: 120,
            align: 'center',
        },
        {
            title: '人数',
            dataIndex: 'realityStaffCount',
            key: 'realityStaffCount',
            width: 120,
            align: 'center',
        }, 
        {
            title: '操作',
            key: 'action',
            width: 120,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 && <a onClick={() => { methodsFn.goDetail(record) }}>明细</a>}
                    {/* <a onClick={() => {}}>下载错误文件</a> */}
                </Space>
            ),
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        <FormItem label="支付完成时间" name="date" style={{ marginLeft: '48px' }} >
                            <RangePicker
                                onChange={methodsFn.timeChange}
                            />
                        </FormItem>
                        <FormItem style={{ with: '600px', marginLeft: '26px' }} label="">
                            <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                                <Radio value={1}>今天</Radio>
                                <Radio value={7}>近7天</Radio>
                                <Radio value={30}>近30天</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Row>
                    <Row>
                        <FormItem label="企业名称" name="companyCode" style={itemStyle} {...labelStyleObj}>
                            <Select placeholder="全部">
                                {
                                    companyList.map(item => {
                                        return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                        <FormItem label="财税企业" name="taxEnterpriseCode" style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder="全部">
                                {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                            </Select>
                        </FormItem>
                    </Row>
                    <Row>
                        <Col span={24} md={24} lg={24}>
                            <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                                <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn = {
        // 获取所有财税企业
        async getTaxEnterpriseListall(){
            const res = await getTaxEnterpriseList()
            if (res.data.success) {
                setTaxEnterpriseLis(res.data.data)
            }
        },
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1, 'd').format('YYYY-MM-DD'),
                endDate = moment().format('YYYY-MM-DD');

            setDayValue(e.target.value)
            form.setFieldsValue({
                date: [moment(startDate), moment(endDate)]
            })
        },
        timeChange(dates, dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate), moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date: dateArr
            })

        },
        getsearchObj() {
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if (formObj.date.length > 1) {
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            } else {
                beginTime = ''
                endTime = ''
            }
            let obj = {
                beginTime: beginTime,
                endTime: endTime,
                source: 1,
                paymentStatusList: ['WC_QBZF','WC_CW'],
                lineType: 1, 
                companyCode: formObj.companyCode,
                taxEnterpriseCode: formObj.taxEnterpriseCode, 
            }
            return obj
        },
        handleSearch() {
            setPageNum(1)
            if(pageNum==1){
                methodsFn.getTableList(methodsFn.getsearchObj())
            }
        },
        handleFormReset() {
            setDayValue('')
            form.resetFields();
        },
        async getCompanyList() {
            let result = await request.get('/platform/companyBase/allList')
            setCompanyList(result)
        },
        async getTableList(searchObj) {
            let result = await request.get('/platform/settlement/getList', {
                ...searchObj,
                pageSize: pageSize,
                pageNum: pageNum,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },

        goDetail(record) {
            setIsDetail(true)
            props.history.push('/admin/BankReceipt?settlementNo=' + record.settlementNo + '&lineType=' + record.lineType)
            setParmObj({
                settlementNo: record.settlementNo,
                lineType: record.lineType,
            })
        },
        detailFn() {
            setIsDetail(false)
            props.history.push('/admin/BankReceipt')
        },
        handleExport() {
            request.downExport('/platform/settlement/export', methodsFn.getsearchObj())
        }
    }

    useEffect(() => {
        methodsFn.getCompanyList()
        methodsFn.getTaxEnterpriseListall()

        if (props.location.search) {       //解决明细页面刷新问题
            methodsFn.goDetail({
                settlementNo: searchParam.settlementNo,
                lineType: searchParam.lineType,
            })
        }
    }, [])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    }, [pageNum])

    return (
        <div>
            {
                !isDetail ?
                    <div>
                        {myComponent.renderSearchForm()}
                        <Table
                            scroll={{ x: 'max-content', y: 510 }}
                            columns={columns}
                            dataSource={tableData}
                            rowKey={(record) => record.settlementNo}
                            pagination={{
                                position: ['bottomCenter'],
                                total: total,
                                current: pageNum,
                                pageSize: pageSize,
                                showSizeChanger: false,
                                showTotal: (total) => `共 ${total} 条数据`,
                                onChange: (pageNum, pageSize) => setPageNum(pageNum)
                            }}
                        />
                    </div>
                    : parmObj.lineType == 1
                        ? <BankReceiptDetail1 isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn} />
                        : <BankReceiptDetail2 isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn} />
            }

        </div>
    )
}

export default SettleQuery
