import React,{ useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { 
  Modal,
  Input,
  Form,
  Select,
  message,
} from 'antd';
import {request} from '../../../../utils/fetch'

const FormItem = Form.Item;
const { Option } = Select;

const MyModel1 = (props,ref) => {
  const [form] = Form.useForm();
  const { TaxEnterpriseLisStatus } = props
  const [provinceName,setprovinceName] = useState('')
  const [cityName,setcityName] = useState('')
  const [countyName,setcountyName] = useState('')
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({
    provinceId: '',
    cityId: '',
    countyId: ''
  });
  const [areaNo, setAreaNo] = React.useState('');
  

   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (val) => {
      setVisible(true);
      setAreaNo('')
      setprovinceName('')
      setcityName('')
      setcountyName('')
      setInitialValues({
        provinceId: '',
        cityId: '',
        countyId:''
      })
      form.resetFields()
    },
    reviseModal: (obj) => {
      setVisible(true)
      setAreaNo(obj.areaNo)
      setprovinceName(obj.provinceName)
      setcityName(obj.cityName)
      setcountyName(obj.countyName)
      setInitialValues({
        provinceId: obj.provinceId,
        cityId: obj.cityId,
        countyId: obj.countyId
      })
      form.setFieldsValue(obj)
    },
  }));
  
  const methodsFn =  {
    async handleOk(){
      try {
        const values = await form.validateFields();
        setLoading(true);
        if(props.titleModel == '新增'){
          methodsFn.handleAdd({...values,...initialValues})
          return
        }
        methodsFn.handleModify(values)
        
      } catch (errorInfo) {
        console.log('Failed:', errorInfo);
      }
    },
    handleAdd(obj){
      request.post('/platform/sysAreaBase/insert',obj).then(res => {
          setVisible(false);
          setLoading(false);
          message.success(res.msg)
          props.refresh()
      }).catch(err => setLoading(false))
    },
    taxEnterChange(value){
      let item = TaxEnterpriseLisStatus.filter(e=>e.code == value)
      setprovinceName(item[0].provinceName)
      setcityName(item[0].cityName)
      setcountyName(item[0].countyName)
      setInitialValues({
        provinceId: item[0].provinceId,
        cityId: item[0].cityId,
        countyId: item[0].countyId
      })
    },
    handleModify(obj){
      request.post('/platform/sysAreaBase/update',{
        areaNo:areaNo,
        certificateCount:obj.certificateCount
      }).then(res => {
        setVisible(false);
        setLoading(false);
        message.success(res.msg)
        props.refresh()
      }).catch(err => setLoading(false))
    },
      
  }
  useEffect(() => {
    if(props.titleModel == '修改') {
      setDisabled(true)
      return
    }
    setDisabled(false)

  },[props.titleModel])


  return (
    <>
      <Modal maskClosable={false}
        forceRender
        title={props.titleModel}
        width='800px'
        visible={visible}
        onOk={methodsFn.handleOk}
        confirmLoading={loading}
        onCancel={() => {setVisible(false);}}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 10 }}
          initialValues={initialValues}
          form={form}
        > 
            <FormItem label="财税企业" name="taxEnterpriseCode" rules={[{required: true,message: '请选择财税企业',},]}>
                <Select disabled={disabled} allowClear onChange={methodsFn.taxEnterChange}>
                    {TaxEnterpriseLisStatus.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                </Select>
              </FormItem>
            <FormItem label="落地省份">
              <Input value={provinceName} disabled={true}></Input>
          </FormItem>
          <FormItem label="落地城市">
            <Input value={cityName} disabled={true}></Input>
          </FormItem>
          <FormItem label="落地区县">
              <Input value={countyName} disabled={true}></Input>
            </FormItem>
            <FormItem 
              name="planType" 
              label="落地方案" 
              rules={[
                {
                  required: true,
                  message: '请选择落地方案',
                },
              ]}
            >
              <Select disabled={disabled}>
                <Option value={1}>临时税务登记</Option>
                <Option value={2}>个体工商户</Option>
                <Option value={3}>委托代征</Option>
              </Select>
            </FormItem>
            <FormItem 
              name="certificateCount" 
              label="证件容量" 
              rules={[
                {
                  required: true,
                  message: '请填写证件容量',
                },
              ]}
            >
              <Input placeholder=""/>
            </FormItem>
            </Form>
      </Modal>
    </>
  );
};
export default forwardRef(MyModel1)



