import {
    request
} from '../../../utils/fetch'

// 众包人员-列表
export const getCrowdStaffList = (params) => {
    return request.requestGet('/platform/crowdStaff/getList', params)
}
// 众包人员-详情
export const getCrowdStaffDetail = (params) => {
    return request.requestGet('/platform/crowdStaff/getDetail', params)
}
// 众包人员-修改
export const updateCrowdStaff = (data) => {
    return request.requestPost('/platform/crowdStaff/update', data)
}
// 众包人员-新增
export const insertCrowdStaff = (data) => {
    return request.requestPost('/platform/crowdStaff/insert', data)
}
// 众包人员-删除
export const deleteCrowdStaff = (data) => {
    return request.requestPost('/platform/crowdStaff/delete', data)
}
// 获取发起人
export const getDingtalkUser = (params)　=> {
    return request.requestGet('/platform/dingtalkUser/selectDingtalkUser', params)
}
// 事件报备-列表
export const getAccidentReportedList = (params)　=> {
    return request.requestGet('/platform/accidentReported/getList', params)
}
// 事件报备-新增
export const addAccidentReported = (params)　=> {
    return request.requestPost('/platform/accidentReported/insert', params)
}
// 事件报备-修改
export const updateAccidentReported = (params)　=> {
    return request.requestPost('/platform/accidentReported/update', params)
}
// 事件报备-删除
export const deleteAccidentReported = (params)　=> {
    return request.requestGet('/platform/accidentReported/delete', params)
}
// 事件报备-详情
export const getDetailAccidentReported = (params)　=> {
    return request.requestGet('/platform/accidentReported/getDetail', params)
}
// 事件报备-获取人员
export const getPerson = (params)　=> {
    return request.requestPost('/platform/accidentReported/getPerson', params)
}
// 事件报备-信息校验
export const accidentReportedCheck = (params)　=> {
    return request.requestGet('/platform/accidentReported/check', params)
}

// 事件档案-列表
export const getAccidentArchivesList = (params)　=> {
    return request.requestGet('/platform/accidentArchives/getList', params)
}
// 事件档案-详情
export const getDetailAccidentArchives = (params)　=> {
    return request.requestGet('/platform/accidentArchives/getDetail', params)
}
// 事件档案-修改
export const updateAccidentArchives = (params)　=> {
    return request.requestPost('/platform/accidentArchives/update', params)
}
// 事件档案-下载
export const download = (params)　=> {
    return request.downExport('/platform/accidentArchives/download', params)
}
// 事件档案-批量下载
export const batchDownload = (params)　=> {
    return request.downExport('/platform/accidentArchives/batchDownload', params)
}
// 事件结案-列表
export const getAccidentCaseList = (params)　=> {
    return request.requestGet('/platform/accidentCase/getList', params)
}
// 事件结案-新增
export const addAccidentCase = (params)　=> {
    return request.requestPost('/platform/accidentCase/insert', params)
}
// 事件结案-修改
export const updateAccidentCase = (params)　=> {
    return request.requestPost('/platform/accidentCase/update', params)
}
// 事件结案-详情
export const getDetailAccidentCase = (params)　=> {
    return request.requestGet('/platform/accidentCase/getDetail', params)
}
// 事件结案-删除
export const deleteDetailAccidentCase = (params)　=> {
    return request.requestGet('/platform/accidentCase/delete', params)
}
// 事件结案-获取人员下拉列表
export const getAccidentStaff = (params)　=> {
    return request.requestGet('/platform/accidentCase/getAccidentStaff', params)
}
// 事件结案-根据事件员工 获取事件编码及相关信息
export const getAccidentNo = (params)　=> {
    return request.requestGet('/platform/accidentCase/getAccidentNo', params)
}
// 提现证明-新增
export const withdrawProveInsert = (params)　=> {
    return request.requestPost('/platform/crowdWithdrawal/insert', params)
}
// 众包管理 根据大区 省份查询城市
export const getCityListByProvinceId = (params)　=> {
    return request.requestGet('/common/getCityListByProvinceId', params)
}
// 提现证明 -列表
export const withdrawProveList = (params)　=> {
    return request.requestGet('/platform/crowdWithdrawal/getList', params)
}
//提现证明-详情
export const withdrawProveDetail = (params)　=> {
    return request.requestGet('/platform/crowdWithdrawal/getDetail', params)
}
//提现证明-获取印章地址
export const getTaxEnterpriseSealUrl = (params)　=> {
    return request.requestGet('/common/getTaxEnterpriseSealUrl', params)
}
//提现证明-删除
export const withdrawProveDelete = (params)　=> {
    return request.requestPost('/platform/crowdWithdrawal/delete', params)
}
//大区管理-列表
export const regionalList = (params)　=> {
    return request.requestGet('/platform/regional/list', params)
}
//大区管理-新增
export const regionalAdd = (params)　=> {
    return request.requestPost('/platform/regional/add', params)
}
//大区管理-修改
export const regionalUpdate = (params)　=> {
    return request.requestPost('/platform/regional/update', params)
}
//事故报备-获取所有大区
export const getAllRegionalList = (params)　=> {
    return request.requestGet('/common/getAllRegionalList', params)
}
//事故报备-通过大区 获取省下拉列表
export const getProvinceIdListByRegionalNo = (params)　=> {
    return request.requestGet('/common/getProvinceIdListByRegionalNo', params)
}
//事故报备-详情
export const regionalGetDetail = (params)　=> {
    return request.requestGet('/platform/regional/getDetail', params)
}
//事件档案-获取短信模板列表
export const getSmsTemplateList = (params)　=> {
    return request.requestGet('/common/getSmsTemplateList', params)
}
//事件档案-获取短信发送历史
export const getSmsLogList = (params)　=> {
    return request.requestGet('/common/getSmsLogList', params)
}
//事件档案-发送短信
export const sendSms = (params)　=> {
    return request.requestGet('/platform/accidentArchives/sendSms', params)
}
//事件报备-发送短信
export const reportedSendSms = (params)　=> {
    return request.requestGet('/platform/accidentReported/sendSms', params)
}
//事件报备-发送短信
export const crowdMerchantBillList = (params)　=> {
    return request.requestGet('/platform/crowdMerchantBill/list', params)
}
// 骑手账单-列表
export const getCrowdRiderBillList = (params) => {
    return request.requestGet('/platform/crowdRiderBill/list', params)
}
// 人之商账单-列表
export const getcrowdMerchantBillList = (params) => {
    return request.requestGet('/platform/crowdMerchantBill/list', params)
}
export const sendMessageToDing = (params)　=> {
    return request.requestGet('/platform/accidentArchives/sendMessageToDing', params)
}
//报备修改身份证号
export const updateIdNumber = (params)　=> {
    return request.requestGet('/platform/accidentReported/updateIdNumber', params)
}

//交安跟踪列表
export const trafficSafetyGetList = (params) => {
    return request.requestGet('/platform/trafficEvent/getList', params)
}
//交安跟踪详情
export const trafficSafetyGetDetail = (params) => {
    return request.requestGet('/platform/trafficEvent/getDetail', params)
}
//交安跟踪新增
export const trafficSafetyInsert = (params) => {
    return request.requestPost('/platform/trafficEvent/insert', params)
}
//交安跟踪修改
export const trafficSafetyUpdate = (params) => {
    return request.requestPost('/platform/trafficEvent/update', params)
}
//交安跟踪跟进
export const trafficSafetyFollow = (params) => {
    return request.requestPost('/platform/trafficEvent/follow', params)
}
//交安跟踪记录
export const trafficSafetyFollowRecord = (params) => {
    return request.requestGet('/platform/trafficEvent/followRecord', params)
}
//交安发送提醒
export const sendMsg = (params) => {
    return request.requestPost('/platform/trafficEvent/sendFollowMsg', params)
}
//交安跟踪结案
export const trafficSafetyEventCase = (params) => {
    return request.requestPost('/platform/trafficEvent/eventCase', params)
}
//交安跟踪删除
export const trafficSafetyDelete = (params) => {
    return request.requestPost('/platform/trafficEvent/delete', params)
}
//交安档案列表
export const trafficArchivesGetList = (params) => {
    return request.requestGet('/platform/trafficEventArchives/getList', params)
}
//交安档案详情
export const trafficArchivesGetDetail = (params) => {
    return request.requestGet('/platform/trafficEventArchives/getDetail', params)
}
//交安档案修改
export const trafficArchivesUpdate = (params) => {
    return request.requestPost('/platform/trafficEventArchives/update', params)
}
