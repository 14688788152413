import React, { useState, useEffect, useRef } from 'react'
import { Row, Table, Select, Form, Button, message, DatePicker, Upload, Modal, Radio, Col, Space, Typography } from 'antd'

import axios from 'axios'
import moment from 'moment'
import { UploadOutlined } from '@ant-design/icons'
import { getcrowdMerchantBillList } from '../api'
import { request } from '../../../../utils/fetch'
import EditModel from './EditModel'
import store from '../../../../store/index'
const FormItem = Form.Item
const { Option } = Select
const { Link } = Typography
const { RangePicker } = DatePicker

// 上传弹窗表单样式
const formItemLayout = {
    labelCol: {
        span: 6
    },
    wrapperCol: {
        span: 14
    }
}
function CrowdsourcePersonnel(props) {
    let path = 'platform:crowdMerchantBill'
    const [form] = Form.useForm() //搜索表单
    const [form2] = Form.useForm() //上传文件表单
    const [fileList, setFileList] = useState([]) //文件列表
    // 初始化搜索数据
    const initSearch = {
        date: null,
        timeType: '1'
    }
    const [tableData, setTableData] = useState([])
    const [visible, setVisible] = useState(false) //文件上传弹窗
    const [loading, setLoading] = useState(false)
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const [radio, setRadio] = useState(0)
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const EditModelRef = useRef()
    const fileProps = {
        //表格上传配置和方法
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept: '.xls, .xlsx',
        beforeUpload: () => {
            return false
        },

        onChange(info) {
            let fileCon = [...info.fileList]
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1)
            setFileList(fileCon)
        }
    }
    const columns = [
        {
            title: '人资商ID',
            dataIndex: 'merchantId',
            width: 150,
            align: 'center'
        },
        {
            title: '人资商名称',
            dataIndex: 'merchantName',
            width: 150,
            align: 'center'
        },
        {
            title: '出账日期',
            dataIndex: 'billDate',
            width: 150,
            align: 'center'
        },
        {
            title: '账单周期-开始时间',
            dataIndex: 'billBeginDate',
            width: 150,
            align: 'center'
        },
        {
            title: '账单周期-结束时间',
            dataIndex: 'billEndDate',
            width: 150,
            align: 'center'
        },
        {
            title: '合计',
            dataIndex: 'totalAmount',
            width: 150,
            align: 'center'
        },
        {
            title: '操作',
            width: 150,
            align: 'center',
            fixed: 'right',
            render: (text, record) => {
                return (
                    <Space size='middle'>
                        <Link onClick={() => view(record)}>明细</Link>
                    </Space>
                )
            }
        }
    ]

    //搜索组件
    const renderSearchForm = () => (
        <Form form={form} initialValues={initSearch}>
            <Row style={{ display: 'flex', paddingLeft: '30px' }}>
                <Form.Item style={{ marginBottom: 0 }} wrapperCol={{ span: 24 }}>
                    <Form.Item style={{ display: 'inline-block', width: '110px' }} name='timeType'>
                        <Select>
                            <Option value='1'>账单周期</Option>
                            <Option value='2'>出账日期</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item style={{ display: 'inline-block', width: '310px' }} name='date'>
                        <RangePicker allowClear onChange={rangeChange} />
                    </Form.Item>
                </Form.Item>
                <Radio.Group style={{ height: '32px', alignItems: 'center', display: 'flex', marginLeft: '10px' }} onChange={radioChange} value={radio}>
                    <Radio value={1}>今天</Radio>
                    <Radio value={7}>近7天</Radio>
                    <Radio value={30}>近30天</Radio>
                </Radio.Group>
            </Row>
            <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }}>
                        {permissions.includes(`${path}:list`) && (
                            <Button type='primary' onClick={handleSearch}>
                                查询
                            </Button>
                        )}
                        <Button style={{ marginLeft: 10 }} onClick={handleFormReset}>
                            重置
                        </Button>
                        {permissions.includes(`${path}:export`) && (
                            <Button style={{ marginLeft: 10 }} onClick={handleExport}>
                                导出
                            </Button>
                        )}
                    </FormItem>
                </Col>
            </Row>
        </Form>
    )
    // 搜索参数
    const getsearchObj = () => {
        let formObj = form.getFieldsValue(),
            time
        if (formObj.date) {
            time = {
                timeType: formObj.timeType,
                beginTime: formObj.date[0].format('YYYY-MM-DD'),
                endTime: formObj.date[1].format('YYYY-MM-DD')
            }
        }

        return {
            ...time
        }
    }
    //搜索
    const handleSearch = () => {
        setPageNum(1)
        if (pageNum == 1) {
            getTableList()
        }
    }
    // 重置搜索表单
    const handleFormReset = () => {
        form.resetFields()
        setRadio(0)
    }
    //获取列表
    const getTableList = () => {
        getcrowdMerchantBillList({
            pageSize: pageSize,
            pageNum: pageNum,
            ...getsearchObj()
        })
            .then(res => {
                if (res.data.success) {
                    console.log(res.data)
                    setTotal(res.data.data.totalCount)
                    setTableData(res.data.data.list)
                } else {
                    message.error(res.data.retMsg)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    // 上传文件
    const handleUp = async () => {
        let values = await form2.validateFields()
        console.log(values)
        let data = new FormData() //通过FormData将文件转成二进制数据
        let file = values.ffile.file
        data.append('token', localStorage.getItem('token'))
        data.append('mobile', localStorage.getItem('mobile'))
        data.append('file', file)

        setLoading(true)

        axios({
            method: 'post',
            url: `/wispay/platform/crowdMerchantBill/import`,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                setLoading(false)
                if (res.data.success) {
                    setVisible(false)
                    message.success(res.data.retMsg)
                    handleSearch()
                } else {
                    message.error(res.data.retMsg)
                }
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
    }
    // 导出
    const handleExport = () => {
        request.downExport('/platform/crowdMerchantBill/export', {
            ...getsearchObj()
        })
    }
    const view = record => {
        EditModelRef.current.showModal(record)
    }

    // 时间radio选择
    const radioChange = e => {
        let startDate, endDate
        setRadio(e.target.value)
        startDate = moment()
            .subtract(e.target.value - 1, 'days')
            .startOf('day')
        endDate = moment()
        form.setFieldsValue({ date: [startDate, endDate] })
    }
    const rangeChange = dates => {
        if (dates === null) {
            setRadio(0)
        } else if (dates[1].format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
            //结束日期是今天
            let diff = dates[1].diff(dates[0], 'days')
            setRadio(diff + 1)
        } else {
            setRadio()
        }
    }

    useEffect(() => {
        getTableList()
    }, [pageNum])

    return (
        <div>
            <div>
                {permissions.includes(`${path}:import`) && (
                    <div style={{ display: 'flex', background: '#fff', marginBottom: '20px', padding: '30px' }}>
                        <div>
                            <div>上传表格请使用固定的 导入模板</div>
                        </div>
                        {
                            <Button
                                type='primary'
                                style={{ marginLeft: '10px' }}
                                onClick={() => {
                                    setFileList([])
                                    form2.resetFields()
                                    setVisible(true)
                                }}
                            >
                                导入
                            </Button>
                        }
                    </div>
                )}
                {renderSearchForm()}
                <Table
                    scroll={{ x: 'max-content', y: 450 }}
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={tableData}
                    pagination={{
                        position: ['bottomCenter'],
                        total: total,
                        current: pageNum,
                        pageSize: pageSize,
                        showSizeChanger: false,
                        showTotal: total => `共 ${total} 条数据`,
                        onChange: (pageNum, pageSize) => setPageNum(pageNum)
                    }}
                />
            </div>

            <Modal maskClosable={false}
                title='导入'
                confirmLoading={loading}
                visible={visible}
                onOk={handleUp}
                onCancel={() => {
                    setVisible(false)
                }}
            >
                <Form form={form2} {...formItemLayout}>
                    <Form.Item label='上传表格' name='ffile' rules={[{ required: true, message: '请上传表格' }]}>
                        <Upload {...fileProps}>
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                            <Button
                                type='link'
                                onClick={e => {
                                    e.stopPropagation()
                                    window.location.href = `/wispay/common/getTemplateDownloadUrl?token=${localStorage.getItem('token')}&mobile=${localStorage.getItem('mobile')}&type=PT_RZSZDDR`
                                }}
                            >
                                下载模板
                            </Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
            <EditModel ref={EditModelRef} refres={handleSearch} />
        </div>
    )
}

export default CrowdsourcePersonnel
