import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import {request} from '../../../../utils/fetch'
import QueryForm from './components/QueryForm'
import TableList from './components/TableList'
import AddMoel from './components/AddMoel'
import moment from 'moment'
import { getCityList } from '../../../../api/api'
import { getCityListByProvinceId } from '../api'
function AccidentReporting() {
    const [loading, setLoading] = useState(false)
    const [cityData, setCityData] = useState([])
    const TableListRef = useRef();
    const AddMoelRef = useRef();
    useEffect(() => {
        getCityListByProvinceId().then((res)=>{
            if(res.data.success){
                setCityData(res.data.data)
            }
        })
    },[])
    const refresh = (val,data,splist)=>{
        switch (val) {
            case "query":
                    TableListRef.current.getlist(data);
                break;
            case "add":
                AddMoelRef.current.showModal('新增');
                break;
            case "updata":
                    AddMoelRef.current.showModal('编辑',Object.assign(data,{caseDate: moment(data.caseDate)}),splist);
                break;
            case "view":
                    AddMoelRef.current.showModal('查看',Object.assign(data,{caseDate: moment(data.caseDate)}),splist);
                break;
          }
    }
    return (
        <Spin spinning={loading}>
            <QueryForm cityData={cityData} refresh={refresh}></QueryForm>
            <TableList refresh={refresh} ref={TableListRef}></TableList>
            <AddMoel cityData={cityData} refresh={refresh} ref={AddMoelRef} ></AddMoel>
        </Spin>
    )
}

export default AccidentReporting
