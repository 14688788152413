import React, { useEffect, useState } from 'react'
import { request } from '../../../../../utils/fetch'
import { Form, Input, Select, Button, DatePicker, Row, Col, Space } from 'antd'
import store from '../../../../../store/index'
import moment from 'moment'
import ImportModal from '../../components/ImportModal'
const QueryForm = (props) => {
    const { refresh, pageNum, setPageNum } = props
    let path = 'accidentArchives'
    const [form] = Form.useForm()
    const [importVisible, setImportVisible] = useState(false)
    const { Option } = Select
    const { RangePicker } = DatePicker
    const labelStyleObj = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    }
    const itemStyle = { width: '390px', marginRight: '15px' }
    const initialFormValues = {}
    const Query = ()=>{
        if(pageNum===1){
            handelQuery()
        }else{
            setPageNum(1)
        }
    }
    // 查询
    const handelQuery = () => {
        let params = form.getFieldsValue()
        refresh('query', Object.assign({},params))
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
    }
    const upFileProps = {
        url: '/wispay/platform/ddc_orderInfo/import',
        type: 'DDC_DD',
        importVisible: importVisible,
        setImportVisible: setImportVisible,
        handelQuery: handelQuery
    }
    // 导出
    const exportData = () => {
        let data = form.getFieldsValue()
        let params ={
            orderNo: data.orderNo,
            stores: data.stores, 
            phone: data.phone, 
            name: data.name, 
            payModel: data.payModel, 
            orderStatus: data.orderStatus, 
            beginReturnTime: data.hcsj ? moment(data.hcsj[0]).format('YYYY-MM-DD HH:mm:ss') : '',
            endReturnTime: data.hcsj ? moment(data.hcsj[1]).format('YYYY-MM-DD HH:mm:ss') : '',
            beginOverTime: data.jssj ? moment(data.jssj[0]).format('YYYY-MM-DD HH:mm:ss') : '',
            endOverTime: data.jssj ? moment(data.jssj[1]).format('YYYY-MM-DD HH:mm:ss') : '',
            beginTime: data.cjsj ? moment(data.cjsj[0]).format('YYYY-MM-DD') : '',
            endTime: data.cjsj ? moment(data.cjsj[1]).format('YYYY-MM-DD') : '',
        }
        request.downExport('/platform/ddc_orderInfo/export', params)
    }
    useEffect(() => {
        handelQuery()
    }, [pageNum])
    return (
        <>
        <Form {...labelStyleObj} initialValues={initialFormValues} form={form}>
            <Row>
                <Form.Item style={itemStyle} name='orderNo' label='订单号'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='stores' label='门店'>
                    <Input placeholder='全部' />
                    {/* <Select allowClear placeholder='全部'>
                        <Option value='NC0000401（鹬鸟车行）'>NC0000401(鹬鸟车行)</Option>
                    </Select> */}
                </Form.Item>
                <Form.Item style={itemStyle} name='phone' label='手机号'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='name' label='姓名'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='payModel' label='付费模式'>
                    <Select allowClear placeholder='全部'>
                        <Option value='先付'>先付</Option>
                        <Option value='先用后付'>先用后付</Option>
                        <Option value='自定义'>自定义</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='orderStatus' label='订单状态'>
                    <Select allowClear placeholder='全部'>
                        <Option value='待付款'>待付款</Option>
                        <Option value='待收货'>待收货</Option>
                        <Option value='租赁中'>租赁中</Option>
                        <Option value='租金逾期'>租金逾期</Option>
                        <Option value='超期使用'>超期使用</Option>
                        <Option value='已完结'>已完结</Option>
                        <Option value='已取消'>已取消</Option>
                        <Option value='已还车待支付'>已还车待支付</Option>
                        <Option value='已还车逾期'>已还车逾期</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='hcsj' label='还车时间'>
                    {/* endReturnTime */}
                    <RangePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                </Form.Item>
                <Form.Item style={itemStyle} name='jssj' label='结束时间'>
                    {/* endOverTime */}
                    <RangePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                </Form.Item>
                <Form.Item style={itemStyle} name='cjsj' label='创建日期'>
                    {/* endTime */}
                    <RangePicker format="YYYY-MM-DD" />
                </Form.Item>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Space>
                        {store.getState().userReducer.permissions.indexOf(`${path}:select`) > -1 && (
                            <Button type='primary' onClick={Query}>
                                查询
                            </Button>
                        )}
                        <Button onClick={resetForm}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`) > -1 && <Button onClick={exportData}>导出</Button>}
                        <Button onClick={()=>{setImportVisible(true)}}>导入</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
        <ImportModal upFileProps={upFileProps} />
    </>
    )
}

export default QueryForm
