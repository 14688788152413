import React, { useState, useEffect } from 'react'
import {
    PageHeader,
    Input,
    Button,
    Row,
    Col,
    Space,
    Form,
    Table,
    Spin
} from 'antd'
import {getPlatformDetail, getDetailTitle} from '../api'
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index'
const PlatformDetail = (props) => {
  let path = 'platform:Financialdeal'
  const params = props.match.params
  const relevanceNo = params.id
  let dealNo, dealType
  if (props.location.state) {
    dealNo = props.location.state.dealNo || ''
    dealType = props.location.state.dealType || ''
  }
  

  const [form] = Form.useForm()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState()
  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: 20,
    total: 0
  })
  const [titleData, setTitleData] = useState({
    month: '',
    source: '',
    billNo: '',
    companyName: ''
  })
  // 列表字段
  const tableColumns = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },{
      title: '身份证号',
      dataIndex: 'idNumber',
      key: 'idNumber',
      align: 'center'
    },{
      title: '佣金（元）',
      dataIndex: 'commissionAmount',
      key: 'commissionAmount',
      align: 'center'
    },{
      title: '服务费（元）',
      dataIndex: 'serviceAmount',
      key: 'serviceAmount',
      align: 'center'
    },{
      title: '税费（元）',
      dataIndex: 'taxAmount',
      key: 'taxAmount',
      align: 'center'
    }
  ]
  // 获取详情
  const getDetail = async(page) => {
    let params = {
      relevanceNo: relevanceNo,
      dealType: dealType,
      keyword: keyword,
      pageNum: page.pageNum,
      pageSize: page.pageSize
    }
    try {
      setLoading(true)
      const res = await getPlatformDetail(params)
      if (res.data.success) {
        setPagination(prev => {
          return {
            ...prev,
            total: res.data.data.totalCount
          }
        })
        setTableData(res.data.data.list)
      }
      setLoading(false)
    } catch(err) {
      setLoading(false)
      console.log('___平台入账详情', err)
    }
  }
  // 获取头部数据
  const getTitle = async() => {
    let params = {
      relevanceNo: relevanceNo,
      dealType: dealType
    }
    try {
      const res = await getDetailTitle(params)
      if (res.data.success) {
        setTitleData({
          month: res.data.data.month,
          source: res.data.data.source,
          billNo: res.data.data.billNo,
          companyName: res.data.data.companyCodeName
        })
      }
    } catch(err) {
      console.log('___平台入账详情头部', err)
    }
  }
  useEffect(() => {
    getDetail(pagination)
    getTitle()
  }, [])
  const submit = () => {
    getDetail({pageNum: 1, pageSize: 20})
  }
  const resetForm = () => {
    setKeyword()
  }
  const tablePageChange = (pageNum, pageSize) => {
    setPagination(prev => {
      return {
        ...prev,
        pageNum: pageNum
      }
    })
    getDetail({pageNum: pageNum, pageSize: pageSize})
  }
  const keywordChange = (e) => {
    setKeyword(e.target.value)
  }
  // 导出
  const exportData = () => {
    const data = {
      dealNo: dealNo,
      relevanceNo: relevanceNo,
      dealType: dealType,
      keyword: keyword
    }
    request.downExport('/platform/financialdeal/exportDetail', data)
  }
  return (
    <Spin spinning={loading}>
      <PageHeader
        title="明细"
        onBack={() => props.history.goBack()}
      />
      <div style={{background: '#fff', paddingLeft: '20px', paddingBottom: '20px'}}>
        <Space size="large">
          <span>账单月份：{ titleData.month }</span>
          <span>来源：{ titleData.source == 1 ? '账单佣金' : '审核账单' }</span>
          <span>佣金账单ID: { titleData.billNo }</span>
          <span>企业名称：{ titleData.companyName }</span>
        </Space>
      </div>
      <Form labelCol={{ span: 6 }} form={form}>
        <Row>
          <Form.Item labelCol={{ span: 5 }} style={{width: '430px'}} label="关键字">
            <Input value={keyword} onChange={keywordChange} placeholder="姓名丨身份证" />
          </Form.Item>
        </Row>
        <Row>
          <Col span={24} style={{textAlign: 'right'}}>
            <Space>
              <Button type="primary" onClick={submit}>查询</Button>
              <Button onClick={resetForm}>重置</Button>
              {store.getState().userReducer.permissions.indexOf(`${path}:detail:export`)>-1&&<Button onClick={exportData}>导出</Button>}
            </Space>
          </Col>                              
        </Row>
      </Form>
      <Table
        columns={tableColumns} 
        dataSource={tableData}
        scroll={{ y: 540 }}
        rowKey={(record, index) => `rowkey${record.name}${index}`}
        pagination={{
          position: ['bottomCenter'],
          total: pagination.total,
          current: pagination.pageNum,
          pageSize: pagination.pageSize,
          showTotal:(total) =>  `共 ${total} 条数据`,
          onChange: (pageNum, pageSize)　=> tablePageChange(pageNum, pageSize)
        }}>

      </Table>
    </Spin>
  )
}

export default PlatformDetail