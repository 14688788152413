import React, { useState, useEffect } from 'react'
import { Modal, Checkbox, Radio, Select, Input, Button, Divider, message, InputNumber, DatePicker } from 'antd'
import { useImmer } from 'use-immer'
import _ from 'lodash'
import { getCityCountyByType, updataConfig } from '../../api'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

import moment from 'moment'

import '../../companyEnter/index.css'


const {Option} = Select

const ConfigModal = (props) => {
  const { visible, close, modalData, refresh } = props
  const [confirmLoading, setConfirmLoading] = React.useState(false)
  const [cityCounty, setCityCounty] = useState([]) // 临时税务城市片区
  const [temporaryDuty, setTemporaryDuty] = useState(false) // 是否勾选临时税务
  const [privateCityCounty, setPrivatecityCounty] = useState([]) // 个体工商户城市片区
  const [privately, setPrivately] = useState(false) // 是否勾选个体工商
  const [commissionBasisCityCounty, setCommissionBasisCityCounty] = useState([]) // 委托代征城市片区
  const [commissionBasis, setCommissionBasis] = useState(false) // 是否勾选委托代征
  const [disabled, setDisabled] = useState(false) // 同时勾选了临时税务和个体工商，不能选阶梯式
  const [effectType, setEffectType] = useState(2)
  const [effectTime, setEffectTime] = useState()

  const [temporaryDutyData, updateTemporaryDutyData] = useImmer({
    areaNo: [],
    cityId: '',
    countyId: '',
    taxEnterpriseCode: '',
    planType: 1,
    taxPoint: '',
    backTaxFlag: 1,
    backTaxPoint: '',
    taxTicketFlag: 0,
    serviceLadderFlag: 1,
    serviceType: 1,
    serviceValue: null,
    bigServicePoint: '',
    effectTime: '',
    companyConfigDetailList: [
      {
        sort: '0',
        minAmount: '0',
        maxAmount: '',
        value: ''
      }
    ]
  })
  // 个体工商户
  const [privatelyData, updatePrivatelyData] = useImmer({
    areaNo: [],
    cityId: '',
    countyId: '',
    taxEnterpriseCode: '',
    planType: 2,
    taxPoint: '',
    backTaxFlag: 1,
    backTaxPoint: '',
    taxTicketFlag: 0,
    serviceLadderFlag: 1,
    serviceType: 1,
    serviceValue: null,
    bigServicePoint: '',
    effectTime: '',
    companyConfigDetailList: [
      {
        sort: '0',
        minAmount: '0',
        maxAmount: '',
        value: ''
      }
    ]
  })
  // 委托代征
  const [commissionBasisData, updateCommissionBasisData] = useImmer({
    areaNo: '',
    cityId: '',
    countyId: '',
    taxEnterpriseCode: '',
    planType: 3,
    taxPoint: '',
    backTaxFlag: 1,
    backTaxPoint: '',
    taxTicketFlag: 0,
    serviceLadderFlag: 1,
    serviceType: 1,
    serviceValue: null,
    bigServicePoint: '',
    companyConfigDetailList: [
      {
        sort: '1',
        minAmount: '0',
        maxAmount: '',
        value: ''
      }
    ]
  })
  // 回显数据
  useEffect(() => {
    if (visible) {
      const arr = _.groupBy(modalData.serviceConfigVoList, 'planType')
      console.log(arr)

      updateTemporaryDutyData(draft=> {
        if(arr[1]) {
          setTemporaryDuty(true)
          getCityCounty(1)
          draft.areaNo = arr[1][0].areaNo
          draft.cityId = arr[1][0].cityId
          draft.countyId = arr[1][0].countyId
          draft.taxEnterpriseCode = arr[1][0].taxEnterpriseCode
          draft.planType = arr[1][0].planType
          draft.taxPoint = arr[1][0].taxPoint||0
          draft.backTaxFlag = arr[1][0].backTaxFlag
          draft.backTaxPoint = arr[1][0].backTaxPoint||0
          draft.taxTicketFlag = arr[1][0].taxTicketFlag
          draft.serviceLadderFlag = arr[1][0].serviceLadderFlag
          draft.serviceType = arr[1][0].serviceType
          draft.serviceValue = arr[1][0].serviceValue||0
          draft.bigServicePoint = arr[1][0].bigServicePoint||0
          draft.effectTime = arr[1][0].effectTime
          draft.companyConfigDetailList = arr[1][0].companyConfigDetailList.length > 0 ? arr[1][0].companyConfigDetailList : [
            {
              sort: '0',
              minAmount: '0',
              maxAmount: '',
              value: ''
            }
          ]
        }
      })
      
      updatePrivatelyData(draft => {
        if (arr[2]) {
          setPrivately(true)
          getCityCounty(2)
          draft.areaNo = arr[2][0].areaNo
          draft.cityId = arr[2][0].cityId
          draft.countyId = arr[2][0].countyId
          draft.taxEnterpriseCode = arr[2][0].taxEnterpriseCode
          draft.planType = arr[2][0].planType
          draft.taxPoint = arr[2][0].taxPoint||0
          draft.backTaxFlag = arr[2][0].backTaxFlag
          draft.backTaxPoint = arr[2][0].backTaxPoint||0
          draft.taxTicketFlag = arr[2][0].taxTicketFlag
          draft.serviceLadderFlag = arr[2][0].serviceLadderFlag
          draft.serviceType = arr[2][0].serviceType
          draft.serviceValue = arr[2][0].serviceValue||0
          draft.bigServicePoint = arr[2][0].bigServicePoint||0
          draft.effectTime = arr[2][0].effectTime
          draft.companyConfigDetailList = arr[2][0].companyConfigDetailList.length > 0 ? arr[2][0].companyConfigDetailList : [
            {
              sort: '0',
              minAmount: '0',
              maxAmount: '',
              value: ''
            }
          ]
        }
      })
      updateCommissionBasisData(draft => {
        if (arr[3]) {
          setCommissionBasis(true)
          getCityCounty(3)
          draft.areaNo = arr[3][0].areaNo
          draft.cityId = arr[3][0].cityId
          draft.countyId = arr[3][0].countyId
          draft.taxEnterpriseCode = arr[3][0].taxEnterpriseCode
          draft.planType = arr[3][0].planType
          draft.taxPoint = arr[3][0].taxPoint||0
          draft.backTaxFlag = arr[3][0].backTaxFlag
          draft.backTaxPoint = arr[3][0].backTaxPoint||0
          draft.taxTicketFlag = arr[3][0].taxTicketFlag
          draft.serviceLadderFlag = arr[3][0].serviceLadderFlag
          draft.serviceType = arr[3][0].serviceType
          draft.serviceValue = arr[3][0].serviceValue||0
          draft.bigServicePoint = arr[3][0].bigServicePoint||0
          draft.effectTime = arr[3][0].effectTime
          draft.companyConfigDetailList = arr[3][0].companyConfigDetailList.length > 0 ? arr[3][0].companyConfigDetailList : [
            {
              sort: '0',
              minAmount: '0',
              maxAmount: '',
              value: ''
            }
          ]
        }
      })
    }
  }, [visible, modalData, updatePrivatelyData, updateTemporaryDutyData,updateCommissionBasisData])
  // 获取城市区县
  const getCityCounty = async(planType) => {
    try {
      const result = await getCityCountyByType({planType:planType})
      if (result.data.success) {
        if (planType === 1) {
          setCityCounty(result.data.data)
        } else if(planType === 2) {
          setPrivatecityCounty(result.data.data)
        } else if(planType === 3){
          setCommissionBasisCityCounty(result.data.data)
        }else{
          message.error(result.data.retMsg)
        }
      } else {
        message.error(result.data.retMsg)
      }
      
    } catch(err) {
      console.log('__企业入驻城市片区', err)
    }
  }
  // 
  const temporaryDutyChange = (e) => {
    if (e.target.checked) {
      getCityCounty(1)
    }
    setTemporaryDuty(e.target.checked)
  }
  // 
  const privatelyChange = (e) => {
    if (e.target.checked) {
      getCityCounty(2)
    }
    setPrivately(e.target.checked)
  }
  //
  const commissionBasisChange = (e) => {
    if (e.target.checked) {
      getCityCounty(3)
    }
    setCommissionBasis(e.target.checked)
  }
  // 初次加载 获取落地配置的城市区县
  useEffect(() => {
    if (temporaryDuty) {
      getCityCounty(1)
    }
    if (privately) {
      getCityCounty(2)
    }
    if (commissionBasis) {
      getCityCounty(3)
    }
  }, [])
  // 同时选中两个以上，阶梯不可选
  useEffect(() => {
    if ((temporaryDuty && privately) || (temporaryDuty && commissionBasis) || (privately && commissionBasis)) {
      setDisabled(true)
      updateTemporaryDutyData(draft => {
        draft.serviceLadderFlag = 0
      })
      updatePrivatelyData(draft => {
        draft.serviceLadderFlag = 0
      })
      updateCommissionBasisData(draft => {
        draft.serviceLadderFlag = 0
      })
    } else {
      setDisabled(false)
    }
  }, [temporaryDuty, privately,commissionBasis])
  // 更新配置数据 type 1 临时税务 2 个体工商  3 委托代征
  // field 字段名  index有的话 该字段是数组，name是field[index]的属性
  const updateData = (value, type, field, index, name) => {
    if (type === 1) {
      // 如果是落地城市区县，从数据中获取cityId, countyId
      if (field === 'taxEnterpriseCode') {
        let arr = cityCounty.filter(item => item.taxEnterpriseCode === value)
        let cityId = arr[0].cityId
        let countyId = arr[0].countyId
        let areaNo = arr[0].areaNo
        updateTemporaryDutyData(draft => {
          draft.cityId = cityId
          draft.countyId = countyId
          draft.areaNo = areaNo
        })
      }
     
      if (index > -1){
        updateTemporaryDutyData(draft => {
          draft[field][index][name] = value
        })
      } else {
        console.log(field)
        updateTemporaryDutyData(draft => {
          draft[field] = value
        })
      }
    }
    if (type === 2) {
      if (field === 'taxEnterpriseCode') {
        let arr = privateCityCounty.filter(item => item.taxEnterpriseCode === value)
        let cityId = arr[0].cityId
        let countyId = arr[0].countyId
        let areaNo = arr[0].areaNo
        updatePrivatelyData(draft => {
          draft.cityId = cityId
          draft.countyId = countyId
          draft.areaNo = areaNo
        })
      }
      if (index > -1){
        updatePrivatelyData(draft => {
          draft[field][index][name] = value
        })
      } else {
        updatePrivatelyData(draft => {
          draft[field] = value
        })
      }
    }
    if (type === 3) {
      if (field === 'taxEnterpriseCode') {
        let arr = commissionBasisCityCounty.filter(item => item.taxEnterpriseCode === value)
        let cityId = arr[0].cityId
        let countyId = arr[0].countyId
        let areaNo = arr[0].areaNo
        updateCommissionBasisData(draft => {
          draft.cityId = cityId
          draft.countyId = countyId
          draft.areaNo = areaNo
        })
      }
      if (index > -1) {
        updateCommissionBasisData(draft => {
          draft[field][index][name] = value
        })
      } else {
        updateCommissionBasisData(draft => {
          draft[field] = value
        })
      }
    }
    console.log(temporaryDutyData,privatelyData,commissionBasisData)
  }
  // 增加阶梯费行
  const addRow = (planType) => {
    if (planType === 1) {
      updateTemporaryDutyData(draft => {
        console.log(draft.companyConfigDetailList)
        draft.companyConfigDetailList = [...draft.companyConfigDetailList, {
          sort: '',
          minAmount: '',
          maxAmount: '',
          value: ''
        }].map((item, index) => {
          item.sort = Number(index) + 1
          return item
        })
      })
    } 
    if (planType === 2) {
      updatePrivatelyData(draft => {
        console.log(draft.companyConfigDetailList)
        draft.companyConfigDetailList = [...draft.companyConfigDetailList, {
          sort: '',
          minAmount: '',
          maxAmount: '',
          value: ''
        }].map((item, index) => {
          item.sort = Number(index) + 1
          return item
        })
      })
    }
    if (planType === 3) {
      updateCommissionBasisData(draft => {
        console.log(draft.companyConfigDetailList)
        draft.companyConfigDetailList = [...draft.companyConfigDetailList, {
          sort: '',
          minAmount: '',
          maxAmount: '',
          value: ''
        }].map((item, index) => {
          item.sort = Number(index) + 1
          return item
        })
      })
    }
  }
  const deleteRow = (index, planType) => {
    if (planType === 1) {
      updateTemporaryDutyData(draft => {
        draft.companyConfigDetailList.splice(index, 1)
        draft.companyConfigDetailList = draft.companyConfigDetailList.map((item, idx) => {
          item.sort = idx
          return item
        })
      })
    }
    if (planType === 2) {
      updatePrivatelyData(draft => {
        draft.companyConfigDetailList.splice(index, 1)
        draft.companyConfigDetailList = draft.companyConfigDetailList.map((item, idx) => {
          item.sort = idx
          return item
        })
      })
    }
    if (planType === 3) {
      updateCommissionBasisData(draft => {
        draft.companyConfigDetailList.splice(index, 1)
        draft.companyConfigDetailList = draft.companyConfigDetailList.map((item, idx) => {
          item.sort = idx
          return item
        })
      })
    }
    
  }
  // 渲染配置服务费 type 1 阶梯式固定金额 2 阶梯式百分比 3 费阶梯式固定金额 4 非阶梯式百分比
  // planType 1 临时税务 2 个体工商
  const renderService = (data, planType, type) => {
    console.log(data, planType, type)
    if(type === 1 || type === 2) {
      return data.map((item, index) => (
        <div className="row" key={index}>
          <span className="row-tit"></span>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{width: '70px'}}>第{index + 1}阶段: </span>
            {index > 0 && <div>
              <InputNumber value={item.minAmount} min={0} onChange={(value) => updateData(value, planType, 'companyConfigDetailList', index, 'minAmount')} size="small" style={{width: '70px'}} />
              万≤
            </div>}
            <span>当月(自然月)累计批量佣金总额</span> &lt;
            <InputNumber value={item.maxAmount} min={0} onChange={(value) => updateData(value, planType, 'companyConfigDetailList', index, 'maxAmount')} size="small" style={{width: '70px'}} />
            万时, {type === 1 ? '单笔佣金的固定金额' : '单笔佣金的百分比'}, 每笔 <InputNumber value={item.value} min={0} onChange={(value) => updateData(value, planType, 'companyConfigDetailList', index, 'value')} size="small" style={{width: '70px'}} /> {type === 1 ? '元' : '%'}
            <Button type="primary" shape="circle" icon={<PlusOutlined />} style={{marginLeft: '10px'}} onClick={() => addRow(planType)} />
            {index > 0 && <Button type="primary" shape="circle" icon={<MinusOutlined />} style={{marginLeft: '10px'}} onClick={() => deleteRow(index, planType)} />}
          </div>
        </div>
      ))
    }
    if (type === 3 || type === 4) {
      return (
        <div className="row">
        <span className="row-tit"></span>
        <div>
        {
          planType === 1 && <>
            {type === 3 ? '单笔佣金的固定金额' : '单笔佣金的百分比'}, 每笔<InputNumber value={temporaryDutyData.serviceValue} min={0} onChange={(value) => updateData(value, planType, 'serviceValue')} size="small" style={{ width: '80px' }} /> {type === 3 ? '元' : '%'}
          </>
        }
        {
          planType === 2 &&
          <>
            {type === 3 ? '单笔佣金的固定金额' : '单笔佣金的百分比'}, 每笔<InputNumber value={privatelyData.serviceValue} min={0} onChange={(value) => updateData(value, planType, 'serviceValue')} size="small" style={{ width: '80px' }} /> {type === 3 ? '元' : '%'}
          </>
        }
        {
          planType === 3 &&
          <>
            {type === 3 ? '单笔佣金的固定金额' : '单笔佣金的百分比'}, 每笔<InputNumber value={commissionBasisData.serviceValue} min={0} onChange={(value) => updateData(value, planType, 'serviceValue')} size="small" style={{ width: '80px' }} /> {type === 3 ? '元' : '%'}
          </>
        }
        </div>
        </div>
      )
    }
  }
  // 生效时间选择
  const effectChange = (value) => {
    console.log(value)
    setEffectType(value)
    if (value === 1) {
      setEffectTime(moment().format('YYYY-MM-DD HH:mm:ss'))
      updateTemporaryDutyData(draft => {
        draft.effectTime = moment().format('YYYY-MM-DD HH:mm:ss')
      })
      updatePrivatelyData(draft => {
        draft.effectTime = moment().format('YYYY-MM-DD HH:mm:ss')
      })
      updateCommissionBasisData(draft => {
        draft.effectTime = moment().format('YYYY-MM-DD HH:mm:ss')
      })
    }
    
    if (value === 2) {
      updateTemporaryDutyData(draft => {
        draft.effectTime = effectTime
      })
      updatePrivatelyData(draft => {
        draft.effectTime = effectTime
      })
      updateCommissionBasisData(draft => {
        draft.effectTime = effectTime
      })
    }
  }
  // 
  const dateChange = (value) => {
    console.log(value)
    setEffectTime(value)
    updateTemporaryDutyData(draft => {
      draft.effectTime = moment(value).format('YYYY-MM-DD HH:mm:ss')
    })
    updatePrivatelyData(draft => {
      draft.effectTime = moment(value).format('YYYY-MM-DD HH:mm:ss')
    })
    updateCommissionBasisData(draft => {
      draft.effectTime = moment(value).format('YYYY-MM-DD HH:mm:ss')
    })
  }
  const handleOk = async() => {
    let serviceConfigVoList = []
    console.log(temporaryDutyData, privatelyData, commissionBasisData)

    if (temporaryDuty) {
      serviceConfigVoList.push(temporaryDutyData)
    }
    if (privately) {
      serviceConfigVoList.push(privatelyData)
    }
    if (commissionBasis) {
      serviceConfigVoList.push(commissionBasisData)
    }
    if (!temporaryDuty && !privately && !commissionBasis) {
      message.warning('落地配置至少填写一个')
      return
    }
    if (!effectTime) {
      message.warning('请选择生效时间')
      return
    }
    const data = Object.assign({}, {companyCode: modalData.companyCode, serviceConfigVoList: serviceConfigVoList})
    try {
      setConfirmLoading(true)
      const result = await updataConfig(data)
      setConfirmLoading(false)
      if (result.data.success) {
        close()
        message.success('修改成功!')
        refresh()
      } else {
        message.error(result.data.retMsg)
      }
    } catch(err) {
      setConfirmLoading(false)
      console.log('__修改管理员', err)
    }
  }
  const onCancel = () => {
    close()
  }
  return (
    <Modal maskClosable={false} 
      title="落地配置" 
      visible={visible} 
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={handleOk} 
      width={1040}>
      <div className="config-modal">
      <Checkbox className="checkbox" checked={temporaryDuty} onChange={temporaryDutyChange}>临时税务登记</Checkbox>
      { temporaryDuty && 
      <>
        <div className="row">
          <span className="row-tit">财税企业：</span>
          <Select placeholder="请选择" name="taxEnterpriseCode" onChange={(value) => updateData(value, 1, 'taxEnterpriseCode')} value={temporaryDutyData.taxEnterpriseCode} style={{width: '400px'}}>
            {cityCounty.map(item => (
              <Option value={item.taxEnterpriseCode} key={item.taxEnterpriseCode}>{item.taxEnterpriseName}</Option>
            ))}
          </Select>
        </div>
        <div className="row">
          <span className="row-tit">税费：</span>
          <div className="row-r">
            单笔打款佣金的
            <InputNumber  placeholder="请输入" min={0} onChange={(value) => updateData(value, 1, 'taxPoint')} value={temporaryDutyData.taxPoint}  size="middle" style={{width: '160px', margin: '0 10px'}}></InputNumber > %
          </div>
        </div>
        <div className="row">
          <span className="row-tit">是否返税：</span>
          <Radio.Group onChange={(e) => updateData(e.target.value, 1, 'backTaxFlag')} value={temporaryDutyData.backTaxFlag}>
            <Radio value={1}>返税</Radio>
            <Radio value={0}>不返税</Radio>
          </Radio.Group>
        </div>
        {temporaryDutyData.backTaxFlag === 1 ? (
          <>
            <div className="row">
              <span className="row-tit">返税：</span>
              <div className="row-r">
                单笔打款佣金的
                <InputNumber placeholder="请输入" min={0} onChange={(value) => updateData(value, 1, 'backTaxPoint')} value={temporaryDutyData.backTaxPoint} size="middle" style={{width: '160px', margin: '0 10px'}}></InputNumber> %
              </div>
            </div>
            <div className="row">
              <span className="row-tit">开票：</span>
              <Radio.Group onChange={(e) => updateData(e.target.value, 1, 'taxTicketFlag')} value={temporaryDutyData.taxTicketFlag}>
                <Radio value={1}>返税开票（开票金额=佣金+服务费+税费）</Radio>
                <Radio value={0}>返税不开票（开票金额=佣金+服务费+税费-返税）</Radio>
              </Radio.Group>
            </div>
          </>
        ) : (
          <div className="row">
            <span className="row-tit">开票：</span>
            <span>开票金额=佣金+服务费+税费</span>
          </div>
        )}
        <div className="row">
          <span className="row-tit">常规服务费：</span>
          <Radio.Group onChange={(e) => updateData(e.target.value, 1, 'serviceLadderFlag')} value={temporaryDutyData.serviceLadderFlag}>
            <Radio value={1} disabled={disabled}>阶梯式</Radio>
            <Radio value={0}>非阶梯式</Radio>
          </Radio.Group>
        </div>
        <div className="row">
          <span className="row-tit"></span>
          <Radio.Group onChange={(e) => updateData(e.target.value, 1, 'serviceType')} value={temporaryDutyData.serviceType}>
            <Radio value={2}>单笔佣金百分比</Radio>
            <Radio value={1}>单笔佣金的固定金额</Radio>
          </Radio.Group>
        </div>
        <>
          {
            (temporaryDutyData.serviceLadderFlag === 1 && temporaryDutyData.serviceType === 1)
            && renderService(temporaryDutyData.companyConfigDetailList, 1, 1)
          }
          {
            (temporaryDutyData.serviceLadderFlag === 1 && temporaryDutyData.serviceType === 2)
            && renderService(temporaryDutyData.companyConfigDetailList, 1, 2)
          }
          {
            (temporaryDutyData.serviceLadderFlag === 0 && temporaryDutyData.serviceType === 1)
            && renderService(temporaryDutyData.companyConfigDetailList, 1, 3)
          }
          {
            (temporaryDutyData.serviceLadderFlag === 0 && temporaryDutyData.serviceType === 2)
            && renderService(temporaryDutyData.companyConfigDetailList, 1, 4)
          }
        </>
        <div className="row">
          <span className="row-tit">大金额服务费</span>
          <div>
            单笔佣金≥3万，每笔<InputNumber min={0} value={temporaryDutyData.bigServicePoint} onChange={(value) => updateData(value, 1, 'bigServicePoint')} size="small" style={{width: '80px'}} /> %
          </div>
        </div>
      </>}
      <Divider />
      <Checkbox className="checkbox" checked={privately} onChange={privatelyChange}>个体工商户</Checkbox>
      {privately && 
      <>
        <div className="row">
          <span className="row-tit">财税企业：</span>
          <Select placeholder="请选择" name="taxEnterpriseCode" onChange={(value) => updateData(value, 2, 'taxEnterpriseCode')} value={privatelyData.taxEnterpriseCode} style={{width: '400px'}}>
            {privateCityCounty.map(item => (
              <Option value={item.taxEnterpriseCode} key={item.taxEnterpriseCode}>{item.taxEnterpriseName}</Option>
            ))}
          </Select>
        </div>
        <div className="row">
          <span className="row-tit">税费：</span>
          <div className="row-r">
            单笔打款佣金的
            <InputNumber min={0} placeholder="请输入" onChange={(value) => updateData(value, 2, 'taxPoint')} value={privatelyData.taxPoint}  size="middle" style={{width: '160px', margin: '0 10px'}}></InputNumber> %
          </div>
        </div>
        <div className="row">
          <span className="row-tit">是否返税：</span>
          <Radio.Group onChange={(e) => updateData(e.target.value, 2, 'backTaxFlag')} value={privatelyData.backTaxFlag}>
            <Radio value={1}>返税</Radio>
            <Radio value={0}>不返税</Radio>
          </Radio.Group>
        </div>
        {privatelyData.backTaxFlag === 1 ? (
          <>
            <div className="row">
              <span className="row-tit">返税：</span>
              <div className="row-r">
                单笔打款佣金的
                <InputNumber min={0} placeholder="请输入" onChange={(value) => updateData(value, 2, 'backTaxPoint')} value={privatelyData.backTaxPoint} size="middle" style={{width: '160px', margin: '0 10px'}}></InputNumber> %
              </div>
            </div>
            <div className="row">
              <span className="row-tit">开票：</span>
              <Radio.Group onChange={(e) => updateData(e.target.value, 2, 'taxTicketFlag')} value={privatelyData.taxTicketFlag}>
                <Radio value={1}>返税开票（开票金额=佣金+服务费+税费）</Radio>
                <Radio value={0}>返税不开票（开票金额=佣金+服务费+税费-返税）</Radio>
              </Radio.Group>
            </div>
          </>
        ) : (
          <div className="row">
            <span className="row-tit">开票：</span>
            <span>开票金额=佣金+服务费+税费</span>
          </div>
        )}
        <div className="row">
          <span className="row-tit">常规服务费：</span>
          <Radio.Group onChange={(e) => updateData(e.target.value, 2, 'serviceLadderFlag')} value={privatelyData.serviceLadderFlag}>
            <Radio value={1} disabled={disabled}>阶梯式</Radio>
            <Radio value={0}>非阶梯式</Radio>
          </Radio.Group>
        </div>
        <div className="row">
          <span className="row-tit"></span>
          <Radio.Group onChange={(e) => updateData(e.target.value, 2, 'serviceType')} value={privatelyData.serviceType}>
            <Radio value={2}>单笔佣金百分比</Radio>
            <Radio value={1}>单笔佣金的固定金额</Radio>
          </Radio.Group>
        </div>
        <>
          {/* 阶梯固定金额 */}
          {
            (privatelyData.serviceLadderFlag === 1 && privatelyData.serviceType === 1)
            && renderService(privatelyData.companyConfigDetailList, 2, 1)
          }
          {/* 阶梯百分比 */}
          {
            (privatelyData.serviceLadderFlag === 1 && privatelyData.serviceType === 2)
            && renderService(privatelyData.companyConfigDetailList, 2, 2)
          }
          {/* 非阶梯固定金额 */}
          {
            (privatelyData.serviceLadderFlag === 0 && privatelyData.serviceType === 1)
            && renderService(privatelyData.companyConfigDetailList, 2, 3)
          }
          {/* 非阶梯百分比 */}
          {
            (privatelyData.serviceLadderFlag === 0 && privatelyData.serviceType === 2)
            && renderService(privatelyData.companyConfigDetailList, 2, 4)
          }
        </>
        <div className="row">
          <span className="row-tit">大金额服务费</span>
          <div>
            单笔佣金≥3万，每笔<InputNumber min={0} value={privatelyData.bigServicePoint} onChange={(value) => updateData(value, 2, 'bigServicePoint')} size="small" style={{width: '80px'}} /> %
          </div>
        </div>
      </>
      }
      <Divider />
      <Checkbox className="checkbox" checked={commissionBasis} onChange={commissionBasisChange}>委托代征</Checkbox>
      {commissionBasis &&
        <>
          <div className="row">
            <span className="row-tit">财税企业：</span>
            <Select placeholder="请选择" name="taxEnterpriseCode" onChange={(value) => updateData(value, 3, 'taxEnterpriseCode')} value={commissionBasisData.taxEnterpriseCode} style={{ width: '400px' }}>
              {commissionBasisCityCounty.map(item => (
                <Option value={item.taxEnterpriseCode} key={item.taxEnterpriseCode}>{item.taxEnterpriseName}</Option>
              ))}
            </Select>
          </div>
          <div className="row">
            <span className="row-tit">税费：</span>
            <div className="row-r">
              单笔打款佣金的
              <InputNumber min={0} placeholder="请输入" onChange={(value) => updateData(value, 3, 'taxPoint')} value={commissionBasisData.taxPoint} size="middle" style={{ width: '160px', margin: '0 10px' }}></InputNumber> %
            </div>
          </div>
          <div className="row">
            <span className="row-tit">是否返税：</span>
            <Radio.Group onChange={(e) => updateData(e.target.value, 3, 'backTaxFlag')} value={commissionBasisData.backTaxFlag}>
              <Radio value={1}>返税</Radio>
              <Radio value={0}>不返税</Radio>
            </Radio.Group>
          </div>
          {commissionBasisData.backTaxFlag === 1 ? (
            <>
              <div className="row">
                <span className="row-tit">返税：</span>
                <div className="row-r">
                  单笔打款佣金的
                  <InputNumber min={0} placeholder="请输入" onChange={(value) => updateData(value, 3, 'backTaxPoint')} value={commissionBasisData.backTaxPoint} size="middle" style={{ width: '160px', margin: '0 10px' }}></InputNumber> %
                </div>
              </div>
              <div className="row">
                <span className="row-tit">开票：</span>
                <Radio.Group onChange={(e) => updateData(e.target.value, 3, 'taxTicketFlag')} value={commissionBasisData.taxTicketFlag}>
                  <Radio value={1}>返税开票（开票金额=佣金+服务费+税费）</Radio>
                  <Radio value={0}>返税不开票（开票金额=佣金+服务费+税费-返税）</Radio>
                </Radio.Group>
              </div>
            </>
          ) : (
            <div className="row">
              <span className="row-tit">开票：</span>
              <span>开票金额=佣金+服务费+税费</span>
            </div>
          )}
          <div className="row">
            <span className="row-tit">常规服务费：</span>
            <Radio.Group onChange={(e) => updateData(e.target.value, 3, 'serviceLadderFlag')} value={commissionBasisData.serviceLadderFlag}>
              <Radio value={1} disabled={disabled}>阶梯式</Radio>
              <Radio value={0}>非阶梯式</Radio>
            </Radio.Group>
          </div>
          <div className="row">
            <span className="row-tit"></span>
            <Radio.Group onChange={(e) => updateData(e.target.value, 3, 'serviceType')} value={commissionBasisData.serviceType}>
              <Radio value={2}>单笔佣金百分比</Radio>
              <Radio value={1}>单笔佣金的固定金额</Radio>
            </Radio.Group>
          </div>
          <>
            {/* 阶梯固定金额 */}
            {
              (commissionBasisData.serviceLadderFlag === 1 && commissionBasisData.serviceType === 1)
              && renderService(commissionBasisData.companyConfigDetailList, 3, 1)
            }
            {/* 阶梯百分比 */}
            {
              (commissionBasisData.serviceLadderFlag === 1 && commissionBasisData.serviceType === 2)
              && renderService(commissionBasisData.companyConfigDetailList, 3, 2)
            }
            {/* 非阶梯固定金额 */}
            {
              (commissionBasisData.serviceLadderFlag === 0 && commissionBasisData.serviceType === 1)
              && renderService(commissionBasisData.companyConfigDetailList, 3, 3)
            }
            {/* 非阶梯百分比 */}
            {
              (commissionBasisData.serviceLadderFlag === 0 && commissionBasisData.serviceType === 2)
              && renderService(commissionBasisData.companyConfigDetailList, 3, 4)
            }
          </>
          <div className="row">
            <span className="row-tit">大金额服务费</span>
            <div>
              单笔佣金≥3万，每笔<InputNumber min={0} value={commissionBasisData.bigServicePoint} onChange={(value) => updateData(value, 3, 'bigServicePoint')} size="small" style={{ width: '80px' }} /> %
            </div>
          </div>
        </>
      }
      <Divider />
      <div className="row">
        <span>生效时间：</span>
        <Radio.Group onChange={(e) => effectChange(e.target.value)} value={effectType}>
          <Radio value={1}>立即生效</Radio>
          <Radio value={2}>
            <DatePicker format="YYYY-MM-DD HH:mm:ss" onChange={(value) => dateChange(value)} disabled={effectType === 1} showTime />
          </Radio>
        </Radio.Group>
      </div>
      </div>
    </Modal>
  )
}

export default ConfigModal