import React, { useImperativeHandle, useState, forwardRef, useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  Button,
  message,
} from 'antd';
import moment from 'moment'
import { subtractTime } from '../../../../../utils/auth'
import { getPerson, getProvinceIdListByRegionalNo,getCityListByProvinceId } from '../../api'
import { securityEventReport } from '../api'
import '../index.css'
const { TextArea } = Input;
const topCol = {
  span: 12,
}

const AddMoel = forwardRef((props,ref) => {
  const { refresh } = props
  const [visible, setVisible] = useState(false);
  const [provinceList, setProvinceList] = useState([])
  const [cityList, setCityList] = useState([])
  const [renyunData, setRenyunData] = useState([])
  const [idNumberd, setidNumberd] = useState()
  const [name, setName] = useState()
  const [isRisky, setIsRisky] = useState()
  const [isReported, setIsReported] = useState()
  const [isJudged, setIsJudged] = useState()
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm()
  const { Option } = Select
  const initialFormValues = {}
  
  const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU:1.重大刑事案件;2.涉及刑事案件对公司品牌造成严重影响</p>
      <p className="titp">P0:1.普通刑事案件对公司造成不良品牌影响;2.殴打政府工作人员</p>
      <p className="titp">P1:涉及普通刑事犯罪,未造成不良品牌影响造成不良品牌影响治安事件</p>
      <p className="titp">P2:未造成不良影响的治安案件</p>
      <p className="titp">P3:扬言要杀人跳楼自杀等事件辱骂威胁骚扰等其他案件</p>
    </div>
  )
  const shixiao = <div className="titbox">客服响应时间-首次响应时间</div>
  useEffect(() => {
    getRider()
    getProvinceIdList()
  }, [])
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (ctitle, data, splist) => { 
      setVisible(true)
      setidNumberd('')
      form.resetFields()
    }
  }));
    // 获取省份
  const getProvinceIdList = (value) => {
    getProvinceIdListByRegionalNo().then((res)=>{
        if (res.data.success) {
            setProvinceList(res.data.data)
        }else{
          setProvinceList([])
        }
        
    })
  }
    // 根据省份获取城市
  const provinceIdChange = (value) => {
      if(value){
          getCityListByProvinceId({
              provinceId:value
          }).then((res)=>{
              if (res.data.success) {
                  setCityList(res.data.data)
              }
          })
      }else{
          setCityList([])
      }
  }
   // 获取骑手
   const getRider = async (value) => {
    const res = await getPerson()
    if (res.data.success) {
      setRenyunData(res.data.data||[])
    }
  }
  const renyuanChange = (value)=>{
    setidNumberd(value)
    let row = renyunData.filter(item=>{
      return item.idNumber == value
    })
    setName(row[0].name)
    form.setFieldsValue({
      eleCourierId: row[0].staffId,
      phone: row[0].phone
    })
  }
  // 新增
  const handleInsert = async (params) => {
    let result = await securityEventReport(params)
    if (result.data.success) {
      message.success(result.data.retMsg)
      form.resetFields()
      setVisible(false)
      refresh('query', {})
    } else {
      message.error(result.data.retMsg)
    }
    setLoading(false)
  }
  const handleOk = (submitType) => {
    setLoading(true)
    form.validateFields().then((values)=>{
      const data = form.getFieldsValue()
      let params = Object.assign(data, { type: submitType})
      params.eventReportTime = params.eventReportTime ? moment(params.eventReportTime).format('YYYY-MM-DD HH:mm:ss') : null
      params.eventTime = params.eventTime ? moment(params.eventTime).format('YYYY-MM-DD HH:mm:ss') : null
      params.firstAnswerTime = params.firstAnswerTime ? moment(params.firstAnswerTime).format('YYYY-MM-DD HH:mm:ss') : null
      params.serverAnswerTime = params.serverAnswerTime ? moment(params.serverAnswerTime).format('YYYY-MM-DD HH:mm:ss') : null
      params.name = name
      handleInsert(params)
    }).catch(()=>{
      setLoading(false)
      message.error('请完善表单')
    })
  }
  // 下拉框
  const selectFilter = (inputValue, option) => {
    if (option.children.indexOf(inputValue) != -1) {
        return option
    }
  }

  // 响应时间变化
  const AnswerChange = (inputValue) => {
    const firstAnswerTime = form.getFieldsValue().firstAnswerTime;
    const serverAnswerTime = form.getFieldsValue().serverAnswerTime;
    if(serverAnswerTime&&firstAnswerTime){
      const diff = subtractTime(firstAnswerTime,serverAnswerTime)
      form.setFieldsValue({
        caseResponseEffective: diff
      })
    }
  }
  const disabledDatesc = (current) => {
    let dates = form.getFieldsValue().serverAnswerTime
    if (!dates) {
      return false;
    }
    return current>dates&&current!=dates;
  };
  const disabledDatexy = (current) => {
    let dates = form.getFieldsValue().firstAnswerTime
    if (!dates) {
      return false;
    }
    return current<dates;
  };
  return (
    <>
      <Modal maskClosable={false}
        title="新增"
        width='1000px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={[
          <Button key="back" onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={()=>{handleOk(0)}}>
            暂存
          </Button>,
          <Button key="link" type="primary" loading={loading} onClick={()=>{handleOk(1)}}>
            提交审批
          </Button>,
        ]}
      >
        <Form initialValues={initialFormValues} className="addform" form={form}>
          <Row>
            <Col {...topCol}>
              <Form.Item name="eventSource" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件来源" rules={[{ required: true, message: '请选择事件来源!' }]} >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择" >
                  <Option value='1'>客服工单</Option>
                  <Option value='2'>战团反馈</Option>
                  <Option value='3'>饿了么客服</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件工单号" rules={[{ required: true, message: '请填写事件工单号!' }]} >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventReportTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="接报或上报时间" rules={[{ required: true, message: '请填写接报或上报时间!' }]} >
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  showTime
                  format={["YYYY-MM-DD HH:mm:ss"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityId" label="注册城市" rules={[{ required: true, message: '请选择注册城市!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择" >
                  <Option value='TJ' key='TJ'>天津市</Option>
                  <Option value='NC' key='NC'>南昌市</Option>
                  <Option value='JJ' key='JJ'>九江市</Option>
                  <Option value='GZ' key='GZ'>赣州市</Option>
                  <Option value='JDZ' key='JDZ'>景德镇市</Option>
                  <Option value='JN'>济南市</Option>
                  <Option value='LY'>临沂市</Option>
                  <Option value='QD'>青岛市</Option>
                  <Option value='WH'>威海市</Option>
                  <Option value='WF'>潍坊市</Option>
                  <Option value='YT'>烟台市</Option>
                  <Option value='ZB'>淄博市</Option>
                  <Option value='TY'>太原市</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventClassify" label="事件分类" rules={[{ required: true, message: '请选择事件分类!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Select allowClear placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="1">偷盗</Option>
                  <Option value="2">服务态度恶劣</Option>
                  <Option value="3">实施威胁行为(不当言论)</Option>
                  <Option value="4">实施威胁行为(无不当言论)</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventLevel" label="事件等级" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} tooltip={{ title: content, color: '#FFFFFF' }} >
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="ESU">ESU</Option>
                  <Option value="P0">P0</Option>
                  <Option value="P1">P1</Option>
                  <Option value="P2">P2</Option>
                  <Option value="P3">P3</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="idNumber" label="骑手姓名" labelCol={{ span: 6 }} rules={[{ required: true,message: '请选择骑手!' }]} wrapperCol={{ span: 13 }} >
                <Select placeholder="请选择" showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)} getPopupContainer={triggerNode => triggerNode.parentElement} onChange={(value) => renyuanChange(value)} >
                  {renyunData&&renyunData.map(item => (
                    <Option value={item.idNumber} key={item.idNumber}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eleCourierId" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="骑手ID">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="手机号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="身份证号">
                <Input disabled={true} value={idNumberd} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件发生时间" rules={[{ required: true,message: '请选择事件发生时间!' }]} >
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  showTime
                  format={["YYYY-MM-DD HH:mm:ss"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} required wrapperCol={{ span: 13 }} label="事发城市">
                <div style={{display: 'flex', justifyContent: 'space-between'}} >
                  <Form.Item style={{ marginBottom: 0, width: '100px' }} name="eventProvinceId" rules={[{ required: true,message: '请选择省份!' }]} >
                    <Select placeholder="请选择" onChange={(value) => { provinceIdChange(value) }} getPopupContainer={triggerNode => triggerNode.parentElement} >
                      {provinceList.map(item => (
                        <Option value={item.provinceId} key={item.provinceId}>{item.provinceName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <span style={{display: 'inline-block',height: '32px', lineHeight: '32px', margin: '0 5px'}} >-</span>
                  <Form.Item style={{flex: '1', marginBottom: 0}} name="eventCityId" rules={[{ required: true,message: '请选择事发城市!' }]} >
                    <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                      {cityList.map(item => (
                        <Option value={item.cityId} key={item.cityId}>{item.cityName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
            {/* <Col {...topCol}>
              <Form.Item name="eventRegionName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发区" rules={[{ required: true,message: '请输入事发区!' }]} >
                <Input placeholder='请输入事发区' />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventStreetName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发街道" rules={[{ required: true,message: '请输入事发街道!' }]} >
                <Input placeholder='请输入事发街道' />
              </Form.Item>
            </Col> */}
            <Col {...topCol}>
              <Form.Item name="firstAnswerTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="首次响应时间" >
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  showTime
                  disabledDate={disabledDatesc}
                  format={["YYYY-MM-DD HH:mm:ss"]} 
                  onChange={(value) => { AnswerChange(value)}} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="serverAnswerTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="客服响应时间" >
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  showTime
                  disabledDate={disabledDatexy}
                  format={["YYYY-MM-DD HH:mm:ss"]}
                  onChange={(value) => { AnswerChange(value)}} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseResponseEffective" label="案件响应时效" tooltip={{ title: shixiao, color: '#FFFFFF' }} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled={true}  />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="dealComment" label="督导处置意见" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写督导处置意见,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventPlace" label="事件发生地址" rules={[{ required: true,message: '请填写事件发生地址!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input placeholder="请填写事件发生地址" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventScene" label="事件发生情景" rules={[{ required: true,message: '请填写事件发生情景!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input placeholder="请填写事件发生情景" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventReason" label="事件发生起因" rules={[{ required: true,message: '请填写事件发生起因!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input placeholder="请填写事件发生起因" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="badBehavior" label="不良行为" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input placeholder="请填写不良行为" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventDescribe" label="事件经过描述" rules={[{ required: true,message: '请填写事件经过描述!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写事件经过描述,限500字" />
              </Form.Item>
            </Col>
            
            <Col {...topCol}>
              <Form.Item name="workFlag"  label="跑单时段" labelCol={{ span: 6 }} rules={[{ required: true,message: '请选择跑单时段!' }]} wrapperCol={{ span: 13 }}>
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="1">是</Option>
                  <Option value="0">否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="sensitiveFlag" label="涉及敏感人群" rules={[{ required: true,message: '请选择涉及敏感人群!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value="1">是</Option>
                  <Option value="0">否</Option>
                  {/* <Option value={0}>不涉及</Option>
                  <Option value={2}>14岁以下儿童</Option>
                  <Option value={3}>65岁以上老人</Option>
                  <Option value={4}>政府要员</Option>
                  <Option value={5}>外国友人</Option>
                  <Option value={6}>军人</Option>
                  <Option value={7}>媒体</Option>
                  <Option value={8}>三期妇女</Option>
                  <Option value={9}>伤残人士</Option>
                  <Option value={10}>高知名度社会人士</Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="reportedFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="是否报警">
                <Select placeholder="请选择" onChange={(value) => { setIsReported(value) }} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="1">是</Option>
                  <Option value="0">否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="judgedFlag" label="是否判责" rules={[{ required: isReported=='1',message: '请选择是否判责!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Select placeholder="请选择" onChange={(value) => { setIsJudged(value) }} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="1">是</Option>
                  <Option value="0">否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="involvedDuty" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="涉及责任方" rules={[{ required: isJudged=='1',message: '请填写涉及责任方!' }]}>
                <Input placeholder="请填写涉及责任方" maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dutyJudge" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="责任认定" rules={[{ required: isJudged=='1',message: '请填写责任认定!' }]} >
                <Input placeholder="请填写责任认定" maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="customerDemand" label="客户诉求" rules={[{ required: true, message: '请填写客户诉求!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input placeholder="请填写客户诉求" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riskyFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="是否有潜在风险">
                <Select placeholder="请选择" onChange={(value) => { setIsRisky(value)}} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="1">是</Option>
                  <Option value="0">否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riskDescribe" label="风险" rules={[{ required: isRisky=='1', message: '请填写风险描述!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写风险描述,限500字" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
});
export default AddMoel



