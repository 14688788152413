import React,{useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Button,
  DatePicker,
  Space,
  PageHeader
 } from 'antd';
import { withRouter } from "react-router-dom";
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
 
 const FormItem = Form.Item;
 const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }

function CompanyInvoiceDetail(props) {
    let path = 'platform:CompanyTicketApply'
    const [form] = Form.useForm();
    const {parmObj} = props
    const initSearch = {
        keyword:'',
    }
    const [month, setMonth] = React.useState('');
    const [tableData,setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
     
    const columns = [
        {
            title: '支付完成时间',
            dataIndex: 'payTime',
            key: 'payTime',
            width: 150,
            align:'center',
            
        }, {
            title: '账单月份',
            dataIndex: 'month',
            key: 'month',
            width: 150,
            align:'center',
            
        }, {
            title: '佣金账单ID',
            dataIndex: 'billNo',
            key: 'billNo',
            width: 150,
            align:'center',
            
        }, 
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align:'center',
            
        }, {
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align:'center',
            
        },
        {
            title: '落地方案',
            dataIndex: 'planTypeName',
            key: 'planTypeName',
            width: 150,
            align:'center',
        }, 
        {
            title: '佣金（元）',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            width: 150,
            align:'center',
            
        }, {
            title: '服务费（元）',
            dataIndex: 'serviceAmount',
            key: 'serviceAmount',
            width: 150,
            align:'center',
            
        },  {
            title: '税费（元）',
            dataIndex: 'taxAmount',
            key: 'taxAmount',
            width: 150,
            align:'center',
        },{
            title: '返税（元）',
            dataIndex: 'backTaxAmount',
            key: 'backTaxAmount',
            width: 150,
            align:'center',
        },
        {
            title: '开票金额',
            dataIndex: 'ticketAmount',
            key: 'ticketAmount',
            width: 150,
            align:'center',
        },
     
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} >
                    <Row>
                        <FormItem label="账单月份" name="month" style={itemStyle} {...labelStyleObj}>
                            <DatePicker onChange={(date, dateString) => setMonth(dateString)} picker="month" />
                        </FormItem>
                        <FormItem label="关键字" name="keyword"  style={itemStyle} {...labelStyleObj}>
                            <Input placeholder="姓名丨身份证号" />
                        </FormItem>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                            <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        </FormItem>
                    </Row>
                   
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        getsearchObj(){
            return (
                {
                    keyword:form.getFieldsValue().keyword,
                    companyCode:parmObj.companyCode,
                    ticketApplyNo:parmObj.ticketApplyNo,
                    month:month
                    
                }
            )
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setMonth('')
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/companyTicketApply/getPageDetail',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        handleExport(){
            request.downExport('/platform/companyTicketApply/exportDetail',methodsFn.getsearchObj())
        }
    }

    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={props.callFn}
                title="明细"
                subTitle={parmObj.companyName}
            />

            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content',y: 500 }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.billDetailNo}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />

         
        </div>
    )
}

export default withRouter(CompanyInvoiceDetail)
