import React,{useState,useEffect,useRef} from 'react'
import {
  Row,
  Table,
  Select,
  Form,
  Input,
  Col,
  Button,
  Space,
  message,
  Popconfirm
 } from 'antd';
 import MyModel1 from './MyModel1';
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
import { getTaxEnterpriseList }　from '../../../../api/api'
const { Option } = Select;
 

 const FormItem = Form.Item;
 const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }

function CityCounty(props) {
    let path = 'platform:SysAreaBase'
    const [form] = Form.useForm();
    const myModel1 = useRef();
    const initSearch = {
        // cityId:'',
        // countyId:'',
        // planType:''
    }
    const [cityList,setCityList] = useState([])
    const [countyList,setCountyList] = useState([])
    const [tableData,setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [titleModel,setTitleModel] = useState('新增')
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const [TaxEnterpriseLisStatus, setTaxEnterpriseLisStatus] = useState([])
    const columns = [
        {
            title: '财税企业',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: 360,
            align: 'center'
        },
        {
            title: '落地省份',
            dataIndex: 'provinceName',
            key: 'provinceName',
            align:'center',
            width: 110,
            
        }, 
        {
            title: '落地城市',
            dataIndex: 'cityName',
            key: 'cityName',
            align:'center',
            width: 150,
            
        }, {
            title: '落地区县',
            dataIndex: 'countyName',
            key: 'countyName',
            align:'center',
            width: 150,
            
        }, {
            title: '落地方案',
            dataIndex: 'planType',
            key: 'planType',
            align:'center',
            width: 150,
            render: (text, record) => ['','临时税务登记','个体工商户','委托代征'][record.planType]
        }, 
        {
            title: '证件容量',
            dataIndex: 'certificateCount',
            key: 'certificateCount',
            align:'center',
            width: 150,
            
        },  {
            title: '已用容量',
            dataIndex: 'useCount',
            key: 'useCount',
            align:'center',
            width: 110,
            
        }, {
            title: '未用容量',
            dataIndex: 'residueCount',
            key: 'residueCount',
            align:'center',
            width: 110,
            
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align:'center',
            width: 110,
            render: (text, record) => record.status?'启用':'停用'
        }, {
            title: '操作',
            key: 'action',
            align:'center',
            width:120,
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {store.getState().userReducer.permissions.indexOf(`${path}:update`)>-1&&<a onClick={() => {methodsFn.handleModify(record)}}>修改</a>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:status`)>-1&&<Popconfirm
                        title={`确定${record.status?'停用':'启用'}吗？`}
                        onConfirm={() => {
                            methodsFn.handleStatus(record)
                        }}
                        okText="确定"
                        cancelText="取消"
                        className="marginLeft"
                        >
                        <a onClick={() => {}}>{record.status?'停用':'启用'}</a>
                    </Popconfirm>}
                </Space>
            ),
            },
     
    ];
   
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        <FormItem label="落地城市" name="cityId"  style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder="全部" onChange={methodsFn.getCounty}>
                                {
                                    cityList.map(item => {
                                       return <Option value={item.id} key={item.id}>{item.areaName}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                        <FormItem label="落地区县" name="countyId"  style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder="全部">
                                {
                                    countyList.map(item => {
                                       return <Option value={item.id} key={item.id}>{item.areaName}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                        <FormItem label="落地方案" name="planType"  style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder="全部">
                                <Option value={1}>临时税务登记</Option>
                                <Option value={2}>个体工商户</Option>
                                <Option value={3}>委托代征</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="财税企业" name="taxEnterpriseCode" style={{ width:'360px',  marginRight:'15px' }} {...{ labelCol:{ span: 7}, wrapperCol:{ span: 17}}}>
                            <Select allowClear placeholder="全部">
                                {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                            </Select>
                        </FormItem>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right' }}>
                            <FormItem  >
                                <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                                {store.getState().userReducer.permissions.indexOf(`${path}:add`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleAdd}>新增</Button>}
                            </FormItem>
                            </Col>
                        </Row>
                   
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        async getCity(){
             const result = await request.get('/common/getCityList')
                setCityList(result)
        },
        async getCounty(cityId){
            const result = await request.get('/common/getCountyList',{
                cityId:cityId
            })
               setCountyList(result)
       },
     
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(form.getFieldsValue())
        },
        handleFormReset(){
            form.resetFields();
        },
          // 获取所有财税企业
         async getTaxEnterpriseListall(data={}){
            const res = await getTaxEnterpriseList(data)
            if (res.data.success) {
                console.log(data)
                if(data.status){
                    setTaxEnterpriseLisStatus(res.data.data)
                }else{
                    setTaxEnterpriseLis(res.data.data)
                }
            }
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/sysAreaBase/getList',Object.assign(searchObj,
                {
                    pageSize:pageSize,
                    pageNum:pageNum,
                }
            ))
               setTotal(result.total)
               setTableData(result)
        },
        handleAdd(){
            setTitleModel('新增')
            myModel1.current.showModal()
        },
        handleModify(record){
            setTitleModel('修改')
            myModel1.current.reviseModal(record)
        },
        refresh(){
            methodsFn.handleSearch()
        },
        handleStatus(obj){
            let status;
            if(obj.status){
                status = 0
            }else{
                status = 1
            }
            request.post('/platform/sysAreaBase/status',{
                areaNo:obj.areaNo,
                status:status,
            }).then(res => {
                message.success('操作成功')
                methodsFn.handleSearch()
            }).catch(err => {
                // message.error(err.msg)
            })
        }
   
    }

    useEffect(() => {
        methodsFn.getCity()
        methodsFn.getTaxEnterpriseListall()
        methodsFn.getTaxEnterpriseListall({status:1})
    },[])
    useEffect(() => {
        methodsFn.getTableList(form.getFieldsValue())
    
    },[pageNum])

    return (
        <div>
            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content',y:500}}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.areaNo}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
              }}
           />
           <MyModel1 ref={myModel1} TaxEnterpriseLisStatus={TaxEnterpriseLisStatus} refresh={methodsFn.refresh}  titleModel={titleModel}/>

         
        </div>
    )
}

export default CityCounty
