import React, { useImperativeHandle, useState, forwardRef, useEffect } from 'react'
import {
  Modal,
  Form,
  Select,
  Row,
  Col,
  Button,
  message,
  Upload,
  Checkbox
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { request } from '../../../../../utils/fetch'
import { getTaxEnterpriseList } from '../../../../../api/api'
import { contractTempInsert } from '../../api'
const topCol = {
  span: 12,
}
const topCol24 = {
  span: 24,
}
const colSpan = {
  labelCol: { flex: '150px' }, wrapperCol: { flex: '1' }
}
const fieldLists =[
  { field: "name", name: "姓名", flag: 0, span: 5},
  { field: "idNumber", name: "身份证号", flag: 0, span: 7 },
  { field: "financeUserName", name: "系统财务姓名", flag: 0, span: 12 },
  { field: "phone", name: "手机号", flag: 0, span: 5 },
  { field: "companyName", name: "公司名称", flag: 0, span: 7 },
  { field: "financeUserIdNumber", name: "系统财务身份证号", flag: 0, span: 12 },
  { field: "idNumberAddress", name: "身份证地址", flag: 0, span: 10 },
]
const action = "/wispay/common/uploadFile"
const AddMoel = (props,ref) => {
  const { refresh, companyList } = props
  const [visible, setVisible] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  // const [fileLists, setFileLists] = useState([])
  const [planTypeData, setPlanTypeData] = useState([]) // 落地方案列表
  const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
  const [form] = Form.useForm()
  const { Option } = Select
  const initialFormValues = {
    detailList: [
      { taxEnterpriseCode: null, pdfUrl: '', fieldList: '', planType: null }
    ]
  }
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (ctitle, data, splist) => {
      form.resetFields()
      setVisible(true);
    }
  }));
  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      let list = e&&e.map((item,index)=>{
        if(item.status === 'done'&&item.response){
          return {
            url: item.url || item.response.data[0].url,
            uid: item.uid || index,
            name: item.name||item.url,
            status: 'done',
          }
        }else{
          return {
            url: item.url,
            uid: item.uid || index,
            name: item.name||item.url,
            status: 'done',
          }
        }
      })
      return list;
    }
    let list1 = e&&e.fileList.map((item,index)=>{
      if(item.status === 'done'&&item.response){
        console.log(item.response)
        return {
          url: item.url || item.response.data[0].url,
          uid: item.uid || index,
          name: item.name||item.url,
          status: 'done',
        }
      }else{
        return {
          url: item.url,
          uid: item.uid || index,
          name: item.name||item.url,
          status: 'done',
        }
      }
    })
    return list1;
  };
  // 上传图片，把图片地址存入 licenseFileUrl
  const changeFile = ({ file, fileList }, type) => {
    console.log(type)
    fileList = fileList.map(item => {
      if (item.response&&item.response.success) {
        // Component will show file.url as link
        item.url = item.response.data[0].url;
      }
      return item;
    });
  }
  const getTaxEnterpriseListall = async ()=> {
    const res = await getTaxEnterpriseList()
    if (res.data.success) {
        setTaxEnterpriseLis(res.data.data)
    }
  }
  const companyChange = (value)=>{
    request.requestGet('/platform/companyBase/detail', {
      companyCode: value,
    }).then((res) => {
      setPlanTypeData(res.data.data.serviceConfigVoList)
      let serviceConfigVoList = res.data.data.serviceConfigVoList.filter(e=>{
        return e.status == 1
      })
      let detailList = serviceConfigVoList.map(ele=>{
        return { 
          taxEnterpriseCode: ele.taxEnterpriseCode, 
          pdfUrl: '', 
          fieldList: '', 
          planType: ele.planType
        }
      })
      form.setFieldsValue({
        detailList: detailList
      })
    })
  }
  const handleOk =  () => {
    setBtnLoading(true)
    form.validateFields().then((valus)=>{
      let values = form.getFieldsValue()
      let detailList = values.detailList.map(e=>{
        let pdfUrl = e.pdfUrl.map(e=>( e.url ))
        e.pdfUrl = pdfUrl.join(',')
        return e
      })
      const data = { ...values, detailList: detailList }
      let item = companyList.filter(ele=>{
        return ele.companyCode == data.companyCode 
      })[0]
      data.companyName = item.companyName
      contractTempInsert(data).then(result=>{
        if(result.data.success){
          message.success(result.data.retMsg)
          setVisible(false)
          refresh()
        }else{
          message.error(result.data.retMsg)
        }
        setBtnLoading(false)
      }).catch(()=>{
        setBtnLoading(false)
        message.error('网络错误')
      })
    }).catch(()=>{
      setBtnLoading(false)
      message.error('请完善表单')
    })
  }
  useEffect(() => {
    getTaxEnterpriseListall()
  }, [])
  return (
    <>
    <Modal maskClosable={false} title="新增" style={{minWidth: '1000px'}} width='1000px' forceRender visible={visible} onCancel={() => { setVisible(false) }}
      footer={[
        <Button key="back" loading={btnLoading} onClick={() => { setVisible(false) }}>取消</Button>,
        <Button key="link" loading={btnLoading} type="primary" onClick={()=>{handleOk()}} >确定</Button>,
      ]}
    >
      <Form initialValues={initialFormValues} form={form}>
        <Row className="margin-15">
          <Col {...topCol}>
            <Form.Item name="companyCode" rules={[{ required: true, message: '请选择企业名称!' }]}  {...colSpan} label="企业名称">
              <Select allowClear placeholder="请选择企业名称" onChange={companyChange} >
                {companyList.map(item => {
                  return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                })
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.List name="detailList">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField },index) => (
                <div key={key} >
                  <Row style={{width: '100%', border: '1px solid #C0C4CC', padding: '20px 10px 20px 0', marginBottom: '10px'}} >
                  <Col {...topCol}>
                      <Form.Item
                        {...restField}
                        label={`签署文件${index+1}`}
                        name={[name, 'pdfUrl']}
                        fieldKey={[fieldKey, 'pdfUrl']}
                        rules={[{ required: true, message: '请上传!' }]}
                        valuePropName="fileList" getValueFromEvent={normFile} {...colSpan} >
                        <Upload name="files" 
                          data={uploadObj} 
                          action={action} 
                          accept=".pdf"
                          maxCount={1}
                          onChange={({ file, fileList }) => changeFile({ file, fileList }, {key, name, fieldKey})}
                          >
                          {<Button type="primary" icon={<UploadOutlined />} >上传</Button>}
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col {...topCol}></Col>
                    <Col {...topCol}>
                      <Form.Item {...restField} label={`签署文件${index+1}的落地方案`} name={[name, 'planType']} fieldKey={[fieldKey, 'planType']}
                        rules={[{ required: true, message: '请选择!' }]}  {...colSpan} >
                        <Select allowClear placeholder="请选择落地方案" disabled >
                          {planTypeData.map((item) => {
                            return (
                              <Option value={item.planType} key={item.planType}>
                                {['', '临时税务登记', '个体工商户', '委托代征'][item.planType]}
                              </Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col {...topCol24}>
                      <Form.Item {...restField} label={`签署文件${index+1}的财税`} name={[name, 'taxEnterpriseCode']} fieldKey={[fieldKey, 'taxEnterpriseCode']}
                        rules={[{ required: true, message: '请选择!' }]}  {...colSpan} >
                        <Select allowClear placeholder="请选择财税企业" disabled style={{width: '70%'}} >
                          {TaxEnterpriseLis.map((e) => (
                            <Option key={e.code} value={e.code}>
                              {e.enterpriseName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col {...topCol24}>
                      <Form.Item 
                        {...restField}
                        label={`签署文件${index+1}的填充信息`}
                        name={[name, 'fieldList']}
                        fieldKey={[fieldKey, 'fieldList']}
                        {...colSpan} >
                        <Checkbox.Group style={{width: '100%'}} >
                          <Row>
                            {fieldLists.map((e)=>(<Col key={e.field} span={e.span}>
                              <Checkbox value={e.field} >{e.name}({e.field})</Checkbox>
                            </Col>))}
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* {fields.length>1&&<Button danger type="primary" style={{position: 'absolute',top: '0',right: '0'}} onClick={() => remove(name)} >删除</Button>} */}
                </div>
              ))}
              {/* <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  添加签署
                </Button>
              </Form.Item> */}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
    </>
  );
};
export default forwardRef(AddMoel)



