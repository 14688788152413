import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Form, Input, Modal, Row, Col, Typography, Upload, Button ,message} from "antd";
import { CloudUploadOutlined } from '@ant-design/icons'
import { getContractArchivesDetail, filingContractArchives } from '../../api'

const { TextArea } = Input;
const { Title } = Typography
const action = "/wispay/common/uploadFile"
const ConfigModal = forwardRef((props,ref)=>{
    const uploadObj = {
        token: localStorage.getItem('token'),
        mobile: localStorage.getItem('mobile')
    }
    const {refresh} = props
    const [title, setTitle] = useState('归档');
    const [visible, setVisible] = useState(false);
    const [fileList, setFileList] = useState([])
    const [form] = Form.useForm()
    const initialValues = {}
    useImperativeHandle(ref, () => ({
        filedData: (record) => {
            getContractArchivesDetail({contractNo:record.contractNo}).then(res=>{
                form.setFieldsValue({
                    ...res.data.data,
                    filingFlag:res.data.data.filingFlag ===1 ? '已归档' : '未归档'
                })
            })
            setTitle('归档')
            setVisible(true)
        },
    }));

    const onChange = ({file}) => {
        if (file.response&&file.status==='done') {
            if(file.response.success){
                setFileList([file])
            }else{
                setFileList([])
            }
        }
    }
    const normFile = e => {
        if (e && e.fileList.length < 1) {
            return null
        }
        if (e.file.response&&e.file.status==='done') {
            if(e.file.response.success){
                return e.file.response.data[0].url
            }else{
                message.error(e.file.response.retMsg)
                if (e.file.response.retCode === '000302') {
                    localStorage.clear()
                    window.location = '/login'
                }
                return null
            }
        }else{
            return null
        }
      }
    const onRemove = () => {
        form.setFieldsValue({ file: null })
        setFileList([])
    }

    const onFinish = (values) => {
        filingContractArchives({
            fileUrl: values.file,
            contractNo:values.contractNo,
            filingFlag:0
        
        }).then(res=>{
            if(res.data.success){
                message.success('归档成功')
                form.resetFields()
                setFileList([])
                setVisible(false)
                refresh()
            }else{
                message.error(res.data.retMsg)
            }
        })
    }

    const onCancel = ()=>{
        setVisible(false)
        form.resetFields()
        setFileList([])
    }

    return (
      <Modal maskClosable={false}
        title={title} 
        visible={visible} 
        width={800}
        onCancel={onCancel}
        footer={[
            <Button key="back" onClick={onCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={form.submit}>
              保存
            </Button>]}>
        <Form
            form={form}
            labelCol={{ flex: '100px' }}
            initialValues={initialValues}
            onFinish={onFinish}
            >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} style={{ margin: '0px 20px 10px 20px', color: '#23a8a8' }}>合同信息</Title>
                </Col>
                <Col span={12}>
                    <Form.Item name="dingEndTime" label="审批通过时间" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="sourceName" label="来源" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="processNo" label="流程编号" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='contractNo' label='合同编码' >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="contractName" label="合同名称" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="partyA" label="合同甲方" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="partyB" label="合同乙方" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="contractStartDate" label="起始日期">
                       <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="contractEndDate" label="终止日期">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="applyName" label="申请人">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="applyDepartment" label="部门">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="applyDate" label="申请日期">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="sealTypeName" label="用印类型">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="stampTypeName" label="印章类型">
                        <Input disabled/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Title level={5} style={{ margin: '20px 20px 10px 20px', color: '#23a8a8' }}>档案信息</Title>
                </Col>
                <Col span={24}>
                    <Form.Item name='file' rules={[{ required: true, message: '请上传合同附件' }]} valuePropName='file' getValueFromEvent={normFile} label="合同附件">
                        <Upload maxCount={1} accept='.pdf,.PDF' action={action} onChange={onChange} onRemove={onRemove} fileList={fileList} name="files" data={uploadObj}>
                            <Button type='primary' icon={<CloudUploadOutlined />}>
                                上传文件
                            </Button>
                        </Upload>
                    </Form.Item>
                </Col>
                <Col span={24}></Col>
                <Col span={12}>
                    <Form.Item name="reverseSignatureDay" label="倒签时间">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="sealNum" label="用印份数">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="sealMsg" label="用印说明">
                        <TextArea disabled rows={3} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item className='wrapl' name="sendAddress" label="回寄联系人及地址">
                        <TextArea disabled rows={3} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
      </Modal>
    );
})

export default ConfigModal;
