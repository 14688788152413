import React, { useState, useEffect } from 'react'
import { Row, Col, Table, Form, Button, Modal, Typography, message, Space, PageHeader, Upload } from 'antd'
import qs from 'qs'
import axios from 'axios'
import { UploadOutlined } from '@ant-design/icons'
import { getTianJinDataDetailList, getTianJinDataDetailError, getTianJinDataDetailSubmit } from '../../api'
import store from '../../../../../store/index'
const { Link } = Typography

const formItemLayout = {
    labelCol: {
        span: 6
    },
    wrapperCol: {
        span: 14
    }
}

function Detail(props) {
    let path = 'platform:dj_import'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g, ''))
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const [importForm] = Form.useForm()
    const [reImportForm] = Form.useForm()
    const [tableData, setTableData] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]) //选中的数据的key
    const [selectedRows, setSelectedRows] = useState([]) //选中的数据
    const [selectedRow, setSelectedRow] = useState({}) //单个提交的数据
    const [fileList, setFileList] = useState([]) // 上传文件 整个页面共享的
    const [isBat, setIsBat] = useState('') // 是否批量提交 批量导入
    const [sureModalVisible, setSureModalVisible] = useState(false) // 提交 批量提交
    const [errorArry, setErrorArry] = useState([]) // 错误提示文字
    const [errorModalVisible, setErrorModalVisible] = useState(false) // 错误提示
    const [urlCode, setUrlCode] = useState('') // 下载模板的编码
    const [importCode, setImportCode] = useState('') //上传的importCode
    const [importModalVisible, setimportModalVisible] = useState(false) //上传 批量上传
    const [reImportTips, setReImportTips] = useState('') // 重新上传弹窗里面的提示文字
    const [reImportModalVisible, setReImportModalVisible] = useState(false) // 重新上传
    const [loading, setLoading] = useState(false) // 弹窗按钮loading 整个页面共享
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const fileProps = {
        //上传文件配置 整个页面共享的
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept: '.xls, .xlsx',
        beforeUpload: () => {
            return false
        },

        onChange(info) {
            let fileCon = [...info.fileList]
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1)
            setFileList(fileCon)
        }
    }

    const columns = [
        {
            title: '月份',
            dataIndex: 'month',
            align: 'center',
            width: 100
        },
        {
            title: '类型',
            dataIndex: 'typeName',
            align: 'center',
            width: 100
        },
        {
            title: '状态',
            align: 'center',
            render: record => {
                return record.times > 1 && record.taskStatus === 2 ? '重新导入待提交' : record.taskStatusName
            },
            width: 100
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            fixed: 'right',
            width: 150,
            render: (text, record) => (
                <Space size='middle'>
                    {record.fileUrl && (
                        <Link
                            onClick={() => {
                                methodsFn.goPreview(record)
                            }}
                        >
                            预览
                        </Link>
                    )}
                    {record.taskStatus === 2 && permissions.includes(`${path}:submit`) && (
                        <Link
                            onClick={() => {
                                methodsFn.goSubmit(record)
                            }}
                        >
                            提交
                        </Link>
                    )}
                    {record.taskStatus === -2 && permissions.includes(`${path}:submit`) && (
                        <Link
                            onClick={() => {
                                methodsFn.goSubmit(record)
                            }}
                        >
                            重新提交
                        </Link>
                    )}
                    {record.taskStatus === 0 && permissions.includes(`${path}:import`) && (
                        <Link
                            onClick={() => {
                                methodsFn.goImport(record)
                            }}
                        >
                            导入
                        </Link>
                    )}
                    {(record.taskStatus === -2 || record.taskStatus === -1 || record.taskStatus === 4) && permissions.includes(`${path}:import`) && (
                        <Link
                            onClick={() => {
                                methodsFn.goReImport(record)
                            }}
                        >
                            重新导入
                        </Link>
                    )}

                    {(record.taskStatus === -2 || record.taskStatus === -1) && (
                        <Link
                            onClick={() => {
                                methodsFn.goError(record)
                            }}
                        >
                            失败原因
                        </Link>
                    )}
                </Space>
            )
        }
    ]

    //组件渲染函数
    const myComponent = {
        renderSearchForm() {
            return (
                <PageHeader onBack={() => props.history.goBack()} title='明细'>
                    <Row>
                        <Col style={{ margin: '0 20px' }}>月份: {searchParam.month}</Col>
                        <Col>业务类型： {searchParam.type === '1' ? '直营' : '众包'}</Col>
                    </Row>
                    <Row justify='end'>
                        {/* <Button
                            type='primary'
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                                methodsFn.goImport('bat')
                            }}
                        >
                            批量导入
                        </Button> */}
                        <Button
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                                methodsFn.getTableList('refree')
                            }}
                        >
                            刷新
                        </Button>
                    </Row>
                </PageHeader>
            )
        }
    }
    //方法函数
    const methodsFn = {
        async getTableList(code) {
            getTianJinDataDetailList({
                pageSize: pageSize,
                pageNum: pageNum,
                businessCode: searchParam.businessCode
            }).then(({ data: { data, success, retMsg } }) => {
                if (success) {
                    setSelectedRowKeys([])
                    setTotal(data.totalCount)
                    setTableData(data.list)
                    if (code === 'refree') {
                        message.success('刷新成功！')
                    }
                } else {
                    message.error(retMsg)
                }
            })
        },
        goPreview(record) {
            props.history.push(`/admin/tianJinData/detail/preview?type=${record.type}&importCode=${record.importCode}`)
        },

        // 打开失败原因弹窗
        goError(record) {
            setErrorModalVisible(true)
            getTianJinDataDetailError({
                errorType: record.taskStatus === -1 ? '1' : '2',
                importCode: record.importCode
            }).then(({ data: { data, success, retMsg } }) => {
                if (success) {
                    setErrorArry(data.list)
                } else {
                    message.error(retMsg)
                }
            })
        },

        // 提交弹窗
        goSubmit(record) {
            setSelectedRow(record)
            setIsBat('')
            setSureModalVisible(true)
        },
        // 提交弹窗的submit
        sureModalSubmit() {
            setLoading(true)
            getTianJinDataDetailSubmit({
                type: selectedRow.type,
                importCode: selectedRow.importCode
            })
                .then(({ data: { success, retMsg } }) => {
                    if (success) {
                        message.success('提交成功！')
                        methodsFn.getTableList()
                        setSureModalVisible(false)
                    } else {
                        message.error(retMsg)
                    }
                    setLoading(false)
                })
                .catch(() => setLoading(false))
        },

        // 打开 导入弹窗
        goImport(record) {
            if (record === 'bat' && selectedRows.length <= 1) {
                message.error('请选择至少2条数据')
                return
            }

            if (record === 'bat') {
                setIsBat('bat')
                setimportModalVisible(true)
                setUrlCode('DJ_PLDR')
            } else {
                setImportCode(record.importCode)
                setUrlCode(['DJ_FWF', 'DJ_DDSJ', 'DJ_SB', 'DJ_GSHZ', 'DJ_XXFP', 'DJ_GSMX'][record.type - 3])
                setimportModalVisible(true)
            }
        },
        // 关闭 导入弹窗
        closeImportModal() {
            setimportModalVisible(false)
            setFileList([])
            setImportCode('')
            setIsBat('')
            setUrlCode('')
            importForm.resetFields()
        },
        // 导入 弹窗提交
        async importModalSubmit() {
            let values = await importForm.validateFields()
            let file = values.ffile.file
            let data = new FormData() //通过FormData将文件转成二进制数据

            data.append('token', localStorage.getItem('token'))
            data.append('mobile', localStorage.getItem('mobile'))
            data.append('file', file)

            let url = '' // 批量上传还是单个上传
            if (isBat === 'bat') {
                data.append('importCodeList', selectedRowKeys.join(','))
                url = '/wispay/platform/dj_import/batchImport'
            } else {
                data.append('importCode', importCode)
                url = '/wispay/platform/dj_import/import'
            }

            setLoading(true)

            axios({
                method: 'post',
                url: url,
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                    if (res.data.success) {
                        methodsFn.closeImportModal()
                        methodsFn.getTableList()
                        message.success(res.data.retMsg)
                    } else {
                        message.error(res.data.retMsg)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                })
        },

        // 重新导入弹窗
        goReImport(record) {
            const tipsArry = [
                '',
                '', // 平台
                '', // 发布方
                '骑手id',
                '骑手id', // 骑手、订单
                '骑手id、税款所属期起、税款所属期止、征收项目', // 申报
                '扣缴义务人识别号、税款所属期起、税款所属期止、税率', // 个税汇总
                '发票代码、发票号码', // 销项发票
                '骑手id、税款所属期起、税款所属期止' // 个税明细
            ]
            setUrlCode(['DJ_FWF', 'DJ_DDSJ', 'DJ_SB', 'DJ_GSHZ', 'DJ_XXFP', 'DJ_GSMX'][record.type - 3])
            setReImportTips(tipsArry[record.type])
            setImportCode(record.importCode)
            setReImportModalVisible(true)
        },
        // 关闭 重新导入弹窗
        closeReImportModal() {
            setReImportModalVisible(false)
            setFileList([])
            setImportCode('')
            setUrlCode('')
            reImportForm.resetFields()
        },
        // 重新导入弹窗提交
        async reImportModalSubmit() {
            let values = await reImportForm.validateFields()
            let file = values.ffile.file
            let data = new FormData() //通过FormData将文件转成二进制数据

            data.append('token', localStorage.getItem('token'))
            data.append('mobile', localStorage.getItem('mobile'))
            data.append('file', file)
            data.append('importCode', importCode)

            setLoading(true)

            axios({
                method: 'post',
                url: `/wispay/platform/dj_import/import`,
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                    if (res.data.success) {
                        methodsFn.closeReImportModal()
                        methodsFn.getTableList()
                        message.success(res.data.retMsg)
                    } else {
                        message.error(res.data.retMsg)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                })
        }
    }
    // 表格行选择配置
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
        getCheckboxProps: record => ({
            disabled: record.taskStatus !== 0
        }),
        selectedRowKeys
    }

    useEffect(() => {
        methodsFn.getTableList()
    }, [pageNum])

    return (
        <div>
            {myComponent.renderSearchForm()}
            <Table
                // rowSelection={rowSelection}
                scroll={{ x: 'max-content', y: 550 }}
                columns={columns}
                rowKey={record => record.importCode}
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: total => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
            />
            {/* 导入Modal */}
            <Modal maskClosable={false} title='导入' confirmLoading={loading} visible={importModalVisible} onOk={methodsFn.importModalSubmit} onCancel={methodsFn.closeImportModal}>
                <Form form={importForm} {...formItemLayout}>
                    <Form.Item label='上传表格' name='ffile' rules={[{ required: true, message: '请上传表格' }]}>
                        <Upload {...fileProps}>
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                            <Button
                                type='link'
                                onClick={e => {
                                    e.stopPropagation()
                                    window.location.href = `/wispay/common/getTemplateDownloadUrl?token=${localStorage.getItem('token')}&mobile=${localStorage.getItem('mobile')}&type=${urlCode}`
                                }}
                            >
                                下载模板
                            </Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
            {/* 重新导入Modal */}
            <Modal maskClosable={false} title='重新导入' confirmLoading={loading} visible={reImportModalVisible} onOk={methodsFn.reImportModalSubmit} onCancel={methodsFn.closeReImportModal}>
                <Form form={reImportForm} {...formItemLayout}>
                    {reImportTips !== '' && (
                        <div style={{ padding: '0 30px 30px' }}>
                            <span style={{ color: 'red' }}>{reImportTips}</span> 信息与之前导入提交数据一致则视为修改原数据，不一致视为新增导入。
                        </div>
                    )}

                    <Form.Item label='上传表格' name='ffile' rules={[{ required: true, message: '请上传表格' }]}>
                        <Upload {...fileProps}>
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                            <Button
                                type='link'
                                onClick={e => {
                                    e.stopPropagation()
                                    window.location.href = `/wispay/common/getTemplateDownloadUrl?token=${localStorage.getItem('token')}&mobile=${localStorage.getItem('mobile')}&type=${urlCode}`
                                }}
                            >
                                下载模板
                            </Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
            {/* 失败原因Modal */}
            <Modal maskClosable={false} title='提示' visible={errorModalVisible} footer={null} onCancel={() => setErrorModalVisible(false)}>
                <div>
                    <p style={{ marginBottom: '20px' }}>失败原因：</p>
                    {errorArry.map(item => {
                        return (
                            <p key={item.id}>
                                第{item.rowNumber}行：{item.message}
                            </p>
                        )
                    })}
                </div>
            </Modal>
            {/* 提交 批量提交Modal */}
            <Modal maskClosable={false} title='提示' confirmLoading={loading} visible={sureModalVisible} onCancel={() => setSureModalVisible(false)} onOk={methodsFn.sureModalSubmit}>
                <div>确定提交数据至天津么？</div>
            </Modal>
        </div>
    )
}

export default Detail
