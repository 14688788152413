import React, { useState, useRef,useEffect } from 'react';
import { Spin, Tabs } from 'antd';
import QueryForm from './components/QueryForm'
import ReportTableList from './components/ReportTableList'
import ClosureTableList from './components/ClosureTableList'
// import TableList from './components/TableList'
import AddMoel from './components/AddMoel'
import { getDingtalkUser } from '../api'
function OrderTrail() {
    const [loading] = useState(false)
    const [activeKey,setActiveKey] = useState('1')
    const [DingkUser, setDingkUser] = useState([]);
    const ReportTableRef = useRef();
    const ClosureTableRef = useRef();
    const AddMoelRef = useRef();
    
    useEffect(() => {
        getUser()
        getlist()
    }, [activeKey])
    const onTabsChange = (value) => {
        setActiveKey(value)
    }
    const getlist = (data)=>{
        if(activeKey=='1'){
            ReportTableRef.current.getlist(data)
        }else{
            ClosureTableRef.current.getlist(data)
        }
    }
    const getUser = () => {
        getDingtalkUser().then(res => {
          if (res.data.success) {
            setDingkUser(res.data.data)
          }
        })
    }
    const refresh = (val,data,splist)=>{
        switch (val) {
            case "query":
                getlist(data);
                break;
            case "add":
                AddMoelRef.current.showModal('新增');
                break;
            default: 
        }
    }
    return (
        <Spin spinning={loading}>
            <QueryForm DingkUser={DingkUser} activeKey={activeKey} refresh={refresh}></QueryForm>
            <div className='tablenotop' style={{ backgroundColor: '#fff' }} >
                <Tabs defaultActiveKey="1" tabBarStyle={{ paddingLeft: '20px', marginBottom: '2px' }} onChange={onTabsChange}>
                    <Tabs.TabPane tab={`报备`} key="1">
                        <ReportTableList refresh={refresh} ref={ReportTableRef} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={`结案`} key="2">
                        <ClosureTableList refresh={refresh} ref={ClosureTableRef} />
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <AddMoel DingkUser={DingkUser} refresh={refresh} ref={AddMoelRef} ></AddMoel>
        </Spin>
    )
}

export default OrderTrail
