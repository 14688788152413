import React, { useState, useEffect } from 'react'
import { Tree, Row, Col, Table, Button, Empty, message } from 'antd'
import { request } from '../../../../utils/fetch'

function OrganizationManage() {
    const [treeData, setTreeData] = useState([])
    const [tableData, setTableData] = useState([])
    const [timeStatus, setTimeStatus] = useState('')
    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: '所属部门',
            dataIndex: 'departmentName',
            align: 'center',
        },
        {
            title: '联系电话',
            dataIndex: 'mobile',
            align: 'center',
        },
    ]
    //获取组织架构列表
    const getTreeList = async () => {
        const result = await request.get('/platform/dingtalkUser/getDingtalkOrganization').catch((err) => {
            return Promise.reject(err)
        })
        setTreeData(result.list)
        setTimeStatus(result.lastStatus + '--' + result.lastTime)
    }
    // 选中树形节点触发列表更新
    const onSelect = async (data) => {
        const result = await request
            .get('/platform/dingtalkUser/getDingtalkUser', {
                departmentId: data[0],
            })
            .catch((err) => {
                return Promise.reject(err)
            })
        setTableData(result)
    }
    //同步组织架构
    const renewal = async () => {
        const result = await request.get('/platform/dingtalkUser/renewal').catch((err) => {
            return Promise.reject(err)
        })
        message.success(result)
    }
    useEffect(() => {
        getTreeList()
    }, [])

    return (
        <Row>
            <Col flex='320px' style={{ maxHeight: 600, overflow: 'auto', background: '#fff' }}>
                {treeData.length === 0 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : (
                    <Tree
                        style={{
                            minHeight: 274,
                            padding: 10,
                        }}
                        onSelect={onSelect}
                        treeData={treeData}
                    />
                )}
            </Col>
            <Col flex='auto' style={{ marginLeft: 30 }}>
                <Row align='middle'>
                    <Button type='primary' onClick={renewal}>
                        同步钉钉组织架构
                    </Button>
                    <Col style={{ marginLeft: 20 }}>{timeStatus}</Col>
                </Row>
                <Table columns={columns} dataSource={tableData} rowKey='userId' pagination={false} />
            </Col>
        </Row>
    )
}

export default OrganizationManage
