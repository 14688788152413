import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  PageHeader,
  Image,
  Divider,
  Button,
  Popconfirm,
  Table,
  Skeleton,
  message
} from 'antd'
import store from '../../../../store/index'
import './CheckDetail.css'
import { getCompanyDetail, updateConfigStatus } from '../api'

import BaseModal from './components/BaseModal'
import AuditModal from './components/AuditModa'
import ManageModal from './components/ManageModal'
import ConfigModal from './components/ConfigModal'

const CheckDetail = (props) => {
  console.log(props)
  let path = 'platform:CompanyBase'
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState({})
  const [baseVisible, setBaseVisible] = useState(false)
  const [auditVisible, setAuditVisible] = useState(false)
  const [manageVisible, setManageVisible] = useState(false)
  const [configVisible, setConfigVisible] = useState(false)
  const [configVoList, setConfigVoList] = useState([])
  const params = props.match.params
  useEffect(() => {
    getDetail()
  }, [])
  // 获取详情
  const getDetail = async () => {
    try {
      setLoading(true)
      const result = await getCompanyDetail({ companyCode: params.id })
      if (result.data.success) {
        setDetail(result.data.data)
        let list = result.data.data.serviceConfigVoList.map(e=>e.planType)
        setConfigVoList(list)
      } else {
        message.error(result.data.retMsg)
      }
      console.log(result)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log('__企业详情', err)
    }

  }
  const tableColumns = [
    {
      title: '收件人',
      dataIndex: 'receiptName',
      key: 'receiptName'
    }, {
      title: '联系电话',
      dataIndex: 'receiptPhone',
      key: 'receiptPhone'
    }, {
      title: '收件地址',
      dataIndex: 'receiptAddress',
      key: 'receiptAddress'
    }

  ]
  // 修改基础信息
  const updateBase = () => {
    if (!detail.status) {
      message.warning('停用时不可以修改')
      return
    }
    setBaseVisible(true)
  }
  // 修改自动审核信息
  const updateAudit = () => {
    if (!detail.status) {
      message.warning('停用时不可以修改')
      return
    }
    setAuditVisible(true)
  }
  // 基础信息弹窗
  const baseClose = () => {
    setBaseVisible(false)
  }
  // 自动审核弹窗
  const auditClose = () => {
    setAuditVisible(false)
  }
  // 修改管理员账户
  const manageModalOpen = () => {
    if (!detail.status) {
      message.warning('停用时不可以修改')
      return
    }
    setManageVisible(true)
  }
  const manageClose = () => {
    setManageVisible(false)
  }
  // 修改配置
  const configModalOpen = () => {
    if (!detail.status) {
      message.warning('停用时不可以修改')
      return
    }
    setConfigVisible(true)
  }
  const configClose = () => {
    setConfigVisible(false)
  }
  const confirm = () => {

  }
  const handlestatus = async (item) => {
    console.log(item)
    let status = ''
    if(item.status==1){
      status = 0
    }else{
      status = 1
    }
    let result = await updateConfigStatus({status:status,serviceConfigCode:item.serviceConfigCode,companyCode:item.companyCode})
    if (result.data.success) {
      let list = detail.serviceConfigVoList.map((e)=>{
        if(e.serviceConfigCode == item.serviceConfigCode){
          return {
            ...e,
            status: status
          }
        }else{
          return e
        }
      })
      console.log(list)
      setDetail(prev => {
        return {
            ...prev,
            serviceConfigVoList: list
        }
    })
      message.success(result.data.retMsg)
    } else {
      message.error(result.data.retMsg)
    }
  }
  // 修改成功后刷新
  const refresh = () => {
    getDetail()
  }
  const goPage = () => {
    const data = {
      companyName: detail.companyName
    }
    props.history.push({ pathname: `/admin/historyConfig/${detail.companyCode}`, state: data })
  }
  return (
    <>
      <PageHeader
        title="查看"
        onBack={() => props.history.goBack()}
      />

      <div className="mes-content">
        <Skeleton loading={loading} active>
          <div style={{ position: 'relative' }}>
            <div className="mes-box">
              <div className="box-top">
                <div className="title">基本信息</div>
                {store.getState().userReducer.permissions.indexOf(`${path}:updateBase`) > -1 && <Button type="primary" shape="round" onClick={() => updateBase()}>修改</Button>}
              </div>
              <div className="box-row">
                <span className="row-label">企业名称</span>
                <span>{detail.companyName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">项目性质</span>
                <span>{detail.projectTypeName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">项目名称</span>
                <span>{detail.projectName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">行业分类</span>
                <span>{detail.industryTypeName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">纳税人类型</span>
                <span>{detail.taxpayerTypeName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">统一社会信用代码</span>
                <span>{detail.taxpayerNumber}</span>
              </div>
              <div className="box-row">
                <span className="row-label">单位地址</span>
                <span>{detail.address}</span>
              </div>
              <div className="box-row">
                <span className="row-label">注册电话</span>
                <span>{detail.registeredPhone}</span>
              </div>
              <div className="box-row">
                <span className="row-label">开户行名称</span>
                <span>{detail.bankName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">银行账号</span>
                <span>{detail.bankNumber}</span>
              </div>
              <div className="box-row">
                <span className="row-label">法人姓名</span>
                <span>{detail.legalPersonName}</span>
              </div>
              {/* <div className="box-row">
                <span className="row-label">浦发银行审核模式</span>
                {detail.checkMode == '0' && <span>不启用（信任模式）</span>}
                {detail.checkMode == '1' && <span>企业网银审核</span>}
                {detail.checkMode == '2' && <span>开放银行审核</span>}
                {detail.checkMode == '3' && <span>短信验证审核</span>}
                {detail.checkMode == '4' && <span>文件对比审核</span>}
              </div> */}
              <div className="box-row">
                <span className="row-label">联系邮箱</span>
                <span>{detail.contactEmail}</span>
              </div>
              {/* <div className="box-row">
                <span className="row-label">银行子户</span>
                <span>{detail.refAccountCompanyName}</span>
              </div> */}
              <div className="box-row">
                <span className="row-label">营业执照</span>
                <span>
                  {
                    detail.licenseFileUrl &&
                    <Image
                      width={100}
                      src={detail.licenseFileUrl}
                    />
                  }

                </span>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', right: '30px', top: '60px' }}>
              {configVoList.includes(2) && 
                <div style={{ width: '220px' }}>
                  <Image width={200} src={detail.unitPictureUrl} />
                  <a href={detail.unitPictureUrl} target="_blank" download={detail.unitPictureUrl} style={{ display: 'block', textAlign: 'center' }}>下载二维码</a>
                </div>
              }
              {configVoList.includes(1) &&
                <div style={{ width: '220px' }}>
                  <Image width={200} src={detail.taxPictureUrl} />
                  <a href={detail.taxPictureUrl} target="_blank" download={detail.taxPictureUrl} style={{ display: 'block', textAlign: 'center' }}>下载二维码</a>
                </div>
              }
              {configVoList.includes(3) &&
                <div style={{ width: '220px' }}>
                  <Image width={200} src={detail.entrustPictureUrl} />
                  <a href={detail.entrustPictureUrl} target="_blank" download={detail.entrustPictureUrl} style={{ display: 'block', textAlign: 'center' }}>下载二维码</a>
                </div>
              }
            </div>
          </div>
          <Divider />
          <div className="mes-box">
            <div className="box-top">
              <div className="title">佣金审核</div>
              {store.getState().userReducer.permissions.indexOf(`${path}:updateBase`) > -1 && <Button type="primary" shape="round" onClick={() => updateAudit()}>修改</Button>}
            </div>
            {
              <div className="box-row">
                <span className="row-label">人工审核</span>
                <span>{detail.auditModeName}</span>
              </div>
            }
          </div>
          {/* <div className="mes-box">
            <div className="box-top">
              <div className="title">
                银行确权
              </div>
            </div>

            <div className="box-row">
              <span className="row-label">银行确权手机号</span>
              <span>{detail.mblNo}</span>
            </div>
            <div className="box-row">
              <span className="row-label">确权状态</span>
              <span>{detail.mobileConfirmStatus == '01' || detail.mobileConfirmStatus == null ? '未确权' : '已确权'}</span>
            </div>
          </div> */}
          <Divider />
          <div className="mes-box">
            <div className="box-top">
              <div className="title">
                落地配置　({detail.serviceConfigVoList && detail.serviceConfigVoList.length > 0 && detail.serviceConfigVoList[0].effectTime + '开始生效'})
              </div>
              {store.getState().userReducer.permissions.indexOf(`${path}:updateConfig`) > -1 && <Button type="primary" shape="round" style={{ marginRight: '30px' }} onClick={() => configModalOpen()}>修改</Button>}
              {store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 && <a onClick={goPage}>历史配置信息</a>}
            </div>

            {detail.serviceConfigVoList && detail.serviceConfigVoList.length > 0 && detail.serviceConfigVoList.map(item => {
              let title = ''
              if(item.status==1){
                title = '暂停'
              }else{
                  title = '开放'
              }
              let bot = <Popconfirm
                title={`确定${title}吗？`}
                onConfirm={() => {
                    handlestatus(item)
                }}
                okText="确定"
                cancelText="取消"
                className="marginLeft"
                >
                <Button style={{marginLeft: "40px"}} shape="round" type="primary" size="small">{item.status==1?'暂停':'开放'}</Button>
              </Popconfirm>
              
              
              return <div key={item.planType}>
                {item.planType === 1 && <div className="sub-title">临时税务登记 {bot}</div>}
                {item.planType === 2 && <div className="sub-title">个体工商户 {bot}</div>}
                {item.planType === 3 && <div className="sub-title">委托代征 {bot}</div>}

                <div className="box-row">
                  <span className="row-label">财税企业</span>
                  <span>{item.taxEnterpriseName}</span>
                </div>
                <div className="box-row">
                  <span className="row-label">税费</span>
                  <span>单笔打款佣金{item.taxPoint}%</span>
                </div>
                <div className="box-row">
                  <span className="row-label">是否返税</span>
                  <span>{item.backTaxFlag === 1 ? '返税' : '不返税'}</span>
                </div>
                {
                  item.backTaxFlag === 0 ?
                    <div className="box-row">
                      <span className="row-label">开票</span>
                      <span>开票金额=佣金+服务费+税费</span>
                    </div>
                    :
                    <>
                      <div className="box-row">
                        <span className="row-label">返税</span>
                        <span>单笔打款佣金的{item.backTaxPoint}%</span>
                      </div>
                      <div className="box-row">
                        <span className="row-label">开票</span>
                        <span>{item.taxTicketFlag === 0 ? '返税不开票（开票金额=佣金+服务费+税费-返税）' : '返税开票（开票金额=佣金+服务费+税费）'}</span>
                      </div>
                    </>
                }
                <div className="box-row">
                  <span className="row-label">常规服务费</span>
                  <div>
                    {item.serviceLadderFlag === 1 &&
                      <>
                        <p>阶梯式</p>
                        {
                          item.companyConfigDetailList && item.companyConfigDetailList.map(p =>
                            <p key={p.sort}>第{p.sort}阶段：{p.minAmount}≤当月（自然月）累计批量佣金金额 &lt; {p.maxAmount}万时，单笔佣金的{item.serviceType === 1 ? '固定金额' : '百分比'}，每笔{p.value}{item.serviceType === 1 ? '元' : '%'}</p>
                          )
                        }

                      </>
                    }
                    {
                      item.serviceLadderFlag === 0 &&
                      <>
                        <p>非阶梯</p>
                        <p>{item.serviceType === 1 ? '单笔佣金的固定金额' : '单笔佣金的百分比'}, 每笔{item.serviceValue}{item.serviceType === 1 ? '元' : '%'}</p>
                      </>
                    }
                  </div>
                </div>
                <div className="box-row">
                  <span className="row-label">大金额服务费</span>
                  <span>单笔佣金≥3万，每笔{item.bigServicePoint}%</span>
                </div>
              </div>
            })}
          </div>
          <Divider />
          <div className="mes-box">
            <div className="box-top">
              <div className="title">企业管理员</div>
              {store.getState().userReducer.permissions.indexOf(`${path}:updateManager`) > -1 && <Button type="primary" shape="round" style={{ marginRight: '30px' }} onClick={() => manageModalOpen()}>修改</Button>}
              {/* <Popconfirm placement="top" title="是否确定将密码重置为手机号后六位的初始密码？" onConfirm={confirm} okText="确认" cancelText="取消">
                  <Button shape="round">重置登录密码</Button>
                </Popconfirm> */}
            </div>
            <div className="box-row">
              <span className="row-label">姓名</span>
              <span>{detail.manageName}</span>
            </div>
            <div className="box-row">
              <span className="row-label">手机号</span>
              <span>{detail.managePhone}</span>
            </div>
          </div>
          <Divider />
          <div className="mes-box">
            <div className="box-top">
              <div>发票收件信息</div>
            </div>
            <Table columns={tableColumns} dataSource={detail.companyReceiptList} rowKey={record => record.receiptNo}></Table>
          </div>
        </Skeleton>
      </div>

      <BaseModal
        visible={baseVisible}
        close={baseClose}
        modalData={detail}
        refresh={refresh}
      />
      <AuditModal
        visible={auditVisible}
        close={auditClose}
        modalData={detail}
        refresh={refresh}
      />
      <ManageModal
        visible={manageVisible}
        close={manageClose}
        modalData={detail}
        refresh={refresh}
      />
      <ConfigModal
        visible={configVisible}
        close={configClose}
        modalData={detail}
        refresh={refresh}
      />
    </>
  )
}

export default CheckDetail