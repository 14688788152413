import React, { useState, useEffect } from 'react'
import { useImmer } from 'use-immer'
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Modal,
  Radio,
  Checkbox,
  Space,
  message,
  InputNumber,
  Row,
  Col
} from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import store from '../../../../store/index'
import { insertCompanyList, getCityCountyByType, getBankList, updateManage, getCompanyAccountLists } from '../api'

import './index.css'

const { Option } = Select

// const formItemLayout = {
//   labelCol: { span: 4 },
//   wrapperCol: { span: 12 },
// }


function CompanyEnter(props) {
  let path = 'platform:CompanyBase'
  const [form] = Form.useForm()
  const [temporaryDuty, setTemporaryDuty] = useState(true) // 临时税务
  const [licenseFileUrl, setLicenseFileUrl] = useState()
  const [privately, setPrivately] = useState(true) // 个体工商
  const [commissionBasis, setCommissionBasis] = useState(true) // 委托代征
  const [submitLoading, setSubmitLoading] = useState(false)
  const [draftLoading, setDraftLoading] = useState(false)
  const [cityCounty, setCityCounty] = useState([])
  const [privateCityCounty, setPrivatecityCounty] = useState([])
  const [commissionBasisCityCounty, setCommissionBasisCityCounty] = useState([])
  const [disabled, setDisabled] = useState(false) // 同时勾选了临时税务和个体工商，不能选阶梯式
  const [bankList, setBankList] = useState([])
  const [companyAccountLists, setCompanyAccountLists] = useState([])

  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 8 },
  }

  // 获取城市区县
  const getCityCounty = async (planType) => {
    try {
      const result = await getCityCountyByType({ planType: planType })
      if (result.data.success) {
        if (planType === 1) {
          setCityCounty(result.data.data)
        } else if (planType === 2) {
          setPrivatecityCounty(result.data.data)
        } else if (planType === 3) {
          setCommissionBasisCityCounty(result.data.data)
        } else {
          message.error(result.data.retMsg)
        }
      } else {
        message.error(result.data.retMsg)
      }
    } catch (err) {
      console.log('__企业入驻城市片区', err)
    }
  }
  const getCompanyAccounts = async () => {
    try {
      const result = await getCompanyAccountLists()
      if (result.data.success) {
        setCompanyAccountLists(result.data.data)
      } else {
        message.error(result.data.retMsg)
      }
    } catch (err) {
      console.log('__企业入驻银行子户', err)
    }
  }
  // 获取银行列表
  const getBankLists = async () => {
    let res = await getBankList()
    console.log(res)
    if (res.data.success) {
      setBankList(res.data.data)
    }
  }
  const initialFormValues = {
    companyName: '',
    industryType: '',
    taxpayerType: '',
    taxpayerNumber: '',
    address: '',
    registeredPhone: '',
    bankNo: '',
    checkModal: '',
    bankNumber: '',
    legalPersonName: '',
    contactEmail: '',
    // refAccountCompanyCode: '',
    projectType: 1, // 项目性质 1-注册入驻 2-导入入驻（众包）
    projectCode:'', //项目名称 ELE饿了么 CN菜鸟 HM盒马
    auditMode: 1  //佣金审核模式 0-人工审核 1-自动审核通过
  }
  const [temporaryDutyData, updateTemporaryDutyData] = useImmer({
    areaNo: '',
    cityId: '',
    countyId: '',
    taxEnterpriseCode: '',
    planType: 1,
    taxPoint: '',
    backTaxFlag: 1,
    backTaxPoint: '',
    taxTicketFlag: 0,
    serviceLadderFlag: 1,
    serviceType: 1,
    serviceValue: null,
    bigServicePoint: '',
    companyConfigDetailList: [
      {
        sort: '1',
        minAmount: '0',
        maxAmount: '',
        value: ''
      }
    ]
  })
  // 个体工商户
  const [privatelyData, updatePrivatelyData] = useImmer({
    areaNo: '',
    cityId: '',
    countyId: '',
    taxEnterpriseCode: '',
    planType: 2,
    taxPoint: '',
    backTaxFlag: 1,
    backTaxPoint: '',
    taxTicketFlag: 0,
    serviceLadderFlag: 1,
    serviceType: 1,
    serviceValue: null,
    bigServicePoint: '',
    companyConfigDetailList: [
      {
        sort: '1',
        minAmount: '0',
        maxAmount: '',
        value: ''
      }
    ]
  })
  // 委托代征
  const [commissionBasisData, updateCommissionBasisData] = useImmer({
    areaNo: '',
    cityId: '',
    countyId: '',
    taxEnterpriseCode: '',
    planType: 3,
    taxPoint: '',
    backTaxFlag: 1,
    backTaxPoint: '',
    taxTicketFlag: 0,
    serviceLadderFlag: 1,
    serviceType: 1,
    serviceValue: null,
    bigServicePoint: '',
    companyConfigDetailList: [
      {
        sort: '1',
        minAmount: '0',
        maxAmount: '',
        value: ''
      }
    ]
  })
  const [administrator, setAdministrator] = useState({
    managePhone: '',
    manageName: ''
  })
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList: '',
    previewTitle: ''
  })

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
  // 上传图片，把图片地址存入 licenseFileUrl
  const handleChange = ({ file, fileList }) => {
    console.log(file, fileList)
    let licenseFileUrl = ''

    fileList.forEach(item => {

      if (item.status === 'done') {
        if (item.response.retCode === '000302') {
          localStorage.clear()
          window.location = '/login'
        } else {
          licenseFileUrl = item.response.data[0].url
        }
      }
    })
    setLicenseFileUrl(licenseFileUrl)
    setPreviewData(prev => {
      return {
        ...prev,
        fileList: fileList
      }
    })

  }
  const handleCancel = () => {
    setPreviewData(prev => {
      return {
        ...prev,
        previewVisible: false
      }
    })
  }
  // 
  const temporaryDutyChange = (e) => {
    if (e.target.checked) {
      getCityCounty(1)
    }
    setTemporaryDuty(e.target.checked)
  }
  // 
  const privatelyChange = (e) => {
    if (e.target.checked) {
      getCityCounty(2)
    }
    setPrivately(e.target.checked)
  }
  const commissionBasisChange = (e) => {
    if (e.target.checked) {
      getCityCounty(3)
    }
    setCommissionBasis(e.target.checked)
  }
  // 初次加载 获取落地配置的城市区县
  useEffect(() => {
    if (temporaryDuty) {
      getCityCounty(1)
    }
    if (privately) {
      getCityCounty(2)
    }
    if (commissionBasis) {
      getCityCounty(3)
    }
    getBankLists()
    getCompanyAccounts()
  }, [])
  // 同时选中两个以上，阶梯不可选
  useEffect(() => {
    if ((temporaryDuty && privately) || (temporaryDuty && commissionBasis) || (privately && commissionBasis)) {
      setDisabled(true)
      updateTemporaryDutyData(draft => {
        draft.serviceLadderFlag = 0
      })
      updatePrivatelyData(draft => {
        draft.serviceLadderFlag = 0
      })
      updateCommissionBasisData(draft => {
        draft.serviceLadderFlag = 0
      })
    } else {
      setDisabled(false)
    }
  }, [temporaryDuty, privately, commissionBasis])


  // // 审核模式选择
  // const checkModeChange = (value) => {
  //   setCheckMode(value)
  // }
  // 更新配置数据 type 1 临时税务 2 个体工商 3 委托代征
  // field 字段名  index有的话 该字段是数组，name是field[index]的属性
  const updateData = (value, type, field, index, name) => {
    if (type === 1) {
      // 如果是落地城市区县，从数据中获取cityId, countyId
      if (field === 'taxEnterpriseCode') {
        let arr = cityCounty.filter(item => item.taxEnterpriseCode === value)
        let cityId = arr[0].cityId
        let countyId = arr[0].countyId
        let areaNo = arr[0].areaNo
        updateTemporaryDutyData(draft => {
          draft.cityId = cityId
          draft.countyId = countyId
          draft.areaNo = areaNo
        })
      }

      if (index > -1) {
        updateTemporaryDutyData(draft => {
          draft[field][index][name] = value
        })
      } else {
        updateTemporaryDutyData(draft => {
          draft[field] = value
        })
      }
    }
    if (type === 2) {
      if (field === 'taxEnterpriseCode') {
        let arr = privateCityCounty.filter(item => item.taxEnterpriseCode === value)
        let cityId = arr[0].cityId
        let countyId = arr[0].countyId
        let areaNo = arr[0].areaNo
        updatePrivatelyData(draft => {
          draft.cityId = cityId
          draft.countyId = countyId
          draft.areaNo = areaNo
        })
      }
      if (index > -1) {
        updatePrivatelyData(draft => {
          draft[field][index][name] = value
        })
      } else {
        updatePrivatelyData(draft => {
          draft[field] = value
        })
      }
    }
    if (type === 3) {
      if (field === 'taxEnterpriseCode') {
        let arr = commissionBasisCityCounty.filter(item => item.taxEnterpriseCode === value)
        let cityId = arr[0].cityId
        let countyId = arr[0].countyId
        let areaNo = arr[0].areaNo
        updateCommissionBasisData(draft => {
          draft.cityId = cityId
          draft.countyId = countyId
          draft.areaNo = areaNo
        })
      }
      if (index > -1) {
        updateCommissionBasisData(draft => {
          draft[field][index][name] = value
        })
      } else {
        updateCommissionBasisData(draft => {
          draft[field] = value
        })
      }
    }
    console.log(temporaryDutyData,privatelyData,commissionBasisData)
  }
  // 增加阶梯费行
  const addRow = (planType) => {
    if (planType === 1) {
      updateTemporaryDutyData(draft => {
        console.log(draft.companyConfigDetailList)
        draft.companyConfigDetailList = [...draft.companyConfigDetailList, {
          sort: '',
          minAmount: '',
          maxAmount: '',
          value: ''
        }].map((item, index) => {
          item.sort = Number(index) + 1
          return item
        })
      })
    }
    if (planType === 2) {
      updatePrivatelyData(draft => {
        console.log(draft.companyConfigDetailList)
        draft.companyConfigDetailList = [...draft.companyConfigDetailList, {
          sort: '',
          minAmount: '',
          maxAmount: '',
          value: ''
        }].map((item, index) => {
          item.sort = Number(index) + 1
          return item
        })
      })
    }
    if (planType === 3) {
      updateCommissionBasisData(draft => {
        console.log(draft.companyConfigDetailList)
        draft.companyConfigDetailList = [...draft.companyConfigDetailList, {
          sort: '',
          minAmount: '',
          maxAmount: '',
          value: ''
        }].map((item, index) => {
          item.sort = Number(index) + 1
          return item
        })
      })
    }
  }
  const deleteRow = (index, planType) => {
    if (planType === 1) {
      updateTemporaryDutyData(draft => {
        draft.companyConfigDetailList.splice(index, 1)
        draft.companyConfigDetailList = draft.companyConfigDetailList.map((item, idx) => {
          item.sort = idx
          return item
        })
      })
    }
    if (planType === 2) {
      updatePrivatelyData(draft => {
        draft.companyConfigDetailList.splice(index, 1)
        draft.companyConfigDetailList = draft.companyConfigDetailList.map((item, idx) => {
          item.sort = idx
          return item
        })
      })
    }
    if (planType === 3) {
      updateCommissionBasisData(draft => {
        draft.companyConfigDetailList.splice(index, 1)
        draft.companyConfigDetailList = draft.companyConfigDetailList.map((item, idx) => {
          item.sort = idx
          return item
        })
      })
    }

  }
  // 渲染配置服务费 type 1 阶梯式固定金额 2 阶梯式百分比 3 费阶梯式固定金额 4 非阶梯式百分比
  // planType 1 临时税务 2 个体工商 3 委托代征
  const renderService = (data, planType, type) => {
    if (type === 1 || type === 2) {
      return data.map((item, index) => (
        <div className="row" key={index}>
          <span className="row-tit"></span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ width: '70px' }}>第{index + 1}阶段: </span>
            {index > 0 && <div>
              <InputNumber value={item.minAmount} min={0} onChange={(value) => updateData(value, planType, 'companyConfigDetailList', index, 'minAmount')} size="small" style={{ width: '70px' }} />
              万≤
            </div>}
            <span>当月(自然月)累计批量佣金总额</span> &lt;
            <InputNumber value={item.maxAmount} min={0} onChange={(value) => updateData(value, planType, 'companyConfigDetailList', index, 'maxAmount')} size="small" style={{ width: '70px' }} />
            万时, {type === 1 ? '单笔佣金的固定金额' : '单笔佣金的百分比'}, 每笔 <InputNumber value={item.value} min={0} onChange={(value) => updateData(value, planType, 'companyConfigDetailList', index, 'value')} size="small" style={{ width: '70px' }} /> {type === 1 ? '元' : '%'}
            <Button type="primary" shape="circle" icon={<PlusOutlined />} style={{ marginLeft: '10px' }} onClick={() => addRow(planType)} />
            {index > 0 && <Button type="primary" shape="circle" icon={<MinusOutlined />} style={{ marginLeft: '10px' }} onClick={() => deleteRow(index, planType)} />}
          </div>
        </div>
      ))
    }
    if (type === 3 || type === 4) {
      return (
        <div className="row">
          <span className="row-tit"></span>
          <div>
            {
              planType === 1 && <>
                {type === 3 ? '单笔佣金的固定金额' : '单笔佣金的百分比'}, 每笔<InputNumber value={temporaryDutyData.serviceValue} min={0} onChange={(value) => updateData(value, planType, 'serviceValue')} size="small" style={{ width: '80px' }} /> {type === 3 ? '元' : '%'}
              </>
            }
            {
              planType === 2 &&
              <>
                {type === 3 ? '单笔佣金的固定金额' : '单笔佣金的百分比'}, 每笔<InputNumber value={privatelyData.serviceValue} min={0} onChange={(value) => updateData(value, planType, 'serviceValue')} size="small" style={{ width: '80px' }} /> {type === 3 ? '元' : '%'}
              </>
            }
            {
              planType === 3 &&
              <>
                {type === 3 ? '单笔佣金的固定金额' : '单笔佣金的百分比'}, 每笔<InputNumber value={commissionBasisData.serviceValue} min={0} onChange={(value) => updateData(value, planType, 'serviceValue')} size="small" style={{ width: '80px' }} /> {type === 3 ? '元' : '%'}
              </>
            }
          </div>
        </div>
      )
    }

  }
  // 
  const updateManagePhone = (value) => {

    setAdministrator(prev => {
      return { ...prev, managePhone: value }
    })
  }
  const updateManageName = (value) => {
    setAdministrator(prev => {
      return { ...prev, manageName: value }
    })
  }
  // 提交
  const submit = async () => {
    let serviceConfigVoList = []

    console.log(temporaryDutyData, privatelyData, commissionBasisData)

    if (temporaryDuty) {
      // if(temporaryDutyData.taxEnterpriseCode==''){
      //   message.warning('请选择临时税务——财税企业')
      //   return
      // }
      // if(temporaryDutyData.taxPoint==''){
      //   message.warning('请选择临时税务——税费')
      //   return
      // }
      serviceConfigVoList.push(temporaryDutyData)
    }
    if (privately) {
      serviceConfigVoList.push(privatelyData)
    }
    if (commissionBasis) {
      serviceConfigVoList.push(commissionBasisData)
    }
    if (!temporaryDuty && !privately && !commissionBasis) {
      message.warning('落地配置至少填写一个')
      return
    }
    let data = Object.assign({}, form.getFieldsValue(), { operateType: 2, licenseFileUrl: licenseFileUrl, serviceConfigVoList: serviceConfigVoList }, administrator)
    try {
      await form.validateFields()
      if (!licenseFileUrl) {
        message.warning('请上传营业执照')
        return
      }
      if (!administrator.manageName) {
        message.warning('请填写管理员姓名！')
        return
      }

      if (!(/^1[3456789]\d{9}$/).test(administrator.managePhone) || !administrator.managePhone) {
        message.warning('请输入正确的手机号！')
        return
      }
      setSubmitLoading(true)
      const result = await insertCompanyList(data)
      setSubmitLoading(false)
      console.log(result)
      if (result.data.success) {
        message.success('入驻成功')
        props.history.push({ pathname: '/admin/companyLists' })
      } else {
        message.error(result.data.retMsg)
      }
    } catch (error) {
      console.log('__企业入驻', error)
      setSubmitLoading(false)
    }
  }
  // 暂存
  const draft = async () => {
    let serviceConfigVoList = []
    if (temporaryDuty) {
      serviceConfigVoList.push(temporaryDutyData)
    }
    if (privately) {
      serviceConfigVoList.push(privatelyData)
    }
    if (commissionBasis) {
      serviceConfigVoList.push(commissionBasisData)
    }
    let data = Object.assign({}, form.getFieldsValue(), { operateType: 1, licenseFileUrl: licenseFileUrl, serviceConfigVoList: serviceConfigVoList }, administrator)
    try {
      if (!form.getFieldValue('companyName')) {
        message.warning('请填写企业名称！')
        return
      }
      if (!form.getFieldValue('industryType')) {
        message.warning('请选择行业分类！')
        return
      }
      setDraftLoading(true)
      const result = await insertCompanyList(data)
      if (result.data.success) {
        message.success('暂存成功')
        props.history.push({ pathname: '/admin/companyLists' })
      } else {
        setDraftLoading(false)
        message.error(result.data.retMsg)
      }
    } catch (error) {
      console.log('__企业入驻暂存', error)
      setDraftLoading(false)
    }
  }
  // 清空
  const resetData = () => {
    form.resetFields()
    updateTemporaryDutyData(draft => {
      draft.areaNo = ''
      draft.cityId = ''
      draft.countyId = ''
      draft.taxEnterpriseCode = ''
      draft.planType = 1
      draft.taxPoint = ''
      draft.backTaxFlag = 1
      draft.backTaxPoint = ''
      draft.taxTicketFlag = 0
      draft.serviceType = 1
      draft.serviceValue = null
      draft.bigServicePoint = ''
      draft.companyConfigDetailList = [
        {
          sort: '0',
          minAmount: '0',
          maxAmount: '',
          value: ''
        }
      ]
    })
    updatePrivatelyData(draft => {
      draft.areaNo = ''
      draft.cityId = ''
      draft.countyId = ''
      draft.taxEnterpriseCode = ''
      draft.planType = 2
      draft.taxPoint = ''
      draft.backTaxFlag = 1
      draft.backTaxPoint = ''
      draft.taxTicketFlag = 0
      draft.serviceType = 1
      draft.serviceValue = null
      draft.bigServicePoint = ''
      draft.companyConfigDetailList = [
        {
          sort: '0',
          minAmount: '0',
          maxAmount: '',
          value: ''
        }
      ]
    })
    updateCommissionBasisData(draft => {
      draft.areaNo = ''
      draft.cityId = ''
      draft.countyId = ''
      draft.taxEnterpriseCode = ''
      draft.planType = 3
      draft.taxPoint = ''
      draft.backTaxFlag = 1
      draft.backTaxPoint = ''
      draft.taxTicketFlag = 0
      draft.serviceType = 1
      draft.serviceValue = null
      draft.bigServicePoint = ''
      draft.companyConfigDetailList = [
        {
          sort: '0',
          minAmount: '0',
          maxAmount: '',
          value: ''
        }
      ]
    })
    setAdministrator({
      manageName: '',
      managePhone: ''
    })
  }
  return (
    <div className="company-enter">
      <div className="title">基本信息</div>
      <Form form={form} initialValues={initialFormValues} {...formItemLayout}>
        <Form.Item name="companyName" rules={[{ required: true }]} label="企业名称">
          <Input placeholder="请输入" />
        </Form.Item>
        {/* 项目性质的的导入入驻：导入入驻即代表该企业是众包企业 */}
        <Form.Item name="projectType" rules={[{ required: true }]} label="项目性质">
          <Radio.Group>
            <Radio value={2}>导入入驻</Radio>
            <Radio value={1}>注册入驻</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="projectCode" rules={[{ required: true }]} label="项目名称">
          <Select placeholder="请选择">
            <Option value='ELE'>饿了么</Option>
            <Option value='CN'>菜鸟</Option>
            <Option value='HM'>盒马</Option>
            <Option value='WL'>物流</Option>
          </Select>
        </Form.Item>
        <Form.Item name="industryType" rules={[{ required: true }]} label="行业分类">
          <Select placeholder="请选择">
            <Option value={1}>交通运输服务业</Option>
            <Option value={2}>信息技术服务业</Option>
            <Option value={3}>物流辅助服务业</Option>
            <Option value={4}>广播影视服务业</Option>
            <Option value={5}>供应链服务业</Option>
            <Option value={6}>居民生活日常服务业</Option>
          </Select>
        </Form.Item>
        <Form.Item name="taxpayerType" rules={[{ required: true }]} hasFeedback label="纳税人类型">
          <Select placeholder="请选择">
            <Option value="1">一般纳税人</Option>
            <Option value="2">小规模纳税人</Option>
          </Select>
        </Form.Item>
        <Form.Item name="taxpayerNumber" rules={[{ required: true }]} label="统一社会信用代码">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="address" rules={[{ required: true }]} label="单位地址">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="registeredPhone" rules={[{ required: true }]} label="注册电话">
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="bankNo" rules={[{ required: true }]} label="开户行名称">
          {/* <Input placeholder="请输入" /> */}
          <Select placeholder="请选择">
            {
              bankList.map(item => (
                <Option value={item.bankNo} key={item.bankNo}>{item.bankName}</Option>
              ))
            }
          </Select>
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} name="bankNumber" rules={[{ required: true }]} label="银行账号">
              <Input placeholder="请输入" />
            </Form.Item>
          </Col>
          {/* <Col>
            <span style={{ color: 'red' }}>注意：银行信息1对1绑定子户，用于充值打款以及开票</span>
          </Col> */}
        </Row>

        <Form.Item name="legalPersonName" rules={[{ required: true }]} label="法人姓名">
          <Input placeholder="请输入" />
        </Form.Item>
        {/* <Form.Item name="checkMode" rules={[{ required: true }]} label="浦发银行审核模式">
          <Select>
            <Option value="0">不启用（信任模式）</Option>
            <Option value="1">企业网银审核</Option>
            <Option value="2">开放银行审核</Option>
            <Option value="3">短信验证审核</Option>
            <Option value="4">文件对比审核</Option>
          </Select>
        </Form.Item> */}
        <Form.Item name="contactEmail" rules={[
          {
            type: 'email',
            message: '邮箱格式不正确!',
          }, {
            required: true
          }]} label="联系邮箱">
          <Input placeholder="请输入" />
        </Form.Item>
        {/* <Form.Item name="refAccountCompanyCode" label="银行子户">
          <Select>
            {
              companyAccountLists.map(item => (
                <Option value={item.companyCode} key={item.companyCode}>{item.companyName}</Option>
              ))
            }
          </Select>
        </Form.Item> */}
        <Form.Item rules={[{ required: true }]} label="营业执照">
          <Upload
            action="/wispay/common/uploadFile"
            listType="picture-card"
            data={uploadObj}
            name="files"
            fileList={previewData.fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {previewData.fileList.length > 0 ? null : uploadButton}
          </Upload>
          <Modal maskClosable={false}
            visible={previewData.previewVisible}
            title={previewData.previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
          </Modal>
        </Form.Item>
        <div className="title" style={{ marginLeft: '-10px' }}>佣金审核</div>
        <Form.Item name="auditMode"  rules={[{ required: true }]} label="人工审核">
          <Radio.Group>
            <Radio value={0}>人工审核判定通过或拒绝</Radio>
            <Radio value={1}>自动审核通过</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>

      <div className="title">落地配置</div>
      <Checkbox className="checkbox" checked={temporaryDuty} onChange={temporaryDutyChange}>临时税务登记</Checkbox>
      {temporaryDuty &&
        <>
          <div className="row">
            <span className="row-tit">财税企业：</span>
            <Select placeholder="请选择" name="taxEnterpriseCode" onChange={(value) => updateData(value, 1, 'taxEnterpriseCode')} value={temporaryDutyData.taxEnterpriseCode} style={{ width: '400px' }}>
              {cityCounty.map(item => (
                <Option value={item.taxEnterpriseCode} key={item.taxEnterpriseCode}>{item.taxEnterpriseName}</Option>
              ))}
            </Select>
          </div>
          <div className="row">
            <span className="row-tit">税费：</span>
            <div className="row-r">
              单笔打款佣金的
              <InputNumber placeholder="请输入" min={0} onChange={(value) => updateData(value, 1, 'taxPoint')} value={temporaryDutyData.taxPoint} size="middle" style={{ width: '160px', margin: '0 10px' }}></InputNumber > %
            </div>
          </div>
          <div className="row">
            <span className="row-tit">是否返税：</span>
            <Radio.Group onChange={(e) => updateData(e.target.value, 1, 'backTaxFlag')} value={temporaryDutyData.backTaxFlag}>
              <Radio value={1}>返税</Radio>
              <Radio value={0}>不返税</Radio>
            </Radio.Group>
          </div>
          {temporaryDutyData.backTaxFlag === 1 ? (
            <>
              <div className="row">
                <span className="row-tit">返税：</span>
                <div className="row-r">
                  单笔打款佣金的
                  <InputNumber placeholder="请输入" min={0} onChange={(value) => updateData(value, 1, 'backTaxPoint')} value={temporaryDutyData.backTaxPoint} size="middle" style={{ width: '160px', margin: '0 10px' }}></InputNumber> %
                </div>
              </div>
              <div className="row">
                <span className="row-tit">开票：</span>
                <Radio.Group onChange={(e) => updateData(e.target.value, 1, 'taxTicketFlag')} value={temporaryDutyData.taxTicketFlag}>
                  <Radio value={1}>返税开票（开票金额=佣金+服务费+税费）</Radio>
                  <Radio value={0}>返税不开票（开票金额=佣金+服务费+税费-返税）</Radio>
                </Radio.Group>
              </div>
            </>
          ) : (
            <div className="row">
              <span className="row-tit">开票：</span>
              <span>开票金额=佣金+服务费+税费</span>
            </div>
          )}
          <div className="row">
            <span className="row-tit">常规服务费：</span>
            <Radio.Group onChange={(e) => updateData(e.target.value, 1, 'serviceLadderFlag')} value={temporaryDutyData.serviceLadderFlag}>
              <Radio value={1} disabled={disabled}>阶梯式</Radio>
              <Radio value={0}>非阶梯式</Radio>
            </Radio.Group>
          </div>
          <div className="row">
            <span className="row-tit"></span>
            <Radio.Group onChange={(e) => updateData(e.target.value, 1, 'serviceType')} value={temporaryDutyData.serviceType}>
              <Radio value={2}>单笔佣金百分比</Radio>
              <Radio value={1}>单笔佣金的固定金额</Radio>
            </Radio.Group>
          </div>
          <>
            {
              (temporaryDutyData.serviceLadderFlag === 1 && temporaryDutyData.serviceType === 1)
              && renderService(temporaryDutyData.companyConfigDetailList, 1, 1)
            }
            {
              (temporaryDutyData.serviceLadderFlag === 1 && temporaryDutyData.serviceType === 2)
              && renderService(temporaryDutyData.companyConfigDetailList, 1, 2)
            }
            {
              (temporaryDutyData.serviceLadderFlag === 0 && temporaryDutyData.serviceType === 1)
              && renderService(temporaryDutyData.companyConfigDetailList, 1, 3)
            }
            {
              (temporaryDutyData.serviceLadderFlag === 0 && temporaryDutyData.serviceType === 2)
              && renderService(temporaryDutyData.companyConfigDetailList, 1, 4)
            }
          </>
          <div className="row">
            <span className="row-tit">大金额服务费</span>
            <div>
              单笔佣金≥3万，每笔<InputNumber min={0} value={temporaryDutyData.bigServicePoint} onChange={(value) => updateData(value, 1, 'bigServicePoint')} size="small" style={{ width: '80px' }} /> %
            </div>
          </div>
        </>}
      <Checkbox className="checkbox" checked={privately} onChange={privatelyChange}>个体工商户</Checkbox>
      {privately &&
        <>
          <div className="row">
            <span className="row-tit">财税企业：</span>
            <Select placeholder="请选择" name="taxEnterpriseCode" onChange={(value) => updateData(value, 2, 'taxEnterpriseCode')} value={privatelyData.taxEnterpriseCode} style={{ width: '400px' }}>
              {privateCityCounty.map(item => (
                <Option value={item.taxEnterpriseCode} key={item.taxEnterpriseCode}>{item.taxEnterpriseName}</Option>
              ))}
            </Select>
          </div>
          <div className="row">
            <span className="row-tit">税费：</span>
            <div className="row-r">
              单笔打款佣金的
              <InputNumber min={0} placeholder="请输入" onChange={(value) => updateData(value, 2, 'taxPoint')} value={privatelyData.taxPoint} size="middle" style={{ width: '160px', margin: '0 10px' }}></InputNumber> %
            </div>
          </div>
          <div className="row">
            <span className="row-tit">是否返税：</span>
            <Radio.Group onChange={(e) => updateData(e.target.value, 2, 'backTaxFlag')} value={privatelyData.backTaxFlag}>
              <Radio value={1}>返税</Radio>
              <Radio value={0}>不返税</Radio>
            </Radio.Group>
          </div>
          {privatelyData.backTaxFlag === 1 ? (
            <>
              <div className="row">
                <span className="row-tit">返税：</span>
                <div className="row-r">
                  单笔打款佣金的
                  <InputNumber min={0} placeholder="请输入" onChange={(value) => updateData(value, 2, 'backTaxPoint')} value={privatelyData.backTaxPoint} size="middle" style={{ width: '160px', margin: '0 10px' }}></InputNumber> %
                </div>
              </div>
              <div className="row">
                <span className="row-tit">开票：</span>
                <Radio.Group onChange={(e) => updateData(e.target.value, 2, 'taxTicketFlag')} value={privatelyData.taxTicketFlag}>
                  <Radio value={1}>返税开票（开票金额=佣金+服务费+税费）</Radio>
                  <Radio value={0}>返税不开票（开票金额=佣金+服务费+税费-返税）</Radio>
                </Radio.Group>
              </div>
            </>
          ) : (
            <div className="row">
              <span className="row-tit">开票：</span>
              <span>开票金额=佣金+服务费+税费</span>
            </div>
          )}
          <div className="row">
            <span className="row-tit">常规服务费：</span>
            <Radio.Group onChange={(e) => updateData(e.target.value, 2, 'serviceLadderFlag')} value={privatelyData.serviceLadderFlag}>
              <Radio value={1} disabled={disabled}>阶梯式</Radio>
              <Radio value={0}>非阶梯式</Radio>
            </Radio.Group>
          </div>
          <div className="row">
            <span className="row-tit"></span>
            <Radio.Group onChange={(e) => updateData(e.target.value, 2, 'serviceType')} value={privatelyData.serviceType}>
              <Radio value={2}>单笔佣金百分比</Radio>
              <Radio value={1}>单笔佣金的固定金额</Radio>
            </Radio.Group>
          </div>
          <>
            {/* 阶梯固定金额 */}
            {
              (privatelyData.serviceLadderFlag === 1 && privatelyData.serviceType === 1)
              && renderService(privatelyData.companyConfigDetailList, 2, 1)
            }
            {/* 阶梯百分比 */}
            {
              (privatelyData.serviceLadderFlag === 1 && privatelyData.serviceType === 2)
              && renderService(privatelyData.companyConfigDetailList, 2, 2)
            }
            {/* 非阶梯固定金额 */}
            {
              (privatelyData.serviceLadderFlag === 0 && privatelyData.serviceType === 1)
              && renderService(privatelyData.companyConfigDetailList, 2, 3)
            }
            {/* 非阶梯百分比 */}
            {
              (privatelyData.serviceLadderFlag === 0 && privatelyData.serviceType === 2)
              && renderService(privatelyData.companyConfigDetailList, 2, 4)
            }
          </>
          <div className="row">
            <span className="row-tit">大金额服务费</span>
            <div>
              单笔佣金≥3万，每笔<InputNumber min={0} value={privatelyData.bigServicePoint} onChange={(value) => updateData(value, 2, 'bigServicePoint')} size="small" style={{ width: '80px' }} /> %
            </div>
          </div>
        </>
      }
      <Checkbox className="checkbox" checked={commissionBasis} onChange={commissionBasisChange}>委托代征</Checkbox>
      {commissionBasis &&
        <>
          <div className="row">
            <span className="row-tit">财税企业：</span>
            <Select placeholder="请选择" name="taxEnterpriseCode" onChange={(value) => updateData(value, 3, 'taxEnterpriseCode')} value={commissionBasisData.taxEnterpriseCode} style={{ width: '400px' }}>
              {commissionBasisCityCounty.map(item => (
                <Option value={item.taxEnterpriseCode} key={item.taxEnterpriseCode}>{item.taxEnterpriseName}</Option>
              ))}
            </Select>
          </div>
          <div className="row">
            <span className="row-tit">税费：</span>
            <div className="row-r">
              单笔打款佣金的
              <InputNumber min={0} placeholder="请输入" onChange={(value) => updateData(value, 3, 'taxPoint')} value={commissionBasisData.taxPoint} size="middle" style={{ width: '160px', margin: '0 10px' }}></InputNumber> %
            </div>
          </div>
          <div className="row">
            <span className="row-tit">是否返税：</span>
            <Radio.Group onChange={(e) => updateData(e.target.value, 3, 'backTaxFlag')} value={commissionBasisData.backTaxFlag}>
              <Radio value={1}>返税</Radio>
              <Radio value={0}>不返税</Radio>
            </Radio.Group>
          </div>
          {commissionBasisData.backTaxFlag === 1 ? (
            <>
              <div className="row">
                <span className="row-tit">返税：</span>
                <div className="row-r">
                  单笔打款佣金的
                  <InputNumber min={0} placeholder="请输入" onChange={(value) => updateData(value, 3, 'backTaxPoint')} value={commissionBasisData.backTaxPoint} size="middle" style={{ width: '160px', margin: '0 10px' }}></InputNumber> %
                </div>
              </div>
              <div className="row">
                <span className="row-tit">开票：</span>
                <Radio.Group onChange={(e) => updateData(e.target.value, 3, 'taxTicketFlag')} value={commissionBasisData.taxTicketFlag}>
                  <Radio value={1}>返税开票（开票金额=佣金+服务费+税费）</Radio>
                  <Radio value={0}>返税不开票（开票金额=佣金+服务费+税费-返税）</Radio>
                </Radio.Group>
              </div>
            </>
          ) : (
            <div className="row">
              <span className="row-tit">开票：</span>
              <span>开票金额=佣金+服务费+税费</span>
            </div>
          )}
          <div className="row">
            <span className="row-tit">常规服务费：</span>
            <Radio.Group onChange={(e) => updateData(e.target.value, 3, 'serviceLadderFlag')} value={commissionBasisData.serviceLadderFlag}>
              <Radio value={1} disabled={disabled}>阶梯式</Radio>
              <Radio value={0}>非阶梯式</Radio>
            </Radio.Group>
          </div>
          <div className="row">
            <span className="row-tit"></span>
            <Radio.Group onChange={(e) => updateData(e.target.value, 3, 'serviceType')} value={commissionBasisData.serviceType}>
              <Radio value={2}>单笔佣金百分比</Radio>
              <Radio value={1}>单笔佣金的固定金额</Radio>
            </Radio.Group>
          </div>
          <>
            {/* 阶梯固定金额 */}
            {
              (commissionBasisData.serviceLadderFlag === 1 && commissionBasisData.serviceType === 1)
              && renderService(commissionBasisData.companyConfigDetailList, 3, 1)
            }
            {/* 阶梯百分比 */}
            {
              (commissionBasisData.serviceLadderFlag === 1 && commissionBasisData.serviceType === 2)
              && renderService(commissionBasisData.companyConfigDetailList, 3, 2)
            }
            {/* 非阶梯固定金额 */}
            {
              (commissionBasisData.serviceLadderFlag === 0 && commissionBasisData.serviceType === 1)
              && renderService(commissionBasisData.companyConfigDetailList, 3, 3)
            }
            {/* 非阶梯百分比 */}
            {
              (commissionBasisData.serviceLadderFlag === 0 && commissionBasisData.serviceType === 2)
              && renderService(commissionBasisData.companyConfigDetailList, 3, 4)
            }
          </>
          <div className="row">
            <span className="row-tit">大金额服务费</span>
            <div>
              单笔佣金≥3万，每笔<InputNumber min={0} value={commissionBasisData.bigServicePoint} onChange={(value) => updateData(value, 3, 'bigServicePoint')} size="small" style={{ width: '80px' }} /> %
            </div>
          </div>
        </>
      }
      <div className="title">企业管理员</div>
      <div className="row">
        <span className="row-tit">姓名：</span>
        <Input value={administrator.manageName} onChange={(e) => updateManageName(e.target.value)} placeholder="请输入" style={{ width: '350px' }}></Input>
      </div>
      <div className="row">
        <span className="row-tit">手机号：</span>
        <Input value={administrator.managePhone} onChange={(e) => updateManagePhone(e.target.value)} placeholder="请输入" style={{ width: '350px' }}></Input>
      </div>
      <div className="btns">
        <Space>
          {store.getState().userReducer.permissions.indexOf(`${path}:add`) > -1 && <Button type="primary" onClick={() => submit()} loading={submitLoading}>提交</Button>}
          {store.getState().userReducer.permissions.indexOf(`${path}:add`) > -1 && <Button loading={draftLoading} onClick={() => draft()}>存为草稿</Button>}
          <Button onClick={resetData}>清空</Button>
        </Space>
      </div>
    </div>

  )
}

export default CompanyEnter
