import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import Chart1 from './Chart1.js'
import Chart2 from './Chart2.js'
import {request} from '../../../utils/fetch'


function Home() {
    const [dataObj, setDataObj] = React.useState({});
    
    const getIndexData = () => {
       request.get('/platform/index/indexData').then(res => {
        setDataObj(res)

       })
    }

    useEffect(() => {
        getIndexData()
    },[])
    return (
        <div>
            <h3 className="home-title">平台概况</h3>
            <Row  justify="space-between">
                <Col className="home-top b1">
                    <div>入驻企业数</div>
                    <h3>{dataObj.companyCount}</h3>
                </Col>
                <Col className="home-top b2">
                    <div>签约人数</div>
                    <h3>{dataObj.signCount}</h3></Col>
                <Col className="home-top b3">
                    <div>注册人数</div>
                    <h3>{dataObj.registerCount}</h3>
                </Col>
            </Row>
            <Row justify="space-between" style={{marginTop:'20px'}}>
                <Col className="home-bottom">
                    <div className="home-title">待处理事务</div>
                    <div  className="home-bottom-bg-wrap">
                        <div className="home-bottom-bg bg1">
                            <div>办证待审</div>
                            <div>{dataObj.transactAuditCount}</div>
                        </div>
                        <div className="home-bottom-bg bg2">
                            <div>佣金待审</div>
                            <div>{dataObj.commissionAuditCount}</div>
                        </div>
                    </div>
                    <div className="home-bottom-bg-wrap" style={{marginTop:'1vh'}}>
                        <div className="home-bottom-bg bg3">
                            <div>线下支付待确认</div>
                            <div>{dataObj.paymentWaitSureCount}</div>
                        </div>
                        <div className="home-bottom-bg bg4">
                            <div>申请开票待完成</div>
                            <div>{dataObj.ticketApplyWaitCount}</div>
                        </div>
                    </div>
                </Col>
                <Col className="home-bottom">
                    <div className="home-title">近7天导入佣金<h6 className="unit">(万元)</h6> </div>
                    <Chart1 data={dataObj.commissionDataList}/>
                </Col>
                <Col className="home-bottom">
                    <div className="home-title">单笔佣金分布</div>
                    <Chart2 data={dataObj.commissionDistributeList}/>
                </Col>
            </Row>
    
        </div>
    )
}

export default Home
