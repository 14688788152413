import React,{ useImperativeHandle, useState, forwardRef } from 'react'
import { 
  Modal,
  Input,
  Form,
  Row,
  DatePicker,
  Select,
  Button,
  message,
  Table,
  Col,
} from 'antd';
import {request} from '../../../../utils/fetch'

const FormItem = Form.Item;
const itemStyle = {
  width:'330px',
  marginRight:'15px'
}
const labelStyleObj = {
 labelCol:{ span: 6},
 wrapperCol:{ span: 18}
}
const { Option } = Select;


const MyModel2 = (props,ref) => {
  const { TaxEnterpriseLis } = props
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [companyList,setCompanyList] = useState([])
  const [visible, setVisible] = React.useState(false);
  const [tableData,setTableData] = useState([])
  const [month, setMonth] = React.useState('');
  const [selectRow,setSelectRow] = useState([])
  const [selectedRowKeys,setSelectedRowKeys] = useState([])
  const [planType,setPlanType] = useState()
  const pageSize = 20     //每页条数
  const [total,setTotal] = useState(0)
  const [pageNum,setPageNum] = useState(1)
  const columns = [
    {
      title: '财税企业',
      dataIndex: 'taxEnterpriseName',
      key: 'taxEnterpriseName',
      width: 150,
      align:'center',
  },
    {
        title: '确认支付时间',
        dataIndex: 'payTime',
        key: 'payTime',
        width: 150,
        align:'center',
    }, {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        align:'center',
    },{
        title: '身份证号',
        dataIndex: 'idNumber',
        key: 'idNumber',
        width: 150,
        align:'center',
        
    },{
      title: '手机号',
      dataIndex: 'managePhone',
      key: 'managePhone',
      width: 150,
      align:'center',   
  },
  {
    title: '落地方案',
    dataIndex: 'planTypeName',
    key: 'planTypeName',
    width: 150,
    align:'center',
  },  
  {
        title: '账单月份',
        dataIndex: 'month',
        key: 'month',
        width: 150,
        align:'center',    
    }, 
    {
        title: '佣金账单ID',
        dataIndex: 'billNo',
        key: 'billNo',
        width: 150,
        align:'center',
    }, 
    {
      title: '个人佣金（元）',
      dataIndex: 'commissionAmount',
      key: 'commissionAmount',
      width: 150,
      align:'center',
      
    }, 
  ];

   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
   useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (obj) => {
        setLoading(false)
        setSelectRow([])
        setSelectedRowKeys([])
        setVisible(true)
        form.resetFields()
        setTableData([])
        getCompanyList()
        setPlanType()
        
      }
  }));
  
  const getCompanyList = async() => {
      let result = await request.get('/platform/companyBase/allList')
        setCompanyList(result)
        
  }
  const getTableList = async () => {
    let searchObj = form.getFieldsValue()
    let result = await request.get('/platform/staffTicket/getStaffList',{
      companyCode:searchObj.companyCode,
      payMonths:month || '',
      keyword:searchObj.keyword,
      planType:searchObj.planType,
      taxEnterpriseCode:searchObj.taxEnterpriseCode
    })
       setTotal(result.length)
       setTableData(result)
  }
  const handleSearch = ()=> {
    if(!month){
      message.error('请选择支付月份') 
      return
    }
    if(!form.getFieldsValue().companyCode){
      message.error('请选择企业名称') 
      return
    }
    if(!form.getFieldsValue().taxEnterpriseCode){
      message.error('请选择财税企业') 
      return
    }
      setPageNum(1)
      getTableList()
  }
  const handleFormReset = () => {
      form.resetFields();
     
  }
  const tableChange = (key,row) => {
    setSelectedRowKeys(key)
    setSelectRow(row)
  }
  const handleExport = () => {
    request.downExport('/platform/staffTicket/exportDetail',{
      companyCode:form.getFieldsValue().companyCode,
      payMonths:month || '',
      keyword:form.getFieldsValue().keyword,
      planType:form.getFieldsValue().planType,
      taxEnterpriseCode:form.getFieldsValue().taxEnterpriseCode
    })
    
  }
  const handleOk = () => {
    console.log(planType,9999)
    if(!planType){
      message.error('请选择开票方式！') 
      return
    }
    if(selectRow.length < 1){
      message.error('请勾选列表') 
      return
    }
    setLoading(true)
    let staffTicketNoList = selectRow.map(item => {
      return {
        companyCode:item.companyCode,
        companyName:item.companyName,
        name:item.name,
        idNumber:item.idNumber,
        commissionAmount:item.commissionAmount,
        staffTicketNo:item.staffTicketNo,
        planType:item.planType,
        taxEnterpriseCode:item.taxEnterpriseCode
      }
    })

    request.post('/platform/staffTicket/apply',{
      planType: planType,
      staffTicketList:staffTicketNoList,
    }).then(res => {
      setVisible(false);
      setLoading(false);
      message.success('操作成功')
      props.refresh()
    }).catch(err => setLoading(false))
  }
  const handleOkAll = ()=>{
    if(!month){
      message.error('请选择支付月份') 
      return
    }
    if(!form.getFieldsValue().companyCode){
      message.error('请选择企业名称') 
      return
    }
    if(!form.getFieldsValue().taxEnterpriseCode){
      message.error('请选择财税企业') 
      return
    }
    setLoading(true)
    let searchObj = form.getFieldsValue()
    request.post('/platform/staffTicket/applyAll',{
      companyCode:searchObj.companyCode,
      payMonths:month || '',
      keyword:searchObj.keyword,
      planType:searchObj.planType,
      taxEnterpriseCode:searchObj.taxEnterpriseCode
    }).then(res => {
      setVisible(false);
      setLoading(false);
      message.success('操作成功')
      props.refresh()
    }).catch(err => {
      console.log(err)  
      setLoading(false)
    })
  }
 
  return (
    <>
      <Modal maskClosable={false}
        forceRender
        title='新增'
        width='1050px'
        onOk={handleOk}
        confirmLoading={loading}
        visible={visible}
        onCancel={() => {setVisible(false);}}
        footer={[
          <Button key="back" onClick={() => {setVisible(false);}}>
            取消
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            确定
          </Button>,
          <Button
            key="link"
            type="primary"
            loading={loading}
            onClick={handleOkAll}
          >
            全部开票
          </Button>,
        ]}
      >
        <Form form={form} initialValues={{
            companyCode:''
          }}>
          <Row>
              <FormItem label="企业名称" name="companyCode" style={itemStyle} {...labelStyleObj}>
                  <Select>
                      {
                          companyList.map(item => {
                              return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                          })
                      }
                  </Select>
              </FormItem>
              <FormItem label="支付月份" name="payMonths"  style={{ width:'270px',  marginRight:'15px'}} {...labelStyleObj}>
                  <DatePicker onChange={(date, dateString) => setMonth(dateString)} picker="month" />
              </FormItem>
              <Form.Item label="落地方案" name="planType" style={{ width:'300px',  marginRight:'15px'}} {...labelStyleObj}>
                <Select allowClear placeholder="全部">
                    <Option value="1">临时税务登记</Option>
                    <Option value="2">个体工商户</Option>
                    <Option value="3">委托代征</Option>
                </Select>
              </Form.Item>
              <FormItem label="财税企业" name="taxEnterpriseCode" style={itemStyle} {...labelStyleObj}>
                <Select allowClear>
                    {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                </Select>
              </FormItem>
              <FormItem label="关键字" name="keyword" style={{ width:'270px',  marginRight:'15px'}} {...labelStyleObj}>
                  <Input placeholder="姓名丨身份证号" />
              </FormItem>
          </Row>
          <Row>
            <Col span={24} md={24} lg={24}>
                <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                    <Button type="primary" onClick={handleSearch}>查询</Button>
                    <Button style={{ marginLeft: 10 }} onClick={handleFormReset}>重置</Button>
                    <Button style={{ marginLeft: 10 }} onClick={handleExport}>导出</Button>
                </FormItem>
            </Col>
          </Row>
        </Form>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '40px'}} >
        <div>
          开票方式：
          <Select allowClear style={{width: '200px'}} value={planType} onChange={(value)=>{ setPlanType(value) }} placeholder="全部">
              <Option value="1">临时税务登记</Option>
              <Option value="2">个体工商户</Option>
              <Option value="3">委托代征</Option>
          </Select>
        </div>
        <div>
          合计：{selectRow.length}人   ￥{selectRow.reduce((pre,cur) => {
            return pre + cur.commissionAmount
          },0).toFixed(2)}佣金
        </div>
      </div>
        
        <Table 
            scroll={{ x: 'max-content',y:500 }}
            columns={columns} 
            dataSource={tableData} 
            rowSelection={{
              type: "checkbox",
              onChange:tableChange,
              selectedRowKeys
            }}
            rowKey={(record) => record.staffTicketNo}
            pagination={{
              position: ['bottomCenter'],
              total: total,
              current: pageNum,
              pageSize: pageSize,
              showSizeChanger:false,
              showTotal:(total) =>  `共 ${total} 条数据` ,
              onChange: (pageNum, pageSize) => setPageNum(pageNum)
          }}
        />
       
      </Modal>
    </>
  );
};
export default forwardRef(MyModel2)



