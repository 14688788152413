import React, { useState, useEffect, useRef } from 'react'
import { Row, Table, Select, Form, Button, message, Input, Space, Typography, Col } from 'antd'
import { regionalList, getCityListByProvinceId} from '../api'
import AddModel from './AddModel'
import store from '../../../../store/index'
import {} from '../../../../api/api'
const { Link } = Typography
const FormItem = Form.Item
const { Option } = Select

// 搜索表单通用样式
const itemStyle = {
    width: '350px',
    marginRight: '15px',
}
const labelStyleObj = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
}

function CrowdsourcePersonnel() {
    let path = 'platform:regional'
    const [form] = Form.useForm() //搜索表单
    const [tableData, setTableData] = useState([])
    const [cityData, setCityData] = useState([]) // 城市
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const AddModelRef = useRef()
    const columns = [
        {
            title: '大区ID',
            dataIndex: 'regionalNo',
            width: 150,
            align: 'center',
        },
        {
            title: '大区名称',
            dataIndex: 'regionalName',
            width: 150,
            align: 'center',
        },
        {
            title: '省份',
            dataIndex: 'provinceName',
            width: 150,
            align: 'center',
        },
        {
            title: '城市',
            dataIndex: 'cityName',
            width: 150,
            align: 'center',
        },
        {
            title: '操作',
            width: 120,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size='middle'>
                    <Link onClick={() => methodsFn.view(record)}>查看</Link>
                    {store.getState().userReducer.permissions.includes(`${path}:update`) && <Link onClick={() => methodsFn.edit(record)}>修改</Link>}
                </Space>
            ),
        },
    ]

    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} >
                    <Row>
                        <FormItem label='大区ID/名称' name='regionalKeyWords'  style={itemStyle} {...labelStyleObj}>
                            <Input />
                        </FormItem>
                        <FormItem label='城市名称' name='cityId' style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder='全部'>
                                {cityData.length > 0 &&
                                    cityData.map((item) => (
                                        <Option value={Number(item.cityId)} key={item.cityId}>
                                            {item.cityName}
                                        </Option>
                                    ))}
                            </Select>
                        </FormItem>
                    </Row>
                    <Row>
                        <Col span={24} md={24} lg={24}>
                            <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }}>
                                <Button type='primary' onClick={methodsFn.handleSearch}>
                                    查询
                                </Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>
                                    重置
                                </Button>
                                {store.getState().userReducer.permissions.includes(`${path}:insert`) && (
                                    <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>
                                        新增
                                    </Button>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            )
        },
    }
    //方法函数
    const methodsFn = {
        handleSearch() {
            setPageNum(1)
            if (pageNum == 1) {
                methodsFn.getTableList()
            }
        },
        handleFormReset() {
            form.resetFields()
        },
        getsearchObj() {
            let formObj = form.getFieldsValue()

            let obj = {
                regionalKeyWords: formObj.regionalKeyWords,
                cityId: formObj.cityId,
            }
            return obj
        },
        getTableList() {
            let searchObj = methodsFn.getsearchObj()
            regionalList({
                ...searchObj,
                pageSize: pageSize,
                pageNum: pageNum,
            })
                .then((res) => {
                    console.log(res)
                    if (res.data.success) {
                        console.log(res.data)
                        setTotal(res.data.data.totalCount)
                        setTableData(res.data.data.list)
                    } else {
                        message.error(res.data.retMsg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },

        // 修改
        view(record) {
            AddModelRef.current.showModal(record, 'view')
        },
        // 修改
        edit(record) {
            AddModelRef.current.showModal(record, 'edit')
        },
        // 新增
        handleExport(record) {
            AddModelRef.current.showModal(record, 'new')
        }, // 获取所有财税企业
    }
    useEffect(() => {
        getCityListByProvinceId().then((res) => {
            if (res.data.success) {
                setCityData(res.data.data)
            }
        })
    }, [])
    useEffect(() => {
        methodsFn.getTableList()
    }, [pageNum])

    return (
        <>
            {myComponent.renderSearchForm()}
            <Table
                scroll={{ x: 'max-content', y: 500 }}
                columns={columns}
                rowKey='cityId'
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum),
                }}
            />
            <AddModel ref={AddModelRef} refres={methodsFn.handleSearch} />
        </>
    )
}

export default CrowdsourcePersonnel
