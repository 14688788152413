import React,{ useImperativeHandle, useEffect, forwardRef } from 'react'
import { 
  Modal,
  Input,
  Form,
  message,
  Spin
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getCount } from '../api'
const FormItem = Form.Item;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 20,
    }}
    spin
  />
);
const BalanceModel = (props, ref) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [count, setCount] = React.useState();
  const [spinLoading, setSpinLoading] = React.useState(false);
  const initialValues = {
    age:''
  }

   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (record) => {
      setVisible(true);
      form.setFieldsValue(record)
      getYuE(record)
    }
  }));
  useEffect(() => {
  }, [])
  const getYuE = (record)=>{
    setCount()
    setSpinLoading(true)
    let data = {
      taxEnterpriseCode: record.taxEnterpriseCode,
      logAccNo: record.logAccNo,
    }
    getCount(data).then(res=>{
      setSpinLoading(false)
      if(res.data.success){
        setCount(res.data.data)
      }else{
        message.error(res.data.retMsg)
        setCount('')
      }
    }).catch(()=>{
      setSpinLoading(false)
    })
  }
  return (
    <>
      <Modal maskClosable={false}
        forceRender
        title='余额'
        width='600px'
        visible={visible}
        onCancel={() => {setVisible(false);}}
        footer={null}
      >
        <Form
          labelCol={{ flex: '100px' }}
          wrapperCol={{ span: 15 }}
          initialValues={initialValues}
          form={form}
        >   
          <FormItem name="companyName" label="关联企业" >
            <Input disabled />
          </FormItem>
          <FormItem name="typeName" label="子户类型" >
            <Input disabled />
          </FormItem>
          <FormItem label="可用余额" >
            <Spin spinning={spinLoading} indicator={antIcon} >
              <Input value={count} disabled />
            </Spin>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};
export default forwardRef(BalanceModel)



