import React,{useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Button,
  DatePicker,
  Space,
  PageHeader
 } from 'antd';
import { withRouter } from "react-router-dom";
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'

 const FormItem = Form.Item;
 const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }

function SecondStatementDetail(props) {
    let path = 'platform:StaffAccount'
    const {parmObj} = props
    const initSearch = {
        keyword:'',
    }
    const [tableData,setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [totalMoney,setTotalMoney] = useState(0)
     
    const columns = [
        {
            title: '进出账时间',
            dataIndex: 'dealTime',
            key: 'dealTime',
            width: 150,
            align:'center',
            
        }, {
            title: '收支类型',
            dataIndex: 'dealType',
            key: 'dealType',
            width: 150,
            align:'center',
            render: (text, record) => record.dealType == 'R_10'?' 佣金收入 ':'提现',
            
        },{
            title: '交易金额（元）',
            dataIndex: 'dealAmount',
            key: 'dealAmount',
            width: 150,
            align:'center',
            render: (text, record) => record.dealType == 'R_10'?'+'+record.dealAmount:'-'+record.dealAmount,
            
            
        },{
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            align:'center',
            render: (text, record) => record.status == 'SUCCESS'?' 交易成功 ':record.status == 'SLZ'?'受理中':'交易失败',
            
            
        },{
            title: '打款来源',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 150,
            align:'center',
            
        },{
            title: '交易银行',
            dataIndex: 'bankName',
            key: 'bankName',
            width: 150,
            align:'center',
            
        }, {
            title: '交易卡号',
            dataIndex: 'cardNumber',
            key: 'cardNumber',
            width: 260,
            align:'center',
            
        }, 
    ];

    //方法函数
    const methodsFn =  {
        getsearchObj(){
            return (
                {
                    idNumber:parmObj.idNumber,
                }
            )
        },
        async getTableList(searchObj){
            let result = await request.post('/platform/staffAccount/transactionDetail',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        async getAccountsBalances(){
            let result = await request.get('/platform/staffAccount/getAccountsBalances',{
                idNumber:parmObj.idNumber,
            })
            setTotalMoney(result)

        },
        handleExport(){
            request.downExport('/platform/staffAccount/transactionDetail/export',methodsFn.getsearchObj())
        }
   
    }
    useEffect(() => {
        methodsFn.getAccountsBalances()
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={props.callFn}
                title="查看"
                subTitle=''
            />
            <div style={{
                    'background': '#fff',
                    'padding': '18px'
            }}>
            <span style={{'marginRight':'10px'}}>姓名: {parmObj.name} </span>
            <span style={{'marginRight':'10px'}}>身份证号:{parmObj.idNumber} </span>
            <span>当前余额:¥{totalMoney}</span>
                {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{'marginLeft': '40px'}} onClick={() =>  methodsFn.handleExport()}>导出</Button>}
            </div>

           <Table 
            scroll={{ x: 'max-content',y: 500 }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.dealNo}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />

         
        </div>
    )
}

export default withRouter(SecondStatementDetail)
