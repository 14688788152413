import { request } from '../../../utils/fetch'

// 合同档案-列表
export const getContractArchivesList = params => {
  return request.requestGet('/platform/contractArchives/getList', params)
}

// 合同档案-列表Total
export const getTabTotal = params => {
  return request.requestGet('/platform/contractArchives/getTabTotal', params)
}

// 合同档案-详情
export const getContractArchivesDetail = params => {
  return request.requestGet('/platform/contractArchives/getDetail', params)
}

// 合同档案-修改
export const updateContractArchives = data => {
  return request.requestPost('/platform/contractArchives/update', data)
}

// 合同档案-归档
export const filingContractArchives = data => {
  return request.requestPost('/platform/contractArchives/filing', data)
}

// 合同档案-预览下载
export const getPreviewRecord = data => {
  return request.requestGet('/platform/contractArchives/getPreviewRecord', data)
}

// 合同档案-合同链接
export const getContractUrl = data => {
  return request.requestGet('/platform/contractArchives/getContractUrl', data)
}
