import React,{useEffect,useState} from 'react'
import { Layout, Menu, } from 'antd';
import * as Icon from '@ant-design/icons';

// import {menuList} from '../../menuList.js'
import {Link,withRouter} from "react-router-dom";
import MyHeader from './MyHeader'
// 动态渲染路由如果报错，用wintRouter包裹一下
import './layout.css';
const { SubMenu } = Menu;
const MenuItem = Menu.Item
const { Content, Sider } = Layout;
let menuList = []


function Index(props) {
  const createIcon = function(name) {
   return React.createElement(Icon[name],null)
  }

  let pathname = props.location.pathname
  let selectedKeys
  // 查询'/admin/'之后是否还有'/';没有就算默认，有则截取前两个路径（key只有前两个路径）
  if(pathname.indexOf('/', 7)===-1){
    selectedKeys = pathname
  }else{
    selectedKeys = pathname.substring(0,pathname.indexOf('/', 7))
  }
  try {
    menuList = JSON.parse(localStorage.getItem('menu')) || []
  } catch (error) {
    
  }
  const [openKeys,setOpenKeys] = useState([''])
  useEffect(() => {  
    menuList.forEach(subMenu => {
      if(subMenu.children){
        subMenu.children.forEach(item => {
          // 2021-6-16更改判断方式 以前的判断方式 pathname === item.url
          if( item.url&&pathname.indexOf(item.url) !== -1 ){   //刷新页面展开对应菜单  
            setOpenKeys([String(subMenu.id)])
          }
        })
      }else{

      }
    })
  },[pathname])    //监听路由变化

  //菜单渲染
  const getMenuItem = list => {
      return list.map((item, index) => {
          if (item.children && item.children.length > 0) {
              return (
                  <SubMenu
                      key={item.id}
                      icon={item.icon ? createIcon(item.icon):''} 
                      title={item.menuName}
                  >
                      {getMenuItem(item.children)}
                  </SubMenu>
              )
          } else {
              return (
                  <MenuItem key={item.url}>
                      <Link to={item.url}>
                        <span>{item.menuName}</span>
                      </Link>
                  </MenuItem>
              )
          }
      })
  }
    return (
        <Layout className="myLayout">
          {/* style={{backgroundColor:'#b5f5ec'}} */}
          <Sider width={200} className="my-layout-Sider">
          <div className="logo" />
            <Menu
              mode="inline"
              selectedKeys={[selectedKeys]}
              openKeys={openKeys}
              onOpenChange={(keys) => setOpenKeys(keys)}
              style={{borderRight: 0 }}
            >
            <MenuItem key="/admin/home" icon={createIcon('HomeOutlined')}>
              <Link to='/admin/home'/>
                首页
            </MenuItem>
              {getMenuItem(menuList)}
              <MenuItem key="/admin/downPage" icon={createIcon('CloudDownloadOutlined')}>
              <Link to='/admin/downPage'/>
              下载列表
            </MenuItem>
            </Menu>
          </Sider>
          
          <Layout className="site-layout">
            <MyHeader />
            <Content
              className="site-layout-background"
              style={{
                margin: '24px 16px 0',
                padding: 24,
                minHeight: 280,
              }}
            >
              {props.children}
            
            </Content>
          </Layout>
        </Layout>
    )
}

export default withRouter(Index)

