import React, { useState, useEffect } from 'react'
import { Row, Col, Table, Select, Form, Input, Radio, Button, DatePicker, message, Space, Typography, Modal, Upload, Popconfirm } from 'antd'
import moment from 'moment'
import { request } from '../../../../utils/fetch'
import axios from 'axios'
import { UploadOutlined } from '@ant-design/icons'
import store from '../../../../store/index'
import { getMerchantInfoList, getMerchantInfoDetail, merchantInfoEnable } from '../api'

const FormItem = Form.Item
const { RangePicker } = DatePicker
const { Option } = Select
const { confirm } = Modal
const { Link } = Typography
const itemStyle = {
    width: '350px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 }
}
const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 18 }
}

function MerchantInfo(props) {
    let path = 'platform:ContractSign'
    const [form] = Form.useForm()
    const [insertForm] = Form.useForm()
    const [detailModalForm] = Form.useForm()
    const initSearch = {
        date: []
    }

    const [dayValue, setDayValue] = useState(0)
    const [fileList, setFileList] = useState([]) //文件列表
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const [insertVisible, setInsertVisible] = useState(false)
    const [detailVisible, setDetailVisible] = useState(false)
    const [tableDetailData, setTableDetailData] = useState([])
    const [insertTitle, setInsertTitle] = useState('')
    const [tempRecord, setTempRecord] = useState({})
    const [loading, setLoading] = useState(false)
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [tableData, setTableData] = useState([])
    const [detailTotal, setDetailTotal] = useState(0)
    const [detailPageNum, setDetailPageNum] = useState(1)
    const fileProps = {
        //表格上传配置和方法
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept: '.xls, .xlsx',
        beforeUpload: () => {
            return false
        },

        onChange(info) {
            let fileCon = [...info.fileList]
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1)
            setFileList(fileCon)
        }
    }

    const columns = [
        {
            title: '添加时间',
            dataIndex: 'createTime',
            width: 150,
            align: 'center'
        },
        {
            title: '商户名称',
            dataIndex: 'businessName',
            width: 150,
            align: 'center'
        },
        {
            title: '商户ID',
            dataIndex: 'businessId',
            width: 150,
            align: 'center'
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            align: 'center',
            render: status => (status === 1 ? '停用' : '启用')
        },
        {
            title: '操作',
            key: 'action',
            width: 160,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size='middle'>
                    <Link onClick={() => methodsFn.handleModify(record)}>修改</Link>
                    <Link onClick={() => methodsFn.handleDetail(record)}>明细</Link>
                    {
                        <Popconfirm
                            title={record.status === 0 ? '是否确定要停用?' : '是否确定要启用?'}
                            onConfirm={() => {
                                methodsFn.handleToogleStatus(record)
                            }}
                            okText='确定'
                            cancelText='取消'
                        >
                            <Link>{record.status === 0 ? '停用' : '启用'}</Link>
                        </Popconfirm>
                    }
                </Space>
            )
        }
    ]
    //组件渲染函数
    const myComponent = {
        renderSearchForm() {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        <FormItem label='时间' name='date' style={{ width: '486px' }} labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
                            <RangePicker onChange={methodsFn.timeChange} />
                        </FormItem>
                        <FormItem style={{ with: '600px', marginLeft: '26px' }} label=''>
                            <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                                <Radio value={1}>今天</Radio>
                                <Radio value={7}>近7天</Radio>
                                <Radio value={30}>近30天</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Row>
                    <Row>
                        <FormItem style={itemStyle} {...labelStyleObj} name='status' label='状态'>
                            <Select allowClear placeholder='全部'>
                                <Option key='0' value='0'>
                                    启用
                                </Option>
                                <Option key='1' value='1'>
                                    停用
                                </Option>
                            </Select>
                        </FormItem>
                        <FormItem label='关键字' name='keyword' style={itemStyle} {...labelStyleObj}>
                            <Input placeholder='商户名称丨站点名称' />
                        </FormItem>
                    </Row>
                    <Row justify='end'>
                        <Space size={10}>
                            <Button type='primary' onClick={methodsFn.handleSearch}>
                                查询
                            </Button>
                            <Button onClick={methodsFn.handleFormReset}>重置</Button>
                            {
                                // permissions.includes(`${path}:export`) &&
                                <Button onClick={methodsFn.handleExport}>导出</Button>
                            }
                            {
                                // permissions.includes(`${path}:insert`) &&
                                <Button onClick={methodsFn.handleInsert}>新增</Button>
                            }
                        </Space>
                    </Row>
                </Form>
            )
        },
        insertModal() {
            return (
                <Modal maskClosable={false} title={insertTitle} visible={insertVisible} onCancel={methodsFn.onInsertCancel} confirmLoading={loading} onOk={methodsFn.onInsertSubmit}>
                    <Form form={insertForm} {...formItemLayout}>
                        <Row>
                            <Col span={24}>
                                <FormItem name='businessName' rules={[{ required: true }]} label='商户名称'>
                                    <Input placeholder='请输入' disabled={insertTitle === '修改'} />
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem name='businessId' label='商户ID'>
                                    <Input placeholder='请输入' disabled={insertTitle === '修改'} />
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label='上传表格' name='ffile' rules={[{ required: true, message: '请上传表格' }]}>
                                    <Upload {...fileProps}>
                                        <Button icon={<UploadOutlined />}>点击上传</Button>
                                        <Button
                                            type='link'
                                            onClick={e => {
                                                e.stopPropagation()
                                                window.location.href = `/wispay/common/getTemplateDownloadUrl?token=${localStorage.getItem('token')}&mobile=${localStorage.getItem(
                                                    'mobile'
                                                )}&type=DDC_SHXX`
                                            }}
                                        >
                                            下载模板
                                        </Button>
                                    </Upload>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )
        },
        detailModal() {
            return (
                <Modal maskClosable={false} title='明细' visible={detailVisible} onCancel={methodsFn.onDetailCancel} footer={null} width={800}>
                    <Form form={detailModalForm} {...formItemLayout}>
                        <Row>
                            <Col span={12}>
                                <FormItem name='businessName' label='商户名称'>
                                    <Input disabled />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name='businessId' label='商户ID'>
                                    <Input disabled />
                                </FormItem>
                            </Col>
                        </Row>
                        <Table
                            scroll={{ x: 'max-content', y: 400 }}
                            columns={[
                                {
                                    title: '城市',
                                    dataIndex: 'cityName',
                                    align: 'center'
                                },
                                {
                                    title: '站点名称',
                                    dataIndex: 'pointName',
                                    align: 'center'
                                },
                                {
                                    title: '站点ID',
                                    dataIndex: 'pointId',
                                    align: 'center'
                                }
                            ]}
                            rowKey={record => record.id}
                            dataSource={tableDetailData}
                            pagination={{
                                position: ['bottomCenter'],
                                total: detailTotal,
                                current: detailPageNum,
                                pageSize: pageSize,
                                showSizeChanger: false,
                                showTotal: total => `共 ${total} 条数据`,
                                onChange: pageNum => {
                                    getMerchantInfoDetail({
                                        businessCode: tempRecord.businessCode,
                                        pageSize: pageSize,
                                        pageNum: pageNum
                                    }).then(({ data: { data, retMsg, success } }) => {
                                        if (success) {
                                            setTableDetailData(data.list)
                                            setDetailPageNum(pageNum)
                                        } else {
                                            message.error(retMsg)
                                        }
                                    })
                                }
                            }}
                        />
                    </Form>
                </Modal>
            )
        }
    }
    //方法函数
    const methodsFn = {
        //日期变化
        timeChange() {
            setDayValue(0)
        },
        //天数变化
        dayChange(e) {
            let startDate = moment()
                .subtract(e.target.value - 1, 'd')
                .format('YYYY-MM-DD')
            let endDate = moment().format('YYYY-MM-DD')
            console.log(e.target.value)
            setDayValue(e.target.value)
            form.setFieldsValue({
                date: [moment(startDate), moment(endDate)]
            })
        },
        getsearchObj() {
            let formObj = form.getFieldsValue()
            let beginTime = undefined,
                endTime = undefined

            if (formObj.date.length > 1) {
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }

            return {
                beginTime: beginTime,
                endTime: endTime,
                status: formObj.status,
                keyword: formObj.keyword
            }
        },
        handleSearch() {
            pageNum == 1 && methodsFn.getTableList()
            setPageNum(1)
        },
        handleFormReset() {
            setDayValue(0)
            form.resetFields()
        },
        async getTableList() {
            getMerchantInfoList({
                ...methodsFn.getsearchObj(),
                pageSize: pageSize,
                pageNum: pageNum
            }).then(({ data: { data, retMsg, success } }) => {
                if (success) {
                    setTotal(data.totalCount)
                    setTableData(data.list)
                } else {
                    message.error(retMsg)
                }
            })
        },
        // 导出
        handleExport() {
            request.downExport('/platform/ddc_businessInfo/export', methodsFn.getsearchObj())
        },
        // 新增
        handleInsert() {
            setInsertTitle('新增')
            setInsertVisible(true)
        },
        //关闭新增弹窗
        onInsertCancel() {
            setInsertVisible(false)
            insertForm.resetFields()
            setFileList([])
        },
        // 新增提交
        async onInsertSubmit() {
            let values = await insertForm.validateFields()
            console.log(values)
            let data = new FormData() //通过FormData将文件转成二进制数据
            let file = values.ffile.file
            data.append('token', localStorage.getItem('token'))
            data.append('mobile', localStorage.getItem('mobile'))
            data.append('businessName', values.businessName)
            values.businessId && data.append('businessId', values.businessId)
            data.append('file', file)

            setLoading(true)
            // 新增

            if (insertTitle === '新增') {
                axios({
                    method: 'post',
                    url: `/wispay/platform/ddc_businessInfo/insert`,
                    data: data,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(({ data: { retMsg, success } }) => {
                        setLoading(false)
                        if (success) {
                            methodsFn.onInsertCancel()
                            message.success(retMsg)
                            methodsFn.handleSearch()
                        } else {
                            if (retMsg.includes('#')) {
                                retMsg = retMsg.split('#')
                                retMsg = retMsg.map(item => <p>{item}</p>)
                            }
                            message.error(retMsg)
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                        console.log(err)
                    })
            }

            if (insertTitle === '修改') {
                data.append('businessCode', tempRecord.businessCode)
                const sumitAxios = () => {
                    axios({
                        method: 'post',
                        url: `/wispay/platform/ddc_businessInfo/update`,
                        data: data,
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(({ data: { retMsg, success } }) => {
                            setLoading(false)
                            if (success) {
                                message.success(retMsg)
                                methodsFn.onInsertCancel()
                            } else {
                                if (retMsg.includes('#')) {
                                    retMsg = retMsg.split('#')
                                    retMsg = retMsg.map(item => <p>{item}</p>)
                                }
                                message.error(retMsg)
                            }
                        })
                        .catch(err => {
                            setLoading(false)
                            console.log(err)
                        })
                }

                axios({
                    method: 'post',
                    url: `/wispay/platform/ddc_businessInfo/importCheck`,
                    data: data,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(({ data: { data, retMsg, success } }) => {
                        if (success) {
                            if (data === '') {
                                sumitAxios()
                            } else {
                                let errText = data.split('#')
                                errText = errText.map(item => <p>{item}</p>)
                                errText[errText.length] = <p style={{ fontSize: '16px', fontWeight: '700' }}>导入后会直接覆盖原数据。确定导入么？</p>
                                confirm({
                                    title: '提示',
                                    content: errText,
                                    onOk() {
                                        sumitAxios()
                                    },
                                    onCancel() {
                                        setLoading(false)
                                    }
                                })
                            }
                        } else {
                            setLoading(false)
                            message.error(retMsg)
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                        console.log(err)
                    })
            }
        },
        handleModify(record) {
            setInsertTitle('修改')
            setInsertVisible(true)
            setTempRecord(record)
            insertForm.setFieldsValue(record)
        },
        //启停用
        handleToogleStatus(record) {
            merchantInfoEnable({
                businessCode: record.businessCode,
                flag: record.status === 1 //true-启用 false-停用
            }).then(({ data: { retMsg, success } }) => {
                if (success) {
                    message.success(retMsg)
                } else {
                    message.error(retMsg)
                }
                methodsFn.getTableList()
            })
        },
        handleDetail(record) {
            setTempRecord(record)
            getMerchantInfoDetail({
                businessCode: record.businessCode,
                pageSize: pageSize,
                pageNum: 1
            }).then(({ data: { data, retMsg, success } }) => {
                if (success) {
                    console.log(data.list)
                    setTableDetailData(data.list)
                    setDetailTotal(data.totalCount)
                    detailModalForm.setFieldsValue({
                        businessName: record.businessName,
                        businessId: record.businessId
                    })
                    setDetailVisible(true)
                } else {
                    message.error(retMsg)
                }
            })
        },
        onDetailCancel() {
            setDetailVisible(false)
        }
    }

    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    }, [pageNum])

    return (
        <div>
            {myComponent.renderSearchForm()}
            <Table
                scroll={{ x: 'max-content', y: 518 }}
                columns={columns}
                rowKey={record => record.id}
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: total => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
            />
            {myComponent.insertModal()}
            {myComponent.detailModal()}
        </div>
    )
}

export default MerchantInfo
