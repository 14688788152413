import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Form, message, Input, Select, Table, Space } from 'antd'
import { companyBind, getRelationInfo } from '../../api.js'
import { getRelevanceCompany } from '../../../../../api/api.js'


const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 },
}
const { Option } = Select
const BindingModal = (props,ref) => {
    const { refresh } = props
    const [form] = Form.useForm()
    const [visible, setvisible] = useState(false)
    const [companyData, setCompanyData] = useState({})
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [selectData, setSelectData] = useState([])
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        showModal: (record) => {
            setCompanyData(record)
            form.setFieldsValue(record)
            setvisible(true)
            getInfo(record)
            getlist()
          }
    }));

    const getInfo = async (record) => {
        form.setFieldsValue({
            companyCodeList: []
        })
        let result = await getRelationInfo({accountCompanyCode:record.companyCode,taxEnterpriseCode:record.taxEnterpriseCode})
        if (result.data.success) {
           let list = result.data.data.map((e)=>{return e.companyCode})
           if(list.length>0){
                form.setFieldsValue({
                    companyCodeList: list
                })
           }
        } else {
            message.error(result.data.retMsg)
        }
    }
    const getlist = async () => {
        let result = await getRelevanceCompany().catch((err)=>{
            throw err
        })
        setSelectData(result)
       
    }
    const onCancel = () => {
        setvisible(false)
    }
    const handleOk = () => {
        setConfirmLoading(true)
        let companyBaseList = form.getFieldsValue().companyCodeList||[]
        let list = companyBaseList.map(e=>{
            return { companyCode: e }
        })
        let data = {
            companyCode: companyData.companyCode,
            companyBaseList: list
        }
        companyBind(data).then(result=>{
            if (result.data.success) {
                setConfirmLoading(false)
                onCancel()
                message.success('绑定成功!')
                refresh()
            } else {
                setConfirmLoading(false)
                message.error(result.data.retMsg)
            }
        }).catch(err=>{
            setConfirmLoading(false)
        })
    }
    return (
        <Modal maskClosable={false}
            title="企业绑定" 
            visible={visible} 
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            onOk={handleOk}
            width={540}>
            <Form {...formItemLayout} initialValues={companyData} form={form}>
                <Form.Item name="taxEnterpriseName" label="财税企业">
                    <Input disabled />
                </Form.Item>
                <Form.Item name="baseAccountName" label="子户企业">
                    <Input disabled  />
                </Form.Item>
                <Form.Item name="companyCodeList" label="企业名称">
                    <Select mode="multiple" allowClear placeholder="请选择">
                        {selectData.map((e)=>{ return <Option key={e.companyCode} value={e.companyCode}>{e.companyName}</Option>})}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default forwardRef(BindingModal)