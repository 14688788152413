import React, { useState, useEffect } from 'react'
import { useSetState } from 'react-use'
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Table,
  DatePicker,
  Space,
  Spin,
  message
} from 'antd'
import CheckModal from './components/CheckModal'
import ConfirmRefund from './components/ConfirmRefund'
import { getLadderRefundList } from '../api'
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index'
import { getTaxEnterpriseList }　from '../../../../api/api'
import moment from 'moment'

const { Option } = Select
const labelStyleObj = {
  wrapperCol:{ span: 18}
}
const itemStyle = {width: '340px', marginRight: '15px'}

const LadderRefund = (props) => {
  let path = 'platform:ReturnCost'
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [visible, setVisible] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [month, setMonth] = useState(null)
  const [backNo, setBackNo] = useState()
  const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
  
  // form 表单域默认值
  const initialFormValues = {
    queryStatus: '',
    keyword: '', // 关键字
  }
  const [pagination, setPagination] = useSetState({
    pageNum: 1,
    pageSize: 20,
    total: 0
  })
  // 列表字段
  const tableColumns = [
    {
      title: '企业名称',
      dataIndex: 'companyCodeName',
      key: 'companyCodeName',
      align: 'center'
    },
    {
      title: '财税企业',
      dataIndex: 'taxEnterpriseName',
      key: 'taxEnterpriseName',
      align: 'center'
    },{
      title: '企业ID',
      dataIndex: 'companyCode',
      key: 'companyCode',
      align: 'center'
    },{
      title: '自然月',
      dataIndex: 'month',
      key: 'month',
      align: 'center'
    },{
      title: '退还常规服务费（元）',
      dataIndex: 'backAmount',
      key: 'backAmount',
      align: 'center'
    },{
      title: '状态',
      dataIndex: 'statusName',
      key: 'statusName',
      align: 'center'
    },{
      title: '退还操作时间',
      dataIndex: 'backCostTime',
      key: 'backCostTime',
      align: 'center'
    },{
      title: '操作人',
      dataIndex: 'backCostUser',
      key: 'backCostUser',
      align: 'center'
    },{
      title: '操作',
      key: 'control',
      align: 'center',
      render: (record) => (
        <Space>
          {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a key="0" onClick={() => checkModal(record)}>查看</a>}
          {
            store.getState().userReducer.permissions.indexOf(`${path}:confirm`)>-1&&(record.status == 0 && record.backAmount > 0) && <a key="1" onClick={() => confirmRefund(record)}>确认退费</a>
          }
        </Space>
      )
    }
  ]

  // 获取列表数据
  const getTableLists = async (page) => {
    const params = {
      month: month ? month.format('YYYY-MM') : month,
      queryStatus: form.getFieldsValue().queryStatus,
      keyword: form.getFieldsValue().keyword,
      taxEnterpriseCode: form.getFieldsValue().taxEnterpriseCode,
      pageSize: page.pageSize,
      pageNum: page.pageNum
    }
    try {
      setLoading(true)
      const result = await getLadderRefundList(params)
      setLoading(false)
      if (result.data.success) {
        setTableData(result.data.data.list)
        setPagination(prev => {
          return {
            ...prev,
            total: result.data.data.totalCount
          }
        })
      } else {
        message.error(result.data.retMsg)
      }
    } catch(err) {
      setLoading(false)
      console.log('___个人列表', err)
    }
  }
  useEffect(() => {
    getTableLists(pagination)
    getTaxEnterpriseListall()
  }, [])

  const monthChange = (value) => {
    setMonth(value)
  }
  // 重置
  const resetForm = () => {
    form.resetFields()
    setMonth(null)
  }
   // 获取所有财税企业
  const getTaxEnterpriseListall = async () => {
      const res = await getTaxEnterpriseList()
      if (res.data.success) {
        setTaxEnterpriseLis(res.data.data)
      }
  }
  //分页改变
  const tablePageChange = (page, pageSize) => {
    setPagination(prev => {
      return {
        ...prev,
        pageNum: page
      }
    })
    getTableLists({pageNum: page, pageSize: pageSize})
  }
  // 详细
  const checkModal = (record) => {
    setBackNo(record.backCostNo)
    setVisible(true)
  }
  // 确认退费
  const confirmRefund = (record) => {
    setBackNo(record.backCostNo)
    setConfirmVisible(true)
  }
  const submit = () => {
    getTableLists({pageNum: 1, pageSize: 20})
  }
  const close = () => {
    setVisible(false)
  }
  const refresh = () => {
    getTableLists(pagination)
  }
  const confirmClose = () => {
    setConfirmVisible(false)
  }
  // 导出
  const exportData = () => {
    let data = {
      month: month ? month.format('YYYY-MM') : month,
      queryStatus: form.getFieldsValue().queryStatus,
      keyword: form.getFieldsValue().keyword,
      taxEnterpriseCode: form.getFieldsValue().taxEnterpriseCode
    }
    request.downExport('/platform/returnCost/export', data)
  }
  return (
    <Spin spinning={loading} className="contract-lists">
    
      <Form labelCol={{ span: 6 }} initialValues={initialFormValues} form={form}>
        <Row>
          <Form.Item style={itemStyle} {...labelStyleObj} name='taxEnterpriseCode' label="财税企业">
            <Select allowClear placeholder="全部">
              {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item style={itemStyle} {...labelStyleObj} label="自然月">
            <DatePicker value={month} picker="month" defaultValue={null} format="YYYY-MM" onChange={(value) => monthChange(value)} />
          </Form.Item>
          <Form.Item style={itemStyle} {...labelStyleObj} name="queryStatus" label="状态">
            <Select allowClear placeholder="全部">
              <Option value="1">--</Option>
              <Option value="2">未处理</Option>
              <Option value="3">已处理</Option>
            </Select>
          </Form.Item>
          <Form.Item {...labelStyleObj} style={itemStyle} name="keyword" label="关键字">
              <Input placeholder="企业名称丨企业ID" />
          </Form.Item>
        </Row>
        <Row>
          <Col span={24} style={{textAlign: 'right'}}>
            <Space>
              <Button type="primary" onClick={submit}>查询</Button>
              <Button onClick={resetForm}>重置</Button>
              {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button onClick={exportData}>导出</Button>}
            </Space>
          </Col>                              
        </Row>
      </Form>

      <Table 
        columns={tableColumns} 
        dataSource={tableData}
        rowKey="backCostNo"
        scroll={{ x: 1600, y: 540 }}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: false,
          showTotal:(total) =>  `共 ${total} 条数据`,
          total: pagination.total,
          current: pagination.pageNum,
          pageSize: pagination.pageSize,
          onChange: (page, pageSize)　=> tablePageChange(page, pageSize)
        }}
      >
      </Table>
      <CheckModal
        visible={visible}
        close={close}
        backNo={backNo}
      />
      <ConfirmRefund
        visible={confirmVisible}
        close={confirmClose}
        backNo={backNo}
        refresh={refresh}
      />
    </Spin>

  )
}

export default LadderRefund