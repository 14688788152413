// 线上支付
import React, { useState, useRef } from 'react'
import { Spin, Table, Space, Popconfirm, message } from 'antd'
import QueryForm from './components/QueryForm'
import ConfirmModel from './components/ConfirmModel'
import store from '../../../../store/index'
import { paymentGetList, abandon } from './api'
const CnlinePayment = (props)=> {
    let path = 'platform:payment'
    const [tableData, setTableData] = useState([])
    const [query, setQuery] = useState([])
    const ConfirmModelRef = useRef();
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const refresh = (val,params) => {
        switch (val) {
            case 'query':
                setQuery(params)
                getTableLists(params)
                break;
            case 'refresh':
                if(pageNum==1){
                    getTableLists(query)
                }else{
                    setPageNum(1)
                }
                break;
            default:
        }
    }
    
    const getTableLists = (params) => {
        let data ={
            ...params,
            pageSize: pageSize,
            pageNum: pageNum
        }
        paymentGetList(data).then(res=>{
            if(res.data.success){
                setTableData(res.data.data.list)
                setTotal(res.data.data.totalCount)
            }else{
                message.error(res.data.retMsg)
            }
        })
    }
    const getTableData = (num)=>{
        setPageNum(num)
    }
    const handelView = (record)=>{
        props.history.push(`/admin/CnlinePayment/Detail?settlementNo=${record.settlementNo}`)
    }
    const handelPay = (record)=>{
        ConfirmModelRef.current.showModal(record);
    }
    const cancelPay = (record)=>{
        let data = {
            settlementNo: record.settlementNo
        }
        abandon(data).then(res=>{
            if(res.data.success){
                message.success(res.data.retMsg)
                refresh('refresh')
            }else{
                message.error(res.data.retMsg)
            }
        })
    }
    // 列表字段
    const tableColumns = [
        { title: '企业确认放款时间', dataIndex: 'companyConfirmTime', key: 'companyConfirmTime', align: 'center', width: 160 },
        { title: '企业名称', dataIndex: 'companyCodeName', key: 'companyCodeName', align: 'center', width: 220 },
        { title: '佣金账单', dataIndex: 'billNo', key: 'billNo', align: 'center', width: 150 },
        { title: '账单月份', dataIndex: 'month', key: 'month', align: 'center', width: 100 },
        { title: '放款人数', dataIndex: 'staffCount', key: 'staffCount', align: 'center', width: 90 },
        { title: '合计放款(元)', dataIndex: 'totalAmount', key: 'totalAmount', align: 'center', width: 120 },
        { title: '个人佣金(元)', dataIndex: 'totalCommissionAmount', key: 'totalCommissionAmount', align: 'center', width: 120 },
        { title: '服务费(元)', dataIndex: 'totalServiceAmount', key: 'totalServiceAmount', align: 'center', width: 100 },
        { title: '税费(元)', dataIndex: 'totalTaxAmount', key: 'totalTaxAmount', align: 'center', width: 90 },
        { title: '状态', dataIndex: 'paymentStatusName', key: 'paymentStatusName', align: 'center', width: 110 },
        { title: '平台操作时间', dataIndex: 'statusUpdateTime', key: 'statusUpdateTime', align: 'center', width: 160 },
        {
            title: '操作',
            key: 'control',
            fixed: 'right',
            width: 260,
            align: 'center',
            render: (record) => (
                <Space>
                    {<a key="1" onClick={() => handelView(record)}>明细</a>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:payment`) > -1&&record.paymentStatus=='WZF'&&<a key="2" onClick={() => handelPay(record)}>确认支付</a>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:abandon`) > -1&&record.paymentStatus=='WZF'&&<Popconfirm
                        title="确认取消支付吗?"
                        onConfirm={()=>{cancelPay(record)}}
                        okText="确定"
                        cancelText="取消"
                    >
                        <a href="#">取消支付</a>
                    </Popconfirm>}
            </Space>
            )
          }
    ]
    return (
        <Spin spinning={false}>
            <QueryForm refresh={refresh} pageNum={pageNum} setPageNum={setPageNum} />
            <Table 
            columns={tableColumns} 
            dataSource={tableData} 
            scroll={{ x: 'max-content', y: 520 }} 
            rowKey={(record) => record.settlementNo}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger: false,
                showTotal: (total) => `共 ${total} 条数据`,
                onChange: (num, pageSize) => getTableData(num),
            }} />
            <ConfirmModel refresh={refresh} ref={ConfirmModelRef} />
        </Spin>
    )
}
export default CnlinePayment
