import React, { useState, useEffect, useRef } from 'react'
import { Tabs } from 'antd'

import Search from './components/search'
import AddReport from './components/addReport'
import ViewReport from './components/viewReport'
import EditReport from './components/editReport'
import CaseReport from './components/caseReport'
import FollowUp from './components/followUp'
import Take from './components/take'
import ReportTable from './components/reportTable'
import CloseTable from './components/closeTable'

function TrafficSafety(props) {
    let path = 'platform:trafficSafety'
    const SearchRef = useRef();
    const AddReportRef = useRef();
    const ViewReportRef = useRef();
    const EditReportRef = useRef();
    const ReportTableRef = useRef();
    const CaseReportRef = useRef();
    const CloseTableRef = useRef();
    const FollowUpRef = useRef();
    const TakeRef = useRef();
    const [tabType,setTabType] = useState(1)
    useEffect(() => {
    }, [])
    const onTabsChange = (value) => {
        setTabType(value)
        refresh('search')
    }

    const refresh = (val,data,splist)=>{
        switch (val) {
            case "search":
                ReportTableRef.current.getlist(data);
                break;
            case "query":
                CloseTableRef.current.getlist(data);
                break;
            case "add":
                AddReportRef.current.showModal('新增');
                break;
            case "view":
                ViewReportRef.current.showModal(data);
                break;
            case "edit":
                EditReportRef.current.showModal(data);
                break;
            case "case":
                CaseReportRef.current.showModal(data);
                break;
            case "followUp":
                FollowUpRef.current.showModal(data);
                break;
            case "take":
                TakeRef.current.showModal(data,tabType);
                break;
            default: 
          }
    }
    return (
        <div className='trafficSafety'>
            <Search ref={SearchRef} refresh={refresh} type={tabType}></Search>
            <div className='tablenotop' style={{ backgroundColor: '#fff' }} >
                <Tabs defaultActiveKey="1" tabBarStyle={{ paddingLeft: '20px', marginBottom: '2px' }} onChange={onTabsChange}>
                    <Tabs.TabPane tab={`报备`} key="1">
                        <ReportTable refresh={refresh} ref={ReportTableRef} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={`结案`} key="2">
                        <CloseTable refresh={refresh} ref={CloseTableRef} />
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <AddReport ref={AddReportRef} refresh={refresh}></AddReport>
            <ViewReport ref={ViewReportRef} refresh={refresh}></ViewReport>
            <EditReport ref={EditReportRef} refresh={refresh}></EditReport>
            <CaseReport ref={CaseReportRef} refresh={refresh}></CaseReport>
            <FollowUp ref={FollowUpRef} refresh={refresh}></FollowUp>
            <Take ref={TakeRef} refresh={refresh}></Take>
        </div>
    )
}

export default TrafficSafety
