import React, { useState, useEffect, useRef } from 'react'
import Search from './components/search'
import BaseTable from './components/baseTable'
import EditReport from './components/editReport'
import ViewReport from './components/viewReport'
function TrafficArchives(props){
    const SearchRef = useRef();
    const BaseTableRef = useRef();
    const EditReportRef = useRef();
    const ViewReportRef = useRef();
    const refresh = (val,data,splist)=>{
        switch (val) {
            case "search":
                BaseTableRef.current.getlist(data);
                break;
            case "view":
                ViewReportRef.current.showModal("view",data);
                break;
            case "edit":
                EditReportRef.current.showModal("edit",data);
                break;
            default: 
            break;
          }
    }
    return (
        <div>
            <Search ref={SearchRef} refresh={refresh} type={1}></Search>
            <BaseTable ref={BaseTableRef} refresh={refresh}></BaseTable>
            <ViewReport ref={ViewReportRef} refresh={refresh}></ViewReport>
            <EditReport ref={EditReportRef} refresh={refresh}></EditReport>
        </div>
    )
}
export default TrafficArchives;