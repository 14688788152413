import React, { useRef } from 'react'
import { Table, Space, Button, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons'
import FiledModal from './FiledModal'
import ViewModal from './ViewModal'
import EditModal from './EditModal'
// 待归档
const ToBeFiled = (props) => {
    const { pageNum, setPageNum, total, tableData=[], refresh } = props
    const FiledModalRef = useRef()
    const ViewModalRef = useRef()
    const EditModalRef = useRef()
    const toView = (record) => {
        ViewModalRef.current.viewData(record)
    };
    const toUpdata = (record) => {
        EditModalRef.current.editData(record)
    };
    const toFiled = (record) => {
        FiledModalRef.current.filedData(record)
    };
    // 列表字段
    const tableColumns = [
        { title: '审批通过时间', dataIndex: 'dingEndTime', width: 170, align: 'center' },
        { title: '来源', dataIndex: 'sourceName', width: 160, align: 'center' },
        { title: '流程编号', dataIndex: 'processNo', width: 170, align: 'center' },
        { title: '合同名称', dataIndex: 'contractName', width: 160, align: 'center' },
        { title: '甲方', dataIndex: 'partyA', width: 160, align: 'center' },
        { title: '乙方', dataIndex: 'partyB', width: 160, align: 'center' },
        { title: '申请日期', dataIndex: 'applyDate', width: 120, align: 'center' },
        { title: '起始日期', dataIndex: 'contractStartDate', width: 120, align: 'center' },
        { title: '终止日期', dataIndex: 'contractEndDate', width: 120, align: 'center' },
        { title: '申请人', dataIndex: 'applyName', width: 120, align: 'center' },
        { title: '部门', dataIndex: 'applyDepartment', width: 120, align: 'center' },
        { title: ()=>{
            return (
                <>
                <span style={{ marginRight: '5px' }}>未归档天数</span>
                <Tooltip placement="topLeft" title={'未归档天数=当天日期 - 审批通过日期'}>
                    <QuestionCircleFilled />
                </Tooltip>
                </>
                )
            }, 
        dataIndex: 'noFilingDay', width: 140, align: 'center' },
        { title: ()=>{
            return (
                <>
                <span style={{ marginRight: '5px' }}>倒签天数</span>
                <Tooltip placement="topLeft" title={'倒签天数=申请日期 - 起始日期'}>
                    <QuestionCircleFilled />
                </Tooltip>
                </>
                )
            }, 
        dataIndex: 'reverseSignatureDay', width: 140, key: 'reverseSignatureDay', align: 'center' },
        {
            title: '操作',
            key: 'control',
            align: 'center',
            fixed: 'right',
            width: 200,
            render: (record) => (
                <Space size="small">
                    <Button type="link" size="small" onClick={() => {toView(record)}} block>查看</Button>
                    <Button type="link" size="small" onClick={() => {toUpdata(record)}} block>修改</Button>
                    <Button type="link" size="small" onClick={() => {toFiled(record)}} block>归档</Button>
                </Space>
            )
        }
    ]
  return (
    <>
    <Table 
        columns={tableColumns} 
        dataSource={tableData} 
        rowKey="contractNo"
        scroll={{ x: 'max-content', y: 540 }}
        pagination={{
        position: ['bottomCenter'],
        total: total,
        current: pageNum,
        pageSize: 20,
        showTotal:(total) =>  `共 ${total} 条数据`,
        onChange: (page, pageSize)　=> setPageNum(page)
        }}
    />
    <FiledModal ref={FiledModalRef} refresh={refresh}/>
    <ViewModal ref={ViewModalRef} />
    <EditModal ref={EditModalRef} refresh={refresh}/>
    </>
    
  )
}

export default ToBeFiled