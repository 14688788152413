import React, { useState, useEffect, useRef } from 'react'
import { Row, Table, Form, Button, Space, Input, Spin, Select, Typography, Popconfirm, PageHeader, message } from 'antd'
import AddDetailModel from './components/AddDetailModel'
import TransferModel from './components/TransferModel'
import BalanceModel from './components/BalanceModel'
import { abcEnterpriseGetDetail, SetStatus } from './api'
import store from '../../../../store/index'
import qs from 'qs'
const FormItem = Form.Item
const { Option } = Select
const { Link } = Typography
const labelStyleObj = {
    labelCol: {
        span: 9,
    },
    wrapperCol: {
        span: 15,
    },
}
function Detail(props) {
    const search = qs.parse(props.location.search.replace(/\?/g, ''))
    let searchParam = search
    let path = 'platform:abcEnterpriseAccountConfig'
    const [form] = Form.useForm()
    const [tableData, setTableData] = useState([])
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [loading, setLoading] = useState(false)
    const [phoneBefore, setPhoneBefore] = useState()
    const [phoneAfter, setPhoneAfter] = useState()
    const AddDetailModelRef = useRef();
    const TransferModelRef = useRef();
    const BalanceModelRef = useRef();
    const columns =[
        {
            title: '子户类型',
            dataIndex: 'typeName',
            width: 150,
            align: 'center',
        },
        {
            title: '关联企业',
            dataIndex: 'companyName',
            width: 150,
            align: 'center',
        },
        {
            title: '子户编号',
            dataIndex: 'logAccNo',
            width: 150,
            align: 'center',
        },
        {
            title: '银行账号',
            dataIndex: 'ownAccNo',
            width: 150,
            align: 'center',
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            align: 'center',
            render: (text)=>{
                if(text==1){
                    return '启用'
                }else{
                    return '停用'
                }
            }
        },
        {
            title: '操作',
            key: 'control',
            fixed: 'right',
            width: 160,
            align: 'center',
            render: (record) => (
                <>
                {record.type==3&&<Space>
                    {
                        store.getState().userReducer.permissions.indexOf(`${path}:status`)>-1&&<Popconfirm
                            title={record.status === 1 ? '是否确定要停用?' : '是否确定要启用?'}
                            onConfirm={() => {
                                onStatus(record)
                            }}
                            okText='确定'
                            cancelText='取消'
                        >
                            <Link>{record.status === 1 ? '停用' : '启用'}</Link>
                        </Popconfirm>
                    }
                    {<Link onClick={() => onBalance(record)}>查询余额</Link>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:transfer`)>-1&&<Link onClick={() => onTransfer(record)}>转帐</Link>}
                </Space>}
                </>
            )
        }
    ]
    const onStatus = (record)=>{
        let data = {
            companyCode: record.companyCode,
            status: record.status==1? 0: 1
        }
        SetStatus(data).then(res=>{
            if(res.data.success){
                message.success(res.data.retMsg)
                handleSearch()
            }else{
                message.error(res.data.retMsg)
            }
        })
    }
    const onBalance = (record)=>{
        BalanceModelRef.current.showModal(record)
    }
    const onTransfer = (record)=>{
        TransferModelRef.current.showModal(record);
    }
    //导航和查询组件
    const renderSearchForm = () => {
        return (
            <Form form={form} {...labelStyleObj}>
                <Row>
                <Form.Item name='type' style={{ width: '360px' }} labelCol={{ flex: '100px' }} label='子户类型'>
                    <Select allowClear placeholder='全部'>
                        <Option value='3'>企业户</Option>
                        <Option value='1'>服务费户</Option>
                        <Option value='2'>税费户</Option>
                    </Select>
                </Form.Item>
                    <FormItem label='关键字' name='keyword' labelCol={{ flex: '100px' }} style={{ width: '360px' }}>
                        <Input placeholder='关联企业' />
                    </FormItem>
                </Row>
                <Row justify='end'>
                    <Space>
                        <Button onClick={handleSearch} type='primary'>
                            查询
                        </Button>
                        <Button onClick={handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:insertCompany`)>-1&&<Button onClick={onAdd}>新增</Button>}
                    </Space>
                </Row>
            </Form>
        )
    }

    //重置表单
    const handleFormReset = () => form.resetFields()
    const handleSearch = () => {
        setPageNum(1)
        if(pageNum==1){
            getTableList()
        }
    }
    //新增
    const onAdd = () => {
        AddDetailModelRef.current.showModal();
    }
    
    const refresh = () => {
        handleSearch()
    }

    const getTableList = async () => {
        const formData = form.getFieldsValue()
        //根据companyType判断换签状态，来选择对应链接和参数
        let params = {
            pageSize: pageSize,
            pageNum: pageNum,
            keyword: formData.keyword,
            type: formData.type,
            taxEnterpriseCode: searchParam.taxEnterpriseCode,
        }
        setLoading(true)
        abcEnterpriseGetDetail(params).then(res=>{
            if(res.data.success){
                setTableData(res.data.data.list || [])
                setTotal(res.data.data.totalCount)
                setPhoneBefore(res.data.data.phoneBefore)
                setPhoneAfter(res.data.data.phoneAfter)
            }else{
                message.error(res.data.retMsg)
            }
        })
        setLoading(false)
    }
    
    useEffect(() => {
        getTableList()
    }, [pageNum])

    return (
        <>
        <Spin spinning={loading}>
            <PageHeader
                title="查看"
                style={{padding: '10px 24px 0px 24px'}}
                onBack={() => props.history.goBack()}
            />
            {renderSearchForm()}
            <Table
                scroll={{
                    x: 'max-content',
                    y: 500,
                }}
                columns={columns}
                rowKey='id'
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum),
                }}
            />
        </Spin>
        <AddDetailModel ref={AddDetailModelRef} refresh={refresh} taxEnterpriseCode={searchParam.taxEnterpriseCode} />
        <TransferModel ref={TransferModelRef} phoneBefore={phoneBefore} refresh={refresh} phoneAfter={phoneAfter} />
        <BalanceModel ref={BalanceModelRef} />
        </>
        
    )
}

export default Detail
