import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Row, Col, Typography, Table, Button, Space } from "antd";
import {getPreviewRecord, getContractUrl } from '../../api';
import { request } from '../../../../../utils/fetch'
const { Title } = Typography
const ConfigModal = forwardRef((props,ref)=>{
    const [title, setTitle] = useState('预览');
    const [visible, setVisible] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [tableData1, setTableData1] = useState([]);
    const columns = [
        { title: '合同编码', dataIndex: 'contractNo'},
        { title: '合同名称', dataIndex: 'contractName'},
        { title: '合同电子件', dataIndex: 'contractNamePdf'},
        {
            title: '操作',
            key: 'control',
            align: 'center',
            width: 100,
            render: (record) => (
                <Space size="small">
                    <Button type="link" size="small" onClick={() => {handelClick(record)}} block>{title}</Button>
                </Space>
            )
        }
    ]
    const columns1 = [
            { title: `${title}时间`, dataIndex: 'createTime', align: 'center' },
            { title: `${title}人`, dataIndex: 'createUser',  align: 'center' },
            { title: `${title}合同`, dataIndex: 'contractNamePdf', align: 'center' },
        ]
    useImperativeHandle(ref, () => ({
        previewData: (record) => {
            setTableData([{
                contractNo:record.contractNo,
                contractName:record.contractName,
                contractNamePdf:record.contractName + '.pdf',
            }])

            getPreviewRecord({
                type:1,
                contractNo:record.contractNo
            }).then(res=>{
                console.log(res.data.data);
                if(res.data.success){
                    setTableData1(res.data.data.map(item=>{
                        return{
                            ...item,
                            contractNamePdf:item.contractName + '.pdf',
                        }
                    }))
                }
            })
            setTitle('预览')
            setVisible(true)
        },
        downloadData: (record) => {
            setTableData([{
                contractNo:record.contractNo,
                contractName:record.contractName,
                contractNamePdf:record.contractName + '.pdf',
            }])

            getPreviewRecord({
                type:2,
                contractNo:record.contractNo
            }).then(res=>{
                console.log(res.data.data);
                if(res.data.success){
                    setTableData1(res.data.data.map(item=>{
                        return{
                            ...item,
                            contractNamePdf:item.contractName + '.pdf',
                        }
                    }))
                }
            })
            setTitle('下载')
            setVisible(true)
        },
    }));
    const handelClick = (record)=>{
        if(title==='预览'){
            getContractUrl({
                type:1,
                contractNo:record.contractNo
            }).then(res=>{
                console.log(res.data.data);
                if(res.data.success){
                    window.open(`/generic/web/viewer.html?file=${encodeURIComponent(res.data.data)}` )
                    // request.downExport(`http://192.168.19.47:8700/${res.data.data}` ,{})
                }
            })
        }else{
           
            request.downExport(`/platform/contractArchives/getContractUrl` ,{type:2,
                contractNo:record.contractNo})
        }
    }
    return (
      <Modal maskClosable={false}
        title={title} 
        visible={visible} 
        width={800}
        onCancel={() => { setVisible(false) }}
        footer={false}>
        <Row className='tablenotop'>
            <Col span={24}>
                <Title level={5} style={{ margin: '20px 20px 10px 20px', color: '#23a8a8' }}> 合同信息 </Title>
            </Col>
            <Table 
                columns={columns} 
                scroll={{ x: 'max-content', y: 540 }}
                dataSource={tableData} 
                rowKey="companyCode"
                pagination={false}
            />
            <Col span={24}>
                <Title level={5} style={{ margin: '20px 20px 10px 20px', color: '#23a8a8' }}> {title}记录 </Title>
            </Col>
            <Table 
                columns={columns1} 
                scroll={{ x: 'max-content', y: 540 }}
                dataSource={tableData1} 
                rowKey="companyCode"
                pagination={false}
            />
        </Row>
      </Modal>
    );
})

export default ConfigModal;
