import React, { useEffect, useState } from 'react'
import { request } from '../../../../../utils/fetch'
import { Form, Input, Select, Button, DatePicker, Row, Col, Space, InputNumber } from 'antd'
import store from '../../../../../store/index'
import moment from 'moment'
import ImportModal from '../../components/ImportModal'
const QueryForm = (props) => {
    const { refresh, pageNum, setPageNum } = props
    let path = 'accidentArchives'
    const [form] = Form.useForm()
    const [importVisible, setImportVisible] = useState(false)
    const { Option } = Select
    const { RangePicker } = DatePicker
    const labelStyleObj = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    }
    const itemStyle = { width: '390px', marginRight: '15px' }
    const initialFormValues = {}
    const Query = ()=>{
        if(pageNum===1){
            handelQuery()
        }else{
            setPageNum(1)
        }
    }
    // 查询
    const handelQuery = () => {
        let params = form.getFieldsValue()
        refresh('query', Object.assign({},params))
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
    }
    const upFileProps = {
        url: '/wispay/platform/ddc_bill/import',
        type: 'DDC_ZD',
        importVisible: importVisible,
        setImportVisible: setImportVisible,
        handelQuery: handelQuery
    }
    // 导出
    const exportData = () => {
        let data = form.getFieldsValue()
        let params ={
            orderNo: data.orderNo,
            storesName: data.storesName, 
            phone: data.phone, 
            name: data.name, 
            model: data.model, 
            payModel: data.payModel, 
            status: data.status, 
            beginExpireDays: data.beginExpireDays, 
            endExpireDays: data.endExpireDays, 
            beginPayDate: data.yfrq ? moment(data.yfrq[0]).format('YYYY-MM-DD') : '',
            endPayDate: data.yfrq ? moment(data.yfrq[1]).format('YYYY-MM-DD') : '',
            beginSettlementDate: data.sfrq ? moment(data.sfrq[0]).format('YYYY-MM-DD') : '',
            endSettlementDate: data.sfrq ? moment(data.sfrq[1]).format('YYYY-MM-DD') : '',
        }
        request.downExport('/platform/ddc_bill/export', params)
    }
    useEffect(() => {
        handelQuery()
    }, [pageNum])
    return (
        <>
        <Form {...labelStyleObj} initialValues={initialFormValues} form={form}>
            <Row>
                <Form.Item style={itemStyle} name='orderNo' label='订单号'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='storesName' label='门店'>
                    <Input placeholder='全部' />
                    {/* <Select allowClear placeholder='全部'>
                        <Option value='鹬鸟车行'>NC0000401(鹬鸟车行)</Option>
                    </Select> */}
                </Form.Item>
                <Form.Item style={itemStyle} name='phone' label='手机号'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='name' label='姓名'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='payModel' label='付费模式'>
                    <Select allowClear placeholder='全部'>
                        <Option value='先付'>先付</Option>
                        <Option value='先用后付'>先用后付</Option>
                        <Option value='自定义'>自定义</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='status' label='账单状态'>
                    <Select allowClear placeholder='全部'>
                        <Option value='正常待支付'>正常待支付</Option>
                        <Option value='逾期待支付'>逾期待支付</Option>
                        <Option value='支付中'>支付中</Option>
                        <Option value='已支付'>已支付</Option>
                        <Option value='已关闭'>已关闭</Option>
                        <Option value='已续租'>已续租</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='yfrq' label='应付日期'>
                    <RangePicker />
                </Form.Item>
                <Form.Item style={itemStyle} name='sfrq' label='实付日期'>
                    <RangePicker />
                </Form.Item>
                <Form.Item style={itemStyle}  label='逾期天数'>
                    <div style={{display: 'flex',justifyContent: 'space-between'}}>
                        <div style={{display: 'flex',flex: 1}}>
                            <Form.Item name='beginExpireDays' style={{width: '70%', marginBottom: '0px'}}>
                                <InputNumber placeholder='逾期天数' style={{width: '100%'}}  />  
                            </Form.Item>
                            <span style={{lineHeight: '32px',marginLeft: '6px'}}>天<span style={{marginLeft: '6px'}} >~</span></span>
                        </div>
                        <div style={{display: 'flex',flex: 1}}>
                            <Form.Item name='endExpireDays' style={{width: '70%', marginBottom: '0px'}}>
                                <InputNumber placeholder='逾期天数' style={{width: '100%'}} />
                            </Form.Item>
                            <span style={{lineHeight: '32px',marginLeft: '6px'}}>天</span>
                        </div>
                    </div>
                </Form.Item>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Space>
                        {store.getState().userReducer.permissions.indexOf(`${path}:select`) > -1 && (
                            <Button type='primary' onClick={Query}>
                                查询
                            </Button>
                        )}
                        <Button onClick={resetForm}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`) > -1 && <Button onClick={exportData}>导出</Button>}
                        <Button onClick={()=>{setImportVisible(true)}}>导入</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
        <ImportModal upFileProps={upFileProps} />
    </>
    )
}

export default QueryForm
