import React, { useImperativeHandle,forwardRef, useState, useEffect } from 'react'
import {Modal,Form,Input,Select,Row,Col,Upload,Button} from 'antd';
import {trafficArchivesGetDetail} from '../../api'
import {
  PlusOutlined,
} from '@ant-design/icons';
import '../index.css'
const { TextArea } = Input;
const topCol = {
  span: 12,
}
const action = "/wispay/common/uploadFile"
const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const EditReport = (props,ref) => {
//   const { cityData } = props
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList1: '',
    fileList2: '',
    fileList3: '',
    fileList4: '',
    fileList5: '',
    fileList6: '',
    fileList7: '',
    previewTitle: '',
  })
  const [form] = Form.useForm()
  const { Option } = Select
  const initialFormValues = {}
  
  const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU：重大刑事案件、涉及刑事或者治安案件，对公司品牌造成严重影响的</p>
      <p className="titp">P0：致人死亡或2人及以上重伤</p>
      <p className="titp">P1：致1人重伤</p>
      <p className="titp">P2：致人轻伤</p>
      <p className="titp">P3：致人轻微伤</p>
    </div>
  )
  const content2 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">案件响应时间-接报或上报时间</p>
    </div>
  )
  const content3 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">处置回复时间-案件响应时间</p>
    </div>
  )
  const content4 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">实际垫付金额 - 保险回款金额</p>
    </div>
  )
  const content5 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">家属诉求慰问金 - 实际赔付慰问金</p>
    </div>
  )
  const content6 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">智酬报备提审时间 - 智酬结案提审时间</p>
    </div>
  )
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  useEffect(() => {
  }, [])
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (ctitle, data, splist) => {
      form.resetFields()
      setVisible(true);
      trafficArchivesGetDetail({archivesCode:data.archivesCode}).then((res)=>{
        const result = res.data.data
        setEditData(res.data.data)
        result.scenePictureUrl = setFlieList(result.scenePictureUrl)
        result.orderPictureUrl = setFlieList(result.orderPictureUrl)
        result.responsibleLetterUrl = setFlieList(result.responsibleLetterUrl)
        result.medicalProveUrl = setFlieList(result.medicalProveUrl)
        result.comfortProtocolUrl = setFlieList(result.comfortProtocolUrl)
        result.otherZipUrl = setFlieList(result.otherZipUrl)
        form.setFieldsValue(result)
        setPreviewData(prev => {
          return {
            ...prev,
            fileList1:result.scenePictureUrl?result.scenePictureUrl:[],
            fileList2:result.orderPictureUrl?result.orderPictureUrl:[],
            fileList3:result.responsibleLetterUrl?result.responsibleLetterUrl:[],
            fileList4:result.medicalProveUrl?result.medicalProveUrl:[],
            fileList5:result.comfortProtocolUrl?result.comfortProtocolUrl:[],
            fileList6:result.otherZipUrl?result.otherZipUrl:[]
          }
        })
        console.log(previewData);
      })
    }
  }));
  const setFlieList = (list) => {
    list = list&&list.filter(item=>{
      return item.url
    })
    list = list&&list.map((item,index)=>{
      return {
        url: item.url,
        uid: item.uid || index,
        name: item.name||item.url,
        status: 'done',
      }
    })
    return list
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  const download = ()=>{
    window.location.href=`/${previewData.fileList6[0].url}`
  }
  return (
    <>
      <Modal maskClosable={false}
        title='查看'
        width='1200px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={null}
      >
        <Form initialValues={initialFormValues} form={form} labelAlign='right' className='trafficSafetyEditForm'>
          <Row className="margin-15">
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 7 }} label="当前处理进度">
                <Input disabled={true} value={form.getFieldValue('dealStatus')==1?'报备':form.getFieldValue('dealStatus')==2?'跟进':'结案'} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="reportOriginatorUserName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="报备发起人">
                <Input disabled={true} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseOriginatorUserName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="结案发起人">
                <Input disabled={true} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventSourceName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件来源">
                <Input disabled={true} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件工单号">
                <Input disabled={true} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="regionalName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="大区" >
                <Input disabled={true} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="注册城市">
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name='eventTypeNames' label="事件类型" style={{ marginBottom: 0 }} labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }}>
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventLevel" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} tooltip={{ title: content, color: '#FFFFFF' }} label="事件等级">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发城市">
                <div style={{display: 'flex', justifyContent: 'space-between'}} className='nobtn' >
                  <Form.Item style={{ marginBottom: 0, width: '100px' }} name="eventProvinceName" >
                    <Input disabled />
                  </Form.Item>
                  <span style={{display: 'inline-block',height: '32px', lineHeight: '32px', margin: '0 5px'}} >-</span>
                  <Form.Item style={{flex: '1', marginBottom: 0}} name="eventCityName" >
                    <Input disabled />
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="name" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="骑手姓名">
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eleCourierId" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="骑手ID">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="手机号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name='idNumber' labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="身份证号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="staffTypeName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="人员类型">
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insureFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="是否报保险">
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insureCompanyName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="保险公司">
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="insureOrderNo" labelCol={{ span: 3 }} wrapperCol={{ span: 7 }} label="保单号">
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventTime" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件发生时间">
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventReportTime" 
              labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="接报或上报时间">
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseRespondTime" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="案件响应时间">
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseRespondInterval" tooltip={{ title: content2, color: '#FFFFFF' }} labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="案件响应时效">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dealWithTime" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="处置回复时间">
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dealWithInterval" tooltip={{ title: content3, color: '#FFFFFF' }} labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="处置回复时效">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventRegionName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事发区" >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventStreetName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事发街道" >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventPlace" label="事件发生地址" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea autoSize={{ minRows: 2}}disabled={true} showCount />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="workFlag" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="跑单时段">
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="policeDepartmentName" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="报警部门">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="carTypeName" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="驾驶车辆">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="sensitiveName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="涉及敏感人群">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderStatusName" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="骑手状态">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderDescribe" label="骑手受伤情况说明" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right"  >
                <TextArea autoSize={{ minRows: 2}} disabled={true}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderWakeFlag" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="骑手是否清醒" labelAlign="right"  >
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderSurgicalFlag" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="骑手是否需要手术" labelAlign="right"  >
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='riderFamilyFlag' labelCol={{ span: 3 }} 
              wrapperCol={{ span: 7 }} label="骑手家属是否到场" labelAlign="right"  >
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartyStatusName" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="三者状态" >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartySurgicalFlag" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="三者是否需要手术">
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="threePartyDescribe" label="三者具体受伤情况及物损说明" labelCol={{ span: 3 }} 
              wrapperCol={{ span: 19 }} labelAlign="right"  >
                <TextArea autoSize={{ minRows: 2}} disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartyDamageAmount" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="三者财产损失金额">
                <Input placeholder="请填写" disabled={true} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="responsiblePartyName" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="事故定责">
                <Input disabled={true}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="violationTypeName" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="违规项目">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            {editData.violationType=='18'&&<Col {...topCol}>
              <Form.Item name="violationDescribe" label="自定义违规项目" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} >
                <Input placeholder="请输入" maxLength={128} />
              </Form.Item>
            </Col>}
            <Col span={24}>
              <Form.Item name="costDescribe" label="费用支出说明" labelCol={{ span: 3 }} 
              wrapperCol={{ span: 19 }} labelAlign="right"  >
                <TextArea autoSize={{ minRows: 2}} disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riskFlag" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="是否存在潜在风险">
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riskDescribe" label="风险描述" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <TextArea autoSize={{ minRows: 2}} disabled={true}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventDescribe" label="事件经过描述" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <TextArea autoSize={{ minRows: 2}} disabled={true}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="requireDescribe" label="甲方要求" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <TextArea autoSize={{ minRows: 2}} disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="advanceAmount" label="预估垫付金额" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <Input disabled={true}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="advanceReason" label="垫付原因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <TextArea autoSize={{ minRows: 2}} disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="borrowAmount" label="借款金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="realityAdvanceAmount" label="实际垫付金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insureCollectionAmount" label="保险回款金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="chargeAgainstFlag" label="垫付是否冲销" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="chargeAgainstReason" label="未冲销原因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <TextArea autoSize={{ minRows: 2}} disabled={true}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="advanceLossAmount" label="垫付损失金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" tooltip={{ title: content4, color: '#FFFFFF' }}>
                <Input  disabled={true} placeholder="请填写" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="advanceDamageReason" label="垫付损失原因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <TextArea autoSize={{ minRows: 2}}disabled={true}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="finalSolution" label="最终解决方案" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <TextArea autoSize={{ minRows: 2}} disabled={true}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="familyComfortAmount" label="家属诉求慰问金" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <Input disabled={true}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="realityComfortAmount" label="实际赔付慰问金" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="comfortLossAmount" label="减损金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" tooltip={{ title: content5, color: '#FFFFFF' }}>
                <Input  disabled={true}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="systemReportTime" label="智酬报备时间" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" >
                <Input  disabled={true}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="systemCaseTime" label="智酬结案时间" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right">
                <Input  disabled={true}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="systemCaseInterval" label="智酬结案时效" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" tooltip={{ title: content6, color: '#FFFFFF' }}>
                <Input  disabled={true}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} name='scenePictureUrl' valuePropName="fileList"
                getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={{ span: 19 }} label="现场/住院照片">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  disabled={true}
                  action={action}
                  listType="picture-card"
                  maxCount={10}
                  name="files"
                  fileList={previewData.fileList1}
                  onPreview={handlePreview}
                >{previewData.fileList1.length>0? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: 'right' }}>订单截图<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
            </Col>
            <Col span={19}>
              <Form.Item name='orderPictureUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  disabled={true}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={10}
                  name="files"
                  fileList={previewData.fileList2}
                  onPreview={handlePreview}
                >{previewData.fileList2.length>0? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: 'right' }}>责任认定书<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
            </Col>
            <Col span={19}>
              <Form.Item name='responsibleLetterUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
                <Upload
                  accept='.jpg,.jpeg,.png'
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={10}
                  disabled={true}
                  name="files"
                  fileList={previewData.fileList3}
                  onPreview={handlePreview}
                >{previewData.fileList3.length>0? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} name='medicalProveUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')} 
              wrapperCol={{ span: 19 }} label="诊断证明或病历">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  disabled={true}
                  maxCount={10}
                  name="files"
                  fileList={previewData.fileList4}
                  onPreview={handlePreview}
                >{previewData.fileList4.length>0? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} name='comfortProtocolUrl' valuePropName="fileList" 
              getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={{ span: 19 }} label="慰问协议">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  listType="picture-card"
                  disabled={true}
                  maxCount={10}
                  name="files"
                  fileList={previewData.fileList4}
                  onPreview={handlePreview}
                >{previewData.fileList5.length>0? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="otherZipUrl" label="家属资料/诉讼材料/其他" getValueFromEvent={(e) => normFile(e, 'list')}
                valuePropName="fileList"
                labelCol={{ span: 3 }} wrapperCol={{ span: 19 }}>
                <Upload 
                  name="files" 
                  fileList={previewData.fileList6}
                  disabled={true}
                  maxCount={1}
                  listType="picture"
                  onPreview={handlePreview}
                  >
                    {previewData.fileList6.length>0?<Button type="primary" onClick={download}>下载</Button>:null}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Modal maskClosable={false}
          width={1000}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
        </Modal>
      </Modal>
    </>
  );
};
export default forwardRef(EditReport)