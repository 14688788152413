import React,{useImperativeHandle, useState, useEffect,forwardRef} from 'react'
import { 
  Modal,
  Form,
  Input,
  Select,
  Space,
  Row,
  Col,
  DatePicker,
  Upload,
  Button,
  InputNumber,
  message
} from 'antd';
import {
  PlusOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import '../index.css'
import moment from 'moment'
import { updateAccidentArchives, getDingtalkUser } from '../../api'
import  eventTypedetail from '../../eventTypedetail'
const { TextArea } = Input;
const {  OptGroup } = Select;
const topCol = {
  span:12,
}
const action = "/wispay/common/uploadFile"
const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const AddMoel = (props,ref) => {
  const {refresh} = props
  const [visible, setVisible] = useState(false);
  const [damage, setDamage] = useState(true);
  const [sgqy, setsgqy] = useState(false);
  const [rycl, setrycl] = useState(false);
  const [sjrdzr, setsjrdzr] = useState(false);
  const [jaje, setjaje] = useState(false);
  const [title, setTitle] = useState('修改');
  const [disabled, setDisabled] = useState(false);
  const [editData, setEditData] = useState([]);
  const [DingkUser, setDingkUser] = useState([]);
  const [idNumberd, setidNumberd] = useState()
  const [jiean, setJiean] = useState()
  const [filterEventTypedetail, setFilterEventTypedetail] = useState([])
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList1: '',
    fileList2: '',
    fileList3: '',
    fileList4: '',
    fileList5: '',
    fileList6: '',
    fileList7: '',
    fileList8: '',
    fileList9: '',
    previewTitle: '',
  })
  const [form] = Form.useForm()
  const { Option } = Select
  const initialFormValues = {
    companyCompensationAmountList:[{amount:'',amountDate:''}],
    receivableAmountList:[{amount:'',amountDate:''}],
  }
  const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU：重大刑事案件、涉及刑事或者治安案件，对公司品牌造成严重影响的</p>
      <p className="titp">P0：交通事件致人死亡或2人及以上重伤</p>
      <p className="titp">P1：交通事件致1人重伤</p>
      <p className="titp">P2：交通事件致人轻伤</p>
      <p className="titp">P3：交通事件致人轻微伤</p>
    </div>
  )
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  useEffect(() => {
    getUser()
  }, [])
  const getUser = () => {
    getDingtalkUser().then(res => {
      console.log(res)
      if (res.data.success) {
        setDingkUser(res.data.data)
      }
    })
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    }
      // 上传图片，把图片地址存入 licenseFileUrl
  const handleChange = ({ file, fileList },type) => {
    console.log(file, fileList)
    fileList.forEach(item => {
      if (item.status === 'done') {
        if (item.response&&item.response.retCode === '000302') {
          localStorage.clear()
          window.location = '/login'
        }
      }
    })
    fileList = fileList.map(item => {
      if (item.response&&item.response.success) {
        // Component will show file.url as link
        item.url = item.response.data[0].url;
      }
      return item;
    });
    switch (type) {
      case 'files1':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList1: fileList
          }
        })
        break;
      case 'files2':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList2: fileList
          }
        })
        break;
      case 'files3':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList3: fileList
          }
        })
        break;
      case 'files4':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList4: fileList
          }
        })
        break;
      case 'files5':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList5: fileList
          }
        })
        break;
      case 'files6':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList6: fileList
          }
        })
        break;
      case 'files7':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList7: fileList
          }
        })
        break;
      case 'files8':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList8: fileList
          }
        })
        break;
      case 'files9':
        setPreviewData(prev => {
          return {
            ...prev,
            fileList9: fileList
          }
        })
        break;
      default:
    }
  }
  const setFlieList = (list) => {
    list = list&&list.filter(item=>{
      return item.url
    })
    list = list&&list.map((item,index)=>{
      return {
        url: item.url,
        uid: item.uid || index,
        name: item.name||item.url,
        status: 'done',
      }
    })
    return list
  }
  const setFlieParams = (list,type) => {
    list = list&&list.filter(item=>{
      return item.url
    })
    list = list&&list.map((item,index)=>{
      return {
        url: item.url,
        uid: 0-index,
        name: item.name||item.url,
        status: 'done',
        type: type,
      }
    })
    return list
  }
   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (title, data) => {
      setVisible(true);
      setTitle(title)
      setPreviewData({
        previewVisible: false,
        previewImage: null,
        fileList1: '',
        fileList2: '',
        fileList3: '',
        fileList4: '',
        fileList5: '',
        fileList6: '',
        fileList7: '', 
        fileList8: '',
        fileList9: '',
        previewTitle: '',
      })
      if(title == '查看'){
        setDisabled(true)
      }else{
        setDisabled(false)
      }
      if (data) {
        setEditData(data)
        setJiean(data.accidentHandlingStageType)
        // data.cityId = Number(data.cityId)
        data.eventType&&setFilterEventTypedetail(eventTypedetail[data.eventType])
        data.accidentCauseType ={value: String(data.accidentCauseType),label: String(data.accidentCauseTypeName)}
        data.scenePictureUrls = setFlieList(data.scenePictureUrls)
        data.accidentOrderScreenshotUrls = setFlieList(data.accidentOrderScreenshotUrls)
        data.accidentLiabilityUrl = setFlieList(data.accidentLiabilityUrl)
        data.diagnosticProofUrl = setFlieList(data.diagnosticProofUrl)
        data.motorcycleDrivingLicenseUrl = setFlieList(data.motorcycleDrivingLicenseUrl)
        data.compensationAgreement = setFlieList(data.compensationAgreement)
        data.transferRecord = setFlieList(data.transferRecord)
        data.otherUrl = setFlieList(data.otherUrl)
        data.otherNotPictureUrl = setFlieList(data.otherNotPictureUrl)
        // data.tname = data.tname
        // data.name = data.name 
        // data.phone = data.phone
        // data.staffType = data.staffType
        data.accidentMonth = data.accidentDate ? moment(data.accidentDate).format('YYYY年MM月') : null
        data.degreeInjuryType == '5'?setDamage(false):setDamage(true)
        data.accidentCauseTypeName == '其他'?setsgqy(true):setsgqy(false)
        data.riderVehicleType == '0'?setrycl(true):setrycl(false)
        data.accidentLiabilityType == '6'?setsjrdzr(true):setsjrdzr(false)
        data.accidentHandlingStageType == '6'?setjaje(true):setjaje(false)
        setidNumberd(data.idNumber)
        if(!data.companyCompensationAmountList||(data.companyCompensationAmountList&&data.companyCompensationAmountList.length==0)){
          data.companyCompensationAmountList = [{amount:'',amountDate:''}]
        }
        if(!data.receivableAmountList||(data.receivableAmountList&&data.receivableAmountList.length==0)){
          data.receivableAmountList = [{amount:'',amountDate:''}]
        }
        form.setFieldsValue(data)
        // getCompany()
        // getPersonList()
      } else {
        setEditData({})
        form.resetFields()
      }
    }
  }));
  const handleUpdate = async (params)=>{
    params.scenePictureUrls = setFlieParams(params.scenePictureUrls,'F01')
    params.accidentOrderScreenshotUrls = setFlieParams(params.accidentOrderScreenshotUrls,'F02')
    params.accidentLiabilityUrl = setFlieParams(params.accidentLiabilityUrl,'F04')
    params.diagnosticProofUrl = setFlieParams(params.diagnosticProofUrl,'F05')
    params.motorcycleDrivingLicenseUrl = setFlieParams(params.motorcycleDrivingLicenseUrl,'F06')
    params.compensationAgreement = setFlieParams(params.compensationAgreement,'F12')
    params.transferRecord = setFlieParams(params.transferRecord,'F11')
    params.otherUrl = setFlieParams(params.otherUrl,'F07')
    params.otherNotPictureUrl = setFlieParams(params.otherNotPictureUrl,'F08')
    params.archivesNo = editData.archivesNo
    // params.accidentDate = moment(editData.accidentDate).format('YYYY-MM-DD') 
    params.companyCompensationAmountList =  params.companyCompensationAmountList.map(element => {
      return {
        amountDate: element.amountDate? moment(element.amountDate).format('YYYY-MM-DD'):null,
        amount: element.amount,
        type: 1,
        archivesNo: element.archivesNo,
      }
    });
    params.receivableAmountList =  params.receivableAmountList.map(element => {
      return {
        amountDate: element.amountDate? moment(element.amountDate).format('YYYY-MM-DD'):null,
        amount: element.amount,
        type: 2,
        archivesNo: element.archivesNo,
      }
    });
    let result = await updateAccidentArchives(params)
    if (result.data.success) {
      message.success(result.data.retMsg)
      setVisible(false)
      refresh('query', {})
    } else {
      message.error(result.data.retMsg)
    }
  }
  const handleOk = ()=>{
    form.validateFields().then(valus=>{
      console.log(valus)
      const data = form.getFieldsValue()
      console.log('tijiao', data)
      let date = data.accidentDate ? moment(data.accidentDate).format('YYYY-MM-DD') : null
      let dzlist = form.getFieldsValue().companyCompensationAmountList.filter(item => { return (item.amount&&(!item.amountDate)||(item.amountDate&&(!item.amount)))});
      let hklist = form.getFieldsValue().receivableAmountList.filter(item => { return (item.amount&&(!item.amountDate)||(item.amountDate&&(!item.amount)))});
      if(dzlist.length>0){
          message.error('请完善公司赔偿/垫支项')
          return
        }
      if(hklist.length>0){
          message.error('请完善回款项')
          return
        }
      let params = Object.assign(data, { accidentDate: date,accidentCauseType:data.accidentCauseType.value})
      handleUpdate(params)
    }).catch(()=>{
      message.error('请完善表单')
    })
  }
  const onChange = (e)=>{
    form.setFieldsValue({
      accidentMonth: moment(e).format('YYYY-MM')
    })
  }
  const handleNumhk = ()=>{
    let acont = 0
    form.getFieldsValue().receivableAmountList.forEach(e => {
      if(e.amount){
        acont += Number(e.amount)
      }
    });
    let lossAmount = form.getFieldsValue().companyCompensationAmount - acont
    form.setFieldsValue({
      receivableAmount: acont,
      lossAmount: lossAmount.toFixed(2)
    })
  }
  const handleNumpc = ()=>{
    let acont = 0
    console.log(form.getFieldsValue().companyCompensationAmountList)
    form.getFieldsValue().companyCompensationAmountList.forEach(e => {
      if(e.amount){
        acont += Number(e.amount)
      }
    });
    let lossAmount = acont - form.getFieldsValue().receivableAmount
    form.setFieldsValue({
      companyCompensationAmount: acont,
      lossAmount: lossAmount.toFixed(2)
    })
  }
  const handleCancel = () => {
    setPreviewData(prev => {
      return {
        ...prev,
        previewVisible: false
      }
    })
  }
  const selectFilter2 = (inputValue, option) => {
    if (option.children&&option.children.includes(inputValue)) {
      return option
    }}
    const eventTypeChange = (value) => {
        console.log('sdfsd')
        setFilterEventTypedetail(eventTypedetail[value])
        form.setFieldsValue({
            accidentCauseType:{value:'',label:''}
        })
        setsgqy(false)
    }
  return (
    <>
      <Modal maskClosable={false}
        title={title}
        width='1000px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={!disabled?[
          <Button key="back" onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={()=>{handleOk()}}>
            确定
          </Button>,
        ]:null}
      >
        <Form initialValues={initialFormValues} form={form}>
          <Row className="margin-15">
            <Col {...topCol}>
              <Form.Item name="accidentNo" rules={[{ required: true }]} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件编号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}></Col>
            <Col {...topCol}>
              <Form.Item name="accidentHandlingStageType" labelCol={{ span: 6 }} rules={[{ required: true, message: '请选择处理阶段!' }]} wrapperCol={{ span: 14 }} label="处理阶段">
                <Select placeholder="请选择"  disabled={disabled} 
                getPopupContainer={triggerNode => triggerNode.parentElement}
                onChange={(val)=>{val=='6'?setjaje(true):setjaje(false)}}
                >
                  <Option value="-1">处理中</Option>
                  <Option value="5">暂冻结处理</Option>
                  {jiean=='6'&&<Option value="6">已结案</Option>}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="reportedUserId" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="报备人">
              <Select disabled={true} getPopupContainer={triggerNode => triggerNode.parentElement} 
              showSearch filterOption={(inputValue, option) => selectFilter2(inputValue, option)}>
              {DingkUser.map(item => (
                    <OptGroup label={item.name} key={item.name}>
                        {
                            item.list.map((citem)=>(
                                <Option value={citem.userId} key={citem.userId}>{citem.name}</Option>
                            ))
                        }
                    </OptGroup>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentDate" rules={[{ required: true, message: '请选择事件日期!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件日期">
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  disabled={disabled} 
                  allowClear={true}
                  onChange={onChange}
                  format={["YYYY-MM-DD"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentMonth" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件月">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="regionalName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="大区">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="provinceName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="省份">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityIdName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="城市">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="companyName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="企业名称">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="tname" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="T名称">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="name" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件人员">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="手机号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="身份证号">
                <Input disabled={true} value={idNumberd} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="staffType" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="人员类型">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} disabled={true} >
                  <Option value='1'>普通</Option>
                  <Option value='2'>优选</Option>
                  <Option value='3'>001</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentLevel" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} tooltip={{ title: content,color: '#FFFFFF'}} label="事件级别">
                <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="ESU">ESU</Option>
                  <Option value="P0">P0</Option>
                  <Option value="P1">P1</Option>
                  <Option value="P2">P2</Option>
                  <Option value="P3">P3</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="事件类型" required style={{ marginBottom: 0 }} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                <Form.Item
                  name="eventType"
                  rules={[{ required: true, message: '请选择事件类型' }]}
                  style={{ display: 'inline-block', width: 'calc(36% - 8px)' }}
                >
                  <Select placeholder="请选择" disabled={disabled} onChange={(value)=>{eventTypeChange(value)}} getPopupContainer={triggerNode => triggerNode.parentElement} >
                    <Option value="JT">交通</Option>
                    <Option value="XF">消防</Option>
                    <Option value="ZA">治安</Option>
                    <Option value="WW">维稳</Option>
                    <Option value="YW">意外</Option>
                  </Select>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="事件起因" required style={{ marginBottom: 0 }} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                <Form.Item
                  name="accidentCauseType"
                  rules={[{ required: true, message: '请选择事件起因' }]}
                  style={{ display: 'inline-block', width: 'calc(36% - 8px)' }}
                >
                  <Select labelInValue  placeholder="请选择" disabled={disabled} onChange={(val)=>{ val.label==='其他'?setsgqy(true):setsgqy(false)}} getPopupContainer={triggerNode => triggerNode.parentElement} >
                      {
                          filterEventTypedetail.map((item)=>{
                            return(
                                <Option value={item.id} key={item.id}>{item.name}</Option>
                            )
                          })
                      }
                  </Select>
                </Form.Item>
                {sgqy&&<Form.Item
                  name="accidentCauseOther"
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: sgqy, message: '请输入事件起因说明' }]}
                  style={{ display: 'inline-block', width: 'calc(60% - 8px)', margin: '0 8px' }}
                >
                  <TextArea autoSize={true} disabled={disabled} maxLength={500} placeholder="请输入其他说明，限500字" />
                </Form.Item>}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="人员车辆" required style={{ marginBottom: 0 }} rules={[{ required: true }]} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                  <Form.Item
                    name="riderVehicleType"
                    rules={[{ required: true, message: '请选择人员车辆' }]}
                    style={{ display: 'inline-block', width: 'calc(36% - 8px)' }}
                  >
                    <Select placeholder="请选择" disabled={disabled} onChange={(val)=>{val=='0'?setrycl(true):setrycl(false)}} getPopupContainer={triggerNode => triggerNode.parentElement} >
                      <Option value="1">电动车</Option>
                      <Option value="2">摩托车</Option>
                      <Option value="0">其他</Option>
                    </Select>
                  </Form.Item>
                  {rycl&&<Form.Item
                    name="riderVehicleOther"
                    wrapperCol={{ span: 24}}
                    rules={[{ required: rycl, message: '请输入人员车辆说明' }]}
                    style={{ display: 'inline-block', width: 'calc(60% - 8px)', margin: '0 8px' }}
                  >
                    <TextArea autoSize={true} disabled={disabled} maxLength={500} placeholder="请输入其他说明，限500字" />
                  </Form.Item>}
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name="accidentAddress" label="事件地点" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                  <TextArea disabled={disabled} showCount maxLength={200} placeholder="请输入其他说明，限500字" />
                </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="事件认定责任" required style={{ marginBottom: 0 }} rules={[{ required: true }]} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                <Form.Item
                  name="accidentLiabilityType"
                  rules={[{ required: true, message: '请选择事件认定责任!' }]}
                  style={{ display: 'inline-block', width: 'calc(36% - 8px)' }}
                >
                  <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement}  onChange={(val)=>{val=='6'?setsjrdzr(true):setsjrdzr(false)}}>
                  <Option value="0">对方全责</Option>
                  <Option value="1">对方主责</Option>
                  <Option value="2">双方同责</Option>
                  <Option value="3">我方全责</Option>
                  <Option value="4">我方主责</Option>
                  <Option value="5">责任待定</Option>
                  <Option value="6">其他</Option>
                </Select>
                </Form.Item>
                {sjrdzr&&<Form.Item
                  name="accidentLiabilityOther"
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: sjrdzr, message: '请输入事件认定责任说明' }]}
                  style={{ display: 'inline-block', width: 'calc(60% - 8px)', margin: '0 8px' }}
                >
                  <TextArea autoSize={true} disabled={disabled} maxLength={500} placeholder="请输入其他说明，限500字" />
                </Form.Item>}
              </Form.Item>
            </Col>
            {/* <Col {...topCol}>
              <Form.Item name="accidentLiabilityType" labelCol={{ span: 6 }} rules={[{ required: true, message: '请选择事件认定责任' }]} wrapperCol={{ span: 14 }} label="事件认定责任">
                <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="0">对方全责</Option>
                  <Option value="1">对方主责</Option>
                  <Option value="2">双方同责</Option>
                  <Option value="3">我方全责</Option>
                  <Option value="4">我方主责</Option>
                  <Option value="5">责任待定</Option>
                </Select>
              </Form.Item>
            </Col> */}
            <Col {...topCol}>
              <Form.Item name="degreeInjuryType" labelCol={{ span: 6 }} rules={[{ required: true, message: '请选择人身伤害程度' }]} wrapperCol={{ span: 14 }} label="人身伤害程度">
                <Select placeholder="请选择" disabled={disabled} 
                onChange={(val)=>{val=='5'?setDamage(false):setDamage(true); form.setFieldsValue({ damageType: null })}}
                getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="0">轻伤</Option>
                  <Option value="1">中度伤</Option>
                  <Option value="2">重伤</Option>
                  <Option value="4">死亡</Option>
                  <Option value="5">无</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="propertyType" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="财产伤害">
                <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="0">对方财产</Option>
                  <Option value="1">我方财产</Option>
                  <Option value="2">双方财产</Option>
                  <Option value="3">无财产伤害</Option>
                </Select>
              </Form.Item>
            </Col>
            {damage&&<Col {...topCol}>
            <Form.Item name="damageType" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="人身伤害">
                <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="0">对方人身</Option>
                  <Option value="1">我方人身</Option>
                  <Option value="2">双方人身</Option>
                  <Option value="3">无人身伤害</Option>
                </Select>
              </Form.Item>
            </Col>}
            <Col {...topCol}>
              <Form.Item name="insuranceCompanyType" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="保险公司">
                <Select placeholder="请选择" disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value="0">平安</Option>
                  <Option value="1">国泰</Option>
                  <Option value="2">太平洋</Option>
                  <Option value="3">无商业保险</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insurancePolicyNo" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="保单号">
                <Input placeholder="请输入保单号" disabled={disabled} maxLength={100}  />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="accidentOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件工单号">
                <Input placeholder="请输入事件工单号" disabled={disabled} maxLength={100}  />
              </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name="accidentDescribe" label="事件描述" rules={[{ required: true }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                  <TextArea disabled={disabled} showCount maxLength={500} placeholder="请输入其他说明，限500字" />
                </Form.Item>
            </Col>
            <Col span={3} style={{textAlign:'right'}} >公司赔偿/垫支：</Col>
            <Form.List name="companyCompensationAmountList">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row style={{width:'100%'}} key={key} >
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} 
                          label="金额"
                          name={[name, 'amount']}
                          fieldKey={[fieldKey, 'amount']}
                        >
                          <InputNumber placeholder="请输入" onChange={handleNumpc} disabled={disabled} style={{width:'100%'}} min={0}  />
                        </Form.Item>
                        </Col>
                        <Col span={8}>
                        <Form.Item
                          label="日期"
                          {...restField}
                          labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} 
                          name={[name, 'amountDate']}
                          fieldKey={[fieldKey, 'amountDate']}
                        >
                          <DatePicker disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} />
                        </Form.Item>
                      </Col>
                      {!disabled &&<Col span={5} >
                        <Form.Item>
                          <Space>
                            {fields.length>1&&<MinusCircleOutlined style={{fontSize:'20px'}} onClick={() => {remove(name);handleNumpc()}} />}
                            <PlusCircleOutlined style={{fontSize:'20px'}} onClick={() => add()} />
                          </Space>
                        </Form.Item>
                      </Col>}
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
            <Col span={10}>
              <Form.Item name="companyCompensationAmount" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} label="合计公司赔偿/垫支金额">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}></Col>
            <Col span={3} style={{textAlign:'right'}} >回款：</Col>
            <Form.List name="receivableAmountList">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row style={{width:'100%'}} key={key} >
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} 
                          label="金额"
                          name={[name, 'amount']}
                          fieldKey={[fieldKey, 'amount']}
                        >
                          <InputNumber placeholder="请输入" onChange={handleNumhk} disabled={disabled} style={{width:'100%'}} min={0}  />
                        </Form.Item>
                        </Col>
                        <Col span={8}>
                        <Form.Item
                          label="日期"
                          {...restField}
                          labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} 
                          name={[name, 'amountDate']}
                          fieldKey={[fieldKey, 'amountDate']}
                        >
                          <DatePicker disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentElement} />
                        </Form.Item>
                      </Col>
                      {!disabled&&<Col span={5} >
                        <Form.Item>
                          <Space>
                            {fields.length>1&&<MinusCircleOutlined style={{fontSize:'20px'}} onClick={() => {remove(name);handleNumhk()}} />}
                            <PlusCircleOutlined style={{fontSize:'20px'}} onClick={() => add()} />
                          </Space>
                        </Form.Item>
                      </Col>}
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
            <Col span={10}>
              <Form.Item name="receivableAmount" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} label="合计回款金额">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item name="lossAmount" labelCol={{ span: 8 }} tooltip={{ title: '公司当前损失金额=合计公司赔偿/垫支金额 - 合计回款金额' }} wrapperCol={{ span: 14 }} label="公司当前损失金额">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="lossCausesType" labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} label="造成当前实损的原因">
                <TextArea disabled={disabled} maxLength={500} placeholder="请输入造成当前实损的原因，限500字" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="insuranceClaimAmount" labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} label="保险理赔金额">
                <Input placeholder="请输入保险理赔金额" disabled={disabled||jaje} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="insuranceClaimDetail" labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} label="保险理赔明细">
                <TextArea disabled={disabled} maxLength={500} placeholder="请输入保险理赔明细，限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} name="disableGradeType" label="伤残等级">
                <Select placeholder="请选择" disabled={disabled} allowClear getPopupContainer={triggerNode => triggerNode.parentElement}>
                  <Option value="1">1级</Option>
                  <Option value="2">2级</Option>
                  <Option value="3">3级</Option>
                  <Option value="4">4级</Option>
                  <Option value="5">5级</Option>
                  <Option value="6">6级</Option>
                  <Option value="7">7级</Option>
                  <Option value="8">8级</Option>
                  <Option value="9">9级</Option>
                  <Option value="10">10级</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} name="disputesHandlingType" label="纠纷处理方式">
                <Select placeholder="请选择" disabled={disabled} allowClear getPopupContainer={triggerNode => triggerNode.parentElement}>
                  <Option value="0">调解</Option>
                  <Option value="1">仲裁</Option>
                  <Option value="2">诉讼</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} name="victimName" label="受害者姓名">
                <Input placeholder="请输入受害者姓名" disabled={disabled} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} name="victimIdNumber" label="受害者身份证">
                <Input placeholder="请输入受害者身份证" disabled={disabled} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} name="victimAppealAmount" label="受害者诉求金额">
                <InputNumber placeholder="请输入受害者诉求金额" disabled={disabled} style={{width:'100%'}} min={0}  />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} name="victimAppealDetail" label="受害者诉求明细">
                <TextArea disabled={disabled} maxLength={500} placeholder="请输入受害者诉求明细，限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} name="sentenceAmount" label="判决/调解金额">
                <InputNumber placeholder="请输入判决/调解金额" disabled={disabled} style={{width:'100%'}} min={0}  />
              </Form.Item>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: 'right',paddingRight: '10px' }}>判决/调解金额明细<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
            </Col>
            <Col span={7}>
              <Form.Item name="sentenceDetail">
                <TextArea disabled={disabled} maxLength={500} placeholder="请输入判决/调解金额明细，限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} name="caseDate" label="结案日期">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} name="caseAmount" label="结案金额">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="remark" label="备注" rules={[{ required: true }]} labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea disabled={disabled} showCount maxLength={500} placeholder="请输入备注，限500字" />
              </Form.Item>
            </Col>
            <Col style={{textAlign:'right',padding: '0 10px 10px 0'}} span={4}>
              <span className="equip-h2">材料附件</span>
            </Col>
            <Col span={24}>
              <Form.Item name="scenePictureUrls" labelCol={{ span: 4 }} valuePropName="fileList"
                getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={{ span: 19 }} label="现场照片">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  disabled={disabled}
                  action={action}
                  maxCount={5}
                  listType="picture-card"
                  data={uploadObj}
                  name="files"
                  fileList={previewData.fileList1}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => handleChange({ file, fileList }, 'files1')}
                >
                  {previewData.fileList1.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col  span={4}>
              <div style={{ textAlign: 'right' }}>事件订单截图<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
              {/* <div style={{ textAlign: 'center' }}><Button type="link" block>查看示例图</Button></div> */}
            </Col>
            <Col span={19}>
              <Form.Item valuePropName="fileList" name='accidentOrderScreenshotUrls'
                getValueFromEvent={(e) => normFile(e, 'list')} >
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={5}
                  disabled={disabled}
                  name="files"
                  fileList={previewData.fileList2}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => handleChange({ file, fileList }, 'files2')}
                >
                  {previewData.fileList2.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col  span={4}>
              <div style={{ textAlign: 'right' }}>责任认定书<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
              {/* <div style={{ textAlign: 'center' }}><Button type="link" block>查看示例图</Button></div> */}
            </Col>
            <Col span={19}>
              <Form.Item name='accidentLiabilityUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={5}
                  disabled={disabled}
                  name="files"
                  fileList={previewData.fileList3}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => handleChange({ file, fileList }, 'files3')}
                >
                  {previewData.fileList3.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='diagnosticProofUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')} labelCol={{ span: 4 }} wrapperCol={{ span: 19 }} label="诊断证明或病历">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={5}
                  disabled={disabled}
                  name="files"
                  fileList={previewData.fileList4}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => handleChange({ file, fileList }, 'files4')}
                >
                  {previewData.fileList4.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='motorcycleDrivingLicenseUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')} labelCol={{ span: 4 }} wrapperCol={{ span: 19 }} label="摩托车驾驶证行驶证">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  name="files"
                  maxCount={5}
                  disabled={disabled}
                  fileList={previewData.fileList5}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => handleChange({ file, fileList }, 'files5')}
                >
                  {previewData.fileList5.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 4 }} name='transferRecord' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={{ span: 19 }} label="转账记录/保险理赔凭据">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  name="files"
                  maxCount={5}
                  disabled={disabled}
                  fileList={previewData.fileList6}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => handleChange({ file, fileList }, 'files6')}
                >
                  {previewData.fileList6.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='compensationAgreement' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')} labelCol={{ span: 4 }} wrapperCol={{ span: 19 }} label="赔偿协议">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  name="files"
                  maxCount={5}
                  disabled={disabled}
                  fileList={previewData.fileList7}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => handleChange({ file, fileList }, 'files7')}
                >
                  {previewData.fileList7.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name='otherUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')} labelCol={{ span: 4 }} wrapperCol={{ span: 19 }} label="其他">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  name="files"
                  maxCount={5}
                  disabled={disabled}
                  fileList={previewData.fileList8}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => handleChange({ file, fileList }, 'files8')}
                >
                  {previewData.fileList8.length >= 5 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
              name="otherNotPictureUrl"
              labelCol={{ span: 4 }} wrapperCol={{ span: 19 }}
              label="其他(非图片)"
              getValueFromEvent={(e) => normFile(e, 'list')}
              valuePropName="fileList"
            >
              <Upload 
              name="files" 
              action={action}
              maxCount={5}
              disabled={disabled}
              data={uploadObj}
              fileList={previewData.fileList9} 
              listType="picture"
              onChange={({ file, fileList }) => handleChange({ file, fileList }, 'files9')}
              >
                {previewData.fileList9.length >= 5 ? null : !disabled&&<Button type="primary">上传</Button>}
              </Upload>
            </Form.Item>
            </Col>
          </Row>
        </Form>
        <Modal maskClosable={false}
          width={1000}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
        </Modal>
      </Modal>
    </>
  );
};
export default forwardRef(AddMoel)



