import React, { useImperativeHandle, useState, useEffect, forwardRef } from 'react'
import domtoimage from 'dom-to-image'
import { Modal, message, Button } from 'antd'
import './ProveModel.css'
import { getTaxEnterpriseSealUrl } from '../api'

const EditModel = (props,ref) => {
    const date = new Date()
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [hasSeal, setHasSeal] = useState(false)
    const [detail, setDetail] = useState({})
    const [style, setStyle] = useState({})
    // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        showModal: (record) => {
            setVisible(true)
            setDetail(record)
            console.log(record)

            getTaxEnterpriseSealUrl({
                code: record.taxEnterpriseCode,
            })
                .then((res) => {
                    if (res.data.success && res.data.data) {
                        setHasSeal(true)
                        setStyle({
                            backgroundImage: `url(${res.data.data}`,
                        })
                    } else {
                        // message.error('印章获取失败')
                        setHasSeal(false)
                    }
                })
                .catch(() => {})
        },
    }))

    const toPng = (type) => {
        if (type === '1' && !hasSeal) {
            message.error('印章未上传或获取失败，无法盖章下载！')
            return
        }

        let node = document.querySelector('.withdrawProve2')
        let obj = {}
        if (type === '1') {
            setLoading(true)
            obj.backgroundPosition = 'right 126px bottom 36px'
        }
        if (type === '2') {
            setLoading2(true)
        }
        domtoimage
            .toJpeg(node, {
                style: {
                    ...obj,
                },
            })
            
            .then((dataUrl) => {
                var link = document.createElement('a');
                link.download = detail.name + '合作证明.jpg';
                link.href = dataUrl;
                link.click();

                if (type === '1') {
                    setLoading(false)
                } else if (type === '2') {
                    setLoading2(false)
                }
            })
            .catch((error) => {
                if (type === '1') {
                    setLoading(false)
                }
                if (type === '2') {
                    setLoading2(false)
                }
                message.error('截图出错')
                throw error
            })
    }

    const onCancel = () => {
        setLoading(false)
        setLoading2(false)
        setVisible(false)
    }

    useEffect(() => {}, [])
    return (
        <Modal maskClosable={false}
            title='合作证明'
            width='800px'
            visible={visible}
            footer={[
                <Button key='back' onClick={onCancel}>
                    取消
                </Button>,
                <Button
                    key='down'
                    type='primary'
                    loading={loading}
                    onClick={() => {
                        toPng('1')
                    }}
                >
                    盖章下载
                </Button>,
                <Button
                    key='down1'
                    loading={loading2}
                    type='primary'
                    onClick={() => {
                        toPng('2')
                    }}
                >
                    下载
                </Button>,
            ]}
            onCancel={() => {
                setVisible(false)
            }}
        >
            <div className='withdrawProveBox2'>
                <div className='withdrawProve2' style={style}>
                    <p>证明</p>
                    <p>
                        兹证明骑手姓名<span>{detail.name}</span>，身份证号<span>{detail.idNumber}</span>，系与我司合作承揽外卖配送业务的骑士，在<span>{detail.cityName}</span>
                        范围内承接我司外卖配送服务。
                    </p>
                    <p>
                        本证明仅限骑手本人，基于外卖配送服务向相关部门证明使用，其他用途无效。
                    </p>
                    <p>特此证明</p>
                    <p>
                        出具人：<span>{detail.taxEnterpriseName}</span>
                    </p>
                    <p>
                        <span>{date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日'}</span>
                    </p>
                </div>
            </div>
        </Modal>
    )
}
export default forwardRef(EditModel)
