import React, { useState, useEffect,useRef, useImperativeHandle,forwardRef } from 'react';
import {
    Table,
    Space,
    message,
    Popconfirm
} from 'antd'
import store from '../../../../../store/index'
import { getAccidentCaseList, getDetailAccidentCase, deleteDetailAccidentCase } from '../../api'
const TableList = (props,ref)=> {
    let path = 'accidentCase'
    const {refresh} = props
    const [tableData, setTableData] = useState([])
    let pageSize = 20
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: pageSize,
        total: 0
    })
    
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        getlist: (val) => {
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
            getTableLists(pagination,val)
        }
    }));
    const getTableLists = async ({pageNum, pageSize},queryData={}) => {
        let result = await getAccidentCaseList(Object.assign({}, queryData, {pageNum: pageNum, pageSize: pageSize}))
        if (result.data.success) {
            setTableData(result.data.data.list)
            setPagination(prev => {
                return {
                    ...prev,
                    total: result.data.data.totalCount
                }
            })
        } else {
            message.error(result.data.retMsg)
        }
    }
    // 列表字段
    const tableColumns = [
        { title: '结案日期', dataIndex: 'caseDate', key: 'caseDate', align: 'center', width: 120 },
        { title: '事件编号', dataIndex: 'accidentNo', key: 'accidentNo', align: 'center', width: 140 },
        { title: '事件月', dataIndex: 'accidentMonth', key: 'accidentMonth', align: 'center', width: 120 },
        { title: '事件日期', dataIndex: 'accidentDate', key: 'accidentDate', align: 'center', width: 140 },
        { title: '城市', dataIndex: 'cityIdName', key: 'cityIdName', align: 'center', width: 100 },
        { title: '企业名称', dataIndex: 'companyName', key: 'companyName', align: 'center', width: 180 },
        { title: 'T名称', dataIndex:'tname', key: 'tname', align: 'center', width: 120,},
        { title: '结案人', dataIndex: 'reportedUserIdName', key: 'reportedUserIdName', align: 'center', width: 100 },
        { title: '事件级别', dataIndex: 'accidentLevel', key: 'accidentLevel', align: 'center', width: 100 },
        { title: '事件人员', dataIndex:'name', key: 'name', align: 'center', width: 120,},
        { title: '手机号',dataIndex:'phone', key: 'phone', width: 150, align: 'center',},
        { title: '身份证号', dataIndex:'idNumber', key: 'idNumber', width: 150, align: 'center'},
        { title: '结案金额', key: 'caseAmount', align: 'center', width: 100, render: (record) => { return record.caseAmount||record.caseAmount===0?<span>￥{record.caseAmount}</span>:''}},
        { title: '保险理赔金额', key: 'insuranceClaimAmount', align: 'center', width: 120,render: (record) => { return record.insuranceClaimAmount||record.insuranceClaimAmount===0?<span>￥{record.insuranceClaimAmount}</span>:''} },
        { title: '创建日期', dataIndex: 'createTime', key: 'createTime', align: 'center', width: 120 },
        { title: '流程状态', dataIndex: 'processStatusName', key: 'processStatusName', align: 'center', width: 100 },
        {
            title: '操作',
            key: 'control',
            fixed: 'right',
            width: 160,
            align: 'center',
            render: (record) => (
                <Space>
                    {store.getState().userReducer.permissions.indexOf(`${path}:select`) > -1 &&<a key="1" onClick={() => handelView(record)}>查看</a>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:update`) > -1 &&(record.processStatus == 0 || record.processStatus == 4  || record.processStatus == 5)&&<a key="2" onClick={() => handelModify(record)}>修改</a>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:delete`) > -1 &&record.processStatus == 0&&<Popconfirm
                        title="是否删除?"
                        onConfirm={()=>{handelDelete(record)}}
                        okText="确定"
                        cancelText="取消"
                    >
                        <a href="#">删除</a>
                    </Popconfirm>}
                </Space>
            )
        }
    ]
    useEffect(() => {
        getTableLists(pagination)
    }, [])
    // 查看
    const handelView = async (record) => {
        let result = await getDetailAccidentCase({caseNo:record.caseNo})
        if(result.data.success){
            refresh('view', result.data.data.accidentCaseVo,result.data.data.auditRecordList)
        }else{
            message.error(result.data.retMsg)
        }
    }
    // 修改
    const handelModify = async (record) => {
        let result = await getDetailAccidentCase({caseNo:record.caseNo})
        if(result.data.success){
            console.log(result)
            refresh('updata',result.data.data.accidentCaseVo,result.data.data.auditRecordList)
        }else{
            message.error(result.data.retMsg)
        }
    }
    // 删除
    const handelDelete = async (row) => {
        let result = await deleteDetailAccidentCase({caseNo:row.caseNo})
        if (result.data.success) {
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
            message.success(result.data.retMsg)
            getTableLists({ pageSize: pageSize, pageNum: 1 })
        } else {
            message.error(result.data.retMsg)
        }
    }
    //分页改变
    const tablePageChange = (pageNum, pageSize) => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: pageNum
            }
        })
        getTableLists({ pageSize: pageSize, pageNum: pageNum })
    }
    return (
        <Table
            columns={tableColumns}
            dataSource={tableData}
            scroll={{ x: 1600, y: 500 }}
            rowKey={record => record.caseNo}
            pagination={{
                position: ['bottomCenter'],
                showSizeChanger: false,
                total: pagination.total,
                current: pagination.pageNum,
                pageSize: pagination.pageSize,
                showTotal: (total) => `共 ${total} 条数据`,
                onChange: (pageNum, pageSize) => tablePageChange(pageNum, pageSize)
            }}
        />
    )
}
export default forwardRef(TableList)
