const eventTypedetail = {
    JT: [
        {
            id: '0',
            name: '追尾',
        },
        {
            id: '1',
            name: '逆行',
        },
        {
            id: '2',
            name: '闯红灯',
        },
        {
            id: '3',
            name: '个人纠纷',
        },
        {
            id: '4',
            name: '餐盒剐蹭',
        },
        {
            id: '5',
            name: '肇事逃逸',
        },
        {
            id: '6',
            name: '其他',
        },
        {
            id: '7',
            name: '超车不当',
        },
        {
            id: '8',
            name: '车辆失控',
        },
        {
            id: '9',
            name: '未按规定车道行驶',
        },
        {
            id: '10',
            name: '避让不及时',
        },
    ],
    XF: [
        {
            id: 'X1',
            name: '自租房火灾',
        },
        {
            id: 'X2',
            name: '电瓶车自燃',
        },
        {
            id: 'X0',
            name: '其他',
        },
    ],
    ZA: [
        {
            id: 'Z1',
            name: '刑事案件',
        },
        {
            id: 'Z2',
            name: '服务异常（恐吓、威胁、辱骂骚扰）',
        },
        {
            id: 'Z3',
            name: '扬言（自杀、他杀)',
        },
        {
            id: 'Z4',
            name: '治安案件（偷盗、斗殴闹事、性骚扰、妨碍公务）',
        },
        {
            id: 'Z0',
            name: '其他',
        },
    ],
    WW: [
        {
            id: 'W1',
            name: '罢工',
        },
        {
            id: 'W2',
            name: '煽动罢工',
        },
        {
            id: 'W3',
            name: '个人极端行为',
        },
        {
            id: 'W4',
            name: '群体事件',
        },
        {
            id: 'W0',
            name: '其他',
        },
    ],
    YW: [
        {
            id: 'Y1',
            name: '突发疾病',
        },
        {
            id: 'Y2',
            name: '工作期间意外事故',
        },
        {
            id: 'Y3',
            name: '非工作期间意外事故',
        },
        {
            id: 'Y0',
            name: '其他',
        },
    ],
}

export default eventTypedetail