import React,{useImperativeHandle,forwardRef} from 'react'
import { 
  Modal,
  Table,
  Input,
  message,
  Space,
} from 'antd';
import {request} from '../../../../utils/fetch'
const { TextArea } = Input;
const MyModel1 = (props,ref) => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [auditContent, setAuditContent] = React.useState('');


   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (val) => {
        setVisible(val)
        setAuditContent('')
      }
  }));
  const columns = [
    {
        title: '进入审核时间',
        dataIndex: 'createTime',
        key: 'createTime',
    }, {
        title: '企业名称',
        dataIndex: 'companyCodeName',
        key: 'companyCodeName',
    },{
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
    }, {
        title: '身份证号',
        dataIndex: 'idNumber',
        key: 'idNumber',
    },  {
        title: '佣金',
        dataIndex: 'commissionAmount',
        key: 'commissionAmount',
    },  {
        title: '进入审核的原因',
        dataIndex: 'auditReasonName',
        key: 'auditReasonName',
    },  
  ];
  const handleOk = () => {
    setLoading(true)
    let billDetailNoList = props.tableData.map(item => {
      return item.billDetailNo
    })

    if(props.titleModel == '通过'){
       request.post('/platform/billAudit/batchPass',{
          billDetailNoList:billDetailNoList.join(),
          auditContent:auditContent,
        }).then(res => {
          setVisible(false);
          setLoading(false);
          message.success('操作成功')
          props.refresh()
        }).catch(err =>  setLoading(false))
    }else{
       request.post('/platform/billAudit/batchReject',{
        billDetailNoList:billDetailNoList.join(),
        auditContent:auditContent,
      }).then(res => {
        setVisible(false);
        setLoading(false);
        message.success('操作成功')
        props.refresh()
      }).catch(err => setLoading(false))

    }
  }


  return (
    <>
      <Modal maskClosable={false}
        title={'批量'+props.titleModel}
        width='1000px'
        visible={visible}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={() => setVisible(false)}
      >
        <h3>勾选的打款信息</h3>
        <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            rowKey={(record) => record.idNumber}
            pagination={false}
            dataSource={props.tableData} 
           />
        <h3>审核{props.titleModel}</h3>
        <div>
          <Space align="center">
            审核描述:
            <TextArea cols="100" value={auditContent} onChange={(e) => setAuditContent(e.target.value) } showCount allowClear maxLength={500} />
          </Space>
        </div>
      </Modal>
    </>
  );
};
export default forwardRef(MyModel1)



