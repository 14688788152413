// 资产列表
import React, { useState} from 'react'
import { Spin, Table } from 'antd'
import moment from 'moment'
import QueryForm from './components/QueryForm'
import { ddcAssetGetList } from '../api'
function AssetList() {
    const [tableData, setTableData] = useState([])
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const refresh = (val,params) => {
        switch (val) {
            case 'query':
                getTableLists(params)
                break;
            default:
        }
    }
    
    const getTableLists = async (record) => {
        let data ={
            assetNo: record.assetNo,
            serialNo: record.serialNo, 
            storesName: record.storesName, 
            type: record.type, 
            model: record.model, 
            carOtherNumber: record.carOtherNumber, 
            carPlateNumber: record.carPlateNumber, 
            gpsNo: record.gpsNo, 
            carMotorNumber: record.carMotorNumber, 
            status: record.status, 
            orderNo: record.orderNo, 
            beginTime: record.djsj ? moment(record.djsj[0]).format('YYYY-MM-DD HH:mm:ss') : '',
            endTime: record.djsj ? moment(record.djsj[1]).format('YYYY-MM-DD HH:mm:ss') : '',
            pageSize: pageSize,
            pageNum: pageNum
        }
        ddcAssetGetList(data).then((res)=>{
            console.log(res)
            if(res.data.success){
                setTableData(res.data.data.list)
                setTotal(res.data.data.totalCount)
            }
        })
    }
    const getTableData = (num)=>{
        setPageNum(num)
    }
    // 列表字段
    const tableColumns = [
        { title: '序号', align: 'center', width: 60,render:(text, record, index)=>{
            return pageSize*(pageNum-1)+index+1
        }},
        { title: '资产编号', dataIndex: 'assetNo', key: 'assetNo', align: 'center', width: 140 },
        { title: '序列号', dataIndex: 'serialNo', key: 'serialNo', align: 'center', width: 140 },
        { title: '门店', dataIndex: 'storesName', key: 'storesName', align: 'center', width: 200 },
        { title: '品类', dataIndex: 'type', key: 'type', align: 'center', width: 100 },
        { title: '款型', dataIndex: 'model', key: 'model', align: 'center', width: 100 },
        { title: '车架/电池编号', dataIndex: 'carOtherNumber', key: 'carOtherNumber', align: 'center', width: 180 },
        { title: 'GPS厂商', dataIndex: 'gpsName', key: 'gpsName', align: 'center', width: 100 },
        { title: 'GPS编号', dataIndex: 'gpsNo', key: 'gpsNo', align: 'center', width: 100 },
        { title: '车牌号', dataIndex: 'carPlateNumber', key: 'carPlateNumber', align: 'center', width: 100 },
        { title: '电机号', dataIndex: 'carMotorNumber', key: 'carMotorNumber', align: 'center', width: 100 },
        { title: '备注', dataIndex: 'remarks', key: 'remarks', align: 'center', width: 100 },
        { title: '资产状态', dataIndex: 'status', key: 'status', align: 'center', width: 110 },
        { title: '订单号', dataIndex: 'orderNo', key: 'orderNo', align: 'center', width: 170 },
        { title: '登记时间', dataIndex: 'registerTime', key: 'registerTime', align: 'center', width: 110 },
    ]
    return (
        <Spin spinning={false}>
            <QueryForm refresh={refresh} pageNum={pageNum} setPageNum={setPageNum} />
            <Table 
            columns={tableColumns} 
            dataSource={tableData} 
            scroll={{ x: 'max-content', y: 520 }} 
            rowKey={(record) => record.id}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger: false,
                showTotal: (total) => `共 ${total} 条数据`,
                onChange: (num, pageSize) => getTableData(num),
            }} />
        </Spin>
    )
}
export default AssetList
