import React,{ useImperativeHandle, useEffect, forwardRef } from 'react'
import { 
  Modal,
  Input,
  Form,
  message,
  Button,
  InputNumber,
  Spin,
  Select
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PasscodeInput from '../../../../../components/PasscodeInput/index'
import { getCount, getCode, transfer, getBankList } from '../api'
const FormItem = Form.Item;
const { Option } = Select
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 20,
    }}
    spin
  />
);
const TransferModel = (props, ref) => {
  let second = 60
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState();
  const [disabled, setDisabled] = React.useState(false);
  const [btnTxt, setBtnTxt] = React.useState('获取验证码');
  const [row, setRow] = React.useState();
  const [bankList, setBankList] = React.useState([])
  const [spinLoading, setSpinLoading] = React.useState(false);
  const phoneBefore = props.phoneBefore
  const phoneAfter = props.phoneAfter
  const initialValues = {
    age:''
  }

  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (record) => {
      setCount('')
      setVisible(true);
      form.resetFields()
      form.setFieldsValue(record)
      setRow({...record})
      getYuE(record)
      getBankLists()
    }
  }));
  useEffect(() => {
  }, [])
  const getYuE = (record)=>{
    setSpinLoading(true)
    let data = {
      taxEnterpriseCode: record.taxEnterpriseCode,
      logAccNo: record.logAccNo,
    }
    getCount(data).then(res=>{
      setSpinLoading(false)
      if(res.data.success){
        setCount(res.data.data)
      }else{
        message.error(res.data.retMsg)
        setCount('')
      }
    }).catch(()=>{
      setSpinLoading(false)
    })
  }
  // 获取银行列表
  const getBankLists = async() => {
    let res = await getBankList()
    console.log(res)
    if (res.data.success) {
        setBankList(res.data.data)
    }
  }
  const methodsFn =  {
    handleOk: async() => {
      try {
        const values = await form.validateFields();
        console.log(values)
        setLoading(true);
        methodsFn.handleAdd(values)
      } catch (errorInfo) {
        console.log('Failed:', errorInfo);
      }
    },
    handleAdd(obj){
      if(Number(obj.amount) > Number(count)){
        message.error('转账金额不能大于可用余额');
        setLoading(false);
        return
      }
      let phone = phoneBefore + obj.phone + phoneAfter
      let data = {
        accountNo: obj.accountNo,
        accountName: obj.accountName,
        bankName: obj.bankName,
        code: obj.code,
        amount: obj.amount,
        phone: phone,
        type: row.type,
        taxEnterpriseCode: row.taxEnterpriseCode,
        companyCode: row.companyCode
      }
      transfer(data).then(res=>{
        if(res.data.success){
          setVisible(false);
          setLoading(false);
          message.success(res.data.retMsg)
          props.refresh()
        }else{
          message.error(res.data.retMsg)
          setLoading(false);
        }
      }).catch(()=>{
        setLoading(false);
      })
    },
  }
  const timerFn = () => {
    setTimeout(() => {
        if(second > 0){
              setDisabled(true)
              setBtnTxt(`${second}秒后重试`)
            second --
            timerFn()
        }else{
          second = 60
          setDisabled(false)
          setBtnTxt('获取验证码')
        }
    }, 1000);

  }
  const onGetCode = ()=>{
    form.validateFields(['phone']).then((values)=>{
      console.log(values)
      let phone = phoneBefore + values.phone + phoneAfter
      let data = {
        phone: phone,
        type: 4
      }
      setBtnTxt('发送中')
      setDisabled(true)
      getCode(data).then(res=>{
        console.log(res)
        if(res.data.success){
          timerFn()
        }else{
          message.error(res.data.retMsg)
          setDisabled(false)
          setBtnTxt('获取验证码')
        }
      }).catch(()=>{
        setBtnTxt('获取验证码')
        setDisabled(false)
      })
    })
  }

  return (
    <>
      <Modal maskClosable={false}
        forceRender
        title='转账'
        width='600px'
        visible={visible}
        onOk={methodsFn.handleOk}
        confirmLoading={loading}
        onCancel={() => {setVisible(false);}}
      >
        <Form
          labelCol={{ flex: '100px' }}
          wrapperCol={{ span: 15 }}
          initialValues={initialValues}
          form={form}
        >   
          <FormItem name="companyName" label="关联企业" >
            <Input disabled />
          </FormItem>
          <FormItem name="typeName" label="子户类型" >
            <Input disabled />
          </FormItem>
          <FormItem label="可用余额" >
            <Spin spinning={spinLoading} indicator={antIcon} >
              <Input value={count} disabled />
            </Spin>
          </FormItem>
          <FormItem name="accountName" label="转入户名" rules={[{ required: true, message: '请输入转入户名' }]} >
            <Input />
          </FormItem>
          <FormItem name="accountNo" label="转入卡号" rules={[{ required: true, message: '请输入转入卡号' }]} >
            <Input />
          </FormItem>
          <FormItem name="bankName" label="开户行名称" rules={[{ required: true, message: '请选择开户行名称' }]} >
            <Select placeholder="请选择开户行名称">
              {
                bankList.map(item => (
                    <Option value={item.bankName} key={item.bankName}>{item.bankName}</Option>
                ))
              }
            </Select>
          </FormItem>
          <FormItem name="amount" type="number" label="转账金额" rules={[{ required: true, message: '请输入转账金额' }]} >
            <InputNumber style={{width: '100%'}} placeholder="请填写" min={0} max={9999999999} maxLength={100} precision={2} />
          </FormItem>
          <FormItem name="phone" label="授权手机号" rules={[{ required: true, min: 4, message: '请补全号码' }]} >
            <div style={{display: 'flex',alignItems: 'center'}}>
              <div style={{fontSize: '20px'}} >{phoneBefore}</div>
                <PasscodeInput />
              <div style={{fontSize: '20px'}} >{phoneAfter}</div>
            </div>
          </FormItem>
          <FormItem name="code" label="短信验证码" rules={[{ required: true, message: '请输入短信验证码' }]} >
            <div style={{display: 'flex'}} >
              <Input placeholder="请输入"/>
              <Button style={{marginLeft: '10px'}} onClick={onGetCode} disabled={disabled} type="primary">{btnTxt}</Button>
            </div>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};
export default forwardRef(TransferModel)



