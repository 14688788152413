import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Form, Input, Modal, Row, Col, Typography ,message} from "antd";
import { getContractArchivesDetail } from '../../api'
import { getCodeUrl } from '../../../../../api/api';

const { TextArea } = Input;
const { Title } = Typography

const ConfigModal = forwardRef((props,ref)=>{
    const [title, setTitle] = useState('查看');
    const [visible, setVisible] = useState(false);
    const [NailQRcode, setNailQRcode] = useState(null)
    const [form] = Form.useForm()
    const initialValues = {}
     // 获取钉钉审批二维码
     const getQRcode = async (row)=>{
        console.log(row)
        let result = await getCodeUrl({ processInstanceId: row.processId, type: row.source })
        if (result.data.success) {
            setNailQRcode(result.data.data)
        } else {
            message.error(result.data.retMsg)
        }
    }

    useImperativeHandle(ref, () => ({
        viewData: (record) => {
            getContractArchivesDetail({contractNo:record.contractNo}).then(res=>{
                form.setFieldsValue({
                    ...res.data.data,
                    filingFlag:res.data.data.filingFlag ===1 ? '已归档' : '未归档'
                })
            })
            if(record.processId){
                getQRcode(record)
            }
            setTitle('查看')
            setVisible(true)
        },
    }));

    const onCancel = () =>{
        setVisible(false)
        form.resetFields()
        setNailQRcode(null)
    }
    
    return (
      <Modal maskClosable={false}
        title={title} 
        visible={visible} 
        width={800}
        onCancel={onCancel}
        footer={false}>
        <Form
            form={form}
            labelCol={{ flex: '100px' }}
            initialValues={initialValues}
            >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} style={{ margin: '0px 20px 10px 20px', color: '#23a8a8' }}>合同信息</Title>
                </Col>
                <Col span={12}>
                    <Form.Item name="dingEndTime" label="审批通过时间" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="sourceName" label="来源" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="processNo" label="流程编号" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='contractNo' label='合同编码' >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="contractName" label="合同名称" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="partyA" label="合同甲方" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="partyB" label="合同乙方" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="contractStartDate" label="起始日期">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="contractEndDate" label="终止日期">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="applyName" label="申请人">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="applyDepartment" label="部门">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="applyDate" label="申请日期">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="sealTypeName" label="用印类型">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="stampTypeName" label="印章类型">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Title level={5} style={{ margin: '20px 20px 10px 20px', color: '#23a8a8' }}>档案信息</Title>
                </Col>
                <Col span={12}>
                    <Form.Item name="filingFlag" label="归档状态">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="filingName" label="归档人员">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="filingTime" label="归档时间">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="filingDay" label="归档天数">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={24}></Col>
                <Col span={12}>
                    <Form.Item name="reverseSignatureDay" label="倒签时间">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="sealNum" label="用印份数">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="sealMsg" label="用印说明">
                        <TextArea disabled rows={3} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item className='wrapl' name="sendAddress" label="回寄联系人及地址">
                        <TextArea disabled rows={3} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="remark" label="备注">
                        <TextArea disabled rows={3} />
                    </Form.Item>
                </Col>
                <Col>
                    <Title level={5} style={{ margin: '20px 20px 10px 20px', color: '#23a8a8' }}>关联审批单</Title>
                </Col>
                <Col span={24}>
                    <Form.Item name="relationProcessName" label="关联流程">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            {NailQRcode && (
            <Row>
                <Title level={5} style={{ margin: '0 30px 30px', color: '#23a8a8' }}> 钉钉审批二维码 </Title>
                <Col>
                    <img alt='' style={{ width: '150px', height: '150px' }} src={NailQRcode} />
                </Col>
            </Row>
            )}
        </Form>
      </Modal>
    );
})

export default ConfigModal;
