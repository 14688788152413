import React, { useImperativeHandle, useState, useEffect, forwardRef } from 'react'
import { Form, Modal, Select, Input, Row, Col, message, DatePicker } from 'antd'
import { withdrawProveInsert, getCrowdStaffList, getCityListByProvinceId } from '../api'
const { Option } = Select

const EditModel = (props, ref) => {
    const { TaxEnterpriseLis } = props
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm() //上传文件表单
    const [planTypeData, setPlanTypeData] = useState([]) // 落地方案列表
    const [renyunData, setRenyunData] = useState([]) // 落地方案列表
    const [cityData, setCityData] = useState([]) // 城市
    // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        showModal: () => {
            setVisible(true)
        },
    }))
    useEffect(() => {
        getCityListByProvinceId().then((res) => {
            if (res.data.success) {
                setCityData(res.data.data)
            }
        })
    }, [])

    // 验证通过后的回调
    const onFinish = (values) => {
        console.log(values)
        setLoading(true)
        if (values['endMonth'].isBefore(values['beginMonth'])) {
            message.error('开始月份不能大于结束月份')
            setLoading(false)
            return
        }
        if ((values['endMonth'].diff(values['beginMonth'], 'month'))>11) {
            message.error('最多选择12个月的记录！')
            setLoading(false)
            return
        }

        withdrawProveInsert({
            ...values,
            beginMonth: values['beginMonth'].format('YYYY-MM'),
            endMonth: values['endMonth'].format('YYYY-MM'),
        })
            .then((res) => {
                if (res.data.success) {
                    message.success('新增成功')
                    props.refres()
                    setVisible(false)
                } else {
                    message.error(res.data.retMsg)
                }
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const onCancel = () => {
        setVisible(false)
        form.resetFields()
        setPlanTypeData([])
    }

    // 获取人员
    const getPersonList = async (value) => {
        form.setFieldsValue({
            idNumber: '',
        })
        const res = await getCrowdStaffList({
            pageSize: 10000,
            pageNum: 1,
            cityIdList: form.getFieldsValue().cityId,
            companyCodeList: form.getFieldsValue().companyCode,
            taxEnterpriseCode: form.getFieldsValue().taxEnterpriseCode,
        })
        if (res.data.success) {
            setRenyunData(res.data.data.list || [])
        }
        console.log(res)
    }

    const onRenYuanChange = async (value) => {
        let renYuan = renyunData.filter((item) => {
            return item.idNumber === value
        })

        renYuan.length > 0 &&
            form.setFieldsValue({
                phone: renYuan[0].phone,
                name: renYuan[0].name,
            })
    }

    // 下拉框
    const selectFilter = (inputValue, option) => {
        if (option.children.indexOf(inputValue) != -1) {
            return option
        }
    }

    return (
        <Modal maskClosable={false}
            title='新增'
            width='800px'
            visible={visible}
            onCancel={onCancel}
            onOk={() => {
                form.submit()
            }}
            confirmLoading={loading}
        >
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} form={form} onFinish={onFinish}>
                <Row>
                    {/* <Col span={12}>
                        <Form.Item
                            name='companyCode'
                            label='企业名称'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择企业名称',
                                },
                            ]}
                        >
                            <Select onChange={getPersonList}>
                                {companyData.map((item) => {
                                    return (
                                        <Option value={item.companyCode} key={item.companyCode}>
                                            {item.companyName}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col> */}
                    <Col span={12}>
                        <Form.Item label='财税企业' name='taxEnterpriseCode' rules={[{ required: true }]}>
                            <Select allowClear placeholder='全部' onChange={getPersonList}>
                                {TaxEnterpriseLis.map((e) => (
                                    <Option key={e.code} value={e.code}>
                                        {e.enterpriseName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item name='cityId' label='城市名称' rules={[{ required: true }]}>
                            <Select onChange={getPersonList} filterOption={(inputValue, option) => selectFilter(inputValue, option)} showSearch >
                                {cityData.length > 0 &&
                                    cityData.map((item) => (
                                        <Option value={Number(item.cityId)} key={item.cityId}>
                                            {item.cityName}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='idNumber' label='人员' rules={[{ required: true }]}>
                            <Select filterOption={(inputValue, option) => selectFilter(inputValue, option)} onChange={onRenYuanChange} showSearch>
                                {renyunData.map((item) => {
                                    return (
                                        <Option value={item.idNumber} key={item.idNumber}>
                                            {item.name}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item name='name' hidden>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label='开始月份' name='beginMonth' rules={[{ required: true }]}>
                            <DatePicker
                                onChange={(e) => {
                                    console.log(e)
                                }}
                                picker='month'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='结束月份' name='endMonth' rules={[{ required: true }]}>
                            <DatePicker
                                onChange={(e) => {
                                    console.log(e)
                                }}
                                picker='month'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item name='phone' label='手机号'>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='idNumber' label='身份证号'>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export default forwardRef(EditModel)
