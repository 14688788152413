import React, { useState, useEffect } from 'react'
import { useSetState } from 'react-use'
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Table,
  Radio,
  Space,
  Spin,
  message
} from 'antd'
import { getCompanyPipeline } from '../api'
import { getCityList, getCountyList, getRelevanceCompany } from '../../../../api/api'


const { Option } = Select
const labelStyleObj = {
  wrapperCol:{ span: 18 }
}
const itemStyle = {width: '340px', marginRight: '15px'}

const PlatformRecord = (props) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(true)
  const [cityData, setCityData] = useState([])
  const [tableData, setTableData] = useState([])
  const [countyData, seteCountyData] = useState([])
  const [companyData, setCompanyData] = useState([])

  //
  const getList = async(page) => {
    const { cityIdList, countyIdList, planType, companyCode } = form.getFieldsValue()
    const params = {
      cityIdList: cityIdList,
      countyIdList: countyIdList,
      planType: planType,
      companyCode: companyCode,
      pageSize: page.pageSize,
      pageNum: page.pageNum
    }
    try {
      setLoading(true)
      const result = await getCompanyPipeline(params)
      console.log(result)
      if (result.data.success) {
        setTableData(result.data.data.list || [])
        setPagination(prev => {
          return {...prev, total: result.data.data.totalCount}
        })
      } else {
        message.error(result.data.retMsg)
      }
      setLoading(false)
    } catch(err) {
      setLoading(false)
      console.log('___企业流水列表', err)
    }
    
  }
  // 获取城市片区
  const getCity = async() => {
    try {
      const citys = await getCityList()
      setCityData(citys)
    }catch(err) {
      console.log(err)
    }
  }
  // 
  const cityChange = async (value) => {
    console.log(value)
    form.setFieldsValue({
      countyIdList: []
    })
    seteCountyData([])
    if (!value) return
    try {
      const countyData = await getCountyList(value)
      seteCountyData(countyData)
    }catch(err) {
      console.log(err)
    }
  }
  // 获取关联公司
  const getCompany = async () => {
    const res = await getRelevanceCompany()
    console.log(res)
    if (res && res.length > 0) {
      setCompanyData(res)
    }
  }
  useEffect(() => {
    getCity()
    getCompany()
    getList(pagination)
  }, [])
  // form 表单域默认值
  const initialFormValues = {
    cityIdList: [],  // 状态
    countyIdList: [],
    planType: '',
    companyCode: [], // 关键字
  }
  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: 20,
    total: 0
  })
  // 列表字段
  const tableColumns = [
    {
      title: '入驻时间',
      dataIndex: 'createTime',
      key: 'createTime',
      align: 'center'
    },{
      title: '落地城市区县',
      dataIndex: 'cityCountyNames',
      key: 'cityCountyNames',
      align: 'center'
    },{
      title: '落地方案',
      dataIndex: 'planTypeNames',
      key: 'planTypeNames',
      align: 'center'
    },{
      title: '企业名称',
      dataIndex: 'companyName',
      key: 'companyName',
      align: 'center'
    },{
      title: '企业ID',
      dataIndex: 'companyCode',
      key: 'companyCode',
      align: 'center'
    },{
      title: '操作',
      key: 'control',
      align: 'center',
      render: (record) => (
        <a onClick={() => toDetail(record)}>查看</a>
      )
    }
  ]

  // 重置
  const resetForm = () => {
    form.resetFields()
  }
  //分页改变
  const tablePageChange = (page, pageSize) => {
    setPagination(prev => {
      return {
        ...prev,
        pageNum: page
      }
    })
    getList({pageNum: page, pageSize: pageSize})
  }
  // 详细
  const toDetail = (record) => {
    props.history.push({pathname: `/admin/companyPipeline/detail/${record.companyCode}`, state: {companyName: record.companyName}})
  }
  const submit = () => {
    getList({pageNum: 1, pageSize: pagination.pageSize})
    setPagination(prev => {
      return {
        ...prev,
        pageNum: 1
      }
    })
  }
  // 下拉框
  const selectFilter = (inputValue, option) => {
    if (option.children.indexOf(inputValue) != -1) {
      return option
    }
  }
  return (
    <Spin spinning={loading}>
      <div className="contract-lists">
      
        <Form labelCol={{ span: 6 }} initialValues={initialFormValues} form={form}>
          <Row>
            <Form.Item style={itemStyle} {...labelStyleObj} name="cityIdList" label="落地城市">
              <Select onChange={(value) => cityChange(value)} allowClear placeholder="全部">
                {cityData.length > 0 && cityData.map(item => (
                  <Option value={item.id} key={item.id}>{item.areaName}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={itemStyle} {...labelStyleObj} name="countyIdList" label="落地区县">
              <Select mode="multiple"  placeholder="全部">
                {countyData.map(item => (
                  <Option value={item.id} key={item.id}>{item.areaName}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={itemStyle} {...labelStyleObj} name="planType" label="落地方案">
              <Select allowClear  placeholder="全部">
                <Option value="1">临时税务登记</Option>
                <Option value="2">个体工商户</Option>
                <Option value="3">委托代征</Option>
              </Select>
            </Form.Item>
            <Form.Item style={itemStyle} {...labelStyleObj} name="companyCode" label="企业名称">
              <Select filterOption={(inputValue, option) => selectFilter(inputValue, option)} allowClear>
                {
                  companyData.map(item => (
                    <Option value={item.companyCode} key={item.companyCode}>{item.companyName}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: 'right'}}>
              <Space>
                <Button type="primary" onClick={submit}>查询</Button>
                <Button onClick={resetForm}>重置</Button>
              </Space>
            </Col>                              
          </Row>
        </Form>

        {/* <div>服务费合计：325321.12元 税费合计：3151117.45元</div> */}
        <Table 
          columns={tableColumns} 
          dataSource={tableData} 
          rowKey="companyCode"
          scroll={{ x: 1600, y: 540 }}
          pagination={{
            position: ['bottomCenter'],
            total: pagination.total,
            current: pagination.pageNum,
            pageSize: pagination.pageSize,
            showTotal:(total) =>  `共 ${total} 条数据`,
            onChange: (page, pageSize)　=> tablePageChange(page, pageSize)
          }}
        >
        </Table>
      </div>
    </Spin>
  )
}

export default PlatformRecord