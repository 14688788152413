import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import {
    Table,
    Space,
    message,
    Popconfirm,
    Button
} from 'antd'
import '../index.css'
import store from '../../../../../store/index'
import ViewMoel from './ViewMoel'
import EditMoel from './EditMoel'
import DeliverMoel from './DeliverMoel'
import FollowMoel from './FollowMoel'
import CaseMoel from './CaseMoel'
import NotesMoel from './NotesMoel'
import { securityEventList, securityEventDelete } from '../api'
const ReportTableList = forwardRef((props,ref)=> {
    let path = 'securityEvent'
    const [tableData, setTableData] = useState([])
    const ViewMoelRef = useRef();
    const EditMoelRef = useRef();
    const DeliverMoelRef = useRef();
    const FollowMoelRef = useRef();
    const CaseMoelRef = useRef();
    const NotesMoelRef = useRef();
    const [queryData, setQueryData] = useState({})
    const [ja] = useState(store.getState().userReducer.permissions.indexOf(`${path}:finish`)>-1)
    const [gj] = useState(store.getState().userReducer.permissions.indexOf(`${path}:follow`)>-1)
    const [xg] = useState(store.getState().userReducer.permissions.indexOf(`${path}:update`)>-1)
    const [zja] = useState(store.getState().userReducer.permissions.indexOf(`${path}:transform`)>-1)
    const [sc] = useState(store.getState().userReducer.permissions.indexOf(`${path}:delete`)>-1)
    let pageSize = 20
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: pageSize,
        total: 0
    })
    useImperativeHandle(ref, () => ({
        // getlist 就是暴露给父组件的方法
        getlist: (val) => {
            setQueryData(val)
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
            getTableLists({...pagination,pageNum: 1},val)
        }
    }));
    const onRefresh = ()=>{
        getTableLists(pagination)
    }
    const getTableLists = async ({pageNum, pageSize},QData=queryData) => {
        let result = await securityEventList(Object.assign({}, QData, {pageNum: pageNum, pageSize: pageSize, eventType: 1}))
        if (result.data.success) {
            setTableData(result.data.data.list)
            setPagination(prev => {
                return {
                    ...prev,
                    total: result.data.data.totalCount
                }
            })
        } else {
            message.error(result.data.retMsg)
        }
    }
    // 列表字段
    const tableColumns = [
        { title: '接报或上报时间', dataIndex: 'eventReportTime', key: 'eventReportTime', align: 'center', width: 130 },
        { title: '事件工单号', dataIndex: 'eventOrderNo', key: 'eventOrderNo', align: 'center', width: 170 },
        { title: '注册城市', dataIndex: 'cityName', key: 'cityName', align: 'center', width: 100 },
        { title: '报备人', dataIndex: 'reportOriginatorUserName', key: 'reportOriginatorUserName', align: 'center', width: 100 },
        { title: '事件分类', dataIndex: 'eventClassifyName', key: 'eventClassifyName', align: 'center', width: 120,},
        { title: '事件等级', dataIndex: 'eventLevel', key: 'eventLevel', align: 'center', width: 100 },
        { title: '骑手姓名', dataIndex: 'name', key: 'name', align: 'center', width: 100 },
        { title: '事发城市', dataIndex: 'eventCityName', key: 'eventCityName', align: 'center', width: 110 },
        { title: '费用支出金额', dataIndex: 'payAmount', key: 'payAmount', align: 'center', width: 180, render: (text, record) => { return record.payAmount?<span>￥{record.payAmount}</span>:''}},
        { title: '流程状态', dataIndex: 'processStatusName', key: 'processStatusName', align: 'center', width: 130, 
            render: (text, record) => { 
                if(record.dealStatus==4){
                    return text + '(转交安)'
                }else if(record.dealStatus==3){
                    return text + '(已结案)'
                }else{
                    return text
                }
            }
        },
        {
            title: '操作',
            key: 'control',
            fixed: 'right',
            width: 280,
            align: 'center',
            render: (record) => (
                <Space align="center" >
                    <Button className='botlink' type="link" key="1" onClick={() => handelView(record)}>查看</Button>
                    {(record.processStatus=='0'||record.processStatus=='4'||record.processStatus=='5')&&xg&&<Button className='botlink' type="link" key="2" onClick={() => handelModify(record)}>修改</Button>}
                    {record.processStatus=='3'&&record.dealStatus!=3&&record.dealStatus!=4&&zja&&<Button className='botlink' type="link" key="3" onClick={() => handelDeliver(record)}>转交安</Button>}
                    {record.processStatus=='3'&&record.dealStatus!=3&&record.dealStatus!=4&&gj&&<Button className='botlink' type="link" key="4" onClick={() => handelFollow(record)}>跟进</Button>}
                    {record.processStatus=='3'&&record.dealStatus!=3&&record.dealStatus!=4&&ja&&<Button className='botlink' type="link" key="5" onClick={() => handelCase(record)}>结案</Button>}
                    {record.processStatus=='3'&&<Button className='botlink' type="link" key="6" onClick={() => handelNotes(record)}>记录</Button>}
                    {record.processStatus=='0'&&sc&&<Popconfirm
                        title="是否删除?"
                        onConfirm={()=>{handelDelete(record)}}
                        okText="确定"
                        cancelText="取消"
                    >
                        <Button className='botlink' type="link" href="#">删除</Button>
                    </Popconfirm>}
                </Space>
            )
        }
    ]
    // 查看
    const handelView = async (record) => {
        ViewMoelRef.current.showModal(record);
    }
    // 修改
    const handelModify = async (record) => {
        EditMoelRef.current.editModal(record,1);
    }
    // 转交安
    const handelDeliver = async (record) => {
        DeliverMoelRef.current.showModal(record)
    }
    // 删除
    const handelDelete = async (record) => {
        let result = await securityEventDelete({ eventCode: record.eventCode })
        if (result.data.success) {
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
            message.success(result.data.retMsg)
            getTableLists({ pageSize: pageSize, pageNum: 1 })
        } else {
            message.error(result.data.retMsg)
        }
    }
     // 跟进
     const handelFollow = async (record) => {
        FollowMoelRef.current.showModal(record)
    }
     // 结案
     const handelCase = async (record) => {
        CaseMoelRef.current.showModal(record,'结案',1)
    }
     // 跟进记录
     const handelNotes = async (record) => {
        NotesMoelRef.current.showModal(record)
    }
    //分页改变
    const tablePageChange = (pageNum, pageSize) => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: pageNum
            }
        })
        getTableLists({ pageSize: pageSize, pageNum: pageNum })
    }
    return (
        <>
            <Table
                columns={tableColumns}
                dataSource={tableData}
                scroll={{ x: 'max-content', y: 450 }}
                rowKey={record => record.eventCode}
                pagination={{
                    position: ['bottomCenter'],
                    showSizeChanger: false,
                    total: pagination.total,
                    current: pagination.pageNum,
                    pageSize: pagination.pageSize,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => tablePageChange(pageNum, pageSize)
                }}
            />
            <ViewMoel ref={ViewMoelRef} />
            <EditMoel refresh={onRefresh} ref={EditMoelRef} />
            <FollowMoel refresh={onRefresh} ref={FollowMoelRef} />
            <DeliverMoel refresh={onRefresh} ref={DeliverMoelRef} />
            <CaseMoel refresh={onRefresh} ref={CaseMoelRef} />
            <NotesMoel ref={NotesMoelRef} />
        </>
    )
})
export default ReportTableList
