import { request } from '../../../../utils/fetch'

// 列表
export const abcEnterpriseGetList = (params)　=> {
    return request.requestGet('/platform/abcEnterpriseAccountConfig/getList', params)
}

// 明细
export const abcEnterpriseGetDetail = (params)　=> {
    return request.requestGet('/platform/abcEnterpriseAccountConfig/getDetail', params)
}

// 新增
export const insert = (data) => {
    return request.requestPost('/platform/abcEnterpriseAccountConfig/insert', data)
}
// 明细新增
export const insertCompany = (data) => {
    return request.requestPost('/platform/abcEnterpriseAccountConfig/insertCompany', data)
}

// 启停用
export const SetStatus = (data) => {
    return request.requestPost('/platform/abcEnterpriseAccountConfig/status', data)
}

// 查询余额
export const getCount = (params) => {
    return request.requestGet('/platform/abcEnterpriseAccountConfig/getCount', params)
}

// 获取验证码
export const getCode = (params) => {
    return request.requestGet('/platform/abcEnterpriseAccountConfig/getCode', params)
}

// 转账
export const transfer = (params) => {
    return request.requestPost('/platform/abcEnterpriseAccountConfig/transfer', params)
}

// 获取银行列表
export const getBankList = (params) => {
    return request.requestGet('/common/getBankInfo', params)
}