import React, { useImperativeHandle,forwardRef, useState, useEffect } from 'react'
import {Modal, Radio,Form,Input,Row,Col,Button,message} from 'antd';
import {trafficSafetyGetDetail,trafficSafetyFollow,getProvinceIdListByRegionalNo,getCityListByProvinceId} from '../../api'
import '../index.css'
const { TextArea } = Input;
const topCol = {
  span: 12,
}


const FollowUp = (props,ref) => {
  const { refresh } = props
//   const { cityData } = props
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('跟进');
  const [disabled, setDisabled] = useState(false);
  const [provinceList, setProvinceList] = useState([])
  const [cityList, setCityList] = useState([])
  const [loading,setLoading] = useState(false)
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList1: '',
    fileList2: '',
    fileList3: '',
    fileList4: '',
    fileList5: '',
    fileList6: '',
    fileList7: '',
    previewTitle: '',
  })
  const [form] = Form.useForm()
  const initialFormValues = {}
  
  const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU：重大刑事案件、涉及刑事或者治安案件，对公司品牌造成严重影响的</p>
      <p className="titp">P0：致人死亡或2人及以上重伤</p>
      <p className="titp">P1：致1人重伤</p>
      <p className="titp">P2：致人轻伤</p>
      <p className="titp">P3：致人轻微伤</p>
    </div>
  )
  useEffect(() => {
  }, [])
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (data, splist) => {
      form.resetFields()
      setVisible(true);
      trafficSafetyGetDetail({eventCode:data.eventCode,type:0}).then((res)=>{
        console.log(res);
        const result = res.data.data
        if(result.eventLevel=='P2'||result.eventLevel=='P3'){
          result.sendMsgFlag = 0
        }else{
          result.sendMsgFlag = 1
        }
        getCity()
        form.setFieldsValue(result)
        console.log(form.getFieldValue('eventProvinceName'));
      })
    }
  }));
  const getProvince = ()=>{
    getProvinceIdListByRegionalNo({
        regionalNo:form.getFieldsValue().regionalNo
    }).then((res)=>{
        setProvinceList(res.data.data)
    })
  }
  const getCity = ()=>{
    getCityListByProvinceId({
        regionalNo:form.getFieldsValue().regionalNo,
        provinceId:form.getFieldsValue().eventProvinceId
    }).then((res)=>{
        setCityList(res.data.data)
    })
  }
  const submit = ()=>{
    const formData = form.getFieldValue()
    form.validateFields().then((values)=>{
      setLoading(true)
      const query= {
        eventCode:formData.eventCode,
        followDescribe:formData.followDescribe,
        sendMsgFlag:formData.sendMsgFlag,
        address:formData.address
      }
      trafficSafetyFollow(query).then((res)=>{
        if(res.data.success){
          message.success('提交成功')
          setVisible(false)
          refresh('search')
        }else{
          message.error(res.data.retMsg)
        }
        setLoading(false)
      }).catch(()=>{
        setLoading(false)
      })
    }).catch(()=>{
      message.error('请完善表单')
    })
    
  }
  return (
    <>
      <Modal maskClosable={false}
        title={title}
        width='1200px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={!disabled?[
          <Button key="back" onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button
            key="link"
            type="primary" loading={loading}
            onClick={()=>{submit()}}
          >
            提交
          </Button>,
        ]:null}
      >
        <Form initialValues={initialFormValues} form={form} labelAlign='right' className='trafficSafetyTakeForm'>
          <Row className="margin-15">
            <Col {...topCol}>
              <Form.Item name="eventSourceName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件来源">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件工单号">
                <Input disabled={true} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name='eventTypeNames' label="事件类型" style={{ marginBottom: 0 }} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventLevel" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} tooltip={{ title: content, color: '#FFFFFF' }} label="事件等级">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="注册城市">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="staffTypeName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="人员类型">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事发城市">
                <div style={{display:'flex'}}>
                  <Input disabled={true} value={form.getFieldValue('eventProvinceName')} />
                  <span style={{margin:'0 10px'}}>—</span>
                  <Input disabled={true} value={form.getFieldValue('eventCityName')}/>
                </div>
              </Form.Item>
            </Col>
            
            <Col {...topCol}>
              <Form.Item name="name" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="骑手姓名">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eleCourierId" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="骑手ID">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="手机号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item  name='idNumber' labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="身份证号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventTime"
              labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事发时间">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderStatusName" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="骑手状态">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartyStatusName" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="三者状态" >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="sendMsgFlag" label="是否发送提醒" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <Radio.Group >
                  <Radio value={0}>不发送</Radio>
                  <Radio value={1}>发送</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="followDescribe" label="跟进描述" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right"  rules={[{ required: true, message: '请填写事件跟进描述' }]}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="address" label="地点" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" >
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Modal maskClosable={false}
          width={1000}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
        </Modal>
      </Modal>
    </>
  );
};
export default forwardRef(FollowUp)