import React,{useState,useEffect} from 'react'
import {
  Modal,
  Row,
  Table,
  Select,
  Input,
  Form,
  Tree,
  Button,
  message,
  Popconfirm,
  PageHeader,
  Space
 } from 'antd';
import qs from 'qs'
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
const FormItem = Form.Item;
const itemStyle = {
    width:'300px',
    marginRight:'15px'
}
const labelStyleObj = {
   labelCol:{ span: 9},
   wrapperCol:{ span: 15}
}

function ColonyRole(props) {
    let path = 'platform:ClusterRole'
    let removepath = 'platform:SysCompanyInfo:listRoleForCompany'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [searchForm] = Form.useForm();
    const [modelForm] = Form.useForm();
    const [tableData,setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [loading, setLoading] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [titleModel, setTitleModel] = React.useState();
    const [id, setId] = React.useState('');
    const [initialSearch, setInitialSearch] = React.useState({
        roleName:''
    });
    const [initialValues, setInitialValues] = React.useState({
        ksyword:''
    });
    const [treeData, setTreeData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);     //显示用
    const [parmKeys, setParmKeys] = useState([]);       //传给后台的
    
    const columns = [
        {
            title: '角色名称',
            dataIndex: 'roleName',
            key: 'roleName',
            align:'center',
            
        }, {
            title: '角色描述',
            dataIndex: 'remark',
            key: 'remark',
            align:'center',
            
        },{
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align:'center',
            render: (text, record) => (
                record.status?'启用':'停用'
            ),
            
        },
        {
        title: '操作',
        key: 'action',
        align:'center',
        render: (text, record) => (
            <Space size="middle">
                {store.getState().userReducer.permissions.indexOf(`${path}:update`)>-1&&<a onClick={() => {methodsFn.handleModify(record)}}>修改</a>}
                {
                    store.getState().userReducer.permissions.indexOf(`${removepath}:remove`)>-1&&(!record.status)
                    ?<Popconfirm
                    title="确定删除吗？"
                    onConfirm={() => {
                        methodsFn.handleDel(record)
                    }}
                    okText="确定"
                    cancelText="取消"
                    className="marginLeft"
                    >
                    <a>删除</a>
                    </Popconfirm>
                    :null
                }

             
                {store.getState().userReducer.permissions.indexOf(`${removepath}:status`)>-1&&<Popconfirm
                    title={`确定${record.status?'停用':'启用'}吗？`}
                    onConfirm={() => {
                        methodsFn.handleStatus(record)
                    }}
                    okText="确定"
                    cancelText="取消"
                    className="marginLeft"
                    >
                    <a>{record.status == 1?'停用':'启用'}</a>
                </Popconfirm>}
            </Space>
        ),
        },
    ];
    const methodsFn =  {
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(searchForm.getFieldsValue())
        },
        handleFormReset(){
            searchForm.resetFields()
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/clusterRole/getList',Object.assign(searchObj,
                {
                    companyCode:searchParam.companyCode || '',
                    pageSize:pageSize,
                    pageNum:pageNum,
                }
            ))
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        async getTreeList(){
            const result = await request.get('/platform/user/roleMenuTreeDataForCompany')
            setTreeData(result)
        },
        async handleOk(){
            try {
                const values = await modelForm.validateFields();
                setLoading(true);
                if(titleModel == '新增'){
                    methodsFn.handleAddSubmit(values)
                    return
                  }
                  methodsFn.handleModifySubmit(values)
                
              } catch (errorInfo) {
                console.log('Failed:', errorInfo);
              }
        },
        handleAddSubmit(obj){
            if(!checkedKeys || checkedKeys.length <1){
                message.error('请勾选菜单权限')
                setLoading(false)
                return
            }
            request.post('/platform/clusterRole/insert',{
                ...obj,
                menuIds:parmKeys,
                roleSort:1, //先写死
                systemType:1
            }).then(res => {
                setVisible(false);
                setLoading(false);
                message.success(res.msg)
                methodsFn.getTableList(searchForm.getFieldsValue())
                
            }).catch(err => setLoading(false))
          },
        handleAdd(){
            setTitleModel('新增')
            setVisible(true)
            modelForm.resetFields();
            setExpandedKeys([]);
            setCheckedKeys([]);

        },
        handleModifySubmit(obj){
            if(!checkedKeys || checkedKeys.length <1){
                message.error('请勾选菜单权限')
                setLoading(false)
                return
            }
            request.post('/platform/clusterRole/update',{
              ...obj,
              menuIds:parmKeys,
              id:id
            }).then(res => {
              setVisible(false);
              setLoading(false);
              message.success(res.msg)
              methodsFn.getTableList(searchForm.getFieldsValue())
              
            }).catch(err => setLoading(false))
          },
        handleModify(record){
            setTitleModel('修改')
            setVisible(true)
            setId(record.id)
            modelForm.setFieldsValue(record)
            request.get('/platform/user/detailRoleForCompany',{id:record.id}).then(res => {
                setCheckedKeys(res.viewMenuIds);
                setParmKeys(res.menuIds)     //给后台的
                setExpandedKeys(res.menuIds);
                console.log(res)
            })
            
        },
        handleDel(obj){
            request.get('/platform/user/removeRoleForCompany',{id:obj.id}).then(res => {
                message.success(res.msg)
                methodsFn.getTableList(searchForm.getFieldsValue())
                
            })
        },
        handleStatus(obj){
            let status;
            if(obj.status){
                status = 0
            }else{
                status = 1
            }
            request.post('/platform/user/changeStatusRoleForCompany',{
                id:obj.id,
                status:status,
            }).then(res => {
                methodsFn.handleSearch()
                message.success('操作成功')
            })
        },
        onCheck(checkedKeys, info) {
            let concatTreeData =  checkedKeys.concat(info.halfCheckedKeys)
            setCheckedKeys(checkedKeys);
            setParmKeys(concatTreeData)     //给后台的
            console.log('checkedKeys',checkedKeys)
            console.log('concatTreeData',concatTreeData)


             //过滤父节点
            // let checkedNodes = info.checkedNodes
            // let childrenCheck = checkedNodes.filter(item => !item.children)
            // let resultCheck = childrenCheck.map(item => item.key)
        },
        onExpand(expandedKeys) {
            setExpandedKeys(expandedKeys);
        },
    }
    useEffect(() => {
        methodsFn.getTableList(searchForm.getFieldsValue())

        // const listener = () => {    //页面刷新去掉参数，明细隐藏
        //     props.history.replace('/admin/rolePermission')
        // }
        // window.addEventListener('load',listener)
        // return () => {
        //     window.removeEventListener('load',listener)      //卸载
        // }
    
    },[pageNum])

    useEffect(() => {
        methodsFn.getTreeList()
    },[])

    return (
        <div>
            {
               searchParam.detail
                    ?<PageHeader
                    className="site-page-header"
                    onBack={() => {props.history.goBack()}}
                    title="明细"
                    subTitle={'公司名称：'+searchParam.companyName}
                />
                :null
            }
             <Form 
                initialValues={initialSearch}
                form={searchForm}
            >
                <Row>
                    <FormItem label="关键字" name="roleName" style={itemStyle} {...labelStyleObj}>
                        <Input placeholder="角色名称"  />
                    </FormItem>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:add`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleAdd}>新增角色</Button>}
                    </FormItem>
                </Row>
                   
            </Form>
            
            <Table 
                scroll={{ x: 'max-content',y:500 }}
                columns={columns} 
                rowKey={(record) => record.id}
                dataSource={tableData} 
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger:false,
                    showTotal:(total) =>  `共 ${total} 条数据` ,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
           />
            <Modal maskClosable={false}
                forceRender
                title={titleModel}
                width='800px'
                visible={visible}
                onOk={methodsFn.handleOk}
                confirmLoading={loading}
                onCancel={() => {setVisible(false);}}
            >
                <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 10 }}
                initialValues={initialValues}
                form={modelForm}
                >
                    <FormItem 
                        name="roleName"
                        label="角色名称"
                        rules={[
                        {
                            required: true,
                            message: '请填写角色名称',
                        },
                        ]}
                        >
                        <Input maxLength="20" placeholder="请填写角色名称"/>
                    </FormItem>
                    <FormItem name="remark" label="角色描述" >
                        <Input maxLength="20" placeholder="请填写角色描述"/>
                    </FormItem>
                    <FormItem name="menuIds"
                        label="菜单权限"
                    >
                       <Tree
                        checkable
                        // defaultExpandedKeys={["0-0-0-0", "0-0-0-1", "0-0-0", "0-0"]}
                        // defaultSelectedKeys={['0-0-0-0', '0-0-0-1']}
                        // defaultCheckedKeys={["0-0-0-0", "0-0-0-1", "0-0-0", "0-0"]}
                        onExpand={methodsFn.onExpand}
                        expandedKeys={expandedKeys}
                        checkedKeys={checkedKeys}
                        onCheck={methodsFn.onCheck}
                        treeData={treeData}
                        />
                    </FormItem>
                </Form>
            </Modal>
        </div>
    )
}

export default ColonyRole
