import React,{useImperativeHandle,forwardRef} from 'react'
import { 
  Modal,
  Table,
  Input,
  Space,
  message,
  Row,
  Col,
} from 'antd';
import {request} from '../../../../utils/fetch'

const { TextArea } = Input;
const topCol = {
  span:8,
}
const endCol = {
  span:8,
  offset:6
}

const MyModel3 = (props,ref) => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [money, setMoney] = React.useState(0);
  const [auditContent, setAuditContent] = React.useState('');

  

   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (val) => {
        setVisible(val);
        setMoney('')
        setAuditContent('')
       
      }
  }));
  const columns = [
    {
        title: '打款时间',
        dataIndex: 'paymentTime',
        key: 'paymentTime',
    }, {
        title: '企业名称',
        dataIndex: 'companyCodeName',
        key: 'companyCodeName',
    },{
        title: '佣金',
        dataIndex: 'commissionAmount',
        key: 'commissionAmount',
    },
  ];

  const handleOk = () => {
      setLoading(true)
      if(props.titleModel == '通过'){
         request.post('/platform/billAudit/batchPass',{
            billDetailNoList:props.detailObj.billDetailNo,
            addAmount:money,
            auditContent:auditContent,
          }).then(res => {
            setVisible(false);
            setLoading(false);
            message.success('操作成功')
            props.refresh()
          }).catch(err =>  setLoading(false))
      }else{
         request.post('/platform/billAudit/batchReject',{
          billDetailNoList:props.detailObj.billDetailNo,
          auditContent:auditContent,
        }).then(res => {
          setVisible(false);
          setLoading(false);
          message.success('操作成功')
          props.refresh()
        }).catch(err => setLoading(false))

      }
    }


  return (
    <>
      <Modal maskClosable={false}
        title={'审批'+props.titleModel}
        width='1000px'
        visible={visible}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={() => {setVisible(false)}}
      >
      <Row className="margin-15">
        <Col {...topCol}>
          <div className="flex">
            <span className="left-span">进入审核时间:</span>
            <Input placeholder={props.detailObj.createTime} disabled={true} />
          </div>
        </Col>
        <Col {...endCol}>
          <div className="flex">
            <span className="left-span">落地城市:</span>
            <Input placeholder={props.detailObj.cityName} disabled={true} />
          </div>
        </Col>
      </Row>
      <Row className="margin-15">
        <Col {...topCol}>
          <div className="flex">
            <span className="left-span">企业名称:</span>
            <Input placeholder={props.detailObj.companyCodeName} disabled={true} />
          </div>
        </Col>
        <Col {...endCol}>
          <div className="flex">
            <span className="left-span">落地方案:</span>
            <Input placeholder={props.detailObj.planTypeName}  disabled={true} />
          </div>
        </Col>
      </Row>
      <Row className="margin-15">
        <Col {...topCol}>
          <div className="flex">
            <span className="left-span">账单月份:</span>
            <Input placeholder={props.detailObj.month} disabled={true} />
          </div>
        </Col>
        <Col {...endCol}>
          <div className="flex">
            <span className="left-span">姓名:</span>
            <Input placeholder={props.detailObj.name} disabled={true} />
          </div>
        </Col>
      </Row>
      <Row className="margin-15">
        <Col {...topCol}>
          <div className="flex">
            <span className="left-span">身份证号:</span>
            <Input placeholder={props.detailObj.idNumber} disabled={true} />
          </div>
        </Col>
        <Col {...endCol}>
          <div className="flex">
            <span className="left-span">佣金:</span>
            <Input placeholder={props.detailObj.commissionAmount} disabled={true} />
          </div>
        </Col>
      </Row>
      <Row className="margin-15">
        <Col {...topCol}>
          <div className="flex">
            <span className="left-span">税费:</span>
            <Input placeholder={props.detailObj.taxAmount} disabled={true} />
          </div>
        </Col>
        <Col {...endCol}>
          <div className="flex">
            <span className="left-span">服务费:</span>
            <Input placeholder={props.detailObj.serviceAmount} disabled={true} />
          </div>
        </Col>
      </Row>
      <Row className="margin-15">
        <Col {...topCol}>
          <div className="flex">
            <span className="left-span">进入审核的原因:</span>
            <Input placeholder={props.detailObj.auditReasonName} disabled={true} />
          </div>
        </Col>
        <Col {...endCol}>
          <div className="flex">
            <span className="left-span">打款备注:</span>
            <Input placeholder={props.detailObj.content} disabled={true} />
          </div>
        </Col>
      </Row>
      {
         props.detailObj.auditReason.indexOf(2) > -1
        ? <div>
          <h3>当月累计打款记录</h3>
          <Table 
              scroll={{ x: 'max-content' }}
              columns={columns} 
              rowKey={(record) => record.companyCode}
              dataSource={props.detailObj.paymentList} 
            />
        </div>
        :null
      }
      
        <h3>审核{props.titleModel}</h3>
        <div>
          {
            props.titleModel === '通过'
              ?<Row className="margin-15">
                <Col {...topCol}>
                  <div className="flex">
                    <span className="left-span">补缴服务费:</span>
                    <Input placeholder="请填写" value={money} onChange={(e) => setMoney(e.target.value) }/>
                  </div>
                </Col>
              </Row>
              :null
          }
          <div style={{"marginLeft":55}}>
            <Space align="center" >
              审核描述:
              <TextArea cols="100" placeholder="请填写" value={auditContent} showCount allowClear maxLength={500} onChange={(e) => setAuditContent(e.target.value) } />
            </Space>
          </div>
            
          
        </div>
      </Modal>
    </>
  );
};
export default forwardRef(MyModel3)



