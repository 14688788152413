import React, { useState, useEffect } from 'react'
import { Row, Table, Tabs, Form, Input, Button, message, PageHeader } from 'antd'
import qs from 'qs'
import { getTianJinDataDetailPreList } from '../../../api'
const FormItem = Form.Item
const { TabPane } = Tabs
const itemStyle = {
    width: '300px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 }
}

function Preview(props) {
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g, ''))
    const tabName = ['骑手', '订单', '申报', '个税汇总', '发票', '个税明细'][searchParam.type - 3]
    let pltext = ''
    if (tabName === '骑手' || tabName === '订单' || tabName === '申报' || tabName === '个税明细') {
        pltext = '姓名丨身份证号'
    } else if (tabName === '发票') {
        pltext = '发票号码丨发票代码'
    } else {
        pltext = ''
    }
    const [form] = Form.useForm()
    const initSearch = {
        keyWord: undefined
    }
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [nowTab, setNowTab] = useState('1')
    const [pl, setPl] = useState(pltext)
    const [pageNum, setPageNum] = useState(1)
    const c0 = [
        {
            title: '骑手ID',
            dataIndex: 'fwfuuid',
            width: 150,
            align: 'center'
        },
        {
            title: '服务方姓名',
            dataIndex: 'fwfxm',
            width: 150,
            align: 'center'
        },
        {
            title: '身份证件类型',
            dataIndex: 'fwfsfzjlx',
            width: 150,
            align: 'center'
        },
        {
            title: '身份证件号码',
            dataIndex: 'fwfsfzjhm',
            width: 150,
            align: 'center'
        },
        {
            title: '手机号码',
            dataIndex: 'yddh',
            width: 150,
            align: 'center'
        },
        {
            title: '常住地区',
            dataIndex: 'czdq',
            width: 150,
            align: 'center'
        },
        {
            title: '服务方所属地区',
            dataIndex: 'ssdq',
            width: 150,
            align: 'center'
        },
        {
            title: '服务方在平台注册时间',
            dataIndex: 'ptzcsj',
            width: 150,
            align: 'center'
        },
        {
            title: '主要提供的业务类别',
            dataIndex: 'zytgywlb',
            width: 150,
            align: 'center'
        }
    ]
    const c1 = [
        {
            title: '骑手id',
            dataIndex: 'fwfuuid',
            width: 150,
            align: 'center'
        },
        {
            title: '骑手姓名',
            dataIndex: 'fwfxm',
            width: 150,
            align: 'center'
        },
        {
            title: '骑手身份证号',
            dataIndex: 'fwfsfzjhm',
            width: 150,
            align: 'center'
        },
        {
            title: '订单总数',
            dataIndex: 'fwfjsds',
            width: 150,
            align: 'center'
        },
        {
            title: '结算总金额',
            dataIndex: 'fbfddje',
            width: 150,
            align: 'center'
        },
        {
            title: '实际结算到手金额',
            dataIndex: 'fwfddje',
            width: 150,
            align: 'center'
        },
        {
            title: '平台合计扣款',
            dataIndex: 'ptfwf',
            width: 150,
            align: 'center'
        },
        {
            title: '订单最终结算时间',
            dataIndex: 'jssj',
            width: 150,
            align: 'center'
        },
        {
            title: '收款人姓名',
            dataIndex: 'skrxm',
            width: 150,
            align: 'center'
        },
        {
            title: '收款人身份证件号码',
            dataIndex: 'skrsfzjhm',
            width: 150,
            align: 'center'
        },
        {
            title: '服务方支付方式',
            dataIndex: 'fwfzffs',
            width: 150,
            align: 'center'
        },
        {
            title: '银行流水',
            dataIndex: 'yhls',
            width: 150,
            align: 'center'
        },
        {
            title: '结算账户',
            dataIndex: 'yhkh',
            width: 150,
            align: 'center'
        }
    ]
    const c11 = [
        {
            title: '账单时间',
            dataIndex: 'zdsj',
            width: 150,
            align: 'center'
        },
        {
            title: '团队ID',
            dataIndex: 'tdid',
            width: 150,
            align: 'center'
        },
        {
            title: '团队名称',
            dataIndex: 'tdmc',
            width: 150,
            align: 'center'
        },
        {
            title: '骑手ID',
            dataIndex: 'fwfuuid',
            width: 150,
            align: 'center'
        },
        {
            title: '骑手名称',
            dataIndex: 'fwfxm',
            width: 150,
            align: 'center'
        },
        {
            title: '配送状态',
            dataIndex: 'pszt',
            width: 150,
            align: 'center'
        },
        {
            title: '订单号',
            dataIndex: 'ddh',
            width: 150,
            align: 'center'
        },
        {
            title: '运单号',
            dataIndex: 'ydh',
            width: 150,
            align: 'center'
        },
        {
            title: '业务交易时间',
            dataIndex: 'ywjysj',
            width: 150,
            align: 'center'
        },
        {
            title: '订单来源',
            dataIndex: 'ddly',
            width: 150,
            align: 'center'
        },
        {
            title: '标品类型',
            dataIndex: 'bplx',
            width: 150,
            align: 'center'
        },
        {
            title: '门店ID',
            dataIndex: 'mdid',
            width: 150,
            align: 'center'
        },
        {
            title: '门店名称',
            dataIndex: 'mdmc',
            width: 150,
            align: 'center'
        },
        {
            title: '结算总金额',
            dataIndex: 'jszje',
            width: 150,
            align: 'center'
        },
        {
            title: '实际结算到手金额',
            dataIndex: 'sjje',
            width: 150,
            align: 'center'
        },
        {
            title: '平台合计扣款',
            dataIndex: 'hjje',
            width: 150,
            align: 'center'
        }
    ]
    const c2 = [
        {
            title: '骑手id',
            dataIndex: 'fwfuuid',
            width: 150,
            align: 'center'
        },
        {
            title: '被代征单位纳税人识别号',
            dataIndex: 'zjhm',
            width: 150,
            align: 'center'
        },
        {
            title: '被代征单位纳税人名称',
            dataIndex: 'bdzdwnsrmc',
            width: 150,
            align: 'center'
        },
        {
            title: '被代征船舶识别号',
            dataIndex: 'bdzcbsbh',
            width: 150,
            align: 'center'
        },
        {
            title: '被代征船舶名称',
            dataIndex: 'bdzcbmc',
            width: 150,
            align: 'center'
        },
        {
            title: '国家或地区',
            dataIndex: 'gjhdq',
            width: 150,
            align: 'center'
        },
        {
            title: '证件类型',
            dataIndex: 'zjlx',
            width: 150,
            align: 'center'
        },
        {
            title: '证件号码',
            dataIndex: 'zjhm',
            width: 150,
            align: 'center'
        },
        {
            title: '所属行业',
            dataIndex: 'sshy',
            width: 150,
            align: 'center'
        },
        {
            title: '征收项目',
            dataIndex: 'zsxm',
            width: 150,
            align: 'center'
        },
        {
            title: '征收品目',
            dataIndex: 'zspm',
            width: 150,
            align: 'center'
        },
        {
            title: '征收子目',
            dataIndex: 'zszm',
            width: 150,
            align: 'center'
        },
        {
            title: '税款所属期起',
            dataIndex: 'skssqq',
            width: 150,
            align: 'center'
        },
        {
            title: '税款所属期止',
            dataIndex: 'skssqz',
            width: 150,
            align: 'center'
        },
        {
            title: '计税依据',
            dataIndex: 'jsyj',
            width: 150,
            align: 'center'
        },
        {
            title: '税率或税额',
            dataIndex: 'slhse',
            width: 150,
            align: 'center'
        },
        {
            title: '应纳税额',
            dataIndex: 'ynse',
            width: 150,
            align: 'center'
        },
        {
            title: '减免税额',
            dataIndex: 'jmse',
            width: 150,
            align: 'center'
        },
        {
            title: '已缴税额',
            dataIndex: 'yjse',
            width: 150,
            align: 'center'
        },
        {
            title: '应代征税额',
            dataIndex: 'ydzse',
            width: 150,
            align: 'center'
        },
        {
            title: '已代征税额',
            dataIndex: 'sdzse',
            width: 150,
            align: 'center'
        },
        {
            title: '税源标志',
            dataIndex: 'sybz',
            width: 150,
            align: 'center'
        },
        {
            title: '税源编号',
            dataIndex: 'sybh',
            width: 150,
            align: 'center'
        },
        {
            title: '税源坐落',
            dataIndex: 'syzl',
            width: 150,
            align: 'center'
        },
        {
            title: '减免性质',
            dataIndex: 'jmxz',
            width: 150,
            align: 'center'
        }
    ]
    const c3 = [
        {
            title: '税款所属期起',
            dataIndex: 'skssqq',
            width: 150,
            align: 'center'
        },
        {
            title: '税款所属期止',
            dataIndex: 'skssqz',
            width: 150,
            align: 'center'
        },
        {
            title: '申报日期',
            dataIndex: 'sbrq',
            width: 150,
            align: 'center'
        },
        {
            title: '扣缴义务人识别号（平台企业-纳税人社会信用代码）',
            dataIndex: 'nsrsbh',
            width: 150,
            align: 'center'
        },
        {
            title: '扣缴义务人名称（平台企业-纳税人名称）',
            dataIndex: 'nsrmc',
            width: 150,
            align: 'center'
        },
        {
            title: '税率',
            dataIndex: 'sl',
            width: 150,
            align: 'center'
        },
        {
            title: '申报人数',
            dataIndex: 'sbrs',
            width: 150,
            align: 'center'
        },
        {
            title: '应税收入',
            dataIndex: 'yssr',
            width: 150,
            align: 'center'
        },
        {
            title: '应纳税额',
            dataIndex: 'ynse',
            width: 150,
            align: 'center'
        },
        {
            title: '累计已缴纳税额',
            dataIndex: 'ljyjnse',
            width: 150,
            align: 'center'
        },
        {
            title: '本期应补退税额',
            dataIndex: 'bqybtse',
            width: 150,
            align: 'center'
        }
    ]
    const c4 = [
        {
            title: '购货单位名称',
            dataIndex: 'gfmc',
            width: 150,
            align: 'center'
        },
        {
            title: '购货单位统一社会信用代码',
            dataIndex: 'gfsh',
            width: 150,
            align: 'center'
        },
        {
            title: '价税合计',
            dataIndex: 'jshjxx',
            width: 150,
            align: 'center'
        },
        {
            title: '货物名称',
            dataIndex: 'hwmc',
            width: 150,
            align: 'center'
        },
        {
            title: '发票代码',
            dataIndex: 'fpdm',
            width: 150,
            align: 'center'
        },
        {
            title: '发票号码',
            dataIndex: 'fphm',
            width: 150,
            align: 'center'
        },
        {
            title: '开票日期',
            dataIndex: 'kpsj',
            width: 150,
            align: 'center'
        },
        {
            title: '骑手id',
            dataIndex: 'm_id',
            width: 150,
            align: 'center'
        },
        {
            title: '发票类型ID',
            dataIndex: 'fplx_id',
            width: 150,
            align: 'center'
        },
        {
            title: '发票所属月份',
            dataIndex: 'fpssyf',
            width: 150,
            align: 'center'
        },
        {
            title: '有效标志',
            dataIndex: 'yxbz',
            width: 150,
            align: 'center'
        }
    ]
    const c5 = [
        {
            title: '纳税人识别号（平台企业）',
            dataIndex: 'nsrsbh',
            width: 150,
            align: 'center'
        },
        {
            title: '纳税人名称（平台企业）',
            dataIndex: 'nsrmc',
            width: 150,
            align: 'center'
        },
        {
            title: '行业',
            dataIndex: 'hy',
            width: 150,
            align: 'center'
        },
        {
            title: '行政区划',
            dataIndex: 'xzqh',
            width: 150,
            align: 'center'
        },
        {
            title: '街道乡镇',
            dataIndex: 'jdxz',
            width: 150,
            align: 'center'
        },
        {
            title: '税务机关',
            dataIndex: 'swjg',
            width: 150,
            align: 'center'
        },
        {
            title: '骑手id',
            dataIndex: 'fwfuuid',
            width: 150,
            align: 'center'
        },
        {
            title: '姓名',
            dataIndex: 'xm',
            width: 150,
            align: 'center'
        },
        {
            title: '身份证件类型',
            dataIndex: 'sfzjlx',
            width: 150,
            align: 'center'
        },
        {
            title: '身份证件号码',
            dataIndex: 'sfzjhm',
            width: 150,
            align: 'center'
        },
        {
            title: '国籍（地区）',
            dataIndex: 'gjdq',
            width: 150,
            align: 'center'
        },
        {
            title: '联系电话',
            dataIndex: 'lxdh',
            width: 150,
            align: 'center'
        },
        {
            title: '生产经营地行政区划',
            dataIndex: 'scjydxzqh',
            width: 150,
            align: 'center'
        },
        {
            title: '税款所属期起',
            dataIndex: 'skssqq',
            width: 150,
            align: 'center'
        },
        {
            title: '税款所属期止',
            dataIndex: 'skssqz',
            width: 150,
            align: 'center'
        },
        {
            title: '申报日期',
            dataIndex: 'sbrq',
            width: 150,
            align: 'center'
        },
        {
            title: '应税收入',
            dataIndex: 'yssr',
            width: 150,
            align: 'center'
        },
        {
            title: '应税所得率',
            dataIndex: 'yssdl',
            width: 150,
            align: 'center'
        },
        {
            title: '计税依据',
            dataIndex: 'jsyj',
            width: 150,
            align: 'center'
        },
        {
            title: '税率',
            dataIndex: 'sl',
            width: 150,
            align: 'center'
        },
        {
            title: '速算扣除数',
            dataIndex: 'sskcs',
            width: 150,
            align: 'center'
        },
        {
            title: '应纳税额',
            dataIndex: 'ynse',
            width: 150,
            align: 'center'
        },
        {
            title: '累计已缴纳税额',
            dataIndex: 'ljyjse',
            width: 150,
            align: 'center'
        },
        {
            title: '本期应补退税额',
            dataIndex: 'bqybtse',
            width: 150,
            align: 'center'
        }
    ]

    //组件渲染函数
    const myComponent = {
        totalTxt() {
            return (
                <>
                    <div style={{ backgroundColor: '#fff' }}>
                        <PageHeader onBack={() => props.history.goBack()} title='预览' subTitle={tabName} />
                    </div>
                    {tabName === '订单' && (
                        <Tabs className='tianJinDataTabs' size='large' defaultActiveKey='1' onChange={methodsFn.TabChange} style={{ backgroundColor: '#fff' }}>
                            <TabPane tab='订单汇总' key='1' />
                            <TabPane tab='订单明细' key='2' />
                        </Tabs>
                    )}
                </>
            )
        },
        renderSearchForm() {
            return (
                <Form form={form} initialValues={initSearch} {...labelStyleObj}>
                    <Row>
                        <FormItem label='关键字' name='keyword' style={itemStyle}>
                            <Input placeholder={pl} disabled={tabName === '个税汇总'} />
                        </FormItem>
                        <Row style={{ marginLeft: 50 }}>
                            <Button onClick={methodsFn.handleFormReset} disabled={tabName === '个税汇总'}>
                                重置
                            </Button>
                            <Button style={{ marginLeft: 10 }} type='primary' onClick={methodsFn.handleSearch} disabled={tabName === '个税汇总'}>
                                查询
                            </Button>
                        </Row>
                    </Row>
                </Form>
            )
        }
    }
    //方法函数
    const methodsFn = {
        handleSearch() {
            setPageNum(1)
            if (pageNum == 1) {
                methodsFn.getTableList(nowTab)
            }
        },
        handleFormReset() {
            form.resetFields()
        },
        async getTableList(nowTab = 1) {
            setLoading(true)
            let formData = form.getFieldsValue()
            console.log(nowTab)
            getTianJinDataDetailPreList({
                pageSize: pageSize,
                pageNum: pageNum,
                type: searchParam.type,
                importCode: searchParam.importCode,
                subType: nowTab,
                keyword: formData.keyword
            })
                .then(({ data: { data, success, retMsg } }) => {
                    if (success) {
                        setTotal(data.totalCount)
                        setTableData(data.list)
                    } else {
                        message.error(retMsg)
                    }
                    setLoading(false)
                })
                .catch(() => setLoading(false))
        },
        TabChange(tabKey) {
            setTableData([])
            setNowTab(tabKey)
            methodsFn.getTableList(tabKey)
            tabKey === '1' ? setPl('姓名丨身份证号') : setPl('姓名丨骑手id')
        }
    }

    useEffect(() => {
        methodsFn.getTableList(nowTab)
    }, [pageNum])

    return (
        <>
            {myComponent.totalTxt()}
            {myComponent.renderSearchForm()}
            <Table
                loading={loading}
                scroll={{ x: 'max-content', y: tabName === '订单' ? 450 : 520 }}
                columns={nowTab === '1' ? [c0, c1, c2, c3, c4, c5][searchParam.type - 3] : c11}
                rowKey={record => (nowTab === '1' ? record.id : record.ydh)}
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: total => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
            />
        </>
    )
}

export default Preview
