// 订单列表
import React, { useState} from 'react'
import { Table, Spin } from 'antd'
import QueryForm from './components/QueryForm'
import moment from 'moment'
import { ddcOrderInfoGetList } from '../api'
function OrderList() {
    const [tableData, setTableData] = useState([])
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)

    const refresh = (val,params) => {
        switch (val) {
            case 'query':
                getTableLists(params)
                break;
            default:
        }
    }
    
    const getTableLists = async (record) => {
        let data ={
            orderNo: record.orderNo,
            stores: record.stores, 
            phone: record.phone, 
            name: record.name, 
            payModel: record.payModel, 
            orderStatus: record.orderStatus, 
            beginReturnTime: record.hcsj ? moment(record.hcsj[0]).format('YYYY-MM-DD HH:mm:ss') : '',
            endReturnTime: record.hcsj ? moment(record.hcsj[1]).format('YYYY-MM-DD HH:mm:ss') : '',
            beginOverTime: record.jssj ? moment(record.jssj[0]).format('YYYY-MM-DD HH:mm:ss') : '',
            endOverTime: record.jssj ? moment(record.jssj[1]).format('YYYY-MM-DD HH:mm:ss') : '',
            beginTime: record.cjsj ? moment(record.cjsj[0]).format('YYYY-MM-DD') : '',
            endTime: record.cjsj ? moment(record.cjsj[1]).format('YYYY-MM-DD') : '',
            pageSize: pageSize,
            pageNum: pageNum
        }
        console.log(data)
        ddcOrderInfoGetList(data).then((res)=>{
            console.log(res)
            if(res.data.success){
                setTableData(res.data.data.list)
                setTotal(res.data.data.totalCount)
            }
        })
    }
    const getTableData = (num)=>{
        setPageNum(num)
    }
    // 列表字段
    const tableColumns = [
        { title: '序号', align: 'center', width: 60,render:(text, record, index)=>{
            return pageSize*(pageNum-1)+index+1
        }},
        { title: '订单号', dataIndex: 'orderNo', key: 'orderNo', align: 'center', width: 170 },
        { title: '门店', dataIndex: 'stores', key: 'stores', align: 'center', width: 240 },
        { title: '手机号', dataIndex: 'phone', key: 'phone', align: 'center', width: 120 },
        { title: '姓名', dataIndex: 'name', key: 'name', align: 'center', width: 110 },
        { title: '车架号', dataIndex: 'carShelfNumber', key: 'carShelfNumber', align: 'center', width: 190 },
        { title: '付费模式', dataIndex: 'payModel', key: 'payModel', align: 'center', width: 110 },
        { title: '月租金', dataIndex: 'monthRent', key: 'monthRent', align: 'center', width: 110 },
        { title: '总租期(不含续租)', dataIndex: 'totalPeriod', key: 'totalPeriod', align: 'center', width: 140 },
        { title: '已还期数', dataIndex: 'returnPeriod', key: 'returnPeriod', align: 'center', width: 110 },
        { title: '最大逾期天数', dataIndex: 'maxOverdueDay', key: 'maxOverdueDay', align: 'center', width: 120 },
        { title: '超期使用天数', dataIndex: 'overDay', key: 'overDay', align: 'center', width: 120 },
        { title: '押金方式', dataIndex: 'depositType', key: 'depositType', align: 'center', width: 110 },
        { title: '押金金额', dataIndex: 'depositAmount', key: 'depositAmount', align: 'center', width: 110 },
        { title: '订单状态', dataIndex: 'orderStatus', key: 'orderStatus', align: 'center', width: 110 },
        { title: '创建时间', dataIndex: 'orderCreateTime', key: 'orderCreateTime', align: 'center', width: 110 },
        { title: '结束时间', dataIndex: 'overTime', key: 'overTime', align: 'center', width: 110 },
        { title: '还车时间', dataIndex: 'returnTime', key: 'returnTime', align: 'center', width: 110 },
    ]
    return (
        <Spin spinning={false}>
            <QueryForm refresh={refresh} pageNum={pageNum} setPageNum={setPageNum} />
            <Table 
            columns={tableColumns} 
            dataSource={tableData} 
            scroll={{ x: 'max-content', y: 520 }} 
            rowKey={(record) => record.id}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger: false,
                showTotal: (total) => `共 ${total} 条数据`,
                onChange: (num, pageSize) => getTableData(num),
            }} />
        </Spin>
    )
}
export default OrderList
