// 线上明细
import React,{useState,useEffect} from 'react'
import {
  Row,
  Table,
  Select,
  Form,
  Input,
  Button,
  PageHeader
 } from 'antd';
import { withRouter } from "react-router-dom";
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
const { Option } = Select;
 const FormItem = Form.Item;
 const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }


function SettleQueryDetail(props) {
    let path = 'platform:Settlement'
    const [form] = Form.useForm();
    const {parmObj} = props
    const [tableData,setTableData] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
     
    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align:'center',
            
        }, {
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align:'center',
            
        },{
            title: '个人佣金（元）',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            width: 150,
            align:'center',
            
        },
        {
            title: '佣金状态',
            dataIndex: 'paymentStatusName',
            key: 'paymentStatusName',
            width: 150,
            align:'center',
            
        }, {
            title: '服务费（元）',
            dataIndex: 'serviceAmount',
            key: 'serviceAmount',
            width: 150,
            align:'center',
            
        },  {
            title: '税费（元）',
            dataIndex: 'taxAmount',
            key: 'taxAmount',
            width: 150,
            align:'center',
        },
        {
            title: '退回状态',
            dataIndex: 'backFlag',
            key: 'backFlag',
            width: 150,
            align:'center',
            render: (text) => {
                if(text){
                    return '已退回'
                }else{
                    return '未退回'
                }
            },
        },
        {
            title: '失败原因',
            dataIndex: 'failDesc',
            key: 'failDesc',
            width: 150,
            align:'center',
        }
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} >
                    <Row>
                        <FormItem label="关键字" name="keyword"  style={itemStyle} {...labelStyleObj}>
                            <Input placeholder="姓名丨身份证号" />
                        </FormItem>
                        {/* <FormItem label="金额类型" name="type"  style={itemStyle} {...labelStyleObj}>
                            <Select>
                                <Option value={1}>个人佣金</Option>
                                <Option value={2}>服务费</Option>
                                <Option value={3}>税点</Option>
                            </Select>
                        </FormItem> */}
                        <FormItem label="佣金状态" name="paymentStatus"  style={itemStyle} {...labelStyleObj}>
                            <Select>
                                <Option value={'YZF'}>已支付</Option>
                                <Option value={'ZFSB'}>支付失败</Option>
                                <Option value={'FQ'}>放弃支付</Option>
                                <Option value={'WZF'}>未支付</Option>
                            </Select>
                        </FormItem>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                            <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        </FormItem>
                    </Row>
                    {/* <div>本次结算拆分为3次支付</div> */}
                   <div style={{display: 'flex',}} >
                     <div>佣金支付时间：{parmObj.paymentTime1||parmObj.paymentTime2}</div>
                     <div style={{marginLeft: '40px'}} >服务费税费支付时间：{parmObj.servicePaymentTime}</div>
                   </div>
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        getsearchObj(){
            return (
                {
                    // type:form.getFieldsValue().type,
                    paymentStatus:form.getFieldsValue().paymentStatus,
                    keyword:form.getFieldsValue().keyword,
                    settlementNo:parmObj.settlementNo,
                    lineType:parmObj.lineType,
                }
            )
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/settlement/getDetailList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        handleExport(){
            request.downExport('/platform/settlement/exportDetail',methodsFn.getsearchObj())
        }
   
    }

    useEffect(() => {
       
        methodsFn.getTableList(methodsFn.getsearchObj())
    
    },[pageNum])

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={props.callFn}
                title="线上明细"
                subTitle=""
            />

            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content',y: 500 }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.idNumber}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />

         
        </div>
    )
}

export default withRouter(SettleQueryDetail)
