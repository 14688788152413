import React, { useState, useEffect } from 'react'
import { Row, Table, Select, Form, Button, message, DatePicker, Modal, Space, Typography } from 'antd'
import { getTianJinDataList, tianJinDataInsert } from '../api'
import store from '../../../../store/index'
const { Link } = Typography
const FormItem = Form.Item
const { Option } = Select

const itemStyle = {
    width: '360px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 }
}
const formItemLayout = {
    labelCol: {
        span: 6
    },
    wrapperCol: {
        span: 14
    }
}

function TianJinData(props) {
    let path = 'platform:dj_import'
    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    // 初始化搜索数据
    const initSearch = {
        month: null,
        status: undefined,
        type: undefined
    }

    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const [tableData, setTableData] = useState([])
    const [visible, setVisible] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const columns = [
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
            width: 100
        },
        {
            title: '月份',
            dataIndex: 'month',
            align: 'center',
            width: 100
        },
        {
            title: '业务类型',
            dataIndex: 'type',
            align: 'center',
            width: 100,
            render: type => (type === 1 ? '直营' : '众包')
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            width: 100,
            render: status => (status === 1 ? '待处理' : '提交成功')
        },
        {
            title: '操作',
            align: 'center',
            key: 'action',
            width: 100,
            render: (text, record) => <Space size='middle'>{<Link onClick={() => methodsFn.goDetail(record)}>明细</Link>}</Space>
        }
    ]

    //组件渲染函数
    const myComponent = {
        renderSearchForm() {
            return (
                <Form form={form} initialValues={initSearch} {...labelStyleObj}>
                    <Row>
                        <FormItem label='月份' name='month' style={itemStyle}>
                            <DatePicker picker='month' placeholder='全部' allowClear />
                        </FormItem>
                        <FormItem label='业务类型' name='type' style={itemStyle}>
                            <Select allowClear placeholder='全部'>
                                <Option value='1'>直营</Option>
                                <Option value='2'>众包</Option>
                            </Select>
                        </FormItem>
                        <FormItem label='状态' name='status' style={itemStyle}>
                            <Select allowClear placeholder='全部'>
                                <Option value='1'>待处理</Option>
                                <Option value='2'>提交成功</Option>
                            </Select>
                        </FormItem>
                    </Row>
                    <Row justify='end'>
                        <Space>
                            <Button type='primary' onClick={methodsFn.handleSearch}>
                                查询
                            </Button>
                            <Button onClick={methodsFn.handleFormReset}>重置</Button>
                            {permissions.includes(`${path}:insert`) && <Button onClick={methodsFn.handleAdd}>新增</Button>}
                        </Space>
                    </Row>
                </Form>
            )
        }
    }
    //方法函数
    const methodsFn = {
        // 更新列表
        handleSearch() {
            setPageNum(1)
            // 其他情况页码变化也会刷新列表
            if (pageNum == 1) {
                methodsFn.getTableList()
            }
        },
        async getTableList() {
            let formData = form.getFieldsValue()
            getTianJinDataList({
                pageSize: pageSize,
                pageNum: pageNum,
                month: formData.month && formData.month.format('YYYY-MM'),
                status: formData.status,
                type: formData.type
            }).then(({ data: { data, success, retMsg } }) => {
                if (success) {
                    setTotal(data.totalCount)
                    setTableData(data.list)
                } else {
                    message.error(retMsg)
                }
            })
        },
        // 重置搜索项
        handleFormReset() {
            form.resetFields()
        },
        // 点击新增
        handleAdd() {
            setVisible(true)
        },
        // 点击提交
        async handleSubmit() {
            setLoading(true)
            let values = await form2.validateFields()
            tianJinDataInsert({
                month: values.month.format('YYYY-MM'),
                type: values.type
            })
                .then(({ data: { success, retMsg } }) => {
                    if (success) {
                        message.success('新增成功')
                        methodsFn.closeSubmitForm()
                        methodsFn.handleSearch()
                    } else {
                        message.error(retMsg)
                    }
                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                })
        },
        // 明细
        goDetail(record) {
            props.history.push(`/admin/tianJinData/detail?businessCode=${record.businessCode}&type=${record.type}&month=${record.month}`)
        },
        closeSubmitForm() {
            form2.resetFields()
            setVisible(false)
        }
    }

    useEffect(() => {
        methodsFn.getTableList()
    }, [pageNum])

    return (
        <div>
            <div>
                {myComponent.renderSearchForm()}
                <Table
                    scroll={{ x: 'max-content', y: 550 }}
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={tableData}
                    pagination={{
                        position: ['bottomCenter'],
                        total: total,
                        current: pageNum,
                        pageSize: pageSize,
                        showSizeChanger: false,
                        showTotal: total => `共 ${total} 条数据`,
                        onChange: pageNum => setPageNum(pageNum)
                    }}
                />
            </div>
            <Modal maskClosable={false} title='新增' confirmLoading={loading} visible={visible} onOk={methodsFn.handleSubmit} onCancel={methodsFn.closeSubmitForm}>
                <Form form={form2} {...formItemLayout}>
                    <Form.Item rules={[{ required: true, message: '请选择' }]} label='月份' name='month'>
                        <DatePicker style={{ width: '100%' }} picker='month' />
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: '请选择' }]} label='业务类型' name='type'>
                        <Select placeholder='请选择'>
                            <Option value='1'>直营</Option>
                            <Option value='2'>众包</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default TianJinData
