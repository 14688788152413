/*天津数据（东疆）接口*/

import { request } from '../../../utils/fetch'

// 数据导入 列表
export const getTianJinDataList = params => {
    return request.requestGet('/platform/dj_import/getList', params)
}
// 数据导入 新增
export const tianJinDataInsert = data => {
    return request.requestPost('/platform/dj_import/insert', data)
}
// 数据导入-明细 列表
export const getTianJinDataDetailList = params => {
    return request.requestGet('/platform/dj_import/getDetailList', params)
}
// 数据导入-明细 错误信息
export const getTianJinDataDetailError = params => {
    return request.requestGet('/platform/dj_import/errorList', params)
}
// 数据导入-明细 提交
export const getTianJinDataDetailSubmit = data => {
    return request.requestPost('/platform/dj_import/submit', data)
}
// 数据导入-明细-预览 列表
export const getTianJinDataDetailPreList = params => {
    return request.requestGet('/platform/dj_import/getPreviewList', params)
}
