import React,{ useImperativeHandle, useEffect, forwardRef } from 'react'
import { 
  Modal,
  Form,
  message,
  Select
} from 'antd';
import { getRelevanceCompany } from '../../../../../api/api.js'
import { insertCompany } from '../api'
const FormItem = Form.Item;
const AddDetailModel = (props, ref) => {
  const { Option } = Select
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [accType, setAccType] = React.useState();
  const [selectData, setSelectData] = React.useState([])
  const initialValues = {
    age:''
  }

   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (val) => {
      setVisible(true);
      form.resetFields()
    }
  }));
  useEffect(() => {
    getlist()
  }, [])
  const getlist = async () => {
    let result = await getRelevanceCompany().catch((err)=>{
        throw err
    })
    setSelectData(result)
   
  }
  const typeChange = (value)=>{
    console.log(value)
    setAccType(value)
  }
  const methodsFn =  {
    handleOk: async() => {
      try {
        const values = await form.validateFields();
        setLoading(true);
        methodsFn.handleAdd(values)
      } catch (errorInfo) {
        console.log('Failed:', errorInfo);
      }
    },
    handleAdd(obj){
     
      let data = {
        ...obj,
        taxEnterpriseCode: props.taxEnterpriseCode,
      }
      if(obj.type==3){
        let item = selectData.filter(e=>{
          return e.companyCode === obj.companyCode
        })
        data.companyName = item[0].companyName
      }
      insertCompany(data).then(res=>{
        if(res.data.success){
          setVisible(false);
          setLoading(false);
          message.success(res.data.retMsg)
          props.refresh()
        }else{
          message.error(res.data.retMsg)
          setLoading(false);
        }
      }).catch(err => setLoading(false))
     
    },
  }

  return (
    <>
    <Modal maskClosable={false}
        forceRender
        title='新增'
        width='500px'
        visible={visible}
        onOk={methodsFn.handleOk}
        confirmLoading={loading}
        onCancel={() => {setVisible(false);}}
    >
        <Form
          labelCol={{ flex: '100px' }}
          wrapperCol={{ span: 15 }}
          initialValues={initialValues}
          form={form}
        >
            <FormItem name="type" label="子户类型" >
              <Select allowClear placeholder='请选择子户类型' onChange={typeChange} >
                <Option value='1'>服务费</Option>
                <Option value='2'>税费</Option>
                <Option value='3'>企业账户</Option>
              </Select>
            </FormItem>
            {accType==3&&<FormItem name="companyCode" label="关联企业" >
                <Select allowClear placeholder='请选择关联企业'>
                  {selectData.map((e)=>{ return <Option key={e.companyCode} value={e.companyCode}>{e.companyName}</Option>})}
                </Select>
            </FormItem>}
        </Form>
    </Modal>
    </>
  );
};
export default forwardRef(AddDetailModel)



