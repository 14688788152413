import React, { useState, useEffect } from 'react'
import { Row, Table, Select, Form, Button, Popconfirm, Spin, Space, Typography, Modal, message } from 'antd'
import { request } from '../../../../utils/fetch'
import { getRelevanceCompany, getTaxEnterpriseList, getListByCompanyCode } from '../../../../api/api'
import { changeSignInsert, changeSignChangeStatus } from '../api'
import store from '../../../../store/index'
const { Link } = Typography
const FormItem = Form.Item
const { Option } = Select

const itemStyle = {
    width: '300px',
    marginRight: '15px',
}
const labelStyleObj = {
    labelCol: {
        span: 9,
    },
    wrapperCol: {
        span: 15,
    },
}
//新增弹窗表单布局
const formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 15 },
}

function ChangeContract(props) {
    const path = 'platform:changeSign'
    const [form] = Form.useForm() //筛选表单
    const [companyData, setCompanyData] = useState([]) //公司列表
    const [allTaxEnterprise, setAllTaxEnterprise] = useState([]) //财税企业列表
    const [companytaxEnterprise, setCompanytaxEnterprise] = useState([]) //某个企业的财税企业
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)

    const [insertForm] = Form.useForm() //新增弹窗的表单
    const [visible, setVisible] = useState() //新增弹窗显示
    const [confirmLoading, setConfirmLoading] = useState(false) //弹窗按钮loading
    const [planTypes, setPlanTypes] = useState([]) //财税企业对应的落地方案

    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)

    const columns = [
        {
            title: '创建时间',
            dataIndex: 'createTime',
            width: 150,
            align: 'center',
        },
        // {
        //     title: '操作人',
        //     dataIndex: 'createUser',
        //     width: 150,
        //     align: 'center',
        // },
        {
            title: '企业名称',
            dataIndex: 'companyName',
            width: 150,
            align: 'center',
        },
        {
            title: '换签前财税企业',
            dataIndex: 'beforeEnterpriseName',
            width: 150,
            align: 'center',
        },
        {
            title: '换签前落地方案',
            dataIndex: 'beforePlanName',
            width: 150,
            align: 'center',
        },
        {
            title: '换签后财税企业',
            dataIndex: 'afterEnterpriseName',
            width: 150,
            align: 'center',
        },
        {
            title: '换签后落地方案',
            dataIndex: 'afterPlanName',
            width: 150,
            align: 'center',
        },
        {
            title: '已换签人数',
            dataIndex: 'hasSignCount',
            width: 150,
            align: 'center',
            render: (num, record) => {
                return (
                    <Link
                        onClick={() => {
                            goDetail(record, '1')
                        }}
                    >
                        {num}
                    </Link>
                )
            },
        },
        {
            title: '未换签人数',
            dataIndex: 'noSignCount',
            width: 150,
            align: 'center',
            render: (num, record) => {
                return record.status === 0 && num === 0 ? (
                    '--'
                ) : (
                    <Link
                        onClick={() => {
                            goDetail(record, '2')
                        }}
                    >
                        {num}
                    </Link>
                )
            },
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            align: 'center',
            render: (status) => {
                return status === 1 ? '启用' : '停用'
            },
        },
        {
            title: '操作',
            width: 150,
            align: 'center',
            fixed: 'right',
            render: (record) => {
                return (
                    record.status === 1 &&
                    store.getState().userReducer.permissions.includes(`${path}:insert`) && (
                        <Space size='middle'>
                            {
                                <Popconfirm
                                    title='停用后无法启用，确定停用吗？'
                                    onConfirm={() => {
                                        changeStatus(record)
                                    }}
                                    okText='确定'
                                    cancelText='取消'
                                >
                                    <Link>停用</Link>
                                </Popconfirm>
                            }
                        </Space>
                    )
                )
            },
        },
    ]

    //组件渲染函数
    const myComponent = {
        renderSearchForm() {
            return (
                <Form form={form} {...labelStyleObj}>
                    <Row>
                        <Form.Item label='企业名称' name='companyCode' style={itemStyle}>
                            <Select allowClear showSearch filterOption={(inputValue, option) => option.children.includes(inputValue)}>
                                {companyData.map((item) => (
                                    <Option value={item.companyCode} key={item.companyCode}>
                                        {item.companyName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <FormItem label='换签前财税企业' name='beforeEnterpriseCode' style={itemStyle}>
                            <Select>
                                {allTaxEnterprise.map((item) => (
                                    <Option value={item.code} key={item.code}>
                                        {item.enterpriseName}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                        <FormItem label='换签前落地方案' name='beforePlanType' style={itemStyle}>
                            <Select>
                                <Option value={1}>临时税务登记</Option>
                                <Option value={3}>委托代征</Option>
                            </Select>
                        </FormItem>
                        <FormItem label='换签后财税企业' name='afterEnterpriseCode' style={itemStyle}>
                            <Select>
                                {allTaxEnterprise.map((item) => (
                                    <Option value={item.code} key={item.code}>
                                        {item.enterpriseName}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                        <FormItem label='换签后落地方案' name='afterPlanType' style={itemStyle}>
                            <Select>
                                <Option value={1}>临时税务登记</Option>
                                <Option value={3}>委托代征</Option>
                            </Select>
                        </FormItem>
                        <FormItem label='状态' name='status' style={itemStyle} initialValue={1}>
                            <Select>
                                <Option value={0}>停用</Option>
                                <Option value={1}>启用</Option>
                            </Select>
                        </FormItem>
                    </Row>
                    <Row justify='end'>
                        <Space>
                            <Button onClick={handleSearch} type='primary'>
                                查询
                            </Button>
                            <Button onClick={handleFormReset}>重置</Button>
                            {store.getState().userReducer.permissions.includes(`${path}:insert`) && <Button onClick={insert}>新增</Button>}
                        </Space>
                    </Row>
                </Form>
            )
        },
    }
    // 获取企业
    const getCompany = async () => {
        const res = await getRelevanceCompany()
        res && res.length && setCompanyData(res)
    }
    // 获取财税企业
    const getTaxEnterprise = async () => {
        const res = await getTaxEnterpriseList().catch((err) => {
            throw err
        })
        res.data.success && res.data.data && setAllTaxEnterprise(res.data.data)
    }
    // 根据入驻企业获取财税企业
    const getCompanyTaxEnterprise = async (params) => {
        const res = await getListByCompanyCode(params)
        res && res.length && setCompanytaxEnterprise(res)
    }
    const companyCodeChange = (companyCode) => {
        getCompanyTaxEnterprise({ companyCode })
    }
    const afterEnterpriseCodeChange = (value) => {
        let arry = companytaxEnterprise.filter((item) => {
            return item.code === value
        })
        setPlanTypes(arry[0].planTypes.split(','))
    }
    //重置表单
    const handleFormReset = () => form.resetFields()
    const handleSearch = () => {
        setPageNum(1)
        if (pageNum == 1) {
            getTableList()
        }
    }
    // 停用
    const changeStatus = (record) => {
        console.log(record)
        changeSignChangeStatus({
            signNo: record.signNo,
        }).then((res) => {
            if (res.data.success) {
                message.success('停用成功!')
                handleSearch()
            } else {
                message.error('停用失败：' + res.data.retMsg)
            }
        })
    }
    // 新增
    const insert = () => {
        insertForm.resetFields()
        setCompanytaxEnterprise([])
        setPlanTypes([])
        setVisible(true)
    }
    // 新增点击确认按钮
    const insertSubmit = () => {
        insertForm.submit()
    }
    //表单校验成功后
    const insertFormOnFinish = (values) => {
        if (values.afterEnterpriseCode === values.beforeEnterpriseCode && values.afterPlanType === values.beforePlanType) {
            message.error('换签前后信息没有改动，请核对信息后重试！')
            return
        }
        setConfirmLoading(true)
        changeSignInsert({ ...values })
            .then((res) => {
                setConfirmLoading(false)
                if (res.data.success) {
                    message.success('新增成功')
                    setVisible(false)
                    handleSearch()
                } else {
                    message.error('新增失败：' + res.data.retMsg)
                }
            })
            .catch((err) => {
                throw err
            })
    }
    const getTableList = async () => {
        setLoading(true)
        const formData = form.getFieldsValue()
        const result = await request
            .get('/platform/changeSign/list', {
                pageSize: pageSize,
                pageNum: pageNum,
                companyCode: formData.companyCode,
                beforeEnterpriseCode: formData.beforeEnterpriseCode,
                beforePlanType: formData.beforePlanType,
                afterEnterpriseCode: formData.afterEnterpriseCode,
                afterPlanType: formData.afterPlanType,
                status: formData.status,
            })
            .catch((err) => {
                setLoading(false)
                throw err
            })
        setTotal(result.totalCount)
        setTableData(result.list)
        setLoading(false)
    }
    //换签人数详情
    const goDetail = (record, type) => {
        if ((type === '1' && record.hasSignCount !== 0) || (type === '2' && record.noSignCount !== 0)) {
            props.history.push(`/admin/changeContract/detail?type=${type}&record=${JSON.stringify(record)}`)
        }
    }

    useEffect(() => {
        getCompany()
        getTaxEnterprise()
    }, [])

    useEffect(() => {
        getTableList()
    }, [pageNum])

    return (
        <Spin spinning={loading}>
            {myComponent.renderSearchForm()}
            <Table
                scroll={{
                    x: 'max-content',
                    y: 500,
                }}
                columns={columns}
                rowKey='signNo'
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum),
                }}
            />
            <Modal maskClosable={false}
                title='新增'
                visible={visible}
                onCancel={() => {
                    setVisible(false)
                }}
                confirmLoading={confirmLoading}
                onOk={insertSubmit}
            >
                <Form {...formItemLayout} form={insertForm} onFinish={insertFormOnFinish}>
                    <Form.Item label='企业名称' name='companyCode' rules={[{ required: true }]}>
                        <Select allowClear showSearch filterOption={(inputValue, option) => option.children.includes(inputValue)} onChange={companyCodeChange}>
                            {companyData.map((item) => (
                                <Option value={item.companyCode} key={item.companyCode}>
                                    {item.companyName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <FormItem label='换签前财税企业' name='beforeEnterpriseCode' rules={[{ required: true }]}>
                        <Select>
                            {allTaxEnterprise.map((item) => (
                                <Option value={item.code} key={item.code}>
                                    {item.enterpriseName}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem label='换签前落地方案' name='beforePlanType' rules={[{ required: true }]}>
                        <Select>
                            <Option value={1}>临时税务登记</Option>
                            <Option value={3}>委托代征</Option>
                        </Select>
                    </FormItem>
                    <FormItem label='换签后财税企业' name='afterEnterpriseCode' rules={[{ required: true }]}>
                        <Select onChange={afterEnterpriseCodeChange}>
                            {companytaxEnterprise.map((item) => (
                                <Option value={item.code} key={item.code}>
                                    {item.enterpriseName}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem label='换签后落地方案' name='afterPlanType' rules={[{ required: true }]}>
                        <Select>
                            {planTypes.includes('1') && <Option value={1}>临时税务登记</Option>}
                            {planTypes.includes('3') && <Option value={3}>委托代征</Option>}
                        </Select>
                    </FormItem>
                </Form>
            </Modal>
        </Spin>
    )
}

export default ChangeContract
