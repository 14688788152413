import { request } from '../../../utils/fetch'

// 企业列表
export const getCompanyList = (params)　=> {
    return request.requestGet('/platform/companyBase/list', params)
}
// 企业列表详情
export const getCompanyDetail = (params) => {
    return request.requestGet('/platform/companyBase/detail', params)
}
// 企业列表草稿状态修改
export const updateCompany = (data) => {
    return request.requestPost('/platform/companyBase/update', data)
}
// 企业列表修改基础信息
export const updateBase = (data) => {
    return request.requestPost('/platform/companyBase/updateBase', data)
}

// 修改配置
export const updataConfig = (data) => {
    return request.requestPost('/platform/companyBase/updateConfig', data)
}
// 修改企业管理员
export const updateManage = (data) => {
    return request.requestPost('/platform/companyBase/updateManager', data)
}
// 获取历史配置信息
export const getHistoryConfig = (params) => {
    return request.requestGet('/platform/companyBase/historyConfig', params)
}
// 企业列表启停用
export const companyUpdateStatus = (data) => {
    return request.requestPost('/platform/companyBase/updateStatus', data)
}
// 企业列表删除
export const companyDelete = (data) => {
    return request.requestPost('/platform/companyBase/delete', data)
}
// 重新绑定子户
export const updateBindCode = (data) => {
    return request.requestPost('/platform/companyBase/updateBindCode', data)
}
// 重新开户
export const openAccount = (data) => {
    return request.requestGet('/platform/companyBase/againOpenCompanyAccount', data)
}
// 企业入驻
export const insertCompanyList = async(data) => {
    return request.requestPost('/platform/companyBase/insert', data)  
}
// 获取银行列表
export const getBankList = (params) => {
    return request.requestGet('/common/getBankInfo', params)
}
// 企业入驻 下拉子户
export const getCompanyAccountLists = (params) => {
    return request.requestGet('/company/companyBase/getCompanyAccountList', params)
}

// 发包信息
export const getContractLists = (params) => {
    return request.requestGet('/platform/companySendConfig/getList', params)
}
// 发包信息详情
export const getContractDetail = (params) => {
    return request.requestGet('/platform/companySendConfig/getDetail', params)
}
// 发包信息修改
export const updateContractLists = (data) => {
    return request.requestPost('/platform/companySendConfig/update', data)  
}

// 获取城市/片区  planType 1为临时税务登记 2 个体工商户
export const getCityCountyByType = (planType) => {
    return request.requestGet('/common/getCityCountyByType', planType)
}

// 银行子户

// 列表
export const getCompanyAccount = (data) => {
    return request.requestGet('/platform/companyAccount/list', data)
}

// 新增
export const insertCompanyAccount = (data) => {
    return request.requestPost('/platform/companyAccount/insert', data)  
}
// 企业绑定
export const companyBind = (data) => {
    return request.requestPost('/platform/companyAccount/companyBind', data)  
}
// 企业绑定回显
export const getRelationInfo = (data) => {
    return request.requestGet('/platform/companyAccount/getRelationInfo', data)  
}
// 企业开放，暂停
export const updateConfigStatus = (data) => {
    return request.requestGet('/platform/companyBase/updateConfigStatus', data)  
}

// 详情
export const getCompanyAccountDetail = (data) => {
    return request.requestGet('/platform/companyAccount/detail', data)
}
// 重新开户
export const resetCompanyAccount = (data) => {
    return request.requestPost('/platform/companyAccount/againOpenCompanyAccount', data)  
}

// 企业协议模板列表
export const contractTempGetList = (data) => {
    return request.requestGet('/platform/qyContractTemplate/getList', data)  
}

// 企业协议模板详情
export const contractTempGetDetail = (data) => {
    return request.requestGet('/platform/qyContractTemplate/getDetail', data)  
}

// 企业协议模板启停用
export const contractTempUpdateStatus = (data) => {
    return request.requestPost('/platform/qyContractTemplate/updateStatus', data)  
}

// 企业协议模板新增
export const contractTempInsert = (data) => {
    return request.requestPost('/platform/qyContractTemplate/insert', data)  
}