import {
    request
} from '../../../../utils/fetch'

// 治安档案-列表
export const securityArchivesList = (params) => {
    return request.requestGet('/platform/securityArchives/getList', params)
}

// 治安档案-修改
export const securityArchivesUpdate = (data) => {
    return request.requestPost('/platform/securityArchives/update', data)
}

// 治安档案-详情
export const securityArchivesgetDetail = (params) => {
    return request.requestGet('/platform/securityArchives/getDetail', params)
}
