import React, { useState, useEffect, useRef } from 'react'
import { Row, Table, Select, Form, Button, message, Input, Upload, Modal, Space, Typography, Col, Popconfirm } from 'antd'
import { withdrawProveList, getCityListByProvinceId, withdrawProveDelete } from '../api'
import { request } from '../../../../utils/fetch'
import EditModel from './EditModel'
import AddModel from './AddModel'
import store from '../../../../store/index'
import { getTaxEnterpriseList } from '../../../../api/api'
const { Link } = Typography
const FormItem = Form.Item
const { Option } = Select

// 搜索表单通用样式
const itemStyle = {
    width: '300px',
    marginRight: '15px',
}
const labelStyleObj = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
}

function WithdrawProve(props) {
    let path = 'platform:crowdWithdrawal'
    const [form] = Form.useForm() //搜索表单
    // 初始化搜索数据
    const initSearch = {
        date: [],
    }
    const [companyData, setCompanyData] = useState([]) //公司列表
    const [tableData, setTableData] = useState([])
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const EditModelRef = useRef()
    const AddModelRef = useRef()
    const columns = [
        {
            title: '编码',
            dataIndex: 'code',
            width: 150,
            align: 'center',
        },
        // {
        //     title: '企业',
        //     dataIndex: 'taxEnterpriseName',
        //     width: 200,
        //     align: 'center',
        // },
        {
            title: '财税企业',
            dataIndex: 'taxEnterpriseName',
            width: 200,
            align: 'center',
        },
        {
            title: '姓名',
            dataIndex: 'name',
            width: 150,
            align: 'center',
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            width: 150,
            align: 'center',
        },
        {
            title: '身份证号',
            dataIndex: 'idNumber',
            width: 150,
            align: 'center',
        },
        {
            title: '开始月份',
            dataIndex: 'beginMonth',
            width: 150,
            align: 'center',
        },

        {
            title: '结束月份',
            dataIndex: 'endMonth',
            width: 150,
            align: 'center',
        },
        {
            title: '佣金总额',
            dataIndex: 'totalCommissionAmount',
            width: 150,
            align: 'center',
        },
        {
            title: '操作',
            width: 120,
            align: 'center',
            fixed: 'right',
            render: (text, record) => {
                return (
                    <Space size='middle'>
                        {store.getState().userReducer.permissions.includes(`${path}:download`) && <Link onClick={() => methodsFn.toDown(record)}>下载</Link>}
                        {store.getState().userReducer.permissions.includes(`${path}:delete`) && (
                            <Popconfirm title='确定删除吗？' onConfirm={() => methodsFn.toDelete(record)} okText='确定' cancelText='取消'>
                                <Link>删除</Link>
                            </Popconfirm>
                        )}
                    </Space>
                )
            },
        },
    ]

    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        {/* <FormItem label='企业' name='companyCode' style={itemStyle} {...labelStyleObj}>
                            <Select  placeholder='全部' onChange={methodsFn.companyChange}>
                                {companyData.map((item) => {
                                    return (
                                        <Option value={item.companyCode} key={item.companyCode}>
                                            {item.companyName}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </FormItem> */}
                        <FormItem label='财税企业' name='taxEnterpriseCode' style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder='全部'>
                                {TaxEnterpriseLis.map((e) => (
                                    <Option key={e.code} value={e.code}>
                                        {e.enterpriseName}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                        <FormItem label='关键字' name='keyword' style={{ width: '350px', marginRight: '15px' }}>
                            <Input placeholder='姓名丨身份证号丨手机号' />
                        </FormItem>
                    </Row>
                    <Row>
                        <Col span={24} md={24} lg={24}>
                            <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }}>
                                <Button type='primary' onClick={methodsFn.handleSearch}>
                                    查询
                                </Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>
                                    重置
                                </Button>
                                {store.getState().userReducer.permissions.includes(`${path}:insert`) && (
                                    <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>
                                        新增
                                    </Button>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            )
        },
    }
    //方法函数
    const methodsFn = {
        handleSearch() {
            setPageNum(1)
            if (pageNum == 1) {
                methodsFn.getTableList()
            }
        },
        handleFormReset() {
            form.resetFields()
        },
        getsearchObj() {
            let formObj = form.getFieldsValue()

            let obj = {
                companyCode: formObj.companyCode,
                keyword: formObj.keyword,
                taxEnterpriseCode: formObj.taxEnterpriseCode,
            }
            return obj
        },
        getTableList() {
            let searchObj = methodsFn.getsearchObj()
            withdrawProveList({
                ...searchObj,
                pageSize: pageSize,
                pageNum: pageNum,
            })
                .then((res) => {
                    if (res.data.success) {
                        console.log(res.data)
                        setTotal(res.data.data.totalCount)
                        setTableData(res.data.data.list)
                    } else {
                        message.error(res.data.retMsg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // 修改
        toDown(record) {
            EditModelRef.current.showModal(record)
        },
        // 新增
        handleExport(record) {
            AddModelRef.current.showModal()
        }, // 获取所有财税企业
        async getTaxEnterpriseListall() {
            const res = await getTaxEnterpriseList()
            if (res.data.success) {
                setTaxEnterpriseLis(res.data.data)
            }
        },
        toDelete(record) {
            withdrawProveDelete({
                code: record.code,
            })
                .then((res) => {
                    if (res.data.success) {
                        methodsFn.getTableList()
                        message.success('删除成功')
                    } else {
                        message.error(res.data.retMsg)
                    }
                })
                .catch(() => {})
        },
    }
    useEffect(() => {
        getCityListByProvinceId()
        methodsFn.getTaxEnterpriseListall()
    }, [])
    useEffect(() => {
        methodsFn.getTableList()
        request.get('/platform/companyBase/allList', { projectType: 2 }).then((res) => {
            setCompanyData(res)
        })
    }, [pageNum])

    return (
        <>
            {myComponent.renderSearchForm()}
            <Table
                scroll={{ x: 'max-content', y: 550 }}
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum),
                }}
            />
            <EditModel ref={EditModelRef} companyData={companyData} refres={methodsFn.handleSearch} />
            <AddModel ref={AddModelRef} companyData={companyData} TaxEnterpriseLis={TaxEnterpriseLis} refres={methodsFn.handleSearch} />
        </>
    )
}

export default WithdrawProve
