import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import {
    Table,
    Space,
    message,
    Popconfirm,
    Button
} from 'antd'
import { securityEventList, securityEventDelete } from '../api'
import store from '../../../../../store/index'
import ViewMoel from './ViewMoel'
import CaseEditMoel from './CaseEditMoel'
import NotesMoel from './NotesMoel'
import CaseMoel from './CaseMoel'
const ClosureTableList = forwardRef((props,ref)=> {
    let path = 'securityEvent'
    const [cxja] = useState(store.getState().userReducer.permissions.indexOf(`${path}:refinish`)>-1)
    const [xg] = useState(store.getState().userReducer.permissions.indexOf(`${path}:update`)>-1)
    const [sc] = useState(store.getState().userReducer.permissions.indexOf(`${path}:delete`)>-1)
    const [tableData, setTableData] = useState([])
    const ViewMoelRef = useRef();
    const CaseEditMoelRef = useRef();
    const NotesMoelRef = useRef();
    const CaseMoelRef = useRef();
    const [queryData, setQueryData] = useState({})
    let pageSize = 20
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: pageSize,
        total: 0
    })
    useImperativeHandle(ref, () => ({
        // getlist 就是暴露给父组件的方法
        getlist: (val) => {
            setQueryData(val)
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
            getTableLists({ ...pagination, pageNum: 1},val)
        }
    }));
    const onRefresh = ()=>{
        getTableLists(pagination)
    }
    const getTableLists = async ({pageNum, pageSize},query=queryData) => {
        let result = await securityEventList(Object.assign({}, query, {pageNum: pageNum, pageSize: pageSize, eventType: 2}))
        if (result.data.success) {
            setTableData(result.data.data.list)
            setPagination(prev => {
                return {
                    ...prev,
                    total: result.data.data.totalCount
                }
            })
        } else {
            message.error(result.data.retMsg)
        }
    }
    // 列表字段
    const tableColumns = [
        { title: '创建日期', dataIndex: 'createTime', key: 'createTime', align: 'center', width: 120 },
        { title: '接报或上报时间', dataIndex: 'eventReportTime', key: 'eventReportTime', align: 'center', width: 130 },
        { title: '事件工单号', dataIndex: 'eventOrderNo', key: 'eventOrderNo', align: 'center', width: 170 },
        { title: '注册城市', dataIndex: 'cityName', key: 'cityName', align: 'center', width: 100 },
        { title: '结案人', dataIndex: 'caseOriginatorUserName', key: 'caseOriginatorUserName', align: 'center', width: 140 },
        { title: '事件分类', dataIndex: 'eventClassifyName', key: 'eventClassifyName', align: 'center', width: 100,},
        { title: '事件等级', dataIndex: 'eventLevel', key: 'eventLevel', align: 'center', width: 100 },
        { title: '骑手姓名', dataIndex: 'name', key: 'name', align: 'center', width: 100 },
        { title: '事件发生城市', dataIndex: 'eventCityName', key: 'eventCityName', align: 'center', width: 120 },
        { title: '费用支出金额', dataIndex: 'payAmount', key: 'payAmount', align: 'center', width: 180,},
        { title: '流程状态', dataIndex: 'processStatusName', key: 'processStatusName', align: 'center', width: 120 },
        {
            title: '操作',
            key: 'control',
            fixed: 'right',
            width: '200',
            align: 'center',
            render: (record) => (
                <Space align="center" >
                    {<Button className='botlink' type="link" key="1" onClick={() => handelView(record)}>查看</Button>}
                    {<Button className='botlink' type="link" key="1" onClick={() => handelNotes(record)}>记录</Button>}
                    {(record.processStatus=='0'||record.processStatus=='4'||record.processStatus=='5')&&xg&&<Button className='botlink' type="link" key="2" onClick={() => handelModify(record)}>修改</Button>}
                    {record.processStatus=='3'&&record.caseAgainFlag===0&&cxja&&<Button className='botlink' type="link" key="3" onClick={() => handelCase(record)}>重新结案</Button>}
                    {record.processStatus=='0'&&sc&&<Popconfirm
                        title="是否删除?"dealStatus
                        onConfirm={()=>{handelDelete(record)}}
                        okText="确定"
                        cancelText="取消"
                    >
                        <Button className='botlink' type="link" href="#">删除</Button>
                    </Popconfirm>}
                </Space>
               
            )
        }
    ]
    // 查看
    const handelView = async (record) => {
        ViewMoelRef.current.showModal(record,true);
    }
    // 修改
    const handelModify = async (record) => {
        CaseEditMoelRef.current.editModal(record,2);
    }
     // 跟进记录
    const handelNotes = async (record) => {
        NotesMoelRef.current.showModal(record,true)
    }
     // 重新结案
     const handelCase = async (record) => {
        CaseMoelRef.current.showModal(record,'重新结案',0)
    }
    // 删除
    const handelDelete = async (record) => {
        let result = await securityEventDelete({ eventCode: record.eventCode, relationEventCode: record.relationEventCode })
        if (result.data.success) {
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
            message.success(result.data.retMsg)
            getTableLists({ pageSize: pageSize, pageNum: 1 })
        } else {
            message.error(result.data.retMsg)
        }
    }
    //分页改变
    const tablePageChange = (pageNum, pageSize) => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: pageNum
            }
        })
        getTableLists({ pageSize: pageSize, pageNum: pageNum })
    }
    return (
        <>
        <Table
            columns={tableColumns}
            dataSource={tableData}
            scroll={{ x: 'max-content', y: 500 }}
            rowKey={record => record.eventCode}
            pagination={{
                position: ['bottomCenter'],
                showSizeChanger: false,
                total: pagination.total,
                current: pagination.pageNum,
                pageSize: pagination.pageSize,
                showTotal: (total) => `共 ${total} 条数据`,
                onChange: (pageNum, pageSize) => tablePageChange(pageNum, pageSize)
            }}
        />
        <ViewMoel ref={ViewMoelRef} />
        <CaseEditMoel refresh={onRefresh} ref={CaseEditMoelRef} />
        <NotesMoel ref={NotesMoelRef} />
        <CaseMoel refresh={onRefresh} ref={CaseMoelRef} />
        </>
        
    )
})
export default ClosureTableList
