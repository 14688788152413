import React, { useState, useEffect } from 'react';
import { request } from '../../../../../utils/fetch'
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Radio,
  Row,
  Col,
  Space,
} from 'antd'
import store from '../../../../../store/index'
import { getProvinceIdListByRegionalNo,getCityListByProvinceId} from '../../api'
import moment from 'moment'
const QueryForm = (props)=> {
    let path = 'securityEvent'
    const { refresh, DingkUser, activeKey } = props
    const [form] = Form.useForm()
    const { OptGroup, Option } = Select
    const { RangePicker } = DatePicker
    const labelStyleObj = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
    }
    const itemStyle = { width: '364px', marginRight: '15px' }
    const initialFormValues = {}
    const [radio, setRadio] = useState(10)
    const [cityType, setCityType] = useState('cityId')
    const [bb] = useState(store.getState().userReducer.permissions.indexOf(`${path}:report`)>-1)
    const [dc] = useState(store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1)
    const [userType, setUserType] = useState('reportOriginatorUserId')
    const [provinceList, setProvinceList] = useState([])
    const [cityList, setCityList] = useState([])
    useEffect(() => {
    }, [])
      // 下拉框
    const selectFilter = (inputValue, option) => {
        if (option.children.indexOf(inputValue) != -1) {
            return option
        }
    }
      // 选择事发城市获取省份
    const cityTypeChange = (value) => {
        if(value=='eventCityId'){
            getProvinceIdListByRegionalNo().then((res)=>{
                if (res.data.success) {
                    setProvinceList(res.data.data)
                }
            })
        }else{
            form.setFieldsValue({
                eventProvinceId:'',
            })
            setProvinceList([])
        }
    }
      // 根据省份获取城市
    const provinceIdChange = (value) => {
        if(value){
            getCityListByProvinceId({
                provinceId:value
            }).then((res)=>{
                if (res.data.success) {
                    setCityList(res.data.data)
                }
            })
        }else{
            setCityList([])
        }
    }
    // 时间radio选择
    const radioChange = e => {
        let startDate, endDate
        setRadio(e.target.value)
        startDate = moment().subtract(e.target.value - 1, 'days').startOf('day')
        endDate = moment()
        form.setFieldsValue({ date: [startDate, endDate] })
    }
    const rangeChange = (dates) => {
        if (dates === null) {
          setRadio(10)
        }
    }
    const handelQuery = () => {
        const date = form.getFieldsValue().date
        let data = {
            beginTime: date ? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date ? moment(date[1]).format('YYYY-MM-DD') : '',
            keyword: form.getFieldsValue().keyword,
            eventClassify: form.getFieldsValue().eventClassify,
            eventLevel: form.getFieldsValue().eventLevel,
            processStatusList: form.getFieldsValue().processStatusList,
        }
        data[userType] = form.getFieldsValue().userId
        data[cityType] = form.getFieldsValue()[cityType]
        refresh('query',data)
      }
    // 重置
    const resetForm = () => {
        form.resetFields()
        setRadio('')
    }
    // 新增
    const handAdd = () => {
        refresh('add')
    }
    // 导出
    const exportData = () => {
        const date = form.getFieldsValue().date
        let params = {
            beginTime: date ? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date ? moment(date[1]).format('YYYY-MM-DD') : '',
            keyword: form.getFieldsValue().keyword,
            eventClassify: form.getFieldsValue().eventClassify,
            eventLevel: form.getFieldsValue().eventLevel,
            processStatusList: form.getFieldsValue().processStatusList,
            eventType: activeKey
        }
        params[userType] = form.getFieldsValue().userId
        params[cityType] = form.getFieldsValue()[cityType]
        request.downExport('/platform/securityEvent/export', params)
      }
    return (
        <Form {...labelStyleObj} initialValues={initialFormValues} form={form}>
            <Row>
                <Form.Item style={{ width: '364px', marginRight: '20px' }} labelCol={{ flex: '111px' }} wrapperCol={{ flex: '1' }} name="date" label="接报或上报时间">
                    <RangePicker
                        allowClear={true}
                        onChange={(dates) => { rangeChange(dates); setRadio(10) }}
                        format="YYYY-MM-DD" />
                </Form.Item>
                <Radio.Group style={{height: '32px',alignItems: 'center',display: 'flex'}} onChange={radioChange} value={radio}>
                    <Radio value={1}>今天</Radio>
                    <Radio value={7}>近7天</Radio>
                    <Radio value={30}>近30天</Radio>
                </Radio.Group>
            </Row>
            <Row>
                <Form.Item style={ {...itemStyle, display: 'flex'} } wrapperCol={{ span: 24 }}>
                    <Form.Item style={{display: 'inline-block', marginBottom: 0, marginLeft:'11px', width: '100px' }} >
                        <Select value={cityType} onChange={(value) => { setCityType(value); cityTypeChange(value) }} >
                            <Option value="cityId">注册城市</Option>
                            <Option value="eventCityId">事发城市</Option>
                        </Select>
                    </Form.Item>
                    {cityType=='cityId'&&<Form.Item style={{display: 'inline-block', marginBottom: 0, width: '253px'}} wrapperCol={{ span: 24 }} name="cityId">
                        <Select allowClear key='cityId' placeholder="全部">
                            <Option value='TJ' key='TJ'>天津市</Option>
                            <Option value='NC' key='NC'>南昌市</Option>
                            <Option value='JJ' key='JJ'>九江市</Option>
                            <Option value='GZ' key='GZ'>赣州市</Option>
                            <Option value='JDZ' key='JDZ'>景德镇市</Option>
                            <Option value='JN'>济南市</Option>
                            <Option value='LY'>临沂市</Option>
                            <Option value='QD'>青岛市</Option>
                            <Option value='WH'>威海市</Option>
                            <Option value='WF'>潍坊市</Option>
                            <Option value='YT'>烟台市</Option>
                            <Option value='ZB'>淄博市</Option>
                            <Option value='TY'>太原市</Option>
                        </Select>
                    </Form.Item>}
                    {cityType=='eventCityId'&&<Form.Item style={{display: 'inline-block', marginBottom: 0, width: '253px'}} >
                        <div style={{display: 'flex', justifyContent: 'space-between'}} key='eventCityId' >
                            <Form.Item style={{ marginBottom: 0, width: '100px' }} name="eventProvinceId" >
                                <Select onChange={(value) => { provinceIdChange(value) }} placeholder="全部" >
                                    {provinceList.map(item => (
                                        <Option value={item.provinceId} key={item.provinceId}>{item.provinceName}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <span style={{display: 'inline-block',height: '32px', lineHeight: '32px', margin: '0 5px'}} >-</span>
                            <Form.Item style={{flex: '1', marginBottom: 0}} name="eventCityId" >
                                <Select placeholder="全部" >
                                    {cityList.map(item => (
                                        <Option value={item.cityId} key={item.cityId}>{item.cityName}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </Form.Item>}
                </Form.Item>
                <Form.Item style={itemStyle} name="eventClassify" label="事件分类">
                    <Select allowClear placeholder="全部" >
                        <Option value="1">偷盗</Option>
                        <Option value="2">服务态度恶劣</Option>
                        <Option value="3">实施威胁行为(不当言论)</Option>
                        <Option value="4">实施威胁行为(无不当言论)</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name="eventLevel" label="事件等级">
                    <Select allowClear placeholder="全部" showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)}>
                        <Option value="ESU">ESU</Option>
                        <Option value="P0">P0</Option>
                        <Option value="P1">P1</Option>
                        <Option value="P2">P2</Option>
                        <Option value="P3">P3</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={ {...itemStyle, display: 'flex'} } wrapperCol={{ span: 24 }}>
                    <Form.Item style={{display: 'inline-block', marginBottom: 0, marginLeft:'21px', width: '90px' }}>
                        <Select value={userType} onChange={(value) => { setUserType(value) } } >
                            <Option value="reportOriginatorUserId">报备人</Option>
                            <Option value="caseOriginatorUserId">结案人</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item style={{display: 'inline-block', marginBottom: 0, width: '253px'}} wrapperCol={{ span: 24 }} name="userId">
                        <Select allowClear placeholder="全部">
                        {DingkUser.map(item => (
                            <OptGroup label={item.name} key={item.name}>
                                {
                                    item.list.map((citem)=>(
                                        <Option value={citem.userId} key={citem.userId}>{citem.name}</Option>
                                    ))
                                }
                            </OptGroup>
                        ))}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item style={itemStyle} name="processStatusList" label="流程状态">
                    <Select mode="multiple" placeholder="全部" allowClear showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)}>
                            <Option value="0">草稿</Option>
                            {/* <Option value="1">待审</Option> */}
                            <Option value="2">审批中</Option>
                            <Option value="3">审批通过</Option>
                            <Option value="4">审批拒绝</Option>
                            <Option value="5">已撤销</Option>
                    </Select>
                </Form.Item>
                <Form.Item labelCol={{ flex: '106px' }} style={{ width: '460px' }} name="keyword" label="关键字">
                    <Input placeholder="骑手ID丨姓名丨手机号丨身份证号丨事件工单号" />
                </Form.Item>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Space>
                        {<Button type="primary" onClick={handelQuery}>查询</Button>}
                        <Button onClick={resetForm}>重置</Button>
                        {dc&& <Button onClick={exportData}>导出</Button>}
                        {bb&&<Button type="primary" onClick={handAdd}>新增</Button>}
                    </Space>
                </Col>
            </Row>
        </Form>
    )
}

export default QueryForm
