import React, { useState, useEffect } from 'react'
import { Row, Col, Table, Select, Form, Input, Radio, Button, DatePicker, message, Space, Typography, Modal, Upload, Popconfirm } from 'antd'
import moment from 'moment'
import { request } from '../../../../utils/fetch'
import axios from 'axios'
import { UploadOutlined } from '@ant-design/icons'
import store from '../../../../store/index'
import { getServiceChargeList, getServiceChargeDetailList, serviceChargeDelete } from '../api'

const FormItem = Form.Item
const { RangePicker } = DatePicker
const { Option } = Select
const { Link } = Typography
const itemStyle = {
    width: '350px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 }
}
const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 18 }
}

function ServiceChargeDetail(props) {
    let path = 'platform:ContractSign'
    const [form] = Form.useForm()
    const [insertForm] = Form.useForm()
    const [detailModalForm] = Form.useForm()
    const initSearch = {
        date: []
    }

    const [dayValue, setDayValue] = useState(0)
    const [fileList, setFileList] = useState([]) //文件列表
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const [insertVisible, setInsertVisible] = useState(false)
    const [detailVisible, setDetailVisible] = useState(false)
    const [insertTitle, setInsertTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [tableData, setTableData] = useState([])
    const [tableDetailData, setTableDetailData] = useState([])
    const [detailTotal, setDetailTotal] = useState(0)
    const [detailPageNum, setDetailPageNum] = useState(1)
    const [tempRecord, setTempRecord] = useState({})
    const fileProps = {
        //表格上传配置和方法
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept: '.xls, .xlsx',
        beforeUpload: () => {
            return false
        },

        onChange(info) {
            let fileCon = [...info.fileList]
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1)
            setFileList(fileCon)
        }
    }

    const columns = [
        {
            title: '添加时间',
            dataIndex: 'createTime',
            width: 150,
            align: 'center'
        },
        {
            title: '城市',
            dataIndex: 'cityName',
            width: 150,
            align: 'center'
        },
        {
            title: '车行名称',
            dataIndex: 'dealerName',
            width: 150,
            align: 'center'
        },
        {
            title: '月份',
            dataIndex: 'month',
            width: 250,
            align: 'center'
        },
        {
            title: '需协助代扣租金',
            dataIndex: 'withholdAmount',
            width: 250,
            align: 'center'
        },
        {
            title: '管理费(鹬鸟及城市)',
            dataIndex: 'managementAmount',
            width: 250,
            align: 'center'
        },
        {
            title: '实际结算金额',
            dataIndex: 'settlementAmount',
            width: 250,
            align: 'center'
        },
        {
            title: '操作',
            key: 'action',
            width: 160,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size='middle'>
                    <Link onClick={() => methodsFn.handleDetail(record)}>明细</Link>
                    {
                        <Popconfirm
                            title='确定删除吗？'
                            onConfirm={() => {
                                methodsFn.handleDelete(record)
                            }}
                            okText='确定'
                            cancelText='取消'
                        >
                            <Link>删除</Link>
                        </Popconfirm>
                    }
                </Space>
            )
        }
    ]
    const columnsDetail = [
        {
            title: '租车人姓名',
            dataIndex: 'lesseeName',
            align: 'center',
            width: 150,
            fixed: 'left'
        },
        {
            title: '身份证号',
            dataIndex: 'lesseeIdNumber',
            align: 'center',
            width: 150,
            fixed: 'left'
        },
        {
            title: '联系方式',
            dataIndex: 'phone',
            width: 150,
            align: 'center'
        },
        {
            title: '合同起租日期',
            dataIndex: 'beginDate',
            width: 150,
            align: 'center'
        },
        {
            title: '租金',
            dataIndex: 'lesseeAmount',
            width: 150,
            align: 'center'
        },
        {
            title: '需协助代扣租金',
            dataIndex: 'withholdAmount',
            width: 150,
            align: 'center'
        },
        {
            title: '管理费(鹬鸟)',
            dataIndex: 'firstManagementAmount',
            width: 150,
            align: 'center'
        },
        {
            title: '管理费(城市)',
            dataIndex: 'secondManagementAmount',
            width: 150,
            align: 'center'
        },
        {
            title: '实际结算金额',
            dataIndex: 'settlementAmount',
            width: 150,
            align: 'center'
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            width: 150,
            align: 'center'
        }
    ]
    //组件渲染函数
    const myComponent = {
        renderSearchForm() {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        <FormItem label='时间' name='date' style={{ width: '486px' }} labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
                            <RangePicker onChange={methodsFn.timeChange} />
                        </FormItem>
                        <FormItem style={{ with: '600px', marginLeft: '26px' }} label=''>
                            <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                                <Radio value={1}>今天</Radio>
                                <Radio value={7}>近7天</Radio>
                                <Radio value={30}>近30天</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Row>
                    <Row>
                        <FormItem style={itemStyle} {...labelStyleObj} name='cityName' label='城市'>
                            <Input placeholder='全部' />
                        </FormItem>
                        <FormItem label='月份' name='month' style={itemStyle} {...labelStyleObj}>
                            <DatePicker picker='month' />
                        </FormItem>
                        <FormItem label='关键字' name='keyword' style={itemStyle} {...labelStyleObj}>
                            <Input placeholder='车行名称' />
                        </FormItem>
                    </Row>
                    <Row justify='end'>
                        <Space size={10}>
                            <Button type='primary' onClick={methodsFn.handleSearch}>
                                查询
                            </Button>
                            <Button onClick={methodsFn.handleFormReset}>重置</Button>
                            {
                                // permissions.includes(`${path}:export`) &&
                                <Button onClick={methodsFn.handleExport}>导出</Button>
                            }
                            {
                                // permissions.includes(`${path}:export`) &&
                                <Button onClick={methodsFn.handleExportDetail}>导出明细</Button>
                            }
                            {
                                // permissions.includes(`${path}:insert`) &&
                                <Button onClick={methodsFn.handleInsert}>新增</Button>
                            }
                        </Space>
                    </Row>
                </Form>
            )
        },
        insertModal() {
            return (
                <Modal maskClosable={false} title={insertTitle} visible={insertVisible} onCancel={methodsFn.onInsertCancel} confirmLoading={loading} onOk={methodsFn.onInsertSubmit}>
                    <Form form={insertForm} {...formItemLayout}>
                        <Row>
                            <Col span={24}>
                                <FormItem name='cityName' rules={[{ required: true }]} label='城市'>
                                    <Input placeholder='请输入' />
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem name='dealerName' rules={[{ required: true }]} label='车行'>
                                    <Input placeholder='请输入' />
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label='月份' name='month' rules={[{ required: true }]}>
                                    <DatePicker picker='month' />
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label='上传表格' name='ffile' rules={[{ required: true, message: '请上传表格' }]}>
                                    <Upload {...fileProps}>
                                        <Button icon={<UploadOutlined />}>点击上传</Button>
                                        <Button
                                            type='link'
                                            onClick={e => {
                                                e.stopPropagation()
                                                window.location.href = `/wispay/common/getTemplateDownloadUrl?token=${localStorage.getItem('token')}&mobile=${localStorage.getItem(
                                                    'mobile'
                                                )}&type=DDC_FWFMX`
                                            }}
                                        >
                                            下载模板
                                        </Button>
                                    </Upload>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )
        },
        detailModal() {
            return (
                <Modal maskClosable={false} title='明细' visible={detailVisible} onCancel={methodsFn.onDetailCancel} footer={null} width={1000}>
                    <Form form={detailModalForm} {...formItemLayout}>
                        <Row>
                            <Col span={12}>
                                <FormItem name='cityName' label='城市'>
                                    <Input disabled />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name='month' label='月份'>
                                    <Input disabled />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name='dealerName' label='车行名称'>
                                    <Input disabled />
                                </FormItem>
                            </Col>
                        </Row>
                        <Table
                            scroll={{ x: 'max-content', y: 400 }}
                            columns={columnsDetail}
                            rowKey={record => record.id}
                            dataSource={tableDetailData}
                            pagination={{
                                position: ['bottomCenter'],
                                total: detailTotal,
                                current: detailPageNum,
                                pageSize: pageSize,
                                showSizeChanger: false,
                                showTotal: total => `共 ${total} 条数据`,
                                onChange: pageNum => {
                                    getServiceChargeDetailList({
                                        costCode: tempRecord.costCode,
                                        pageSize: pageSize,
                                        pageNum: pageNum
                                    }).then(({ data: { data, retMsg, success } }) => {
                                        if (success) {
                                            setTableDetailData(data.list)
                                            setDetailTotal(data.totalCount)
                                            setDetailPageNum(pageNum)
                                        } else {
                                            message.error(retMsg)
                                        }
                                    })
                                }
                            }}
                        />
                    </Form>
                </Modal>
            )
        }
    }
    //方法函数
    const methodsFn = {
        //日期变化
        timeChange() {
            setDayValue(0)
        },
        //天数变化
        dayChange(e) {
            let startDate = moment()
                .subtract(e.target.value - 1, 'd')
                .format('YYYY-MM-DD')
            let endDate = moment().format('YYYY-MM-DD')
            console.log(e.target.value)
            setDayValue(e.target.value)
            form.setFieldsValue({
                date: [moment(startDate), moment(endDate)]
            })
        },
        getsearchObj() {
            let formObj = form.getFieldsValue()
            let beginTime = undefined,
                endTime = undefined
            if (formObj.date.length > 1) {
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }

            return {
                beginTime: beginTime,
                endTime: endTime,
                keyword: formObj.keyword,
                cityName: formObj.cityName,
                month: formObj.month && formObj.month.format('YYYY-MM')
            }
        },
        handleSearch() {
            setPageNum(1)
            pageNum == 1 && methodsFn.getTableList()
        },
        handleFormReset() {
            setDayValue(0)
            form.resetFields()
        },
        async getTableList() {
            getServiceChargeList({
                ...methodsFn.getsearchObj(),
                pageSize: pageSize,
                pageNum: pageNum
            }).then(({ data: { data, retMsg, success } }) => {
                if (success) {
                    setTotal(data.totalCount)
                    setTableData(data.list)
                } else {
                    message.error(retMsg)
                }
            })
        },
        // 导出
        handleExport() {
            request.downExport('/platform/ddc_serviceFee/export', methodsFn.getsearchObj())
        },
        // 导出明细
        handleExportDetail() {
            request.downExport('/platform/ddc_serviceFee/exportDetail', methodsFn.getsearchObj())
        },
        // 新增
        handleInsert() {
            setInsertTitle('新增')
            setInsertVisible(true)
        },
        //关闭新增弹窗
        onInsertCancel() {
            setInsertVisible(false)
            insertForm.resetFields()
            setFileList([])
        },
        // 新增提交
        async onInsertSubmit() {
            const values = await insertForm.validateFields()
            console.log(values)
            let data = new FormData() //通过FormData将文件转成二进制数据
            let file = values.ffile.file
            data.append('token', localStorage.getItem('token'))
            data.append('mobile', localStorage.getItem('mobile'))
            data.append('cityName', values.cityName)
            data.append('dealerName', values.dealerName)
            data.append('month', values.month.format('YYYY-MM'))
            data.append('file', file)

            setLoading(true)

            axios({
                method: 'post',
                url: `/wispay/platform/ddc_serviceFee/insert`,
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(({ data: { retMsg, success } }) => {
                    setLoading(false)
                    if (success) {
                        methodsFn.onInsertCancel()
                        message.success(retMsg)
                        methodsFn.handleSearch()
                    } else {
                        if (retMsg.includes('#')) {
                            retMsg = retMsg.split('#')
                            retMsg = retMsg.map(item => <p>{item}</p>)
                        }
                        message.error(retMsg)
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                })
        },
        handleDelete(record) {
            serviceChargeDelete({
                costCode: record.costCode
            }).then(({ data: { retMsg, success } }) => {
                if (success) {
                    message.success(retMsg)
                } else {
                    message.error(retMsg)
                }
                methodsFn.getTableList()
            })
        },
        handleDetail(record) {
            setTempRecord(record)
            getServiceChargeDetailList({
                costCode: record.costCode,
                pageSize: pageSize,
                pageNum: 1
            }).then(({ data: { data, retMsg, success } }) => {
                if (success) {
                    setTableDetailData(data.list)
                    setDetailTotal(data.totalCount)
                    detailModalForm.setFieldsValue({
                        cityName: record.cityName,
                        month: record.month,
                        dealerName: record.dealerName
                    })
                    setDetailVisible(true)
                } else {
                    message.error(retMsg)
                }
            })
        },
        onDetailCancel() {
            setDetailVisible(false)
        }
    }

    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    }, [pageNum])

    return (
        <div>
            {myComponent.renderSearchForm()}
            <Table
                scroll={{ x: 'max-content', y: 518 }}
                columns={columns}
                rowKey={record => record.id}
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: total => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
            />
            {myComponent.insertModal()}
            {myComponent.detailModal()}
        </div>
    )
}

export default ServiceChargeDetail
