import React, { useState, useEffect } from 'react';
import { request } from '../../../../../utils/fetch'
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Radio,
  Row,
  Col,
  Space,
} from 'antd'
import store from '../../../../../store/index'
import { getRelevanceCompany } from '../../../../../api/api'
import moment from 'moment'
const QueryForm = (props)=> {
    let path = 'accidentReported'
    const { refresh } = props
    const { cityData } = props
    const [form] = Form.useForm()
    const { Option } = Select
    const { RangePicker } = DatePicker
    const labelStyleObj = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
    }
    const itemStyle = { width: '364px', marginRight: '15px' }
    const initialFormValues = {}
    const [dataObj, setDataObj] = useState({});
    const [radio, setRadio] = useState(10)
    const [companyData, setCompanyData] = useState([])
    const getIndexData = () => {
        //    request.get('/platform/index/indexData').then(res => {
        //     setDataObj(res)

        //    })
    }

    useEffect(() => {
        getCompany()
        getIndexData()
    }, [])
      // 下拉框
    const selectFilter = (inputValue, option) => {
        if (option.children.indexOf(inputValue) != -1) {
            return option
        }
    }
    // 时间radio选择
    const radioChange = e => {
        let startDate, endDate
        setRadio(e.target.value)
        startDate = moment().subtract(e.target.value - 1, 'days').startOf('day')
        endDate = moment()
        form.setFieldsValue({ date: [startDate, endDate] })
    }
    const rangeChange = (dates) => {
        if (dates === null) {
          setRadio(10)
        }
    }
    // 获取企业
    const getCompany = async () => {
        const res = await getRelevanceCompany({projectType:2})
        if (res && res.length > 0) {
        setCompanyData(res)
        }
    }
    const handelQuery = () => {
        console.log(form.getFieldsValue())
        const date = form.getFieldsValue().date
        let data = {
            beginTime: date ? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date ? moment(date[1]).format('YYYY-MM-DD') : '',
            keyword: form.getFieldsValue().keyword,
            cityIdList: form.getFieldsValue().cityIdList,
            processStatus: form.getFieldsValue().processStatus,
            companyCode: form.getFieldsValue().companyCode,
        }
        refresh('query',data)
      }
    // 重置
    const resetForm = () => {
        form.resetFields()
        setRadio('')
    }
    // 新增
    const handAdd = () => {
        refresh('add')
    }
    // 导出
    const exportData = () => {
        console.log(form.getFieldsValue())
        const date = form.getFieldsValue().date
        let params = {
            beginTime: date ? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date ? moment(date[1]).format('YYYY-MM-DD') : '',
            keyword: form.getFieldsValue().keyword,
            cityIdList: form.getFieldsValue().cityIdList,
            processStatus: form.getFieldsValue().processStatus,
            companyCode: form.getFieldsValue().companyCode,
        }
        request.downExport('/platform/accidentReported/export', params)
      }
    return (
        <Form {...labelStyleObj} initialValues={initialFormValues} form={form}>
            <Row>
                <Form.Item style={{ width: '510px' }} labelCol={{ span: 5 }} wrapperCol={{ span: 18 }} name="date" label="事件日期">
                    <RangePicker
                        allowClear={true}
                        onChange={(dates) => { rangeChange(dates); setRadio(10) }}
                        format="YYYY-MM-DD" />
                </Form.Item>
                <Radio.Group style={{height: '32px',alignItems: 'center',display: 'flex'}} onChange={radioChange} value={radio}>
                    <Radio value={1}>今天</Radio>
                    <Radio value={7}>近7天</Radio>
                    <Radio value={30}>近30天</Radio>
                </Radio.Group>
            </Row>
            <Row>
            <Form.Item style={itemStyle} name="processStatus" label="流程状态">
                    <Select mode="multiple" placeholder="全部" allowClear showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)}>
                            <Option value="0">草稿</Option>
                            {/* <Option value="1">待审</Option> */}
                            <Option value="2">审批中</Option>
                            <Option value="3">审批通过</Option>
                            <Option value="4">审批拒绝</Option>
                            <Option value="5">已撤销</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name="cityIdList" label="城市名称">
                    <Select allowClear placeholder="全部">
                        {cityData.length > 0 && cityData.map(item => (
                            <Option value={Number(item.cityId)} key={item.cityId}>{item.cityName}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name="companyCode" label="企业名称">
                    <Select allowClear placeholder="全部" showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)}>
                        {companyData.map(item => (
                            <Option value={item.companyCode} key={item.companyCode}>{item.companyName}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item labelCol={{ flex: '106px' }} style={{ width: '510px' }} name="keyword" label="关键字">
                    <Input placeholder="事件人员姓名丨事件人员身份证号丨事件编号丨T名称" />
                </Form.Item>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Space>
                        {store.getState().userReducer.permissions.indexOf(`${path}:select`) > -1&&<Button type="primary" onClick={handelQuery}>查询</Button>}
                        <Button onClick={resetForm}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`) > -1 && <Button onClick={exportData}>导出</Button>}
                        {store.getState().userReducer.permissions.indexOf(`${path}:insert`) > -1 &&<Button type="primary" onClick={handAdd}>新增报备</Button>}
                    </Space>
                </Col>
            </Row>
        </Form>
    )
}

export default QueryForm
