import React, { useImperativeHandle, useState, forwardRef } from 'react'
import domtoimage from 'dom-to-image'
import { Modal, message, Button } from 'antd'
import './EditModel.css'
import { withdrawProveDetail, getTaxEnterpriseSealUrl } from '../api'
import { request } from '../../../../utils/fetch'

const EditModel = (props,ref) => {
    const date = new Date()
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [hasSeal, setHasSeal] = useState(false)
    const [detail, setDetail] = useState({})
    const [style, setStyle] = useState({})
    // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        showModal: (record) => {
            setVisible(true)
            withdrawProveDetail({
                code: record.code,
            })
                .then((res) => {
                    if (res.data.success) {
                        setDetail(res.data.data)
                    } else {
                        setDetail({})
                        message.error(res.data.retMsg)
                    }
                    console.log(res.data.data)
                })
                .catch(() => {})
            getTaxEnterpriseSealUrl({
                code: record.taxEnterpriseCode,
            })
                .then((res) => {
                    if (res.data.success && res.data.data) {
                        setHasSeal(true)
                        setStyle({
                            backgroundImage: `url(${res.data.data}`,
                        })
                    } else {
                        // message.error('印章获取失败')
                        setHasSeal(false)
                    }
                })
                .catch(() => {})
        },
    }))

    const toPng = (type) => {
        if (type === '1' && !hasSeal) {
            message.error('印章未上传或获取失败，无法盖章下载！')
            return
        }
        setLoading(true)
        let node = document.querySelector('.withdrawProve')
        let obj = {}
        if (type === '1') {
            setLoading(true)
            obj.backgroundPosition = 'right 134px bottom 10px'
        }
        if (type === '2') {
            setLoading2(true)
        }
        console.log(obj)
        domtoimage
            .toPng(node, {
                style: {
                    ...obj,
                },
            })
            .then((dataUrl) => {
                request
                    .requestPost('/common/imageToPDF', {
                        base64String: dataUrl,
                        fileName: detail.name + '提现证明',
                    })
                    .then((res) => {
                        if (type === '1') {
                            setLoading(false)
                        }
                        if (type === '2') {
                            setLoading2(false)
                        }
                        if (res.data.success) {
                            request.downExport('/platform/crowdWithdrawal/download', {
                                localFile: decodeURI(res.data.data),
                            })
                        } else {
                            message.error(res.data.retMsg)
                        }
                    })
                    .catch((err) => {
                        if (type === '1') {
                            setLoading(false)
                        }
                        if (type === '2') {
                            setLoading2(false)
                        }
                        throw err
                    })
            })
            .catch((error) => {
                message.error('截图出错')
                if (type === '1') {
                    setLoading(false)
                }
                if (type === '2') {
                    setLoading2(false)
                }
                throw error
            })
    }

    const onCancel = () => {
        setLoading(false)
        setLoading2(false)
        setVisible(false)
    }

    return (
        <Modal maskClosable={false}
            title='下载'
            width='800px'
            visible={visible}
            footer={[
                <Button key='back' onClick={onCancel}>
                    取消
                </Button>,
                <Button
                    key='down'
                    type='primary'
                    loading={loading}
                    onClick={() => {
                        toPng('1')
                    }}
                >
                    盖章下载
                </Button>,
                <Button
                    key='down1'
                    type='primary'
                    loading={loading2}
                    onClick={() => {
                        toPng('2')
                    }}
                >
                    下载
                </Button>,
            ]}
            onCancel={() => {
                setVisible(false)
            }}
        >
            <div className='withdrawProveBox'>
                <div className='withdrawProve' style={style}>
                    <p>提现证明</p>
                    <p>
                        兹证明众包骑手<span>{detail.name}</span>，身份证号为：<span>{detail.idNumber}</span>，与我司建立外卖配送承揽关系，
                        <span>{detail.beginMonth && detail.beginMonth.replace(/-/, '年') + '月-' + detail.endMonth.replace(/-/, '年') + '月'}</span>
                        ，使用蜂鸟众包APP平台接收并配送外卖订单，跑单提现收入如下表，总计<span>{detail.totalCommissionAmount}</span>元。
                    </p>
                    <table border='1'>
                        <thead>
                            <tr>
                                <th>月份</th>
                                <th>收入（元人民币）</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detail.detailList &&
                                detail.detailList.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <th>{item.month.replace(/-/, '年') + '月'}</th>
                                            <th>{item.commissionAmount}</th>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                    <p>本公司对该证明的真实性负责，本证明仅用于合作骑手示明收入使用，不作为任何形式的担保文件。</p>
                    <p>以上情况，特此证明</p>
                    <p>
                        单位名称：<span>{detail.taxEnterpriseName}</span>
                    </p>
                    <p>单位公章：</p>
                    <p>
                        <span>{date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日'}</span>
                    </p>
                </div>
            </div>
        </Modal>
    )
}
export default forwardRef(EditModel)
