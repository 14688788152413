import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import {request} from '../../../../utils/fetch'
import { getCityList } from '../../../../api/api'
import { getCityListByProvinceId } from '../api'
import QueryForm from './components/QueryForm'
import TableList from './components/TableList'
import AddMoel from './components/AddMoel'
import SendMsgModel from './components/SendMsgModel'
import moment from 'moment'
function AccidentFile() {
    const [loading, setLoading] = useState(false)
    const [cityData, setCityData] = useState([])
    const TableListRef = useRef();
    const AddMoelRef = useRef();
    const SendMsgModelRef = useRef();
    useEffect(() => {
        getCityListByProvinceId().then((res)=>{
            if(res.data.success){
                setCityData(res.data.data)
            }
        })
    },[])
    const refresh = (val,data)=>{
        if(data&&data.companyCompensationAmountList){
            data.companyCompensationAmountList.forEach(element => {
                element.amountDate = moment(element.amountDate)
            });
        }
        if(data&&data.receivableAmountList){
            data.receivableAmountList.forEach(element => {
                element.amountDate = moment(element.amountDate)
            });
        }
        console.log(data)
        // return
        switch (val) {
            case "query":
                TableListRef.current.getlist(data);
              break;
            case "updata":
                // 这里报错
                AddMoelRef.current.showModal('编辑',Object.assign(data,{accidentDate: moment(data.accidentDate)}));
              break;
            case "view":
                AddMoelRef.current.showModal('查看',Object.assign(data,{accidentDate: moment(data.accidentDate)}));
              break;
            case "rows":
                TableListRef.current.handelExports();
              break;
            case "sendMsg":
                SendMsgModelRef.current.showModal(data);
              break;
          }
        console.log(123)
    }
    return (
        <Spin spinning={loading}>
            <QueryForm cityData={cityData} refresh={refresh}></QueryForm>
            <TableList refresh={refresh} ref={TableListRef}></TableList>
            <AddMoel refresh={refresh} ref={AddMoelRef} ></AddMoel>
            <SendMsgModel refresh={refresh} ref={SendMsgModelRef} ></SendMsgModel>
        </Spin>
    )
}

export default AccidentFile
