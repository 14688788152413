import React, { useState, useRef, useEffect } from 'react'
import {
    Spin,
    Form,
    Select,
    Input,
    Space,
    Button,
    Table,
    Row,
    Col,
    Radio,
    DatePicker,
    message,
    Modal
} from 'antd'
import { request } from '../../../../utils/fetch'
import { getCompanyAccount, getBankList, insertCompanyAccount, resetCompanyAccount } from '../api'
import { getTaxEnterpriseList, getCompletedTaxEnterpriseList } from '../../../../api/api'
import moment from 'moment'
import ShowModal from './components/ShowModal.js'
import BindingModal from './components/BindingModal.js'
import store from '../../../../store/index'
const { RangePicker } = DatePicker
const { Option } = Select

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}
const BankSon = (props) => {
    let path = 'platform:CompanyAccount'
    const [visible, setVisible] = useState()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [insertForm] = Form.useForm()
    const [resetModalForm] = Form.useForm()
    const [tableData, setTableData] = useState([])
    const [radio, setRadio] = useState(10)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [bankList, setBankList] = useState([])
    const [companyCode, setCompanyCode] = useState()
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const [CompletedTaxEnterpriseList, setCompletedTaxEnterpriseList] = useState([])
    const [msg, setMsg] = useState()
    const [resetVisible, setResetVisible] = useState(false)
    const BindingModalRef = useRef();
    const ShowModalRef = useRef();

      // form 表单域默认值
    const initialFormValues = {
        date: null,
        accountStatus: undefined,
        mobileConfirmStatus: undefined,
        keyword: '' // 关键字
    }

    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: 20,
        total: 0
    })

    const insertFormValues = {
        companyName: '',
        certificateNumber: '',
        bankNo: '',
        bankNumber: ''
    }

    const resetFormValues = {
        companyName: '',
        certificateNumber: '',
        bankNo: '',
        bankNumber: ''
    }


    // 
    
    // 获取银行列表
    const getBankLists = async() => {
        let res = await getBankList()
        console.log(res)
        if (res.data.success) {
            setBankList(res.data.data)
        }
    }
    const tableColumns = [
        { title: '创建时间', dataIndex: 'createTime', key: 'createTime', align: 'center' },
        // { title: '企业名称', dataIndex: 'companyName', key: 'companyName', align: 'center' },
        { title: '财税企业', dataIndex: 'taxEnterpriseName', key: 'taxEnterpriseName', align: 'center' },
        { title: '子账号企业', dataIndex: 'companyName', key: 'companyName', align: 'center' },
        { title: '统一社会信用代码', dataIndex: 'certificateNumber', key: 'certificateNumber', align: 'center' },
        { title: '开户行名称', dataIndex: 'openBankName', width: 120, align: 'center',
            render: (value, record) => {
                return record.accountStatus == 'FAIL' ? <span>{record.bankName}</span> : <span>{record.openBankName}</span>
            }
        },
        { title: '银行账号', dataIndex: 'baseAccountId', key: 'baseAccountId', align: 'center',
            render: (value, record) => {
                return record.accountStatus == 'FAIL' ? <span>{record.bankNumber}</span> : <span>{record.baseAccountId}</span>
            }
        },
        { title: '子户状态', dataIndex: 'accountStatus', width: 110, key: 'accountStatus', align: 'center',
            render: (accountStatus, record) => {
                switch (accountStatus) {
                    case 'SUCCESS':
                        return <span>开户成功</span>
                    case 'FAIL':
                        return <span>开户失败</span>
                    case 'YJY':
                        return <span>已解约</span>
                    case 'DYHSH':
                        return <span>待银行审核</span>
                    default: return ''
                }
            } 
        },
        { title: '确权手机号', dataIndex: 'mblNo', width: 120, key: 'mblNo', align: 'center' },
        { title: '确权状态', dataIndex: 'mobileConfirmStatus', width: 100, key: 'mobileConfirmStatus', align: 'center', 
        render: (mobileConfirmStatus, record) => {
            switch (mobileConfirmStatus) {
                case '01':
                    return <span>未确权</span>
                case '02':
                    return <span>已确权</span>
                default: return ''
            }
        } 
        },
        { title: '关联企业数', dataIndex: 'count', width: 110, key: 'count', align: 'center' },
        { title: '操作', key: 'control', width: 200, align: 'center', render: (record) => {
            const control = []
            store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&control.push(<a key="1" onClick={() => showDetail(record)}>查看</a>)
            store.getState().userReducer.permissions.indexOf(`${path}:againOpenCompanyAccount`)>-1&&record.accountStatus == 'FAIL' && control.push(<a key="2" onClick={() => resetOpenCompanyAccount(record)}>重新开户</a>)
            record.accountStatus == 'SUCCESS' && control.push(<a key="3" onClick={() => toDetail(record)}>查看流水</a>)
            store.getState().userReducer.permissions.indexOf(`${path}:companyBind`)>-1&&record.accountStatus == 'SUCCESS' && control.push(<a key="3" onClick={() => showBind(record)}>企业绑定</a>)
            const result = []
            control.forEach(item => {
                result.push(item)
            })

            return (
                <Space>{result}</Space>
            )
        }}
    ]
    // 
    const rangeChange = (dates) => {
        console.log(dates)
        if (dates === null) {
            setRadio(10)
        } else if (dates[1].format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
            let diff = dates[1].diff(dates[0], 'days')
            setRadio(diff + 1)
        } else {
            setRadio()
        }
    }
    // 时间radio选择
    const radioChange = e => {
        let startDate, endDate
        setRadio(e.target.value)
        startDate = moment().subtract(e.target.value - 1, 'days')
        endDate = moment()
        form.setFieldsValue({ date: [startDate, endDate] })
    }
    const resetForm = () => {
        form.resetFields()
        setRadio()
    }
    async function getDataLists(page) {
        const date = form.getFieldsValue().date
        const params = {
            beginTime: date ? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date ? moment(date[1]).format('YYYY-MM-DD') : '',
            keyword: form.getFieldsValue().keyword,
            accountStatus: form.getFieldsValue().accountStatus || '',
            mobileConfirmStatus: form.getFieldsValue().mobileConfirmStatus || '',
            taxEnterpriseCode: form.getFieldsValue().taxEnterpriseCode,
            pageSize: page.pageSize,
            pageNum: page.pageNum
        }
        try {
            setLoading(true)
            const result = await getCompanyAccount(params)
            if (result.data.success) {
                setTableData(result.data.data.list || [])
                setPagination(prev => {
                    return { ...prev, total: result.data.data.totalCount }
                })
            } else {
                message.error(result.data.retMsg)
            }
            setLoading(false)
        } catch (err) {
        setLoading(false)
            console.log('___银行子户', err)
        }
    } 
    // 获取所有财税企业
    const getTaxEnterpriseListall = async () => {
        const res = await getTaxEnterpriseList()
        if (res.data.success) {
            setTaxEnterpriseLis(res.data.data)
        }
    }
    // 获取所有财税企业
    const getCompletedTaxEnterprise = async () => {
        const res = await getCompletedTaxEnterpriseList()
        if (res.data.success) {
            setCompletedTaxEnterpriseList(res.data.data)
        }
    }
    useEffect(() => {
        getDataLists(pagination)
        getTaxEnterpriseListall()
        getCompletedTaxEnterprise()
        getBankLists()
    }, [])
    // 查询
    const search = () => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: 1
            }
        })
        getDataLists(pagination)
    }
    const refresh = () => {
        getDataLists(pagination)
    }
    // 
    const tablePageChange = (pageNum, pageSize) => {
        setPagination(prev => {
        return {
            ...prev,
            pageNum: pageNum
        }
        })
        getDataLists({ pageNum: pageNum, pageSize: pageSize })
    }
    // 新增
    const insert = () => {
        setVisible(true)
        
    }
    // 查看流水
    const toDetail = (record) => {
        props.history.push({pathname: `/admin/companyPipeline/detail/${record.companyCode}`, state: {companyName: record.companyName}})
    }
    // 重新开户
    const resetOpenCompanyAccount = (record) => {
        setResetVisible(true)
        setMsg(record.msg)
        resetModalForm.setFieldsValue({
            companyName: record.companyName,
            taxEnterpriseCode: record.taxEnterpriseCode,
            certificateNumber: record.certificateNumber,
            bankNo: record.bankNo,
            bankNumber: record.bankNumber
        })
        setCompanyCode(record.companyCode)
    }
    // 详情
    const showDetail = (record) => {
        // setCompanyCode(record.companyCode)
        ShowModalRef.current.showModal(record)
    }
    //企业绑定
    const showBind = (record)=>{
        BindingModalRef.current.showModal(record)
    }

    const onCancel = () => {
        setVisible(false)
        insertForm.resetFields()
    }

    const resetCancel = () => {
        setResetVisible(false)
        resetModalForm.resetFields()
    }

    const insertSubmit = async() => {
        const data = Object.assign({}, insertForm.getFieldsValue())
        try {
            await insertForm.validateFields()
            setConfirmLoading(true)
            const result = await insertCompanyAccount(data)
            setConfirmLoading(false)
            if (result.data.success) {
                onCancel()
                message.success('新增成功!')
                search()
            } else {
                message.error(result.data.retMsg)
            }
        } catch(err) {
            setConfirmLoading(false)
            console.log('__新增银行子户', err)
        }
    }

    const resetSubmit = async() => {
        const data = Object.assign({}, resetModalForm.getFieldsValue(), { companyCode: companyCode })
        try {
            await resetModalForm.validateFields()
            setConfirmLoading(true)
            const result = await resetCompanyAccount(data)
            setConfirmLoading(false)
            if (result.data.success) {
                resetCancel()
                message.success('提交成功!')
                search()
            } else {
                message.error(result.data.retMsg)
            }
        } catch(err) {
            setConfirmLoading(false)
            console.log('__重新开户', err)
        }
    }

    return (
        <Spin spinning={loading}>
            <Form initialValues={initialFormValues} form={form}>
                <Row gutter={4}>
                    <Col span={6}>
                        <Form.Item name="date" label="时间" style={{marginLeft: '28px'}}>
                        <RangePicker onChange={(dates) => rangeChange(dates)} allowClear={false} format="YYYY-MM-DD" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <Radio.Group onChange={radioChange} value={radio}>
                                <Radio value={1}>今天</Radio>
                                <Radio value={7}>近7天</Radio>
                                <Radio value={30}>近30天</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item name="accountStatus" label="子户状态">
                            <Select allowClear style={{width: '260px'}} placeholder="全部">
                                <Option value="DYHSH">待银行审核</Option>
                                <Option value="SUCCESS">开户成功</Option>
                                <Option value="FAIL">开户失败</Option>
                                <Option value="YJY">已解约</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="mobileConfirmStatus" label="确权状态">
                            <Select allowClear style={{width: '260px'}} placeholder="全部">
                                <Option value="01">未确权</Option>
                                <Option value="02">已确权</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item name="taxEnterpriseCode" label="财税企业">
                        <Select allowClear style={{width: '260px'}} placeholder="全部">
                            {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                        </Select>
                    </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="keyword" label="关键字">
                        <Input autoComplete="off" style={{width: '260px'}} placeholder="关联的企业名称丨确权手机号" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Space>
                        <Button type="primary" onClick={search}>查询</Button>
                        <Button onClick={resetForm}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:add`)>-1&&<Button onClick={insert}>新增</Button>}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                columns={tableColumns}
                dataSource={tableData}
                scroll={{ x: 1600, y: 551 }}
                rowKey={record=>record.capitalAcctNo}
                pagination={{
                position: ['bottomCenter'],
                    showSizeChanger: false,
                    total: pagination.total,
                    current: pagination.pageNum,
                    pageSize: pagination.pageSize,
                    showTotal:(total) =>  `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize)　=> tablePageChange(pageNum, pageSize)
                }}>
            </Table>

            <Modal maskClosable={false}
                title="新增子户" 
                visible={visible} 
                onCancel={onCancel}
                confirmLoading={confirmLoading}
                onOk={insertSubmit} 
                width={850}>
                <Form {...formItemLayout} form={insertForm} initialValues={insertFormValues}>
                    <Row gutter={0}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item name="taxEnterpriseCode" rules={[{required: true}]} label="财税企业">
                                <Select allowClear placeholder="请选择财税企业">
                                    {CompletedTaxEnterpriseList.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item name="companyName" rules={[{required: true}]} label="子账号企业">
                                <Input placeholder="请输入" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item name="certificateNumber" rules={[{required: true}]} label="统一社会信用代码">
                                <Input placeholder="请输入" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item name="bankNo" rules={[{required: true}]} label="开户行名称">
                                <Select placeholder="请选择">
                                    {
                                        bankList.map(item => (
                                            <Option value={item.bankNo} key={item.bankNo}>{item.bankName}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item name="bankNumber" rules={[{required: true}]} label="银行账号">
                                <Input placeholder="请输入" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>    
            </Modal>
            <Modal maskClosable={false}
                title="重新开户" 
                visible={resetVisible} 
                onCancel={resetCancel}
                confirmLoading={confirmLoading}
                onOk={resetSubmit} 
                width={850}>
                <Form {...formItemLayout} form={resetModalForm} initialValues={resetFormValues}>
                    <Row gutter={0}>
                        <Col span={24} >
                            <Form.Item {...{ labelCol: { span: 4 }, wrapperCol: { span: 20 }, }} rules={[{required: true}]} label="开户失败原因">
                                <div>{msg}</div>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item name="taxEnterpriseCode" rules={[{required: true}]} label="财税企业">
                                <Select disabled allowClear placeholder="请选择财税企业">
                                    {CompletedTaxEnterpriseList.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item name="companyName" rules={[{required: true}]} label="企业名称">
                                <Input disabled placeholder="请输入" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item name="certificateNumber" rules={[{required: true}]} label="统一社会信用代码">
                                <Input placeholder="请输入" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item name="bankNo" rules={[{required: true}]} label="开户行名称">
                                <Select placeholder="请选择">
                                    {
                                        bankList.map(item => (
                                            <Option value={item.bankNo} key={item.bankNo}>{item.bankName}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item name="bankNumber" rules={[{required: true}]} label="银行账号">
                                <Input placeholder="请输入" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>    
            </Modal>
            <ShowModal 
                ref={ShowModalRef}
            />
            <BindingModal
                refresh={refresh}
                ref={BindingModalRef}
            />
        </Spin>
    )
}

export default BankSon