import React, { useImperativeHandle, useState, forwardRef } from 'react'
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  message,
  Select,
  Upload,
  Timeline
} from 'antd';
import { securityEventgetDetail } from '../api'
import { getCodeUrl, getProcessTask } from '../../../../../api/api'
import '../index.css'
const { TextArea } = Input;
const { Option } = Select;
const topCol = {
  span: 12,
}

const ViewMoel = forwardRef((props,ref) => {
  const [visible, setVisible] = useState(false);
  const [showFile, setShowfile] = useState(false);
  const [recordData, setRecordData] = useState({});
  const [spData, setSpData] = useState([]);
  const [NailQRcode, setNailQRcode] = useState(null);
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileListpf: '',
    previewTitle: '',
  })
  const [form] = Form.useForm()
  const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU:1.重大刑事案件;2.涉及刑事案件对公司品牌造成严重影响</p>
      <p className="titp">P0:1.普通刑事案件对公司造成不良品牌影响;2.殴打政府工作人员</p>
      <p className="titp">P1:涉及普通刑事犯罪,未造成不良品牌影响造成不良品牌影响治安事件</p>
      <p className="titp">P2:未造成不良影响的治安案件</p>
      <p className="titp">P3:扬言要杀人跳楼自杀等事件辱骂威胁骚扰等其他案件</p>
    </div>
  )
  const shixiao = <div className="titbox">客服响应时间-首次响应时间</div>
  const shixiaocz = <div className="titbox">处置回复时间-客服响应时间</div>
  const shixiaojz = <div className="titbox">结案时间-接报或上报时间</div>
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (record,showfile) => {
      setVisible(true)
      setShowfile(showfile)
      getDetail(record,showfile)
    }
  }));
  const getDetail = async (record,showfile)=>{
    let result = await securityEventgetDetail({eventCode:record.eventCode,type:0})
    if (result.data.success) {
      let data =result.data.data 
      if(showfile){
        data.attachmentUrl = setFlieList(data.attachmentUrl)
      }
      setRecordData(data)
      getProcess(data)
      if(data.processInstanceId){
        getQRcode(data)
      }else{
        setNailQRcode('')
      }
      form.setFieldsValue(data)
    } else {
      message.error(result.data.retMsg)
    }
  }
  // 获取钉钉审批二维码
  const getQRcode = async (row)=>{
      let result = await getCodeUrl({processInstanceId:row.processInstanceId})
      if (result.data.success) {
        setNailQRcode(result.data.data)
      } else {
        message.error(result.data.retMsg)
      }
  }
  // 获取钉钉审批节点
  const getProcess = async (row)=>{
      let result = await getProcessTask({ no: row.eventCode })
      if (result.data.success) {
        setSpData(result.data.data)
      } else {
        message.error(result.data.retMsg)
      }
  }
  const setFlieList = (list) => {
    list = list&&list.filter(item=>{
      return item.url
    })
    list = list&&list.map((item,index)=>{
      return {
        url: item.url,
        uid: item.uid || index,
        name: item.name||item.url,
        status: 'done',
      }
    })
    return list
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  const handleCancel = () => {
    setPreviewData(prev => {
      return {
        ...prev,
        previewVisible: false
      }
    })
  }
  return (
    <>
      <Modal maskClosable={false}
        title="查看"
        width='1000px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={null}
      >
        <Form className="addform" form={form}>
          <Row>
            <Col {...topCol}>
              <Form.Item name="dealStatusName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="当前处理进度"  >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...topCol}>
              <Form.Item name="reportOriginatorUserName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="报备发起人"  >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseOriginatorUserName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="结案发起人"  >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="createTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="提交审批时间"  >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="processStatusName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="流程状态" >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventSourceName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件来源">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件工单号">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventReportTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="接报或上报时间">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="注册城市">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventClassifyName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件分类">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventLevel" label="事件等级" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} tooltip={{ title: content, color: '#FFFFFF' }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="name" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="骑手姓名">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eleCourierId" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="骑手ID">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="手机号">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="idNumber" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="身份证号">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事件发生时间">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发城市">
                <div style={{display: 'flex', justifyContent: 'space-between'}} >
                  <Form.Item style={{ marginBottom: 0, width: '100px' }} name="eventProvinceName" >
                    <Input disabled />
                  </Form.Item>
                  <span style={{display: 'inline-block',height: '32px', lineHeight: '32px', margin: '0 5px'}} >-</span>
                  <Form.Item style={{flex: '1', marginBottom: 0}} name="eventCityName" >
                    <Input disabled />
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
            {/* <Col {...topCol}>
              <Form.Item name="eventRegionName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发区" >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventStreetName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="事发街道" >
                <Input disabled={true} />
              </Form.Item>
            </Col> */}
            <Col {...topCol}>
              <Form.Item name="firstAnswerTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="首次响应时间">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="serverAnswerTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="客服响应时间">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseResponseEffective" label="案件响应时效" tooltip={{ title: shixiao, color: '#FFFFFF' }} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="dealComment" label="督导处置意见" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea autoSize={{ minRows: 2}} disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dealWithTime" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="处置回复时间">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dealWithTimeEffective" label="处置回复时效" tooltip={{ title: shixiaocz, color: '#FFFFFF' }} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventPlace" label="事件发生地址" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventScene" label="事件发生情景" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventReason" label="事件发生起因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="badBehavior" label="不良行为" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventDescribe" label="事件经过描述" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea autoSize={{ minRows: 2}} disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="workFlag" label="跑单时段" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }}>
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="sensitiveName" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="涉及敏感人群">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="reportedFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="是否报警">
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="judgedFlag" label="是否判责" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="involvedDuty" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="涉及责任方">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dutyJudge" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="责任认定">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="customerDemand" label="客户诉求" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="customerDemandFlag" className='labelwarp' labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="客户诉求是否达成一致">
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riskyFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} label="是否有潜在风险">
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riskDescribe" label="风险描述" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea autoSize={{ minRows: 2}} disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="solutionDescribe" label="解决方案" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea autoSize={{ minRows: 2}} disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderViolateProject" label="骑手违规项目" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderViolateReason" label="骑手违规原因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...topCol}>
              <Form.Item name="greyInvisibleFlag" className='labelwarp' label="是否需要隐形拉灰" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Select disabled showArrow={false} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="requireDescribe" label="甲方要求" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="payAmount" label="费用支出金额" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="compensateReason" label="赔偿原因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseFinishTime" label="结案时间" labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseFinishTimeEffective" label="结案时效" tooltip={{ title: shixiaojz, color: '#FFFFFF' }} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="caseFinishRemark" label="结案备注" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea autoSize={{ minRows: 2}} disabled />
              </Form.Item>
            </Col>
          </Row>
          {showFile&&<Row>
            <Col span={24}>
              <div style={{marginBottom: '20px'}} >
                <h2 className="equip-h2">附件</h2>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item name='attachmentUrl' label="赔付凭证" valuePropName="fileList" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <Upload
                  accept='.jpg,.jpeg,.png'
                  listType="picture-card"
                  name="files"
                  fileList={previewData.fileListpf}
                  onPreview={handlePreview}
                >
                </Upload>
              </Form.Item>
            </Col>
          </Row>}
          <Row>
            <Col span={24}>
              {(recordData.processStatus=='2'||recordData.processStatus=='3'||recordData.processStatus=='4')&&NailQRcode&&<div style={{marginBottom: '20px'}} >
                <h2 className="equip-h2">钉钉审批二维码</h2>
                <div>
                  <img style={{width: '150px', height: '150px'}} src={NailQRcode}  />
                </div>
              </div>}
            </Col>
            <Col span={24}>
              {spData.length > 0&&<div style={{marginBottom: '20px'}} >
                <h2 className="equip-h2">审批节点</h2>
                <Timeline>
                {spData.map((item,index) => (
                    <Timeline.Item key={index}>
                      {!item.afterStatus&&<span>{item.auditUserName} 发起 {item.time}</span>}
                      {item.afterStatus==3&&<span>{item.auditUserName} 通过 {item.time}</span>}
                      {item.afterStatus==3&&item.auditNote&&<span>理由： {item.auditNote}</span>}
                      {item.afterStatus == 4&&<span>{item.auditUserName} 拒绝 {item.time}</span>}
                      {item.afterStatus == 4&&item.auditNote&&<span>理由： {item.auditNote}</span>}
                    </Timeline.Item>
                  ))}
                </Timeline>
              </div>}
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal maskClosable={false}
          width={1000}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
      </Modal>
    </>
  );
});
export default ViewMoel



