import React, { useImperativeHandle, useState, forwardRef } from 'react'
import {
  Modal,
  Form,
  Divider,
  Row,
  Col,
  message,
  Input,
  Button,
  Select
} from 'antd';
import { contractTempGetDetail } from '../../api'
const topCol = {
  span: 12,
}
const topCol24 = {
  span: 24,
}
const colSpan = {
  labelCol: { flex: '150px' }, wrapperCol: { flex: 1 }
}
const fieldLists = { 
  name: "姓名", 
  idNumber: "身份证号", 
  financeUserName: "系统财务姓名", 
  phone: "手机号", 
  companyName: "公司名称", 
  financeUserIdNumber: "系统财务身份证号", 
  idNumberAddress: "身份证地址" 
}
const ViewMoel = (props,ref) => {
  const [visible, setVisible] = useState(false);
  // const [fileLists, setFileLists] = useState([])
  const [form] = Form.useForm()
  const initialFormValues = {}
  const { Option } = Select
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (record) => {
      form.resetFields()
      getDetail(record)
      
    }
  }));
  const getDetail = (record)=>{
    contractTempGetDetail({templateNo:record.templateNo}).then(res=>{
      if(res.data.success){
        setVisible(true);//fieldList
        let data = res.data.data
        data.detailList = data.detailList.map(ele=>{
          console.log(ele)
          let fieldList = ele.fieldList || []
          fieldList = fieldList.map(v=>{
            return fieldLists[v]
          })
          if(fieldList.length>0){
            ele.fieldList = fieldList.join(',')
          }else{
            ele.fieldList = '无'
          }
          return ele
        })
        form.setFieldsValue(res.data.data)
      }else{
          message.error(res.data.retMsg)
      }
    })
  }
  const showPdf = (fieldKey)=>{
     console.log(fieldKey)
     console.log('data',form.getFieldValue());
     let values = form.getFieldValue()
     let pdfUrl = values.detailList[fieldKey].pdfUrl
     window.open(`/generic/web/viewer.html?file=${encodeURIComponent(pdfUrl)}` )
  }

  return (
    <>
    <Modal maskClosable={false} title="查看" width='1000px' forceRender visible={visible} onCancel={() => { setVisible(false) }} footer={null} >
      <Form initialValues={initialFormValues} form={form}>
        <Row className="margin-15">
          <Col {...topCol}>
            <Form.Item name='companyName' label='企业名称' {...colSpan}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col {...topCol}>
            <Form.Item name='status' label='状态' {...colSpan}>
              <Select disabled showArrow={false} >
                <Option value={1}>启用</Option>
                <Option value={0}>停用</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col {...topCol}>
            <Form.Item name='createUser' label='创建人' {...colSpan}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col {...topCol}>
            <Form.Item name='createTime' label='创建时间' {...colSpan}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Form.List name="detailList">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={fieldKey}>
                <Row style={{width:'100%'}}>
                  <Col {...topCol}>
                    <Form.Item {...restField} label={`签署文件${fieldKey+1}`} fieldKey={[fieldKey, 'pdfUrl']} {...colSpan} >
                      <Button type="link" onClick={()=>{showPdf(fieldKey)}} >预览</Button>
                    </Form.Item>
                  </Col>
                  <Col {...topCol}></Col>
                  <Col {...topCol}>
                    <Form.Item {...restField} label={`签署文件${fieldKey+1}的落地方案`} name={[name, 'planTypeName']} fieldKey={[fieldKey, 'planTypeName']} {...colSpan} >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col {...topCol24}>
                    <Form.Item {...restField} label={`签署文件${fieldKey+1}的财税`} name={[name, 'taxEnterpriseName']} fieldKey={[fieldKey, 'taxEnterpriseName']} {...colSpan} >
                      <Input disabled style={{width: '70%'}} />
                    </Form.Item>
                  </Col>
                  <Col {...topCol24}>
                    <Form.Item {...restField} label={`签署文件${fieldKey+1}的填充信息`} name={[name, 'fieldList']} fieldKey={[fieldKey, 'fieldList']} {...colSpan} >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider style={{ margin: '15px 0px', width: '80%', borderColor: '#C0C4CC' }} dashed />
                </div>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
    </>
  );
};
export default forwardRef(ViewMoel)



