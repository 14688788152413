import React, { useState, useEffect } from 'react'
import {
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Button,
  Table,
  Radio,
  Space,
  Spin,
  Modal,
  message
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import './index.css'
import CheckModal from '../ladderRefund/components/CheckModal'
import { withdrawal, getFee, abcEnterpriseAccountDealGetList } from '../api'
import { getCompanyList } from '../../../../api/api'
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 20,
    }}
    spin
  />
);

const PlatformRecord = (props) => {
  let path = 'platform:Financialdeal'
  let path1 = 'platform:abcEnterpriseAccountDeal'
  const [form] = Form.useForm()
  const [modalForm] = Form.useForm()
  const [bankForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [radio, setRadio] = useState(30)
  const [tableData, setTableData] = useState([])
  const [type, setType] = useState()
  const [taxEnterpriseCode, settaxEnterpriseCode] = useState('')
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [checkVisible, setCheckVisible] = useState(false)
  const [backNo, setBackNo] = useState()
  const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
  const [spinLoading, setSpinLoading] = useState(false);
  const [balance, setBalance] = useState({
    taxAmountBalance: '',
    taxAmountAmountTotal: '',
    taxAcctName:'',
    taxAcctNo: '',
    serviceAmountBalance: '',
    serviceAmountTotal: '',
    serviceAcctName:'',
    serviceAcctNo: '',
  })
  const [visible, setVisible] = useState(false)
  const [bankVisible, setBankVisible] = useState(false)
  const [fee, setFee] = useState({
    accountService: "",
    accountTax: ""
  })
  // form 表单域默认值
  const initialFormValues = {
    date: [moment().subtract(29, 'days'), moment()],
    cityIdList: [],
    countyIdList: [],
    dealType: [],
    companyCode: [] // 关键字
  }
  // 提现表单
  const initialModalValues = {
    acctName: '',
    acctNo: '',
    typeName: ''
  }
  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: 20,
    total: 0
  })
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  // 获取服务费税费余额 银行账号
  const getBalances = async(code) => {
    setSpinLoading(true)
    try {
      const res = await getFee({taxEnterpriseCode: code||taxEnterpriseCode})
      setSpinLoading(false)
      setFee(prev => {
        return {
          ...prev,
          ...res.data.data
        }
      })
      setBalance(prev=>{
        return {
          ...prev,
          taxAcctName: res.data.data.taxAcctName,
          taxAcctNo: res.data.data.taxAcctNo,
          serviceAcctName: res.data.data.serviceAcctName,
          serviceAcctNo: res.data.data.serviceAcctNo,
        }
      })
    } catch(err) {
      console.log(err)
      setSpinLoading(false)
    }
  }
  //
  const getList = async(page,code) => {
    const { date, dealType, companyCode, type } = form.getFieldsValue()
    const params = {
      beginTime: moment(date[0]).format('YYYY-MM-DD'),
      endTime: moment(date[1]).format('YYYY-MM-DD'),
      dealType: dealType,
      companyCode: companyCode,
      taxEnterpriseCode: code||taxEnterpriseCode,
      pageSize: page.pageSize,
      pageNum: page.pageNum,
      type: type
    }
    try {
      setLoading(true)
      const result = await abcEnterpriseAccountDealGetList(params)
      if (result.data.success) {
        setPagination(prev => {
          return {
            ...prev,
            total: result.data.data.totalCount
          }
        })
        setBalance(prev => {
          return {
            ...prev,
            taxAmountAmountTotal: result.data.data.taxAmountAmountTotal,
            serviceAmountTotal: result.data.data.serviceAmountTotal
          }
        })
      }
      setTableData(result.data.data.list)
      setLoading(false)
    } catch(err) {
      setLoading(false)
    }
    
  }
  useEffect(() => {
    getTaxEnterpriseListall()
  }, [])
  // 时间radio选择
  const radioChange = e => {
    let startDate, endDate
    setRadio(e.target.value)
    startDate = moment().subtract(e.target.value - 1, 'days')
    endDate = moment()
    form.setFieldsValue({date: [startDate, endDate]})
  }
  // 列表字段
  const tableColumns = [
    {
      title: '子户类型',
      dataIndex: 'typeName',
      key: 'typeName',
      align: 'center'
    },{
      title: '交易时间',
      dataIndex: 'dealTime',
      key: 'dealTime',
      align: 'center'
    },{
      title: '交易类型',
      dataIndex: 'dealTypeName',
      key: 'dealTypeName',
      align: 'center'
    },{
      title: '金额',
      dataIndex: 'dealAmount',
      key: 'dealAmount',
      align: 'center',
    }
  ]

  // 获取所有财税企业
  const getTaxEnterpriseListall = async () => {
    const res = await getCompanyList()
    if (res.data.success) {
      await setTaxEnterpriseLis(res.data.data)
      await settaxEnterpriseCode(res.data.data[0].taxEnterpriseCode)
      await getList(pagination,res.data.data[0].taxEnterpriseCode)
      await getBalances(res.data.data[0].taxEnterpriseCode)
    }
  }

  // 申请提现
  const withdraw = (type) => {
    setType(type)
    setVisible(true)
    let typeName = ''
    if (type == 1) {
      typeName = '服务费'
    } else {
      typeName = '税费'
    }
    modalForm.setFieldsValue({
      acctName: type == 1 ? balance.serviceAcctName : balance.taxAcctName,
      acctNo: type === 1 ? balance.serviceAcctNo : balance.taxAcctNo,
      typeName: typeName
    })
  }
  // 重置
  const resetForm = () => {
    form.resetFields()
    setRadio(30)
  }
  // 导出
  const exportData = () => {
    const { date, dealType, companyCode, type } = form.getFieldsValue()
    const data = {
      beginTime: moment(date[0]).format('YYYY-MM-DD'),
      endTime: moment(date[1]).format('YYYY-MM-DD'),
      type: type,
      dealType: dealType,
      companyCode: companyCode,
      taxEnterpriseCode: taxEnterpriseCode
    }
    request.downExport('/platform/abcEnterpriseAccountDeal/export', data)
  }
  const exportBankStatement = ()=>{
    setBankVisible(true)
    bankForm.resetFields()
  }
  const exportBank = ()=>{
    const { date } = bankForm.getFieldsValue()
    if(!date){
      message.error('请选择日期')
      return
    }
    let data = {
      taxEnterpriseCode: taxEnterpriseCode,
      startDate: moment(date).format('YYYYMMDD'),
      endDate: moment(date).format('YYYYMMDD')
    }
    request.downExport('/platform/abcEnterpriseAccountDeal/exportDeal', data)
    setBankVisible(false)
  }
  const bankCancel = ()=>{
    setBankVisible(false)
  }
  //分页改变
  const tablePageChange = (page, pageSize) => {
    setPagination({
      pageNum: page
    })
    getList({pageNum: page, pageSize: pageSize})
  }
  const submit = () => {
    form.setFieldsValue()
    getBalances()
    getList({pageNum: 1, pageSize: 20})
  }
  const resetModal = () => {
    setType()
    modalForm.resetFields()
    setVisible(false)
  }
  // 
  const modalCancel = () => {
    resetModal()
  }
  // 
  const submitModal = async() => {
    const { amount } = modalForm.getFieldsValue()
    if (!amount) {
      message.error('请填写提现金额')
      return
    }
    if (type == 1 && amount > Number(fee.accountService)) {
      message.error('提现金额不能大于余额')
      return
    }
    if (type == 2 && amount > Number(fee.accountTax)) {
      message.error('提现金额不能大于余额')
      return
    }
    let data = {
      type: type,
      amount: amount,
      taxEnterpriseCode: taxEnterpriseCode
    }
    try {
      setConfirmLoading(true)
      const res = await withdrawal(data)
      console.log(res)
      if (res.data.success) {
        message.success('提现成功！')
        getList(pagination)
        resetModal()
      } else {
        message.error(res.data.retMsg)
      }
      getBalances()
      setConfirmLoading(false)
    } catch(err) {
      setConfirmLoading(false)
      console.log('__提现', err)
    }
  }
  const close = () => {
    setCheckVisible(false)
  }
  const selChange = (value) => {
    settaxEnterpriseCode(value)
    getList(pagination,value)
    getBalances(value)
  }
  return (
    <Spin spinning={loading}>
      <div className="contract-lists" style={{background: '#fff'}}>
        <div className="selebox">
          <Select style={{width:'300px'}} onChange={selChange} value={taxEnterpriseCode} allowClear>
            {TaxEnterpriseLis.map((e)=><Option key={e.taxEnterpriseCode} value={e.taxEnterpriseCode}>{e.enterprisName}</Option>)}
          </Select>
        </div>
        <div className="page-top">
          <div className="balance-box">
            <div>服务费（余额）</div>
            <div className="balance-fl">
              <div className="balance" >
                <div>
                  ￥
                </div> 
                <Spin spinning={spinLoading} indicator={antIcon} >
                  {fee.accountService}
                </Spin>
              </div>
              {store.getState().userReducer.permissions.indexOf(`${path1}:withDraw`)>-1&&<Button className="withdraw" type="primary" disabled={Number(fee.accountService)<=0} onClick={() => withdraw(1)}>申请提现</Button>}
            </div>
          </div>
          <div className="balance-box">
            <div>税费（余额）</div>
            <div className="balance-fl">
              <div className="balance">
                <div>
                ￥
                </div> 
                <Spin spinning={spinLoading} indicator={antIcon} >
                  {fee.accountTax}
                </Spin>
              </div>
              {store.getState().userReducer.permissions.indexOf(`${path1}:withDraw`)>-1&&<Button className="withdraw" type="primary" disabled={Number(fee.accountTax)<=0} onClick={() => withdraw(2)}>申请提现</Button>}
            </div>
          </div>
        </div>

        <Form labelCol={{ span: 6 }} initialValues={initialFormValues} form={form}>
          <Row>
            <Col span={6}>
              <Form.Item name="date" label="交易时间">
                <RangePicker allowClear={false} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            
            <Form.Item style={{width: '600px', marginLeft: '15px'}}>
              <Radio.Group onChange={radioChange} value={radio}>
              <Radio value={1}>今天</Radio>
              <Radio value={7}>近7天</Radio>
              <Radio value={30}>近30天</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item wrapperCol={{ span: 16 }} name="type" label="子户类型">
                <Select placeholder="全部" allowClear>
                  <Option value="1">服务费</Option>
                  <Option value="2">税费</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item wrapperCol={{ span: 16 }} name="dealType" label="交易类型">
                <Select placeholder="全部" allowClear>
                  <Option value="1">入金</Option>
                  <Option value="2">提现</Option>
                  <Option value="3">退费</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: 'right'}}>
              <Space>
                <Button type="primary" onClick={submit}>查询</Button>
                <Button onClick={resetForm}>重置</Button>
                {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button onClick={exportData}>导出</Button>}
                {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button onClick={exportBankStatement}>导出银行流水</Button>}
              </Space>
            </Col>                              
          </Row>
        </Form>

        {/* <div style={{paddingLeft: '20px'}}>服务费合计：{balance.serviceAmountTotal}元 税费合计：{balance.taxAmountAmountTotal}元</div> */}
        <Table 
          columns={tableColumns} 
          dataSource={tableData}
          scroll={{ x: 1600, y: 360 }}
          rowKey="dealNo"
          pagination={{
            position: ['bottomCenter'],
            total: pagination.total,
            current: pagination.pageNum,
            pageSize: pagination.pageSize,
            showTotal:(total) =>  `共 ${total} 条数据`,
            onChange: (page, pageSize) => tablePageChange(page, pageSize)
          }}
        >

        </Table>
      </div>
      <Modal maskClosable={false} title="申请提现" confirmLoading={confirmLoading} visible={visible} onOk={submitModal} onCancel={modalCancel} width={740}>
        <Form labelCol={{span: 3}} form={modalForm} initialValues={initialModalValues}>
          <Form.Item name="acctName" label="收款户名">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item name="acctNo" label="收款账户">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item name="typeName" label="类型">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="余额">
          {
            type == 1 && <Input value={fee.accountService} disabled></Input>
          }
          {
            type == 2 && <Input value={fee.accountTax} disabled></Input>
          }
            
          </Form.Item>
          <Form.Item name="amount" label="提现金额">
            <Input></Input>
          </Form.Item>
        </Form>
      </Modal>

      <Modal maskClosable={false} title="导出银行流水" visible={bankVisible} onOk={exportBank} forceRender onCancel={bankCancel} width={400}>
        <Form labelCol={{flex: '70px'}} form={bankForm} initialValues={{date: ''}}>
          <Form.Item name="date" label="选择日期">
            <DatePicker disabledDate={disabledDate} />
          </Form.Item>
        </Form>
      </Modal>
      
      <CheckModal
        visible={checkVisible}
        close={close}
        backNo={backNo}
      />

    </Spin>
  )
}

export default PlatformRecord