import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  Modal,
  message,
  DatePicker,
  Popconfirm,
  Tag,
  Space
 } from 'antd';
import qs from 'qs'
 import moment from 'moment';
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
import { getTaxEnterpriseList }　from '../../../../api/api'
 const FormItem = Form.Item;
const { Option } = Select;
 const { RangePicker } = DatePicker;
 const itemStyle = {
     width:'360px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }

function BankSlip(props) {
    let path = 'platform:Receipt'
    const [form] = Form.useForm();
    const initSearch = {
        date:[],
        companyCode:''
    }
    const [dayValue, setDayValue] = React.useState('');
    const [tableData,setTableData] = useState([])
    const [companyList,setCompanyList] = useState([])
    const [month, setMonth] = React.useState('');
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [isDetail,setIsDetail] = useState(false)
    const [parmObj,setParmObj] = useState({})       //传给明细的参数
    const [visible, setVisible] = React.useState(false);
    const [detail,setDetail] = useState({})
    const [selectRow,setSelectRow] = useState([])
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    
    const columns = [
        {
            title: '支付完成时间',
            dataIndex: 'finishTime',
            key: 'finishTime',
            width: 150,
            align:'center',
        }, {
            title: '企业名称',
            dataIndex: 'companyCodeName',
            key: 'companyCodeName',
            width: 150,
            align:'center',
        },{
            title: '财税企业',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: 150,
            align: 'center'
          },{
            title: '金额',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            width: 150,
            align:'center',
            
        }, {
            title: '状态',
            dataIndex: 'statusName',
            key: 'statusName',
            width: 100,
            align:'center',
            
        }, 
      
        {
        title: '操作',
        key: 'action',
        width: 320,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            store.getState().userReducer.permissions.indexOf(`${path}:download`)>-1&&record.statusName == '已生成'? <Space size="middle">
              <a onClick={() => {
                       var downloadUrl = window.location.origin + record.localUrl,
                       fileName = record.fileName
             
                     var loadUrl =
                       `/wispay/common/pageUrlDownload?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&fileName=${fileName}&urlStr=${downloadUrl}`
                     window.location.href = loadUrl
                } }>下载</a>
                <a onClick={() => {
                     setDetail(record)
                     setVisible(true)
                } }>查看</a>
            </Space>
            :null
        ),
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="支付完成时间" name="date" style={{marginLeft:'40px'}}>
                        <RangePicker 
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem label="企业名称" name="companyCode" style={itemStyle} {...labelStyleObj}>
                        <Select>
                            {
                                companyList.map(item => {
                                    return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                                })
                            }
                        </Select>
                    </FormItem>
                    <FormItem name='taxEnterpriseCode' label="财税企业" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                          {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                        </Select>
                    </FormItem>
                    <FormItem  label="状态" name="status" style={itemStyle} {...labelStyleObj}>
                        <Select>
                            <Select.Option value={'0'}>未生成</Select.Option>
                            <Select.Option value={'1'}>已生成</Select.Option>
                        </Select>
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        {store.getState().userReducer.permissions.indexOf(`${path}:download`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleAnyDown}>批量下载</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').format('YYYY-MM-DD'),
            endDate = moment().format('YYYY-MM-DD');
        
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
         
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if(formObj.date.length > 1){
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }else{
                beginTime = ''
                endTime = ''
            }
            let obj = {
                beginTime:beginTime,
                endTime:endTime,
                companyCode:formObj.companyCode,
                source:formObj.source,
                status:formObj.status,
                taxEnterpriseCode:formObj.taxEnterpriseCode,
            }
            return obj
        },
      
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/receipt/getList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        handleAnyDown(){
            if(selectRow.length < 1){
                message.error('请勾选需要下载的列表！');
                return
            }
            let result = selectRow.map(item => {
               return item.receiptNo
            })
            request.downExport('/platform/receipt/bathDownload',{receiptNoList:result})

        },
        async getCompanyList(){
            let result = await request.get('/platform/companyBase/allList')
               setCompanyList(result)
        },
         // 获取所有财税企业
        async getTaxEnterpriseListall(){
            const res = await getTaxEnterpriseList()
            if (res.data.success) {
              setTaxEnterpriseLis(res.data.data)
            }
          },
        tableChange(key,row){
            setSelectRow(row)
        },
        handleExport(){
            request.downExport('/platform/receipt/export',methodsFn.getsearchObj())
        }
    }
    useEffect(() => {
        methodsFn.getCompanyList()
        methodsFn.getTaxEnterpriseListall()
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
             <div>
                <div>
                     {myComponent.renderSearchForm()}
                     <Table 
                       rowSelection={{
                        type: "checkbox",
                        onChange:methodsFn.tableChange,
                      }}
                         scroll={{ x: 'max-content',y: 500 }}
                         columns={columns} 
                         dataSource={tableData} 
                         rowKey={(record) => record.receiptNo}
                         pagination={{
                            position: ['bottomCenter'],
                            total: total,
                            current: pageNum,
                            pageSize: pageSize,
                            showSizeChanger:false,
                            showTotal:(total) =>  `共 ${total} 条数据` ,
                            onChange: (pageNum, pageSize) => setPageNum(pageNum)
                        }}
                     />
                 </div>
                 <Modal maskClosable={false}
                    title='查看'
                    width='1000px'
                    visible={visible}
                    footer={null}
                    onCancel={() => setVisible(false)}>
                    <h2>银行回单</h2>
                    <iframe src={detail.localUrl} width="100%" height="500px">
                        你的浏览器暂不支持预览，请下载查看！
                    </iframe>
                </Modal>
            </div>
    )
}

export default BankSlip