import React, { useImperativeHandle, useState, forwardRef } from 'react'
import { Form, Modal, Input, Tree, Button, message } from 'antd'
import { regionalAdd, regionalGetDetail, regionalUpdate } from '../api'
import map from '../../../../utils/chinaMap.js'

const EditModel = (props, ref) => {
    const { refres } = props
    const [visible, setVisible] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm() //上传文件表单

    const [checkedKeys, setCheckedKeys] = useState([]) // 落地方案列表
    // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        showModal: (record, type) => {
            console.log(record, type)
            setVisible(true)
            if (type === 'edit' || type === 'view') {
                regionalGetDetail({
                    regionalNo: record.regionalNo,
                }).then((res) => {
                    setCheckedKeys(res.data.data.map(Number))
                    form.setFieldsValue({
                        cityIdList: res.data.data.join(','),
                    })
                })

                form.setFieldsValue({
                    regionalNo: record.regionalNo,
                    regionalName: record.regionalName,
                })
            }

            if (type === 'view') {
                setDisabled(true)
            }
        },
    }))

    // 验证通过后的回调
    const onFinish = (values) => {
        setLoading(true)
        if (values.regionalNo) {
            regionalUpdate({
                regionalNo: values.regionalNo,
                regionalName: values.regionalName,
                cityIdList: values.cityIdList,
            }).then((res) => {
                setLoading(false)
                if (res.data.success) {
                    message.success('修改成功')
                    setVisible(false)
                    refres()
                } else {
                    message.error(res.data.retMsg)
                }
            })
        } else {
            regionalAdd({
                regionalName: values.regionalName,
                cityIdList: values.cityIdList,
            }).then((res) => {
                setLoading(false)
                if (res.data.success) {
                    message.success('新增成功')
                    setVisible(false)
                    refres()
                } else {
                    message.error(res.data.retMsg)
                }
            })
        }
    }

    const onCancel = () => {
        setVisible(false)
        setDisabled(false)
        form.resetFields()
        setCheckedKeys([])
    }

    const onCheck = (checkedKeys, info) => {
        console.log('onCheck', checkedKeys, info)
        setCheckedKeys(checkedKeys)
        console.log(checkedKeys.join(','))
        form.setFieldsValue({
            cityIdList: checkedKeys.join(','),
        })
    }
    return (
        <Modal maskClosable={false}
            title='新增'
            width='800px'
            visible={visible}
            onCancel={onCancel}
            confirmLoading={loading}
            footer={
                !disabled
                    ? [
                          <Button key='back' onClick={onCancel}>
                              取消
                          </Button>,
                          <Button
                              key='submit'
                              type='primary'
                              onClick={() => {
                                  form.submit()
                              }}
                          >
                              确定
                          </Button>,
                      ]
                    : null
            }
        >
            <Form labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} form={form} onFinish={onFinish}>
                <Form.Item name='regionalNo' label='大区编号'>
                    <Input disabled placeholder='ID后台自动添加' />
                </Form.Item>

                <Form.Item
                    name='regionalName'
                    label='大区名称'
                    rules={[
                        {
                            required: true,
                            message: '请填写大区名称',
                        },
                    ]}
                >
                    <Input disabled={disabled} />
                </Form.Item>
                <Form.Item
                    name='cityIdList'
                    label='所属城市'
                    rules={[
                        {
                            required: true,
                            message: '请选择所属城市',
                        },
                    ]}
                >
                    <Tree checkable checkedKeys={checkedKeys} onCheck={onCheck} treeData={map.chinaMap} disabled={disabled} />
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default forwardRef(EditModel)
