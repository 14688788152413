import React, { useEffect, useState } from 'react'
import { request } from '../../../../../utils/fetch'
import { Form, Input, Select, Button, DatePicker, Row, Col, Space } from 'antd'
import store from '../../../../../store/index'
import moment from 'moment'
import ImportModal from '../../components/ImportModal'
const QueryForm = (props) => {
    const { refresh, pageNum, setPageNum } = props
    let path = 'accidentArchives'
    const [importVisible, setImportVisible] = useState(false)
    const [form] = Form.useForm()
    const { Option } = Select
    const { RangePicker } = DatePicker
    const labelStyleObj = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    }
    const itemStyle = { width: '390px', marginRight: '15px' }
    const initialFormValues = {}
    // 查询
    const Query = ()=>{
        if(pageNum===1){
            handelQuery()
        }else{
            setPageNum(1)
        }
    }
    // 查询
    const handelQuery = () => {
        let params = form.getFieldsValue()
        refresh('query', Object.assign({},params))
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
    }
    const upFileProps = {
        url: '/wispay/platform/ddc_asset/import',
        type: 'DDC_ZC',
        importVisible: importVisible,
        setImportVisible: setImportVisible,
        handelQuery: handelQuery
    }
    // 导出
    const exportData = () => {
        let data = form.getFieldsValue()
        let params ={
            assetNo: data.assetNo,
            serialNo: data.serialNo, 
            storesName: data.storesName, 
            type: data.type, 
            model: data.model, 
            carOtherNumber: data.carOtherNumber, 
            carPlateNumber: data.carPlateNumber, 
            gpsNo: data.gpsNo, 
            carMotorNumber: data.carMotorNumber, 
            status: data.status, 
            orderNo: data.orderNo, 
            beginTime: data.djsj ? moment(data.djsj[0]).format('YYYY-MM-DD HH:mm:ss') : '',
            endTime: data.djsj ? moment(data.djsj[1]).format('YYYY-MM-DD HH:mm:ss') : '',
        }
        request.downExport('/platform/ddc_asset/export', params)
    }
    useEffect(() => {
        handelQuery()
    }, [pageNum])
    return (
        <>
        <Form {...labelStyleObj} initialValues={initialFormValues} form={form}>
            <Row>
                <Form.Item style={itemStyle} name='assetNo' label='资产编号'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='serialNo' label='序列号'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='storesName' label='门店'>
                    <Input placeholder='全部' />
                    {/* <Select allowClear placeholder='全部'>
                        <Option value='鹬鸟车行'>NC0000401(鹬鸟车行)</Option>
                    </Select> */}
                </Form.Item>
                <Form.Item style={itemStyle} name='type' label='品类'>
                    <Select allowClear placeholder='全部'>
                        <Option value='车架'>车架</Option>
                        <Option value='电池'>电池</Option>
                        <Option value='车牌'>车牌</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='model' label='款型'>
                    <Select allowClear placeholder='全部'>
                        <Option value='企业牌'>企业牌</Option>
                        <Option value='电动车'>电动车</Option>
                        <Option value='电池'>电池</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='carOtherNumber' label='车架/电池编号'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='carPlateNumber' label='车牌号'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='gpsNo' label='GPS编号'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='carMotorNumber' label='电机号'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='status' label='资产状态'>
                    <Select allowClear placeholder='全部'>
                        <Option value='空闲中'>空闲中</Option>
                        <Option value='租赁中'>租赁中</Option>
                        <Option value='已丢失'>已丢失</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={itemStyle} name='orderNo' label='订单号'>
                    <Input placeholder='全部' />
                </Form.Item>
                <Form.Item style={itemStyle} name='djsj' label='登记时间'>
                    <RangePicker format="YYYY-MM-DD" />
                </Form.Item>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Space>
                        {store.getState().userReducer.permissions.indexOf(`${path}:select`) > -1 && (
                            <Button type='primary' onClick={Query}>
                                查询
                            </Button>
                        )}
                        <Button onClick={resetForm}>重置</Button>
                        <Button onClick={exportData}>导出</Button>
                        <Button onClick={()=>{setImportVisible(true)}}>导入</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
        <ImportModal upFileProps={upFileProps} />
        </>
    )
}

export default QueryForm
