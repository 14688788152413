import React, { useState, useEffect } from 'react'
import {
  Modal,
  Table,
  message
} from 'antd'
import { getElectronicCard } from '../../api'


const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
}

const AccountModal = (props) => {
  const { visible, close, idNumber } = props
  const [tableData, setTableData] = useState([])
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [detail, setDetail] = useState({})
  const [statusName, setStatusName] = useState()

  // 
  const getCardDetail = async() => {
    try {
      let res = await getElectronicCard({idNumber: idNumber})
      console.log(res)
      if (res.data.success) {
        if (res.data.data) {
          setDetail(res.data.data.staffAccount)
          setStatusName(getAccountStatus(res.data.data.staffAccount.accountStatus))
          setTableData(res.data.data.staffAccountBankList)
        }
      } else {
        message.error(res.data.retMsg)
      }
    } catch(err) {
      console.log(err)
    }
  }
  const getAccountStatus = (status) => {
    let statusName = ''
    switch(status) {
      case 'DTJ':
        return statusName = '待提交'
        break;
      case 'YTJ':
        return statusName = '已提交'
        break;
      case 'SUCCESS':
        return statusName = '开户成功'
        break;
      case 'FAIL':
        return statusName = '开户失败'
        break;
      case 'YJY':
        return statusName = '已解约'
        break;
    }
    return statusName
  }
  useEffect(() => {
    if (visible){
      getCardDetail()
    }
  }, [visible])
  const tableColumns = [
    {title: '银行', dataIndex: 'bankName', key: 'bankName', align: 'center'},
    {title: '卡号', dataIndex: 'cardNumber', key: 'cardNumber', align: 'center'}
  ]
  const onCancel = () => {
    close()
    setStatusName()
    setDetail({})
    setTableData([])
  }
  return ( 
    <>
      <Modal maskClosable={false} title="二类户" visible={visible} confirmLoading={confirmLoading} onCancel={onCancel} width={740}>
        <div>
          <div>姓名：{detail.name}</div>
          <div>身份证号：{detail.idNumber}</div>
        </div>
        <div>
          <div>二类户状态：{statusName}</div>
          <div>二类户卡号：{detail.accountCardNumber}</div>
        </div>
        <Table 
          columns={tableColumns} 
          dataSource={tableData}
          rowKey={record=>record.cardNumber}
          pagination={false}>
        </Table>
      </Modal>
    </>
  )
}

export default AccountModal