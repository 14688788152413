import React,{useImperativeHandle,useState,forwardRef} from 'react'
import { 
  Modal,
  Input,
  Row,
  Table,
  Col,
} from 'antd';

const MyModel1 = (props,ref) => {
  const [visible, setVisible] = React.useState(false);
  const [tableData,setTableData] = useState([])
  const [detailObj, setDetailObj] = React.useState({});
  const columns = [
    {
      title: '财税企业',
      dataIndex: 'taxEnterpriseName',
      key: 'taxEnterpriseName',
      width: 200,
      align:'center',
  },
    {
        title: '确认支付时间',
        dataIndex: 'payTime',
        key: 'payTime',
        width: 150,
        align:'center',
    }, {
        title: '账单月份',
        dataIndex: 'month',
        key: 'month',
        width: 120,
        align:'center',
    },{
        title: '来源',
        dataIndex: 'source',
        key: 'source',
        width: 120,
        align:'center',
        render: (text, record) => {
          if(record.source == 1){
            return '佣金账单'
          }else if(record.source == 2){
            return '审核账单'
          }else{
            return
          }
        }
    },{
        title: '佣金账单ID',
        dataIndex: 'billNo',
        key: 'billNo',
        width: 150,
        align:'center',
        
    }, 
    {
        title: '个人佣金（元）',
        dataIndex: 'commissionAmount',
        key: 'commissionAmount',
        width: 130,
        align:'center',
        
    }, 
];
  const topCol = {
    span: 8,
  }
  const endCol = {
    span:8,
    offset:6
  }

   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (obj) => {
        setTableData(obj.staffTicketList)
        setDetailObj(obj)
        setVisible(true)
        
      }
  }));
 
  return (
    <>
      <Modal maskClosable={false}
        forceRender
        title='查看'
        width='1000px'
        visible={visible}
        footer={false}
        onCancel={() => setVisible(false)}
      >
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">确认开票时间:</span>
                <Input placeholder={detailObj.ticketTime} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">开票操作人:</span>
                <Input placeholder={detailObj.ticketUser} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">姓名:</span>
                <Input placeholder={detailObj.name} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">身份证号:</span>
                <Input placeholder={detailObj.idNumber} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">开票金额:</span>
                <Input placeholder={detailObj.ticketAmount} disabled={true} />
              </div>
            </Col>
        </Row>
        <Table 
            scroll={{ x: 'max-content',y:500 }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.billNo}
            pagination={false}
        />
      
      </Modal>
    </>
  );
};
export default forwardRef(MyModel1)



