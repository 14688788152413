import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import {
    Table,
    Space,
    message,
    Tooltip,
    Popconfirm
} from 'antd'
import store from '../../../../../store/index'
import { getAccidentArchivesList, getDetailAccidentArchives, download, batchDownload, sendMessageToDing } from '../../api'
const TableList = (props,ref)=> {
    let path = 'accidentArchives'
    const { refresh } = props
    const [tableData, setTableData] = useState([])
    const [rows, setRows] = useState([])
    const [selectedRowKeys, setselectedRowKeys] = useState([])
    let pageSize = 20
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: pageSize,
        total: 0
    })
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        getlist: (val) => {
            getTableLists(pagination,val)
        },
         // 批量下载
        handelExports: () => {
            console.log(rows)
            if(rows.length<1){
                message.error('请选择需要下载的列表！')
                return
            }
            let archivesNos = rows.map(e=>{
                return e.archivesNo
            })
            batchDownload({archivesNoList:archivesNos})
            setRows([])
            setselectedRowKeys([])
        }
    }));
    const getTableLists = async ({pageNum, pageSize},queryData={}) => {
        let result = await getAccidentArchivesList(Object.assign({}, queryData, {pageNum: pageNum, pageSize: pageSize}))
        if (result.data.success) {
            setTableData(result.data.data.list)
            setPagination(prev => {
                return {
                    ...prev,
                    total: result.data.data.totalCount
                }
            })
        } else {
            message.error(result.data.retMsg)
        }
    }
    // 列表字段
    const tableColumns = [
        { title: '事件编号', dataIndex: 'accidentNo', key: 'accidentNo', align: 'center', width: 130 },
        { title: '更新时间', dataIndex: 'updateTime', key: 'updateTime', align: 'center', width: 110 },
        { title: '处理阶段', dataIndex: 'accidentHandlingStageTypeName', key: 'accidentHandlingStageTypeName', align: 'center', width: 100 },
        { title: '事件月', dataIndex: 'accidentMonth', key: 'accidentMonth', align: 'center', width: 140 },
        { title: '事件日期', dataIndex: 'accidentDate', key: 'accidentDate', align: 'center', width: 110 },
        { title: '城市', dataIndex: 'cityIdName', key: 'cityIdName', align: 'center', width: 100 },
        { title: '企业名称', dataIndex: 'companyName', key: 'companyName', align: 'center', width: 120 },
        { title: 'T名称', dataIndex: 'tname', key: 'tname', align: 'center', width: 100},
        { title: '报备人', dataIndex: 'reportedUserIdName', key: 'reportedUserIdName', align: 'center', width: 100 },
        { title: '事件级别', dataIndex: 'accidentLevel', key: 'accidentLevel', align: 'center', width: 100 },
        { title: '事件人员', key: 'name', dataIndex: 'name', align: 'center', width: 100, },
        { title: '手机号', key: 'phone', dataIndex: 'phone', align: 'center', width: 120,},
        { title: '身份证号', key: 'idNumber', dataIndex: 'idNumber', align: 'center', width: 130,},
        { 
            title: '人员类型', 
            key: 'staffType', 
            align: 'center', 
            width: 100, 
            render: (record) => { 
                    switch (record.staffType) {
                        case '1': return '普通'
                        case '2':  return '优选'
                        case '3': return '001'
                        default: return ''
                    }
            } 
        },
        { title: '事件类别', dataIndex: 'eventTypeName', key: 'eventTypeName', align: 'center', width: 100 },
        { title: '事件起因', dataIndex: 'accidentCause', key: 'accidentCause', align: 'center', width: 120,
        ellipsis: {
            showTitle: false,
          },
          render: accidentCause => (
            <Tooltip placement="topLeft" title={accidentCause}>
              {accidentCause}
            </Tooltip>)
        },
        { title: '人员车辆', dataIndex: 'riderVehicle', key: 'riderVehicle', align: 'center', width: 100 },
        { title: '事件地点', dataIndex: 'accidentAddress', key: 'accidentAddress', align: 'center', width: 100,ellipsis: {
            showTitle: false,
          },
          render: accidentAddress => (
            <Tooltip placement="topLeft" title={accidentAddress}>
              {accidentAddress}
            </Tooltip>)
        },
        { title: '财产伤害', dataIndex: 'propertyTypeName', key: 'propertyTypeName', align: 'center', width: 100 },
        { title: '人身伤害程度', dataIndex: 'degreeInjuryTypeName', key: 'degreeInjuryTypeName', align: 'center', width: 100 },
        { title: '事件认定责任', dataIndex: 'accidentLiability', key: 'accidentLiability', align: 'center', width: 100 },
        { title: '保险公司', dataIndex: 'insuranceCompanyTypeName', key: 'insuranceCompanyTypeName', align: 'center', width: 100 },
        { title: '保单号', dataIndex: 'insurancePolicyNo', key: 'insurancePolicyNo', align: 'center', width: 100 },
        { title: '事件工单号', dataIndex: 'accidentOrderNo', key: 'accidentOrderNo', align: 'center', width: 130 },
        { title: '事件描述', dataIndex: 'accidentDescribe', key: 'accidentDescribe', align: 'center', width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: accidentDescribe => (
            <Tooltip placement="topLeft" title={accidentDescribe}>
              {accidentDescribe}
            </Tooltip>)
        },
        { title: '合计公司赔偿/垫支金额', 
            key: 'companyCompensationAmount', 
            align: 'center', 
            width: 130,
            render: (record) => { return record.companyCompensationAmount||record.companyCompensationAmount===0?<span>￥{record.companyCompensationAmount}</span>:''} },
        { title: '合计回款金额', key: 'receivableAmount', align: 'center', width: 100,render: (record) => { return record.receivableAmount||record.receivableAmount===0?<span>￥{record.receivableAmount}</span>:''} },
        { title: '公司当前损失金额', key: 'lossAmount', align: 'center', width: 100,render: (record) => { return record.lossAmount||record.lossAmount===0?<span>￥{record.lossAmount}</span>:''} },
        { title: '保险理赔金额', key: 'insuranceClaimAmount', align: 'center', width: 100,render: (record) => { return record.insuranceClaimAmount||record.insuranceClaimAmount===0?<span>￥{record.insuranceClaimAmount}</span>:''} },
        { title: '保险理赔明细', dataIndex: 'insuranceClaimDetail', key: 'insuranceClaimDetail', align: 'center', width: 160,
        ellipsis: {
            showTitle: false,
          },
          render: insuranceClaimDetail => (
            <Tooltip placement="topLeft" title={insuranceClaimDetail}>
              {insuranceClaimDetail}
            </Tooltip>)
        },
        { title: '结案日期', dataIndex: 'caseDate', key: 'caseDate', align: 'center', width: 110 },
        { title: '结案金额', key: 'caseAmount', align: 'center', width: 100,render: (record) => { return record.caseAmount||record.caseAmount===0?<span>￥{record.caseAmount}</span>:''} },
        { title: '伤残等级', key: 'disableGradeType', align: 'center', width: 100,render: (record) => { return record.disableGradeType?<span>{record.disableGradeType}级</span>:''} },
        { title: '纠纷处理方式', dataIndex: 'disputesHandlingTypeName', key: 'disputesHandlingTypeName', align: 'center', width: 100 },
        { title: '受害者姓名', dataIndex: 'victimName', key: 'victimName', align: 'center', width: 100 },
        { title: '受害者身份证号', dataIndex: 'victimIdNumber', key: 'victimIdNumber', align: 'center', width: 130 },
        { title: '受害者诉求明细', dataIndex: 'victimAppealDetail', key: 'victimAppealDetail', align: 'center', width: 150,
        ellipsis: {
            showTitle: false,
          },
          render: victimAppealDetail => (
            <Tooltip placement="topLeft" title={victimAppealDetail}>
              {victimAppealDetail}
            </Tooltip>)
        },
        { title: '受害者诉求金额', key: 'victimAppealAmount', align: 'center', width: 100,render: (record) => { return record.victimAppealAmount||record.victimAppealAmount===0?<span>￥{record.victimAppealAmount}</span>:''} },
        { title: '判决/调节金额明细', dataIndex: 'sentenceDetail', key: 'sentenceDetail', align: 'center', width: 150,
        ellipsis: {
            showTitle: false,
          },
          render: sentenceDetail => (
            <Tooltip placement="topLeft" title={sentenceDetail}>
              {sentenceDetail}
            </Tooltip>)
        },
        { title: '判决/调节金额', key: 'sentenceAmount', align: 'center', width: 100,render: (record) => { return record.sentenceAmount||record.sentenceAmount===0?<span>￥{record.sentenceAmount}</span>:''} },
        { title: '造成当前实损的原因', dataIndex: 'lossCausesType', key: 'lossCausesType', align: 'center', width: 160,
            ellipsis: {
            showTitle: true,
          },
          render: lossCausesType => (
            <Tooltip placement="topLeft" title={lossCausesType}>
              {lossCausesType}
            </Tooltip>)
        },
        { title: '备注', dataIndex: 'remark', key: 'remark', align: 'center', width: 130, ellipsis: {
            showTitle: false,
          },
          render: remark => (
            <Tooltip placement="topLeft" title={remark}>
              {remark}
            </Tooltip>)
        },
        {
            title: '操作',
            key: 'control',
            fixed: 'right',
            width: 250,
            align: 'center',
            render: (record) => {
                const control = []
                {store.getState().userReducer.permissions.indexOf(`${path}:select`) > -1 &&control.push(<a key="1" onClick={() => handelView(record)}>查看</a>)}
                {store.getState().userReducer.permissions.indexOf(`${path}:update`) > -1 &&control.push(<a key="2" onClick={() => handelModify(record)}>修改</a>)}
                store.getState().userReducer.permissions.indexOf(`${path}:sendMsg`) > -1 &&record.accidentHandlingStageType!=='6' &&control.push(<a key="3" onClick={() => handelSendMsg(record)}>发短信</a>)
                store.getState().userReducer.permissions.indexOf(`${path}:sendMessageToDing`) > -1 &&record.accidentHandlingStageType!=='6' &&control.push(<Popconfirm
                    key="5"
                    title="是否发送?"
                    onConfirm={()=>{handelSendDD(record)}}
                    okText="确定"
                    cancelText="取消">
                    <a href="#">钉钉提醒</a>
                </Popconfirm>)
                {store.getState().userReducer.permissions.indexOf(`${path}:download`) > -1 &&control.push(<a key="4" onClick={() => handelExport(record)}>下载</a>)}
                const result = []
                control.forEach(item => {
                    result.push(item)
                })
                return (
                    <Space>{result}</Space>
                )
            }
        }
    ]
    useEffect(() => {
        getTableLists(pagination)
    }, [])
    // 查看
    const handelView = async (record) => {
        let result = await getDetailAccidentArchives({archivesNo:record.archivesNo})
        console.log(result)
        if(result.data.success){
            refresh('view',result.data.data.accidentArchiveVo)
        }else{
            message.error(result.data.retMsg)
        }
    }
    // 修改
    const handelModify = async (record) => {
        let result = await getDetailAccidentArchives({archivesNo:record.archivesNo})
        console.log(result)
        if(result.data.success){
            refresh('updata',result.data.data.accidentArchiveVo)
        }else{
            message.error(result.data.retMsg)
        }
    }
    // 发短信
    const handelSendMsg = async (record) => {
        refresh('sendMsg',record)
    }
    // 发钉钉提醒
    const handelSendDD = async (record) => {
        let result = await sendMessageToDing({accidentNo:record.accidentNo})
        if(result.data.success){
            message.success(result.data.retMsg)
        }else{
            message.error(result.data.retMsg)
        }
    }
    // 下载
    const handelExport = (record) => {
        // archivesNo,name,idNumber
        let params = {
            archivesNo: record.archivesNo,
            name: record.name,
            idNumber: record.idNumber,
            accidentDate: record.accidentDate
        }
        download(params)
    }
   
    //分页改变
    const tablePageChange = (pageNum, pageSize) => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: pageNum
            }
        })
        getTableLists({ pageSize: pageSize, pageNum: pageNum })
    }
    return (
        <Table
            columns={tableColumns}
            dataSource={tableData}
            rowSelection={{selectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {
                setRows(selectedRows)
                setselectedRowKeys(selectedRowKeys)
              }}}
            scroll={{ x: 1600, y: 600 }}
            rowKey={record => record.archivesNo}
            pagination={{
                position: ['bottomCenter'],
                showSizeChanger: false,
                total: pagination.total,
                current: pagination.pageNum,
                pageSize: pagination.pageSize,
                showTotal: (total) => `共 ${total} 条数据`,
                onChange: (pageNum, pageSize) => tablePageChange(pageNum, pageSize)
            }}
        />
    )
}
export default forwardRef(TableList)
