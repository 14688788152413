import React, { useImperativeHandle, useState, forwardRef } from 'react'
import { Modal, Table } from 'antd'

const EditModel = (props,ref) => {
    const [visible, setVisible] = useState(false)
    const [tableData, setTableData] = useState() // 表格数据
    // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        showModal: record => {
            setVisible(true)
            setTableData([
                {
                    billName: '骑手配送服务费提现流水',
                    desc: '表示饿了么应付人资商的骑手可提现的费用',
                    price: record.deliveryWithdrawalAmount
                },
                {
                    billName: '配送服务费（太保）',
                    desc: '表示饿了么支付人资商的用于购买骑手太平洋保险的费用',
                    price: record.deliveryInsuranceAmount
                },
                {
                    billName: '配送服务费（平安）',
                    desc: '表示饿了么支付人资商的用于购买骑手平安保险的费用',
                    price: record.deliverySafetyAmount
                },
                {
                    billName: '配送服务费（技术服务费）',
                    desc: '用于支付使用拉扎斯系统的使用费',
                    price: record.deliveryTechnologyAmount
                },
                {
                    billName: '配送服务费合计',
                    desc: '表示饿了么应付人资商的配送费合计',
                    price: record.deliveryTotalAmount
                },
                {
                    billName: '管理服务费',
                    desc: '表示饿了么应付人资商的骑手管理服务费',
                    price: record.managementAmount
                },
                {
                    billName: '平台承担税费',
                    desc: '表示饿了么应付人资商的税费',
                    price: record.platformTaxAmount
                },
                {
                    billName: '配送服务费优惠（负向）',
                    desc: '表示人资商应返还给饿了么的配送费服务费优惠金额',
                    price: record.deliveryPreferentialAmount
                },
                {
                    billName: '调整账（负向）',
                    desc: '表示人资商应返还给饿了么的调整涨金额',
                    price: record.adjustBillAmount
                },
                {
                    billName: '管理服务费合计',
                    desc: '表示饿了么应付人资商的管理服务费合计金额',
                    price: record.managementTotalAmount
                },
                {
                    billName: '合计',
                    desc: '',
                    price: record.totalAmount
                }
            ])
        }
    }))

    const onCancel = () => {
        setVisible(false)
    }

    const columns = [
        {
            title: '账单名称',
            dataIndex: 'billName',
            width: 100,
            align: 'center'
        },
        {
            title: '账单金额',
            dataIndex: 'price',
            width: 50,
            align: 'center'
        },
        {
            title: '账单说明',
            dataIndex: 'desc',
            width: 150,
            align: 'center'
        }
    ]

    return (
        <Modal maskClosable={false} title='人资商账单详情' bodyStyle={{ padding: '0 24px 24px' }} width='1000px' visible={visible} onCancel={onCancel} footer={null}>
            <Table scroll={{ x: 'max-content' }} columns={columns} rowKey='billName' dataSource={tableData} pagination={false} />
        </Modal>
    )
}
export default forwardRef(EditModel)
