import React, { useState, useEffect, useImperativeHandle,forwardRef } from 'react';
import {Table,Space,message,Popconfirm,Tooltip,Typography} from 'antd'
import store from '../../../../../store/index'
import {trafficSafetyGetList,trafficSafetyDelete} from '../../api'
const { Link } = Typography

function CloseTable(props,ref) {
    let path = 'trafficEvent'
    const { refresh } = props
    const [tableData, setTableData] = useState([])
    const [queryData,setQueryData] = useState({})
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    let pageSize = 20
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: pageSize,
        total: 0
    })
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        getlist: (val) => {
            if(val) {
                setQueryData(val)
            }
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
            const pag = {...pagination}
            pag.pageNum = 1
            getTableLists(pag,val)
        }
    }));
    //获取数据
    const getTableLists = async ({pageNum, pageSize},query=queryData) => {
        trafficSafetyGetList(Object.assign({}, query,{pageNum: pageNum, pageSize: pageSize,tabType:2})).then((res)=>{
            console.log(res);
            if (res.data.success) {
                setTableData(res.data.data.list)
                setPagination(prev => {
                    return {
                        ...prev,
                        total: res.data.data.totalCount
                    }
                })
            } else {
                message.error(res.data.retMsg)
            }
        })
    }
    const columns = [
        { title: '接报或上报时间',dataIndex: 'eventReportTime',width: 130,align: 'center' },
        { title: '事件工单号',dataIndex: 'eventOrderNo',width: 120,align: 'center', },
        { title: '报备人',dataIndex: 'reportOriginatorUserName',width: 150,align: 'center'},
        { title: '事件来源',dataIndex: 'eventSourceName',width: 150,align: 'center' },
        { title: '注册城市',dataIndex: 'cityName',width: 150,align: 'center'},
        { title: '事发城市',dataIndex: 'eventCityName',width: 150,align: 'center' },
        { title: '事件类型',dataIndex: 'eventType1Name',width: 150,align: 'center' },
        { title: '事件等级',dataIndex: 'eventLevel',width: 150,align: 'center' },
        { title: '骑手姓名',dataIndex: 'name',width: 100,align: 'center' },
        { title: '人员类型',dataIndex: 'staffTypeName',width: 100,align: 'center' },
        { title: '保险公司',dataIndex: 'insureCompanyName',width: 100,align: 'center'},
        { title: '骑手状态',dataIndex: 'riderStatusName',width: 150,align: 'center' },
        { title: '三者状态',dataIndex: 'threePartyStatusName',width: 150,align: 'center'},
        { title: '流程状态',dataIndex: 'processStatusName',width: 150,align: 'center'},
        { 
            title: '操作',width: 220,align: 'center',fixed: 'right',
            render: (text, record) => {
                return (
                    <Space size='middle'>
                        {<Link onClick={() => view(record)}>查看</Link>}
                        {<Link onClick={() => take(record)}>记录</Link>}
                        {record.processStatus!='3'&&record.processStatus!='2'&&permissions.includes(`${path}:update`)&&<Link onClick={() => edit(record)}>修改</Link>}
                        {record.processStatus=='0'&&permissions.includes(`${path}:delete`) && (
                            <Popconfirm
                                title='确定删除吗？'
                                onConfirm={() => {
                                    handelDelete(record)
                                }}
                                okText='确定'
                                cancelText='取消'
                            >
                                <Link>删除</Link>
                            </Popconfirm>
                        )}
                    </Space>
                )
            }
        }
    ]
    useEffect(() => {
        getTableLists(pagination)
    }, [])
    // 删除
    const handelDelete = async (row) => {
        console.log(row);
        trafficSafetyDelete({eventCode:row.eventCode}).then((res)=>{
            if(res.data.success){
                message.success('删除成功')
            }else{
                message.error(res.data.retMsg)
            }
            refresh('query')
        })
    }
    //查看
    const view = (record)=>{
        refresh('view',{...record,type:1})
    }
    //修改
    const edit = (record)=>{
        refresh('case',{...record,type:2})
    }
    //记录
    const take = (record)=>{
        refresh('take',record)
    }
    //分页改变
    const tablePageChange = (pageNum, pageSize) => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: pageNum
            }
        })
        getTableLists({ pageSize: pageSize, pageNum: pageNum })
    }
    return (
        <Table
            scroll={{ x: 'max-content', y: 420 }}
            columns={columns}
            rowKey={record => record.id}
            dataSource={tableData}
            pagination={{
                position: ['bottomCenter'],
                total: pagination.total,
                current: pagination.pageNum,
                pageSize: pagination.pageSize,
                showSizeChanger: false,
                showTotal: total => `共 ${total} 条数据`,
                onChange: (pageNum, pageSize) => tablePageChange(pageNum, pageSize)
            }}
        />
    )
}
export default forwardRef(CloseTable)
