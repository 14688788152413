import React, { useState, useEffect,forwardRef } from 'react';
import { request } from '../../../../../utils/fetch'
import {Form,Input,Modal,Select,Button,DatePicker,Radio,Row,Col,message} from 'antd'
import {getDingtalkUser,getCityListByProvinceId} from '../../api'
import store from '../../../../../store/index'
import moment from 'moment'
const FormItem = Form.Item
const { OptGroup,Option } = Select
const { RangePicker } = DatePicker
const Search = (props)=> {
    let path = 'trafficEventArchives'
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const {type} = props
    const { refresh } = props
    const cityList1 = [
        { cityId:'TJ', cityName:'天津市' },
        { cityId:'NC', cityName:'南昌市' },
        { cityId:'JJ', cityName:'九江市' },
        { cityId:'GZ', cityName:'赣州市' },
        { cityId:'JDZ', cityName:'景德镇市' },
        { cityId:'JN', cityName:'济南市' },
        { cityId:'LY', cityName:'临沂市' },
        { cityId:'QD', cityName:'青岛市' },
        { cityId:'WH', cityName:'威海市' },
        { cityId:'WF', cityName:'潍坊市' },
        { cityId:'YT', cityName:'烟台市' },
        { cityId:'ZB', cityName:'淄博市' },
        { cityId:'TY', cityName:'太原市' },
    ]
    const [cityList2,setCityList2] = useState([])
    const [cityData, setCityData] = useState([...cityList1]) // 城市
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [month,setMonth] = useState()
    const [form] = Form.useForm()
    const [dingkUser,setDingkUser] = useState([])
    const initSearch = {
        date: '',
        cityQueryType:'2',
        userQueryType:'1',
        dateQueryType:'1',
    }
    const [radio, setRadio] = useState(10)
    // 搜索表单通用样式
    const labelStyleObj = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
    }
    const itemStyle = { width: '364px', marginRight: '15px' }
    // const itemStyle = {
    //     width: '300px',
    //     marginRight: '10px'
    // }
    // const labelStyleObj = {
    //     labelCol: { span: 8 },
    //     wrapperCol: { span: 16 }
    // }

    useEffect(() => {
        getCity()
        getUser()
    }, [])
    // 时间radio选择
    const radioChange = e => {
        let startDate, endDate
        setRadio(e.target.value)
        startDate = moment().subtract(e.target.value - 1, 'days').startOf('day')
        endDate = moment()
        form.setFieldsValue({ date: [startDate, endDate] })
    }
    const rangeChange = (dates) => {
        if (dates === null) {
          setRadio(10)
        }
    }
    const getCity = ()=>{
        getCityListByProvinceId({
            regionalNo:'',
            provinceId:''
        }).then((res)=>{
            setCityList2(res.data.data)
        })
      }
    const getUser = () => {
        getDingtalkUser().then(res => {
          if (res.data.success) {
            setDingkUser(res.data.data)
          }
        })
    }
    const cityChange = (value)=>{
        form.setFieldsValue({cityIdList:[]})
        if(value==2){
            setCityData(cityList1)
        }else{
            setCityData(cityList2)
        }
        
    }
    //查询
    const handleSearch =()=>{
        const formData = form.getFieldsValue()
        const date = form.getFieldsValue().date
        console.log(date);
        let params = {
            tabType:type,
            dateQueryType:formData.dateQueryType,
            dealStatusList:formData.dealStatusList,
            beginTime: date? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date? moment(date[1]).format('YYYY-MM-DD') : '',
            keyword: formData.keyword,
            cityQueryType:formData.cityQueryType,
            cityIdList: formData.cityIdList,
            userQueryType:formData.userQueryType,
            originatorUserId:formData.originatorUserId,
            eventTypeList:formData.eventTypeList,
            eventLevelList:formData.eventLevelList,
        }
        refresh('search',params)
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
        setRadio('')
    }
//     档案导出：eventDownLoad/exportTraffic?参数与查询一致

//     批量下载：eventDownLoad/batchDownloadTraffic?参数与查询一致

    //档案导出
    const exportReport = ()=>{
        const formData = form.getFieldsValue()
        const date = form.getFieldsValue().date
        console.log(date);
        let params = {
            tabType:type,
            dateQueryType:formData.dateQueryType,
            dealStatusList:formData.dealStatusList,
            beginTime: date? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date? moment(date[1]).format('YYYY-MM-DD') : '',
            keyword: formData.keyword,
            cityQueryType:formData.cityQueryType,
            cityIdList: formData.cityIdList,
            userQueryType:formData.userQueryType,
            originatorUserId:formData.originatorUserId,
            eventTypeList:formData.eventTypeList,
            eventLevelList:formData.eventLevelList,
        }
        console.log(params);
        request.downExport('/platform/eventDownLoad/exportTraffic', params)
    }
    //批量下载
    const exportBatch = ()=>{
        const formData = form.getFieldsValue()
        const date = form.getFieldsValue().date
        console.log(date);
        let params = {
            tabType:type,
            dateQueryType:formData.dateQueryType,
            dealStatusList:formData.dealStatusList,
            beginTime: date? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date? moment(date[1]).format('YYYY-MM-DD') : '',
            keyword: formData.keyword,
            cityQueryType:formData.cityQueryType,
            cityIdList: formData.cityIdList,
            userQueryType:formData.userQueryType,
            originatorUserId:formData.originatorUserId,
            eventTypeList:formData.eventTypeList,
            eventLevelList:formData.eventLevelList,
        }
        // request.downExport('/platform/eventDownLoad/batchDownloadTraffic',params)
        request.get('/platform/eventDownLoad/batchDownloadTraffic', params).then(({ msg }) => {
            if (msg === '执行成功！') {
                message.success('下载任务已开启，请稍后在【个人下载任务列表】中下载数据')
            } else {
                message.error(msg)
            }
        })
    }
    //月报导出：eventDownLoad/downloadMonthReport?month=''&type=1(固定传1）
    //月报导出
    const exportMonth = (params)=>{
        console.log(params);
        request.downExport('/platform/eventDownLoad/downloadMonthReport',params)
    }
    const showModal = () => {
        setIsModalOpen(true);
        setMonth('')
        console.log(month);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        const params = {
            month:moment(month).format('YYYY-MM'),
            type:1
        }
        console.log(params);
        exportMonth(params)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const monthChange =(value)=>{
        setMonth(value)
    }
    return (
        <>
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <Form.Item style={ {...itemStyle, width: '404px', display: 'flex'} } wrapperCol={{ span: 24 }}>
                        <Form.Item style={{display: 'inline-block', marginBottom: 0, marginLeft:'11px', width: '140px' }} name="dateQueryType">
                            <Select>
                                <Option value="1">接报或上报时间</Option>
                                <Option value="2">更新时间</Option>
                                <Option value="3">结案时间</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ display: 'inline-block',  marginBottom: 0, width: '253px' }} wrapperCol={{ flex: '1' }} name="date">
                            <RangePicker
                                allowClear={true}
                                onChange={(dates) => { rangeChange(dates); setRadio(10) }}
                                format="YYYY-MM-DD" />
                        </Form.Item>
                    </Form.Item>
                    <Radio.Group style={{height: '32px',alignItems: 'center',display: 'flex'}} onChange={radioChange} value={radio}>
                        <Radio value={1}>今天</Radio>
                        <Radio value={7}>近7天</Radio>
                        <Radio value={30}>近30天</Radio>
                    </Radio.Group>
                </Row>
                <Row>
                    <Form.Item style={itemStyle} {...labelStyleObj} name="dealStatusList" label="当前处理进度">
                        <Select allowClear placeholder="全部" >
                            <Option value="1">报备</Option>
                            <Option value="2">跟进</Option>
                            <Option value="3">结案</Option>
                        </Select>
                    </Form.Item>
                    <FormItem style={itemStyle} {...labelStyleObj}
                    wrapperCol={{ span: 24 }}>
                        <FormItem style={{display: 'inline-block', marginBottom: 0, marginLeft:'7px', width: '100px' }} name="cityQueryType">
                            <Select onChange={(value)=>cityChange(value)}>
                                <Option value="2">注册城市</Option>
                                <Option value="1">事发城市</Option>
                            </Select>
                        </FormItem>
                        <FormItem style={{display: 'inline-block', marginBottom: 0, width: '257px'}}  name="cityIdList">
                            <Select allowClear placeholder='全部' mode='multiple'>
                                {cityData.length > 0 &&
                                    cityData.map(item => (
                                        <Option value={item.cityId} key={item.cityId}>
                                            {item.cityName}
                                        </Option>
                                    ))}
                            </Select>
                        </FormItem>
                    </FormItem>
                    <FormItem label='事件类型' name='eventTypeList' style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder='全部' mode='multiple'>
                            <Option value='JT'>交通事故</Option>
                            <Option value='YW'>意外事故</Option>
                            <Option value='ZAXS'>治安刑事事件</Option>
                            <Option value='WW'>维稳事件</Option>
                            <Option value='XF'>消防事件</Option>
                            <Option value='YQ'>舆情事件</Option>
                        </Select>
                    </FormItem>
                    <FormItem label='事件等级' name='eventLevelList' style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder='全部' mode='multiple'>
                            <Option value={'ESU'}>ESU</Option>
                            <Option value={'P0'}>P0</Option>
                            <Option value={'P1'}>P1</Option>
                            <Option value={'P2'}>P2</Option>
                            <Option value={'P3'}>P3</Option>
                        </Select>
                    </FormItem>
                    <FormItem name='staffType' style={itemStyle} {...labelStyleObj}
                    wrapperCol={{ span: 24 }}>
                        <FormItem style={{display: 'inline-block', marginBottom: 0, marginLeft:'7px', width: '100px' }} name="userQueryType">
                            <Select placeholder='请选择'>
                                <Option value="1">报备人</Option>
                                <Option value="2">结案人</Option>
                            </Select>
                        </FormItem>
                        <FormItem style={{display: 'inline-block', marginBottom: 0, width: '257px'}}  name="originatorUserId">
                        <Select allowClear placeholder="全部">
                        {dingkUser.map(item => (
                            <OptGroup label={item.name} key={item.name}>
                                {
                                    item.list.map((citem)=>(
                                        <Option value={citem.userId} key={citem.userId}>{citem.name}</Option>
                                    ))
                                }
                            </OptGroup>
                        ))}
                        </Select>
                        </FormItem>
                    </FormItem>
                    <FormItem label='关键字' labelCol={{ style: { width: 100 } }} wrapperCol={{ flex: 300 }} name='keyword'>
                        <Input style={{ width: 350 }} placeholder='骑手ID丨姓名丨手机号丨身份证号丨事件工单号' />
                    </FormItem>
                </Row>
                <Row>
                    <Col span={24} md={24} lg={24}>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }}>
                            {permissions.includes(`${path}:select`) && (
                                <Button type='primary' onClick={handleSearch}>
                                    查询
                                </Button>
                            )}
                            <Button style={{ marginLeft: 10 }} onClick={resetForm}>
                                重置
                            </Button>
                            {permissions.includes(`${path}:select`)&& (
                                <Button style={{ marginLeft: 10 }} onClick={exportReport}>
                                    档案导出
                                </Button>
                            )}
                            {permissions.includes(`${path}:select`)&& (
                                <Button style={{ marginLeft: 10 }} onClick={exportBatch}>
                                    批量下载
                                </Button>
                            )}
                            {permissions.includes(`${path}:select`)&& (
                                <Button style={{ marginLeft: 10 }} onClick={showModal}>
                                    月报导出
                                </Button>
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <Modal maskClosable={false} title="月报导出" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div style={{display:'flex',alignItems: 'center' }}>
                    <span>月份选择：</span><DatePicker style={{width:'240px'}} onChange={(value)=>monthChange(value)} value={month} picker="month" />
                </div>
            </Modal>
        </>
    )
}

export default forwardRef(Search)
