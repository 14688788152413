import React, { useState, useEffect } from 'react'
import { Row, Col, Table, Form, Input, Radio, Button, DatePicker, message, Space, Typography, Modal, Upload, Popconfirm } from 'antd'
import moment from 'moment'
import { request } from '../../../../utils/fetch'
import axios from 'axios'
import { UploadOutlined } from '@ant-design/icons'
import store from '../../../../store/index'
import { getCarDealerInfoList, carDealerInfoInsert, carDealerInfoUpdate, carDealerInfoEnable } from '../api'

const FormItem = Form.Item
const { RangePicker } = DatePicker
const { Link } = Typography
const itemStyle = {
    width: '350px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 }
}
const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 }
}

function CarDealerInfo(props) {
    let path = 'platform:ContractSign'
    const [form] = Form.useForm()
    const [insertForm] = Form.useForm()
    const [importForm] = Form.useForm()
    const initSearch = {
        date: []
    }

    const [dayValue, setDayValue] = useState(0)
    const [fileList, setFileList] = useState([]) //文件列表
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const [insertVisible, setInsertVisible] = useState(false)
    const [importVisible, setImportVisible] = useState(false)
    const [insertTitle, setInsertTitle] = useState('')
    const [tempRecord, setTempRecord] = useState({})
    const [loading, setLoading] = useState(false)
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [tableData, setTableData] = useState([])
    const fileProps = {
        //表格上传配置和方法
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept: '.xls, .xlsx',
        beforeUpload: () => {
            return false
        },

        onChange(info) {
            let fileCon = [...info.fileList]
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1)
            setFileList(fileCon)
        }
    }

    const columns = [
        {
            title: '添加时间',
            dataIndex: 'createTime',
            width: 150,
            align: 'center'
        },
        {
            title: '城市',
            dataIndex: 'cityName',
            width: 150,
            align: 'center'
        },
        {
            title: '车行名称',
            dataIndex: 'dealerName',
            width: 150,
            align: 'center'
        },
        {
            title: '车行联系人姓名',
            dataIndex: 'name',
            width: 250,
            align: 'center'
        },
        {
            title: '车行联系人电话',
            dataIndex: 'phone',
            width: 150,
            align: 'center'
        },
        {
            title: '收款方',
            dataIndex: 'recipientName',
            width: 150,
            align: 'center'
        },
        {
            title: '收款银行',
            dataIndex: 'recipientBank',
            width: 150,
            align: 'center'
        },
        {
            title: '收款账号',
            dataIndex: 'recipientAccount',
            width: 150,
            align: 'center'
        },
        {
            title: '城市对接人姓名',
            dataIndex: 'contactName',
            width: 150,
            align: 'center'
        },
        {
            title: '城市对接人电话',
            dataIndex: 'contactPhone',
            width: 150,
            align: 'center'
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            align: 'center',
            render: status => (status === 1 ? '停用' : '启用')
        },
        {
            title: '操作',
            key: 'action',
            width: 160,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size='middle'>
                    <Link onClick={() => methodsFn.handleModify(record)}>修改</Link>
                    {
                        <Popconfirm
                            title={record.status === 0 ? '是否确定要停用?' : '是否确定要启用?'}
                            onConfirm={() => {
                                methodsFn.handleToogleStatus(record)
                            }}
                            okText='确定'
                            cancelText='取消'
                        >
                            <Link>{record.status === 0 ? '停用' : '启用'}</Link>
                        </Popconfirm>
                    }
                </Space>
            )
        }
    ]
    //组件渲染函数
    const myComponent = {
        renderSearchForm() {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        <FormItem label='时间' name='date' style={{ width: '486px' }} labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
                            <RangePicker onChange={methodsFn.timeChange} />
                        </FormItem>
                        <FormItem style={{ with: '600px', marginLeft: '26px' }} label=''>
                            <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                                <Radio value={1}>今天</Radio>
                                <Radio value={7}>近7天</Radio>
                                <Radio value={30}>近30天</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Row>
                    <Row>
                        <FormItem style={itemStyle} {...labelStyleObj} name='cityName' label='城市'>
                            <Input placeholder='全部' />
                        </FormItem>
                        <FormItem label='关键字' name='keyword' style={itemStyle} {...labelStyleObj}>
                            <Input placeholder='车行名称' />
                        </FormItem>
                    </Row>
                    <Row justify='end'>
                        <Space size={10}>
                            <Button type='primary' onClick={methodsFn.handleSearch}>
                                查询
                            </Button>
                            <Button onClick={methodsFn.handleFormReset}>重置</Button>
                            {
                                // permissions.includes(`${path}:export`) &&
                                <Button onClick={methodsFn.handleExport}>导出</Button>
                            }
                            {
                                // permissions.includes(`${path}:insert`) &&
                                <Button onClick={methodsFn.handleInsert}>新增</Button>
                            }
                            {
                                // permissions.includes(`${path}:import`) &&
                                <Button onClick={methodsFn.handleImport}>导入</Button>
                            }
                        </Space>
                    </Row>
                </Form>
            )
        },
        insertModal() {
            return (
                <Modal maskClosable={false} title={insertTitle} visible={insertVisible} onCancel={methodsFn.onInsertCancel} confirmLoading={loading} onOk={methodsFn.onInsertSubmit} width={850}>
                    <Form {...formItemLayout} form={insertForm}>
                        <Row>
                            <Col span={12}>
                                <FormItem name='cityName' rules={[{ required: true }]} label='城市'>
                                    <Input placeholder='请输入' />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name='dealerName' rules={[{ required: true }]} label='车行名称'>
                                    <Input placeholder='请输入' />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name='name' label='车行联系人姓名'>
                                    <Input placeholder='请输入' />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name='phone' label='车行联系人电话'>
                                    <Input placeholder='请输入' />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name='recipientName' label='收款方'>
                                    <Input placeholder='请输入' />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name='recipientBank' label='收款银行'>
                                    <Input placeholder='请输入' />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name='recipientAccount' label='收款账号'>
                                    <Input placeholder='请输入' />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name='contactName' label='城市对接人姓名'>
                                    <Input placeholder='请输入' />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name='contactPhone' label='城市对接人电话'>
                                    <Input placeholder='请输入' />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )
        },
        importModal() {
            return (
                <Modal maskClosable={false} title='导入' confirmLoading={loading} visible={importVisible} onOk={methodsFn.onHandleUp} onCancel={methodsFn.onImportCancel}>
                    <Form form={importForm}>
                        <FormItem label='上传表格' name='ffile' rules={[{ required: true, message: '请上传表格' }]}>
                            <Upload {...fileProps}>
                                <Button icon={<UploadOutlined />}>点击上传</Button>
                                <Button
                                    type='link'
                                    onClick={e => {
                                        e.stopPropagation()
                                        window.location.href = `/wispay/common/getTemplateDownloadUrl?token=${localStorage.getItem('token')}&mobile=${localStorage.getItem('mobile')}&type=DDC_CHXX`
                                    }}
                                >
                                    下载模板
                                </Button>
                            </Upload>
                        </FormItem>
                    </Form>
                </Modal>
            )
        }
    }
    //方法函数
    const methodsFn = {
        //日期变化
        timeChange() {
            setDayValue(0)
        },
        //天数变化
        dayChange(e) {
            let startDate = moment()
                .subtract(e.target.value - 1, 'd')
                .format('YYYY-MM-DD')
            let endDate = moment().format('YYYY-MM-DD')

            setDayValue(e.target.value)
            form.setFieldsValue({
                date: [moment(startDate), moment(endDate)]
            })
        },
        getsearchObj() {
            let formObj = form.getFieldsValue()
            let beginTime = '',
                endTime = ''
            if (formObj.date.length > 1) {
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }

            return {
                beginTime: beginTime,
                endTime: endTime,
                keyword: formObj.keyword,
                cityName: formObj.cityName
            }
        },
        handleSearch() {
            pageNum == 1 && methodsFn.getTableList()
            setPageNum(1)
        },
        handleFormReset() {
            setDayValue(0)
            form.resetFields()
        },
        async getTableList() {
            getCarDealerInfoList({
                ...methodsFn.getsearchObj(),
                pageSize: pageSize,
                pageNum: pageNum
            }).then(({ data: { data, retMsg, success } }) => {
                if (success) {
                    setTotal(data.totalCount)
                    setTableData(data.list)
                } else {
                    message.error(retMsg)
                }
            })
        },
        // 导出
        handleExport() {
            request.downExport('/platform/ddc_dealerInfo/export', methodsFn.getsearchObj())
        },
        // 新增
        handleInsert() {
            setInsertTitle('新增')
            setInsertVisible(true)
        },
        //关闭新增弹窗
        onInsertCancel() {
            setInsertVisible(false)
            insertForm.resetFields()
        },
        // 提交
        async onInsertSubmit() {
            const values = await insertForm.validateFields()
            setLoading(true)
            //新增
            insertTitle === '新增' &&
                carDealerInfoInsert(values)
                    .then(({ data: { retMsg, success } }) => {
                        setLoading(false)
                        if (success) {
                            message.success(retMsg)
                            methodsFn.onInsertCancel()
                            methodsFn.handleSearch()
                        } else {
                            message.error(retMsg)
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                        console.log(err)
                    })

            //修改
            insertTitle === '修改' &&
                carDealerInfoUpdate({ ...values, id: tempRecord.id })
                    .then(({ data: { retMsg, success } }) => {
                        setLoading(false)
                        if (success) {
                            message.success(retMsg)
                            methodsFn.onInsertCancel()
                            methodsFn.getTableList()
                        } else {
                            message.error(retMsg)
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                        console.log(err)
                    })
        },

        // 导入弹窗
        handleImport() {
            setImportVisible(true)
        },
        //关闭新增弹窗
        onImportCancel() {
            setImportVisible(false)
            importForm.resetFields()
            setFileList([])
        },
        // 上传文件
        async onHandleUp() {
            let values = await importForm.validateFields()
            let data = new FormData() //通过FormData将文件转成二进制数据
            let file = values.ffile.file
            data.append('token', localStorage.getItem('token'))
            data.append('mobile', localStorage.getItem('mobile'))
            data.append('file', file)

            setLoading(true)

            axios({
                method: 'post',
                url: `/wispay/platform/ddc_dealerInfo/import`,
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(({ data: { retMsg, success } }) => {
                    setLoading(false)
                    if (success) {
                        methodsFn.onImportCancel()
                        message.success(retMsg)
                        methodsFn.handleSearch()
                    } else {
                        if (retMsg.includes('#')) {
                            retMsg = retMsg.split('#')
                            retMsg = retMsg.map(item => <p>{item}</p>)
                        }
                        message.error(retMsg)
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                })
        },
        // 修改
        handleModify(record) {
            setInsertTitle('修改')
            setTempRecord(record)
            insertForm.setFieldsValue({
                ...record
            })
            setInsertVisible(true)
        },
        //启停用
        handleToogleStatus(record) {
            carDealerInfoEnable({
                id: record.id,
                flag: record.status === 1 //true-启用 false-停用
            }).then(({ data: { retMsg, success } }) => {
                if (success) {
                    message.success(retMsg)
                } else {
                    message.error(retMsg)
                }
                methodsFn.getTableList()
            })
        }
    }

    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    }, [pageNum])

    return (
        <div>
            {myComponent.renderSearchForm()}
            <Table
                scroll={{ x: 'max-content', y: 518 }}
                columns={columns}
                rowKey={record => record.id}
                dataSource={tableData}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: total => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
            />
            {myComponent.insertModal()}
            {myComponent.importModal()}
        </div>
    )
}

export default CarDealerInfo
