import React, { useImperativeHandle, useState, useEffect, forwardRef } from 'react'
import { Form, Modal, Select, Input, Row, Col, message } from 'antd'
import { getCrowdStaffDetail, updateCrowdStaff, getCityListByProvinceId } from '../api'
import { request } from '../../../../utils/fetch'
const { Option } = Select

const EditModel = (props,ref) => {
    const { companyData } = props
    const [visible, setVisible] = useState(false)
    const [id, setId] = useState()
    const [loading, setLoading] = useState(false)
    const [planType, setPlanType] = useState('') //落地配置 用来限制纳税人识别号
    const [form] = Form.useForm() //上传文件表单
    const [planTypeData, setPlanTypeData] = useState([]) // 落地方案列表
    const [detail, setDetail] = useState({}) // 落地方案列表
    const [cityData, setCityData] = useState([]) // 城市
    // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        showModal: (record) => {
            setId(record.id)
            setVisible(true)
            request
                .requestGet('/platform/companyBase/detail', {
                    companyCode: record.companyCode,
                })
                .then((res) => {
                    setPlanTypeData(res.data.data.serviceConfigVoList)
                })

            //获取回填数据
            getCrowdStaffDetail({ id: record.id })
                .then((res) => {
                    if (res.data.success) {
                        let data = res.data.data
                        setDetail(data)
                        form.setFieldsValue({
                            name: data.name,
                            idNumber: data.idNumber,
                            phone: data.phone,
                            staffType: data.staffType,
                            cityId: data.cityId,
                            companyCode: data.companyCode,
                            tname: data.tname,
                            staffId: data.staffId,
                            planType: data.planType,
                            taxpayerNo: data.taxpayerNo,
                            certificateStatus: data.certificateStatus,
                            source: data.source === 2 ? '手工录入' : '文件导入'
                        })

                        setPlanType(data.planType)
                    } else {
                        message.error(res.data.retMsg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    }))
    useEffect(() => {
        getCityListByProvinceId().then((res) => {
            if (res.data.success) {
                setCityData(res.data.data)
            }
        })
    }, [])
    // 验证通过后的回调
    const onFinish = (values) => {
        console.log(values)
        setLoading(true)
        updateCrowdStaff({
            id: id,
            name: values.name,
            idNumber: values.idNumber,
            phone: values.phone,
            staffType: values.staffType,
            cityId: values.cityId,
            companyCode: values.companyCode,
            tName: values.tname,
            staffId: values.staffId,
            planType: values.planType,
            taxpayerNo: values.taxpayerNo,
            certificateStatus: values.certificateStatus,
        })
            .then((res) => {
                setLoading(false)
                if (res.data.success) {
                    onCancel()
                    props.refres()
                    message.success(res.data.retMsg)
                } else {
                    message.error(res.data.retMsg)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }

    const onCancel = () => {
        setVisible(false)
        form.resetFields()
        setPlanTypeData([])
    }

    const idNumberChange = () => {
        if (form.getFieldValue('planType') === 1&&form.getFieldValue('idNumber')) {
            console.log('sdfd')
            form.setFieldsValue({
                taxpayerNo: String(form.getFieldValue('idNumber')) + '01',
            })
        }else if(form.getFieldValue('planType') === 1&&!form.getFieldValue('idNumber')){
            form.setFieldsValue({
                taxpayerNo: '',
            })
        }
    }
    // 下拉框
    const selectFilter = (inputValue, option) => {
        if (option.children.indexOf(inputValue) !== -1) {
            return option
        }
    }
    const companyChange = (value) => {
        form.setFieldsValue({
            planType: '',
            areaNo: '',
        })

        request
            .requestGet('/platform/companyBase/detail', {
                companyCode: value,
            })
            .then((res) => {
                setPlanTypeData(res.data.data.serviceConfigVoList)
            })

        console.log('企业变化', value)
    }

    const planTypeChange = (value) => {
        setPlanType(value)
        if (value == 1) {
            form.setFieldsValue({
                taxpayerNo: String(form.getFieldValue('idNumber')) + '01',
            })
        } else if (value == detail.planType) {
            form.setFieldsValue({
                taxpayerNo: detail.taxpayerNo,
            })
        } else {
            form.setFieldsValue({
                taxpayerNo: '',
            })
        }
    }

    return (
        <Modal maskClosable={false}
            title='修改'
            width='800px'
            visible={visible}
            onCancel={onCancel}
            onOk={() => {
                form.submit()
            }}
            confirmLoading={loading}
        >
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                form={form}
                onFinish={onFinish}
                initialValues={{
                    personStatus: 1,
                }}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name='name'
                            label='姓名'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='idNumber' label='身份证号' rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Input  onChange={idNumberChange}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name='phone'
                            label='手机号'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name='staffType'
                            label='人员类别'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择人员类别',
                                },
                            ]}
                        >
                            <Select>
                                <Option value={1}>普通</Option>
                                <Option value={2}>优选</Option>
                                <Option value={3}>001</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name='cityId'
                            label='注册城市'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select showSearch filterOption={(inputValue, option) => selectFilter(inputValue, option)} >
                                {cityData.length > 0 &&
                                    cityData.map((item) => (
                                        <Option value={Number(item.cityId)} key={item.cityId}>
                                            {item.cityName}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name='companyCode'
                            label='企业名称'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择企业名称',
                                },
                            ]}
                        >
                            <Select onChange={companyChange}>
                                {companyData.map((item) => {
                                    return (
                                        <Option value={item.companyCode} key={item.companyCode}>
                                            {item.companyName}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item name='tname' label='T名称'>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='staffId' label='骑手ID'>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name='planType'
                            label='落地方案'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择落地方案',
                                },
                            ]}
                        >
                            <Select onChange={planTypeChange}>
                                {planTypeData.map((item) => {
                                    return (
                                        <Option value={item.planType} key={item.planType}>
                                            {['', '临时税务登记', '个体工商户', '委托代征'][item.planType]}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name='planType'
                            label='财税企业'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select showArrow={false} disabled>
                                {planTypeData.map((item) => {
                                    return (
                                        <Option value={item.planType} key={item.planType}>
                                            {item.taxEnterpriseName}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name='taxpayerNo'
                            label='纳税人识别号'
                            rules={[
                                {
                                    required: planType != 3,
                                    message: '请输入纳税人识别号',
                                },
                            ]}
                        >
                            <Input disabled={planType == 1 || planType == 3} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name='certificateStatus'
                            label='办证状态'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择办证状态',
                                },
                            ]}
                        >
                            <Select>
                                <Option value={1}>未办理</Option>
                                <Option value={2}>已办理</Option>
                                <Option value={3}>已注销</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='source' label='数据来源'>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export default forwardRef(EditModel)
