import React, { useImperativeHandle,forwardRef, useState, useEffect } from 'react'
import {Modal,Form,InputNumber,Input,Select,Row,Col,DatePicker,Upload,Button,message} from 'antd';
import {trafficSafetyGetDetail,getPerson,trafficSafetyEventCase,trafficSafetyUpdate,getAllRegionalList,getProvinceIdListByRegionalNo,getCityListByProvinceId} from '../../api'
import {
  PlusOutlined,
} from '@ant-design/icons';
import '../index.css'
import moment from 'moment'
const { TextArea } = Input;
const topCol = {
  span: 12,
}

const action = "/wispay/common/uploadFile"
// const action = "http://test.zchpay.com/wispay/common/uploadFile"
const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const CaseReport = (props,ref) => {
  const { refresh } = props
//   const { cityData } = props
  const [visible, setVisible] = useState(false);

  const [title, setTitle] = useState('结案');
  const [cityList, setCityList] = useState([])
  const [rule, setRule] = useState([])
  const [insureOrderNoRule,setInsureOrderNoRule] = useState([])
  const [regionalList, setRegionalList] = useState([])
  const [provinceList, setProvinceList] = useState([])
  const [personList,setPersonList] =useState([])
  const [advanceReasonRule, setAdvanceReasonRule] = useState()
  const [chargeAgainstReasonRule,setChargeAgainstReasonRule] = useState()
  const [advanceDamageReasonRule,setAdvanceDamageReasonRule] = useState()
  const [chargeAgainstFlagRule,setChargeAgainstFlagRule] = useState()
  const [type,setType] = useState(1)
  const [loading,setLoading] = useState(false)
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList1: '',
    fileList2: '',
    fileList3: '',
    fileList4: '',
    fileList5: '',
    fileList6: '',
    fileList7: '',
    previewTitle: '',
  })
  const [form] = Form.useForm()
  const { Option } = Select
  const initialFormValues = {}

  const [violationShow,setViolationShow] = useState(false)

  const violationChange = (value)=>{
    console.log(value)
    if(value=='18'){
      setViolationShow(true)
    }else{
      setViolationShow(false)
    }
  }
  
  const content = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">ESU：重大刑事案件、涉及刑事或者治安案件，对公司品牌造成严重影响的</p>
      <p className="titp">P0：致人死亡或2人及以上重伤</p>
      <p className="titp">P1：致1人重伤</p>
      <p className="titp">P2：致人轻伤</p>
      <p className="titp">P3：致人轻微伤</p>
    </div>
  )
  const content2 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">案件响应时间-接报或上报时间</p>
    </div>
  )
  const content3 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">处置回复时间-案件响应时间</p>
    </div>
  )
  const content4 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">实际垫付金额 - 保险回款金额</p>
    </div>
  )
  const content5 = (
    <div className="titbox">
      <div className="tititle">说明</div>
      <p className="titp">家属诉求慰问金 - 实际赔付慰问金</p>
    </div>
  )
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  useEffect(() => {
    getAllRegional()
  }, [])
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (data, splist) => {
      setType(data.type)
      setRule([])
      setAdvanceReasonRule([])
      setChargeAgainstFlagRule([])
      setChargeAgainstReasonRule([])
      form.resetFields()
      setVisible(true);
      trafficSafetyGetDetail({eventCode:data.eventCode,type:data.type==1?1:0}).then((res)=>{
        const result = {...res.data.data}
        result.dealWithTime=result.dealWithTime?moment(res.data.data.dealWithTime):''
        result.eventProvinceId= String(result.eventProvinceId)
        result.eventCityId = String(result.eventCityId)
        result.policeDepartment = result.policeDepartment?result.policeDepartment.split(','):['0']
        result.scenePictureUrl = setFlieList(result.scenePictureUrl)
        result.orderPictureUrl = setFlieList(result.orderPictureUrl)
        result.responsibleLetterUrl = setFlieList(result.responsibleLetterUrl)
        result.medicalProveUrl = setFlieList(result.medicalProveUrl)
        result.comfortProtocolUrl = setFlieList(result.comfortProtocolUrl)
        result.otherZipUrl = setFlieList(result.otherZipUrl)
        if(result.insureFlag!=0) setRule([{ required: true, message: '请选择保险!' }])||setInsureOrderNoRule([{ required: true, message: '请输入保单号!' }])
        if(result.advanceAmount!=0) setAdvanceReasonRule([{ required: true, message: '请填写垫付原因' }])
        if(result.realityAdvanceAmount!=0) setChargeAgainstFlagRule([{ required: true, message: '请选择垫付是否冲销' }])
        if(result.chargeAgainstFlag==0) setChargeAgainstReasonRule([{ required: true, message: '请输入未冲销原因!' }])
        form.setFieldsValue(result)
        if(result.violationType=='18'){
          setViolationShow(true)
        }else{
          setViolationShow(false)
        }
        getProvince()
        getCity()
        getPersonList()
      })
    }
  }));
  //获取大区
  const getAllRegional = () => {
    getAllRegionalList().then((res)=>{
        setRegionalList(res.data.data)
    })
  }
  const getProvince = ()=>{
    getProvinceIdListByRegionalNo({
        regionalNo:form.getFieldsValue().regionalNo
    }).then((res)=>{
        setProvinceList(res.data.data)
    })
  }
  const getCity = ()=>{
    getCityListByProvinceId({
        regionalNo:form.getFieldsValue().regionalNo,
        provinceId:form.getFieldsValue().eventProvinceId
    }).then((res)=>{
        setCityList(res.data.data)
    })
  }
  const dealWithTimeChange = (value)=>{
    let a = form.getFieldValue('caseRespondTime')
    if(a&&value<=a){
      form.setFieldsValue({
        dealWithTime:''
      })
      message.error('处置回复时间要在案件响应之后')
    }
    
    if(form.getFieldValue('caseRespondTime')&&form.getFieldValue('dealWithTime')){
      form.setFieldsValue({
        dealWithInterval: subtractTime(moment(form.getFieldValue('caseRespondTime')),form.getFieldValue('dealWithTime'))
      })
    }
  }
  //计算时间差
  const subtractTime = (startDate,endDate)=>{
    let time = endDate-startDate
    console.log(time);
    let days = 1000*60*60*24
    let hours = 1000*60*60
    let minutes = 1000*60
    let day =0
    let hour =0
    let minute =0
    let second = 0
    let timeStr=''
    if(time/days>1){
      day = parseInt(time/days)
      timeStr = day+'天'
    }
    if(time/hours>1){
      hour = parseInt((time-(day*days))/hours)
      timeStr = timeStr+hour+'时'
    }
    if(time/minutes>1){
      minute = parseInt((time-(day*days)-(hour*hours))/minutes)
      timeStr = timeStr+minute+'分'
    }
    if(time/1000>1){
      second = parseInt((time-(day*days)-(hour*hours)-(minute*minutes))/1000)
      timeStr = timeStr+second+'秒'
    }
    console.log(timeStr);
    return timeStr
  }
  // 获取人员
  const getPersonList = async (value) => {
    const res = await getPerson({})
    if (res.data.success) {
      setPersonList(res.data.data||[])
    }
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev => {
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  const handleCancel = () => {
    setPreviewData(prev => {
      return {
        ...prev,
        previewVisible: false
      }
    })
  }
  const changeFile = ({ file, fileList }, type) => {
    fileList = fileList.map(item => {
      if (item.response&&item.response.success) {
        item.url = item.response.data[0].url;
      }
      return item;
    });
    setPreviewData(prev => {
      const data = {...prev}
      data[type] = fileList
      return data
    })
  }
  const setFlieList = (list) => {
    list = list&&list.filter(item=>{
      return item.url
    })
    list = list&&list.map((item,index)=>{
      return {
        url: item.url,
        uid: item.uid || index,
        name: item.name||item.url,
        status: 'done',
      }
    })
    return list
  }
  const setFlieParams = (list,type) => {
      list = list&&list.filter(item=>{
        return item.url
      })
      list = list&&list.map((item,index)=>{
        return {
          url: item.url,
          uid: 0-index,
          name: item.name||item.url,
          status: 'done',
          type: type
        }
      })
      return list
  }
  const insureFlagChange = (value) =>{
    if(value==1){
      console.log('1');
      setRule([{ required: true, message: '请选择保险!' }])
      setInsureOrderNoRule([{ required: true, message: '请输入保单号!' }])
    }else if(value==0){
      setRule([])
      setInsureOrderNoRule([])
      form.setFieldsValue({
        insureCompany:'',
        insureOrderNo:''
      })
    }
  }
  const chargeAgainstFlagChange = (value)=>{
    if(value==0){
      setChargeAgainstReasonRule([{ required: true, message: '请输入未冲销原因!' }])
    }else if(value==1){
      setChargeAgainstReasonRule([])
    }
  }
  const advanceAmountChange = ()=>{
    let value = form.getFieldValue('advanceAmount')
    if(value==0){
      setAdvanceReasonRule([])
    }else{
      setAdvanceReasonRule([{ required: true, message: '请填写垫付原因' }])
    }
  }
  const advanceLossChange = (val)=>{
    let value = form.getFieldValue('advanceLossAmount')
    console.log(val,form.getFieldValue('advanceLossAmount'));
    if(value<=0){
      setAdvanceDamageReasonRule([])
    }else{
      setAdvanceDamageReasonRule([{ required: true, message: '请输入垫付损失原因!' }])
    }
  }
  const comfortLossAmountChange = ()=>{
    form.setFieldsValue({
      comfortLossAmount:form.getFieldValue('familyComfortAmount')-form.getFieldValue('realityComfortAmount')
    })
  }
  const advanceLossAmountChange = (value)=>{
    if(value){
      setChargeAgainstFlagRule([{ required: true, message: '请选择垫付是否冲销!' }])
    }else{
      setChargeAgainstFlagRule([])
    }
    form.setFieldsValue({
      advanceLossAmount:form.getFieldValue('realityAdvanceAmount')-form.getFieldValue('insureCollectionAmount')
    })
    if(form.getFieldValue('advanceLossAmount')>0){
      setAdvanceDamageReasonRule([{ required: true, message: '请输入垫付损失原因!' }])
    }else{
      setAdvanceDamageReasonRule([])
    }
  }
  const submit = (submitType)=>{
    
    let submitMethods
    if(type==1){
      submitMethods = trafficSafetyEventCase
    }else{
      submitMethods = trafficSafetyUpdate
    }
    const formData = form.getFieldValue()
    const query = {submitType,...formData}
    form.validateFields().then((values)=>{
      setLoading(true)
      query.dealWithTime = query.dealWithTime?moment(query.dealWithTime).format("YYYY-MM-DD HH:mm:ss"):''
      query.policeDepartment = query.policeDepartment.join(',')
      query.scenePictureUrl = setFlieParams(query.scenePictureUrl)
      query.orderPictureUrl = setFlieParams(query.orderPictureUrl)
      query.responsibleLetterUrl = setFlieParams(query.responsibleLetterUrl)
      query.medicalProveUrl = setFlieParams(query.medicalProveUrl)
      query.comfortProtocolUrl = setFlieParams(query.comfortProtocolUrl)
      query.otherZipUrl = setFlieParams(query.otherZipUrl)
      submitMethods(query).then((res)=>{
        console.log(res);
        if(res.data.success){
          message.success(submitType==1?'暂存成功':'提交成功')
          setVisible(false)
          refresh('query')
          refresh('search')
        }else{
          message.error(res.data.retMsg)
        }
        setLoading(false)
      }).catch(()=>{
        setLoading(false)
      })
    }).catch(()=>{
      message.error('请完善表单')
    })
  }
  return (
    <>
      <Modal maskClosable={false}
        title={title}
        width='1200px'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={[
          <Button key="back"  onClick={() => { setVisible(false) }}>
            取消
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={()=>{submit(1)}}>
            暂存
          </Button>,
          <Button
            key="link"
            type="primary" loading={loading}
            onClick={()=>{submit(2)}}
          >
            提交审批
          </Button>,
        ]}
      >
        <Form initialValues={initialFormValues} form={form} labelAlign='right' className='trafficSafetyCaseForm'>
          <Row className="margin-15">
            <Col {...topCol}>
              <Form.Item name="eventSourceName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件来源">
                <Input disabled={true}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventOrderNo" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="事件工单号">
                <Input disabled={true} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name='eventTypeNames' label="事件类型" style={{ marginBottom: 0 }} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
                <Input disabled={true}></Input>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eventLevel" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} tooltip={{ title: content, color: '#FFFFFF' }} label="事件等级">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="cityName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="注册城市">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="staffTypeName" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="人员类型">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="name" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="骑手姓名">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="eleCourierId" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="骑手ID">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="phone" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="手机号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name='idNumber' labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="身份证号">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insureFlag" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="是否报保险"
              rules={[{ required: true, message: '请选择是否报保险!' }]}>
                <Select getPopupContainer={triggerNode => triggerNode.parentElement}
                onChange={(values)=>insureFlagChange(values)}>
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insureCompany" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="保险公司" rules={rule}>
                <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentElement}>
                  <Option value={1}>平安</Option>
                  <Option value={2}>前海</Option>
                  <Option value={3}>泰康</Option>
                  <Option value={7}>紫金</Option>
                  <Option value={4}>太平洋</Option>
                  <Option value={6}>大地保险</Option>
                  <Option value={5}>新职业伤害险</Option>     
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="insureOrderNo" labelCol={{ span: 3 }} wrapperCol={{ span: 7 }} label="保单号"
              rules={insureOrderNoRule}>
                <Input placeholder="请输入保单号" maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="caseRespondTime" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="案件响应时间">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dealWithTime" rules={[{ required: true, message: '请选择处置回复时间!' }]} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="处置回复时间">
                <DatePicker
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  allowClear={true}
                  showTime
                  onChange={value=>dealWithTimeChange(value)}
                  format={["YYYY-MM-DD HH:mm:ss"]} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="dealWithInterval" tooltip={{ title: content3, color: '#FFFFFF' }} labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="处置回复时效">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventPlace" label="事件发生地址" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} >
                <TextArea disabled={true} autoSize={{ minRows: 2}}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="eventDescribe" label="事件经过描述" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" rules={[{ required: true, message: '请输入事件发生地址!' }]}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请输入事件发生地址，限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderStatus" rules={[{ required: true, message: '请选择骑手状态!' }]} labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="骑手状态">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value={1}>死亡</Option>
                  <Option value={2}>重伤(ICU)</Option>
                  <Option value={3}>重伤(普通病房)</Option>
                  <Option value={4}>轻伤(住院) </Option>
                  <Option value={5}>轻伤(未住院)</Option>
                  <Option value={6}>无人伤</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderDescribe" label="骑手受伤情况说明" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right"  rules={[{ required: true, message: '请输入骑手受伤情况说明!' }]}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderWakeFlag" rules={[{ required: true, message: '请选择骑手是否清醒!' }]} labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="骑手是否清醒" labelAlign="right"  >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder="请选择">
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="riderSurgicalFlag" rules={[{ required: true, message: '请选择骑手是否需要手术!' }]} labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="骑手是否需要手术" labelAlign="right"  >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="riderFamilyFlag" rules={[{ required: true, message: '请选择骑手家属是否到场!' }]} labelCol={{ span: 3 }} 
              wrapperCol={{ span: 7 }} label="骑手家属是否到场" labelAlign="right"  >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartyStatus" rules={[{ required: true, message: '请选择三者状态!' }]} labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="三者状态" >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>死亡</Option>
                  <Option value={2}>重伤(ICU)</Option>
                  <Option value={3}>重伤(普通病房)</Option>
                  <Option value={4}>轻伤(住院) </Option>
                  <Option value={5}>轻伤(未住院)</Option>
                  <Option value={6}>无人伤</Option>
                  <Option value={0}>无三者</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartySurgicalFlag" rules={[{ required: true, message: '请选择三者是否需要手术!' }]} labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="三者是否需要手术">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="threePartyDescribe" label="三者具体受伤情况及物损说明" labelCol={{ span: 3 }} className='threePartyDescribe'
              wrapperCol={{ span: 19 }} labelAlign="right"  rules={[{ required: true, message: '请输入三者具体受伤情况及物损说明!' }]}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="threePartyDamageAmount" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label="三者财产损失金额"
              rules={[{ required: true, message: '请输入三者财产损失金额!' }]}>
                <InputNumber placeholder="请填写" min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="responsibleParty" rules={[{ required: true, message: '请选择事故定责!' }]} labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="事故定责">
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} >
                  <Option value={1}>待定</Option>
                  <Option value={2}>全责</Option>
                  <Option value={3}>主责</Option>
                  <Option value={4}>同责</Option>
                  <Option value={5}>次责 </Option>
                  <Option value={6}>无责</Option>
                  <Option value={7}>无需定责</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="violationType" labelCol={{ span: 6 }} 
              wrapperCol={{ span: 14 }} label="违规项目" rules={[{ required: true, message: '请选择违规项目!' }]} >
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} onChange={(value)=>{violationChange(value)}} >
                  <Option value='0'>判定无违规</Option>
                  <Option value='1'>超速驾驶(时速超过25KM/H)</Option>
                  <Option value='2'>逆行</Option>
                  <Option value='3'>闯红灯</Option>
                  <Option value='4'>骑行时使用手机</Option>
                  {/* <Option value='5'>追尾</Option>
                  <Option value='6'>肇事逃逸</Option> */}
                  <Option value='7'>酒后驾驶</Option>
                  <Option value='8'>超车不当</Option>
                  <Option value='9'>走机动车道</Option>
                  <Option value='10'>未佩戴头盔</Option>
                  <Option value='11'>转弯未让直行</Option>
                  <Option value='12'>横穿马路</Option>
                  <Option value='13'>单手驾驶</Option>
                  <Option value='14'>违规载人</Option>
                  <Option value='15'>驾驶不合格车辆</Option>
                  <Option value='16'>无证驾驶</Option>
                  <Option value='17'>无法判定</Option>
                  <Option value='18'>自定义</Option>
                </Select>
              </Form.Item>
            </Col>
            {violationShow&&<Col {...topCol}>
              <Form.Item name="violationDescribe" label="自定义违规项目" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} rules={[{ required: violationShow, message: '请输入自定义违规项目!' }]}>
                <Input placeholder="请输入" maxLength={128} />
              </Form.Item>
            </Col>}
            <Col span={24}>
              <Form.Item name="costDescribe" label="费用支出说明" labelCol={{ span: 3 }} 
              wrapperCol={{ span: 19 }} labelAlign="right" rules={[{ required: true, message: '请输入费用支出说明!' }]}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="requireDescribe" label="甲方要求" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" rules={[{ required: true, message: '请输入甲方要求!' }]}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="advanceAmount" label="预估垫付金额" labelCol={{ span: 3 }} wrapperCol={{ span: 7 }} 
              labelAlign="right" rules={[{ required: true, message: '请输入预估垫付金额!' }]}>
                <InputNumber placeholder="请填写" min={0} max={9999999999} maxLength={100} precision={2} onChange={advanceAmountChange}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="advanceReason" label="垫付原因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" rules={advanceReasonRule}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="borrowAmount" label="借款金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" rules={[{ required: true, message: '请输入借款金额!' }]}>
                <InputNumber placeholder="请填写" min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="realityAdvanceAmount" label="实际垫付金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" rules={[{ required: true, message: '请输入实际垫付金额!' }]}>
                <InputNumber placeholder="请填写" onChange={value=>advanceLossAmountChange(value)} min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="insureCollectionAmount" label="保险回款金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" rules={[{ required: true, message: '请输入保险回款金额!' }]} >
                <InputNumber placeholder="请填写" onChange={advanceLossAmountChange} min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="chargeAgainstFlag" label="垫付是否冲销" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" rules={chargeAgainstFlagRule}>
                <Select getPopupContainer={triggerNode => triggerNode.parentElement} placeholder='请选择'
                onChange={value=>chargeAgainstFlagChange(value)}>
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="chargeAgainstReason" label="未冲销原因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" rules={chargeAgainstReasonRule}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="advanceLossAmount" label="垫付损失金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" tooltip={{ title: content4, color: '#FFFFFF' }} rules={[{ required: true, message: '请输入垫付损失金额!' }]}>
                <Input  disabled={true} onChange={value=>advanceLossAmountChange(value)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="advanceDamageReason" label="垫付损失原因" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" rules={advanceDamageReasonRule}>
                <TextArea showCount maxLength={500} autoSize={{ minRows: 2}} placeholder="请填写,限500字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="finalSolution" label="最终解决方案" labelCol={{ span: 3 }} wrapperCol={{ span: 19 }} 
              labelAlign="right" rules={[{ required: true, message: '请输入最终解决方案!' }]}>
                <TextArea showCount maxLength={500} placeholder="请填写,限500字" autoSize={{ minRows: 2}}/>
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="familyComfortAmount" label="家属诉求慰问金" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" rules={[{ required: true, message: '请输入家属诉求慰问金!' }]}>
                <InputNumber placeholder="请填写" onChange={comfortLossAmountChange} min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="realityComfortAmount" label="实际赔付慰问金" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" rules={[{ required: true, message: '请输入实际赔付慰问金!' }]}>
                <InputNumber placeholder="请填写" onChange={comfortLossAmountChange} min={0} max={9999999999} maxLength={100} precision={2} />
              </Form.Item>
            </Col>
            <Col {...topCol}>
              <Form.Item name="comfortLossAmount" label="减损金额" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} 
              labelAlign="right" tooltip={{ title: content5, color: '#FFFFFF' }}>
                <Input  disabled={true} value={form.getFieldValue('familyComfortAmount')-form.getFieldValue('realityComfortAmount')}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} name='scenePictureUrl' valuePropName="fileList"
                getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={{ span: 19 }} label="现场/住院照片">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  multiple
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={10}
                  name="files"
                  fileList={previewData.fileList1}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList1')}
                >
                  {previewData.fileList1.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: 'right' }}>订单截图<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
            </Col>
            <Col span={19}>
              <Form.Item name='orderPictureUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  multiple
                  listType="picture-card"
                  data={uploadObj}
                  maxCount={10}
                  name="files"
                  fileList={previewData.fileList2}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList2')}
                >
                  {previewData.fileList2.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: 'right' }}>责任认定书<span style={{ 'margin': '0 8px 0 2px' }}>:</span></div>
            </Col>
            <Col span={19}>
              <Form.Item name='responsibleLetterUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  multiple
                  maxCount={10}
                  name="files"
                  fileList={previewData.fileList3}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList3')}
                >
                  {previewData.fileList3.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} name='medicalProveUrl' valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')} 
              wrapperCol={{ span: 19 }} label="诊断证明或病历">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  multiple
                  maxCount={10}
                  name="files"
                  fileList={previewData.fileList4}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList4')}
                >
                  {previewData.fileList4.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item labelCol={{ span: 3 }} name='comfortProtocolUrl' valuePropName="fileList" 
              getValueFromEvent={(e) => normFile(e, 'list')} wrapperCol={{ span: 19 }} label="慰问协议">
                <Upload
                  accept='.jpg,.jpeg,.png'
                  action={action}
                  listType="picture-card"
                  data={uploadObj}
                  multiple
                  maxCount={10}
                  name="files"
                  fileList={previewData.fileList5}
                  onPreview={handlePreview}
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList5')}
                >
                  {previewData.fileList5.length >= 10 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="otherZipUrl" label="家属资料/诉讼材料/其他" getValueFromEvent={(e) => normFile(e, 'list')}
                valuePropName="fileList"
                labelCol={{ span: 3 }} wrapperCol={{ span: 19 }}>
                <Upload name="files" data={uploadObj}
                  fileList={previewData.fileList6}
                  action={action}
                  maxCount={1}
                  listType="picture"
                  onChange={({ file, fileList }) => changeFile({ file, fileList }, 'fileList6')}
                  >
                    {previewData.fileList6.length >= 1 ? null : <Button type="primary">上传压缩包</Button>}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Modal maskClosable={false}
          width={1000}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
        </Modal>
      </Modal>
    </>
  );
};
export default forwardRef(CaseReport)