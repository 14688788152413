import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Form, Input, Modal, Row, Col, Typography, Button,message } from "antd";
import { getContractArchivesDetail,updateContractArchives } from '../../api'

const { TextArea } = Input;
const { Title } = Typography
const ConfigModal = forwardRef((props,ref)=>{
    const { refresh } = props
    const [title, setTitle] = useState('备注');
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm()
    const initialValues = {}
    useImperativeHandle(ref, () => ({
        remarkData: (record) => {
            setTitle('备注')
            setVisible(true)
            getContractArchivesDetail({contractNo:record.contractNo}).then(res=>{
                form.setFieldsValue({
                    ...res.data.data,
                    filingFlag:res.data.data.filingFlag ===1 ? '已归档' : '未归档'
                })
            })
        },
    }));

    const onFinish = (values) => {
        console.log(values);
        let params = {
         contractNo:values.contractNo,
         remark:values.remark,
        }
 
        updateContractArchives(params).then(res=>{
             if(res.data.success){
                 message.success('修改成功！')
                 onCancel()
                 refresh()
             }
        })
     }
    
    const onCancel = () =>{
        setVisible(false)
        form.resetFields()
    }
    return (
      <Modal maskClosable={false}
        title={title} 
        visible={visible} 
        width={800}
        onCancel={ onCancel }
        footer={[
            <Button key="back" onClick={onCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={form.submit}>
              保存
            </Button>]}>
        <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ flex: '100px' }}
            initialValues={initialValues}
            >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} style={{ margin: '0px 20px 10px 20px', color: '#23a8a8' }}>合同信息</Title>
                </Col>
                <Col span={12}>
                    <Form.Item name="dingEndTime" label="审批通过时间" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="sourceName" label="来源" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="processNo" label="流程编号" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='contractNo' label='合同编码' >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="contractName" label="合同名称" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="partyA" label="合同甲方" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="partyB" label="合同乙方" >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="contractStartDate" label="起始日期">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="contractEndDate" label="终止日期">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="applyName" label="申请人">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="applyDepartment" label="部门">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="applyDate" label="申请日期">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="sealTypeName" label="用印类型">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="stampTypeName" label="印章类型">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Title level={5} style={{ margin: '20px 20px 10px 20px', color: '#23a8a8' }}>档案信息</Title>
                </Col>
                <Col span={12}>
                    <Form.Item name="reverseSignatureDay" label="倒签时间">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="sealNum" label="用印份数">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="sealMsg" label="用印说明">
                        <TextArea disabled rows={3} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item className='wrapl' name="sendAddress" label="回寄联系人及地址">
                        <TextArea disabled rows={3} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="remark" label="备注">
                        <TextArea rows={3} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
      </Modal>
    );
})

export default ConfigModal;
