export default {
    chinaMap : [{
      "children": [{
        "key": 3,
        "title": "北京市",
        "order": 1
      }],
      "key": 10001,
      "title": "北京市",
      "order": 1
    }, {
      "children": [{
        "key": 5,
        "title": "天津市",
        "order": 1
      }],
      "key": 10002,
      "title": "天津市",
      "order": 2
    }, {
      "children": [{
        "key": 154,
        "title": "张家口市",
        "order": 7
      }, {
        "key": 170,
        "title": "邯郸市",
        "order": 8
      }, {
        "key": 172,
        "title": "承德市",
        "order": 9
      }, {
        "key": 175,
        "title": "沧州市",
        "order": 10
      }, {
        "key": 179,
        "title": "邢台市",
        "order": 11
      }, {
        "key": 266,
        "title": "衡水市",
        "order": 2
      }, {
        "key": 31,
        "title": "石家庄市",
        "order": 1
      }, {
        "key": 40,
        "title": "廊坊市",
        "order": 3
      }, {
        "key": 63,
        "title": "秦皇岛市",
        "order": 4
      }, {
        "key": 87,
        "title": "唐山市",
        "order": 5
      }, {
        "key": 95,
        "title": "保定市",
        "order": 6
      }],
      "key": 10003,
      "title": "河北省",
      "order": 4
    }, {
      "children": [{
        "key": 153,
        "title": "晋中市",
        "order": 5
      }, {
        "key": 166,
        "title": "运城市",
        "order": 6
      }, {
        "key": 168,
        "title": "临汾市",
        "order": 7
      }, {
        "key": 169,
        "title": "大同市",
        "order": 8
      }, {
        "key": 237,
        "title": "长治市",
        "order": 11
      }, {
        "key": 263,
        "title": "忻州市",
        "order": 1
      }, {
        "key": 281,
        "title": "吕梁市",
        "order": 2
      }, {
        "key": 392,
        "title": "朔州市",
        "order": 4
      }, {
        "key": 43,
        "title": "太原市",
        "order": 3
      }, {
        "key": 437,
        "title": "晋城市",
        "order": 9
      }, {
        "key": 995,
        "title": "阳泉市",
        "order": 10
      }],
      "key": 10004,
      "title": "山西省",
      "order": 5
    }, {
      "children": [{
        "key": 1397,
        "title": "兴安盟",
        "order": 5
      }, {
        "key": 1403,
        "title": "锡林郭勒盟",
        "order": 6
      }, {
        "key": 1415,
        "title": "阿拉善盟",
        "order": 7
      }, {
        "key": 268,
        "title": "通辽市",
        "order": 2
      }, {
        "key": 337,
        "title": "赤峰市",
        "order": 1
      }, {
        "key": 393,
        "title": "乌海市",
        "order": 8
      }, {
        "key": 394,
        "title": "乌兰察布市",
        "order": 9
      }, {
        "key": 395,
        "title": "鄂尔多斯市",
        "order": 10
      }, {
        "key": 398,
        "title": "呼伦贝尔市",
        "order": 11
      }, {
        "key": 68,
        "title": "包头市",
        "order": 3
      }, {
        "key": 914,
        "title": "巴彦淖尔市",
        "order": 12
      }, {
        "key": 92,
        "title": "呼和浩特市",
        "order": 4
      }],
      "key": 10005,
      "title": "内蒙古",
      "order": 6
    }, {
      "children": [{
        "key": 108,
        "title": "抚顺市",
        "order": 8
      }, {
        "key": 110,
        "title": "锦州市",
        "order": 9
      }, {
        "key": 118,
        "title": "阜新市",
        "order": 10
      }, {
        "key": 145,
        "title": "丹东市",
        "order": 12
      }, {
        "key": 16,
        "title": "沈阳市",
        "order": 4
      }, {
        "key": 20,
        "title": "大连市",
        "order": 5
      }, {
        "key": 218,
        "title": "葫芦岛市",
        "order": 13
      }, {
        "key": 222,
        "title": "辽阳市",
        "order": 14
      }, {
        "key": 336,
        "title": "本溪市",
        "order": 6
      }, {
        "key": 339,
        "title": "营口市",
        "order": 1
      }, {
        "key": 340,
        "title": "盘锦市",
        "order": 2
      }, {
        "key": 360,
        "title": "朝阳市",
        "order": 3
      }, {
        "key": 382,
        "title": "铁岭市",
        "order": 11
      }, {
        "key": 96,
        "title": "鞍山市",
        "order": 7
      }],
      "key": 10006,
      "title": "辽宁省",
      "order": 7
    }, {
      "children": [{
        "key": 101,
        "title": "吉林市",
        "order": 2
      }, {
        "key": 12,
        "title": "长春市",
        "order": 1
      }, {
        "key": 167,
        "title": "延边朝鲜族自治州",
        "order": 4
      }, {
        "key": 171,
        "title": "四平市",
        "order": 5
      }, {
        "key": 223,
        "title": "白城市",
        "order": 6
      }, {
        "key": 227,
        "title": "辽源市",
        "order": 7
      }, {
        "key": 239,
        "title": "通化市",
        "order": 8
      }, {
        "key": 361,
        "title": "松原市",
        "order": 9
      }, {
        "key": 397,
        "title": "白山市",
        "order": 3
      }],
      "key": 10007,
      "title": "吉林省",
      "order": 8
    }, {
      "children": [{
        "key": 10,
        "title": "哈尔滨市",
        "order": 1
      }, {
        "key": 1149,
        "title": "黑河市",
        "order": 13
      }, {
        "key": 138,
        "title": "牡丹江市",
        "order": 5
      }, {
        "key": 1511,
        "title": "大兴安岭地区",
        "order": 12
      }, {
        "key": 221,
        "title": "鸡西市",
        "order": 10
      }, {
        "key": 228,
        "title": "绥化市",
        "order": 11
      }, {
        "key": 696,
        "title": "伊春市",
        "order": 6
      }, {
        "key": 697,
        "title": "鹤岗市",
        "order": 7
      }, {
        "key": 698,
        "title": "七台河市",
        "order": 8
      }, {
        "key": 699,
        "title": "双鸭山市",
        "order": 9
      }, {
        "key": 86,
        "title": "大庆市",
        "order": 2
      }, {
        "key": 88,
        "title": "佳木斯市",
        "order": 3
      }, {
        "key": 91,
        "title": "齐齐哈尔市",
        "order": 4
      }],
      "key": 10008,
      "title": "黑龙江省",
      "order": 9
    }, {
      "children": [{
        "key": 1,
        "title": "上海市",
        "order": 1
      }],
      "key": 10009,
      "title": "上海市",
      "order": 3
    }, {
      "children": [{
        "key": 114,
        "title": "淮安市",
        "order": 12
      }, {
        "key": 206,
        "title": "宿迁市",
        "order": 13
      }, {
        "key": 22,
        "title": "扬州市",
        "order": 3
      }, {
        "key": 27,
        "title": "镇江市",
        "order": 4
      }, {
        "key": 29,
        "title": "徐州市",
        "order": 5
      }, {
        "key": 35,
        "title": "无锡市",
        "order": 6
      }, {
        "key": 39,
        "title": "南通市",
        "order": 7
      }, {
        "key": 59,
        "title": "常州市",
        "order": 8
      }, {
        "key": 6,
        "title": "南京市",
        "order": 1
      }, {
        "key": 71,
        "title": "盐城市",
        "order": 9
      }, {
        "key": 8,
        "title": "苏州市",
        "order": 2
      }, {
        "key": 89,
        "title": "连云港市",
        "order": 10
      }, {
        "key": 93,
        "title": "泰州市",
        "order": 11
      }],
      "key": 10010,
      "title": "江苏省",
      "order": 10
    }, {
      "children": [{
        "key": 18,
        "title": "宁波市",
        "order": 2
      }, {
        "key": 182,
        "title": "衢州市",
        "order": 11
      }, {
        "key": 2,
        "title": "杭州市",
        "order": 1
      }, {
        "key": 299,
        "title": "舟山市",
        "order": 4
      }, {
        "key": 30,
        "title": "温州市",
        "order": 3
      }, {
        "key": 52,
        "title": "台州市",
        "order": 5
      }, {
        "key": 55,
        "title": "嘉兴市",
        "order": 6
      }, {
        "key": 61,
        "title": "湖州市",
        "order": 7
      }, {
        "key": 67,
        "title": "金华市",
        "order": 8
      }, {
        "key": 69,
        "title": "绍兴市",
        "order": 9
      }, {
        "key": 73,
        "title": "丽水市",
        "order": 10
      }],
      "key": 10011,
      "title": "浙江省",
      "order": 11
    }, {
      "children": [{
        "key": 135,
        "title": "宿州市",
        "order": 12
      }, {
        "key": 17,
        "title": "合肥市",
        "order": 1
      }, {
        "key": 195,
        "title": "阜阳市",
        "order": 13
      }, {
        "key": 199,
        "title": "黄山市",
        "order": 14
      }, {
        "key": 214,
        "title": "淮北市",
        "order": 15
      }, {
        "key": 246,
        "title": "池州市",
        "order": 16
      }, {
        "key": 313,
        "title": "亳州市",
        "order": 4
      }, {
        "key": 314,
        "title": "宣城市",
        "order": 5
      }, {
        "key": 38,
        "title": "芜湖市",
        "order": 2
      }, {
        "key": 53,
        "title": "淮南市",
        "order": 3
      }, {
        "key": 65,
        "title": "铜陵市",
        "order": 6
      }, {
        "key": 70,
        "title": "安庆市",
        "order": 7
      }, {
        "key": 75,
        "title": "蚌埠市",
        "order": 8
      }, {
        "key": 83,
        "title": "滁州市",
        "order": 9
      }, {
        "key": 85,
        "title": "六安市",
        "order": 10
      }, {
        "key": 90,
        "title": "马鞍山市",
        "order": 11
      }],
      "key": 10012,
      "title": "安徽省",
      "order": 12
    }, {
      "children": [{
        "key": 102,
        "title": "泉州市",
        "order": 5
      }, {
        "key": 111,
        "title": "漳州市",
        "order": 6
      }, {
        "key": 117,
        "title": "南平市",
        "order": 7
      }, {
        "key": 13,
        "title": "厦门市",
        "order": 1
      }, {
        "key": 180,
        "title": "莆田市",
        "order": 8
      }, {
        "key": 181,
        "title": "龙岩市",
        "order": 9
      }, {
        "key": 270,
        "title": "三明市",
        "order": 3
      }, {
        "key": 271,
        "title": "宁德市",
        "order": 4
      }, {
        "key": 9,
        "title": "福州市",
        "order": 2
      }],
      "key": 10013,
      "title": "福建省",
      "order": 13
    }, {
      "children": [{
        "key": 127,
        "title": "新余市",
        "order": 5
      }, {
        "key": 128,
        "title": "宜春市",
        "order": 6
      }, {
        "key": 129,
        "title": "赣州市",
        "order": 7
      }, {
        "key": 150,
        "title": "萍乡市",
        "order": 8
      }, {
        "key": 219,
        "title": "抚州市",
        "order": 9
      }, {
        "key": 220,
        "title": "吉安市",
        "order": 10
      }, {
        "key": 25,
        "title": "南昌市",
        "order": 1
      }, {
        "key": 384,
        "title": "鹰潭市",
        "order": 11
      }, {
        "key": 49,
        "title": "景德镇市",
        "order": 2
      }, {
        "key": 66,
        "title": "九江市",
        "order": 3
      }, {
        "key": 97,
        "title": "上饶市",
        "order": 4
      }],
      "key": 10014,
      "title": "江西省",
      "order": 14
    }, {
      "children": [{
        "key": 132,
        "title": "东营市",
        "order": 13
      }, {
        "key": 134,
        "title": "滨州市",
        "order": 14
      }, {
        "key": 177,
        "title": "菏泽市",
        "order": 16
      }, {
        "key": 19,
        "title": "济南市",
        "order": 2
      }, {
        "key": 224,
        "title": "德州市",
        "order": 17
      }, {
        "key": 28,
        "title": "青岛市",
        "order": 1
      }, {
        "key": 34,
        "title": "威海市",
        "order": 3
      }, {
        "key": 391,
        "title": "莱芜市",
        "order": 15
      }, {
        "key": 47,
        "title": "济宁市",
        "order": 4
      }, {
        "key": 48,
        "title": "潍坊市",
        "order": 5
      }, {
        "key": 50,
        "title": "淄博市",
        "order": 6
      }, {
        "key": 54,
        "title": "枣庄市",
        "order": 7
      }, {
        "key": 58,
        "title": "日照市",
        "order": 8
      }, {
        "key": 60,
        "title": "临沂市",
        "order": 9
      }, {
        "key": 74,
        "title": "泰安市",
        "order": 10
      }, {
        "key": 77,
        "title": "烟台市",
        "order": 11
      }, {
        "key": 84,
        "title": "聊城市",
        "order": 12
      }],
      "key": 10015,
      "title": "山东省",
      "order": 15
    }, {
      "children": [{
        "key": 144,
        "title": "焦作市",
        "order": 12
      }, {
        "key": 183,
        "title": "开封市",
        "order": 13
      }, {
        "key": 198,
        "title": "许昌市",
        "order": 14
      }, {
        "key": 204,
        "title": "周口市",
        "order": 15
      }, {
        "key": 234,
        "title": "信阳市",
        "order": 16
      }, {
        "key": 253,
        "title": "商丘市",
        "order": 17
      }, {
        "key": 260,
        "title": "南阳市",
        "order": 6
      }, {
        "key": 261,
        "title": "安阳市",
        "order": 7
      }, {
        "key": 269,
        "title": "平顶山市",
        "order": 8
      }, {
        "key": 32,
        "title": "郑州市",
        "order": 9
      }, {
        "key": 374,
        "title": "濮阳市",
        "order": 18
      }, {
        "key": 399,
        "title": "驻马店市",
        "order": 3
      }, {
        "key": 400,
        "title": "漯河市",
        "order": 2
      }, {
        "key": 401,
        "title": "三门峡市",
        "order": 4
      }, {
        "key": 406,
        "title": "鹤壁市",
        "order": 5
      }, {
        "key": 415,
        "title": "济源市",
        "order": 1
      }, {
        "key": 76,
        "title": "新乡市",
        "order": 10
      }, {
        "key": 94,
        "title": "洛阳市",
        "order": 11
      }],
      "key": 10016,
      "title": "河南省",
      "order": 16
    }, {
      "children": [{
        "key": 100,
        "title": "荆州市",
        "order": 3
      }, {
        "key": 151,
        "title": "荆门市",
        "order": 4
      }, {
        "key": 152,
        "title": "黄石市",
        "order": 5
      }, {
        "key": 1691,
        "title": "恩施土家族苗族自治州",
        "order": 6
      }, {
        "key": 184,
        "title": "鄂州市",
        "order": 7
      }, {
        "key": 196,
        "title": "十堰市",
        "order": 9
      }, {
        "key": 205,
        "title": "孝感市",
        "order": 11
      }, {
        "key": 217,
        "title": "黄冈市",
        "order": 13
      }, {
        "key": 230,
        "title": "咸宁市",
        "order": 14
      }, {
        "key": 241,
        "title": "襄阳市",
        "order": 15
      }, {
        "key": 350,
        "title": "仙桃市",
        "order": 16
      }, {
        "key": 383,
        "title": "随州市",
        "order": 17
      }, {
        "key": 445,
        "title": "潜江市",
        "order": 8
      }, {
        "key": 5584,
        "title": "神农架林区",
        "order": 12
      }, {
        "key": 7,
        "title": "武汉市",
        "order": 1
      }, {
        "key": 710,
        "title": "天门市",
        "order": 10
      }, {
        "key": 80,
        "title": "宜昌市",
        "order": 2
      }],
      "key": 10017,
      "title": "湖北省",
      "order": 17
    }, {
      "children": [{
        "key": 116,
        "title": "衡阳市",
        "order": 4
      }, {
        "key": 119,
        "title": "益阳市",
        "order": 5
      }, {
        "key": 120,
        "title": "常德市",
        "order": 6
      }, {
        "key": 124,
        "title": "岳阳市",
        "order": 7
      }, {
        "key": 126,
        "title": "株洲市",
        "order": 8
      }, {
        "key": 133,
        "title": "怀化市",
        "order": 9
      }, {
        "key": 143,
        "title": "娄底市",
        "order": 10
      }, {
        "key": 1728,
        "title": "湘西土家族苗族自治州",
        "order": 14
      }, {
        "key": 173,
        "title": "邵阳市",
        "order": 11
      }, {
        "key": 176,
        "title": "永州市",
        "order": 12
      }, {
        "key": 178,
        "title": "郴州市",
        "order": 13
      }, {
        "key": 21,
        "title": "长沙市",
        "order": 2
      }, {
        "key": 280,
        "title": "张家界市",
        "order": 3
      }, {
        "key": 42,
        "title": "湘潭市",
        "order": 1
      }],
      "key": 10018,
      "title": "湖南省",
      "order": 18
    }, {
      "children": [{
        "key": 11,
        "title": "深圳市",
        "order": 4
      }, {
        "key": 123,
        "title": "茂名市",
        "order": 13
      }, {
        "key": 130,
        "title": "江门市",
        "order": 14
      }, {
        "key": 200,
        "title": "汕头市",
        "order": 15
      }, {
        "key": 202,
        "title": "阳江市",
        "order": 16
      }, {
        "key": 203,
        "title": "中山市",
        "order": 17
      }, {
        "key": 231,
        "title": "揭阳市",
        "order": 18
      }, {
        "key": 235,
        "title": "潮州市",
        "order": 19
      }, {
        "key": 24,
        "title": "佛山市",
        "order": 2
      }, {
        "key": 26,
        "title": "珠海市",
        "order": 5
      }, {
        "key": 365,
        "title": "河源市",
        "order": 20
      }, {
        "key": 371,
        "title": "清远市",
        "order": 21
      }, {
        "key": 4,
        "title": "广州市",
        "order": 1
      }, {
        "key": 421,
        "title": "云浮市",
        "order": 3
      }, {
        "key": 51,
        "title": "东莞市",
        "order": 7
      }, {
        "key": 544,
        "title": "汕尾市",
        "order": 6
      }, {
        "key": 56,
        "title": "梅州市",
        "order": 8
      }, {
        "key": 57,
        "title": "惠州市",
        "order": 9
      }, {
        "key": 64,
        "title": "肇庆市",
        "order": 10
      }, {
        "key": 72,
        "title": "韶关市",
        "order": 11
      }, {
        "key": 79,
        "title": "湛江市",
        "order": 12
      }],
      "key": 10019,
      "title": "广东省",
      "order": 19
    }, {
      "children": [{
        "key": 1167,
        "title": "河池市",
        "order": 8
      }, {
        "key": 156,
        "title": "百色市",
        "order": 9
      }, {
        "key": 157,
        "title": "钦州市",
        "order": 10
      }, {
        "key": 1794,
        "title": "防城港市",
        "order": 1
      }, {
        "key": 1827,
        "title": "来宾市",
        "order": 4
      }, {
        "key": 243,
        "title": "梧州市",
        "order": 12
      }, {
        "key": 252,
        "title": "贺州市",
        "order": 13
      }, {
        "key": 254,
        "title": "玉林市",
        "order": 14
      }, {
        "key": 289,
        "title": "崇左市",
        "order": 2
      }, {
        "key": 290,
        "title": "北海市",
        "order": 3
      }, {
        "key": 431,
        "title": "贵港市",
        "order": 11
      }, {
        "key": 45,
        "title": "南宁市",
        "order": 5
      }, {
        "key": 81,
        "title": "柳州市",
        "order": 6
      }, {
        "key": 98,
        "title": "桂林市",
        "order": 7
      }],
      "key": 10020,
      "title": "广西壮族自治区",
      "order": 20
    }, {
      "children": [{
        "key": 1146,
        "title": "陵水黎族自治县",
        "order": 19
      }, {
        "key": 165,
        "title": "三亚市",
        "order": 18
      }, {
        "key": 1839,
        "title": "三沙市",
        "order": 4
      }, {
        "key": 1844,
        "title": "五指山市",
        "order": 5
      }, {
        "key": 1845,
        "title": "白沙黎族自治县",
        "order": 6
      }, {
        "key": 1846,
        "title": "昌江黎族自治县",
        "order": 7
      }, {
        "key": 1847,
        "title": "乐东黎族自治县",
        "order": 8
      }, {
        "key": 1848,
        "title": "保亭黎族苗族自治县",
        "order": 9
      }, {
        "key": 1849,
        "title": "琼中黎族苗族自治县",
        "order": 10
      }, {
        "key": 278,
        "title": "琼海市",
        "order": 1
      }, {
        "key": 282,
        "title": "文昌市",
        "order": 2
      }, {
        "key": 286,
        "title": "儋州市",
        "order": 3
      }, {
        "key": 581,
        "title": "万宁市",
        "order": 12
      }, {
        "key": 582,
        "title": "澄迈县",
        "order": 13
      }, {
        "key": 583,
        "title": "东方市",
        "order": 14
      }, {
        "key": 584,
        "title": "临高县",
        "order": 15
      }, {
        "key": 585,
        "title": "屯昌县",
        "order": 16
      }, {
        "key": 586,
        "title": "定安县",
        "order": 17
      }, {
        "key": 62,
        "title": "海口市",
        "order": 11
      }],
      "key": 10021,
      "title": "海南省",
      "order": 21
    }, {
      "children": [{
        "key": 44,
        "title": "重庆市",
        "order": 1
      }],
      "key": 10022,
      "title": "重庆市",
      "order": 22
    }, {
      "children": [{
        "key": 103,
        "title": "宜宾市",
        "order": 11
      }, {
        "key": 104,
        "title": "内江市",
        "order": 12
      }, {
        "key": 105,
        "title": "自贡市",
        "order": 13
      }, {
        "key": 106,
        "title": "泸州市",
        "order": 14
      }, {
        "key": 131,
        "title": "德阳市",
        "order": 17
      }, {
        "key": 139,
        "title": "乐山市",
        "order": 18
      }, {
        "key": 14,
        "title": "成都市",
        "order": 1
      }, {
        "key": 1905,
        "title": "阿坝藏族羌族自治州",
        "order": 15
      }, {
        "key": 1919,
        "title": "甘孜藏族自治州",
        "order": 16
      }, {
        "key": 1938,
        "title": "凉山彝族自治州",
        "order": 19
      }, {
        "key": 240,
        "title": "雅安市",
        "order": 20
      }, {
        "key": 247,
        "title": "达州市",
        "order": 21
      }, {
        "key": 262,
        "title": "广元市",
        "order": 2
      }, {
        "key": 291,
        "title": "遂宁市",
        "order": 3
      }, {
        "key": 312,
        "title": "眉山市",
        "order": 4
      }, {
        "key": 315,
        "title": "攀枝花市",
        "order": 5
      }, {
        "key": 316,
        "title": "广安市",
        "order": 6
      }, {
        "key": 317,
        "title": "巴中市",
        "order": 7
      }, {
        "key": 323,
        "title": "资阳市",
        "order": 8
      }, {
        "key": 82,
        "title": "绵阳市",
        "order": 9
      }, {
        "key": 99,
        "title": "南充市",
        "order": 10
      }],
      "key": 10023,
      "title": "四川省",
      "order": 23
    }, {
      "children": [{
        "key": 1175,
        "title": "黔南布依族苗族自治州",
        "order": 4
      }, {
        "key": 1176,
        "title": "黔东南苗族侗族自治州",
        "order": 5
      }, {
        "key": 1177,
        "title": "黔西南布依族苗族自治州",
        "order": 6
      }, {
        "key": 136,
        "title": "毕节市",
        "order": 2
      }, {
        "key": 137,
        "title": "贵阳市",
        "order": 3
      }, {
        "key": 226,
        "title": "铜仁市",
        "order": 8
      }, {
        "key": 273,
        "title": "安顺市",
        "order": 1
      }, {
        "key": 379,
        "title": "遵义市",
        "order": 9
      }, {
        "key": 441,
        "title": "六盘水市",
        "order": 7
      }],
      "key": 10024,
      "title": "贵州省",
      "order": 24
    }, {
      "children": [{
        "key": 155,
        "title": "丽江市",
        "order": 16
      }, {
        "key": 2063,
        "title": "临沧市",
        "order": 3
      }, {
        "key": 2071,
        "title": "楚雄彝族自治州",
        "order": 5
      }, {
        "key": 2081,
        "title": "红河哈尼族彝族自治州",
        "order": 6
      }, {
        "key": 2089,
        "title": "文山壮族苗族自治州",
        "order": 10
      }, {
        "key": 2097,
        "title": "西双版纳傣族自治州",
        "order": 11
      }, {
        "key": 2100,
        "title": "大理白族自治州",
        "order": 12
      }, {
        "key": 2111,
        "title": "德宏傣族景颇族自治州",
        "order": 13
      }, {
        "key": 2117,
        "title": "怒江傈僳族自治州",
        "order": 14
      }, {
        "key": 2122,
        "title": "迪庆藏族自治州",
        "order": 15
      }, {
        "key": 23,
        "title": "昆明市",
        "order": 4
      }, {
        "key": 264,
        "title": "保山市",
        "order": 1
      }, {
        "key": 265,
        "title": "曲靖市",
        "order": 2
      }, {
        "key": 292,
        "title": "玉溪市",
        "order": 7
      }, {
        "key": 294,
        "title": "普洱市",
        "order": 8
      }, {
        "key": 295,
        "title": "昭通市",
        "order": 9
      }],
      "key": 10025,
      "title": "云南省",
      "order": 25
    }, {
      "children": [{
        "key": 2133,
        "title": "日喀则市",
        "order": 1
      }, {
        "key": 2151,
        "title": "昌都市",
        "order": 2
      }, {
        "key": 2163,
        "title": "山南市",
        "order": 3
      }, {
        "key": 2176,
        "title": "那曲地区",
        "order": 4
      }, {
        "key": 2188,
        "title": "阿里地区",
        "order": 5
      }, {
        "key": 2196,
        "title": "林芝市",
        "order": 6
      }, {
        "key": 232,
        "title": "拉萨市",
        "order": 7
      }],
      "key": 10026,
      "title": "西藏自治区",
      "order": 26
    }, {
      "children": [{
        "key": 1008,
        "title": "铜川市",
        "order": 8
      }, {
        "key": 115,
        "title": "咸阳市",
        "order": 6
      }, {
        "key": 15,
        "title": "西安市",
        "order": 1
      }, {
        "key": 2277,
        "title": "商洛市",
        "order": 7
      }, {
        "key": 242,
        "title": "延安市",
        "order": 9
      }, {
        "key": 244,
        "title": "宝鸡市",
        "order": 10
      }, {
        "key": 259,
        "title": "汉中市",
        "order": 2
      }, {
        "key": 272,
        "title": "安康市",
        "order": 3
      }, {
        "key": 306,
        "title": "渭南市",
        "order": 4
      }, {
        "key": 307,
        "title": "榆林市",
        "order": 5
      }],
      "key": 10027,
      "title": "陕西省",
      "order": 27
    }, {
      "children": [{
        "key": 2323,
        "title": "定西市",
        "order": 9
      }, {
        "key": 2330,
        "title": "陇南市",
        "order": 10
      }, {
        "key": 2339,
        "title": "临夏回族自治州",
        "order": 11
      }, {
        "key": 2347,
        "title": "甘南藏族自治州",
        "order": 14
      }, {
        "key": 298,
        "title": "天水市",
        "order": 13
      }, {
        "key": 341,
        "title": "庆阳市",
        "order": 1
      }, {
        "key": 342,
        "title": "张掖市",
        "order": 2
      }, {
        "key": 41,
        "title": "兰州市",
        "order": 12
      }, {
        "key": 522,
        "title": "白银市",
        "order": 3
      }, {
        "key": 523,
        "title": "武威市",
        "order": 4
      }, {
        "key": 524,
        "title": "平凉市",
        "order": 5
      }, {
        "key": 526,
        "title": "酒泉市",
        "order": 6
      }, {
        "key": 527,
        "title": "嘉峪关市",
        "order": 7
      }, {
        "key": 528,
        "title": "金昌市",
        "order": 8
      }],
      "key": 10028,
      "title": "甘肃省",
      "order": 28
    }, {
      "children": [{
        "key": 233,
        "title": "西宁市",
        "order": 8
      }, {
        "key": 2359,
        "title": "海东市",
        "order": 1
      }, {
        "key": 2365,
        "title": "海北藏族自治州",
        "order": 2
      }, {
        "key": 2370,
        "title": "黄南藏族自治州",
        "order": 3
      }, {
        "key": 2375,
        "title": "海南藏族自治州",
        "order": 4
      }, {
        "key": 2381,
        "title": "果洛藏族自治州",
        "order": 5
      }, {
        "key": 2388,
        "title": "玉树藏族自治州",
        "order": 6
      }, {
        "key": 2395,
        "title": "海西蒙古族藏族自治州",
        "order": 7
      }],
      "key": 10029,
      "title": "青海省",
      "order": 29
    }, {
      "children": [{
        "key": 1144,
        "title": "中卫市",
        "order": 5
      }, {
        "key": 194,
        "title": "银川市",
        "order": 4
      }, {
        "key": 2410,
        "title": "固原市",
        "order": 1
      }, {
        "key": 396,
        "title": "吴忠市",
        "order": 2
      }, {
        "key": 921,
        "title": "石嘴山市",
        "order": 3
      }],
      "key": 10030,
      "title": "宁夏回族自治区",
      "order": 30
    }, {
      "children": [{
        "key": 1053,
        "title": "克拉玛依市",
        "order": 7
      }, {
        "key": 236,
        "title": "乌鲁木齐市",
        "order": 24
      }, {
        "key": 2418,
        "title": "吐鲁番市",
        "order": 9
      }, {
        "key": 2422,
        "title": "哈密市",
        "order": 10
      }, {
        "key": 2425,
        "title": "昌吉回族自治州",
        "order": 11
      }, {
        "key": 2432,
        "title": "博尔塔拉蒙古自治州",
        "order": 12
      }, {
        "key": 2437,
        "title": "巴音郭楞蒙古自治州",
        "order": 13
      }, {
        "key": 2446,
        "title": "阿克苏地区",
        "order": 14
      }, {
        "key": 2454,
        "title": "克孜勒苏柯尔克孜自治州",
        "order": 15
      }, {
        "key": 2459,
        "title": "喀什地区",
        "order": 16
      }, {
        "key": 2471,
        "title": "和田地区",
        "order": 17
      }, {
        "key": 2480,
        "title": "伊犁哈萨克自治州",
        "order": 18
      }, {
        "key": 2490,
        "title": "塔城地区",
        "order": 19
      }, {
        "key": 2498,
        "title": "阿勒泰地区",
        "order": 20
      }, {
        "key": 2506,
        "title": "自治区直辖县级行政区划",
        "order": 21
      }, {
        "key": 2507,
        "title": "图木舒克市",
        "order": 22
      }, {
        "key": 2508,
        "title": "五家渠市",
        "order": 23
      }, {
        "key": 258,
        "title": "石河子市",
        "order": 1
      }, {
        "key": 327,
        "title": "阿拉尔市",
        "order": 8
      }, {
        "key": 5894,
        "title": "北屯市",
        "order": 2
      }, {
        "key": 5895,
        "title": "双河市",
        "order": 3
      }, {
        "key": 5896,
        "title": "可克达拉市",
        "order": 4
      }, {
        "key": 5897,
        "title": "昆玉市",
        "order": 5
      }, {
        "key": 5898,
        "title": "铁门关市",
        "order": 6
      }],
      "key": 10031,
      "title": "新疆维吾尔自治区",
      "order": 31
    }, {
      "children": [{
        "key": 215,
        "title": "香港特别行政区",
        "order": 1
      }],
      "key": 10032,
      "title": "香港特别行政区",
      "order": 32
    }, {
      "children": [{
        "key": 216,
        "title": "澳门特别行政区",
        "order": 1
      }],
      "key": 10033,
      "title": "澳门特别行政区",
      "order": 33
    }],
    liveChinaMap:  [{
      "children": [{
        "key": 3,
        "title": "北京市",
        "order": 1
      }],
      "key": 10001,
      "title": "北京市",
      "order": 1
    }, {
      "children": [{
        "key": 5,
        "title": "天津市",
        "order": 1
      }],
      "key": 10002,
      "title": "天津市",
      "order": 2
    }, {
      "children": [{
        "key": 154,
        "title": "张家口市",
        "order": 7
      }, {
        "key": 170,
        "title": "邯郸市",
        "order": 8
      }, {
        "key": 172,
        "title": "承德市",
        "order": 9
      }, {
        "key": 175,
        "title": "沧州市",
        "order": 10
      }, {
        "key": 179,
        "title": "邢台市",
        "order": 11
      }, {
        "key": 266,
        "title": "衡水市",
        "order": 2
      }, {
        "key": 31,
        "title": "石家庄市",
        "order": 1
      }, {
        "key": 40,
        "title": "廊坊市",
        "order": 3
      }, {
        "key": 63,
        "title": "秦皇岛市",
        "order": 4
      }, {
        "key": 87,
        "title": "唐山市",
        "order": 5
      }, {
        "key": 95,
        "title": "保定市",
        "order": 6
      }],
      "key": 10003,
      "title": "河北省",
      "order": 4
    }, {
      "children": [{
        "key": 153,
        "title": "晋中市",
        "order": 5
      }, {
        "key": 166,
        "title": "运城市",
        "order": 6
      }, {
        "key": 168,
        "title": "临汾市",
        "order": 7
      }, {
        "key": 169,
        "title": "大同市",
        "order": 8
      }, {
        "key": 237,
        "title": "长治市",
        "order": 11
      }, {
        "key": 263,
        "title": "忻州市",
        "order": 1
      }, {
        "key": 281,
        "title": "吕梁市",
        "order": 2
      }, {
        "key": 392,
        "title": "朔州市",
        "order": 4
      }, {
        "key": 43,
        "title": "太原市",
        "order": 3
      }, {
        "key": 437,
        "title": "晋城市",
        "order": 9
      }, {
        "key": 995,
        "title": "阳泉市",
        "order": 10
      }],
      "key": 10004,
      "title": "山西省",
      "order": 5
    }, {
      "children": [{
        "key": 1397,
        "title": "兴安盟",
        "order": 5
      }, {
        "key": 1403,
        "title": "锡林郭勒盟",
        "order": 6
      }, {
        "key": 1415,
        "title": "阿拉善盟",
        "order": 7
      }, {
        "key": 268,
        "title": "通辽市",
        "order": 2
      }, {
        "key": 337,
        "title": "赤峰市",
        "order": 1
      }, {
        "key": 393,
        "title": "乌海市",
        "order": 8
      }, {
        "key": 394,
        "title": "乌兰察布市",
        "order": 9
      }, {
        "key": 395,
        "title": "鄂尔多斯市",
        "order": 10
      }, {
        "key": 398,
        "title": "呼伦贝尔市",
        "order": 11
      }, {
        "key": 68,
        "title": "包头市",
        "order": 3
      }, {
        "key": 914,
        "title": "巴彦淖尔市",
        "order": 12
      }, {
        "key": 92,
        "title": "呼和浩特市",
        "order": 4
      }],
      "key": 10005,
      "title": "内蒙古",
      "order": 6
    }, {
      "children": [{
        "key": 108,
        "title": "抚顺市",
        "order": 8
      }, {
        "key": 110,
        "title": "锦州市",
        "order": 9
      }, {
        "key": 118,
        "title": "阜新市",
        "order": 10
      }, {
        "key": 145,
        "title": "丹东市",
        "order": 12
      }, {
        "key": 16,
        "title": "沈阳市",
        "order": 4
      }, {
        "key": 20,
        "title": "大连市",
        "order": 5
      }, {
        "key": 218,
        "title": "葫芦岛市",
        "order": 13
      }, {
        "key": 222,
        "title": "辽阳市",
        "order": 14
      }, {
        "key": 336,
        "title": "本溪市",
        "order": 6
      }, {
        "key": 339,
        "title": "营口市",
        "order": 1
      }, {
        "key": 340,
        "title": "盘锦市",
        "order": 2
      }, {
        "key": 360,
        "title": "朝阳市",
        "order": 3
      }, {
        "key": 382,
        "title": "铁岭市",
        "order": 11
      }, {
        "key": 96,
        "title": "鞍山市",
        "order": 7
      }],
      "key": 10006,
      "title": "辽宁省",
      "order": 7
    }, {
      "children": [{
        "key": 101,
        "title": "吉林市",
        "order": 2
      }, {
        "key": 12,
        "title": "长春市",
        "order": 1
      }, {
        "key": 167,
        "title": "延边朝鲜族自治州",
        "order": 4
      }, {
        "key": 171,
        "title": "四平市",
        "order": 5
      }, {
        "key": 223,
        "title": "白城市",
        "order": 6
      }, {
        "key": 227,
        "title": "辽源市",
        "order": 7
      }, {
        "key": 239,
        "title": "通化市",
        "order": 8
      }, {
        "key": 361,
        "title": "松原市",
        "order": 9
      }, {
        "key": 397,
        "title": "白山市",
        "order": 3
      }],
      "key": 10007,
      "title": "吉林省",
      "order": 8
    }, {
      "children": [{
        "key": 10,
        "title": "哈尔滨市",
        "order": 1
      }, {
        "key": 1149,
        "title": "黑河市",
        "order": 13
      }, {
        "key": 138,
        "title": "牡丹江市",
        "order": 5
      }, {
        "key": 1511,
        "title": "大兴安岭地区",
        "order": 12
      }, {
        "key": 221,
        "title": "鸡西市",
        "order": 10
      }, {
        "key": 228,
        "title": "绥化市",
        "order": 11
      }, {
        "key": 696,
        "title": "伊春市",
        "order": 6
      }, {
        "key": 697,
        "title": "鹤岗市",
        "order": 7
      }, {
        "key": 698,
        "title": "七台河市",
        "order": 8
      }, {
        "key": 699,
        "title": "双鸭山市",
        "order": 9
      }, {
        "key": 86,
        "title": "大庆市",
        "order": 2
      }, {
        "key": 88,
        "title": "佳木斯市",
        "order": 3
      }, {
        "key": 91,
        "title": "齐齐哈尔市",
        "order": 4
      }],
      "key": 10008,
      "title": "黑龙江省",
      "order": 9
    }, {
      "children": [{
        "key": 1,
        "title": "上海市（万之象）",
        "order": 1
      },{
        "key": 99999,
        "title": "上海市（蜂鸟）",
        "order": 1
      }],
      "key": 10009,
      "title": "上海市",
      "order": 3
    }, {
      "children": [{
        "key": 114,
        "title": "淮安市",
        "order": 12
      }, {
        "key": 206,
        "title": "宿迁市",
        "order": 13
      }, {
        "key": 22,
        "title": "扬州市",
        "order": 3
      }, {
        "key": 27,
        "title": "镇江市",
        "order": 4
      }, {
        "key": 29,
        "title": "徐州市",
        "order": 5
      }, {
        "key": 35,
        "title": "无锡市",
        "order": 6
      }, {
        "key": 39,
        "title": "南通市",
        "order": 7
      }, {
        "key": 59,
        "title": "常州市",
        "order": 8
      }, {
        "key": 6,
        "title": "南京市",
        "order": 1
      }, {
        "key": 71,
        "title": "盐城市",
        "order": 9
      }, {
        "key": 8,
        "title": "苏州市",
        "order": 2
      }, {
        "key": 89,
        "title": "连云港市",
        "order": 10
      }, {
        "key": 93,
        "title": "泰州市",
        "order": 11
      }],
      "key": 10010,
      "title": "江苏省",
      "order": 10
    }, {
      "children": [{
        "key": 18,
        "title": "宁波市",
        "order": 2
      }, {
        "key": 182,
        "title": "衢州市",
        "order": 11
      }, {
        "key": 2,
        "title": "杭州市",
        "order": 1
      }, {
        "key": 299,
        "title": "舟山市",
        "order": 4
      }, {
        "key": 30,
        "title": "温州市",
        "order": 3
      }, {
        "key": 52,
        "title": "台州市",
        "order": 5
      }, {
        "key": 55,
        "title": "嘉兴市",
        "order": 6
      }, {
        "key": 61,
        "title": "湖州市",
        "order": 7
      }, {
        "key": 67,
        "title": "金华市",
        "order": 8
      }, {
        "key": 69,
        "title": "绍兴市",
        "order": 9
      }, {
        "key": 73,
        "title": "丽水市",
        "order": 10
      }],
      "key": 10011,
      "title": "浙江省",
      "order": 11
    }, {
      "children": [{
        "key": 135,
        "title": "宿州市",
        "order": 12
      }, {
        "key": 17,
        "title": "合肥市",
        "order": 1
      }, {
        "key": 195,
        "title": "阜阳市",
        "order": 13
      }, {
        "key": 199,
        "title": "黄山市",
        "order": 14
      }, {
        "key": 214,
        "title": "淮北市",
        "order": 15
      }, {
        "key": 246,
        "title": "池州市",
        "order": 16
      }, {
        "key": 313,
        "title": "亳州市",
        "order": 4
      }, {
        "key": 314,
        "title": "宣城市",
        "order": 5
      }, {
        "key": 38,
        "title": "芜湖市",
        "order": 2
      }, {
        "key": 53,
        "title": "淮南市",
        "order": 3
      }, {
        "key": 65,
        "title": "铜陵市",
        "order": 6
      }, {
        "key": 70,
        "title": "安庆市",
        "order": 7
      }, {
        "key": 75,
        "title": "蚌埠市",
        "order": 8
      }, {
        "key": 83,
        "title": "滁州市",
        "order": 9
      }, {
        "key": 85,
        "title": "六安市",
        "order": 10
      }, {
        "key": 90,
        "title": "马鞍山市",
        "order": 11
      }],
      "key": 10012,
      "title": "安徽省",
      "order": 12
    }, {
      "children": [{
        "key": 102,
        "title": "泉州市",
        "order": 5
      }, {
        "key": 111,
        "title": "漳州市",
        "order": 6
      }, {
        "key": 117,
        "title": "南平市",
        "order": 7
      }, {
        "key": 13,
        "title": "厦门市",
        "order": 1
      }, {
        "key": 180,
        "title": "莆田市",
        "order": 8
      }, {
        "key": 181,
        "title": "龙岩市",
        "order": 9
      }, {
        "key": 270,
        "title": "三明市",
        "order": 3
      }, {
        "key": 271,
        "title": "宁德市",
        "order": 4
      }, {
        "key": 9,
        "title": "福州市",
        "order": 2
      }],
      "key": 10013,
      "title": "福建省",
      "order": 13
    }, {
      "children": [{
        "key": 127,
        "title": "新余市",
        "order": 5
      }, {
        "key": 128,
        "title": "宜春市",
        "order": 6
      }, {
        "key": 129,
        "title": "赣州市",
        "order": 7
      }, {
        "key": 150,
        "title": "萍乡市",
        "order": 8
      }, {
        "key": 219,
        "title": "抚州市",
        "order": 9
      }, {
        "key": 220,
        "title": "吉安市",
        "order": 10
      }, {
        "key": 25,
        "title": "南昌市",
        "order": 1
      }, {
        "key": 384,
        "title": "鹰潭市",
        "order": 11
      }, {
        "key": 49,
        "title": "景德镇市",
        "order": 2
      }, {
        "key": 66,
        "title": "九江市",
        "order": 3
      }, {
        "key": 97,
        "title": "上饶市",
        "order": 4
      }],
      "key": 10014,
      "title": "江西省",
      "order": 14
    }, {
      "children": [{
        "key": 132,
        "title": "东营市",
        "order": 13
      }, {
        "key": 134,
        "title": "滨州市",
        "order": 14
      }, {
        "key": 177,
        "title": "菏泽市",
        "order": 16
      }, {
        "key": 19,
        "title": "济南市",
        "order": 2
      }, {
        "key": 224,
        "title": "德州市",
        "order": 17
      }, {
        "key": 28,
        "title": "青岛市",
        "order": 1
      }, {
        "key": 34,
        "title": "威海市",
        "order": 3
      }, {
        "key": 391,
        "title": "莱芜市",
        "order": 15
      }, {
        "key": 47,
        "title": "济宁市",
        "order": 4
      }, {
        "key": 48,
        "title": "潍坊市",
        "order": 5
      }, {
        "key": 50,
        "title": "淄博市",
        "order": 6
      }, {
        "key": 54,
        "title": "枣庄市",
        "order": 7
      }, {
        "key": 58,
        "title": "日照市",
        "order": 8
      }, {
        "key": 60,
        "title": "临沂市",
        "order": 9
      }, {
        "key": 74,
        "title": "泰安市",
        "order": 10
      }, {
        "key": 77,
        "title": "烟台市",
        "order": 11
      }, {
        "key": 84,
        "title": "聊城市",
        "order": 12
      }],
      "key": 10015,
      "title": "山东省",
      "order": 15
    }, {
      "children": [{
        "key": 144,
        "title": "焦作市",
        "order": 12
      }, {
        "key": 183,
        "title": "开封市",
        "order": 13
      }, {
        "key": 198,
        "title": "许昌市",
        "order": 14
      }, {
        "key": 204,
        "title": "周口市",
        "order": 15
      }, {
        "key": 234,
        "title": "信阳市",
        "order": 16
      }, {
        "key": 253,
        "title": "商丘市",
        "order": 17
      }, {
        "key": 260,
        "title": "南阳市",
        "order": 6
      }, {
        "key": 261,
        "title": "安阳市",
        "order": 7
      }, {
        "key": 269,
        "title": "平顶山市",
        "order": 8
      }, {
        "key": 32,
        "title": "郑州市",
        "order": 9
      }, {
        "key": 374,
        "title": "濮阳市",
        "order": 18
      }, {
        "key": 399,
        "title": "驻马店市",
        "order": 3
      }, {
        "key": 400,
        "title": "漯河市",
        "order": 2
      }, {
        "key": 401,
        "title": "三门峡市",
        "order": 4
      }, {
        "key": 406,
        "title": "鹤壁市",
        "order": 5
      }, {
        "key": 415,
        "title": "济源市",
        "order": 1
      }, {
        "key": 76,
        "title": "新乡市",
        "order": 10
      }, {
        "key": 94,
        "title": "洛阳市",
        "order": 11
      }],
      "key": 10016,
      "title": "河南省",
      "order": 16
    }, {
      "children": [{
        "key": 100,
        "title": "荆州市",
        "order": 3
      }, {
        "key": 151,
        "title": "荆门市",
        "order": 4
      }, {
        "key": 152,
        "title": "黄石市",
        "order": 5
      }, {
        "key": 1691,
        "title": "恩施土家族苗族自治州",
        "order": 6
      }, {
        "key": 184,
        "title": "鄂州市",
        "order": 7
      }, {
        "key": 196,
        "title": "十堰市",
        "order": 9
      }, {
        "key": 205,
        "title": "孝感市",
        "order": 11
      }, {
        "key": 217,
        "title": "黄冈市",
        "order": 13
      }, {
        "key": 230,
        "title": "咸宁市",
        "order": 14
      }, {
        "key": 241,
        "title": "襄阳市",
        "order": 15
      }, {
        "key": 350,
        "title": "仙桃市",
        "order": 16
      }, {
        "key": 383,
        "title": "随州市",
        "order": 17
      }, {
        "key": 445,
        "title": "潜江市",
        "order": 8
      }, {
        "key": 5584,
        "title": "神农架林区",
        "order": 12
      }, {
        "key": 7,
        "title": "武汉市",
        "order": 1
      }, {
        "key": 710,
        "title": "天门市",
        "order": 10
      }, {
        "key": 80,
        "title": "宜昌市",
        "order": 2
      }],
      "key": 10017,
      "title": "湖北省",
      "order": 17
    }, {
      "children": [{
        "key": 116,
        "title": "衡阳市",
        "order": 4
      }, {
        "key": 119,
        "title": "益阳市",
        "order": 5
      }, {
        "key": 120,
        "title": "常德市",
        "order": 6
      }, {
        "key": 124,
        "title": "岳阳市",
        "order": 7
      }, {
        "key": 126,
        "title": "株洲市",
        "order": 8
      }, {
        "key": 133,
        "title": "怀化市",
        "order": 9
      }, {
        "key": 143,
        "title": "娄底市",
        "order": 10
      }, {
        "key": 1728,
        "title": "湘西土家族苗族自治州",
        "order": 14
      }, {
        "key": 173,
        "title": "邵阳市",
        "order": 11
      }, {
        "key": 176,
        "title": "永州市",
        "order": 12
      }, {
        "key": 178,
        "title": "郴州市",
        "order": 13
      }, {
        "key": 21,
        "title": "长沙市",
        "order": 2
      }, {
        "key": 280,
        "title": "张家界市",
        "order": 3
      }, {
        "key": 42,
        "title": "湘潭市",
        "order": 1
      }],
      "key": 10018,
      "title": "湖南省",
      "order": 18
    }, {
      "children": [{
        "key": 11,
        "title": "深圳市",
        "order": 4
      }, {
        "key": 123,
        "title": "茂名市",
        "order": 13
      }, {
        "key": 130,
        "title": "江门市",
        "order": 14
      }, {
        "key": 200,
        "title": "汕头市",
        "order": 15
      }, {
        "key": 202,
        "title": "阳江市",
        "order": 16
      }, {
        "key": 203,
        "title": "中山市",
        "order": 17
      }, {
        "key": 231,
        "title": "揭阳市",
        "order": 18
      }, {
        "key": 235,
        "title": "潮州市",
        "order": 19
      }, {
        "key": 24,
        "title": "佛山市",
        "order": 2
      }, {
        "key": 26,
        "title": "珠海市",
        "order": 5
      }, {
        "key": 365,
        "title": "河源市",
        "order": 20
      }, {
        "key": 371,
        "title": "清远市",
        "order": 21
      }, {
        "key": 4,
        "title": "广州市",
        "order": 1
      }, {
        "key": 421,
        "title": "云浮市",
        "order": 3
      }, {
        "key": 51,
        "title": "东莞市",
        "order": 7
      }, {
        "key": 544,
        "title": "汕尾市",
        "order": 6
      }, {
        "key": 56,
        "title": "梅州市",
        "order": 8
      }, {
        "key": 57,
        "title": "惠州市",
        "order": 9
      }, {
        "key": 64,
        "title": "肇庆市",
        "order": 10
      }, {
        "key": 72,
        "title": "韶关市",
        "order": 11
      }, {
        "key": 79,
        "title": "湛江市",
        "order": 12
      }],
      "key": 10019,
      "title": "广东省",
      "order": 19
    }, {
      "children": [{
        "key": 1167,
        "title": "河池市",
        "order": 8
      }, {
        "key": 156,
        "title": "百色市",
        "order": 9
      }, {
        "key": 157,
        "title": "钦州市",
        "order": 10
      }, {
        "key": 1794,
        "title": "防城港市",
        "order": 1
      }, {
        "key": 1827,
        "title": "来宾市",
        "order": 4
      }, {
        "key": 243,
        "title": "梧州市",
        "order": 12
      }, {
        "key": 252,
        "title": "贺州市",
        "order": 13
      }, {
        "key": 254,
        "title": "玉林市",
        "order": 14
      }, {
        "key": 289,
        "title": "崇左市",
        "order": 2
      }, {
        "key": 290,
        "title": "北海市",
        "order": 3
      }, {
        "key": 431,
        "title": "贵港市",
        "order": 11
      }, {
        "key": 45,
        "title": "南宁市",
        "order": 5
      }, {
        "key": 81,
        "title": "柳州市",
        "order": 6
      }, {
        "key": 98,
        "title": "桂林市",
        "order": 7
      }],
      "key": 10020,
      "title": "广西壮族自治区",
      "order": 20
    }, {
      "children": [{
        "key": 1146,
        "title": "陵水黎族自治县",
        "order": 19
      }, {
        "key": 165,
        "title": "三亚市",
        "order": 18
      }, {
        "key": 1839,
        "title": "三沙市",
        "order": 4
      }, {
        "key": 1844,
        "title": "五指山市",
        "order": 5
      }, {
        "key": 1845,
        "title": "白沙黎族自治县",
        "order": 6
      }, {
        "key": 1846,
        "title": "昌江黎族自治县",
        "order": 7
      }, {
        "key": 1847,
        "title": "乐东黎族自治县",
        "order": 8
      }, {
        "key": 1848,
        "title": "保亭黎族苗族自治县",
        "order": 9
      }, {
        "key": 1849,
        "title": "琼中黎族苗族自治县",
        "order": 10
      }, {
        "key": 278,
        "title": "琼海市",
        "order": 1
      }, {
        "key": 282,
        "title": "文昌市",
        "order": 2
      }, {
        "key": 286,
        "title": "儋州市",
        "order": 3
      }, {
        "key": 581,
        "title": "万宁市",
        "order": 12
      }, {
        "key": 582,
        "title": "澄迈县",
        "order": 13
      }, {
        "key": 583,
        "title": "东方市",
        "order": 14
      }, {
        "key": 584,
        "title": "临高县",
        "order": 15
      }, {
        "key": 585,
        "title": "屯昌县",
        "order": 16
      }, {
        "key": 586,
        "title": "定安县",
        "order": 17
      }, {
        "key": 62,
        "title": "海口市",
        "order": 11
      }],
      "key": 10021,
      "title": "海南省",
      "order": 21
    }, {
      "children": [{
        "key": 44,
        "title": "重庆市",
        "order": 1
      }],
      "key": 10022,
      "title": "重庆市",
      "order": 22
    }, {
      "children": [{
        "key": 103,
        "title": "宜宾市",
        "order": 11
      }, {
        "key": 104,
        "title": "内江市",
        "order": 12
      }, {
        "key": 105,
        "title": "自贡市",
        "order": 13
      }, {
        "key": 106,
        "title": "泸州市",
        "order": 14
      }, {
        "key": 131,
        "title": "德阳市",
        "order": 17
      }, {
        "key": 139,
        "title": "乐山市",
        "order": 18
      }, {
        "key": 14,
        "title": "成都市",
        "order": 1
      }, {
        "key": 1905,
        "title": "阿坝藏族羌族自治州",
        "order": 15
      }, {
        "key": 1919,
        "title": "甘孜藏族自治州",
        "order": 16
      }, {
        "key": 1938,
        "title": "凉山彝族自治州",
        "order": 19
      }, {
        "key": 240,
        "title": "雅安市",
        "order": 20
      }, {
        "key": 247,
        "title": "达州市",
        "order": 21
      }, {
        "key": 262,
        "title": "广元市",
        "order": 2
      }, {
        "key": 291,
        "title": "遂宁市",
        "order": 3
      }, {
        "key": 312,
        "title": "眉山市",
        "order": 4
      }, {
        "key": 315,
        "title": "攀枝花市",
        "order": 5
      }, {
        "key": 316,
        "title": "广安市",
        "order": 6
      }, {
        "key": 317,
        "title": "巴中市",
        "order": 7
      }, {
        "key": 323,
        "title": "资阳市",
        "order": 8
      }, {
        "key": 82,
        "title": "绵阳市",
        "order": 9
      }, {
        "key": 99,
        "title": "南充市",
        "order": 10
      }],
      "key": 10023,
      "title": "四川省",
      "order": 23
    }, {
      "children": [{
        "key": 1175,
        "title": "黔南布依族苗族自治州",
        "order": 4
      }, {
        "key": 1176,
        "title": "黔东南苗族侗族自治州",
        "order": 5
      }, {
        "key": 1177,
        "title": "黔西南布依族苗族自治州",
        "order": 6
      }, {
        "key": 136,
        "title": "毕节市",
        "order": 2
      }, {
        "key": 137,
        "title": "贵阳市",
        "order": 3
      }, {
        "key": 226,
        "title": "铜仁市",
        "order": 8
      }, {
        "key": 273,
        "title": "安顺市",
        "order": 1
      }, {
        "key": 379,
        "title": "遵义市",
        "order": 9
      }, {
        "key": 441,
        "title": "六盘水市",
        "order": 7
      }],
      "key": 10024,
      "title": "贵州省",
      "order": 24
    }, {
      "children": [{
        "key": 155,
        "title": "丽江市",
        "order": 16
      }, {
        "key": 2063,
        "title": "临沧市",
        "order": 3
      }, {
        "key": 2071,
        "title": "楚雄彝族自治州",
        "order": 5
      }, {
        "key": 2081,
        "title": "红河哈尼族彝族自治州",
        "order": 6
      }, {
        "key": 2089,
        "title": "文山壮族苗族自治州",
        "order": 10
      }, {
        "key": 2097,
        "title": "西双版纳傣族自治州",
        "order": 11
      }, {
        "key": 2100,
        "title": "大理白族自治州",
        "order": 12
      }, {
        "key": 2111,
        "title": "德宏傣族景颇族自治州",
        "order": 13
      }, {
        "key": 2117,
        "title": "怒江傈僳族自治州",
        "order": 14
      }, {
        "key": 2122,
        "title": "迪庆藏族自治州",
        "order": 15
      }, {
        "key": 23,
        "title": "昆明市",
        "order": 4
      }, {
        "key": 264,
        "title": "保山市",
        "order": 1
      }, {
        "key": 265,
        "title": "曲靖市",
        "order": 2
      }, {
        "key": 292,
        "title": "玉溪市",
        "order": 7
      }, {
        "key": 294,
        "title": "普洱市",
        "order": 8
      }, {
        "key": 295,
        "title": "昭通市",
        "order": 9
      }],
      "key": 10025,
      "title": "云南省",
      "order": 25
    }, {
      "children": [{
        "key": 2133,
        "title": "日喀则市",
        "order": 1
      }, {
        "key": 2151,
        "title": "昌都市",
        "order": 2
      }, {
        "key": 2163,
        "title": "山南市",
        "order": 3
      }, {
        "key": 2176,
        "title": "那曲地区",
        "order": 4
      }, {
        "key": 2188,
        "title": "阿里地区",
        "order": 5
      }, {
        "key": 2196,
        "title": "林芝市",
        "order": 6
      }, {
        "key": 232,
        "title": "拉萨市",
        "order": 7
      }],
      "key": 10026,
      "title": "西藏自治区",
      "order": 26
    }, {
      "children": [{
        "key": 1008,
        "title": "铜川市",
        "order": 8
      }, {
        "key": 115,
        "title": "咸阳市",
        "order": 6
      }, {
        "key": 15,
        "title": "西安市",
        "order": 1
      }, {
        "key": 2277,
        "title": "商洛市",
        "order": 7
      }, {
        "key": 242,
        "title": "延安市",
        "order": 9
      }, {
        "key": 244,
        "title": "宝鸡市",
        "order": 10
      }, {
        "key": 259,
        "title": "汉中市",
        "order": 2
      }, {
        "key": 272,
        "title": "安康市",
        "order": 3
      }, {
        "key": 306,
        "title": "渭南市",
        "order": 4
      }, {
        "key": 307,
        "title": "榆林市",
        "order": 5
      }],
      "key": 10027,
      "title": "陕西省",
      "order": 27
    }, {
      "children": [{
        "key": 2323,
        "title": "定西市",
        "order": 9
      }, {
        "key": 2330,
        "title": "陇南市",
        "order": 10
      }, {
        "key": 2339,
        "title": "临夏回族自治州",
        "order": 11
      }, {
        "key": 2347,
        "title": "甘南藏族自治州",
        "order": 14
      }, {
        "key": 298,
        "title": "天水市",
        "order": 13
      }, {
        "key": 341,
        "title": "庆阳市",
        "order": 1
      }, {
        "key": 342,
        "title": "张掖市",
        "order": 2
      }, {
        "key": 41,
        "title": "兰州市",
        "order": 12
      }, {
        "key": 522,
        "title": "白银市",
        "order": 3
      }, {
        "key": 523,
        "title": "武威市",
        "order": 4
      }, {
        "key": 524,
        "title": "平凉市",
        "order": 5
      }, {
        "key": 526,
        "title": "酒泉市",
        "order": 6
      }, {
        "key": 527,
        "title": "嘉峪关市",
        "order": 7
      }, {
        "key": 528,
        "title": "金昌市",
        "order": 8
      }],
      "key": 10028,
      "title": "甘肃省",
      "order": 28
    }, {
      "children": [{
        "key": 233,
        "title": "西宁市",
        "order": 8
      }, {
        "key": 2359,
        "title": "海东市",
        "order": 1
      }, {
        "key": 2365,
        "title": "海北藏族自治州",
        "order": 2
      }, {
        "key": 2370,
        "title": "黄南藏族自治州",
        "order": 3
      }, {
        "key": 2375,
        "title": "海南藏族自治州",
        "order": 4
      }, {
        "key": 2381,
        "title": "果洛藏族自治州",
        "order": 5
      }, {
        "key": 2388,
        "title": "玉树藏族自治州",
        "order": 6
      }, {
        "key": 2395,
        "title": "海西蒙古族藏族自治州",
        "order": 7
      }],
      "key": 10029,
      "title": "青海省",
      "order": 29
    }, {
      "children": [{
        "key": 1144,
        "title": "中卫市",
        "order": 5
      }, {
        "key": 194,
        "title": "银川市",
        "order": 4
      }, {
        "key": 2410,
        "title": "固原市",
        "order": 1
      }, {
        "key": 396,
        "title": "吴忠市",
        "order": 2
      }, {
        "key": 921,
        "title": "石嘴山市",
        "order": 3
      }],
      "key": 10030,
      "title": "宁夏回族自治区",
      "order": 30
    }, {
      "children": [{
        "key": 1053,
        "title": "克拉玛依市",
        "order": 7
      }, {
        "key": 236,
        "title": "乌鲁木齐市",
        "order": 24
      }, {
        "key": 2418,
        "title": "吐鲁番市",
        "order": 9
      }, {
        "key": 2422,
        "title": "哈密市",
        "order": 10
      }, {
        "key": 2425,
        "title": "昌吉回族自治州",
        "order": 11
      }, {
        "key": 2432,
        "title": "博尔塔拉蒙古自治州",
        "order": 12
      }, {
        "key": 2437,
        "title": "巴音郭楞蒙古自治州",
        "order": 13
      }, {
        "key": 2446,
        "title": "阿克苏地区",
        "order": 14
      }, {
        "key": 2454,
        "title": "克孜勒苏柯尔克孜自治州",
        "order": 15
      }, {
        "key": 2459,
        "title": "喀什地区",
        "order": 16
      }, {
        "key": 2471,
        "title": "和田地区",
        "order": 17
      }, {
        "key": 2480,
        "title": "伊犁哈萨克自治州",
        "order": 18
      }, {
        "key": 2490,
        "title": "塔城地区",
        "order": 19
      }, {
        "key": 2498,
        "title": "阿勒泰地区",
        "order": 20
      }, {
        "key": 2506,
        "title": "自治区直辖县级行政区划",
        "order": 21
      }, {
        "key": 2507,
        "title": "图木舒克市",
        "order": 22
      }, {
        "key": 2508,
        "title": "五家渠市",
        "order": 23
      }, {
        "key": 258,
        "title": "石河子市",
        "order": 1
      }, {
        "key": 327,
        "title": "阿拉尔市",
        "order": 8
      }, {
        "key": 5894,
        "title": "北屯市",
        "order": 2
      }, {
        "key": 5895,
        "title": "双河市",
        "order": 3
      }, {
        "key": 5896,
        "title": "可克达拉市",
        "order": 4
      }, {
        "key": 5897,
        "title": "昆玉市",
        "order": 5
      }, {
        "key": 5898,
        "title": "铁门关市",
        "order": 6
      }],
      "key": 10031,
      "title": "新疆维吾尔自治区",
      "order": 31
    }, {
      "children": [{
        "key": 215,
        "title": "香港特别行政区",
        "order": 1
      }],
      "key": 10032,
      "title": "香港特别行政区",
      "order": 32
    }, {
      "children": [{
        "key": 216,
        "title": "澳门特别行政区",
        "order": 1
      }],
      "key": 10033,
      "title": "澳门特别行政区",
      "order": 33
    }]
      //排序方法
      // arrSort:function(){
      //     //定义一个比较器
      //     function compare(propertyName) {
      //       return function(object1, object2) {
      //         var value1 = object1[propertyName];
      //         var value2 = object2[propertyName];
      //         if (value2 < value1) {
      //           return 1;
      //         } else if (value2 > value1) {
      //           return -1;
      //         } else {
      //           return 0;
      //         }
      //       }
      //     }
      //     //使用方法
      //     chinaMap.sort(compare("key"));
      // }
     
}
