import React, { useState, useEffect, useImperativeHandle,forwardRef } from 'react';
import {Table,Space,message,Popconfirm,Typography} from 'antd'
import { request } from '../../../../../utils/fetch'
import store from '../../../../../store/index'
const { Link } = Typography

function BaseTable(props,ref) {
    let path = 'firmApply'
    const { refresh } = props
    const [tableData, setTableData] = useState([])
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const [queryData,setQueryData] = useState({})
    let pageSize = 20
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: pageSize,
        total: 0
    })
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        getlist: (val) => {
            console.log(val,'val');
            if(val){
                setQueryData(val)
            }
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
            const pag = {...pagination}
            pag.pageNum = 1
            console.log(pagination,pag);
            getTableLists(pag,val)
        }
    }));
    
    //获取数据
    const getTableLists = ({pageNum, pageSize},query=queryData) => {
        const params = Object.assign({}, query,{pageNum: pageNum, pageSize: pageSize})
        request.requestGet('/platform/firmApply/getList', params).then((res)=>{
            console.log(res);
            if(res.data.success){
                setTableData(res.data.data.list)
                setPagination(prev => {
                    return {
                        ...prev,
                        total: res.data.data.totalCount
                    }
                })
            }
        })
    }
    const columns = [
        { title: '申请时间',dataIndex: 'applyTime',width: 160,align: 'center'},
        { title: '骑手姓名',dataIndex: 'name',width: 120,align: 'center'},
        { title: '身份证号',dataIndex: 'idNumber',width: 160,align: 'center'},
        { title: '政府审核时间',dataIndex: 'auditEndTime',width: 160,align: 'center' },
        { title: '审核状态',dataIndex: 'statusName',width: 100,align: 'center'},
        { title: '失败原因',dataIndex: 'reason',width: 100,align: 'center'},
        { 
            title: '操作',width: 180,align: 'center',fixed: 'right',
            render: (text, record) => {
                return (
                    <Space size='middle'>
                        {
                            permissions.includes(`${path}:repeat`)&&record.status=='2'&&record.repeatStatus===0&&<Popconfirm
                            placement="topRight"
                            className='pop'
                            style={{width:'100px'}}
                                title='开启后个人可在小程序中重新上传审核资料,确定在小程序端开启“个体户复审”入口么？'
                                onConfirm={() => {
                                    submit(record)
                                }}
                                okText='确定'
                                cancelText='取消'
                            >
                                <Link>小程序提交复审</Link>
                            </Popconfirm>
                        }
                    </Space>
                )
            }
        }
    ]
    useEffect(() => {
        getTableLists(pagination)
    }, [])
     //提交复审
    const submit = (record)=>{
        const params = {
            applyNo:record.applyNo
        }
        request.requestPost('/platform/firmApply/repeatApply', params).then((res)=>{
            console.log(res);
            if(res.data.success){
                message.success("提交成功")
                getTableLists(pagination)
            }else{
                message.error(res.data.retMsg)
            }
        })
    }
    //分页改变
    const tablePageChange = (pageNum, pageSize) => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: pageNum
            }
        })
        getTableLists({ pageSize: pageSize, pageNum: pageNum })
    }
    return (
        <Table
            className='selfEmpTab'
            scroll={{ x: '100%', y: 510 }}
            columns={columns}
            rowKey={record => record.applyNo}
            dataSource={tableData}
            pagination={{
                position: ['bottomCenter'],
                total: pagination.total,
                current: pagination.pageNum,
                pageSize: pagination.pageSize,
                showSizeChanger: false,
                showTotal: total => `共 ${total} 条数据`,
                onChange: (pageNum, pageSize) => tablePageChange(pageNum, pageSize)
            }}
        />
    )
}
export default forwardRef(BaseTable)
