// 账单明细
import React, { useState} from 'react'
import { Spin, Table } from 'antd'
import QueryForm from './components/QueryForm'
import moment from 'moment'
import { ddcBillGetList } from '../api'
function BillDetail() {
    const [tableData, setTableData] = useState([])
    const pageSize = 20 //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)

    const refresh = (val,params) => {
        switch (val) {
            case 'query':
                getTableLists(params)
                break;
            default:
        }
    }
    
    const getTableLists = async (record) => {
        let data ={
            orderNo: record.orderNo,
            storesName: record.storesName, 
            phone: record.phone, 
            name: record.name, 
            model: record.model, 
            payModel: record.payModel, 
            status: record.status, 
            beginExpireDays: record.beginExpireDays, 
            endExpireDays: record.endExpireDays, 
            beginPayDate: record.yfrq ? moment(record.yfrq[0]).format('YYYY-MM-DD') : '',
            endPayDate: record.yfrq ? moment(record.yfrq[1]).format('YYYY-MM-DD') : '',
            beginSettlementDate: record.sfrq ? moment(record.sfrq[0]).format('YYYY-MM-DD') : '',
            endSettlementDate: record.sfrq ? moment(record.sfrq[1]).format('YYYY-MM-DD') : '',
            pageSize: pageSize,
            pageNum: pageNum
        }
        ddcBillGetList(data).then((res)=>{
            console.log(res)
            if(res.data.success){
                setTableData(res.data.data.list)
                setTotal(res.data.data.totalCount)
            }
        })
    }
    const getTableData = (num)=>{
        setPageNum(num)
    }
    // 列表字段
    const tableColumns = [
        { title: '序号', dataIndex: 'zcbh', key: 'zcbh', align: 'center', width: 60,render:(text, record, index)=>{
            return pageSize*(pageNum-1)+index+1
        }},
        { title: '订单号', dataIndex: 'orderNo', key: 'orderNo', align: 'center', width: 120 },
        { title: '门店', dataIndex: 'storesName', key: 'storesName', align: 'center', width: 200 },
        { title: '手机号', dataIndex: 'phone', key: 'phone', align: 'center', width: 110 },
        { title: '姓名', dataIndex: 'name', key: 'name', align: 'center', width: 110 },
        { title: '付费模式', dataIndex: 'payModel', key: 'payModel', align: 'center', width: 110 },
        { title: '总租期', dataIndex: 'lesseeDayCount', key: 'lesseeDayCount', align: 'center', width: 140 },
        { title: '期数', dataIndex: 'cycle', key: 'cycle', align: 'center', width: 110 },
        { title: '应付金额', dataIndex: 'payAmount', key: 'payAmount', align: 'center', width: 120 },
        { title: '应付日期', dataIndex: 'payDate', key: 'payDate', align: 'center', width: 120 },
        { title: '预计扣款日', dataIndex: 'prePayDate', key: 'prePayDate', align: 'center', width: 110 },
        { title: '抵扣金额', dataIndex: 'withholdAmount', key: 'withholdAmount', align: 'center', width: 110 },
        { title: '实付金额', dataIndex: 'settlementAmount', key: 'settlementAmount', align: 'center', width: 110 },
        { title: '实付日期', dataIndex: 'settlementDate', key: 'settlementDate', align: 'center', width: 110 },
        { title: '逾期天数', dataIndex: 'expireDays', key: 'expireDays', align: 'center', width: 110 },
        { title: '租期', dataIndex: 'lesseeDay', key: 'lesseeDay', align: 'center', width: 150 },
        { title: '账单状态', dataIndex: 'status', key: 'status', align: 'center', width: 110 },
    ]
    return (
        <Spin spinning={false}>
            <QueryForm refresh={refresh} pageNum={pageNum} setPageNum={setPageNum} />
            <Table 
            columns={tableColumns} 
            dataSource={tableData} 
            scroll={{ x: 'max-content', y: 520 }} 
            rowKey={(record) => record.id}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger: false,
                showTotal: (total) => `共 ${total} 条数据`,
                onChange: (num, pageSize) => getTableData(num),
            }} />
        </Spin>
    )
}
export default BillDetail
