import React, { useState, useEffect, useImperativeHandle,forwardRef } from 'react';
import {Table,Space,message,Tooltip,Typography} from 'antd'
import { request } from '../../../../../utils/fetch'
import store from '../../../../../store/index'
import {trafficArchivesGetList} from '../../api'
const { Link } = Typography

function BaseTable(props,ref) {
    let path = 'trafficEventArchives'
    const { refresh } = props
    const [tableData, setTableData] = useState([])
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const [queryData,setQueryData] = useState({})
    let pageSize = 20
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: pageSize,
        total: 0
    })
    useImperativeHandle(ref, () => ({
        // showModal 就是暴露给父组件的方法
        getlist: (val) => {
            console.log(val,'val');
            if(val){
                setQueryData(val)
            }
            setPagination(prev => {
                return {
                    ...prev,
                    pageNum: 1
                }
            })
            const pag = {...pagination}
            pag.pageNum = 1
            console.log(pagination,pag);
            getTableLists(pag,val)
        }
    }));
    
    //获取数据
    const getTableLists = async ({pageNum, pageSize},query=queryData) => {
        trafficArchivesGetList(Object.assign({}, query,{pageNum: pageNum, pageSize: pageSize})).then((res)=>{
            console.log(res);
            if (res.data.success) {
                setTableData(res.data.data.list)
                setPagination(prev => {
                    return {
                        ...prev,
                        total: res.data.data.totalCount
                    }
                })
            } else {
                message.error(res.data.retMsg)
            }
        })
    }
  
    const columns = [
        { title: '更新时间',dataIndex: 'updateTime',width: 120,align: 'center' ,
        render: (text, record) => {
            return (
                <Space size='middle'>
                    {record.updateTime?record.updateTime:record.createTime}
                </Space>
            )
        }},
        { title: '当前处理进度',dataIndex: 'dealStatus',width: 120,align: 'center',
        render: (text, record) => {
            return (
                <Space size='middle'>
                    {record.dealStatus==1?'报备':record.dealStatus==2?'跟进':'结案'}
                </Space>
            )
        }},
        { title: '事件来源',dataIndex: 'eventSourceName',width: 120,align: 'center'},
        { title: '事件工单号',dataIndex: 'eventOrderNo',width: 150,align: 'center' },
        { title: '大区',dataIndex: 'regionalName',width: 90,align: 'center' },
        { title: '注册城市',dataIndex: 'cityName',width: 90,align: 'center'},
        { title: '报备人',dataIndex: 'reportOriginatorUserName',width: 90,align: 'center' },
        { title: '事件类型',dataIndex: 'eventTypeNames',width: 150,align: 'center' },
        { title: '事件等级',dataIndex: 'eventLevel',width: 90,align: 'center' },
        { title: '人员类型',dataIndex: 'staffTypeName',width: 90,align: 'center' },
        { title: '骑手姓名',dataIndex: 'name',width: 90,align: 'center'},
        { title: '骑手ID',dataIndex: 'eleCourierId',width: 90,align: 'center' },
        { title: '手机号',dataIndex: 'phone',width: 120,align: 'center'},
        { title: '身份证号',dataIndex: 'idNumber',width: 120,align: 'center'},
        { title: '是否报保险',dataIndex: 'insureFlag',width: 80,align: 'center' ,
        render: (value) => {
            return (
                <Space size='middle'>
                    {value==0?'否':'是'}
                </Space>
            )
        }},
        { title: '保险公司',dataIndex: 'insureCompanyName',width: 90,align: 'center' },
        { title: '保单号',dataIndex: 'insureOrderNo',width: 100,align: 'center' },
        { title: '事件发生时间',dataIndex: 'eventTime',width: 120,align: 'center' },
        { title: '接报或上报时间',dataIndex: 'eventReportTime',width: 130,align: 'center'},
        { title: '案件响应时间',dataIndex: 'caseRespondTime',width: 120,align: 'center' },
        { title: '案件响应时效',dataIndex: 'caseRespondInterval',width: 120,align: 'center'},
        { title: '处置回复时间',dataIndex: 'dealWithTime',width: 120,align: 'center'},
        { title: '处置回复时效',dataIndex: 'dealWithInterval',width: 120,align: 'center' },
        { title: '事发城市',dataIndex: 'eventCityName',width: 90,align: 'center' },
        { title: '事件发生地址',dataIndex: 'eventPlace',width: 130,align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (value) => (
                <Tooltip placement="topLeft" title={value}>
                    {value}
                </Tooltip>
            )
        },
        { title: '事件经过描述',dataIndex: 'eventDescribe',key:'eventDescribe',width: 130,align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (value) => (
                <Tooltip placement="topLeft" title={value}>
                    {value}
                </Tooltip>
            )
        },
        { title: '跑单时段',dataIndex: 'workFlag',width: 90,align: 'center',
            render: (text, record) => {
                return (
                    <Space size='middle'>
                        {record.workFlag==0?'否':'是'}
                    </Space>
                )
            }
        },
        { title: '报警部门',dataIndex: 'policeDepartmentName',width: 150,align: 'center' },
        { title: '驾驶车辆',dataIndex: 'carTypeName',width: 100,align: 'center'},
        { title: '涉及敏感人群',dataIndex: 'sensitiveName',width: 100,align: 'center' },
        { title: '骑手状态',dataIndex: 'riderStatusName',width: 100,align: 'center'},
        { title: '骑手受伤情况说明',dataIndex: 'riderDescribe',width: 150,align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (value) => (
                <Tooltip placement="topLeft" title={value}>
                    {value}
                </Tooltip>
            ) 
        },
        { title: '骑手是否清醒',dataIndex: 'riderWakeFlag',width: 80,align: 'center',
        render: (text, record) => {
            return (
                <Space size='middle'>
                    {record.riderWakeFlag==0?'否':'是'}
                </Space>
            )
        }},
        { title: '骑手是否需要手术',dataIndex: 'riderSurgicalFlag',width: 110,align: 'center',
        render: (text, record) => {
            return (
                <Space size='middle'>
                    {record.riderSurgicalFlag==0?'否':'是'}
                </Space>
            )
        }},
        { title: '骑手家属是否到场',dataIndex: 'riderFamilyFlag',width: 110,align: 'center' ,
        render: (text, record) => {
            return (
                <Space size='middle'>
                    {record.riderFamilyFlag==0?'否':'是'}
                </Space>
            )
        }},
        { title: '三者状态',dataIndex: 'threePartyStatusName',width: 90,align: 'center' },
        { title: '三者是否需要手术',dataIndex: 'threePartySurgicalFlag',width: 110,align: 'center' ,
        render: (text, record) => {
            return (
                <Space size='middle'>
                    {record.threePartySurgicalFlag==0?'否':'是'}
                </Space>
            )
        }},
        { title: '三者财产损失金额',dataIndex: 'threePartyDamageAmount',width: 100,align: 'center'},
        { title: '三者受伤情况及物损说明',dataIndex: 'threePartyDescribe',width: 130,align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (value) => (
                <Tooltip placement="topLeft" title={value}>
                    {value}
                </Tooltip>
            )  
        },
        { title: '事故定责',dataIndex: 'responsiblePartyName',width: 90,align: 'center' },
        { title: '违规项目',dataIndex: 'violationTypeName',width: 90,align: 'center'},
        { title: '费用支出说明',dataIndex: 'costDescribe',width: 130,align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (value) => (
                <Tooltip placement="topLeft" title={value}>
                    {value}
                </Tooltip>
            )  
        },
        { title: '是否存在潜在风险',dataIndex: 'riskFlag',width: 100,align: 'center',
        render: (text, record) => {
            return (
                <Space size='middle'>
                    {record.riskFlag==0?'否':'是'}
                </Space>
            )
        }},
        { title: '风险描述',dataIndex: 'riskDescribe',width: 90,align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (value) => (
                <Tooltip placement="topLeft" title={value}>
                    {value}
                </Tooltip>
            )
        },
        { title: '甲方要求',dataIndex: 'requireDescribe',width: 130,align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (value) => (
                <Tooltip placement="topLeft" title={value}>
                    {value}
                </Tooltip>
            )  
        },
        { title: '预计垫付金额',dataIndex: 'advanceAmount',width: 90,align: 'center' },
        { title: '垫付原因',dataIndex: 'advanceReason',width: 120,align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (value) => (
                <Tooltip placement="topLeft" title={value}>
                    {value}
                </Tooltip>
            )  
        },
        { title: '借款金额',dataIndex: 'borrowAmount',width: 90,align: 'center' },
        { title: '实际垫付金额',dataIndex: 'realityAdvanceAmount',width: 90,align: 'center'},
        { title: '保险回款金额',dataIndex: 'insureCollectionAmount',width: 90,align: 'center'},
        { title: '垫付是否冲销',dataIndex: 'chargeAgainstFlag',width: 90,align: 'center',
        render: (text, record) => {
            return (
                <Space size='middle'>
                    {record.chargeAgainstFlag==0?'否':'是'}
                </Space>
            )
        }},
        { title: '未冲销原因',dataIndex: 'chargeAgainstReason',width: 130,align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (value) => (
                <Tooltip placement="topLeft" title={value}>
                    {value}
                </Tooltip>
            )  
        },
        { title: '垫付损失金额',dataIndex: 'advanceLossAmount',width: 80,align: 'center' },
        { title: '垫付损失原因',dataIndex: 'advanceDamageReason',width: 130,align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (value) => (
                <Tooltip placement="topLeft" title={value}>
                    {value}
                </Tooltip>
            )  
        },
        { title: '最终解决方案',dataIndex: 'finalSolution',width: 130,align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (value) => (
                <Tooltip placement="topLeft" title={value}>
                    {value}
                </Tooltip>
            )  
        },
        { title: '家属诉求慰问金',dataIndex: 'familyComfortAmount',width: 100,align: 'center'},
        { title: '实际赔付慰问金',dataIndex: 'realityComfortAmount',width: 100,align: 'center'},
        { title: '减损金额',dataIndex: 'comfortLossAmount',width: 90,align: 'center' },
        { title: '智酬报备时间',dataIndex: 'systemReportTime',width: 120,align: 'center' },
        { title: '智酬结案时间',dataIndex: 'systemCaseTime',width: 120,align: 'center' },
        { title: '智酬结案时效',dataIndex: 'systemCaseInterval',width: 120,align: 'center' },
        { title: '结案人',dataIndex: 'reportOriginatorUserName',width: 80,align: 'center'},
        { 
            title: '操作',width: 180,align: 'center',fixed: 'right',
            render: (text, record) => {
                return (
                    <Space size='middle'>
                        {<Link onClick={() => view(record)}>查看</Link>}
                        {record.dealStatus!=3&&permissions.includes(`${path}:update`)&&<Link onClick={() => edit(record)}>修改</Link>}
                        {<Link onClick={() => download(record)}>下载</Link>}
                    </Space>
                )
            }
        }
    ]
    useEffect(() => {
        getTableLists(pagination)
    }, [])
     //查看
     const view = (record)=>{
        console.log(record);
        refresh('view',record)
    }
    //修改
    const edit = (record)=>{
        refresh('edit',record)
    }
      //     单个下载：eventDownLoad/download?archivesNo='档案编码'&name=&idNumbe=&eventReportTime 
    const download = (record)=>{
        const query = {
            archivesNo:record.archivesCode,
            eventReportTime:record.eventReportTime,
            name:record.name,
            idNumber:record.idNumber,
        }
        console.log(query);
        request.downExport('/platform/eventDownLoad/download', query)
    }
    //分页改变
    const tablePageChange = (pageNum, pageSize) => {
        setPagination(prev => {
            return {
                ...prev,
                pageNum: pageNum
            }
        })
        getTableLists({ pageSize: pageSize, pageNum: pageNum })
    }
    return (
        <Table
            scroll={{ x: '100%', y: 420 }}
            columns={columns}
            rowKey={record => record.archivesCode}
            dataSource={tableData}
            pagination={{
                position: ['bottomCenter'],
                total: pagination.total,
                current: pagination.pageNum,
                pageSize: pagination.pageSize,
                showSizeChanger: false,
                showTotal: total => `共 ${total} 条数据`,
                onChange: (pageNum, pageSize) => tablePageChange(pageNum, pageSize)
            }}
        />
    )
}
export default forwardRef(BaseTable)
