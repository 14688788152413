import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  DatePicker,
  Space
 } from 'antd';
import qs from 'qs'
 import moment from 'moment';
 import MyModel1 from './MyModel1';
 import MyModel2 from './MyModel2';
 import store from '../../../../store/index'
import {request} from '../../../../utils/fetch'
import { getTaxEnterpriseList } from '../../../../api/api'
 const FormItem = Form.Item;
 const { Option } = Select;
 const { RangePicker } = DatePicker;
 const itemStyle = {
     width:'390px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 5},
    wrapperCol:{ span: 18}
 }

function PersonageInvoice(props) {
    let path = 'platform:staffTicketApply'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [form] = Form.useForm();
    const myModel1 = useRef();
    const myModel2 = useRef();
    
    const initSearch = {
        date:[],
        // companyCode:''
    }
    const [dayValue, setDayValue] = React.useState('');
    const [tableData,setTableData] = useState([])
    const [companyList,setCompanyList] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const columns = [
        {
            title: '开票操作时间',
            dataIndex: 'ticketTime',
            key: 'ticketTime',
            width: 150,
            align:'center',
        }, {
            title: '开票操作人',
            dataIndex: 'ticketUser',
            key: 'ticketUser',
            width: 150,
            align:'center',
        },{
            title: '开票金额',
            dataIndex: 'ticketAmount',
            key: 'ticketAmount',
            width: 150,
            align:'center',
            
        },{
            title: '财税企业',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            align: 'center',
            width: 150,
          },{
            title: '企业名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 150,
            align:'center',
            
        }, 
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align:'center',
            
        }, {
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align:'center',
        },{
            title: '落地方案',
            dataIndex: 'planTypeName',
            key: 'planTypeName',
            width: 150,
            align:'center',
        },  
        {
        title: '操作',
        key: 'action',
        width: 160,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => {methodsFn.handleLook(record)}}>查看</a>}
            </Space>
        ),
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="时间" name="date" style={{marginLeft:'40px'}}>
                        <RangePicker 
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem label="企业名称" name="companyCode" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                            {
                                companyList.map(item => {
                                    return <Option value={item.companyCode} key={item.id}>{item.companyName}</Option>
                                })
                            }
                        </Select>
                    </FormItem>
                    <FormItem label="财税企业" name="taxEnterpriseCode" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                            {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                        </Select>
                    </FormItem>
                    <Form.Item {...labelStyleObj} style={itemStyle} name="planType" label="落地方案">
                        <Select allowClear placeholder="全部">
                            <Option value="1">临时税务登记</Option>
                            <Option value="2">个体工商户</Option>
                            <Option value="3">委托代征</Option>
                        </Select>
                    </Form.Item>
                    <FormItem label="关键字" name="keyword"  style={itemStyle} {...labelStyleObj}>
                            <Input placeholder="姓名丨身份证号" />
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        {store.getState().userReducer.permissions.indexOf(`${path}:add`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleAdd}>新增</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').format('YYYY-MM-DD'),
            endDate = moment().format('YYYY-MM-DD');
        
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
         
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if(formObj.date.length > 1){
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }else{
                beginTime = ''
                endTime = ''
            }
            let obj = {
                beginDate:beginTime,
                endDate:endTime,
                companyCode:formObj.companyCode,
                keyword:formObj.keyword,
                planType:formObj.planType,
                taxEnterpriseCode:formObj.taxEnterpriseCode,
            }
            return obj
        },
      
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setDayValue('')
            form.resetFields();
        },
         // 获取所有财税企业
        async getTaxEnterpriseListall(){
            const res = await getTaxEnterpriseList()
            if (res.data.success) {
            setTaxEnterpriseLis(res.data.data)
            }
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/staffTicketApply/getList',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        handleLook(record){
            request.get('/platform/staffTicketApply/getDetail',{
                staffTicketApplyNo:record.staffTicketApplyNo,
            }).then(res => {
                myModel1.current.showModal(res)
               
            })
        },
        refresh(){
            methodsFn.handleSearch()
        },
       
        async getCompanyList(){
            let result = await request.get('/platform/companyBase/allList')
               setCompanyList(result)
        },
        handleAdd(){
            myModel2.current.showModal()
        },
        handleExport(){
            request.downExport('/platform/staffTicketApply/export',methodsFn.getsearchObj())
        }
    }

    useEffect(() => {
        methodsFn.getCompanyList()
        methodsFn.getTaxEnterpriseListall()
        if(props.location.search){       //解决明细页面刷新问题
            methodsFn.goDetail({
                companyCode:searchParam.companyCode,
                companyName:searchParam.companyName,
                
            })
        }
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
             <div>
                 <div>
                     {myComponent.renderSearchForm()}
                     <Table 
                         scroll={{ x: 'max-content',y: 500 }}
                         columns={columns} 
                         dataSource={tableData} 
                         rowKey={(record) => record.staffTicketApplyNo}
                         pagination={{
                            position: ['bottomCenter'],
                            total: total,
                            current: pageNum,
                            pageSize: pageSize,
                            showSizeChanger:false,
                            showTotal:(total) =>  `共 ${total} 条数据` ,
                            onChange: (pageNum, pageSize) => setPageNum(pageNum)
                        }}
                     />
                 </div>
                <MyModel1 ref={myModel1} refresh={methodsFn.refresh} />
                <MyModel2 ref={myModel2} TaxEnterpriseLis={TaxEnterpriseLis} refresh={methodsFn.refresh} />
                
                 
            </div>
    )
}

export default PersonageInvoice
