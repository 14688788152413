import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import {
  Modal,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Space,
  Button,
  Table,
  message,
  Divider
} from 'antd'
import { request } from '../../../../../utils/fetch'
import { getSMSList, batchSMS } from '../../api'
import moment from 'moment'
const { RangePicker } = DatePicker
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 11 },
}
const BatchSMSModal = (props,ref) => {
    const { refresh } = props
    const [form] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const [tableData, setTableData] = useState([])
    const [rows, setRows] = useState([])
    const [selectedRowKeys, setselectedRowKeys] = useState([])
    const [totalCount, setTotalCount] = useState()
    const initialFormValues = {timeType:2,date: [moment(new Date()).subtract(1,'months'), moment()]}
    const tableColumns = [
        { title: '双方签署完成时间', dataIndex: 'signTime', key: 'signTime', align: 'center', width: 170 },
        { title: '落地城市', dataIndex: 'cityName', key: 'cityName', align: 'center', width: 120 },
        { title: '落地区县', dataIndex: 'countyName', key: 'countyName', align: 'center', width: 120 },
        { title: '姓名', dataIndex: 'name', key: 'name', align: 'center', width: 120 },
        { title: '身份证', dataIndex: 'idNumber', key: 'idNumber', align: 'center', width: 120 },
        { title: '手机号', dataIndex: 'phone', key: 'phone', align: 'center', width: 120 },
    ]
    // 查询
    const handelQuery= async (type)=>{
      let data = form.getFieldsValue()
      if(type==1){
        data = Object.assign(data,{date: [moment(new Date()).subtract(1,'months'), moment()]})
      }
      const date = data.date
      let params = {}
      Object.keys(data).forEach(key => {
        if(data[key]){
            if(key == 'date'){
                params['beginTime'] = date ? moment(date[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                params['endTime'] = date ? moment(date[1]).format('YYYY-MM-DD HH:mm:ss') : ''
            }else{
                params[key] = data[key]
            }
        }
      });
        let result = await getSMSList(params)
        if(result.data.success){
          setTableData(result.data.data.list)
          setTotalCount(result.data.data.totalCount)
        }else{
          message.error(result.data.retMsg)
        }
    }
    // 重置
    const resetForm= ()=>{
        form.resetFields()
    }
    // 导出
    const exportData= ()=>{
      if(rows.length<1){
        message.error('请选择要导出的数据')
        return
      }
      let data = form.getFieldsValue()
      const date = form.getFieldsValue().date
      let params = {}
      let keepNoList = rows.map(e=>{
        return e.keepNo
      })
      Object.keys(data).forEach(key => {
        if(data[key]){
            if(key == 'date'){
                params['beginTime'] = date ? moment(date[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                params['endTime'] = date ? moment(date[1]).format('YYYY-MM-DD HH:mm:ss') : ''
            }else{
                params[key] = data[key]
            }
        }
      });
      params.keepNoList = keepNoList.length == totalCount? [] : keepNoList.join(',')
      request.downExport('/platform/staffKeep/exportSMSList',params)
    }
    // 确定发送
    const handleOk= async ()=>{
      if(rows.length<1){
        message.error('请选择要发送的数据')
        return
    }
      let keepNoList = rows.map(e=>{
        return e.keepNo
      })
      let result = await batchSMS({keepNoList:keepNoList.join(',')})
      if (result.data.success) {
        message.success('发送成功')
        setVisible(false)
        refresh()
      } else {
        message.error(result.data.retMsg)
      }
    }
     // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: () => {
        setVisible(true)
        form.resetFields()
        setRows([])
        setselectedRowKeys([])
        handelQuery(1)
    }
  }));
  useEffect(() => {
   
  })
  return ( 
    <>
      <Modal maskClosable={false} title="批量短信" visible={visible} onCancel={() => { setVisible(false) }} onOk={() => handleOk()} okText="确定发送" width={900}>
        <Form form={form} {...formItemLayout} initialValues={initialFormValues}>
            <Row>
            <Col span={24}>
            <Form.Item label="双方签署完成时间" name="date">
                <RangePicker showTime  format={'YYYY-MM-DD HH:mm:ss'} />
            </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item label="关键字" name="keyword">
                <Input placeholder="姓名|身份证号"></Input>
            </Form.Item>
            </Col>
            <Col span={24} style={{ display: 'flex', }}>
                <div>已选{rows.length}条</div>
                <div style={{ textAlign: 'right',flex: 1 }}>
                    <Space>
                        <Button type="primary" onClick={handelQuery}>查询</Button>
                        <Button onClick={resetForm}>重置</Button>
                        <Button onClick={exportData}>导出</Button>
                    </Space>
                </div>
            </Col>
            </Row>
        </Form>
        <Table
            columns={tableColumns}
            dataSource={tableData}
            scroll={{ x:680, y: 400 }}
            rowKey={record => record.keepNo}
            rowSelection={{selectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {
                setRows(selectedRows)
                setselectedRowKeys(selectedRowKeys)
              }}}
            pagination={false}
        />
        {totalCount>0&&<Divider style={{ margin: '0',paddingTop: '10px' }}>共{totalCount}条数据</Divider>}
      </Modal>
    </>
  )
}

export default forwardRef(BatchSMSModal)