import React, { useState, useEffect,forwardRef } from 'react';
import { request } from '../../../../../utils/fetch'
import {Form,Input,Select,Button,DatePicker,Radio,Row,Col} from 'antd'
import {getDingtalkUser,getCityListByProvinceId} from '../../api'
import store from '../../../../../store/index'
import moment from 'moment'
const FormItem = Form.Item
const { OptGroup,Option } = Select
const { RangePicker } = DatePicker
const Search = (props)=> {
    let path = 'trafficEvent'
    const [permissions] = useState(store.getState().userReducer.permissions) // 权限列表
    const {type} = props
    const { refresh } = props
    const cityList1 = [
        { cityId:'TJ', cityName:'天津市' },
        { cityId:'NC', cityName:'南昌市' },
        { cityId:'JJ', cityName:'九江市' },
        { cityId:'GZ', cityName:'赣州市' },
        { cityId:'JDZ', cityName:'景德镇市' },
        { cityId:'JN', cityName:'济南市' },
        { cityId:'LY', cityName:'临沂市' },
        { cityId:'QD', cityName:'青岛市' },
        { cityId:'WH', cityName:'威海市' },
        { cityId:'WF', cityName:'潍坊市' },
        { cityId:'YT', cityName:'烟台市' },
        { cityId:'ZB', cityName:'淄博市' },
        { cityId:'TY', cityName:'太原市' },
    ]
    const [cityList2,setCityList2] = useState([])
    const [cityData, setCityData] = useState([...cityList1]) // 城市
    const [form] = Form.useForm()
    const initSearch = {
        date: '',
        cityQueryType:'2',
        userQueryType:'1'
    }
    const [radio, setRadio] = useState(10)
    const [dingkUser,setDingkUser] = useState([])
    // 搜索表单通用样式
    const labelStyleObj = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
    }
    const itemStyle = { width: '364px', marginRight: '15px' }

    useEffect(() => {
        getCity()
        getUser()
    }, [])
    // 时间radio选择
    const radioChange = e => {
        let startDate, endDate
        setRadio(e.target.value)
        startDate = moment().subtract(e.target.value - 1, 'days').startOf('day')
        endDate = moment()
        form.setFieldsValue({ date: [startDate, endDate] })
    }
    const rangeChange = (dates) => {
        if (dates === null) {
          setRadio(10)
        }
    }
    const getUser = () => {
        getDingtalkUser().then(res => {
          if (res.data.success) {
            setDingkUser(res.data.data)
          }
        })
    }
    const getCity = ()=>{
        getCityListByProvinceId({
            regionalNo:'',
            provinceId:''
        }).then((res)=>{
            setCityList2(res.data.data)
        })
      }
    const cityChange = (value)=>{
        form.setFieldsValue({cityIdList:[]})
        if(value==2){
            setCityData(cityList1)
        }else{
            setCityData(cityList2)
        }
    }
    //查询
    const handleSearch =()=>{
        const formData = form.getFieldsValue()
        const date = form.getFieldsValue().date
        console.log(formData);
        let params = {
            tabType:type,
            beginTime: date? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date? moment(date[1]).format('YYYY-MM-DD') : '',
            keyword: formData.keyword,
            cityQueryType:formData.cityQueryType,
            cityIdList: formData.cityIdList,
            processStatusList: formData.processStatusList,
            userQueryType:formData.userQueryType,
            originatorUserId:formData.originatorUserId,
            eventTypeList:formData.eventTypeList,
            eventLevelList:formData.eventLevelList,
        }
        if(type==1){
            refresh('search',params)
        }else{
            refresh('query',params)
        }
        
    }
    // 重置
    const resetForm = () => {
        form.resetFields()
        setRadio('')
    }
    // 新增
    const add = () => {
        refresh('add')
    }
    // 导出
    const exportData = () => {
        const formData = form.getFieldsValue()
        const date = form.getFieldsValue().date
        console.log(date);
        let params = {
            tabType:type,
            beginTime: date.length>0 ? moment(date[0]).format('YYYY-MM-DD') : '',
            endTime: date.length>0 ? moment(date[1]).format('YYYY-MM-DD') : '',
            keyword: formData.keyword,
            cityQueryType:formData.cityQueryType,
            cityIdList: formData.cityIdList,
            processStatusList: formData.processStatusList,
            userQueryType:formData.userQueryType,
            originatorUserId:formData.originatorUserId,
            eventTypeList:formData.eventTypeList,
            eventLevelList:formData.eventLevelList,
        }
        console.log(params);
        request.downExport('/platform/trafficEvent/export', params)
    }
    return (
        <Form form={form} initialValues={initSearch}>
            <Row>
                <Form.Item style={{ width: '364px', marginRight: '20px' }} labelCol={{ flex: '111px' }} wrapperCol={{ flex: '1' }} name="date" label="接报或上报时间">
                    <RangePicker
                        allowClear={true}
                        onChange={(dates) => { rangeChange(dates); setRadio(10) }}
                        format="YYYY-MM-DD" />
                </Form.Item>
                <Radio.Group style={{height: '32px',alignItems: 'center',display: 'flex'}} onChange={radioChange} value={radio}>
                    <Radio value={1}>今天</Radio>
                    <Radio value={7}>近7天</Radio>
                    <Radio value={30}>近30天</Radio>
                </Radio.Group>
            </Row>
            <Row>
                <FormItem style={itemStyle} {...labelStyleObj}
                wrapperCol={{ span: 24 }}>
                    <FormItem style={{display: 'inline-block', marginBottom: 0, marginLeft:'7px', width: '100px' }} name="cityQueryType">
                        <Select onChange={(value)=>cityChange(value)}>
                            <Option value="2">注册城市</Option>
                            <Option value="1">事发城市</Option>
                        </Select>
                    </FormItem>
                    <FormItem style={{display: 'inline-block', marginBottom: 0, width: '257px'}}  name="cityIdList">
                        <Select allowClear placeholder='全部' mode='multiple'>
                            {cityData.length > 0 &&
                                cityData.map(item => (
                                    <Option value={item.cityId} key={item.cityId}>
                                        {item.cityName}
                                    </Option>
                                ))}
                        </Select>
                    </FormItem>
                </FormItem>
                <FormItem label='事件类型' name='eventTypeList' style={itemStyle} {...labelStyleObj}>
                    <Select allowClear placeholder='全部' mode='multiple'>
                        <Option value='JT'>交通事故</Option>
                        <Option value='YW'>意外事故</Option>
                        <Option value='ZAXS'>治安刑事事件</Option>
                        <Option value='WW'>维稳事件</Option>
                        <Option value='XF'>消防事件</Option>
                        <Option value='YQ'>舆情事件</Option>
                    </Select>
                </FormItem>
                <FormItem label='事件等级' name='eventLevelList' style={itemStyle} {...labelStyleObj}>
                    <Select allowClear placeholder='全部' mode='multiple'>
                        <Option value={'ESU'}>ESU</Option>
                        <Option value={'P0'}>P0</Option>
                        <Option value={'P1'}>P1</Option>
                        <Option value={'P2'}>P2</Option>
                        <Option value={'P3'}>P3</Option>
                    </Select>
                </FormItem>
                <FormItem name='staffType' style={itemStyle} {...labelStyleObj}
                wrapperCol={{ span: 24 }}>
                    <FormItem style={{display: 'inline-block', marginBottom: 0, marginLeft:'7px', width: '100px' }} name="userQueryType">
                        <Select placeholder='请选择'>
                            <Option value="1">报备人</Option>
                            <Option value="2">结案人</Option>
                        </Select>
                    </FormItem>
                    <FormItem style={{display: 'inline-block', marginBottom: 0, width: '257px'}}  name="originatorUserId">
                        <Select allowClear placeholder="全部">
                        {dingkUser.map(item => (
                            <OptGroup label={item.name} key={item.name}>
                                {
                                    item.list.map((citem)=>(
                                        <Option value={citem.userId} key={citem.userId}>{citem.name}</Option>
                                    ))
                                }
                            </OptGroup>
                        ))}
                        </Select>
                    </FormItem>
                </FormItem>
                <FormItem label='流程状态' name='processStatusList' style={itemStyle} {...labelStyleObj}>
                    <Select allowClear placeholder='全部' mode='multiple'>
                        <Option value={0}>草稿</Option>
                        <Option value={2}>审批中</Option>
                        <Option value={3}>审批通过</Option>
                        <Option value={4}>审批拒绝</Option>
                        <Option value={5}>撤销</Option>
                    </Select>
                </FormItem>
                <FormItem label='关键字' labelCol={{ style: { width: 100 } }} wrapperCol={{ flex: 300 }} name='keyword'>
                    <Input style={{ width: 350 }} placeholder='骑手ID丨姓名丨手机号丨身份证号丨事件工单号' />
                </FormItem>
            </Row>
            <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }}>
                        {permissions.includes(`${path}:select`) && (
                            <Button type='primary' onClick={handleSearch}>
                                查询
                            </Button>
                        )}
                        <Button style={{ marginLeft: 10 }} onClick={resetForm}>
                            重置
                        </Button>
                        {permissions.includes(`${path}:export`) && (
                            <Button style={{ marginLeft: 10 }} onClick={exportData}>
                                导出
                            </Button>
                        )}
                        {permissions.indexOf(`${path}:insert`)&&(
                            <Button type="primary" onClick={add} style={{ marginLeft: 10 }}>
                                新增
                            </Button>
                        )}
                    </FormItem>
                </Col>
            </Row>
        </Form>
    )
}

export default forwardRef(Search)
