import React, { useState, useEffect } from 'react';
import { Pie } from '@ant-design/charts';

const Chart2 = (props) => {
  let data = props.data || []
  var config = {
    appendPadding: 10,
    data: data,
    angleField: 'value',
    colorField: 'name',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        formatter: function formatter() {
          return '佣金\n分布';
        },
      },
    },
  };
  return <Pie {...config} />;
};

export default Chart2;