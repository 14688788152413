import React, { useState, useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  Select,
  message,
  Upload
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { changePersonalStatus, getServiceConfig } from '../../api'
const {Option} = Select
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const OpenModal = (props) => {
  const [form] = Form.useForm()
  const { visible, close, modalData, companyData, refresh } = props
  const [positivePictureUrl, setPositivePictureUrl] = useState()
  const [reversePictureUrl, setReversePictureUrl] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [typeData, setTypeData] = useState([])
  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    positiveFileList: [],
    reverseFileList: [],
    previewTitle: ''
  })
  const uploadObj = {
    token: localStorage.getItem('token'),
    mobile: localStorage.getItem('mobile')
  }
  // 
  const [areaData, setAreaData] = useState({
    cityId: '',
    countyId: '',
    planType: '',
    newCompanyName: ''
  })
  // 表单初始数据
  const initialFormValues = {
    name: '',
    idNumber: '',
    phone: '',
    newCompanyCode: '',
    areaNo: ''
  }
  
  useEffect(() => {
    if (visible){
      form.setFieldsValue({
        name: modalData.name,
        idNumber: modalData.idNumber,
        phone: modalData.phone,
        newCompanyCode: '',
        areaNo: ''
      })
      setPositivePictureUrl(modalData.positivePictureUrl)
      setReversePictureUrl(modalData.reversePictureUrl)
      console.log()
      console.log(modalData.reversePictureUrl)
      if(modalData.positivePictureUrl){
        setPreviewData(prev=> {
          return{
            ...prev,
            positiveFileList: [{
              url: modalData.positivePictureUrl,
              uid: "1",
              name: '正面',
              status: 'done',
            }],
          }
        })
      }else{
        setPreviewData(prev=> {
          return{
            ...prev,
            positiveFileList: [],
          }
        })
      }
      if(modalData.reversePictureUrl){
        setPreviewData(prev=> {
          return{
            ...prev,
            reverseFileList: [{
              url: modalData.reversePictureUrl,
              uid: "2",
              name: '反面',
              status: 'done',
            }]
          }
        })
      }else{
        setPreviewData(prev=> {
          return{
            ...prev,
            reverseFileList: []
          }
        })
      }
    }
  }, [visible, form, modalData])
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev=>{
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
    })
  }
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )
  // 
  const companyChange = async(value) => {
    companyData.forEach(item => {
      if (item.companyCode === value){
        setAreaData(prev => {
          return {
            ...prev,
            newCompanyName: item.companyName
          }
        })
      }
    })
    form.setFieldsValue({
      areaNo: ''
    })
    try {
      const res = await getServiceConfig({companyCode: value})
      setTypeData(res)
    } catch(err) {
      console.log(err)
    }
  }
  // 
  const areaChange = (value) => {
    typeData.forEach(item => {
      if (item.areaNo === value) {
        setAreaData(prev => {
          return {
            ...prev,
            cityId: item.cityId,
            countyId: item.countyId,
            planType: item.planType
          }
        })
      }
    })
  }
  // 上传图片，把图片地址存入
  const uploadChange = ({file, fileList}, type) => {
    console.log(file, fileList)
    let licenseFileUrl = ''
    fileList.forEach(item => {
      if (item.status === 'done'){
        if (item.response.retCode === '000302') {
          localStorage.clear()
          window.location = '/login'
        } else {
          licenseFileUrl = item.response.data[0].url
        }
      }
    })
    if (type === 'positive') {
      setPositivePictureUrl(licenseFileUrl)
      setPreviewData(prev=> {
        return{
          ...prev,
          positiveFileList: fileList
        }
      })
    } else {
      setReversePictureUrl(licenseFileUrl)
      setPreviewData(prev=> {
        return{
          ...prev,
          reverseFileList: fileList
        }
      })
    }
    

  }
  const handleCancel = () => {
    setPreviewData(prev=>{
      return {
        ...prev,
        previewVisible: false 
      }
    })
  }
  const onCancel = () => {
    close()
  }
  // 确定
  const handleOk = async() => {
    const data = {
      status: 1,
      planType: areaData.planType,
      areaNo: form.getFieldsValue().areaNo,
      cityId: areaData.cityId,
      countyId: areaData.countyId,
      name: form.getFieldsValue().name,
      idNumber: form.getFieldsValue().idNumber,
      phone: form.getFieldsValue().phone,
      newCompanyCode: form.getFieldsValue().newCompanyCode,
      newCompanyName: areaData.newCompanyName,
      positivePictureUrl: positivePictureUrl, 
      reversePictureUrl: reversePictureUrl
    }
    try {
      await form.validateFields()
      // if (!positivePictureUrl) {
      //   message.error('请上传身份证正面照')
      //   return
      // }
      // if (!reversePictureUrl) {
      //   message.error('请上传身份证反面照')
      //   return
      // }
      setConfirmLoading(true)
      const result = await changePersonalStatus(data)
      setConfirmLoading(false)
      if (result.data.success) {
        close()
        message.success('启用成功!')
        refresh()
      } else {
        message.error(result.data.retMsg)
      }
    } catch(err) {
      setConfirmLoading(false)
      console.log('__个人信息启用', err)
    }
  }
  return ( 
    <>
      <Modal maskClosable={false} title="启用" visible={visible} confirmLoading={confirmLoading} onCancel={onCancel} onOk={handleOk} width={740}>
        <Form form={form} {...formItemLayout} initialValues={initialFormValues}>
          <Form.Item label="姓名" name="name">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="身份证号" name="idNumber">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="手机号" rules={[{required: true}]} name="phone">
            <Input></Input>
          </Form.Item>
          <Form.Item label="最新关联公司" name="newCompanyCode" rules={[{required: true}]}>
            <Select placeholder="请选择" onChange={(value) => companyChange(value)}>
              {
                companyData.map(item => (
                  <Option value={item.companyCode} key={item.companyCode}>{item.companyName}</Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item label="落地模式" name="areaNo" rules={[{required: true}]}>
            <Select onChange={(value) => areaChange(value)}>
              {
                typeData.map(item => (
                  <Option value={item.areaNo} key={item.areaNo}>{item.joinName}</Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item label="身份证正面照">
            <Upload
              action="/wispay/common/uploadFile"
              listType="picture-card"
              name="files"
              data={uploadObj}
              fileList={previewData.positiveFileList}
              onPreview={handlePreview}
              onChange={({file, fileList}) => uploadChange({file, fileList}, 'positive')}
            >
              {previewData.positiveFileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item label="身份证反面照">
            <Upload
              action="/wispay/common/uploadFile"
              listType="picture-card"
              name="files"
              data={uploadObj}
              fileList={previewData.reverseFileList}
              onPreview={handlePreview}
              onChange={({file, fileList}) => uploadChange({file, fileList}, 'reverse')}
            >
              {previewData.reverseFileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
         <Modal maskClosable={false}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
        </Modal>
        </Form>
      </Modal>
    </>
  )
}

export default OpenModal