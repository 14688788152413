import React,{useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  DatePicker,
  Modal,
  Space,
  Upload,
  message
 } from 'antd';
 import axios from 'axios'
 import { UploadOutlined } from '@ant-design/icons'
 import moment from 'moment';
 import {request} from '../../../../utils/fetch'
 import store from '../../../../store/index'
 import { getTaxEnterpriseList, getRelevanceCompany } from '../../../../api/api'

 const FormItem = Form.Item;
 const { RangePicker } = DatePicker;
 const itemStyle = {
     width:'350px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 7},
    wrapperCol:{ span: 17}
 }

function ElectronicContract(props) {
    let path = 'platform:ContractSign'
    const [form] = Form.useForm();
    const [importForm] = Form.useForm()
    const initSearch = {
        date:[],
    }

    const [dayValue, setDayValue] = React.useState('');
    const [fileList, setFileList] = useState([]) //文件列表
    const [importVisible, setImportVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [startTime, setStartTime] = React.useState('');
    const [endTime, setEndTime] = React.useState('');
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [tableData,setTableData] = useState([])
    const [selectRow,setSelectRow] = useState([])
    const [companyData, setCompanyData] = useState([])
    const fileProps = {
        //表格上传配置和方法
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept: '.xls, .xlsx',
        beforeUpload: () => {
            return false
        },

        onChange(info) {
            let fileCon = [...info.fileList]
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1)
            setFileList(fileCon)
        }
    }
    const columns = [
        {
            title: '双方签署完成时间',
            dataIndex: 'companySignTime',
            key: 'companySignTime',
            width: 150,
            align:'center',
        }, {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align:'center',
        },{
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align:'center',
        }, {
            title: '财税企业',
            dataIndex: 'taxEnterpriseName',
            key: 'taxEnterpriseName',
            width: 250,
            align:'center',
        },  
        {
            title: '落地方案',
            dataIndex: 'typeName',
            key: 'typeName',
            width: 150,
            align:'center',
        }, 
        {
            title: '来源',
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: 130,
            align:'center',
        }, 
        {
            title: '关联公司',
            dataIndex: 'relevanceCompanyName',
            key: 'relevanceCompanyName',
            width: 180,
            align:'center',
        }, 
        {
        title: '操作',
        key: 'action',
        width: 160,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                <a target="_blank" href={record.viewUrl}>预览</a>
                {store.getState().userReducer.permissions.indexOf(`${path}:batchDownload`)>-1&&<a onClick={() => {
                      var downloadUrl = escape(record.downloadUrl),
                      fileName = record.name + '-' + record.idNumber + '.pdf';
            
                    var loadUrl =
                      `/wispay/common/pageUrlDownload?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&fileName=${fileName}&urlStr=${downloadUrl}`
                    window.location.href = loadUrl
                } }>下载</a>}
            </Space>
        ),
        },
    ];
     // 下拉框
     const selectFilter = (inputValue, option) => {
        if (option.children.indexOf(inputValue) !== -1) {
            return option
        }
    }
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="双方签署完成时间" name="date">
                        <RangePicker 
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="" name="dayValue">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                    <Form.Item style={itemStyle} {...labelStyleObj} name='taxEnterpriseCode' label="财税企业">
                        <Select allowClear placeholder="全部">
                        {TaxEnterpriseLis.map((e)=><Select.Option key={e.code} value={e.code}>{e.enterpriseName}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <FormItem label="落地方案" name="planType" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部">
                            <Select.Option value={1}>临时税务登记</Select.Option>
                            <Select.Option value={2}>个体工商户</Select.Option>
                            <Select.Option value={3}>委托代征</Select.Option>
                        </Select>
                    </FormItem>
                    <Form.Item style={itemStyle} {...labelStyleObj} name='source' label='来源'>
                        <Select mode='multiple' maxTagCount={1} allowClear placeholder="全部">
                            <Select.Option value='1'>注册</Select.Option>
                            <Select.Option value='2'>导入</Select.Option>
                            <Select.Option value='3'>雷神换签</Select.Option>
                            <Select.Option value='4'>雷神入驻</Select.Option>
                            <Select.Option value='5'>雷神转签</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item style={itemStyle} name='companyCodeList' {...labelStyleObj} label='关联公司'>
                        <Select mode='multiple' placeholder="全部" maxTagCount={1} filterOption={(inputValue, option) => selectFilter(inputValue, option)} allowClear>
                            {companyData.map(item => (
                                <Select.Option value={item.companyCode} key={item.companyCode}>
                                    {item.companyName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <FormItem label="关键字" name="keyword" style={itemStyle} {...labelStyleObj}>
                        <Input placeholder="姓名|身份证号码" />
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap', marginBottom: 0 }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        {store.getState().userReducer.permissions.indexOf(`${path}:batchDownload`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleAnyDown}>批量下载</Button>}
                        {store.getState().userReducer.permissions.indexOf(`${path}:batchDownload`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleImport}>导入文件下载</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        // 上传文件
        async onHandleUp() {
            let values = await importForm.validateFields()
            let data = new FormData() //通过FormData将文件转成二进制数据
            let file = values.ffile.file
            data.append('token', localStorage.getItem('token'))
            data.append('mobile', localStorage.getItem('mobile'))
            data.append('importFile', file)

            setLoading(true)

            axios({
                method: 'post',
                url: `/wispay/platform/contractSign/batchDownloadByImport`,
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(({ data: { retMsg, success } }) => {
                    setLoading(false)
                    if (success) {
                        message.success('下载任务已开启，请稍后在【个人下载任务列表】中下载数据')
                        methodsFn.onImportCancel()
                        methodsFn.handleSearch()
                    } else {
                        if (retMsg.includes('#')) {
                            retMsg = retMsg.split('#')
                            retMsg = retMsg.map(item => <p>{item}</p>)
                        }
                        message.error(retMsg)
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                })
        },
        // 导入弹窗
        handleImport() {
            setImportVisible(true)
        },
        //关闭新增弹窗
        onImportCancel() {
            setImportVisible(false)
            importForm.resetFields()
            setFileList([])
        },
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').format('YYYY-MM-DD'),
                endDate = moment().format('YYYY-MM-DD');
            
            setStartTime(startDate)
            setEndTime(endDate)
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
            setStartTime(startDate)
            setEndTime(endDate)
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if(formObj.date.length > 1){
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }else{
                beginTime = ''
                endTime = ''
            }
            let obj = {
                beginTime:beginTime,
                endTime:endTime,
                source:formObj.source,
                keyword:formObj.keyword,
                planType:formObj.planType,
                taxEnterpriseCode:formObj.taxEnterpriseCode,
                companyCodeList:formObj.companyCodeList,
            }
            return obj
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/contractSign/list',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
       
        handleAnyDown() {
            request.get('/platform/contractSign/batchDownload', methodsFn.getsearchObj()).then(({ msg }) => {
              if (msg === '执行成功！') {
                message.success('下载任务已开启，请稍后在【个人下载任务列表】中下载数据')
              } else {
                message.error(msg)
              }
            })
          },
        tableChange(key,row){
            setSelectRow(row)

        },
        handleExport(){
            request.downExport('/platform/contractSign/export',methodsFn.getsearchObj())
        },
         // 获取所有财税企业
        async getTaxEnterpriseListall(){
            const res = await getTaxEnterpriseList()
            if (res.data.success) {
                setTaxEnterpriseLis(res.data.data)
            }
        },
        // 获取关联公司
        async getCompany(){
        const res = await getRelevanceCompany()
        if (res && res.length > 0) {
            setCompanyData(res)
        }
    }
    }
  
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    useEffect(() => {
        methodsFn.getTaxEnterpriseListall()
        methodsFn.getCompany()
    },[])

    return (
        <div>
            {myComponent.renderSearchForm()}
           <Table 
            //  rowSelection={{
            //     type: "checkbox",
            //     onChange:methodsFn.tableChange,
            //   }}
            scroll={{ x: 'max-content',y:518}}
            columns={columns} 
            rowKey={(record) => record.id}
            dataSource={tableData} 
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />
            <Modal maskClosable={false} title='导入' confirmLoading={loading} visible={importVisible} onOk={importForm.submit} onCancel={methodsFn.onImportCancel}>
                    <Form form={importForm} onFinish={methodsFn.onHandleUp} >
                        <FormItem label='上传表格' name='ffile' rules={[{ required: true, message: '请上传表格' }]}>
                            <Upload {...fileProps}>
                                <Button icon={<UploadOutlined />}>点击上传</Button>
                                <Button type='link' onClick={e => { e.stopPropagation() }} >
                                    列：身份证号
                                </Button>
                            </Upload>
                        </FormItem>
                    </Form>
                </Modal>
         
        </div>
    )
}

export default ElectronicContract
