import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  message,
  DatePicker,
  Popconfirm,
  Tag,
  Space
 } from 'antd';
import qs from 'qs'
 import moment from 'moment';
 import PlatformCheckDetail from './PlatformCheckDetail'
import {request} from '../../../../utils/fetch'

 const FormItem = Form.Item;
const { Option } = Select;
 const { RangePicker } = DatePicker;
 const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }

function PlatformCheck(props) {
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [form] = Form.useForm();
    const initSearch = {
        companyCode:''
    }
    const [tableData,setTableData] = useState([])
    const [month, setMonth] = React.useState('');
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [isDetail,setIsDetail] = useState(false)
    const [parmObj,setParmObj] = useState({})       //传给明细的参数
    
    const columns = [
        {
            title: '对账月份',
            dataIndex: 'checkMonth',
            key: 'checkMonth',
            width: 150,
            align:'center',
        }, {
            title: '母账户余额',
            dataIndex: 'totalAccountAmount',
            key: 'totalAccountAmount',
            width: 150,
            align:'center',
        },{
            title: '对账状态',
            dataIndex: 'sureStatus',
            key: 'sureStatus',
            width: 150,
            align:'center',
            render: (text, record) => record.sureStatus == 1?'已确认':'待确认'
        }, 
        {
            title: '对账确认人',
            dataIndex: 'sureUser',
            key: 'sureUser',
            width: 150,
            align:'center',
        }, 
        {
            title: '对账确认时间',
            dataIndex: 'sureTime',
            key: 'sureTime',
            width: 150,
            align:'center',
        }, 
        {
        title: '操作',
        key: 'action',
        width: 220,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                <a onClick={() => {methodsFn.goDetail(record)}}>查看</a>
                {
                    record.sureStatus == 0
                    ? <Popconfirm
                    title="确定已经完成对账？"
                    onConfirm={() => {
                        methodsFn.handleSure(record)
                    }}
                    okText="确定"
                    cancelText="取消"
                    className="marginLeft"
                    >
                    <a>确认完成</a>
                </Popconfirm>
                :null
                }
               
            </Space>
        ),
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="对账月份" name="month" style={itemStyle} {...labelStyleObj}>
                        <DatePicker onChange={(date, dateString) => setMonth(dateString)} picker="month" />
                    </FormItem>
                    <FormItem label="对账状态" name="sureStatus" style={itemStyle} {...labelStyleObj}>
                        <Select>
                            <Option value={0}>待确认</Option>
                            <Option value={1}>已确认</Option>
                        </Select>
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let obj = {
                sureStatus:formObj.sureStatus,
                checkMonth:month,
            }
            return obj
        },
      
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setMonth('')
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/platform/platformaccountcheck/list',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
      
        refresh(){
            methodsFn.handleSearch()
        },
        handleSure(record){
          request.get('/platform/platformaccountcheck/sure',{
                checkNo:record.checkNo,
            }).then(res => {
                message.success('操作成功')
                methodsFn.refresh()
            })
        },
        goDetail(record){
            setIsDetail(true)
            props.history.push('/admin/platformCheck?companyCode='+record.companyCode+'&checkMonth='+record.checkMonth+'&companyName='+record.companyName)
            setParmObj({
                companyCode:record.companyCode,
                checkMonth:record.checkMonth,
                companyName:record.companyName,
                
            })
        },
     
        detailFn(){
            setIsDetail(false)
            props.history.push('/admin/platformCheck')
        },
        handleExport(){
            request.downExport('/platform/platformaccountcheck/export',methodsFn.getsearchObj())
        },
      
    }

    useEffect(() => {
        if(props.location.search){       //解决明细页面刷新问题
            methodsFn.goDetail({
                companyCode:searchParam.companyCode,
                checkMonth:searchParam.checkMonth,
                companyName:searchParam.companyName,
                
            })
        }
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    },[pageNum])

    return (
             <div>
                 {
                     !isDetail?
                     <div>
                     {myComponent.renderSearchForm()}
                     <Table 
                         scroll={{ x: 'max-content',y: 500 }}
                         columns={columns} 
                         dataSource={tableData} 
                         rowKey={(record) => record.id}
                         pagination={{
                            position: ['bottomCenter'],
                            total: total,
                            current: pageNum,
                            pageSize: pageSize,
                            showSizeChanger:false,
                            showTotal:(total) =>  `共 ${total} 条数据` ,
                            onChange: (pageNum, pageSize) => setPageNum(pageNum)
                        }}
                     />
                 </div>
                 : <PlatformCheckDetail isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn}/>
                 }
                 
            </div>
    )
}

export default PlatformCheck
