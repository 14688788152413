import React, { useState, useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  message,
  DatePicker,
  Upload,
  Image
} from 'antd'
import moment from 'moment'
import { uploadTransact, updateStaffKeep } from '../../api'
import { verBusinessLicense } from '../../../../../api/api'
import { PlusOutlined } from '@ant-design/icons'
const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const UploadModal = (props) => {
  const [form] = Form.useForm()
  const [licenseFileUrl, setLicenseFileUrl] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { visible, close, modalData, refresh } = props
  const initialFormValues = {
    name: '',
    idNumber: '',
    cityName: '',
    countyName: '',
    planTypeName: '',
    establishDate: ''
  }
  useEffect(() => {
    if (visible) {
      form.resetFields()
      form.setFieldsValue({
        name: modalData.name,
        idNumber: modalData.idNumber,
        cityName: modalData.cityName,
        countyName: modalData.countyName,
        planTypeName: modalData.planTypeName,
        socialCreditCode: modalData.socialCreditCode,
        licenseCompanyName: modalData.licenseCompanyName,
        transactStatusName: modalData.transactStatusName,
        establishDate: modalData.establishDate? moment(modalData.establishDate): null,
        licenseFileUrl: setFlieList(modalData.licenseFileUrl),
        businessLicenseUrl: setFlieList(modalData.businessLicenseUrl)
      })
      setPreviewData(prev=>{
        return {
          ...prev,
          fileList1: [], 
          fileList2: modalData.businessLicenseUrl? setFlieList(modalData.businessLicenseUrl): []
        }
      })
      setLicenseFileUrl()
      
    }
  }, [visible, modalData])

  const [previewData, setPreviewData] = useState({
    previewVisible: false,
    previewImage: null,
    fileList1: [],
    fileList2: [],
    previewTitle: ''
  })
  const setFlieList = (list) => {
    if(list){
      let datas = [{
        url: list,
        uid: -1,
        name: '营业执照',
        status: 'done',
      }]
      return datas
    }else{
      return null
    }
  }
  // 关闭modal
  const onCancel = () => {
    close()
  }
  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewData(prev=>{
      return {
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      }
      
    })
  }
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  )
  // 上传图片，把图片地址存入 licenseFileUrl
  const handleChange = ({file, fileList},type) => {
    fileList.forEach(item => {
      if (item.status === 'done'){
        if (item.response.retCode === '000302') {
          localStorage.clear()
          window.location = '/login'
        }
      }
    })
    fileList = fileList.map(item => {
      if (item.response&&item.response.success) {
        // Component will show file.url as link
        item.url = item.response.data[0].url;
      }
      return item;
    });
    switch (type) {
      case 'files1':
        setPreviewData(prev=> {
          return{
            ...prev,
            fileList1: fileList
          }
        })
        break;
      case 'files2':
        if(fileList.length>0&&fileList[0].url){
          getverBusinessLicense()
        }
        setPreviewData(prev=> {
          return{
            ...prev,
            fileList2: fileList
          }
        })
        break;
    }
    

  }
  
  const getverBusinessLicense = async () => {
    let url = form.getFieldsValue().businessLicenseUrl[0].url
    // let baseURL = 'http://test.zchpay.com'
    let baseURL = window.location.origin //'http://testcp.zchpay.com' // window.location.origin
    console.log(window.location)
    try {
      let result = await verBusinessLicense({pictureUrl: baseURL+url})
      if (result.data.success) {
        let data = JSON.parse(result.data.data)
        if(data.data.biz_license_owner_name!=modalData.name){
          Modal.warning({
            title: '提示',
            content: '营业执照经营者非本人，请重新上传！',
          });
        }
        if(data.data.error_code==0){
          let time = ''
          if(data.data.biz_license_start_time){
            let y = data.data.biz_license_start_time.split('年')[0]
            let m = data.data.biz_license_start_time.split('年')[1].split('月')[0]
            let d = data.data.biz_license_start_time.split('年')[1].split('月')[1].split('日')[0]
            time = y + '-' + m + '-' + d
            time = moment(time)
          }
          form.setFieldsValue({
            socialCreditCode: data.data.biz_license_credit_code,
            licenseCompanyName: data.data.biz_license_company_name,
            establishDate: time,
          })
        }else{
          message.error(data.data.error_msg)
        }
      } else {
        message.error(result.data.retMsg)
      }
    } catch(err) {
      message.error('上传失败')
      form.setFieldsValue({
        businessLicenseUrl: null
      })
    }
    
    
  }
  
  const handleCancel = () => {
    setPreviewData(prev=>{
      return {
        ...prev,
        previewVisible: false 
      }
    })
  }
  const handleOk = async() => {
    if (!form.getFieldsValue().establishDate) {
      message.warning('请选择成立日期')
      return
    }
    try {
      setConfirmLoading(true)
      let data = {
        keepNo: modalData.keepNo,
        establishDate: form.getFieldsValue().establishDate? moment(form.getFieldsValue().establishDate).format('YYYY-MM-DD'):'',
        socialCreditCode: form.getFieldsValue().socialCreditCode,
        licenseCompanyName: form.getFieldsValue().licenseCompanyName,
        businessLicenseUrl: form.getFieldsValue().businessLicenseUrl&&form.getFieldsValue().businessLicenseUrl.length>0? form.getFieldsValue().businessLicenseUrl[0].url: '',
      }
      const res = await updateStaffKeep(data)
      if (res.data.success) {
        message.success('提交成功')
        close()
        refresh()
      } else {
        message.error(res.data.retMsg)
      }
      setConfirmLoading(false)
    } catch(err) {
      setConfirmLoading(false)
    }
    return
    // if (!licenseFileUrl) {
    //   message.warning('请上传证件')
    //   return
    // }
    try {
      setConfirmLoading(true)
      const res = await uploadTransact({
        establishDate: moment(form.getFieldsValue().establishDate).format('YYYY-MM-DD'),
        keepNo: modalData.keepNo,
        transactPictureUrl: licenseFileUrl
      })
      if (res.data.success) {
        message.success('提交成功')
        close()
        refresh()
      } else {
        message.error(res.data.retMsg)
      }
      setConfirmLoading(false)
    } catch(err) {
      setConfirmLoading(false)
    }
  }
  return ( 
    <>
      <Modal maskClosable={false} title="修改" visible={visible} confirmLoading={confirmLoading} onCancel={onCancel} onOk={handleOk} width={740}>
        <Form form={form} {...formItemLayout} initialValues={initialFormValues}>
          <Form.Item label="姓名" name="name">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="身份证号" name="idNumber">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="落地城市" name="cityName">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="落地区县" name="countyName">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="落地方案" name="planTypeName">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="办证状态" name="transactStatusName">
            <Input disabled></Input>
          </Form.Item>
          <Form.Item label="成立日期" required name="establishDate">
            <DatePicker placeholder="请选择成立日期" />
          </Form.Item>
          {modalData.planType!=1&&<Form.Item label="社会信用统一代码" placeholder="请输入社会信用统一代码" name="socialCreditCode">
            <Input />
          </Form.Item>}
          {modalData.planType!=1&&<Form.Item label="登记名称" placeholder="请输入登记名称" name="licenseCompanyName">
            <Input />
          </Form.Item>}
          <Form.Item label="身份证照">
            <div style={{display: 'flex'}}>
            {
              !!modalData.positivePictureUrl && <Image src={modalData.positivePictureUrl} width={240} />
            }
            {
              !!modalData.reversePictureUrl && <Image src={modalData.reversePictureUrl} width={240} />
            }
            </div>
          </Form.Item>
          {modalData.planType!=1&&<Form.Item name="businessLicenseUrl" label="营业执照" valuePropName="fileList" getValueFromEvent={(e) => normFile(e, 'list')}>
            <Upload
              accept='.jpg,.jpeg,.png'
              action="/wispay/common/uploadFile"
              maxCount={1}
              listType="picture-card"
              data={{
                token: localStorage.getItem('token'),
                mobile: localStorage.getItem('mobile')
              }}
              name="files"
              fileList={previewData.fileList2}
              onPreview={handlePreview}
              onChange={({ file, fileList }) => handleChange({ file, fileList }, 'files2')}
            >
              {previewData.fileList2.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>}
        </Form>
        <Modal maskClosable={false}
          width={700}
          visible={previewData.previewVisible}
          title={previewData.previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewData.previewImage} />
        </Modal>
      </Modal>
    </>
  )
}

export default UploadModal