import React, { useState, useRef,useEffect } from 'react';
import { Spin, Tabs } from 'antd';
import QueryForm from './components/QueryForm'
import TableList from './components/TableList'
import moment from 'moment'
import {getDingtalkUser} from '../api'
function OrderTrail() {
    const [loading, setLoading] = useState(false)
    const [DingkUser, setDingkUser] = useState([]);
    const TableRef = useRef();
    useEffect(() => {
        getUser()
    }, [])
    const getUser = () => {
        getDingtalkUser().then(res => {
          if (res.data.success) {
            setDingkUser(res.data.data)
          }
        })
    }
    const refresh = (val,data,splist)=>{
        switch (val) {
            case "query":
                TableRef.current.getlist(data);
                break;
            default: 
          }
    }
    return (
        <Spin spinning={loading}>
            <QueryForm DingkUser={DingkUser} refresh={refresh}></QueryForm>
            <TableList refresh={refresh} ref={TableRef} />
        </Spin>
    )
}

export default OrderTrail
