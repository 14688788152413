import React, { useImperativeHandle, useState, forwardRef } from 'react'
import { Modal, Form, Input, Row, Col, Button, message } from 'antd'
import { updateIdNumber } from '../../api'

const EditIdNumber = (props, ref) => {
  const { refresh} = props
  const [visible, setVisible] = useState(false)
  const [accidentNo, setAccidentNo] = useState('')
  const [form] = Form.useForm()

  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (ctitle, data, splist) => {
      form.resetFields()
      setVisible(true)
      setAccidentNo(data.accidentNo)
      form.setFieldsValue({oldIdNumber:data.idNumber})
    },
  }))

  const handleOk = async () => {
    form.validateFields().then(async values => {
      updateIdNumber({
        accidentNo,
        idNumber: values.idNumber,
      })
        .then(res => {
          if (res.data.success) {
            setVisible(false)
            refresh('query', {})
            message.success(res.data.retMsg)
          } else {
            message.error(res.data.retMsg)
          }
        })
        .catch(err => {
          console.log(err)
        })
    })
  }

  return (
    <>
      <Modal maskClosable={false}
        title={'修改身份证号'}
        width='500px'
        visible={visible}
        onCancel={() => {
          setVisible(false)
        }}
        footer={[
          <Button
            key='back'
            onClick={() => {
              setVisible(false)
            }}
          >
            取消
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={() => {
              handleOk()
            }}
          >
            确认
          </Button>,
        ]}
      >
        <Form form={form}>
          <Row>
            <Col span={22}>
              <Form.Item name='oldIdNumber' label='旧身份证号' labelCol={{ span: 6 }}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={22}>
              <Form.Item name='idNumber' label='新身份证号' rules={[{ required: true, message: '请填写身份证号' }]}  labelCol={{ span: 6 }}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
export default forwardRef(EditIdNumber)
